// InnerPageOne.js

import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import TermsAndConditionModal from "../../Terms&Condition/TermsAndConditionModal";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";

import { ShimmerTitle } from "react-shimmer-effects";
import {
  brandGstDetailsPost,
  brandGstDetailsGet,
  brandPercentageSend,
} from "../../../../../utils/apis/brand/Brand";
import {
  gstVerify,
  panVerify,
} from "../../../../../utils/apis/third_party/third_party";

import { Context } from "../../../../../utils/context";

import { RegxExpression } from "../../../../../utils/common";

const InnerPageOne = ({ nextInnerPage, prevInnerPage, handleBack, userID, firmName, setFirmname }) => {
  const [modalShow, setModalShow] = useState(false);
  const handleNext = () => {
    nextInnerPage();
  };

  const { IMG_URL, shimmerLoader, setShimmerLoader } = useContext(Context);

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: true };
  const {
    control,
    register,
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (d) => {
    if (verifyStatus) {
      const data = new FormData();

      if (firmId != "") {
        data.append("id", firmId);
      }
      data.append("gst_image", d.gst_image[0]);
      // data.append("pan_image", d.pan_image[0]);
      data.append("gst_no", d.gst_no);
      data.append("name", d.name);
      // data.append("pan_no", d.pan_no);
      data.append("b_personal_details_id", userID);

      const res = await brandGstDetailsPost(data);
      d.percentage = brandPercentageSend.stepThree;
      if (res?.success) {
        handleNext();
      }
    } else {
      if (!verifyStatus) {
        setError("gst_no", {
          type: "manual",
          message: "Verify your GST No.",
        });
      }
    }
  };

  const [selectedGSTImages, setSelectedGSTImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedGSTImage(file);
    setStoredGst("");
    clearErrors("gst_image");
  };

  const handleRemoveFile = () => {
    setSelectedGSTImage(null);
    const fileInput = document.getElementById("fileGSTInput");
    fileInput.value = "";
  };
  const [firmId, setfirmId] = useState("");
  const [storedGst, setStoredGst] = useState("");

  const getUserFirmDetails = async () => {
    const res = await brandGstDetailsGet(userID);
    if (res?.success) {
      setfirmId(res.data.id);
      setFirmname(res?.data?.name);
      setStoredGst(IMG_URL + res.data.gst_image);
      setVerifyStatus(true);
      reset(res.data);
    }
  };

  const [verifyStatus, setVerifyStatus] = useState(false);
  const verifyGst = async () => {
    setShimmerLoader(true);
    var data = {
      b_personal_details_id: userID,
      gst_no: getValues("gst_no"),
    }
    const res = await gstVerify(data);
    if (res?.success) {
      setFirmname(res?.data?.data?.business_name);
      setValue("name", res.data?.data?.business_name);
      clearErrors();
      setVerifyStatus(true);
      setShimmerLoader(false);
    } else {
      setVerifyStatus(false);
      setError("gst_no", {
        type: "manual",
        message: res.message.message,
      });
      setShimmerLoader(false);
    }
  };

  useEffect(() => {
    getUserFirmDetails();
  }, []);

  return (
    <div className="firmDetailsFirst">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-6">
            <Form.Group className="mb-4 bankk-verrr" controlId="gst_no">
              <Form.Label>GST Number</Form.Label>
              {shimmerLoader ? (
                <ShimmerTitle line={1} gap={10} />
              ) : (
                <>
                  <Form.Control
                    type="text"
                    name="gst_no"
                    placeholder="Enter your GST number"
                    {...register("gst_no", {
                      required: "GST Number is required",
                      pattern: {
                        value:
                          RegxExpression.gst,
                        message: "Invalid GST Number",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.gst_no,
                      "is-valid": verifyStatus,
                    })}
                  />{" "}
                  <button
                    type="button"
                    className="verrrr-bttt btn-info"
                    onClick={verifyGst}
                  >
                    Verify
                  </button>
                </>
              )}

              {errors.gst_no && (
                <span className="text-danger">{errors.gst_no?.message}</span>
              )}

            </Form.Group>

            <br></br>
            <div className="col-lg-6">
              <div className="uploadGsthead">
                <h3>Upload GST Document</h3>
              </div>
              <div className="d-flex">
                <div className="uploadBtnsec">
                  {firmId === "" ? (
                    <input
                      type="file"
                      id="fileGSTInput"
                      accept="image/*"
                      {...register("gst_image", {
                        required: "GST card image is required",
                      })}
                      onChange={handleFileChange}
                    />
                  ) : (
                    <input
                      type="file"
                      id="fileGSTInput"
                      accept="image/*"
                      {...register("gst_image")}
                      onChange={handleFileChange}
                    />
                  )}
                </div>
                {selectedGSTImages && (
                  <div className="previewImgmain">
                    <img
                      src={URL.createObjectURL(selectedGSTImages)}
                      alt={`Preview of ${selectedGSTImages.name}`}
                      className="previewImg"
                    />
                    <div>
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-xmark"
                        className="circlecrossMark"
                        onClick={handleRemoveFile}
                      />
                    </div>
                  </div>
                )}
                {storedGst != "" && (
                  <div className="previewImgmain">
                    <img src={storedGst} className="previewImg" />
                  </div>
                )}
              </div>
              {errors.gst_image && (
                <span className="text-danger">{errors.gst_image.message}</span>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="text-end btnMainn apply_now_btn">
            {/* <p onClick={() => setModalShow(true)}>
            By continuing, I agree to Netpurti's
            <span className="termstxt">Terms of Use & Privacy Policy</span>
          </p> */}
            <div className="registerContinueMain">
              <Button onClick={handleBack} className="back-btn">
                Back
              </Button>
            </div>
            <div className="registerContinueMain">
              <Button
                // onClick={handleNext}
                type="submit"
                className="tabs-btn"
              >
                Register & Continue
              </Button>
            </div>

            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default InnerPageOne;
