import React, { useState, useContext, useEffect } from "react";
import "../../Scan-mart-store/ProductTab/ProductTab.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "swiper/css";
import "swiper/css/pagination";
import ContactSupplier from "../../../common-components/offcanvas/ContactSupplier/ContactSupplier";
import Chatnow from "../../../common-components/offcanvas/Chatnow/Chatnow";
import "swiper/css";
import "swiper/css/pagination";
import { Link, useNavigate } from "react-router-dom";
import { Product_Type, RequiredIs } from "../../../../../utils/common";
import { Rating } from "react-simple-star-rating";
import { Context } from "../../../../../utils/context";
import CustomisationModal from "../../../common-components/AllModal/CustomisationModal/CustomisationModal";

const ProductTab = ({ item, ...props }) => {
  const { IMG_URL, data } = props;
  const { getData } = useContext(Context);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const navigate = useNavigate();
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  // offcanvas code
  const [show, setShow] = useState(false);
  const [showchat, setShowchat] = useState(false);
  const [showcard, setShowcard] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClosechat = () => setShowchat(false);
  const handleShowchat = () => setShowchat(true);

  const handleCloseCard = () => setShowcard(false);
  const handleShowCard = () => setShowcard(true);

  const features = [
    "Feature : armchair",
    "Mail packing : Y",
    "Material : Synthetic leather",
  ];

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  // const [reviews, setReviews] = useState();
  // const getAllData = async () => {
  //   const res = await getData(`/btb/all-product-reviews/${item?.id}`);
  //   setReviews(res?.data);
  // };

  // useEffect(() => {
  //   getAllData();
  // }, [item]);

  // const { IMG_URL } = useContext(Context);

  // const [data, setData] = useState([]);
  // const getData = async () => {
  //   setData(await getProducts());
  // };

  // // console.log(data);

  // useEffect(() => {
  //   getData();
  // }, []);

  console.log("customisation", item);

  const [showCustomisationModal, setshowCustomisationModal] = useState(false);
  return (
    <>
      <div className="nearshop ">
        <div className="card   gridcardhgt">
          <div className="card-body">
            <div className="row rightMaincardRow">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="main-slider-section">
                  <div className="rightCardsecImage">
                    <Link to={`/b2bbuyer/b2b-productMain/${item?.id}`}>
                      <img
                        className="prodimgg"
                        src={IMG_URL + item?.thumbnail}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div>
                  <div className="rightproductHead">
                    <div className="SupplierHeadimg ">
                      <h3>{item?.product_title}</h3>
                    </div>

                    <div className="main-price">
                      <div>
                        <p className="price">₹{item.selling_price}</p>
                      </div>
                      <div className="mrp-div">
                        <span className="mrp-title">
                          {/* {RequiredIs.working_type_amp.includes(
                            item?.working_type
                          )
                            ? "AMP"
                            : "MRP"} */}
                          MRP :
                        </span>
                        <span className="mrp">₹{item.mrp}</span>
                      </div>
                    </div>
                    <span className="discount">
                      {item.mrp && item.selling_price
                        ? `${(
                            ((item.mrp - item.selling_price) / item.mrp) *
                            100
                          ).toFixed(2)}% off`
                        : ""}
                    </span>

                    <div className="NoOrdersPieces">
                      {/* <h2>₹ {item.mrp}</h2>
                      <p>
                        {item.mrp && item.selling_price
                          ? `${((item.mrp - item.selling_price) / item.mrp * 100).toFixed(2)}% off`
                          : ''}
                      </p>
                      <h2>₹ {item.selling_price}</h2> */}

                      <div className="minOrderProduct">
                        <p>
                          MOQ: {item?.minimum_order_quantity}
                          pc
                        </p>
                        {Product_Type?.map((val, index) => {
                          if (
                            item.customization === val?.name &&
                            val.name !== "General"
                          ) {
                            return (
                              <p
                                key={index}
                                style={{
                                  background: `${item?.customization_color} !important`,
                                }}
                                onClick={() => setshowCustomisationModal(true)}
                                className="text-field-highlight"
                              >
                                {val.tag}
                              </p>
                            );
                          }
                          return null;
                        }).filter((tag) => tag !== null)}

                        {/* <p
                          style={{
                            // background: item.customization_color,
                            backgroundColor: `${item?.customization_color} !important`,
                            // padding: "4px",
                            // fontSize: "10px",
                          }}
                          className="text-field-highlight"
                        >
                          {Product_Type?.map((val, index) => {
                            if (item.customization === val?.name) {
                              return val.tag;
                            }
                            return null;
                          }).filter((tag) => tag !== null)}
                        </p> */}
                      </div>
                    </div>

                    <div className="d-flex reactstarss">
                      <p className="ordersss">{item?.order_count} Orders</p>
                      <Rating
                        initialValue={Math.round(item?.average_rating)}
                        SVGclassName="star-svg"
                        fillColor="#00000"
                        readonly
                      />
                      <p className="ratinggcountt">
                        {Math.round(item?.average_rating) || 0}
                      </p>
                    </div>

                    {/* {parse(item.about)} */}
                    <ul>
                      {item?.highlights?.slice(0, 1).map((feature, index) => (
                        <li key={index}>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/B2Bbuyer/BestSeller/check.png"
                            }
                            className="checkSymbol"
                            alt="Check"
                          />
                          {feature?.description}
                        </li>
                      ))}
                    </ul>

                    <div className=" vierfiedProduct">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/B2Bbuyer/BestSeller/Layer2.png"
                        }
                        className="verifiedCheck"
                        alt="Verified"
                      />
                      Verified Supplier
                    </div>
                  </div>
                </div>

                <Button
                  className="contactSupplbtn rightcardbtn griddshoppbt me-1"
                  type="button"
                  onClick={() => {
                    navigate(`/b2bbuyer/b2b-productMain/${item.id}`);
                  }}
                >
                  {/* Buy Now */}
                  <FontAwesomeIcon icon="fa-solid fa-bag-shopping" />
                </Button>

                <Button
                  className="contactSupplbtn rightcardbtn griddshoppbt  me-1"
                  type="button"
                  onClick={() => {
                    navigate(`/b2bbuyer/b2b-productMain/${item.id}`);
                  }}
                >
                  {/* Add to Cart */}
                  <FontAwesomeIcon icon="fa-solid fa-cart-shopping" />
                </Button>

                {RequiredIs?.inquery?.includes(item?.product_type_id) && (
                  <Button className="contactSupplbtn rightcardbtn griddshoppbt  me-1">
                    {/* Inquiry */}
                    <FontAwesomeIcon icon="fa-solid fa-circle-info" />
                  </Button>
                )}
              </div>

              {/* <div className="col-xxl-12 ">
                      <Button
                        className="contactSupplbtn rightcardbtn me-1"
                        type="button"
                        onClick={() => {
                          navigate(`/b2bbuyer/b2b-productMain/${item.id}`);
                        }}
                      >
                        Buy Now
                      </Button>
                      <Button
                        className="contactSupplbtn rightcardbtn  me-1"
                        type="button"
                        onClick={() => {
                          navigate(`/b2bbuyer/b2b-productMain/${item.id}`);
                        }}
                      >
                        Add to Cart
                      </Button>

                      {RequiredIs?.inquery?.includes(item?.product_type_id) && (
                        <Button className="contactSupplbtn rightcardbtn  me-1">
                          Inquiry
                        </Button>
                      )}
                    </div> */}

              {/* <div className="col-lg-12 col-md-12">
                      <div className="d-flex">
                        <ButtonComponent
                          className="contactSupplbtn rightcardbtn me-3"
                          onClick={handleShow}
                        >
                          Contact Supplier
                        </ButtonComponent>
                        
                      </div>
                    </div> */}
            </div>
          </div>
        </div>

        <ContactSupplier
          handleClose={handleClose}
          placement="bottom"
          name="bottom"
          show={show}
        />

        <Chatnow
          handleClose={handleClosechat}
          placement="bottom"
          name="bottom"
          show={showchat}
        />
      </div>

      {/* 
      <div className="suggestedProd">
        <div className="subCategorieshead">
          <h3>Suggested Products</h3>
        </div>
        <div className="row swiperMainrow">
          <div className="col-lg-4 col-md-12">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className=""
            >
              {products.map((product, index) => (
                <SwiperSlide key={index}>
                  <div className="card SwiperLeftCrad">
                    <div className="card-body">
                      <img
                        src={product.imgSource}
                        className="swipersuggestedImg"
                        alt={product.name}
                      />
                      <p>{product.name}</p>
                      <h3>{product.price}</h3>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="col-lg-8 col-md-12">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className=""
            >
              <SwiperSlide>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/B2Bbuyer/BestSeller/suggestedbanner.png"
                  }
                  className="swipersuggestedImgtwo"
                />
              </SwiperSlide>

              <SwiperSlide>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/B2Bbuyer/BestSeller/suggestedbanner.png"
                  }
                  className="swipersuggestedImgtwo"
                />
              </SwiperSlide>

              <SwiperSlide>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/B2Bbuyer/BestSeller/suggestedbanner.png"
                  }
                  className="swipersuggestedImgtwo"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div> */}

      {/* <div className="card mb-4 rightCradmainSec">
        <div className="card-body">
          <div className="row rightMaincardRow">
            <div className="col-lg-3 col-md-3">
              <div className="main-slider-section">
                <Swiper
                  spaceBetween={15}
                  // modules={[Pagination]}

                  pagination={{
                    clickable: true,
                  }}
                  navigation={{
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                  }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                >
                  <SwiperSlide>
                    <div className="rightCardsecImage">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/B2Bbuyer/BestSeller/productImg1.png"
                        }
                        alt="Product"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="rightCardsecImage">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/B2Bbuyer/BestSeller/productImg1.png"
                        }
                        alt="Product"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="rightCardsecImage">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/B2Bbuyer/BestSeller/productImg1.png"
                        }
                        alt="Product"
                      />
                    </div>
                  </SwiperSlide>

                  <div className="navigation-arrow-holder">
                    <div className="swiper-button-prev">
                      <FontAwesomeIcon
                        className="navigation-arrow "
                        icon={faArrowLeft}
                      />
                    </div>
                    <div className="swiper-button-next">
                      <FontAwesomeIcon
                        className="navigation-arrow "
                        icon={faArrowRight}
                      />
                    </div>
                  </div>
                </Swiper>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div>
                <div className="rightproductHead">
                  <div className="SupplierHeadimg ">
                    <h3>
                      Armchair New Arrival Promotion Fabric Material Adjustable
                      Leisure Single Power Electric Relax
                    </h3>
                  </div>
                  <div className="NoOrdersPieces">
                    <h2>₹ 20000</h2>
                    <div className="minOrderProduct">
                      <p>Min. order: 200 pieces</p>
                      <p>Customize Product</p>
                    </div>
                    <p>20 Orders</p>
                  </div>
                  <ul>
                    <li>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/B2Bbuyer/BestSeller/check.png"
                        }
                        className="checkSymbol"
                        alt="Check"
                      />
                      Feature : armchair
                    </li>
                    <li>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/B2Bbuyer/BestSeller/check.png"
                        }
                        className="checkSymbol"
                        alt="Check"
                      />
                      Mail packing : Y
                    </li>
                    <li>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/B2Bbuyer/BestSeller/check.png"
                        }
                        className="checkSymbol"
                        alt="Check"
                      />
                      Material : Synthetic leather
                    </li>
                  </ul>

                  <div className="vierfiedProduct">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/B2Bbuyer/BestSeller/Layer2.png"
                      }
                      className="verifiedCheck"
                      alt="Verified"
                    />
                    Verified Supplier
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 my-auto">
              <div>
                <ButtonComponent
                  className="contactSupplbtn rightcardbtn"
                  onClick={handleShowCard}
                >
                  Add to Cart
                </ButtonComponent>
                <ButtonComponent className="chatnowbtn rightcardbtn">
                  Chat Now
                </ButtonComponent>
              </div>

              <AddTocart
                handleClose={handleCloseCard}
                placement="end"
                name="end"
                show={showcard}
              />
            </div>
          </div>
        </div>
      </div> */}
      <CustomisationModal
        show={showCustomisationModal}
        onHide={() => setshowCustomisationModal(false)}
      />
    </>
  );
};

export default ProductTab;
