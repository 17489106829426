import React from "react";
import "./Welcome.css";
import Container from "react-bootstrap/Container";
import { NavLink } from "react-router-dom";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
const Welcome = () => {
  return (
    <>
      {/* Welcome Section Started */}

      <section className="Welcome-Section">
        <Container fluid className="p-0">
          <div className="welcome-banner-img">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assest/images/Seller_panel/seller-image/banner/welcome-banner.png"
              }
              className="welcome-img "
              alt="..."
            />
          </div>
          <div className="black-overlay"></div>
          <div className="content-welcome-section">
            <Container>
              <div className="netpurti-logo">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/Seller_panel/seller-image/logo/netpurti_logo.png"
                  }
                  className="logo-img"
                  alt="..."
                />
              </div>
              <div className="welcome-banner-content">
                <h1 className="heading raleway-family">
                  Empower Your Sales Journey
                </h1>
                <div className="content-heading">
                  <div className="d-flex main">
                    <h4>Navigate</h4>
                    <div className="text-border"></div>
                    <h4>Manage</h4>
                    <div className="text-border"></div>
                    <h4>Succeed</h4>
                  </div>
                </div>

                <div className="Panels">
                  <Row>
                    <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6}>
                      <NavLink to="/seller/seller_home">
                        <div className="Seller-main">
                          <div className="seller">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assest/images/Seller_panel/seller-image/logo/seller.png"
                              }
                              className="seller-logo-img"
                              alt="..."
                            />
                          </div>
                          <p className="panel-text roboto-family">Seller</p>
                        </div>
                      </NavLink>
                    </Col>
                    <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6}>
                      <NavLink to="/b2bbuyer/buyer-home">
                        <div className="Seller-main">
                          <div className="seller">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assest/images/Seller_panel/seller-image/logo/B2C.png"
                              }
                              className="B2B-logo-img"
                              alt="..."
                            />
                          </div>
                          <p className="panel-text roboto-family">B2B</p>
                        </div>
                      </NavLink>
                    </Col>
                    <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6}>
                      <NavLink to={"/b2cretail/b2cretail-home"}>
                        <div className="Seller-main">
                          <div className="seller">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assest/images/Seller_panel/seller-image/logo/B2C.png"
                              }
                              className="B2B-logo-img"
                              alt="..."
                            />
                          </div>
                          <p className="panel-text roboto-family">Retail</p>
                        </div>
                      </NavLink>
                    </Col>
                    <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6}>
                      <NavLink to="/logistic/logistic-home">
                        <div className="Seller-main">
                          <div className="seller">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assest/images/Seller_panel/seller-image/logo/checklist.png"
                              }
                              className="logistic-logo-img"
                              alt="..."
                            />
                          </div>
                          <p className="panel-text roboto-family">Logistic</p>
                        </div>
                      </NavLink>
                    </Col>
                    <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6}>
                      <div className="Seller-main">
                        <div className="seller">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/Seller_panel/seller-image/logo/tea.png"
                            }
                            className="farmers-logo-img"
                            alt="..."
                          />
                        </div>
                        <p className="panel-text roboto-family">Farmers</p>
                      </div>
                    </Col>
                    <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6}>
                      <NavLink to={"/mywebsites/home"}>
                        <div className="Seller-main">
                          <div className="seller">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assest/images/Seller_panel/seller-image/logo/browser.png"
                              }
                              className="website-logo-img"
                              alt="..."
                            />
                          </div>
                          <p className="panel-text roboto-family">My Website</p>
                        </div>

                      </NavLink>
                    </Col>
                  </Row>
                </div>
                <div className="netpurti-link">
                  <p className="link">www.netpurti.com</p>
                </div>
              </div>
            </Container>
          </div>
        </Container>
      </section>

      {/* Welcome Section End */}
    </>
  );
};

export default Welcome;
