import React, { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../../Terms&Condition/TermsAndConditionModal";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Context } from "../../../../../utils/context";

const StepthreeInnerthree = ({
  prevStep,
  handleRegisterContinue,
  category,
  setSubCategory,
  subCategory,
  selectedCategory,
  setSelectedCategory,
}) => {
  const { getData, postData, IMG_URL } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const subCategoryClick = (id) => {
    if (subCategory.includes(id)) {
      setSubCategory(subCategory.filter((item) => item !== id));
    } else {
      setSubCategory([...subCategory, id]);
    }
  };

  const [subCategoryIn, setSubCategoryIn] = useState([]);

  const categoryClick = async (id) => {
    setSelectedCategory(id);
    const res = await getData(`/seller/masters/allsubcategory/${id}`);
    if (res?.success) {
      setSearchQuery("");
      setSubCategoryIn(res.data);
      setSubCategory([]);
    }
  };

  const [errors, setErrors] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredSubCategory = subCategoryIn?.filter((subCategoryIn) =>
    subCategoryIn.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleNext = async () => {
    if (subCategory.length === 0) {
      setErrors("Select any sectors to continue.");
    } else {
      handleRegisterContinue();
    }
  };

  const categoryChange = async (id) => {
    setSelectedCategory(id);

    const res = await getData(`/seller/masters/allsubcategory/${id}`);
    if (res?.success) {
      setSubCategoryIn(res.data);
    }
  };
  useEffect(() => {
    categoryChange(selectedCategory);
  }, [selectedCategory]);
  return (
    <>
      <section>
        <div className=" personal-details-form">
          <div className="">
            <div className="form-container">
              <div className="row stepthreeformRowmain">
                <div className="col-md-3">
                  <div className="CategoriesHead">
                    <h3>Categories</h3>
                  </div>
                  <div className="categoriesList">
                    {category?.map((category, index) => (
                      <p
                        key={category}
                        className={
                          selectedCategory === category?.id ? "selected" : ""
                        }
                        onClick={() => categoryClick(category?.id)}
                      >
                        {category?.name}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="col-md-9">
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="stepthreesearch">
                          <div>
                            <Form.Group controlId="">
                              <Form.Control
                                type="text"
                                name=""
                                placeholder="Search for the sector you belong to"
                                value={searchQuery}
                                onChange={handleSearchChange}
                              />
                            </Form.Group>
                            <FontAwesomeIcon
                              icon={["fa", "magnifying-glass"]}
                              className="searchStepIon"
                            />
                          </div>
                        </div>

                        <div className="grid-container">
                          {filteredSubCategory?.map((subcategory, index) => (
                            <div
                              key={index}
                              className={`box ${subCategory.includes(subcategory?.id)
                                  ? "active"
                                  : ""
                                }`}
                              onClick={() => subCategoryClick(subcategory?.id)}
                            >
                              {subcategory?.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="text-end apply_now_btn">
                  {/* <p onClick={() => setModalShow(true)}>
                    By continuing, I agree to Netpurti's
                    <span className="termstxt">
                      Terms of Use & Privacy Policy
                    </span>
                  </p> */}
                  {errors && (
                    <p>
                      <span className="termstxt text text-danger">
                        {errors}
                      </span>
                    </p>
                  )}

                  <Link to={"/seller/seller-login/"}>
                    <Button className="back-btn me-3">Skip</Button>
                  </Link>
                  <Button onClick={prevStep} className="back-btn">
                    Back
                  </Button>

                  <Button className="tabs-btn" onClick={handleNext}>
                    Save & Next
                  </Button>

                  <TermsAndConditionModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepthreeInnerthree;
