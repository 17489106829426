import { useState, useEffect, useContext } from "react";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../../Terms&Condition/TermsAndConditionModal";
import { Context } from "../../../../../utils/context";

function StepthreeInnertwo({
  innerNext,
  nextStep,
  childCategory,
  childSubPost,
  selectedChildCategory,
  setSelectedChildCategory,
  childSubCategory,
  setChildSubCategory,
}) {
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { getData, postData, IMG_URL } = useContext(Context);

  const [s_sub_category_id, sets_sub_category_id] = useState("");
  const [s_child_category_id, sets_child_category_id] = useState("");
  const [s_sector_sub_id, sets_sector_sub_id] = useState("");
  const [s_sector_c_category_id, sets_sector_c_category_id] = useState("");

  const handleClick = (id) => {
    if (childSubCategory.some((item) => item.s_c_sub_category_id === id)) {
      setChildSubCategory((prevState) =>
        prevState.filter((item) => item.btb_c_sub_category_id !== id)
      );
    } else {
      setChildSubCategory((prevState) => [
        ...prevState,
        {
          s_sub_category_id: s_sub_category_id,
          s_child_category_id: s_child_category_id,
          btb_sector_sub_id: s_sector_sub_id,
          btb_sector_c_category_id: s_sector_c_category_id,
          btb_c_sub_category_id: id,
        },
      ]);
    }
  };

  const [childSubCategoryIn, setChildSubCategoryIn] = useState([]);

  const childCategoryClick = async (id) => {
    sets_sub_category_id(id?.s_sub_category_id);
    sets_child_category_id(id?.s_child_category_id);
    sets_sector_sub_id(id?.btb_sector_sub_id);
    sets_sector_c_category_id(id?.id);

    setSelectedChildCategory(id?.s_child_category?.id);
    const res = await getData(
      `/seller/masters/allchildsubcategory/${id?.s_child_category?.id}`
    );
    if (res?.success) {
      // console.log(res);
      setChildSubCategoryIn(res.data);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredChildCategory = childSubCategoryIn?.filter(
    (childSubCategoryIn) =>
      childSubCategoryIn.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [errors, setErrors] = useState("");
  const handleNext = async () => {
    // if(childSubCategory.length === 0){
    //   setErrors('Select any Child Sub Category to continue.');
    // }else{
    nextStep();
    // }
  };

  return (
    <>
      <div className="personal-details-form">
        <div className="">
          <div className="form-container">
            <div className="row stepthreeformRowmain">
              <div className="col-md-3">
                <div className="CategoriesHead">
                  <h3>
                    <FontAwesomeIcon
                      className="left-arrow  me-2"
                      icon="fa-solid fa-arrow-left "
                      onClick={innerNext}
                    />
                    Child Categories
                  </h3>
                </div>
                <div className="categoriesList">
                  {childCategory?.map((subvalue, subindex) => (
                    <>
                      <p className="">
                        <span className=""></span>
                        {subvalue?.s_sub_category?.name}
                      </p>

                      {subvalue?.btb_sector_child_categories?.map(
                        (value, index) => (
                          <ul className="child-subcat-listtt">
                            <li
                              key={index}
                              className={
                                selectedChildCategory ===
                                value?.s_child_category?.id
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => childCategoryClick(value)}
                            >
                              {value?.s_child_category?.name}
                            </li>
                          </ul>
                        )
                      )}
                    </>
                  ))}
                  {/* <ul className="child-subcat-listtt">
                    <li>Home Decore</li>
                  </ul> */}
                </div>
              </div>
              <div className="col-md-9">
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="stepthreesearch">
                        <Form>
                          <Form.Group controlId="">
                            <Form.Control
                              type="text"
                              name=""
                              placeholder="Search for the sector you belong to"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                          </Form.Group>
                          <FontAwesomeIcon
                            icon="fa-solid fa-magnifying-glass"
                            className="searchStepIon"
                          />
                        </Form>
                      </div>
                      <div className="grid-container">
                        {filteredChildCategory?.map((option, index) => (
                          <div
                            key={index}
                            className={`box ${
                              childSubCategory?.some(
                                (item) =>
                                  item.btb_c_sub_category_id === option?.id
                              )
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleClick(option?.id)}
                          >
                            {option?.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="text-end apply_now_btn">
                {/* <Button className="back-btn">Skip</Button> */}
                {errors && (
                  <p>
                    <span className="termstxt text text-danger">{errors}</span>
                  </p>
                )}

                <Button onClick={handleNext} className=" tabs-btn">
                  Register & Continue
                </Button>

                <TermsAndConditionModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StepthreeInnertwo;
