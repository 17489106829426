import React, { useContext, useEffect, useState } from "react";
import MyWalletCard from './MyWalletCard/MyWalletCard'
import HelpSlider from '../../../HelpCenter/HelpSlider/HelpSlider';
import './MyWallet.css'
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddMoneywalletModal from '../../../common-components/AllModal/AddMoneywalletModal/AddMoneywalletModal';
import SuccessModalDynamic from "../../../../CommonForAll/SuccessModalDynamic/SuccessModalDynamic";
import useRazorpay from "react-razorpay";
import { depositAmountPost, getSellerWalletApi, withdrawAmountPost, getExportStatement } from "../../../../../utils/apis/btb/wallet";
import { Context } from "../../../../../utils/context";

const MyWallet = () => {
    const [modalMoney, setModalMoney] = useState(false);
    const uplodimg = [

        "/assest/images/B2Bbuyer/HelpCenter/add.png",
        "/assest/images/B2Bbuyer/HelpCenter/add.png"
    ];

    const [type, setType] = useState("");
    const addWallet = (type) => {
        setType(type);
        setModalMoney(true);
    }

    const [sellerWallet, setSellerWallet] = useState();
    const getWalletHistory = async () => {
        const res = await getSellerWalletApi();
        if (res?.success) {
            setSellerWallet(res?.data);
        }
    };

    const { dateShow, IMG_URL } = useContext(Context);
    const [depositAmount, setAmount] = useState(0);
    const [amountModal, setAmountModal] = useState(false);

    const [Razorpay, openRazorpay] = useRazorpay();
    const handleRazorpay = async () => {

        const options = {
            key: "rzp_test_I05KCFb1v5JJRc",
            amount: depositAmount * 100,
            currency: "INR",
            name: "Netpurti",
            description: "Test Transaction",
            image:
                "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

            handler: async (res) => {
                depositRazor(res.razorpay_payment_id);
            },
            prefill: {
                name: "Piyush Garg",
                email: "youremail@example.com",
                contact: "9999999999",
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#052c65",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
    };

    const [successModalShow, setSuccessModalShow] = useState(false);
    const depositRazor = async (razor) => {
        const res = await depositAmountPost(depositAmount, razor);
        if (res?.success) {
            setSuccessModalShow(true);
            setTimeout(() => {
                setType("");
                setAmount(0);
                getWalletHistory();
                setSuccessModalShow(false);
            }, 2000);
        }
    }

    const handleWithdraw = async () => {
        const res = await withdrawAmountPost(depositAmount);
        if (res?.success) {
            setSuccessModalShow(true);
            setTimeout(() => {
                setType("");
                setAmount(0);
                getWalletHistory();
                setSuccessModalShow(false);
            }, 2000);
        }
    }

    const Export = async () => {
        try {
            const response = await getExportStatement();
            // Create a URL for the blob
            const url = IMG_URL + response;
            console.log('url:', url);

            // Open the PDF in a new window
            window.open(url);

            // Cleanup
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error exporting statement:', error);
        }
    };

    useEffect(() => {
        getWalletHistory();
    }, []);
    return (
        <>
            <section className='b2b-mywallet-main-page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xxl-9 col-xl-9 col-lg-8 col-md-12 col-12'>
                            <div className='mb-4'>
                                <div className='row'>
                                    <div className='col-xxl-7 col-xl-7 col-md-7 col-12'>
                                        <div className='main-banner-div mb-3'>
                                            <div>
                                                <img src="/assest/images/B2Bbuyer/Accounts/wallet-bg.png" class="bg-img" alt="..." />
                                            </div>
                                            <div className='overlay-color' />
                                            <div className='overlay-content'>
                                                <div className='row'>
                                                    <div className='col-xxl-7 col-xl-7 col-md-6 col-12'>
                                                        <h6>₹{sellerWallet?.wallet?.amount}</h6>
                                                        <p>Current Netpurti's Wallet Balance</p>
                                                    </div>
                                                    <div className='col-xxl-5 col-xl-5 col-md-6 col-12 my-auto'>
                                                        <ButtonComponent type="button" className="add-btn" onClick={() => addWallet('pay')} >
                                                            Add money to wallet <FontAwesomeIcon icon="fa-solid fa-plus" />
                                                        </ButtonComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xxl-5 col-xl-5 col-md-5 col-12'>
                                        <div className='main-banner-div-two mb-3'>
                                            <div>
                                                <img src="/assest/images/B2Bbuyer/Accounts/rs.png" class="rs-img" alt="..." />
                                            </div>
                                            <p>Spend</p>
                                            <h6>₹1000</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {sellerWallet?.wallet && sellerWallet?.wallet?.wallet_histories ?
                                <MyWalletCard Export={Export} />
                                : "No Transaction History ! Add Money To Wallet ☝️ "
                            }

                        </div>
                        <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-12 col-12 '>
                            <HelpSlider imagePaths={uplodimg} imageClassName="slider-img mt-xxl-0 mt-xl-0 mt-md-3 mt-sm-3 mt-3" />
                        </div>
                    </div>
                </div>
            </section>

            <AddMoneywalletModal
                handleWithdraw={handleWithdraw}
                walletAmount={sellerWallet?.wallet?.amount}
                depositAmount={depositAmount}
                setAmount={setAmount}
                handleRazorpay={handleRazorpay}
                type={type}
                show={modalMoney}
                onHide={() => setModalMoney(false)}
            />
            <SuccessModalDynamic
                show={successModalShow}
                onHide={() => setSuccessModalShow(false)}
                text={type === 'pay' ? "Amount Deposited to your wallet!" : "Withdraw request sent successfully!"} />
        </>
    )
}

export default MyWallet