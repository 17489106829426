import React, { useContext, useEffect, useState } from "react";
import './affi-events.css'
import { getEvents } from "../../../../../utils/apis/events/event";
import { Context } from "../../../../../utils/context";
import { getGuide } from "../../../../../utils/apis/affiliate/guide";

const DoAndDont = () => {

    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const { IMG_URL } = useContext(Context);
    const [events, setEvents] = useState();
    const getAllEvents = async () => {
        const res = await getGuide();
        if (res?.success) {
            setEvents(res?.data);
        }
    };
    useEffect(() => {
        getAllEvents();
    }, []);

    const formatEventDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        }).replace(':00', '');
        return `${formattedDate} at ${formattedTime}`;
    };
    return (
        <>
            <section className='afffievents-sec'>
                <div className='row'>
                    
                        <div className='col-lg-12 col-md-12 col-sm-12 mb-4'>
                            <div className='eventcard'>
                                <div className='cardbody'>
                                    <h4 className='cardtitle'>Do's And Don't</h4>
                                    <p className='like'>{htmlToReactParser.parse(events?.do_and_donts) || ""}</p>
                                </div>
                            </div>
                        </div>
                    
                </div>
            </section>
        </>
    )
}

export default DoAndDont