import React from 'react'
import './ProductPortFolio.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonComponent from '../../../CommonComponents/ButtonComponent/ButtonComponent'

const ProductPortFolio = () => {
  return (
    <section className='product-portfolio'>
      <div className='orange-bg-portfolio'>
        <p className='prod-port'>Product portfolio</p>
        <div className='white-bg-portfolio'>
          <div className='per-month pt-3'>$67</div>
          <div className='per-month'>Per Month</div>
        </div>
      </div>
      <div className='main-dark'>
        <div className='dark-bg-brown'>
          <p><FontAwesomeIcon icon="fa-solid fa-check" className="check-con-class me-4" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
        </div>
        <div className='dark-bg-brown'>
          <p><FontAwesomeIcon icon="fa-solid fa-check" className="check-con-class me-4" />Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
        </div>
        <div className='dark-bg-brown'>
          <p><FontAwesomeIcon icon="fa-solid fa-check" className="check-con-class me-4" />Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
        </div>
      </div>
      <div className='mt-3'>
        <ButtonComponent className='start-with'>Buy Now</ButtonComponent><br />
        <ButtonComponent className='mt-3 more-info'>More Information</ButtonComponent>
      </div>
    </section>
  )
}

export default ProductPortFolio