import React, { useContext } from "react";
import { useState, useEffect } from "react";
// import './ThemePage.css';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactStars from 'react-stars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useParams } from 'react-router-dom';
import { Context } from "../../../../utils/context";
const LivePreview = () => {
    const { id } = useParams();
    const ratingChanged = (newRating) => {
        console.log(newRating);
    };

    const themes = [
        {
            id: 1,
            title: 'Nira Gehra Himare Hotel Theme',
            author: 'Eagle-Theme In Travel',
            price: 'Rs. 6999',
            sales: '960.6k Sales',
            image: '/assest/images/MyWebsite/Blogs/books.png'
        },
        {
            id: 2,
            title: 'Nira Gehra Himare Hotel Theme',
            author: 'Eagle-Theme In Travel',
            price: 'Rs. 6564',
            sales: '960.6k Sales',
            image: '/assest/images/MyWebsite/Blogs/books.png'
        },
        {
            id: 3,
            title: 'Nira Gehra Himare Hotel Theme',
            author: 'Eagle-Theme In Travel',
            price: 'Rs. 6564',
            sales: '960.6k Sales',
            image: '/assest/images/MyWebsite/Blogs/books.png'
        },
        {
            id: 4,
            title: 'Nira Gehra Himare Hotel Theme',
            author: 'Eagle-Theme In Travel',
            price: 'Rs. 6564',
            sales: '960.6k Sales',
            image: '/assest/images/MyWebsite/Blogs/books.png'
        },
        {
            id: 5,
            title: 'Nira Gehra Himare Hotel Theme',
            author: 'Eagle-Theme In Travel',
            price: 'Rs. 4846',
            sales: '960.6k Sales',
            image: '/assest/images/MyWebsite/Blogs/books.png'
        },
        {
            id: 6,
            title: 'Nira Gehra Himare Hotel Theme',
            author: 'Eagle-Theme In Travel',
            price: 'Rs. 4846',
            sales: '960.6k Sales',
            image: '/assest/images/MyWebsite/Blogs/books.png'
        },
        {
            id: 7,
            title: 'Nira Gehra Himare Hotel Theme',
            author: 'Eagle-Theme In Travel',
            price: 'Rs. 4846',
            sales: '960.6k Sales',
            image: '/assest/images/MyWebsite/Blogs/books.png'
        },
        {
            id: 8,
            title: 'Nira Gehra Himare Hotel Theme',
            author: 'Eagle-Theme In Travel',
            price: 'Rs. 4846',
            sales: '960.6k Sales',
            image: '/assest/images/MyWebsite/Blogs/books.png'
        },
    ];

    const { getData, IMG_URL } = useContext(Context);
    const [productDetails, setProductDetails] = useState();
    const [tabId, setTabId] = useState();
    const getProduct = async () => {
        const res = await getData(`/outer-website/packages/theme/${id}`);
        if (res?.success) {
            setProductDetails(res?.data)
            // setTabId(res?.data[0]?.m_w_plan_details[0]?.id)
        } else {

        }
    }
    console.log(tabId, "tabId tabId");
    useEffect(() => {
        getProduct();
    }, []);

    return (
        <section className='blog-card'>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item><Link to={`/mywebsites/pricing`}>Package</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active>Theme</Breadcrumb.Item>
                </Breadcrumb>
                <Row className="mb-5">
                    {productDetails?.map((val) => (
                        <Col key={val.id} xxl={3} xl={3} lg={4} md={6} sm={12} xs={12}>
                            <Link to={`/mywebsites/previewdetails/${val?.theme?.id}?plan_id=${id}`}>

                                <Card style={{height:"305px"}}>
                                    <div className='blog-main-img'>
                                        <img src={IMG_URL + val?.theme?.theme_images?.[0]?.image} className='blog-card-img' alt="{image}" />
                                        <div className="overlay">
                                            <div className="dot-lotti">
                                                <button type='button' className='continue-btn'>Live Preview <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></button>

                                            </div>
                                        </div>
                                    </div>
                                    <Card.Body>
                                        <Card.Title className='card-tit'>{val?.theme?.name}</Card.Title>
                                        <Card.Text>
                                            <p className='main-txtt blog-txtt'>by {val?.theme?.demo_url}</p>
                                        </Card.Text>
                                        <Row>
                                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                                <div className='blog-price'>{val.price}</div>
                                                <ReactStars
                                                    count={5}
                                                    onChange={ratingChanged}
                                                    size={15}
                                                    color2={'#ffd700'} />
                                                <div className='blog-txtt'>{val.sales}</div>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                                {/* <div className='mt-4'>
                                                    <Link to='#'>
                                                        <button type='button' className='buy-now'>Buy Now</button>
                                                    </Link>
                                                </div> */}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default LivePreview;
