import React from 'react'
import HelpCenterBanner from './HelpCenterBanner/HelpCenterBanner'
import HelpCenterTab from './HelpCenterTab/HelpCenterTab'

const HelpCenterMyWebSite = () => {
  return (
    <>
          <HelpCenterBanner />
          <HelpCenterTab />
    </>
    
  )
}

export default HelpCenterMyWebSite