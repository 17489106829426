import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../utils/context";
import "./Seller_blogs.css";
import { Link } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router";
import parse from "html-react-parser";
const Seller_blogs = () => {
  const navigate = useNavigate();
  const TextSlider = [
    {
      title: "Building a Brand through Packaging",
      title1: "Unwrapping the Secrets to Successful Ecommerce Branding",
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/sellerblogs/both.png",
    },
    {
      title: "Importance Of Ecommerce Analytics",
      title1:
        "Unveiling the Significance of E-commerce Analytics: A Guide for Online Store Success",
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/sellerblogs/girls.png",
    },
    {
      title: "Importance Of Ecommerce Analytics",
      title1:
        "Unveiling the Significance of E-commerce Analytics: A Guide for Online Store Success",
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/sellerblogs/mobilelaptop.png",
    },
    {
      title: "Importance Of Ecommerce Analytics",
      title1:
        "Unveiling the Significance of E-commerce Analytics: A Guide for Online Store Success",
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/sellerblogs/boxman.png",
    },
    {
      title: "Importance Of Ecommerce Analytics",
      title1:
        "Unveiling the Significance of E-commerce Analytics: A Guide for Online Store Success",
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/sellerblogs/boxman.png",
    },
    {
      title: "Importance Of Ecommerce Analytics",
      title1:
        "Unveiling the Significance of E-commerce Analytics: A Guide for Online Store Success",
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/sellerblogs/boxman.png",
    },
  ];

  const handlenavigate = (id) => {
    navigate(`/seller/seller-blogs/inner-seller-blog/${id}`);
  };

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/blog/blog-question`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="slider-seller">
        <div className="container">
          <div className="blogs-headig">
            <h2>Seller Blogs</h2>
          </div>
          <div className="row">
            <Swiper
              pagination={true}
              modules={[Pagination]}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                360: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                485: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                567: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2.2,
                  spaceBetween: 30,
                },

                768: {
                  slidesPerView: 2.3,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3.2,
                  spaceBetween: 30,
                },
                1440: {
                  slidesPerView: 3.6,
                  spaceBetween: 30,
                },
              }}
            >
              {data?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="col-md-12">
                    <div className="main-card-div">
                      <div className="img-divs">
                        <img
                          src={IMG_URL+item.image}
                          className="achived"
                          alt="..."
                        />
                      </div>
                      <div className="sellerblog-content">
                        <h6>{item.title}</h6>
                        <p>{item.sub_title}</p>
                      </div>
                      <div className="read-button-div">
                        {/* <button
                          className="read-button"
                          onClick={handlenavigate(item?.id)}
                        >
                          Read More
                        </button> */}

                      <Link to={`/seller/seller-blogs/inner-seller-blog/${item.id}`}>
                              <button
                                type="button"
                                className="btn butn btn-secondary"
                              >
                                Read more
                              </button>
                            </Link>
                      </div>
                      <div className="overlay-bgrrs"></div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Seller_blogs;
