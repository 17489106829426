import React,{useContext,useEffect,useState} from 'react';
import './HomeReasonslider.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Card from 'react-bootstrap/Card';
import { Content } from 'antd/es/layout/layout';
import { Context } from '../../../../../utils/context';

const slideData = [
    {
        imgSrc: "/assest/images/MyWebsite/HomeImages/HomePage/Reason/content-creator.svg",
        firstText: "An intuitive visual editor tailored for everyday users",
        secondText: "You won't have to navigate through a complicated CMS dashboard just to add to your website."
    },
    {
        imgSrc: "/assest/images/MyWebsite/HomeImages/HomePage/Reason/Outline.png",
        firstText: "Built-in Super Fast Website Hosting",
        secondText: "Netpurti sites hosted on Amazon Web Services offer unlimited bandwidth and storage, ensuring top security and reliability"
    },
    {
        imgSrc: "/assest/images/MyWebsite/HomeImages/HomePage/Reason/secure-data.png",
        firstText: "Automated updates for features and security patches",
        secondText: "You won't have to keep checking and paying for version updates or worry about security."
    },
    {
        imgSrc: "/assest/images/MyWebsite/HomeImages/HomePage/Reason/Path 1597.png",
        firstText: "Streamlined SEO and Analytics Integration",
        secondText: "Editing your keywords, descriptions and other on-page SEO related task are just a few simple clicks away."
    }
];

const HomeReasonslider = ({homeReasonsData}) => {

    
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();


    const { IMG_URL } = useContext(Context);
    
    return (
        <section className='reason-slider mb-5'>
            <Swiper
                modules={[Pagination, A11y, Autoplay]}
                slidesPerView={2.5}
                spaceBetween={25}
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                    loop: true,
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    380: {
                        slidesPerView: 1,
                    },
                    485: {
                        slidesPerView: 1,
                    },
                    575: {
                        slidesPerView: 2,
                    },

                    768: {
                        slidesPerView: 2,
                    },
                    992: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 2,
                    },
                    1200: {
                        slidesPerView: 2,
                    },
                    1440: {
                        slidesPerView: 2.5,
                    },
                    2000: {
                        slidesPerView: 2.5,
                    },
                }}
            >
                {homeReasonsData?.map((slide, index) => (
                    <SwiperSlide key={index} className='mb-5 mt-1'>
                        <Card className='reason-card'>
                            <div className='main-img-reason text-center'>
                                {/* <img src={process.env.PUBLIC_URL + slide.imgSrc} className="reason-img" alt="..." /> */}
                                <img src={IMG_URL + slide?.image} className="reason-img" alt="..." />
                            </div>
                            <Card.Body>
                                <Card.Text>
                                    <p className='card-text-first'>{slide?.name}</p>
                                    <p className='card-text-second'>{htmlToReactParser.parse(slide?.description)}</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
};

export default HomeReasonslider;
