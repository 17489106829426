import React, { useState } from "react";
import Empower_banner from "./banner/Affilate_Empower_banner";
import All_product_count_list from "./all-product-count/Affilate_All_product_count_list";
import "./main-dashboard.css";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logout_modal from "../../../../CommonForAll/logout-modal/Logout_modal";
import Notification_offcanvas from "../../common-components/offcanvas/notification-offcanvas/Notification_offcanvas";
import Affilate_Empower_banner from "./banner/Affilate_Empower_banner";
import Customer_engagement from "./Customer-engagement/Customer_engagement";
import Earning from "./Earning/Earning";
import New_Customers from "./New-Customers/New_Customers";
import New_Customers_Clicks from "./New_Customers_Clicks/New_Customers_Clicks";

const Maindashboard = () => {
  const [nottificationshow, setNotificationShow] = useState(false);
  const [logoutshow, setLogoutShow] = useState(false);
  return (
    <>
      <section className="Maindashboard">
        <div className="row">
          <div className="col-md-6">
            <div className="first-col">
              

              <Affilate_Empower_banner />
              <All_product_count_list />

              <Customer_engagement />
            </div>
          </div>

          <div className="col-md-6">
            <div className="sec_col">
         

              <Earning />

              <div className="New_Customers_and_Clicks">
                <div className="row">
                  <div className="col-md-5">
                    <New_Customers />
                  </div>

                  <div className="col-md-7">
                    <New_Customers_Clicks />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Maindashboard;
