import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import Cookies from "js-cookie";
import MobileOtpModal from "../../../CommonForAll/Mobile_otp_modal/MobileOtpModal";
import EmailOtpModal from "../../../CommonForAll/Email_otp_modal/EmailOtpModal";

import { Errors, Placeholders } from "../../../../utils/errors";
import {
  sendMobileOTP,
  resendMobileOTP,
  sendEmailOTP,
  verifyMobileOTP,
  verifyEmailOTP,
} from "../../../../utils/apis/common/Common";
import {
  btcPersonalDetailsPost,
  sellerPercentageSend,
} from "../../../../utils/apis/btc/Btc";
import { RegxExpression } from "../../../../utils/common";
const Stepone = (props) => {
  const { getData, postData, IMG_URL } = useContext(Context);
  const [startDate, setStartDate] = useState(new Date());
  const [phone, setPhone] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showOTPFields, setShowOTPFields] = useState(false);
  const [showOTPFieldsOne, setShowOTPFieldsone] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const [mobileVerify, setMobileVerify] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [termStatus, setTermStatus] = useState(false);
  const [otpErr, setOtpErr] = useState("");

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (props?.userDetails?.btc_personal_details_id) {
      setEmailVerify(true);
      setMobileVerify(true);
    }
    reset(props.userDetails);
  }, [props.userDetails]);

  const onSubmit = async (d) => {
    if (mobileVerify && emailVerify) {
      //clearErrors();
      clearErrors();
      if (termStatus == true) {
        d.term_status = termStatus;
      } else {
        setModalShow(true);
      }
      // d.term_status = true;
      // d.percentage = sellerPercentageSend.stepOne;

      if (props?.userDetails?.btc_personal_details_id) {
        d.btc_personal_details_id = props.userDetails?.btc_personal_details_id;
      }
      const res = await btcPersonalDetailsPost(d);
      if (res?.success) {
        Cookies.set("btc_id", res.data.btc_personal_details_id, { expires: 1 });
        props.nextStep();
      } else {
        if (res?.message?.email) {
          setEmailVerify(false);
          setError("email", {
            message: res.message.email,
          });
        }
        if (res?.message?.contact_no) {
          setMobileVerify(false);
          setError("contact_no", {
            message: res.message.contact_no,
          });
        }
      }
    } else {
      if (!mobileVerify) {
        setError("contact_no", {
          message: "Verify Mobile Number",
        });
      }
      if (!emailVerify) {
        setError("email", {
          message: "Verify Email",
        });
      }
    }
  };
  const [otpLoader, setOtpLoader] = useState(false);
  const handleSendOtp = async (type, subType) => {
    setOtpLoader(true);
    if (type === "mobile") {
      if (getValues("contact_no")) {
        if (subType === "resend") {
          const res = await resendMobileOTP(getValues("contact_no"));
          if (res?.success) {
            setModalShow2(true);
            setSeconds(90);
            clearErrors();
          }
        } else {
          const res = await sendMobileOTP(getValues("contact_no"));
          if (res?.success) {
            setModalShow2(true);
            setSeconds(90);
            clearErrors();
          }
        }
      } else {
        setError("contact_no", {
          message: "Mobile Number Required",
        });
      }
    } else {
      if (getValues("email")) {
        if (RegxExpression.email.test(getValues("email"))) {
          const res = await sendEmailOTP(getValues("email"));

          if (res?.success) {
            setModalShow3(true);
            setSeconds(90);
            clearErrors();
          }
        } else {
          setError("email", {
            message: "Email Invalid",
          });
        }
      } else {
        setError("email", {
          message: "Email Required",
        });
      }
    }
    setOtpLoader(false);
  };

  const verifyOTP = async (type, otp) => {
    const formValues = getValues();
    if (type == "mobile") {
      const res = await verifyMobileOTP(getValues("contact_no"), otp);
      if (res?.success) {
        setMobileVerify(true);

        setModalShow2(false);
        clearErrors();
        setSeconds(0);
      } else {
        setOtpErr(res.message);
      }
    } else {
      const res = await verifyEmailOTP(getValues("email"), otp);
      if (res?.success) {
        setEmailVerify(true);
        setModalShow3(false);
        clearErrors();
        setSeconds(0);
      } else {
        setOtpErr(res.message);
      }
    }
  };

  return (
    <>
      <div className="personal-details-form mb-0 ">
        {/* form-holder */}
        <div className="details-form-holder">
          <div className="form-container">
            <Form onSubmit={handleSubmit(onSubmit)}>
              {/* First Name , Middle Name , Last Name< */}
              <div className="">
                <div className="row ">
                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="First_Name">
                        <Form.Label className="required">First Name</Form.Label>

                        <Form.Control
                          type="text"
                          name="First_Name"
                          placeholder="Enter First Name"
                          {...register("f_name", {
                            required: "First name required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.f_name,
                            "is-valid": getValues("f_name"),
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required">Last Name</Form.Label>

                        <Form.Control
                          type="text"
                          name="Last_Name"
                          placeholder="Enter Last Name"
                          {...register("l_name", {
                            required: "Last name required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.l_name,
                            "is-valid": getValues("l_name"),
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>

              {/*   Mobile Number , Date of Birth,  Gender*/}
              <div className="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-field">
                      <Form.Group controlId="Mobile_Number">
                        <Form.Label className="required">
                          Contact Number
                        </Form.Label>
                        <div className="get-otp-text">
                          <Controller
                            name="contact_no"
                            control={control}
                            render={({ field }) => (
                              <div style={{ position: "relative" }}>
                                <PhoneInput
                                  country="in"
                                  name="contact_no"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                  placeholder={Placeholders.phone}
                                  inputStyle={{
                                    borderColor: errors.contact_no
                                      ? "red"
                                      : mobileVerify
                                      ? "green"
                                      : "",
                                  }}
                                  disabled={mobileVerify}
                                />
                                {mobileVerify && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "10px",
                                      transform: "translateY(-50%)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "1.2em",
                                      }}
                                    >
                                      ✔
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                            rules={{ required: Errors.phone }}
                          />

                          {!mobileVerify && !otpLoader && (
                            <p
                              className="telepara"
                              onClick={() => handleSendOtp("mobile", "send")}
                            >
                              Send OTP
                            </p>
                          )}
                        </div>
                        <sup className="text-danger">
                          {errors?.contact_no?.message}
                        </sup>
                        <MobileOtpModal
                          show={modalShow2}
                          seconds={seconds}
                          otpErr={otpErr}
                          setSeconds={setSeconds}
                          verifyOTP={verifyOTP}
                          handleSendOtp={() =>
                            handleSendOtp("mobile", "resend")
                          }
                          onHide={() => setModalShow2(false)}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="Corporate_Email">
                        <Form.Label className="required form-field">
                          Corporate Email
                        </Form.Label>
                        <div className="get-otp-text">
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Enter Last Name"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: RegxExpression.email,
                                message: "Invalid email address",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.email,
                              "is-valid": emailVerify,
                            })}
                            disabled={emailVerify}
                          />
                          {emailVerify && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                              }}
                            >
                              <span
                                style={{ color: "green", fontSize: "1.2em" }}
                              >
                                ✔
                              </span>
                            </div>
                          )}
                          {!emailVerify && !otpLoader && (
                            <p
                              className="telepara"
                              onClick={() => handleSendOtp("email", "send")}
                            >
                              Send OTP
                            </p>
                          )}
                        </div>
                        <sup className="text-danger">
                          {errors?.email?.message}
                        </sup>
                        <EmailOtpModal
                          show={modalShow3}
                          seconds={seconds}
                          otpErr={otpErr}
                          setSeconds={setSeconds}
                          verifyOTP={verifyOTP}
                          handleSendOtp={() => handleSendOtp("email", "send")}
                          onHide={() => setModalShow3(false)}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <Form className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required">
                          Designation
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="designation"
                          placeholder="Enter Designation"
                          {...register("designation", {
                            required: "designation is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.designation,
                            "is-valid": getValues("designation"),
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </Form>
                  </div>

                  <div className="col-md-12">
                    <div className="text-end apply_now_btn">
                      <p onClick={() => setModalShow(true)}>
                        By continuing, I agree to Netpurti's
                        <span className="termstxt">
                          Terms of Use & Privacy Policy
                        </span>
                      </p>

                      <div className="registerContinueMain">
                        <Button className="tabs-btn" type="submit">
                          Register & Continue
                        </Button>
                      </div>
                    </div>

                    <TermsAndConditionModal
                      show={modalShow}
                      setTermStatus={setTermStatus}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stepone;
