import React from "react";
import "./Affilate-Empower-banner.css";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

const Affilate_Empower_banner = () => {
  return (
    <>
      <div className="row m-3">
        <div className="col-6">
          <h6 className="dash-t-ttde">Dashboard</h6>
          <p className="dasvvv-sub-p">
            Welcome to Netpurti Affiliate Dashboard
          </p>
        </div>
        <div className="col-6 text-end">
          <div className="month-wise-droppp">
            <Form.Select aria-label="Default select example">
              <option>Select</option>
              <option value="1">Monthly</option>
              <option value="2">Yearly</option>
              <option value="3">Weekly</option>
            </Form.Select>
          </div>
        </div>
      </div>
    </>
  );
};

export default Affilate_Empower_banner;
