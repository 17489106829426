// import React, { useState } from "react";
// import InputGroup from "react-bootstrap/InputGroup";
// import Form from "react-bootstrap/Form";
// // import Header from "../../Header/Header";
// import Col from "react-bootstrap/Col";
// import Nav from "react-bootstrap/Nav";
// import Row from "react-bootstrap/Row";
// // import Tab from "react-bootstrap/Tab";
// import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
// import "react-tabs-scrollable/dist/rts.css";
// import Brand from "../../InfoMaster/Brand/Tables";
// import FAQ from "../../InfoMaster/Faq/Table";
// import SubFaq from "../../InfoMaster/SubFaq/Table";
// // import WorkingType from "../InfoMaster/WorkingType/Table";
// import Category from "../../InfoMaster/Category/Table";
// import SubCategory from "../../InfoMaster/SubCategory/Table";
// import ChildCategory from "../../InfoMaster/ChildCategory/Table";
// import ChildSubCategory from "../../InfoMaster/ChildSubCategory/Table";
// import Blogs from "../../InfoMaster/Blogs/Tables";
// // import CouponCodes from "../../InfoMaster/CouponCodes/Table";
// // import TaxType from "../InfoMaster/Tax_Types/Tables";
// // import TaxPercentage from "../InfoMaster/Tax_Percentage/Tables";
// import WebHeader from "../../InfoMaster/WebHeader/Tables";
// import AppSetup from "../../InfoMaster/AppSetup/Tables";
// // import ContactUs from "../InfoMaster/contact_us/Tables";
// import Users from "../../InfoMaster/users/Tables";
// import CrazyDeals from "../../InfoMaster/CrazyDeals/Table";
// import ExclusiveCollection from "../../InfoMaster/ExclusiveCollection/Table";
// import Attribute from "../../InfoMaster/Attribute/Table";
// import SubAttribute from "../../InfoMaster/SubAttribute/Table";
// import AttributePackaging from "../../InfoMaster/AttributePackaging/Table";
// import ShopTrends from "../../InfoMaster/ShopTrends/Table";
// import HomePageBanner from "../../InfoMaster/HomePageBanner/Table";
// import AboutUsBanner from "../../InfoMaster/banner/Edit";
// import OurStory from "../../InfoMaster/OurStory/Tables";
// import HappyCustomer from "../../InfoMaster/HappyCustomer/Tables";
// import WhyChoose from "../../InfoMaster/whyChoose/Tables";
// import PopularProduct from "../../InfoMaster/PopularProduct/Table";
// import SaleBanner from "../../InfoMaster/SaleBanner/Table";
// import CategoryBanner from "../../InfoMaster/CategoryBanner/Table";
// import UserMotivation from "../../InfoMaster/UserMotivation/Table";
// import ProductDealType from "../../InfoMaster/ProductDealType/Table";
// import ProductDeal from "../../InfoMaster/ProductDeal/Table";
// import OurPerformance from "../../InfoMaster/OurPerformnce/Tables";
// import SocialMedia from "../../InfoMaster/SocialMedia/Table";

// import "./Setting.css";

// const Setting = () => {
//   const [filter, setFilter] = useState("");
//   const [active, setActive] = useState(1);

//   const handleFilterChange = (event) => {
//     setFilter(event.target.value.toLowerCase());
//   };

//   const tabsArray = [
//     // { key: 0, name: "About Us", link: "masters/about-us-banner" },

//     {
//       key: 0,
//       name: "HomePage Banner",
//       link: "masters/homepage-banner",
//     },

//     {
//       key: 1,
//       name: "Sale Banner",
//       link: "masters/sale-banner",
//     },
//     {
//       key: 2,
//       name: "Category Banner",
//       link: "masters/category-banner",
//     },

//   ];

//   const tabContent = [
//     // { key: 0, component: <AboutUsBanner /> },
//     { key: 0, component: <HomePageBanner /> },
//     { key: 1, component: <SaleBanner /> },
//     { key: 2, component: <CategoryBanner /> },
//   ];

//   const [activeTab, setActiveTab] = useState(0);
//   const onTabClick = (e, index) => {
//     // console.log(e);
//     console.log(index);
//     setActiveTab(index);
//   };
//   // const tabsArray = [...Array(20).keys()];

//   return (
//     <>

//       <Tabs activeTab={activeTab} onTabClick={onTabClick}>
//         {tabsArray.map((item, index) => (
//           <Tab key={item.eventKey} eventKey={item.key}>
//             {item.name}
//           </Tab>
//         ))}
//       </Tabs>

//       {tabContent.map((item) => (
//         <TabScreen key={item.key} activeTab={activeTab} index={item.key}>
//           {item.component}
//         </TabScreen> ))}
//     </>
//   );
// };

// export default Setting;

import React, { useContext, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
// import Tab from "react-bootstrap/Tab";
import "react-tabs-scrollable/dist/rts.css";
import Brand from "../../InfoMaster/Brand/Tables";
import FAQ from "../../InfoMaster/Faq/Table";
import SubFaq from "../../InfoMaster/SubFaq/Table";
// import WorkingType from "../InfoMaster/WorkingType/Table";
import Category from "../../InfoMaster/Category/Table";
import SubCategory from "../../InfoMaster/SubCategory/Table";
import ChildCategory from "../../InfoMaster/ChildCategory/Table";
import ChildSubCategory from "../../InfoMaster/ChildSubCategory/Table";
import Blogs from "../../InfoMaster/Blogs/Tables";
// import CouponCodes from "../../InfoMaster/CouponCodes/Table";
// import TaxType from "../InfoMaster/Tax_Types/Tables";
// import TaxPercentage from "../InfoMaster/Tax_Percentage/Tables";
import WebHeader from "../../InfoMaster/WebHeader/Tables";
import AppSetup from "../../InfoMaster/AppSetup/Tables";
// import ContactUs from "../InfoMaster/contact_us/Tables";
import Users from "../../InfoMaster/users/Tables";
import CrazyDeals from "../../InfoMaster/CrazyDeals/Table";
import ExclusiveCollection from "../../InfoMaster/ExclusiveCollection/Table";
import Attribute from "../../InfoMaster/Attribute/Table";
import SubAttribute from "../../InfoMaster/SubAttribute/Table";
import AttributePackaging from "../../InfoMaster/AttributePackaging/Table";
import ShopTrends from "../../InfoMaster/ShopTrends/Table";
import HomePageBanner from "../../InfoMaster/HomePageBanner/Table";
import AboutUsBanner from "../../InfoMaster/banner/Edit";
import OurStory from "../../InfoMaster/OurStory/Tables";
import HappyCustomer from "../../InfoMaster/HappyCustomer/Tables";
import WhyChoose from "../../InfoMaster/whyChoose/Tables";
import PopularProduct from "../../InfoMaster/PopularProduct/Table";
import SaleBanner from "../../InfoMaster/SaleBanner/Table";
import CategoryBanner from "../../InfoMaster/CategoryBanner/Table";
import UserMotivation from "../../InfoMaster/UserMotivation/Table";
import ProductDealType from "../../InfoMaster/ProductDealType/Table";
import ProductDeal from "../../InfoMaster/ProductDeal/Table";
import OurPerformance from "../../InfoMaster/OurPerformnce/Tables";
import SocialMedia from "../../InfoMaster/SocialMedia/Table";

import "./Setting.css";
import { faFigma } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClipboard,
  faFileLines,
  faList12,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import { Context } from "../../../../../../utils/context";

const Setting = () => {
  const [filter, setFilter] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const tabsArray = [
    {
      key: 0,
      name: (
        <>
          <FontAwesomeIcon icon={faClipboard} />
          <span className="name-text">HomePage Banner</span>
        </>
      ),
      component: <HomePageBanner />,
      tooltip: "HomePage Banner",
    },
    {
      key: 1,
      name: (
        <>
          <FontAwesomeIcon icon={faBars} />
          <span className="name-text">Sale Banner</span>
        </>
      ),
      component: <SaleBanner />,
      tooltip: "Sale Banner",
    },
    {
      key: 2,
      name: (
        <>
          <FontAwesomeIcon icon={faFileLines} />
          <span className="name-text">Category Banner</span>
        </>
      ),
      component: <CategoryBanner />,
      tooltip: "Category Banner",
    },
  ];

  const renderTooltip = (props) => (
    <Tooltip {...props}>{props.tooltip}</Tooltip>
  );

  const closeSidebar = () => {
    setSidebar(true);

    setOuterSidebar(true);

    setInnerSidebar(false);
  };

  const {
    sidebar,
    setSidebar,
    outerSidebar,
    setOuterSidebar,
    innerSidebar,
    setInnerSidebar,
  } = useContext(Context);

  return (
    <>
      <div className="sidebar-tab-holder">
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
        >
          <Row>
          <div
              // sm={1}
              className={`scale-up-hor-left ${
                !innerSidebar
                  ? "col-width-expand col-xxl-2 col-xl-3 col-md-3"
                  : "sidebar-adjust col-width col-xxl-1 col-xl-1 col-md-1"
              }`}

              // className={`  scale-up-hor-left ${
              //       sidebar ? "col-width-expand" : "col-width"
              //     }`}
            >
              <Nav variant="pills" className="flex-column">
                {tabsArray.map((item) => (
                  <OverlayTrigger
                    key={item.key}
                    placement="right"
                    delay={{ show: 200, hide: 0 }}
                    overlay={renderTooltip({ tooltip: item.tooltip })}
                  >
                    <Nav.Item onClick={closeSidebar}>
                      <Nav.Link eventKey={item.key}>{item.name}</Nav.Link>
                    </Nav.Item>
                  </OverlayTrigger>
                ))}
              </Nav>
            </div>
            <div
              // sm={11}
              className={`${
                !innerSidebar
                  ? "col2-width col-xxl-10 col-xl-9 col-md-9"
                  : "col2-width col-xxl-11 col-xl-11 col-md-11"
              }`}
            >
              {" "}
              <Tab.Content>
                {tabsArray.map((item) => (
                  <Tab.Pane eventKey={item.key} key={item.key}>
                    {item.component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </div>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};

export default Setting;
