import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Form from 'react-bootstrap/Form';
import "./Contactus.css";
import ButtonComponent from "../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import PhoneInput from "react-phone-input-2";
import InquiryModal from "../common-components/AllModal/inquiry-Modal/InquiryModal";
import classNames from "classnames";
import { postData } from "../../../utils/api";

const Contactus = () => {
    const [modalInquiry, setModalInquiry] = React.useState(false);

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    const [phone, setPhone] = useState('');
    const [phoneone, setPhoneone] = useState('');
    const submitModal = () => {
        setModalInquiry(true);
        setTimeout(() => {
            window.location = "/b2bbuyer/buyer-home"
            reset();
            setModalInquiry(false);
        }, 3000);
    };

    const onSubmit = async (data) => {
        const res = await postData('/btb/contact_us/create', data);
        submitModal(true)
    };

    return (
        <>
            <section className="B2bContact">
                <div className="container">
                    <div className="row b2bContactMain">
                        <div className="col-lg-6">
                            <div className="Contactbanner">
                                <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/Contact/ContactBanner.png"} alt="Contact Banner" />
                            </div>
                        </div>
                        <div className="col-lg-6 my-auto">
                            <div>
                                <div className="contactHead">
                                    <h3>Contact Us</h3>
                                </div>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <Form.Group className="mb-3" controlId="fullName">
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Full Name"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.name,
                                                        "is-valid": !errors?.name && getValues("name"),
                                                    })}
                                                    {...register("name", {
                                                        required: "Name is required",
                                                    })}
                                                />
                                            </Form.Group>
                                            {/* {errors.name && (
                                                <span className="text-danger">
                                                    {errors.name.message}
                                                </span>
                                            )} */}
                                            <div className="phoneInputsec mb-3">
                                                <Form.Label>Personal Contact Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="contact_no"
                                                    placeholder="Contact No."
                                                    {...register("contact_no", {
                                                        required: "Contact number is required",
                                                        validate: {
                                                            isTenDigits: (value) =>
                                                                value && value.length === 10 || "Contact number must be 10 digits",
                                                            isNumeric: (value) =>
                                                                /^\d+$/.test(value) || "Contact number must be numeric"
                                                        }
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.contact_no,
                                                        "is-valid": !errors?.contact_no && getValues("contact_no")?.length === 10,
                                                    })}
                                                    onKeyDown={(event) => {
                                                        const { key } = event;
                                                        if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab") {
                                                            event.preventDefault();
                                                        }
                                                        if (event.target.value?.length >= 10 && key !== "Backspace" && key !== "Tab") {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />

                                            </div>

                                            {/* <Form.Group className="mb-3" controlId="country">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    {...register("country", { required: true })}
                                                    placeholder="Enter Country Name"
                                                />
                                                {errors.country && <span className="error-message">Country Name is required</span>}
                                            </Form.Group> */}
                                            <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Company</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="company"
                                                    placeholder="Company Name"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.company,
                                                        "is-valid": !errors?.company && getValues("company"),
                                                    })}
                                                    {...register("company", {
                                                        required: "Company is required",
                                                    })}
                                                />
                                            </Form.Group>
                                            {/* {errors.company && <div className="invalid-feedback">{errors.company.message}</div>} */}

                                        </div>
                                        <div className="col-lg-6 col-md-6">

                                            <Form.Group className="mb-3" controlId="revenueVolume">
                                                <Form.Label>Business Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    placeholder="email"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.email,
                                                        "is-valid": !errors?.email && getValues("email"),
                                                    })}
                                                    {...register("email", {
                                                        required: "Email is required",
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                            message: "Invalid email address",
                                                        },
                                                        validate: (value) => {
                                                            // Extract the domain and TLD
                                                            const domainPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/;
                                                            const match = value.match(domainPattern);
                                                            if (match) {
                                                                const domainParts = match[1].split('.');
                                                                const tld = match[2];

                                                                // Ensure the domain and TLD are not the same
                                                                if (domainParts[domainParts.length - 1] === tld) {
                                                                    return "Domain and top-level domain must be different";
                                                                }
                                                            }
                                                            return true;
                                                        }
                                                    })}
                                                />
                                                {/* {errors.email && <div className="invalid-feedback">{errors.email.message}</div>} */}
                                            </Form.Group>
                                            {/* <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Company</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    {...register("company", { required: true })}
                                                    placeholder="Enter Company Name"
                                                />
                                                {errors.company && <span className="error-message">Company Name is required</span>}
                                            </Form.Group> */}
                                            <div className="phoneInputsec mb-3">
                                                <Form.Label>Business Contact Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="business_contact_no"
                                                    placeholder="Business Contact No."
                                                    {...register("business_contact_no", {
                                                        required: "Business Contact number is required",
                                                        validate: {
                                                            isTenDigits: (value) =>
                                                                value && value.length === 10 || "Contact number must be 10 digits",
                                                            isNumeric: (value) =>
                                                                /^\d+$/.test(value) || "Contact number must be numeric"
                                                        }
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.business_contact_no,
                                                        "is-valid": !errors?.business_contact_no && getValues("business_contact_no")?.length === 10,
                                                    })}
                                                    onKeyDown={(event) => {
                                                        const { key } = event;
                                                        if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab") {
                                                            event.preventDefault();
                                                        }
                                                        if (event.target.value?.length >= 10 && key !== "Backspace" && key !== "Tab") {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />

                                            </div>



                                            {/* <Form.Group className="mb-3" controlId="revenueVolume">
                                                <Form.Label>Annual revenue volume</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    {...register("revenueVolume", { required: true })}
                                                    placeholder="Enter Annual Revenue Volume"
                                                />
                                                {errors.revenueVolume && <span className="error-message">Annual Revenue Volume is required</span>}
                                            </Form.Group> */}
                                            <Form.Group className="mb-3" controlId="country">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="country"
                                                    placeholder="Country Name"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.country,
                                                        "is-valid": !errors?.country && getValues("country"),
                                                    })}
                                                    {...register("country", {
                                                        required: "Country is required",
                                                    })}
                                                />
                                                {/* {errors.country && <div className="invalid-feedback">{errors.country.message}</div>} */}
                                            </Form.Group>
                                        </div>

                                        <Form.Group className="mb-3 teradata-div" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="message"
                                                type="text"
                                                rows={3}
                                                placeholder="Enter Message"
                                                {...register("message", {
                                                    required: "Message required",
                                                })}
                                                className={classNames("", {
                                                    "is-invalid": errors?.message,
                                                    "is-valid": !errors?.message && getValues("message"),
                                                })}
                                            />
                                        </Form.Group>
                                        {/* {errors.message && <div className="invalid-feedback">{errors.message.message}</div>} */}
                                    </div>

                                    <div className="Contactsubmitbtn">
                                        <ButtonComponent type="submit" className="submit" >Submit Form</ButtonComponent>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <InquiryModal
                message="Submitted successfully"
                show={modalInquiry}
                onHide={() => setModalInquiry(false)}
            />
        </>
    );
};

export default Contactus;
