import React, { useRef, useState } from "react";
import "./variants.css";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SingleVariant from "./SingleVariant/SingleVariant";
import MultiVarientMain from "./MultiVarientMain/MultiVarientMain";
import MultiVariant from "./MultiVarientMain/MultiVariant/MultiVariant";
import MultiVarientInner from "./MultiVarientMain/MultiVarientInner/MultiVarientInner";

const Variants = ({ nextStep, prevStep, productId, viewType }) => {
  const [variants_option] = useState(["Single", "Multiple"]);
  const [selectedValue, setSelectedValue] = useState("Single");
  const [showMultiVariantInner, setShowMultiVariantInner] = useState(false);

  // Navigate to ProductInfo component
  const goToProductInfo = () => {
    nextStep();
  };

  const handleOptionChange = (event) => {
    setSelectedValue(event.target.value);
    setShowMultiVariantInner(false);
  };

  const switchToMultipleTab = () => {
    setSelectedValue("Multiple");
    setShowMultiVariantInner(false);
  };

  const switchToSingleTab = () => {
    setSelectedValue("Single");
    setShowMultiVariantInner(true);
  };

  return (
    <>
      <div className="col-md-12">
        <section className="Variants_section_main">
          <div className="sec-div">
            <Form.Label>
              Variant Type <span className="mandatory-star"> *</span>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-title">Product</Tooltip>}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-circle-info"
                  className="ms-3"
                />
              </OverlayTrigger>
            </Form.Label>
            <div className="d-flex varient-checkss">
              {variants_option.map((options, index) => (
                <div className="mb-3" key={index}>
                  <Form.Check
                    inline
                    label={options}
                    name="group1"
                    type="radio"
                    value={options}
                    checked={selectedValue === options}
                    onChange={handleOptionChange}
                  />
                </div>
              ))}
            </div>
          </div>

          {selectedValue === "Single" && (
            <SingleVariant
              prevStep={prevStep}
              nextStep={nextStep}
              productId={productId}
              viewType={viewType}
            />
          )}
          {selectedValue === "Multiple" && (
            <MultiVariant
              prevInnerPage={switchToSingleTab}
              nextStep={nextStep}
              productId={productId}
              viewType={viewType}
            />
          )}
          {selectedValue === "Multiple" && showMultiVariantInner && (
            <MultiVarientInner
              prevInnerPage={switchToSingleTab}
              nextStep={nextStep}
              productId={productId}
              viewType={viewType}
            />
          )}
        </section>
      </div>
    </>
  );
};

export default Variants;
