import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./CustomisationModal.css";
import Form from "react-bootstrap/Form";
import ButtonComponent from "../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import { getCustomizationDetails } from "../../../../../utils/apis/btb/Home_Page";
import parse from "html-react-parser";
const CustomisationModal = (props) => {
  const [data, setData] = useState({});

  const Customisation = async () => {
    const res = await getCustomizationDetails();
    setData(res.data);
  };
  useEffect(() => {
    Customisation();
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-note-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Customisation Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <div className="text-section">
              {parse(data?.name || "")}
              {/* <label>
                Quality Assurance = QA = Ensuring product meets specified
                standards
              </label>
              <br />
              <label>
                Lead Time = LT = Time taken from order placement to delivery
              </label>
              <br />
              <label>
                Minimum Order Quantity = MOQ = Smallest quantity of a product
                that can be ordered
              </label>
              <br />
              <label>
                Return on Investment = ROI = Measure of profitability and
                efficiency of an investment
              </label>
              <br />
              <label>
                Just-In-Time = JIT = Production strategy that reduces inventory
                and delivers products exactly when needed
              </label>
              <br />
              <label>
                Business to Consumer = B2C = Business model focused on selling
                directly to consumers
              </label>
              <br />
              <label>
                Business to Business = B2B = Business model focused on selling
                to other businesses
              </label>
              <br />
              <label>
                Free On Board = FOB = Seller pays for transport to port of
                shipment, and buyer takes ownership thereafter
              </label>
              <br />
              <label>
                Total Cost of Ownership = TCO = Overall cost of a product
                including purchase, usage, and maintenance
              </label>
              <br />
              <label>
                Enterprise Resource Planning = ERP = Software for managing
                business operations and processes
              </label>
              <br />
              <label>
                Quality Assurance = QA = Ensuring product meets specified
                standards
              </label>
              <br />
              <label>
                Lead Time = LT = Time taken from order placement to delivery
              </label>
              <br />
              <label>
                Minimum Order Quantity = MOQ = Smallest quantity of a product
                that can be ordered
              </label>
              <br />
              <label>
                Return on Investment = ROI = Measure of profitability and
                efficiency of an investment
              </label>
              <br />
              <label>
                Just-In-Time = JIT = Production strategy that reduces inventory
                and delivers products exactly when needed
              </label>
              <br />
              <label>
                Business to Consumer = B2C = Business model focused on selling
                directly to consumers
              </label>
              <br />
              <label>
                Business to Business = B2B = Business model focused on selling
                to other businesses
              </label>
              <br />
              <label>
                Free On Board = FOB = Seller pays for transport to port of
                shipment, and buyer takes ownership thereafter
              </label>
              <br />
              <label>
                Total Cost of Ownership = TCO = Overall cost of a product
                including purchase, usage, and maintenance
              </label>
              <br />
              <label>
                Enterprise Resource Planning = ERP = Software for managing
                business operations and processes
              </label>
              <br /> */}
            </div>

            {/* Scrollable textarea */}
            {/* <Form.Control as="textarea" rows={5} style={{ overflowY: 'scroll', height: '150px' }} /> */}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {/* <ButtonComponent onClick={props.onHide} className={'btn-cancle'}>Cancel</ButtonComponent>
                    <ButtonComponent onClick={props.onHide} className={'btn-confirm'} > Confirm</ButtonComponent> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomisationModal;
