import React, { useContext, useState } from "react";
import "./Mywebsite.css";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Product from "../Mywebsite/Product/Product";
import Setting from "../../SidebarComponents/Mywebsite/Setting/Setting";
import Category from "../../SidebarComponents/Mywebsite/Category/Setting";
import WebsiteSetup from "../../SidebarComponents/Mywebsite/WebsiteSetup/Setting";
import FlashSale from "../../SidebarComponents/Mywebsite/FlashSale/Setting";
import Banner from "../../SidebarComponents/Mywebsite/Banner/Setting";
import Content from "../../SidebarComponents/Mywebsite/Content/Setting";
import Orders from "../../SidebarComponents/Mywebsite/Orders/Orders";
import ReturnOrders from "../../SidebarComponents/Mywebsite/ReturnOrders/ReturnOrders";
import LookingFor from "../../SidebarComponents/Mywebsite/LookingFor/Table";
import ContactUsOuterWebsite from "../../SidebarComponents/Mywebsite/ContactUsOuterWebsite/Table";
import QueryManagement from "../../SidebarComponents/Mywebsite/QueryManagement/Table";
import RejectedProducts from "../../SidebarComponents/Mywebsite/RejectedProducts/Table";
import PackageSeller from "./PackageSeller/PackageSeller";
import Dashboard from "./Dashboard/Dashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Policy from "../../SidebarComponents/Mywebsite/Policy/Setting";
import {
  faAddressBook,
  faBagShopping,
  faShoppingBag,
  faBox,
  faContactCard,
  faCreditCard,
  faGear,
  faGift,
  faHeadset,
  faHouse,
  faLaptop,
  faList,
  faTruckFast,
  faTrophy,
  faShield,
  faPersonWalkingDashedLineArrowRight,
  faTentArrowTurnLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faFirstOrder } from "@fortawesome/free-brands-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Context } from "../../../../../utils/context";

const Mywebsite = () => {
  const renderTooltip = (name) => (props) =>
  (
    <Tooltip id="button-tooltip" {...props}>
      {name}
    </Tooltip>
  );

  const closeSidebar = () => {
    if (!sidebar) {
      setSidebar(true);
    }

    setOuterSidebar(false);
    setInnerSidebar(true);
  };

  const {
    sidebar,
    setSidebar,
    outerSidebar,
    setOuterSidebar,
    innerSidebar,
    setInnerSidebar,
    domainData, setDomainData
  } = useContext(Context);

  return (
    <>
      <section className="Mywebsite">
        <div className="mywebsiteMain">
          <Tab.Container id="left-tabs-example" defaultActiveKey="10">
            <Row>
              <div
                // sm={1}
                // className={`  scale-up-hor-left ${
                //   sidebarclosed ? "col-width-expand" : "col-width"
                // }`}

                className={`scale-up-hor-left ${!outerSidebar
                  ? "col-width-expand col-xxl-2 col-xl-3 col-md-3"
                  : "sidebar-adjust col-width col-xxl-1 col-xl-1 col-md-1"
                  }`}
              >
                <div className="websitestab scale-up-hor-left">
                  <Nav variant="pills" className="flex-column">
                    {/* Dashboard */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Dashboard")}
                    >
                      <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="10">Dashboard</Nav.Link> */}
                        <Nav.Link eventKey="10">
                          <FontAwesomeIcon icon={faHouse} />{" "}
                          <span className="name-text">Dashboard</span>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* Product */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Product")}
                    >
                      <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="1">Product</Nav.Link> */}
                        <Nav.Link eventKey="1">
                          {" "}
                          <FontAwesomeIcon icon={faBox} />{" "}
                          <span className="name-text">Product</span>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* Setting */}
                    {/*     <Nav.Item onClick={closeSidebar}>
                      <Nav.Link eventKey="2">Setting</Nav.Link>
                      <Nav.Link eventKey="2">  <FontAwesomeIcon icon={faGear} /></Nav.Link>
                    </Nav.Item> */}

                    {/* Category */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Category Section")}
                    >
                      <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="3">Category Section</Nav.Link> */}
                        <Nav.Link eventKey="3">
                          <FontAwesomeIcon icon={faList} />{" "}
                          <span className="name-text">Category Section</span>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* Banner Section */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Banner Section")}
                    >
                      <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="4">Banner Section</Nav.Link> */}
                        <Nav.Link eventKey="4">
                          <FontAwesomeIcon icon={faCreditCard} />{" "}
                          <span className="name-text">Banner Section</span>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* Content Section */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Content Section")}
                    >
                      <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="5">Content Section</Nav.Link> */}
                        <Nav.Link eventKey="5">
                          <FontAwesomeIcon icon={faAddressBook} />{" "}
                          <span className="name-text">Content Section</span>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* Website Setup */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Website Setup")}
                    >
                      <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="6">Website Setup</Nav.Link> */}
                        <Nav.Link eventKey="6">
                          <FontAwesomeIcon icon={faLaptop} />{" "}
                          <span className="name-text">Website Setup</span>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* Orders */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Orders")}
                    >
                      <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="7">Orders</Nav.Link> */}
                        <Nav.Link eventKey="7">
                          <FontAwesomeIcon icon={faBagShopping} />{" "}
                          <span className="name-text">Orders</span>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Return Orders")}
                    >
                      <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="7">Orders</Nav.Link> */}
                        <Nav.Link eventKey="13">
                          <FontAwesomeIcon icon={faTentArrowTurnLeft} />{" "}
                          <span className="name-text">Return Orders</span>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* InQuiry */}
                    {/*     <Nav.Item onClick={closeSidebar}>
                      <Nav.Link eventKey="8">InQuiry</Nav.Link>
                           <Nav.Link eventKey="8"><FontAwesomeIcon icon={faServicestack} /></Nav.Link>
                    </Nav.Item> */}

                    {/* Looking For */}
                    {/*     <Nav.Item onClick={closeSidebar}>
                      <Nav.Link eventKey="9">Looking For</Nav.Link>
                      <Nav.Link eventKey="9"><FontAwesomeIcon icon={faEye} /></Nav.Link>
                    </Nav.Item>  */}

                    {/* Contact Us */}
                    {domainData?.sections?.includes("contact us") && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 200, hide: 0 }}
                        overlay={renderTooltip("Contact Us")}
                      >
                        <Nav.Item onClick={closeSidebar}>
                          {/* <Nav.Link eventKey="8">Contact Us</Nav.Link> */}
                          <Nav.Link eventKey="8">
                            <FontAwesomeIcon icon={faHeadset} />{" "}
                            <span className="name-text">Contact Us</span>
                          </Nav.Link>
                        </Nav.Item>
                      </OverlayTrigger>
                    )}

                    {/* Rejected Products */}
                    {/*     <Nav.Item onClick={closeSidebar}>
                      <Nav.Link eventKey="7">Rejected Products</Nav.Link>
                       <Nav.Link eventKey="7"><FontAwesomeIcon icon={faHReject} /></Nav.Link>
                    </Nav.Item> */}

                    {/* Packages */}
                    {/* <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Packages")}
                    >
                      <Nav.Item>
                       
                        <Nav.Link eventKey="9">
                          <FontAwesomeIcon icon={faTruckFast} />
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger> */}
                    {domainData?.sections?.includes("flash sale") && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 200, hide: 0 }}
                        overlay={renderTooltip("Flash Sale")}
                      >
                        <Nav.Item onClick={closeSidebar}>
                          {/* <Nav.Link eventKey="8">Contact Us</Nav.Link> */}
                          <Nav.Link eventKey="11">
                            <FontAwesomeIcon icon={faTrophy} />{" "}
                            <span className="name-text">Flash Sale</span>
                          </Nav.Link>
                        </Nav.Item>
                      </OverlayTrigger>
                    )}

                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Policy")}
                    >
                      <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="8">Contact Us</Nav.Link> */}
                        <Nav.Link eventKey="12">
                          <FontAwesomeIcon icon={faShield} />{" "}
                          <span className="name-text">Policy</span>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                  </Nav>
                </div>
              </div>
              <div
                //  sm={11}
                className={`${!outerSidebar
                  ? "col2-width col-xxl-10 col-xl-9 col-md-9"
                  : "col2-width col-xxl-11 col-xl-11 col-md-11"
                  }`}
              >
                <Tab.Content className="tab-content-holder">
                  <Tab.Pane eventKey="1">
                    <Product />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="2">
                    <Setting />
                  </Tab.Pane> */}
                  <Tab.Pane eventKey="3">
                    <Category
                      sidebarclosed={sidebar}
                      setSidebarclosed={setSidebar}
                      outerSidebar={outerSidebar}
                      setOuterSidebar={setOuterSidebar}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="4">
                    <Banner
                      sidebarclosed={sidebar}
                      setSidebarclosed={setSidebar}
                      outerSidebar={outerSidebar}
                      setOuterSidebar={setOuterSidebar}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="5">
                    <Content
                      sidebarclosed={sidebar}
                      setSidebarclosed={setSidebar}
                      outerSidebar={outerSidebar}
                      setOuterSidebar={setOuterSidebar}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="6">
                    <WebsiteSetup
                      sidebarclosed={sidebar}
                      setSidebarclosed={setSidebar}
                      outerSidebar={outerSidebar}
                      setOuterSidebar={setOuterSidebar}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="7">
                    <Orders
                      sidebarclosed={sidebar}
                      setSidebarclosed={setSidebar}
                      outerSidebar={outerSidebar}
                      setOuterSidebar={setOuterSidebar}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="13">
                    <ReturnOrders
                      sidebarclosed={sidebar}
                      setSidebarclosed={setSidebar}
                      outerSidebar={outerSidebar}
                      setOuterSidebar={setOuterSidebar}
                    />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="8">
                    <QueryManagement />
                  </Tab.Pane>
                  <Tab.Pane eventKey="9">
                    <LookingFor />
                  </Tab.Pane> */}
                  <Tab.Pane eventKey="8">
                    <ContactUsOuterWebsite
                      sidebarclosed={sidebar}
                      setSidebarclosed={setSidebar}
                      outerSidebar={outerSidebar}
                      setOuterSidebar={setOuterSidebar}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="10">
                    <Dashboard
                      sidebarclosed={sidebar}
                      setSidebarclosed={setSidebar}
                      outerSidebar={outerSidebar}
                      setOuterSidebar={setOuterSidebar}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="11">
                    <FlashSale
                      sidebarclosed={sidebar}
                      setSidebarclosed={setSidebar}
                      outerSidebar={outerSidebar}
                      setOuterSidebar={setOuterSidebar}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="12">
                    <Policy
                      sidebarclosed={sidebar}
                      setSidebarclosed={setSidebar}
                      outerSidebar={outerSidebar}
                      setOuterSidebar={setOuterSidebar}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Row>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default Mywebsite;
