import React, { useState, useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
import "./HomeBanner.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { HomeBannerGet } from "../../../../utils/apis/myWebsite/mywebsite";
import { Context } from "../../../../utils/context";
const HomeBanner = () => {


    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const BannerSwiperDetails = [
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/HomePage/Mywebsite-Banner.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/HomePage/Mywebsite-Banner.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/HomePage/Mywebsite-Banner.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/HomePage/Mywebsite-Banner.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/HomePage/Mywebsite-Banner.png",
        },
    ];

    const { IMG_URL } = useContext(Context);

    
    const [bannerData, setBannerData] = useState();
    const getBannerData = async () => {

        const res = await HomeBannerGet();
        if (res?.success) {
            setBannerData(res?.data);
        }
    }

    useEffect(() => {
        getBannerData();
    }, [])

    return (
        <>
            <section className="Home-Banner-Section">
                <div className="Banner-Swiper">
                    <Row className="me-0 ms-0">
                        <Col xxl={12} className="p-0">
                            <Swiper
                                className="mySwiper"
                                onSwiper={(swiper) => setSwiperInstance(swiper)}
                                slidesPerView={"auto"}
                                modules={[Navigation, Autoplay]}
                            >
                                {/* {BannerSwiperDetails?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="gallery-imgs">
                                            <img src={item?.image} className="inner-img" alt="Sofa" />
                                        </div>
                                    </SwiperSlide>
                                ))} */}
                                {bannerData?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="gallery-imgs">
                                            <img src={IMG_URL + item?.image} className="inner-img" alt="Sofa" />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="slider-btn">
                                <div className="back-btn" onClick={() => handleMainSliderClick("next")}></div>
                                <div className="next-btn" onClick={() => handleMainSliderClick("prev")}></div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="Providing-Plans">
                    <Container>
                        <div className="Provide-Plans-Background">
                            <div className="Plan">
                                <div className="main-blue">
                                    <div className="blue-circle">
                                        <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/HomePage/web-design (1).png"} className="icon" />
                                    </div>
                                    <p className="sub-title">Free Web Design Demo</p>
                                </div>
                                <div className="border-plans"></div>
                                <div className="main-blue">
                                    <div className="blue-circle">
                                        <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/HomePage/Group 26122.png"} className="icon" />
                                    </div>
                                    <p className="sub-title">Discover Our Pricing Plans</p>
                                </div>
                                <div className="border-plans"></div>
                                <div className="main-blue">
                                    <div className="blue-circle">
                                        <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/HomePage/briefcase.png"} className="icon" />
                                    </div>
                                    <p className="sub-title">Browse Our Portfolio</p>
                                </div>
                                <div className="border-plans"></div>
                                <div className="main-blue">
                                    <div className="blue-circle">
                                        <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/HomePage/Group 26126.png"} className="icon" />
                                    </div>
                                    <p className="sub-title">Get in Touch with Us</p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </section>
        </>
    );
};

export default HomeBanner;

