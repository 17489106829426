import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const sellerPercentageSend = {
  stepOne: 0,
  stepTwo: 10,
  stepThree: 40,
  stepFour: 60,
  stepFive: 70,
  stepSix: 80,
  stepseven: 90,
};

export const sellerPercentage = [0, 10, 20, 40, 60, 70, 80];

export const sellerLogin = async (data) => {
  try {
    const res = await postData(`/seller/seller-login/login`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSellerDetails = async (user_id) => {
  try {
    const res = await getData(`/seller/seller-create/s-over-view/${user_id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const sellerPersonalDetailsPost = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-create/s-personal-details`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSellerPersonalDetails = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-create/s-personal-details/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSectorCategory = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-create/s-sector-category/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmDetailsPost = async (data) => {
  try {
    const res = await postData(`/seller/seller-create/s-firm-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getfirmDetails = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-create/s-firm-details/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmDetailNamePost = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-create/s-firm-details/name`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getFirmDetailName = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-create/s-firm-details/name/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmAddressDetailPost = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-create/s-firm-address-details`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmAddressDetail = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-create/s-firm-address-details/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmDropAddressPost = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-create/s-firm-drop-address`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmDropAddress = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-create/s-firm-drop-address/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmPickupAddressPost = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-create/s-firm-pickup-address`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmPickupAddress = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-create/s-firm-pickup-address/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmBankDetailPost = async (data) => {
  try {
    const res = await postData(`/seller/seller-create/s-bank-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmBankDetail = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-create/s-bank-details/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmPasswordPost = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-create/s-personal-details/password`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getSellerOverview = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-create/s-over-view/category/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const finalPost = async (data) => {
  try {
    const res = await postData(`/seller/seller-create/s-over-view`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
