import React, { useState } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import './ViewRequestedQuote.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonComponent from '../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent'
import ViewRequestedModal from '../../common-components/ViewRequestedModal/ViewRequestedModal'

const ViewQuotes = () => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <section className='view-requested'>
            <div className='first-white-bg'>
                <p className='ganzu-txtt'>Ganzhou Hoyoo Electronic Technology Co., Ltd.</p>
                <div className='d-flex'>
                    <FontAwesomeIcon icon="fa-solid fa-comment" className="chat-icon" />
                    <p className='verified-txt mt-2'><FontAwesomeIcon icon="fa-solid fa-circle-check" className='ms-2 me-1' /> Verified Supplier</p>
                </div>
                <div className='text-end '>
                    <p className='status'>Status :<span className='approved'>Approved</span></p>
                </div>
                <div className='piece-txtt'>Quantity required : 2 Piece/Pieces</div>
                <div className='piece-txtt'>Expiration time:2024-04-17 22:40:57</div>

            </div>
            <Accordion className='mt-4'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Product detailed specifications</Accordion.Header>
                    <Accordion.Body>
                        1
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {/* <div className='brown-border mt-2'></div> */}
            <p className='mt-3 information'>Quotation information</p>
            <div className='brown-border'></div>
            <div className='first-white-bg mt-3'>
                <Row>
                    <Col xs={2}>
                        <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/RFQ/Armchairp.png"} className="pro-img" alt="..." />
                    </Col>
                    <Col xs={10}>
                        <p className='txt-first'>Hot Sale Living Room Single Sofa Chair Armchair High Back Lamb Cashmere Microfiber Fabric Manual Recliner Massage Chair</p>
                    </Col>
                </Row>
            </div>
            <div className='d-flex justify-content-between mt-4'>
                <p className='unit-price'> Unit Price</p>
                <p className='unit-price'>₹10,000 <span className='set-txtt'>/set</span></p>
            </div>
            <div className='brown-border mt-2'></div>
            <Row>
                <Col xxl={12}>
                    <p className='title-producttt mt-4'>Company information</p>
                </Col>
                <Col sm={4}>
                    <p className='cat-name'>Advantages</p>
                </Col>
                <Col sm={8}>
                    <p className='txtt-data'>Finished product inspection, Supplier assessment procedures, On-site material inspection</p>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <p className='cat-name'>Work Experiance</p>
                </Col>
                <Col sm={8}>
                    <p className='txtt-data'>1 Years</p>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <p className='cat-name'>Business type</p>
                </Col>
                <Col sm={8}>
                    <p className='txtt-data'>Multispecialty supplier</p>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <p className='cat-name'>Main products</p>
                </Col>
                <Col sm={8}>
                    <p className='txtt-data'>Furniture Products, Plastic Eco-Friendly Furniture, Manual Functional Sofa, Electric Functional Sofa, Basic Functional Sofa</p>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <p className='cat-name'>Main markets</p>
                </Col>
                <Col sm={8}>
                    <p className='txtt-data'>NA</p>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <p className='cat-name'>Certifications</p>
                </Col>
                <Col sm={8}>
                    <p className='txtt-data'>BSCI</p>
                </Col>
            </Row>
            <div className='d-flex'>
                <ButtonComponent className={'proceed-payment me-1'}>Proceed for payment</ButtonComponent>
                <ButtonComponent className='close-quote-first ' onClick={() => setModalShow(true)}>Close Quote</ButtonComponent>
            </div>

            <ViewRequestedModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </section>

    )
}

export default ViewQuotes