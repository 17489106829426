import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllData = async (currentPage, perPage, search,searchWorking,searchCategary,searchSubCategary,searchChildCategary,searchStatus) => {
  try {
    let url = `/seller/outer-website/masters/attribute?page=${currentPage}&per_page=${perPage}&term=${search}&inputtype=${searchWorking?.label || ""}&category=${searchCategary?.label || ""}&subcategary=${searchSubCategary?.label || ""}&childcategory=${searchChildCategary?.label || ""}`;
    if(searchStatus?.value || searchStatus?.value == 0){
      url += `&status=${searchStatus?.value}`
    }
    return await getData(
      url
    );
  } catch (error) {
    console.log(error);
  }
};

export const changeDataStatus = async (id) => {
  try {
    return await editStatusData(
      `/seller/outer-website/masters/attribute/${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const Delete = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/outer-website/masters/attribute/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const add = async (data) => {
  try {
    return await postData(`/seller/outer-website/masters/attribute`, data);
  } catch (error) {
    console.log(error);
  }
};

export const edit = async (id, data) => {
  try {
    return await postData(
      `/seller/outer-website/masters/attribute/${id}`,
      data
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingledata = async (id) => {
  try {
    return await getData(`/seller/outer-website/masters/attribute/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
