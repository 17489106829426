import React, { useEffect, useState } from "react";
import "./PCS_Services.css";
import { getProcurement_SolutionTwo } from "../../../../utils/apis/btb/Home_Page";

function PCS_Services() {
  const [data, setData] = useState([]);
  const getAllData = async () => {
    setData(await getProcurement_SolutionTwo());
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <div className="PCS_Services">
        <div className="bg-img-holder">
          <img
            className="banner-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assest/images/B2B_panel/Home/PCS_Services/banner_bg_img.png"
            }
            alt="banner-bg-img"
          />
        </div>
        <div className="process-content">
          <div className="container">
            <div className="PCS_Services_content">
              <div className="row ">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div className="girl_img-holder">
                    <img
                      className="girl-img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/B2B_panel/Home/PCS_Services/girl.png"
                      }
                      alt="girl-img"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div className="list-content">
                    <div>
                      <div className="heading-holder">
                        <h4 className="heading">
                          Procurement Solutions and <br></br> Customized Services
                        </h4>
                        <p>
                          Optimize Your Supply Chain Efficiency with Our
                          Tailored Approach
                        </p>
                      </div>

                      <div className="process-list">
                        <ul>
                          {data?.data?.map((data, index) => (
                            <li key={index}>{data?.name}</li>
                          ))}
                          {/* <li>Go to Netpurti.com</li>
                          <li>Make Profile</li>
                          <li>Search Product</li>
                          <li>Submit order request</li>
                          <li>Arrange details with supplier</li>
                          <li>Receive sample order</li>
                          <li>Make payment for order and wait for delivery</li> */}
                        </ul>

                        <div className="vr"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PCS_Services;
