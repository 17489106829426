import React from "react";
import "./Customer_engagement.css";
import { BarChart } from "@mui/x-charts/BarChart";

function Customer_engagement() {
  return (
    <>
      <div className="Customer_engagement">
        <div className="dashboard-box-heading-holder">
          <h4>Customer Engagement</h4>
        </div>

        <div>
          <BarChart
            series={[
              { data: [35, 44, 24, 34] },
              { data: [51, 6, 49, 30] },
              { data: [15, 25, 30, 50] },
            ]}
            height={290}
            xAxis={[
              {
                data: ["Jan - Apr", "May - Aug", "Sept - Dec"],
                scaleType: "band",
              },
            ]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            barWidth={30}
          />
        </div>
      </div>
    </>
  );
}

export default Customer_engagement;
