import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const HomeBannerGet = async () => {
  try {
    const res = await getData(`/my-website/home/banner`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const MaintenanceGet = async () => {
  try {
    const res = await getData(`/my-website/home/feature`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const DesignerGet = async () => {
  try {
    const res = await getData(`/my-website/home/designer`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const HomeReasonsGet = async () => {
  try {
    const res = await getData(`/my-website/home/reason`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const PortfolioGet = async () => {
  try {
    const res = await getData(`/my-website/home/portfolio`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const PortfolioGetData = async () => {
  try {
    const res = await getData(`/my-website/portfolios`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const FAQSGet = async () => {
  try {
    const res = await getData(`/my-website/faqs`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const UpdateInfoGet = async () => {
  try {
    const res = await getData();
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const SubscriptionGet = async () => {
  try {
    const res = await getData("/my-website/pricing/plan");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ResourcesGet = async () => {
  try {
    const res = await getData("/my-website/home/resources");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const BlogsGet = async () => {
  try {
    const res = await getData(`/my-website/blog`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const BlogsSingleGet = async (blog_id) => {
  try {
    const res = await getData(`/my-website/blog/${blog_id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const domainInformationApi = async () => {
  try {
    const res = await getData(`/my-website/masters/domainTutorial`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const domainExist = async (domain) => {
  try {
    const res = await getData(
      `/outer-website/packages/domainexist?domain=${domain}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const CommentsPost = async (data, blog_id) => {
  try {
    const res = await postData(`/my-website/blog/comment/${blog_id}`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const userRegisterPost = async (data) => {
  try {
    const res = await postData(`/my-website/user/register`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const userSignInPost = async (data) => {
  try {
    const res = await postData(`/my-website/user/login`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const userForgotPassPost = async (data) => {
  try {
    const res = await postData(
      `/without-login/verify-otp/email-otp-send`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const resetPassWordPost = async (data) => {
  try {
    const res = await postData(`/my-website/user/reset`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const userCheck = async (data) => {
  try {
    const res = await postData(`/my-website/user/check-user`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
