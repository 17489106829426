import React, { useState } from 'react';
import './SellerViewProduct.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SteponeInfo from './SteponeInfo/SteponeInfo';
import Steptwoprice from './Steptwoprice/Steptwoprice';
import StepthreeDescription from './StepthreeDescription/StepthreeDescription';

function SellerViewProduct() {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <SteponeInfo />;
      case 2:
        return <Steptwoprice />;
      case 3:
        return <StepthreeDescription />;
      default:
        return null;
    }
  };

  return (
    <>
      <section className='main-view-product-stepform'>
        <div className='d-flex view-arrow'>
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " className='me-2' />
          <h3>View Products</h3>
        </div>
        <div className='steps-div'>
          <p className={`steps-name ${step === 1 && 'active'}`}>Product Info</p>
          <p className={`steps-name ${step === 2 && 'active'}`}>Price, Stock & Shipping Information</p>
          <p className={`steps-name ${step === 3 && 'active'}`}>Product Description</p>
        </div>
        <hr className='my-0 mx-3'/>

        {renderStep()}

        <div className='common-steps-btn'>
          {step !== 1 && (
            <button onClick={prevStep} className='back-btn'>Back</button>
          )}
          {step !== 3 ? (
            <button onClick={nextStep} className='next-btn'>Next</button>
          ) : (
            <button className='next-btn'>OK</button>
          )}
        </div>
      </section>
    </>
  );
}

export default SellerViewProduct;
