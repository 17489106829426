import React, { useEffect, useState, useContext } from "react"
import "../MyProfile.css";
import { Scrollchor } from 'react-scrollchor';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import EditPersonalDetailsModal from "../../../common-components/AllModal/Editpersonal-Detailmodal/EditPersonalDetailsModal";
import WorkingTypeModal from "../../../common-components/AllModal/Editpersonal-Detailmodal/WorkingTypeModal";
import { faUser, faBriefcase, faIndustry, faFilm, faLock, faChevronRight, faPen, faEye } from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../../../../utils/api";
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
library.add(fas);

const FirstModal = () => {
    const [activeTab, setActiveTab] = useState("");
    const [modalEditDetails, setModalEditDetails] = useState(false);
    const { IMG_URL, Id } =
        useContext(Context);
    console.log("ID:---", Id);


    useEffect(() => {
        if (activeTab) {
            const element = document.getElementById(activeTab.slice(1)); // Remove '#' from activeTab
            if (element) {
                const yOffset = -80;
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: "smooth" });
            }
        }
    }, [activeTab]);

    const handleTabClick = (id) => {
        setActiveTab(id);
    };

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm();



    const getAllData = async () => {
        try {
            const res = await getData(`/btb/btb-create/btb-personal-details/${Id}`);
            reset(res?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(" getValues()", getValues());

    useEffect(() => {
        getAllData();
    }, [Id]);

    return (
        <>
            <div id="sample-code1">
                <div className="card b2bprofileCard">
                    <div className="card-body">
                        <div className="personalDetail">
                            <h4>Personal Details</h4>
                        </div>
                        <div className="row">
                            <div className=" col-xxl-2 col-xl-3 col-lg-3  col-md-3">
                                <div className="Innernamee">
                                    <p>First Name</p>
                                    <h3>{getValues("f_name")}</h3>
                                </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3 col-lg-3  col-md-3">
                                <div className="Innernamee">
                                    <p>Last Name</p>
                                    <h3>{getValues("l_name")}</h3>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-3">
                                <div className="Innernamee">
                                    <p>Contact Number</p>
                                    <h3>+{getValues("contact_no")}</h3>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                                <div className="Innernamee">
                                    <p>Email</p>
                                    <h3>{getValues("email")}</h3>
                                </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3 col-lg-3  col-md-3">
                                <div className="Innernamee">
                                    <p>Designation</p>
                                    <h3>{getValues("designation")}</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                    <button className="edit-btn" onClick={() => { setModalEditDetails(true) }}><FontAwesomeIcon icon={faPen} /></button>
                </div>
            </div>

            <EditPersonalDetailsModal
                data={getValues()}
                show={modalEditDetails}
                onHide={() => { setModalEditDetails(false); getAllData() }}
            />
        </>
    )
}

export default FirstModal








