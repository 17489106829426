import React from "react";
import HomeBanner from "./HomeBanner/HomeBanner";
import HomeReason from "./HomeReason/HomeReason";
import Maintenance from "./Maintenance/Maintenance";
import WebsiteDesigner from "./WebsiteDesigner/Website-Designer";
import DemoCard from "./DemoCard/DemoCard";
import MyPortfolio from "./MyPortfolio/MyPortfolio";
import UpdateInformation from "./UpdateInformation/UpdateInformation";
import PlanPricing from "../Pricing/PlanPricing/PlanPricing";
import NewPlanPricing from "../Pricing/NewPlanPricing/NewPlanPricing";

const Home = () => {
    return (
        <>
            <HomeBanner />
            <Maintenance />
            <WebsiteDesigner />
            <HomeReason />
            <MyPortfolio />
            <DemoCard />
            {/* <PlanPricing /> */}
            {/* <NewPlanPricing/> */}
            <UpdateInformation />
        </>
    );
};

export default Home;
