import React from 'react'
import CompareAll from '../../Pricing/CompareAll/CompareAll'
import { Breadcrumb, Container } from 'react-bootstrap'
import './MyPackage.css'
import NewPlanPricing from '../../Pricing/NewPlanPricing/NewPlanPricing'

const MyPackage = () => {
    return (
        <section className='my-package'>
            <Container>
                <div className='text-center'>
                    <h3 className='maintenance-title'>My Packages</h3>
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/mywebsites/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>My Packges</Breadcrumb.Item>
                </Breadcrumb>
                <NewPlanPricing/>
                <CompareAll />
            </Container>
        </section>
    )
}

export default MyPackage