import React from 'react'
import { useState, useContext, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import './Report_flag_modal.css';
import SuccessModal from '../../../../../CommonForAll/SuccessModal/SuccessModal';
import { Context } from '../../../../../../utils/context';
const Report_flag_modal = (props) => {
    const [show, setShow] = React.useState(false);    
    const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        setValue,
        clearErrors,
        formState: { errors },
        reset,
    } = useForm();
    const onSubmit = async (d) => {
        console.log(d);
        const data = {
            strick_reason_id: d.reasonid,
            seller_task_id: props.taskId,

        };
        const res = await postData('/seller/seller-task/influncer-strick ', data);
        if (res?.success) {
            props.onHide();
            props.getAdvertisement();
            reset();
            setSuccessModalShow(true);
            setTimeout(() => {
                setSuccessModalShow(false);
            }, 2000);
        }
    }
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Report_flag_modal_sec'

            >
                <Modal.Header closeButton>
                    <Modal.Title>Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='form-group'>
                            {props.reasons?.map((val, index) => (
                                <Form.Check
                                    {...register("reasonid", {
                                        required: "Reason is required"
                                    })}
                                    label={val?.name}
                                    name="reasonid"
                                    type="radio"
                                    id="1"
                                    value={val?.id}
                                    checked
                                />
                            ))}
                            {/* <Form.Check onClick={() => setShow(!show)} label="Other" name="1" type="radio" id="6" />
                            {show && <Form.Control placeholder='Enter Other Reson' />} */}

                        </div>
                        <button className='submit-btn btn'> Submit</button>
                    </Form>
                </Modal.Body>

                {/* <Modal.Footer>
                    <Button variant="secondary" >
                        Close
                    </Button>
                    <Button variant="primary" >
                        Save Changes
                    </Button>
                </Modal.Footer> */}

            </Modal>
            <SuccessModal show={successModalShow} />
        </>
    )
}

export default Report_flag_modal