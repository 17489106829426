import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./Logout_modal.css";
import Cookies from "js-cookie";
import { ProductDelete } from "../../../utils/apis/seller/Product";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";

const Logout_modal = (props) => {
  const { setSignin, setUsertype ,deleteData} = useContext(Context);
  const navigate = useNavigate();

  const deleteProduct = async () => {
    const res = await deleteData(`/seller/seller-task/task/${props.editId}`);
    if (res?.success) {
      props.onHide();
      props.getAllProducts();
      // setAllData(res.data?.data)
    }
  };
  return (
    <>
      <section className="Logout_modal">
        <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="Logout_modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pt-0">
            <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to delete this product?
            </Modal.Title>

            <div className="row mt-4">
              <div className="col-lg-6">
                <div className="text-center">
                  <button
                    type="button"
                    className="mb-2 Cancelbtn"
                    onClick={props.onHide}
                  >
                    Cancel{" "}
                  </button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="text-center">
                  <button
                    type="button"
                    className="mb-2 logoutdbtn"
                    onClick={deleteProduct}
                  >
                    {/* {props.btnname} */}
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default Logout_modal;
