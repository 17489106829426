import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
// import Tab from "react-bootstrap/Tab";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import Brand from "../../Master/Brand/Tables";
import FAQ from "../../Master/Faq/Table";
import SubFaq from "../../Master/SubFaq/Table";
// import WorkingType from "../Master/WorkingType/Table";
import Category from "../../Master/Category/Table";
import SubCategory from "../../Master/SubCategory/Table";
import ChildCategory from "../../Master/ChildCategory/Table";
import ChildSubCategory from "../../Master/ChildSubCategory/Table";
import Blogs from "../../Master/Blogs/Tables";
// import CouponCodes from "../../Master/CouponCodes/Table";
// import TaxType from "../Master/Tax_Types/Tables";
// import TaxPercentage from "../Master/Tax_Percentage/Tables";
import WebHeader from "../../Master/WebHeader/Tables";
import AppSetup from "../../Master/AppSetup/Tables";
// import ContactUs from "../Master/contact_us/Tables";
import Users from "../../Master/users/Tables";
import CrazyDeals from "../../Master/CrazyDeals/Table";
import ExclusiveCollection from "../../Master/ExclusiveCollection/Table";
import Attribute from "../../Master/Attribute/Table";
import SubAttribute from "../../Master/SubAttribute/Table";
import AttributePackaging from "../../Master/AttributePackaging/Table";
import ShopTrends from "../../Master/ShopTrends/Table";
import HomePageBanner from "../../Master/HomePageBanner/Table";
import AboutUsBanner from "../../Master/banner/Tables";
import OurStory from "../../Master/OurStory/Tables";
import HappyCustomer from "../../Master/HappyCustomer/Tables";
import WhyChoose from "../../Master/whyChoose/Tables";
import PopularProduct from "../../Master/PopularProduct/Table";
import SaleBanner from "../../Master/SaleBanner/Table";
import CategoryBanner from "../../Master/CategoryBanner/Table";
import UserMotivation from "../../Master/UserMotivation/Table";
import SocialMedia from "../../Master/SocialMedia/Table";

import "./Setting.css";

const Setting = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(1);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const tabsArray = [
    { key: 0, name: "Brand", link: "masters/brand" },
    { key: 1, name: "FAQ", link: "masters/faq" },
    { key: 2, name: "Sub FAQ", link: "masters/sub-faq" },
    { key: 3, name: "Category", link: "masters/category" },
    { key: 4, name: "Sub Category", link: "masters/sub-category" },
    { key: 5, name: "Child Category", link: "masters/child-category" },
    // { key: 6, name: "Child Sub Category", link: "masters/child-sub-category" },

    { key: 6, name: "Blogs", link: "masters/blogs" },
    { key: 7, name: "Web Header", link: "masters/web-header" },
    { key: 8, name: "App Setup", link: "masters/app-setup" },
    { key: 9, name: "Users", link: "masters/users" },

    { key: 10, name: "Crazy Deals", link: "masters/crazy-deals" },
    {
      key: 11,
      name: "Exclusive Collection",
      link: "masters/exclusive-collections",
    },
    {
      key: 12,
      name: "Attribute",
      link: "masters/attribute",
    },
    {
      key: 13,
      name: "Sub Attribute",
      link: "masters/sub-attribute",
    },
    {
      key: 14,
      name: "Attribute Packaging",
      link: "masters/attribute-packaging",
    },

    { key: 15, name: "About Us Banner", link: "masters/about-us-banner" },
    { key: 16, name: "Our Story", link: "masters/about-our-story" },
    { key: 17, name: "Happy Customer", link: "masters/happy-customer" },
    { key: 18, name: "Why Choose Us ?", link: "masters/why-choose" },
    {
      key: 19,
      name: "Shop Trends",
      link: "masters/shop-trends",
    },

    {
      key: 20,
      name: "HomePage Banner",
      link: "masters/homepage-banner",
    },

    {
      key: 21,
      name: "Popular product",
      link: "masters/popular_product",
    },
    {
      key: 22,
      name: "Sale Banner",
      link: "masters/sale-banner",
    },
    {
      key: 23,
      name: "Category Banner",
      link: "masters/category-banner",
    },
    {
      key: 24,
      name: " User Motivation",
      link: "masters/user-motivation",
    },
    {
      key: 25,
      name: " Social Media",
      link: "masters/social-media",
    },
  ];

  const tabContent = [
    { key: 0, component: <Brand /> },
    { key: 1, component: <FAQ /> },
    { key: 2, component: <SubFaq /> },
    { key: 3, component: <Category /> },
    { key: 4, component: <SubCategory /> },
    { key: 5, component: <ChildCategory /> },
    // { key: 6, component: <ChildSubCategory /> },
    { key: 6, component: <Blogs /> },
    { key: 7, component: <WebHeader /> },
    { key: 8, component: <AppSetup /> },
    { key: 9, component: <Users /> },
    { key: 10, component: <CrazyDeals /> },
    { key: 11, component: <ExclusiveCollection /> },
    { key: 12, component: <Attribute /> },
    { key: 13, component: <SubAttribute /> },
    { key: 14, component: <AttributePackaging /> },
    { key: 15, component: <AboutUsBanner /> },
    { key: 16, component: <OurStory /> },
    { key: 17, component: <HappyCustomer /> },
    { key: 18, component: <WhyChoose /> },
    { key: 19, component: <ShopTrends /> },
    { key: 20, component: <HomePageBanner /> },
    { key: 21, component: <PopularProduct /> },
    { key: 22, component: <SaleBanner /> },
    { key: 23, component: <CategoryBanner /> },
    { key: 24, component: <UserMotivation /> },
    { key: 25, component: <SocialMedia /> },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const onTabClick = (e, index) => {
    console.log(e);
    setActiveTab(index);
  };
  // const tabsArray = [...Array(20).keys()];

  return (
    <>
      <Tabs activeTab={activeTab} onTabClick={onTabClick}>
        {tabsArray.map((item, index) => (
          <Tab key={item.eventKey} eventKey={item.key}>
            {item.name}
          </Tab>
        ))}
      </Tabs>

      {tabContent.map((item) => (
        <TabScreen key={item.key} activeTab={activeTab} index={item.key}>
          {item.component}
        </TabScreen>
      ))}
    </>
  );
};

export default Setting;
