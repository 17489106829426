import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import classNames from "classnames";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import { Context } from "../../../../utils/context";
import { Errors, Placeholders } from "../../../../utils/errors";
import {
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../utils/apis/master/Master";

import {
  CheckerServiceAreaPost,
  getCheckerServiceArea,
} from "../../../../utils/apis/checker/checker";

const Stepsix = ({ nextStep, prevStep, bankID, checkerDetails }) => {
  const [modalShow, setModalShow] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [pincodeOptions, setPincodeOptions] = useState([]);
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const checkerID = checkerDetails.c_personal_details_id;

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (serviceID !== "") {
      formData.append("id", serviceID);
    }
    formData.append("country_id", data.country_id?.value);
    formData.append("city_id", data.city_id?.value);
    formData.append("state_id", data.state_id?.value);
    formData.append(
      "pincode_id",
      JSON.stringify(
        data.pincode_id?.map((item) => {
          return item?.value;
        })
      )
    );
    formData.append("c_personal_details_id", checkerID);
    // formData.append("media_bank_details_id", bankID);

    const res = await CheckerServiceAreaPost(formData);
    if (res?.success) {
      console.log(res, "success");
      nextStep();
    }
  };

  const [serviceID, setServiceID] = useState("");

  const getMediaServiceDetails = async () => {
    const res = await getCheckerServiceArea(checkerID);
    if (res?.success) {
      console.log("Service Details:-", res);
      setServiceID(res.data?.id);
      await reset(res.data);
      await getStates(res?.data?.country_id);
      await getCites(res?.data?.state_id);
      await getPincodes(res?.data?.city_id);

      await setValue("country_id", {
        value: res?.data?.country?.id,
        label: res?.data?.country?.name,
      });
      await setValue("state_id", {
        value: res?.data?.state?.id,
        label: res?.data?.state?.name,
      });
      await setValue("city_id", {
        value: res?.data?.city?.id,
        label: res?.data?.city?.name,
      });
      await setValue(
        "pincode_id",
        res?.data?.media_service_pincodes?.map((item) => ({
          value: item?.pincode?.id,
          label: item?.pincode?.name,
        }))
      );
    }
  };

  const getMasters = async () => {
    const res = await Country();
    if (res?.success) {
      const data = await Select2Data(res.data, "country_id", false);
      setCountryOptions(data);
    }
  };

  const getStates = async (country_id) => {
    const res = await State(country_id);
    if (res?.success) {
      const data = await Select2Data(res.data, "state_id", false);
      setStateOptions(data);
      setValue("state_id", "");
      setValue("city_id", "");
      setValue("pincodeI_id", "");
    }
  };

  const getCites = async (state_id) => {
    const res = await City(state_id);
    if (res?.success) {
      const data = await Select2Data(res.data, "city_id", false);
      setCityOptions(data);
      setValue("city_id", "");
      setValue("pincode_id", "");
    }
  };

  const getPincodes = async (id) => {
    const res = await Pincode(id);
    if (res?.success) {
      const data = await Select2Data(res.data, "pincode_id", false);
      setPincodeOptions(data);
      setValue("pincode_id", "");
    }
  };

  console.log(countryOptions);

  useEffect(() => {
    getMediaServiceDetails();
    getMasters();
  }, []);
  return (
    <>
      <div className="form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h6 className="ms-3 firmmmdd">Service Area</h6>
          <div className="tab-form-container firstthreerrr">
            <div className="row">
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Country</Form.Label>
                  <Controller
                    name="country_id" // name of the field
                    {...register("country_id", {
                      required: "Country is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.country_id ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={countryOptions}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption?.value); // Update Controller's value
                          getStates(selectedOption?.value);
                          setValue("country_id", selectedOption);
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>State</Form.Label>
                  <Controller
                    name="state_id" // name of the field
                    {...register("state_id", {
                      required: "State is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.state_id ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={stateOptions}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption?.value); // Update Controller's value
                          getCites(selectedOption?.value);
                          setValue("state_id", selectedOption);
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>City</Form.Label>
                  <Controller
                    name="city_id" // name of the field
                    {...register("city_id", {
                      required: "City is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.city_id ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={cityOptions}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption?.value); // Update Controller's value
                          getPincodes(selectedOption?.value);
                          setValue("city_id", selectedOption);
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>
                    Pincode <span>(It can be multiple)</span>
                  </Form.Label>
                  <Controller
                    name="pincode_id" // name of the field
                    {...register("pincode_id", {
                      required: "Pincodes is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.pincode_id ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={pincodeOptions}
                        isMulti
                      />
                    )}
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="text-end btnMainn apply_now_btn">
              {/* <p onClick={() => setModalShow(true)}>
              By continuing, I agree to Netpurti's
              <span className="termstxt">Terms of Use & Privacy Policy</span>
            </p> */}
              <div className="registerContinueMain">
                <Button onClick={prevStep} className="back-btn">
                  Back
                </Button>
              </div>

              <div className="registerContinueMain">
                <Button
                  type="submit"
                  // onClick={nextStep}
                  className="tabs-btn"
                >
                  Register & Continue
                </Button>
              </div>
            </div>

            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default Stepsix;
