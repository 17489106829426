import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const userForgotPassPost = async (data) => {
  try {
    const res = await postData(
      `/without-login/verify-otp/email-otp-send`,
      data
    );



    
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const resetPassWordPost = async (data) => {
  try {
    const res = await postData(`/btb/auth/reset`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const userCheck = async (data) => {
  try {
    const res = await postData(`/btb/auth/check-user`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
