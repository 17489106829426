import React, { useState } from "react";
import "./MyWishlist.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PortfolioCards from "../../CommonComponents/PortfolioCards/PortfolioCards";
import BannerCommon from "../../CommonComponents/BannerCommon/BannerCommon";
import Button from "react-bootstrap/Button";
const MyWishlist = () => {
    const [showAll, setShowAll] = useState(false);
    const [numToShow, setNumToShow] = useState(4);
    const MyWishlistCards = [
        {
            images:
                process.env.PUBLIC_URL +
                "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/my-website-mokup.png",
            cardtitle: "Stag- Personal Portfolio",
            css: "height:200px; width:200px;",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/2.png",
            cardtitle: "Stag- Personal Portfolio",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/1.png",
            cardtitle: "Stag- Personal Portfolio",
        },
        {
            images:
                process.env.PUBLIC_URL +
                "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/my-website-mokup.png",
            cardtitle: "Stag- Personal Portfolio",
            css: "height:200px; width:200px;",
        },
        {
            images:
                process.env.PUBLIC_URL +
                "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/my-website-mokup.png",
            cardtitle: "Stag- Personal Portfolio",
            css: "height:200px; width:200px;",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/2.png",
            cardtitle: "Stag- Personal Portfolio",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/1.png",
            cardtitle: "Stag- Personal Portfolio",
        },
        {
            images:
                process.env.PUBLIC_URL +
                "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/my-website-mokup.png",
            cardtitle: "Stag- Personal Portfolio",
            css: "height:200px; width:200px;",
        },
    ];
    const toggleShowAll = () => {
        setShowAll(!showAll);
        if (showAll) {
           
            setNumToShow(4);
        } else {
           
            setNumToShow(MyWishlistCards.length); 
        }
    };
    return (
        <>
            <section className="My-Wishlist">
            <BannerCommon heading={"My Wishlist"} content={"My Wishlist"} />
                <div className="Wishlist-Cards">
                    <div className="container">
                        <Row className="justify-content-center">
                            <Col xxl={10} xl={11} lg={9} md={9}>
                                <Row className="mt-3">
                                    {MyWishlistCards.slice(0, numToShow).map((item, index) => (
                                        <Col xxl={3} xl={3} lg={6} md={6} sm={6} xs={12} key={index}>
                                            <PortfolioCards images={item.images} cardtitle={item.cardtitle} />
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                        <div className="Show-More">
                                {(numToShow <MyWishlistCards.length || showAll) && (
                                    <Button variant="primary" className="show" onClick={toggleShowAll}>{showAll ? "Show Less" : "Show More"}</Button>
                                   
                                )}
                            </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MyWishlist;
