import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./BestSellersCategories.css";
import React, { useContext, useEffect, useState } from "react";
import Furniture from "./Furniture/Furniture";
import Beauty from "./Beauty/Beauty";
import { Context } from "../../../../utils/context";
import { getBestSellersCategories } from "../../../../utils/apis/btb/Home_Page";
import { Helmet } from "react-helmet";

function BestSellersCategories() {
  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState()

  const getData = async () => {
    setData(await getBestSellersCategories());
  };


  useEffect(() => {

    if (data?.data?.length > 0) {

      const fetchCategoryName = data?.data?.map((item) => {
        return item?.name
      })
      setMetaData(fetchCategoryName)
    }

  }, [data])

  console.log("metaData", data?.data,metaData)

  useEffect(() => {
    getData();
  }, []);
  return (
    <>

      <Helmet>
        <title>Categories With Wide Range.</title>
        <meta name="description" content="Categories With Wide Range" />
        <meta name="og:url" content={window.location.href} />
        <meta name="og:type" content={"category"} />
        <meta name="keywords" content={metaData?.join(', ')} />
      </Helmet>


      <div className="BestSellersCategories">
        <div className="container">
          <div className="heading-holder">
            <h4 className="heading">Best Sellers Categories</h4>
            <div className="subtitle-holder">
              <p>Browse our diverse array of product</p>
              {/* <p>
                See all categories
                <FontAwesomeIcon
                  className="ms-2"
                  icon="fa-solid fa-angle-right"
                />
              </p> */}
            </div>
          </div>
          {data?.data?.map((data, index) => (
            <div className="BestSellersCategories-slider-sec">
              <Furniture data={data} IMG_URL={IMG_URL} />
            </div>
          ))}

          {/* <div className="BestSellersCategories-slider-sec">
            <Beauty />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default BestSellersCategories;
