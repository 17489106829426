import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const allProductCounter = async (data) => {
  try {
    const res = await getData(`/btb/dashboards/product`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const allProductOrder = async (data) => {
  try {
    const res = await getData(`/btb/dashboards/order`);

    return res;
  } catch (error) {
    console.log(error);
  }
};


export const allMainDashboeard = async (data) => {
  try {
    const res = await getData(`/btb/dashboards`);

    return res;
  } catch (error) {
    console.log(error);
  }
};


export const allBarChart = async (data) => {
  try {
    const res = await getData(`/btb/dashboards/bar-chart`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const allBarCategory = async (data) => {
  try {
    const res = await getData(`/btb/dashboards/category`);

    return res;
  } catch (error) {
    console.log(error);
  }
};