import React, { useState } from 'react'
import HelpSlider from '../../../HelpCenter/HelpSlider/HelpSlider'
import { Form } from 'react-bootstrap';
import './MakePaymentWallet.css'
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InquiryModal from '../../../common-components/AllModal/inquiry-Modal/InquiryModal';
import { InputGroup } from 'react-bootstrap';
const MakePaymentWallet = () => {
    const [showMoney, setShowMoney] = useState(false);
    const handleClose = () => setShowMoney(false);
    const openSetModal = () => {
        setShowMoney(true);
        setTimeout(() => {
            window.location = "/b2bbuyer/my-wallet"
            setShowMoney(false);
        }, 3000);
    };
    const uplodimg = [

        "/assest/images/B2Bbuyer/HelpCenter/add.png",
        "/assest/images/B2Bbuyer/HelpCenter/add.png"
    ];
    return (
        <>
            <section className='ShoppingCart-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xxl-9 col-xl-9 col-lg-8 col-md-12 col-12'>
                            <div className='heading'>
                                <h1>Make Payment</h1>
                            </div>
                            <div className='payment-sec  '>
                                <div className='my-3'>
                                    <Form.Check
                                        label='Add a new card'
                                        name='delivery'
                                        type='radio'
                                    />
                                    <div className='ms-4'>
                                        <img className="header-logo"
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/assest/images/B2Bbuyer/icons/visa.png"
                                            }
                                            alt="Logo"
                                        />
                                        <img
                                            className="header-logo"
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/assest/images/B2Bbuyer/icons/mastercard.png"
                                            }
                                            alt="Logo"
                                        />
                                    </div>
                                    <div className='row form-div '>
                                        <div className='col-xxl-10 col-md-10 col-12'>
                                            <div className='row ms-3'>
                                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Card Number</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Card Number" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Given name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Given name" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Surname</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Surname" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Month</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Month" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Year</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Year" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>CVV</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control placeholder="CVV" aria-label="CVV" className='form-cvv' aria-describedby="basic-addon1" />
                                                            <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon="fa-solid fa-eye" className='eye-icon' /></InputGroup.Text>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Form.Check
                                        label='Other Payment Methods'
                                        name='delivery'
                                        type='radio'
                                    />
                                    <div className='ms-4'>
                                        <img className="header-logo" src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/icons/paypal.png"} alt="Logo" />
                                        <img className="header-logo" src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/icons/apple.png"} alt="Logo" />
                                        <p className='other-pay-text mt-2'>Order now and pay later using your preferred payment method.</p>
                                    </div>
                                </div>

                                <div>
                                    <Form.Check
                                        label='UPI'
                                        name='delivery'
                                        type='radio'
                                    />
                                    <div className='ms-4'>
                                        <img className="header-logo" src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/icons/UPI-Logo.png"} alt="Logo" />
                                    </div>
                                </div>
                                <div className=' mt-5'>
                                    <ButtonComponent onClick={openSetModal} className={'btn-continue'}>Continue <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='ms-1' /></ButtonComponent>
                                </div>
                            </div>

                        </div>
                        <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-12 col-12'>
                            <HelpSlider imagePaths={uplodimg} imageClassName="slider-img mt-xxl-0 mt-xl-0 mt-md-3 mt-sm-3 mt-3" />
                        </div>
                    </div>
                </div>
            </section>
            <InquiryModal
                message="Money added to wallet successfully"
                show={showMoney}
                onHide={() => setShowMoney(false)}
            />
        </>
    )
}

export default MakePaymentWallet