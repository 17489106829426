import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import CustomerInfo from './CustomerInfo/CustomerInfo';
import ProductPortFolio from './ProductPortfolio/ProductPortFolio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ProductPortfolio/ProductPortFolio.css'
import { Link } from 'react-router-dom';
import ButtonComponent from '../../CommonComponents/ButtonComponent/ButtonComponent';

const CheckoutForm = () => {
    return (
        <section className='checkout-form'>
            <Container>
            <div className='d-md-flex justify-content-md-between text-center'>
                <div className='back-btn-main'>
                        <Link to='/mywebsites/portfolio'><ButtonComponent className='back-btn'><FontAwesomeIcon icon="fa-solid fa-arrow-left" className='bck-icon' /> Back</ButtonComponent></Link>
                </div>
                <div className='heading'>
                        <h3 className='maintenance-title mb-5'>Checkout Form</h3>
                </div>
            </div>
            
                <div className='check-bg'>
                    <Row>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                            <CustomerInfo />
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                            <ProductPortFolio />
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default CheckoutForm