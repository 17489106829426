import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import "./B2BProduct.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-datepicker/dist/react-datepicker.css";
import "react-circular-progressbar/dist/styles.css";
import SelectCategory from "./SelectCategory/SelectCategory";
import SelectBrand from "../B2BProduct/SelectBrand/SelectBrand";
import AddProductInfo from "../B2BProduct/AddProductInfo/AddProductInfo";
import Form from "react-bootstrap/Form";
function B2BProduct({ onNext, onPrev }) {
  const [current, setCurrent] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const stepDescriptions = [
    "Select Category",
    "Select Brand",
    "Add To Product",
  ];

  const handleNext = () => {
    if (current < 3) {
      setCurrent(current + 1);
    }
  };

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const isStepCompleted = (step) => {
    return step < current;
  };

  const getIconForStep = (step) => {
    return step;
  };

  return (
    <>
      <section className="StepForm-Employer">
        <div className="ProgressBar">
          <Form>
            <div className="signup-form">
              <div className="progress-bar-container">
                <div className="step-row">
                  {[1, 2, 3].map((step) => (
                    <div
                      key={step}
                      className={`step-container ${
                        step === current ? "active" : ""
                      }`}
                    >
                      <div
                        className={`circle ${
                          isStepCompleted(step) ? "completed" : ""
                        }`}
                      >
                        {getIconForStep(step)}
                      </div>
                      <span className="step-text">
                        {stepDescriptions[step - 1]}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="wizard-form mt-3">
              {current === 1 && <SelectCategory handleNext={handleNext} />}
              {current === 2 && (
                <SelectBrand
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                />
              )}
              {current === 3 && (
                <AddProductInfo
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                />
              )}
            </div>
          </Form>
        </div>
      </section>
    </>
  );
}

export default B2BProduct;
