import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
library.add(fas);

function Steptwo(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const handleSelectChange = (selectedOption) => {
    setSelectedOptions([...selectedOptions, selectedOption]);
  };

  const handleCloseButtonClick = (optionValue) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.value !== optionValue
    );
    setSelectedOptions(updatedOptions);
  };

  const handleSuggestedSkillClick = (label) => {
    const newOption = { value: label, label: label };
    setSelectedOptions([...selectedOptions, newOption]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options = [
    { value: " XD", label: " XD" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  return (
    <>
      <div className="personal-details-form mb-0 ">
        {/* form-holder */}
        <div className="details-form-holder">
          <div className="row">
            <Form>
              <div className="mb-3">
                <Form.Check type="radio" label="Farmer" name="name" />
              </div>
              <div className="mb-3">
                <Form.Check type="radio" label="Supplier" name="name" />
              </div>
              <div className="mb-3">
                <Form.Check
                  type="radio"
                  label="Manufacturer (OEM)"
                  name="name"
                />
              </div>
            </Form>
          </div>

          <div className="col-md-12">
            <div className="text-end apply_now_btn">
              {/* <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p> */}

              <Link to={"/seller/seller-dashboard/"}>
                <Button className="back-btn me-3">Skip</Button>
              </Link>

              <div className="registerContinueMain">
                <Button onClick={props.prevStep} className="back-btn">
                  Back
                </Button>
              </div>

              <div className="registerContinueMain">
                <Button onClick={props.nextStep} className="tabs-btn">
                  Register & Continue
                </Button>
              </div>
            </div>

            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Steptwo;
