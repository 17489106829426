import { React, useRef, useEffect, useState, useContext } from "react";
import "../variants.css";
import Form from "react-bootstrap/Form";
import { Button, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoditEditor from "jodit-react";
import Nav from "react-bootstrap/Nav";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import SingleVariantInner from "../SingleVariantInner/SingleVariantInner";
import {
  useForm,
  Controller,
  useFormContext,
  useFieldArray,
} from "react-hook-form";
import Select from "react-select";
import classNames from "classnames";

import Cookies from "js-cookie";
import { Context } from "../../../../../../../../../utils/context";
import {
  Attributes,
  PlatformCharges,
  ProductAttributes,
  SubAttributes,
  Unit,
} from "../../../../../../../../../utils/apis/master/Master";
import {
  singleVariantTablePost,
  singleVariantGet,
  singleVariantPost,
} from "../../../../../../../../../utils/apis/seller/Product";
import { ProductInfoIcon } from "../../../../../../../../../utils/apis/master/Master";
import { useLocation } from "react-router-dom";
import { hsnCodes } from "../../../../../../../../../utils/apis/seller/masters";
import {
  IDS,
  InputeType,
  Paths,
} from "../../../../../../../../../utils/common";

const SingleVariant = ({
  prevInnerPage,
  nextInnerPage,
  prevStep,
  nextStep,
}) => {
  const location = useLocation();

  const [content, setContent] = useState("");
  const [hsnCode, setHsnCode] = useState([]);

  const [uploadDefaultImages, setDefaultUploadImages] = useState([
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
  ]);

  const [uploadimages, setUploadImages] = useState([]);

  const uploader = Uploader({
    apiKey: "free",
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      s_s_a_data: [],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "s_s_a_data",
  });

  const { getData, IMG_URL, Select2Data, Select2AttributeData, Id, SelectHsnData, ProductNarrationChangeComa } =
    useContext(Context);
  const [data, setData] = useState([]);
  const [subAtributes, setSubAtributes] = useState([]);
  const [valsubAttribute, setvalsubAttribute] = useState([]);
  const [gsts, setGsts] = useState(null);
  const [units, setUnit] = useState([]);
  const [productInfoIcon, setProductInfoIcon] = useState([]);

  const [platform_charges, setPlatform_Charges] = useState("");

  const getDataAll = async () => {
    {
      const id = Cookies.get("s_product_id");
      // if (location.pathname == Paths.o_w_seller_product) {
      const res = await ProductAttributes(id, Id, 'admin');
      if (res?.success) {
        setData(await Select2AttributeData(res?.data, "attribute_id", false));
      }
      // }
    }
    {
      const res = await Unit();
      if (res?.success) {
        setUnit(await Select2Data(res?.data, "unit_id", false));
      }
    }
    {
      const res = await PlatformCharges();
      if (res?.success) {
        setPlatform_Charges(res.data.no);
      }
    }

    {
      const res = await hsnCodes();
      if (res?.success) {
        setHsnCode(await SelectHsnData(res?.data, "hsn_id", true));
      }
    }

    {
      const res = await ProductInfoIcon();
      if (res?.success) {
        setProductInfoIcon(res?.data);
      }
    }
  };


  console.log(getValues());
  useEffect(() => {
    getDataAll();
    getGstAll();
  }, []);

  const getGstAll = async () => {
    try {
      const response = await getData(`/seller/masters/all-s-gst`);
      setGsts(await Select2Data(response?.data, "gst_id"));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSubAttributes = async (id) => {
    {
      setValue("sub_attribute_id", "");
      const res = await SubAttributes(id);
      if (res?.success) {
        setSubAtributes(
          await Select2Data(res?.data, "sub_attribute_id", false)
        );
      }
    }
  };



  useEffect(() => {
    getDataAll();
    getGstAll();
  }, []);





  const [variantId, setVariantId] = useState("");
  const onSubmit = async (data) => {
    var sendData = new FormData();

    sendData.append("s_product_id", Cookies.get("s_product_id"));
    sendData.append("variant_type", "Single");
    sendData.append("s_attribute_id", data.s_attribute_id.value);

    sendData.append("s_s_a_data", JSON.stringify(selectedOptions));

    const res = await singleVariantTablePost(sendData);

    if (res?.success) {
      getSingleVariant(Cookies.get("s_product_id"));
      setVariantId(res?.data?.id);
      // nextStep();
    }
  };

  const [selectedOptions, setSelectedOption] = useState([]);
  const handleFields = async (selectedOption) => {
    const removedOption = selectedOptions.find(
      (option) => !selectedOption.includes(option)
    );
    if (removedOption) {
      const removedIndex = selectedOptions.indexOf(removedOption);

      remove(removedIndex);
    } else {
      await setUploadImages([...uploadimages, uploadDefaultImages]);

      append({
        thumbnail: null,
        description: "",
        mrp: "",
        hsn: "",
        stock: "",
        selling_price: "",
        after_platform_charges: "",
        minimum_order_quantity: "",
        // s_unit_id: "",
        length: "",
        breath: "",
        height: "",
        weight: "",
        s_gst_id: "",
        open_delivery: "",
        discountable: "",
        uploadimages: uploadDefaultImages,
      });
    }
    setSelectedOption(selectedOption);
  };
  const [defind, setDefind] = useState("");
  const getSingleVariant = async (id) => {
    const res = await singleVariantGet(id);
    if (res?.success) {
      if (res?.data?.variant_type === "Single") {
        setvalsubAttribute(
          await res.data?.s_product_variant_attribute?.s_product_variant_attribute_details?.map(
            (item) => {
              return {
                value: item.s_sub_attribute.id,
                label: item.s_sub_attribute.name,
              };
            }
          )
        );
        setVariantId(res?.data?.id);
        reset(res.data);
        setValue("s_attribute_id", {
          value: res.data?.s_product_variant_attribute?.s_attribute?.id,
          label: res.data?.s_product_variant_attribute?.s_attribute?.defind? res.data?.s_product_variant_attribute?.s_attribute?.name + " :" + res.data?.s_product_variant_attribute?.s_attribute?.defind : res.data?.s_product_variant_attribute?.s_attribute?.name,
        });
        setDefind(res.data?.s_product_variant_attribute?.s_attribute?.defind);
        const updatedSelectedOption = [...selectedOptions];
        res.data?.s_product_variant_attribute?.s_product_variant_attribute_details?.forEach(
          (value, index) => {
            updatedSelectedOption[index] = value?.s_sub_attribute?.name;
          }
        );
        setSelectedOption(updatedSelectedOption);
        setValue(`s_s_a_id`, updatedSelectedOption);
      }
    }
  };

  useEffect(() => {
    const cookie = Cookies.get("s_product_id");
    if (cookie) {
      getSingleVariant(cookie);
    }
  }, []);
  const handleNext = () => {
    setRedirectToNext(true);
  };
  const [redirectToNext, setRedirectToNext] = useState(false);
  if (redirectToNext) {
    return (
      <SingleVariantInner nextStep={nextStep} prevInnerPage={prevInnerPage} />
    );
  }
  // useEffect(()=>{

  // },[uploadimages])

  return (
    <>
      <section className="single-varient-sec">
        <Form onSubmit={() => handleSubmit(onSubmit)}>
          <div className="sec-div ">
            <div className="row">
              <div className="col-md-6 mb-3">
                <Form.Label>
                  Attribute<span className="mandatory-star"> *</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-title">
                        {productInfoIcon?.attribute}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Controller
                  name="s_attribute_id" // name of the field
                  {...register("s_attribute_id", {
                    required: "Select Attribute",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.s_attribute_id
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      options={data}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption.value); // Update Controller's value
                        getSubAttributes(selectedOption.value);
                        setValue("s_attribute_id", selectedOption);
                        setValue("input_type_id", selectedOption.input_type_id);
                      }}
                    />
                  )}
                />
                <br></br>
                <Form.Label>
                  Sub Attribute{" "}
                  <sup className="text-primary">
                    (example 1,example 2,example 3)
                  </sup>
                </Form.Label>
                {/* <Form.Control
                  type="text"
                  name="s_s_a_id"
                  placeholder="Attributes"
                  accept={InputeType[`${getValues("input_type_id")}`]?.value}
                  className={classNames("", {
                    "is-invalid": errors?.s_s_a_id,
                  })}
                  {...register("s_s_a_id", {
                    pattern: InputeType[`${getValues("input_type_id")}`],
                    // required: "Name is required",
                  })}
                  onChange={(e) => {
                    // var valuesArray = e.target.value
                    //   .split(",")
                    //   .filter(function (item) {
                    //     return item !== "";
                    //   });
                    var valuesArray = e.target.value
                      .split(",")
                      .filter(function (item) {
                        return item.trim() !== "";
                      });
                    console.log(valuesArray);
                    setvalsubAttribute(valuesArray);

                    handleFields(valuesArray);
                  }}
                /> */}

                <Form.Control
                  type="text"
                  name="s_s_a_id"
                  placeholder="Attributes"
                  accept={InputeType[`${getValues("input_type_id")}`]?.value}
                  className={classNames("", {
                    "is-invalid": errors?.s_s_a_id,
                  })}
                  {...register("s_s_a_id", {
                    pattern: InputeType[`${getValues("input_type_id")}`],
                    // required: "Name is required",
                  })}
                  onChange={(e) => {
                    // var valuesArray = e.target.value
                    //   .split(",")
                    //   .filter(function (item) {
                    //     return item.trim() !== "";
                    //   });
                    // setTimeout(() => {
                    var valuesArray = ProductNarrationChangeComa(e)
                      .split(",")
                      .filter(function (item) {
                        return item.trim() !== "";
                      })
                      .filter(function (item, index, self) {
                        return self.indexOf(item) === index;
                      });

                    console.log(valuesArray);
                    handleFields(valuesArray);
                    setValue('s_s_a_id', ProductNarrationChangeComa(e));
                    // }, 2000);
                  }}
                />
                {/* <Controller
                  name="s_s_a_id" // name of the field
                  {...register("s_s_a_id")}
                  control={control}
                  render={({ field }) => (
                    <Select
                      isMulti
                      {...field}
                      options={subAtributes}
                      value={valsubAttribute}
                      onChange={(selectedOptions) => {
                        setvalsubAttribute(selectedOptions);
                        handleFields(selectedOptions);
                      }}
                    />
                  )}
                /> */}
              </div>
              <div className="col-lg-6">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Attribute</th>
                      <th>Sub Attribute</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOptions.map((option, index) => (
                      <tr key={index}>
                        <td>{getValues("s_attribute_id")?.label}</td>
                        <td>
                          {option} {defind}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="common-steps-btn">
              <Button type="button" onClick={prevStep} className="back-btn">
                Back
              </Button>
              <Button
                type="button"
                onClick={handleSubmit(onSubmit)}
                className="next-btn"
              >
                Save
              </Button>
              {variantId && (
                <Button type="button" onClick={handleNext} className="next-btn">
                  Next
                </Button>
              )}
            </div>
          </div>
        </Form>
      </section>
    </>
  );
};

export default SingleVariant;
