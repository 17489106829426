import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import { library } from "@fortawesome/fontawesome-svg-core";
import DeleteModal from '../../../../common-components/AllModal/Delete-modal/DeleteModal';
import AddNoteModal from '../../../../common-components/AllModal/Add_note_modal/AddNoteModal';
import ButtonComponent from '../../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import { Link, BrowserRouter as Router } from 'react-router-dom';
import Animation from "../../../../../CommonForAll/Animation/SuccessAnimation/Animation";

library.add(fas);
const cardDataArray = [
    {
        label: 'Sidior Furniture Company Limited',
        image: "/assest/images/B2Bbuyer/products/Sofa_2.png",
        title: 'Modern Minimalist White Half Circle Designer Couches Luxury',
        minOrder: 'Min. order: 2.0 piece',
        deliveryDate: 'Estimated delivery by Apr 26-May 17',
        price: '₹60,000',
        buttonGroup: true,
        trash: true,
        buttonNote: false,
        formcheck: true,

    },
    {
        label: 'Guangzhou Yinli Jiayi Accessories Trading Co., Ltd',
        image: "/assest/images/B2Bbuyer/products/Sofa_2.png",
        title: 'Cross-border Light Luxury Diamond Bracelet',
        minOrder: 'Min. order: 2.0 piece',
        deliveryDate: 'Estimated delivery by Apr 26-May 17',
        price: '₹10,000',
        buttonGroup: true,
        trash: true,
        buttonNote: false,
        formcheck: true,
    },

];

const B2bDeshMyCart = ({ }) => {
    const [showNote, setShowNote] = useState(false);
    const [show, setShow] = useState(false);
    const [modalDelete, setModalDelete] = React.useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [values, setValues] = useState(cardDataArray.map(() => 0));

    const decrement = (index) => {
        const newValues = [...values];
        if (newValues[index] > 0) {
            newValues[index]--;
            setValues(newValues);
        }
    };

    const increment = (index) => {
        const newValues = [...values];
        newValues[index]++;
        setValues(newValues);
    };
    const handleCloseNote = () => setShowNote(false);
    const handleShowNote = () => setShowNote(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <section className='ShoppingCart-sec'>
                <div className='row ms-0 me-0'>
                    <div className='col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-12'>
                        <div className='heading'>
                            <h1>Shopping Cart</h1>
                        </div>
                        <Form.Group className="my-2">
                            <Form.Check
                              
                                label="Select all items"
                                feedback="You must agree before submitting."
                            />
                        </Form.Group>
                        {cardDataArray.map((cardData, index) => (
                            <div className='shopping-card-main-card' key={index}>
                                <Form.Group className="mb-3">
                                    {cardData.formcheck && (
                                        <Form.Check
                                            label={cardData.label}
                                            name='delivery'
                                            inline
                                        />
                                    )}
                                </Form.Group>
                                <div className='sec-card-head'>
                                    <div>
                                        <p>{cardData.order}</p>
                                    </div>
                                    {cardData.buttonNote && (
                                        <div onClick={() => setModalShow(true)}>
                                            <span>Any note for supplier ?</span><button>Add note</button>
                                        </div>
                                    )}
                                </div>
                                <div className='card-sec'>
                                    <div className='row'>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                            <div className='left-sec'>
                                                {cardData.formcheck && (
                                                    <Form.Check
                                                        name='delivery'
                                                        inline
                                                    />
                                                )}
                                                <div className='me-3'>
                                                    <img src={process.env.PUBLIC_URL + cardData.image} className="prduct-img" />
                                                </div>
                                                <div className='details'>
                                                    <h6>{cardData.title}</h6>
                                                    <p>{cardData.minOrder}</p>
                                                    <p>{cardData.deliveryDate}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                            <div className='right-sec'>
                                                <div>
                                                    {cardData.buttonGroup && (
                                                        <ButtonGroup aria-label="First group" className='btn-grp'>
                                                            <Button className='add-btns' onClick={() => decrement(index)}>-</Button>
                                                            <p className='price-text'>{values[index]}</p>
                                                            <Button className='add-btns' onClick={() => increment(index)}>+</Button>
                                                        </ButtonGroup>
                                                    )}
                                                    <div className='count'>
                                                        <p className='price-text'>{cardData.quantity}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className='price-text'>{cardData.price}</p>
                                                </div>
                                                <div onClick={() => { setModalDelete(true) }}>
                                                    {cardData.trash && <button className='trash-btn'><FontAwesomeIcon icon={fas.faTrash} /></button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <DeleteModal
                            show={modalDelete}
                            onHide={() => setModalDelete(false)}
                        />

                        <AddNoteModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </div>
                    <div className='col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12'>
                        <div className='order-summary-card'>
                            <div className='flex-div'>
                                <h6>Order Summary</h6>
                                <p>Total items: 6</p>
                            </div>
                            <div className='flex-div'>
                                <p>item(s) Total</p>
                                <p>₹ 10000</p>
                            </div>
                            <div className='flex-div'>
                                <p>Estimated shipping fee</p>
                                <p>₹ 10000</p>
                            </div>
                            <div className='flex-div'>
                                <p>Tax</p>
                                <p>₹100</p>
                            </div>
                            <div className='border-div' />
                            <div className='flex-div mt-2'>
                                <p>Order total</p>
                                <p>₹ 61000</p>
                            </div>
                            <div className='text-center'>
                                <Link to={"/b2bbuyer/start-order"}>
                                    <ButtonComponent className="proceed-btn" onClick={handleShow}>
                                        Proceed to Checkout
                                    </ButtonComponent>
                                </Link>

                                <Modal show={show} onHide={handleClose} size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    className='success-modal-payment'>
                                    <Modal.Header closeButton />
                                    <Modal.Body>
                                        <Animation />
                                        Payment done successfully
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </>
    )
}

export default B2bDeshMyCart