import React from 'react'
import './ECommerce.css'
import { Container} from 'react-bootstrap';
import ButtonComponent from '../../CommonComponents/ButtonComponent/ButtonComponent';

const ECommerce = () => {
    return (
        <section className='e-commerce-sec'>
            <Container>
                <div className='main-img'>
                    <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Pricing/cyber-monday-shopping-sales.png"} className="pricing-bg-img" alt="..." />
                    <div className='blk-overlay'></div>
                    <div className='txt-ecom'>
                        <h3>E-Commerce </h3>
                        <p className='subtitle-ecom'>Add-On for Netpurti Sitebuilder</p>
                        <p>Instantly Start Selling with Sitebuilder E-Commerce Add-On! Enjoy Strong SEO, Responsive Design, and No Extra Transaction Fees. Request a Free Trial Now!</p>
                        <ButtonComponent className='free-trial'>Free Trial: E-commerce Add-On</ButtonComponent> <br/>
                        <ButtonComponent className='explore mt-3'>Explore E-Commerce Add-On Plans</ButtonComponent>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default ECommerce