import React from "react";
import "./Affiliate_dashboard.css";
import { useLocation } from "react-router-dom";
import Header_affilate_dashboard from "./header/Header_affilate_dashboard";
import Sidebar_affilate_dashboard from "./Sidebar/Sidebar_affilate_dashboard";
import AffiliateDashboardRoutes from "./AffiliateDashboardRoutes/AffiliateDashboardRoutes";
const Affiliate_Dashboard = () => {
  const location = useLocation();

  return (
    <>
      <section className=" affilate-dash-top-main">
        <div className="d-flex">
          <div className="sidebar-flex">
            <Sidebar_affilate_dashboard location={location} />
          </div>

          <div className="dashboard-part content-flex">
            <Header_affilate_dashboard />

            <div className="dash-main-cont-sec">
              <AffiliateDashboardRoutes />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Affiliate_Dashboard;
