import React, { useEffect, useState, useContext } from "react"
import './EditPersonalDetailsModal.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangeModal from './ChangeModal';
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import InquiryModal from "../../../common-components/AllModal/inquiry-Modal/InquiryModal";
import Select from "react-select";
import {
    FirmType,
    firmAddressType,
    City,
    State,
    Country,
    Pincode,
    pincodeWiseAll,
} from "../../../../../utils/apis/master/Master";
import {
    firmDetailNamePost,
    getFirmDetailName,
    btbPercentageSend,
} from "../../../../../utils/apis/btb/B2b";
import classNames from "classnames";
const EditFirmNameDetailsModal = (props) => {
    const [modalChange, setModalChange] = useState(false);
    const [modalInquiry, setModalInquiry] = useState(false);

    const submitModal = () => {
        setModalInquiry(true);
        setTimeout(() => {
            props.onHide();
            reset();
            setModalInquiry(false);
        }, 3000);
    };

    const { getData, postData, IMG_URL, Select2Data, Id } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    const onFirmNameSubmit = async (d) => {
        const data = {
            name: d.name,
            firm_type_id: d.firm_type_id.value,
            btb_personal_details_id: Id,
        };
        if (firmDetailNameId != "") {
            data.id = firmDetailNameId;
        }
        const res = await firmDetailNamePost(data);
        if (res?.success) {
            console.log(res);
            submitModal()
        }
    };

    // console.log(firmName,"firmNamefirmNamefirmNamefirmNamefirmNamefirmNamefirmNamefirmName");


    const [firmAddressTypes, setFirmAddressType] = useState([]);

    const [firmTypes, setFirmType] = useState([]);
    const getMasters = async () => {
        {
            const res = await FirmType();
            if (res?.success) {
                const data = await Select2Data(res.data, "firm_type_id", false);
                setFirmType(data);
            }
        }
        {
            const res = await firmAddressType();
            if (res?.success) {
                setFirmAddressType(res.data);
            }
        }
    };

    const [firmDetailNameId, setFirmDetailNameId] = useState("");
    const getFirmDetailNames = async () => {
        const res = await getFirmDetailName(Id);
        if (res?.success) {
            setFirmDetailNameId(res.data.btb_personal_details_id);
            reset(res.data);
        } else {
            // setValue("name", firmName);
        }
    };

    useEffect(() => {
        getMasters();
        getFirmDetailNames();
    }, [Id]);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='edit-details-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Firm Type Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onFirmNameSubmit)}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={4} controlId="formGridEmail">
                                <div className='d-flex'>
                                    <Form.Label>Enter the firm name</Form.Label>
                                    {/* <span className='change' onClick={() => { setModalChange(true); props.onHide(); }}>Change</span> */}
                                </div>
                                <Form.Control
                                    className={classNames("", {
                                        "is-invalid": errors?.name,
                                    })}
                                    type="text"
                                    {...register("name", {
                                        required: "firm name is required",
                                    })}
                                    placeholder={"firm name"}
                                />
                            </Form.Group>


                            <Form.Group as={Col} md={4} controlId="formGridName">
                                <Form.Label>First Name</Form.Label>
                                <Controller
                                    name="firm_type_id"
                                    {...register("firm_type_id", {
                                        required: "firm name is required",
                                    })}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: errors.firm_type_id ? "red" : baseStyles,
                                                }),
                                            }}
                                            {...field}
                                            options={firmTypes}
                                        />
                                    )}
                                />
                            </Form.Group>


                        </Row>
                        <Modal.Footer>
                            <ButtonComponent onClick={props.onHide} className={'btn-cancle'}>Cancel</ButtonComponent>
                            <ButtonComponent onClick={() => handleSubmit(onFirmNameSubmit(getValues()))} className={'btn-update'} > Update</ButtonComponent>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal >
            <ChangeModal
                show={modalChange}
                onHide={() => setModalChange(false)}
            />
            <InquiryModal
                message="Submitted successfully"
                show={modalInquiry}
                onHide={() => setModalInquiry(false)}
            />
        </>
    )
}

export default EditFirmNameDetailsModal