import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../api";

export const getAffiliateWalletApi = async (currentPage) => {
  try {
    const res = await getData(`/affiliate/affiliate-wallet/wallet?page=${currentPage}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getExportStatement = async () => {
  try {
    const response = await getData(`/affiliate/affiliate-wallet/wallet/export`);
    return response;
  } catch (error) {
    console.error('Error fetching export statement:', error);
  }
};


export const depositAffiliateAmountPost = async (amount, razor) => {
  try {
    const res = await postData(`/affiliate/affiliate-wallet/wallet`, { amount: amount, razor_id: razor });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const withdrawAmountPost = async (amount) => {
  try {
    const res = await postData(`/affiliate/affiliate-wallet/wallet/withdraw`, { amount: amount });

    return res;
  } catch (error) {
    console.log(error);
  }
};
