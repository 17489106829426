import React from "react";
import SellerBanner from "./SellerBanner/SellerBanner";
import MovingPerson from "./Moving-Person/MovingPerson";
import Surmount from "./Surmount/Surmount";
import SelfRelient from "./Self-Relient/SelfRelient";
import WomenSeller from "./Women-Seller/WomenSeller";

const SellerSuccessStory = () => {
  return (
    <>
      <SellerBanner />
      <MovingPerson />
      <Surmount />
      <SelfRelient />
      <WomenSeller />
    </>
  );
};

export default SellerSuccessStory;
