import React, { useContext, useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { SocialPlatform } from "../../../../utils/apis/master/Master";

import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";

import classNames from "classnames";

import Select from "react-select";
import { Context } from "../../../../utils/context";
import {
  SocialPost,
  getSocial,
  SocialUrlPost,
  getSocialUrl,
} from "../../../../utils/apis/affiliate/affiliate";

library.add(fas);
function Steptwo({ affiliateID, ...props }) {
  const { Select2Data } = useContext(Context);

  useEffect(() => {
    console.log("props :-", props);
    console.log("afiliate id :-", affiliateID);
  }, [props]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [platformCount, setPlatformCount] = useState(1);
  const [websiteCount, setWebsiteCount] = useState(1);

  const handleAddPlatform = () => {
    setPlatformCount((prevCount) => prevCount + 1);
  };

  const handleRemovePlatform = () => {
    setPlatformCount((prevCount) => prevCount - 1);
  };

  const handleAddWebsite = () => {
    setWebsiteCount((prevCount) => prevCount + 1);
  };

  const handleRemoveWebsite = () => {
    setWebsiteCount((prevCount) => prevCount - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      data1: [
        {
          platform: "",
          page_link: "",
          page_name: "",
          subscriber: "",
        },
      ],
      data2: [
        {
          website_url: "",
        },
      ],
    },
  });

  const {
    fields: fields1,
    append: append1,
    remove: remove1,
  } = useFieldArray({
    control,
    name: "data1",
  });
  const {
    fields: fields2,
    append: append2,
    remove: remove2,
  } = useFieldArray({
    control,
    name: "data2",
  });

  const onSubmit = async (d) => {
    const formData = new FormData();

    formData.append("a_personal_details_id", affiliateID);

    const data1 = [];
    const data2 = [];

    d.data1.forEach((item) => {
      data1.push({
        social_platform_id: item?.social_platform_id?.value,
        page_link: item?.page_link,
        page_name: item?.page_name,
        subscriber: item?.subscriber,
      });
    });

    d.data2.forEach((item) => {
      data2.push({
        website_url: item?.website_url,
      });
    });

    formData.append("data1", JSON.stringify(data1));
    formData.append("data2", JSON.stringify(data2));

    const res1 = await SocialPost(formData);
    // Handle response and navigate to the next step
    if (res1?.success) {
      props.nextStep();
    }
  };

  const getSocialIn = async () => {
    try {
      const res1 = await getSocial(affiliateID);
      const res2 = await getSocialUrl(affiliateID);

      if (res1?.success && res2?.success) {
        // Ensure data1 and data2 are arrays, even if the response contains a single object
        const data1 = Array.isArray(res1?.data)
          ? res1.data
          : [res1?.data || {}];
        const data2 = Array.isArray(res2?.data)
          ? res2.data
          : [res2?.data || {}];

        reset({
          data1: data1.map((item) => ({
            social_platform_id: item.social_platform_id || "",
            page_link: item.page_link || "",
            page_name: item.page_name || "",
            subscriber: item.subscriber || "",
          })),
          data2: data2.map((item) => ({
            website_url: item.website_url || "",
          })),
        });
      } else {
        reset({
          data1: [
            {
              social_platform_id: "",
              page_link: "",
              page_name: "",
              subscriber: "",
            },
          ],
          data2: [
            {
              website_url: "",
            },
          ],
        });
      }
    } catch (error) {
      console.error(error);
      reset({
        data1: [
          {
            social_platform_id: "",
            page_link: "",
            page_name: "",
            subscriber: "",
          },
        ],
        data2: [
          {
            website_url: "",
          },
        ],
      });
    }
  };

  const [socialPlatform, setSocialPlatform] = useState([]);
  const getMaster = async () => {
    {
      const res = await SocialPlatform();
      if (res?.success) {
        const data = await Select2Data(res?.data, "social_platform_id");
        setSocialPlatform(data);
      }
    }
  };

  console.log("socialPlatform", socialPlatform);

  useEffect(() => {
    // getSocialIn();
    getMaster();
  }, []);

  console.log(getValues());
  useEffect(() => {
    getSocialIn();
  }, []);

  return (
    <>
      <div className="personal-details-form mb-0 ">
        {/* form-holder */}
        <div className="details-form-holder">
          <div className="form-container">
            <Form onSubmit={handleSubmit(onSubmit)} role="form">
              {/* Current Industry , Job Title*/}
              <div className=" ">
                {fields1.map((item, index) => (
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <Form.Group controlId="">
                          <Form.Label>Platform</Form.Label>
                          <div className="verified">
                            <Controller
                              name={`data1[${index}].social_platform_id`}
                              control={control}
                              rules={{
                                required: "Social Media is required",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={socialPlatform}
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.data1?.[index]
                                        ?.social_platform_id
                                        ? "red"
                                        : baseStyles.borderColor,
                                    }),
                                  }}
                                  className={classNames("", {
                                    "is-invalid":
                                      errors.data1?.[index]?.social_platform_id,
                                  })}
                                />
                              )}
                            />

                            {/* {errors.social_platform_id && (
                              <span className="text-danger">
                                {errors.social_platform_id.message}
                              </span>
                            )} */}
                          </div>
                        </Form.Group>
                      </div>

                      <div className="col-md-4">
                        <Form.Group controlId="">
                          <Form.Label>Page Link</Form.Label>
                          <div className="verified">
                            <Form.Control
                              type="text"
                              className={classNames("", {
                                "is-invalid":
                                  errors.data1 &&
                                  errors.data1[index] &&
                                  errors.data1[index].page_link,
                              })}
                              name={`data1[${index}].page_link`}
                              placeholder="Enter Link"
                              defaultValue={item.page_link}
                              {...register(`data1[${index}].page_link`, {
                                required: "Link is required",
                              })}
                            />
                          </div>
                        </Form.Group>
                      </div>

                      <div className="col-md-4">
                        <Form.Group controlId="">
                          <Form.Label>Page Name</Form.Label>

                          <div className="verified">
                            <Form.Control
                              type="text"
                              className={classNames("", {
                                "is-invalid":
                                  errors.data1 &&
                                  errors.data1[index] &&
                                  errors.data1[index].page_name,
                              })}
                              name={`data1[${index}].page_name`}
                              placeholder="Enter Name of Page"
                              defaultValue={item.page_name}
                              {...register(`data1[${index}].page_name`, {
                                required: "Name of Page is required",
                              })}
                            />
                          </div>
                        </Form.Group>
                      </div>

                      <div className="col-md-4">
                        <Form.Group controlId="">
                          <Form.Label>Total Subscriber</Form.Label>

                          <div className="verified">
                            <Form.Control
                              type="text"
                              className={classNames("", {
                                "is-invalid":
                                  errors.data1 &&
                                  errors.data1[index] &&
                                  errors.data1[index].subscriber,
                              })}
                              name={`data1[${index}].subscriber`}
                              placeholder="Enter Name of Page"
                              defaultValue={item.subscriber}
                              {...register(`data1[${index}].subscriber`, {
                                required: "Name of Page is required",
                              })}
                            />
                          </div>
                        </Form.Group>
                      </div>
                      {index !== 0 && (
                        <div className="addremoveadress">
                          <div className="text-end Addnewadresstxt">
                            <p className="" onClick={() => remove1(index)}>
                              Remove Address{" "}
                              <FontAwesomeIcon icon="fa-solid fa-minus" />
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}

                <div className="addremoveadress">
                  {/* <div
                  className="text-end Addnewadresstxt"
                  onClick={handleRemovePlatform}
                >
                  <p className="">
                    Remove new Platform  {" "}
                    <FontAwesomeIcon icon="fa-solid fa-minus" />
                  </p>
                </div> */}
                  <div
                    className="text-end Addnewadresstxt"
                    onClick={() =>
                      append1({
                        platform: "",
                        page_link: "",
                        page_name: "",
                      })
                    }
                  >
                    <p className="">
                      Add new Platform{" "}
                      <FontAwesomeIcon icon="fa-solid fa-plus" />
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <Form.Group controlId="">
                      <Form.Label>Website URL</Form.Label>
                      {fields2.map((item, index) => (
                        <>
                          <div className="verified">
                            <Form.Control
                              type="text"
                              className={classNames("", {
                                "is-invalid":
                                  errors.data2 &&
                                  errors.data2[index] &&
                                  errors.data2[index].website_url,
                              })}
                              name={`data2[${index}].website_url`}
                              placeholder="Enter Website url"
                              defaultValue={item.website_url}
                              {...register(`data2[${index}].website_url`, {
                                required: "URL required",
                                pattern: {
                                  value:
                                    /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-./?%&=]*)?$/,
                                  message: "Invalid URL",
                                },
                                onChange: (e) => {
                                  // Perform any additional actions here if needed
                                  console.log("URL changed:", e.target.value);
                                },
                              })}
                            />

                            {errors.data2 &&
                              errors.data2[index] &&
                              errors.data2[index].website_url && (
                                <div className="invalid-feedback">
                                  {errors.data2[index].website_url.message}
                                </div>
                              )}
                          </div>
                          {index !== 0 && (
                            <div className="addremoveadress">
                              <div className="text-end Addnewadresstxt">
                                <p className="" onClick={() => remove2(index)}>
                                  Remove Address{" "}
                                  <FontAwesomeIcon icon="fa-solid fa-minus" />
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </Form.Group>

                    <div className="addremoveadress">
                      {/* <div
                          className="text-end Addnewadresstxt"
                          onClick={handleRemoveWebsite}
                        >
                          <p className="">
                            Remove new Website  {" "}
                            <FontAwesomeIcon icon="fa-solid fa-minus" />
                          </p>
                        </div> */}
                      <div
                        className="text-end Addnewadresstxt"
                        onClick={() =>
                          append2({
                            website_url: "",
                          })
                        }
                      >
                        <p className="">
                          Add new Website{" "}
                          <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="text-end apply_now_btn">
                    {/* <p onClick={() => setModalShow(true)}>
                      By continuing, I agree to Netpurti's
                      <span className="termstxt">Terms of Use & Privacy Policy</span>
                    </p> */}

                    <Button onClick={props.prevStep} className="back-btn">
                      Back
                    </Button>

                    <Button
                      type="submit"
                      // onClick={nextStep}
                      className=" tabs-btn"
                    >
                      Register & Continue
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>

          {/* <div className="col-md-12">
            <div className="text-end apply_now_btn">
              <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p>

              <Button onClick={props.prevStep} className="back-btn">
                Back
              </Button>

              <Button onClick={props.nextStep} className=" tabs-btn">
                Register & Continue
              </Button>
            </div>
          </div> */}

          <TermsAndConditionModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
      </div>
    </>
  );
}

export default Steptwo;
