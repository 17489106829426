import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const btobAddressAdd = async (data) => {
  try {
    const res = await postData(`/btb/btb-shipping-address/add`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const btobAddressList = async (data) => {
  try {
    const res = await getData(`/btb/btb-shipping-address`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const btobEditGetAddress = async (id) => {
  try {
    const res = await getData(`/btb/btb-shipping-address/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const btobAddressDelete = async (id) => {
  try {
    const res = await deleteData(`/btb/btb-shipping-address/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};
