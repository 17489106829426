import React, { useState, useContext, useEffect } from 'react'
import './LoginPage.css'
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faEye, faEyeSlash, fas } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { RegxExpression } from "../../../../utils/common";
import { userSignInPost } from '../../../../utils/apis/myWebsite/mywebsite';
import { Context } from '../../../../utils/context';
import Cookies from "js-cookie";
const LoginPage = () => {



  const [showPassword, setShowPassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [emailerror, setemailErrors] = useState("");

  const { signin, setSignin, setUsertype } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  console.log("errors", errors)


  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const res = await userSignInPost(data)
    if (res?.success) {
      console.log(data, "data");
      Cookies.set("net_purti_security", res.data.token, { expires: 1 });
      setUsertype(res.data?.type);
      setSignin(true);
      navigate('/mywebsites/pricing')
    } else {
      setemailErrors(res?.message);
    }
    console.log(data, "data");
  }

  <FontAwesomeIcon icon={["fas", "angle-left"]} />

  return (
    <section className='login-page' >
      <Container>
        <Link to="/mywebsites/home">
          <div className="back-home">

            <FontAwesomeIcon icon={["fas", "angle-left"]} />

            <p className="back">Back to Home</p>
          </div>
        </Link>
        <Row>
          <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={12} >
            <div className='login-white'>
              <h3 className='page-heading'>Login Page</h3>
              <div className="log_in_form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Email ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="Email"
                      placeholder="Enter Your Email Id"
                      {...register("email", {
                        required: "Email Id required",
                        pattern: {
                          value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                          message: "Email Id Invalid",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.email,
                      })}
                    />
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="">
                    <div className="password-field-holder">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="Password"
                        placeholder="Enter Password"
                        className={classNames("password-input", {
                          "is-invalid": errors?.password,
                        })}
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message: "Password must be at least 8 characters long",
                          },
                          pattern: {
                            value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                            message:
                              "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                          },
                        })}
                      />
                      <div className={classNames("eye-icon-holder", {
                        "error-long-message": errors?.password?.message?.length > 45,
                        "error-short-message": errors?.password?.message && errors?.password?.message?.length <= 45,
                        "no-error": !errors?.password?.message,
                      })}>
                        <FontAwesomeIcon
                          onClick={togglePasswordVisibility}
                          className="eye-icon"
                          icon={showPassword ? faEye : faEyeSlash}
                        />
                      </div>
                      {errors.password && (
                        <span className="text-danger">{errors.password.message}</span>
                      )}
                      {emailerror && <span className="text text-danger">{emailerror}</span>}


                    </div>


                    <Form.Text
                      className="Forgot_password"
                    >
                      <div className='d-flex justify-content-between mt-2'>
                        <Form.Group className="mb-3">
                          <Form.Check
                            name="terms"
                            label="Remember Me"
                            feedbackType="invalid"
                            id="validationFormik0"
                          />
                        </Form.Group>
                        <Link to='/mywebsites/forget-pass'><p className='forget-txtt'>Forgot password?</p></Link>
                      </div>
                    </Form.Text>
                  </Form.Group>

                  <div className="btn-holder text-center">
                    <button className="submit_btn" type="submit">
                      Login <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" className='ms-4' />
                    </button>

                  </div>
                </Form>
                <div className="log_in_with">
                  <p>or Login with social accounts</p>
                </div>
                <div className='d-flex justify-content-center'>
                  <Link to={"https://www.google.co.in/"} target="blank">
                    <div className="social_img_holder">
                      <img
                        className="social_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/MyWebsite/LoginSignup/search (1).png"
                        }
                        alt="social_img"
                      />
                    </div>
                  </Link>
                  <Link to={"https://in.linkedin.com/"} target="blank">
                    <div className="social_img_holder">
                      <img
                        className="social_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/MyWebsite/LoginSignup/linkedin (1).png"
                        }
                        alt="social_img"
                      />
                    </div>
                  </Link>
                  <Link to={"https://www.facebook.com/"} target="blank">
                    <div className="social_img_holder">
                      <img
                        className="social_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/MyWebsite/LoginSignup/facebook.png"
                        }
                        alt="social_img"
                      />
                    </div>
                  </Link>
                </div>
              </div>
              <div className='text-center mt-3'>
                <p className='login-txtt'>New to Netpurti's My Website? <Link to='/mywebsites/signup'><span className='forget-txtt'>Signup</span></Link></p>
                {/* <p className='login-txtt'>New to Netpurti's My Website? <Link to='/mywebsites/login'><span className='forget-txtt'>Login</span></Link></p> */}
              </div>
            </div>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
            <div className='log-video-class'>
              <video className='video-class' autoPlay loop mute   >
                <source src={process.env.PUBLIC_URL + '/assest/images/MyWebsite/video/login.mp4'}></source>
              </video>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

  )
}



export default LoginPage;
