import React from "react";
import StockProduct from "./StockProduct/StockProduct";
import Producttable from "./Producttable/Producttable";
// import CommonDetails from "./CommonDetails/CommonDetails";
const Product = () => {
  return (
    <div>
      <StockProduct />
      <Producttable />
    </div>
  );
};

export default Product;
