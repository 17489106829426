import React, { useContext, useEffect, useState } from "react";
import "./Business_Cards.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// Import Swiper styles
import "swiper/css";
import { Context } from "../../../../../utils/context";
import { getbtb_adv_two_side_section } from "../../../../../utils/apis/btb/Home_Page";
import parse from "html-react-parser";

function Business_Cards() {
  // Define an array of background colors
  const backgroundColors = ["#DB6B00", "#BC5B00", "#8D4603", "#8D4603"];

  // Data for your slides
  const slidesData = [
    {
      imageSrc:
        "/assest/images/B2B_panel/Home/Addvertise_Offerings/Business_Cards_Slider/briefcase.png",
      title: "Countless business offerings",
      description:
        "Discover a myriad of products and suppliers globally to suit your business needs.",
    },
    {
      imageSrc:
        "/assest/images/B2B_panel/Home/Addvertise_Offerings/Business_Cards_Slider/shield.png",
      title: "Guaranteed quality and secure transactions",
      description:
        "Secure high-quality production from validated suppliers, safeguarding your orders from payment to delivery.",
    },
    {
      imageSrc:
        "/assest/images/B2B_panel/Home/Addvertise_Offerings/Business_Cards_Slider/ecommerce.png",
      title: "Comprehensive trading solution",
      description:
        "Effortlessly transition from product and supplier search to order management, payment, and fulfillment.",
    },
    {
      imageSrc:
        "/assest/images/B2B_panel/Home/Addvertise_Offerings/Business_Cards_Slider/discount.png",
      title: "Customized trading journey",
      description:
        "Access tailored benefits for your business growth, including discounts, protection, and support.",
    },
  ];

  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const getData = async () => {
    setData(await getbtb_adv_two_side_section());
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="Business_Cards">        
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2000, // Time between slides in milliseconds (3 seconds here)
            disableOnInteraction: false, // Auto-slide will not stop on user interactions
          }}
          modules={[Pagination, Autoplay]}
          className="Business_Cards_Slider"
        >
          {data?.data?.map((slide, index) => (
            <SwiperSlide key={index}>
              <div
                className="Business_Cards_content"
                style={{
                  backgroundColor:
                    backgroundColors[index % backgroundColors.length],
                }}
              >
                <div className="Business_Cards_img_holder">
                  <img
                    className="Business_Cards_img"
                    src={IMG_URL + slide?.image}
                    alt=""
                  />
                </div>
                <div className="text_holder">
                  <h4>{slide?.name}</h4>
                  <p>{parse(slide?.description)}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default Business_Cards;
