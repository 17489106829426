import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { Link } from "react-router-dom";
import { bankVerify } from "../../../../utils/apis/third_party/third_party";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";
import { RegxExpression } from "../../../../../src/utils/common";
import classNames from "classnames";
import { ShimmerTitle } from "react-shimmer-effects";
import {
  firmBankDetailPost,
  getfirmBankDetail,
} from "../../../../utils/apis/affiliate/affiliate";
library.add(fas);

function Stepfour(props, affiliateDetails, affiliateID) {
  const { shimmerLoader, setShimmerLoader } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    if (verifyStatus) {
      if (firmBankId !== "") {
        data.id = firmBankId;
      }
      data.a_personal_details_id = props.affiliateDetails.a_personal_details_id;
      const res = await firmBankDetailPost(data);
      if (res?.success) {
        props.nextStep();
        props.setBankId(data.id);
      }
    } else {
      setError("ifsc_code", {
        type: "manual",
        message: "Verify your Bank Details",
      });
    }
  };
  const [firmBankId, setFirmBankId] = useState("");

  const getFirmBankDetailIn = async () => {
    const res = await getfirmBankDetail(
      props.affiliateDetails.a_personal_details_id
    );
    if (res?.success) {
      setVerifyStatus(true);
      setFirmBankId(res.data.id);
      reset(res.data);
    }
  };
  const [verifyStatus, setVerifyStatus] = useState(false);
  const verifyIfsc = async () => {
    setShimmerLoader(true);
    const res = await bankVerify(
      getValues("bank_account_no"),
      getValues("ifsc_code")
    );
    if (res?.success) {
      clearErrors();
      setVerifyStatus(true);
      setShimmerLoader(false);
      setValue("bank_name", res?.data?.data?.ifsc_details?.bank_name);
      setValue("branch_name", res?.data?.data?.ifsc_details?.branch);
      setValue("micr_code", res?.data?.data?.ifsc_details?.micr);
    } else {
      setVerifyStatus(false);
      setError("ifsc_code", {
        type: "manual",
        message: res.message.message,
      });
      setShimmerLoader(false);
    }
  };

  useEffect(() => {
    getFirmBankDetailIn();
  }, []);

  return (
    <>
      <div className="personal-details-form mb-0 ">
        <div className="details-form-holder">
          <div className="form-container">
            <div className=" ">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  {shimmerLoader ? (
                    <ShimmerTitle line={1} gap={10} variant="primary" />
                  ) : (
                    <>
                      <div className="col-md-6">
                        <Form.Group className="bankk-verrr" controlId="">
                          <Form.Label>Bank account number</Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.bank_account_no,
                              "is-valid": verifyStatus,
                            })}
                            type="text"
                            name="bank_account_no"
                            placeholder="Enter Bank Account No."
                            {...register("bank_account_no", {
                              required: "Field is required",
                            })}
                            onChange={() => {
                              setError("ifsc_code", "");
                              setVerifyStatus(false);
                            }}
                            onKeyDown={(event) => {
                              if (!RegxExpression.phone.test(event.key)) {
                                if (event.key != "Backspace")
                                  event.preventDefault();
                              }
                            }}
                          />
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <Form.Group className="bankk-verrr" controlId="">
                          <Form.Label>IFSC code</Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.ifsc_code,
                              "is-valid": verifyStatus,
                            })}
                            type="text"
                            name="ifsc_code"
                            placeholder="Enter IFSC Code."
                            {...register("ifsc_code", {
                              required: "Field is required",
                              pattern: {
                                value: RegxExpression.ifsc,
                                message: "Invalid IFSC code",
                              },
                            })}
                            onChange={() => {
                              setError("ifsc_code", "");
                              setVerifyStatus(false);
                            }}
                            onKeyDown={(event) => {
                              if (
                                !RegxExpression.ifsc_code.test(event.key) &&
                                event.key !== "Backspace"
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={verifyIfsc}
                        >
                          Verify Bank Details
                        </button>
                      </div>
                    </>
                  )}

                  <div className="col-md-6">
                    <Form.Group controlId="">
                      <Form.Label>Bank name</Form.Label>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.bank_name,
                        })}
                        disabled
                        type="text"
                        name="bank_name"
                        placeholder="Enter Bank Name"
                        {...register("bank_name", {
                          required: "Field is required",
                        })}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-end apply_now_btn">
                    {/* <p onClick={() => setModalShow(true)}>
                      By continuing, I agree to Netpurti's
                      <span className="termstxt">
                        Terms of Use & Privacy Policy
                      </span>
                    </p> */}
                    <Link to={"/seller/seller-dashboard/"}>
                      <Button className="back-btn me-3">Skip</Button>
                    </Link>
                    <Button onClick={props.prevStep} className="back-btn">
                      Back
                    </Button>

                    <Button type="submit" className=" tabs-btn">
                      Register & Continue
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>

          {/* <div className="col-md-12">
            <div className="text-end apply_now_btn">
              <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p>

              <Button onClick={props.prevStep} className="back-btn">
                Back
              </Button>

              <Button onClick={props.nextStep} className=" tabs-btn">
                Register & Continue
              </Button>
            </div>
          </div> */}

          <TermsAndConditionModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
      </div>
    </>
  );
}

export default Stepfour;
