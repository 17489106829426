import React, { useState, useEffect, useContext } from "react";
import { Form, Modal } from 'react-bootstrap';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import InquiryModal from '../inquiry-Modal/InquiryModal';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { getWorkType } from "../../../../../utils/apis/master/Master";
import { getSectorCategory } from "../../../../../utils/apis/btb/B2b";
import { Context } from "../../../../../utils/context";
const WorkingTypeModal = (props) => {
    const [modalUpdate, setModalUpdate] = React.useState(false);
    const { IMG_URL, Id, postData } =
        useContext(Context);
    const updateSetModal = () => {
        props.onHide();
        reset();
        setModalUpdate(true);
        setTimeout(() => {
            setModalUpdate(false);
        }, 3000);
    };

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        reset,
        setValue,
        formState: { errors },
    } = useForm();


    const onSubmit = async (data) => {
        data.btb_personal_details_id = Id;
        console.log("dataHere", data);
        const res = await postData('/btb/btb-create/btb-sector-category', data);
        updateSetModal()
    };

    const [workTypes, setWorkTypes] = useState([]);
    const [workTypesSelected, setWorkTypesSelected] = useState("");
    const getWorkTypeMaster = async () => {
        const res = await getWorkType();
        if (res?.success) {
            setWorkTypes(res.data);
        }
    }

    const getUserCategory = async () => {
        const res = await getSectorCategory(props.data?.btb_personal_details_id);
        if (res?.success) {
            setWorkTypesSelected(res.data.s_working_type_id);
            // setValue("s_working_type_id", res.data.s_working_type_id)
            reset(res.data);
        }
    };
    console.log("getValues", getValues());
    useEffect(() => {
        getUserCategory();
    }, [props.data]);

    useEffect(() => {
        getWorkTypeMaster();
        setValue('s_working_type_id', props.workType);
    }, []);
    return (
        <>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='edit-details-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Working Type
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="mb-3">
                            {workTypes?.map((value, index) => (
                                <div className="mb-3">
                                    <Form.Check
                                        // checked={workTypesSelected?.id}
                                        type="radio"
                                        label={value?.name}
                                        id={"s_working_type_id" + value?.id}
                                        name="s_working_type_id"
                                        for={value?.name}
                                        value={value?.id}
                                        defaultChecked={index === 0}
                                        {...register("s_working_type_id", { required: "Please select a category" })}
                                    />
                                </div>
                            ))}
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent onClick={props.onHide} className={'btn-cancle'}>Cancel</ButtonComponent>
                    <ButtonComponent onClick={() => handleSubmit(onSubmit(getValues()))} className={'btn-update'} > Update</ButtonComponent>
                </Modal.Footer>
            </Modal>
            <InquiryModal
                message="Working Type Changed Successfully"
                show={modalUpdate}
                onHide={() => setModalUpdate(false)}
            />
        </>
    )
}

export default WorkingTypeModal