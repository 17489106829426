import React, { useState } from "react";
import "./BannerCommon.css";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BreadcrumComponent from "../../../CommonForAll/CommonComponents/ButtonComponent/BreadcrumComponent/BreadcrumComponent";
const BannerCommon = (props) => {
  const [breadcrumbItems, setbreadcrumbItems] = useState();

  const items = [
    { text: "Home", link: "/mywebsites/home" },
    { text: `${props.content}`, link: "#" },
  ];

  return (
    <>
      <section className="Common-Banner">
        <Container>
          <div className="portfolio-content">
          <div className="d-md-flex justify-content-md-between align-items-center text-center">
              <BreadcrumComponent items={items} />
              <div className="heading">
                <p className="whish">{props.heading}</p>
                <p className="maintenance-text">{props.text}</p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default BannerCommon;
