import React, { useEffect, useState, useContext } from "react"
import "../MyProfile.css";
import { Scrollchor } from 'react-scrollchor';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import EditPersonalDetailsModal from "../../../common-components/AllModal/Editpersonal-Detailmodal/EditPersonalDetailsModal";
import WorkingTypeModal from "../../../common-components/AllModal/Editpersonal-Detailmodal/WorkingTypeModal";
import { faUser, faBriefcase, faIndustry, faFilm, faLock, faChevronRight, faPen, faEye } from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../../../../utils/api";
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Stepthree from "./Stepthree/Stepthree";
library.add(fas);

const ThirdModal = () => {
    const [activeTab, setActiveTab] = useState("");
    const [modalWorkingType, setModalWorkingType] = useState(false);
    const { IMG_URL, Id } =
        useContext(Context);
    console.log("ID:---", Id);


    useEffect(() => {
        if (activeTab) {
            const element = document.getElementById(activeTab.slice(1)); // Remove '#' from activeTab
            if (element) {
                const yOffset = -80;
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: "smooth" });
            }
        }
    }, [activeTab]);

    const handleTabClick = (id) => {
        setActiveTab(id);
    };

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm();


    const [child, setChild] = useState([]);
    const getAllData = async () => {
        try {
            const res = await getData(`/btb/btb-create/btb-sector-category/${Id}`);
            const res2 = await getData(`/btb/btb-create/btb-sector-sub-category/${Id}`);
            reset(res?.data);
            setChild(res2?.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(" getValues()", getValues());

    useEffect(() => {
        getAllData();
    }, [Id]);

    return (
        <>
            <div id="sample-code3">
                <div className="card b2bprofileCard">
                    <div className="card-body">
                        <div className="personalDetail">
                            <h4>Industry Details</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="Innernamee">
                                        <p>Category</p>
                                        <h3>{getValues("s_category")?.label}</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="Innernamee">
                                        <p>Sub Categories</p>
                                        <h3>
                                            {getValues("s_sub_category")?.map((data) => data?.label).join(", ")}
                                        </h3>
                                    </div>
                                </div>
                                {/* <div className="col-lg-12">
                                    <div className="Innernamee">
                                        <p>Child Categories</p>
                                        <h3>Over the Range, Built-in, Under Counter, Wall Oven and Microwave Combination</h3>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                    {/* <button className="edit-btn" onClick={() => { setModalWorkingType(true) }}><FontAwesomeIcon icon={faPen} /></button> */}
                </div>
            </div>

            {/* <Stepthree
                data={getValues()}
                show={modalWorkingType}
                onHide={() => { setModalWorkingType(false); getAllData() }}
            /> */}
        </>
    )
}

export default ThirdModal








