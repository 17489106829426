import React from "react";
import "./Addvertise_Offerings.css";
import Business_Cards from "./Business_Cards/Business_Cards";
import Addvertise_Slider from "./Addvertise_Slider/Addvertise_Slider";

function Addvertise_Offerings() {
  return (
    <>
      <div className="Addvertise_Offerings">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5">
              <div className="SelectedTrendingProducts_slider">
                <Business_Cards />
              </div>
            </div>

            <div className="col-lg-8 col-md-7">
              <div className="NewArrivals_slider">
                <Addvertise_Slider />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Addvertise_Offerings;
