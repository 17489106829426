import React, { useContext, useEffect, useState } from "react";
import "./Payment_stepform.css";
import Shipping_address from "./step1/Shipping_address";
import Payment_method from "./step2/Payment_method";
import Delivery_options from "./step3/Delivery_options";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../utils/context";
import { getBuyOrderDetails } from "../../../utils/apis/btb/buy_order_details";
import { useForm } from "react-hook-form";
import {
  faLocationDot,
  faIndianRupeeSign,
  faCube,
} from "@fortawesome/free-solid-svg-icons";
import Product_total from "./product-total/Product_total";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const Payment_stepform = () => {
  const [current, setCurrent] = useState(1);
  const [orderDetail, setOrderDetails] = useState({});
  const stepLabels = ["Shipping address", "Payment Method", "Delivery options"];
  const stepIcons = [faLocationDot, faIndianRupeeSign, faCube];
  const { buyNowData, IMG_URL } = useContext(Context);

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const isStepCompleted = (step) => {
    return step < current;
  };

  console.log("BuyNowData ::____", buyNowData);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const handleNext = () => {
    if (current < 4) {
      setCurrent(current + 1);
    }
  };

  const getData = async () => {
    const data = {
      product_id: buyNowData?.product_id,
      s_p_v_a_d_id: buyNowData?.s_p_v_a_d_id,
      s_p_v_a_one_d_a_d_id: buyNowData?.s_p_v_a_one_d_a_d_id,
      s_p_v_a_o_d_a_d_a_d_id: buyNowData?.s_p_v_a_o_d_a_d_a_d_id,
    };
    console.log(data);
    try {
      const res = await getBuyOrderDetails(data);
      if (res?.success) {
        reset(res.data);
        setValue(
          "discount",
          getValues("total_mrp") - getValues("total_selling")
        );
        setValue("total", getValues("total_selling") + getValues("total_tax"));
      }
    } catch {}
  };

  console.log("orderDetail:----", orderDetail);

  useEffect(() => {
    getData();
  }, [buyNowData]);

  const [address_id, setAddressId] = useState("");
  const [quantity, setQuantity] = useState(orderDetail?.min_quantity);
  const [amount, setAmount] = useState(
    orderDetail?.min_quantity * orderDetail?.selling_price
  );
  const [discount, setDiscount] = useState(
    (orderDetail?.mrp - orderDetail?.selling_price) * quantity
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (Cookies.get("net_purti_security")) {
    } else {
      navigate("/b2bbuyer/login");
    }
  });

  return (
    <>
      <section className="Payment_stepform_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="">
                <section className="Payment_stepform_sec">
                  <Form>
                    {/* <div className={`${current===4 ? "displaycon" : "signup-form "}`}> */}
                    <div className="signup-form ">
                      <h4 className="stepform-heading">Order Now</h4>
                      <hr></hr>
                      <div className="progress-bar-container">
                        <div className="step-row">
                          {stepIcons.map((icon, index) => (
                            <div
                              key={index + 1}
                              className={`step-container ${
                                index + 1 === current ? "active" : ""
                              }`}
                            >
                              <div className="circle-center">
                                <div
                                  className={`circle ${
                                    isStepCompleted(index + 1)
                                      ? "completed"
                                      : ""
                                  }`}
                                >
                                  <FontAwesomeIcon icon={icon} />
                                </div>
                              </div>
                              <div className="step my-auto">
                                <span className="step-text">
                                  {stepLabels[index]}
                                </span>
                              </div>
                              {index < stepIcons.length - 1 && (
                                <div
                                  className={`line-right ${
                                    isStepCompleted(index + 1)
                                      ? "completed"
                                      : ""
                                  }`}
                                ></div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="wizard-form mt-3">
                      {current === 1 && (
                        <Shipping_address
                          handleNext={handleNext}
                          setAddressId={setAddressId}
                          address_id={address_id}
                          IMG_URL={IMG_URL}
                        />
                      )}
                      {/* {current === 2 && (
                        <Payment_method
                          handleNext={handleNext}
                          handlePrevious={handlePrevious}
                        />
                      )} */}
                      {current === 2 && (
                        <Delivery_options
                          handleNext={handleNext}
                          handlePrevious={handlePrevious}
                          address_id={address_id}
                          buyNowData={buyNowData}
                          quantity={quantity}
                          setQuantity={setQuantity}
                          setAmount={setAmount}
                          orderDetail={orderDetail}
                          IMG_URL={IMG_URL}
                          setValue={setValue}
                          getValues={getValues}
                          reset={reset}
                        />
                      )}
                    </div>
                  </Form>
                </section>
              </div>
            </div>

            <div className="col-lg-4">
              <Product_total
                buyNowData={buyNowData}
                quantity={quantity}
                amount={amount}
                IMG_URL={IMG_URL}
                orderDetail={orderDetail}
                getValues={getValues}
                reset={reset}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Payment_stepform;
