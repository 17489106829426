import React, { useState, useEffect, useContext } from "react";
import "./B2b_all_categories.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { AllCategory } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";

const B2b_all_categories = () => {
  const { IMG_URL } = useContext(Context);
  const [category, setCategory] = useState([]);

  const getData = async () => {
    setCategory(await AllCategory());
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="B2b_all_categories_sec">
        <div className="container">
          <div className="row">
            {category?.data?.map((data, index) => (
              <div className="col-md-4 mb-4">
                <Link to={`/b2bbuyer/b2b-bestSeller/${data?.id}/Grocery`}>
                  <div className="cat-div">
                    <div className="d-flex">
                      <div className="icon-div">
                        <FontAwesomeIcon
                          className="fafaicc"
                          icon={IMG_URL + data?.image}
                        />

                        {/* <FontAwesomeIcon className="fafaicc" icon={data.icon} /> */}
                      </div>
                      <div className="name-div my-auto">
                        <h6 className="name">{data.name}</h6>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default B2b_all_categories;
