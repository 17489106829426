import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import Cookies from "js-cookie";
import { Context } from "../../../../utils/context";
import { MediaTypes } from "../../../../utils/apis/master/Master";
import { getSectorCategory } from "../../../../utils/apis/seller/Seller";
import {
  mediaSectorPost,
  getMediaSector,
} from "../../../../utils/apis/media/media";

library.add(fas);

function Steptwo(props) {
  const { getData, postData, IMG_URL } = useContext(Context);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const handleSelectChange = (selectedOption) => {
    setSelectedOptions([...selectedOptions, selectedOption]);
  };

  const handleCloseButtonClick = (optionValue) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.value !== optionValue
    );
    setSelectedOptions(updatedOptions);
  };

  const handleSuggestedSkillClick = (label) => {
    const newOption = { value: label, label: label };
    setSelectedOptions([...selectedOptions, newOption]);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  useEffect(() => {
    reset(props.mediaType);
  }, [props.mediaType]);

  const options = [
    { value: " XD", label: " XD" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  console.log(Cookies.get("media_id"));
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (sectorId != "") {
      data.id = sectorId;
    }
    // data.media_type_id = data.media_type_id;
    data.media_personal_details_id =
      props.mediaDetails.media_personal_details_id;
    const res = await mediaSectorPost(data);
    if (res?.success) {
      console.log(res, "success");
    }
    var selectedLabel = document.getElementById(
      "media_type_id" + data.media_type_id
    );
    console.log("selectedLabel", selectedLabel);

    // props.setMediaTypeName(selectedLabel.getAttribute('for'));
    props.setMediaType(data.media_type_id);
    props.nextStep();
  };

  const [mediaTypes, setMediaTypes] = useState([]);
  const [sectorId, setSectorId] = useState([]);
  const [mediaTypesSelected, setMediaTypesSelected] = useState("");
  const getMediaTypeMaster = async () => {
    const res = await MediaTypes();
    if (res?.success) {
      setMediaTypes(res.data);
    }
  };

  const getMediaCategory = async () => {
    const res = await getMediaSector(
      props.mediaDetails?.media_personal_details_id
    );
    if (res?.success) {
      setMediaTypesSelected(res.data.media_type_id);
      setSectorId(res.data.media_sector_id);
      reset(res.data);
    }
  };
  useEffect(() => {
    getMediaCategory();
  }, []);

  useEffect(() => {
    getMediaTypeMaster();
  }, []);
  return (
    <>
      <div className="personal-details-form mb-0 ">
        {/* form-holder */}
        <div className="details-form-holder">
          <div className="row">
            <Form onSubmit={handleSubmit(onSubmit)}>
              {mediaTypes?.map((value, index) => (
                <div className="mb-3">
                  {/* {props.mediaType} */}
                  <Form.Check
                    type="radio"
                    label={value?.name}
                    id={"media_type_id" + value?.id}
                    name="media_type_id"
                    for={value?.name}
                    value={value?.id}
                    defaultChecked={mediaTypesSelected === value?.id}
                    {...register("media_type_id", {
                      required: "Please select a category",
                    })}
                  />
                </div>
              ))}

              {errors.media_type_id && (
                <span className="text-danger">
                  {errors.media_type_id.message}
                </span>
              )}
              <div className="text-end btnMainn apply_now_btn">
                {/* <p onClick={() => setModalShow(true)}>
                  By continuing, I agree to Netpurti's
                  <span className="termstxt">Terms of Use & Privacy Policy</span>
                </p> */}

                <Link to={"/seller/seller-dashboard/"}>
                  <Button className="back-btn me-3">Skip</Button>
                </Link>
                <div className="registerContinueMain">
                  <Button onClick={props.prevStep} className="back-btn">
                    Back
                  </Button>
                </div>

                <div className="registerContinueMain">
                  <Button
                    type="submit"
                    // onClick={props.nextStep()}
                    className="tabs-btn"
                  >
                    Register & Continue
                  </Button>
                </div>
              </div>
            </Form>
          </div>

          <div className="col-md-12">
            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Steptwo;
