import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./SuccessModal.css";
import Animation from "../Animation/SuccessAnimation/Animation";
const InfluencerAcceptModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="SuccessModalPop"
      >
        <Modal.Body>
          <div className="modalContentinfo">
            <div className="modalHeadInfluencer">
              {/* <h3>SUCCESS!!</h3> */}
              <p>{props?.text}</p>
              <p><b>Note :</b>{props?.note}</p>
            </div>

            <div className="text-center">
              <Button className="btn btn-danger" onClick={props.onHide}>Cancel</Button>
            
              <Button className="btn btn-success ms-3" onClick={() => props.handleTaskStatus(props.data?.id,'accepted')}>Accept</Button>
            </div>

            {/* <div className="text-center">
              <Button className="btn tryagainbtn">Try again</Button>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InfluencerAcceptModal;
