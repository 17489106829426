import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import CreateAccountSeccseccfullyModal from "../../../CommonForAll/CreateAccountSeccseccfullyModal/CreateAccountSeccseccfullyModal";
import {
  firmPasswordPost,
  btbPercentageSend,
} from "../../../../utils/apis/btb/B2b";
import Cookies from 'js-cookie';
const Stepsix = ({ nextStep, prevStep, userDetails, setInnerPage }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEye);
  const [iconTwo, setIconTwo] = useState(faEye);

  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };
  const [modalShow, setModalShow] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [matcherror, setmatchErrors] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);
  const onSubmit = async (data) => {
    console.log(data);
    data.btb_personal_details_id = userDetails?.btb_personal_details_id;
    data.percentage = btbPercentageSend.stepSix;
    if (data.password === data.re_password) {
      const res = await firmPasswordPost(data);
      if (res?.success) {
        console.log(res);
        setModalSuccess(true);
        setTimeout(() => {
          setModalSuccess(false);
          Cookies.remove("btobbuyer_id");
          navigate("/b2bbuyer/buyer-home");
        }, 3000);
      }
    } else {
      setmatchErrors("Password Must Match");
    }
  };


  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="personal-details-form  employee-details-step">
          <div>
            <div className="row stepsixform">
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Enter Password*</Form.Label>
                  <div className="StepsixPassword">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password*"
                      className={classNames("", {
                        "is-invalid": errors?.password,
                      })}
                      name="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Password must be at least 8 characters long",
                        },
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                          message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                        }
                      })}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <PasswordChecklist
                      rules={["minLength", "specialChar", "number", "capital", "match"]}
                      minLength={8}
                      value={password}
                      valueAgain={passwordAgain}
                      onChange={(isValid) => { }}
                    />
                    <FontAwesomeIcon
                      icon={iconOne}
                      className="StepsixeditIcon"
                      onClick={() => togglePasswordVisibility("password")}
                    />
                    {errors?.password && (
                      <span className="text text-danger">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="reenterPassword">
                  <Form.Label>Re-Enter Password*</Form.Label>
                  <div className="StepsixPassword">
                    <Form.Control
                      type={showPasswordOne ? "text" : "password"}
                      placeholder="Re-Enter Password*"
                      className={classNames("", {
                        "is-invalid": errors?.re_password,
                      })}
                      name="re_password"
                      {...register("re_password", {
                        required: "Re-Password is required",
                      })}
                      onChange={e => setPasswordAgain(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={iconTwo}
                      className="StepsixeditIcon"
                      onClick={() =>
                        togglePasswordVisibility("reenterPassword")
                      }
                    />
                    {errors?.re_password && (
                      <span className="text text-danger">
                        {errors.re_password.message}
                      </span>
                    )}
                  </div>
                </Form.Group>
              </div>
              {matcherror && (
                <span className="text text-danger">{matcherror}</span>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="text-end btnMainn apply_now_btn">
              {/* <p onClick={() => setModalShow(true)}>
              By continuing, I agree to Netpurti's
              <span className="termstxt">Terms of Use & Privacy Policy</span>
            </p> */}
              {/* <Link to={"/seller/seller-dashboard/"}>
                <Button className="back-btn me-3">Skip</Button>
              </Link> */}

              <div className="registerContinueMain">
                <Button onClick={prevStep} className="back-btn">
                  Back
                </Button>
              </div>

              <div className="registerContinueMain">
                <Button className="tabs-btn" type="submit">
                  Submit
                </Button>

              </div>
              <TermsAndConditionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              <CreateAccountSeccseccfullyModal
                show={modalSuccess}
                onHide={() => setModalSuccess(false)}
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Stepsix;
