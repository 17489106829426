import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { get_particular_policy } from '../../../utils/apis/master/Master';

const Policies = () => {

    const { id } = useParams()
    const [policies, setPolicies] = useState([]);

    const getData = async () => {
        setPolicies(await get_particular_policy(id));
    };

    useEffect(() => {
        getData();
    }, [])

    console.log("policies", policies, id)

    return (
        <section className='privacy-policy'>
            <div className='container'>
                <div className='row'>

                    {policies?.data && (

                        <div className='heading-holder'>
                            <h1>{policies?.data?.name}</h1>
                            <div
                                dangerouslySetInnerHTML={{ __html: policies?.data?.description }}
                            ></div>
                        </div>


                    )}

                </div>
            </div>
        </section>
    )
}

export default Policies
