import React from "react";
import "./B2bproducts-table.css";
import Stock_list from "./stock-list/Stock_list";
import B2b_table from "./b2b-table/B2b_table";

const B2bproducts_table = () => {
  return (
    <>
      <section className="">
        <Stock_list />
        <B2b_table />
      </section>
    </>
  );
};

export default B2bproducts_table;
