import React, { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./AddTocart.css";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getMainProductDetails } from "../../../../../utils/apis/btb/Main_Product";
import { btobCartAdd } from "../../../../../utils/apis/btb/Cart";
import { Context } from "../../../../../utils/context";
import ErrorModal from "../../../../CommonForAll/ErrorModalDynamic/ErrorModal";
import ComingSoonModal from "../../../../CommonForAll/CommingSoonModal/CommingSoonModal";
const AddTocart = ({
  name,
  IMG_URL,
  // singleVariant,
  // getValues,
  // MultipleVariantTwo,
  // MultipleVariantThree,
  ...props
}) => {
  const { product_id } = useParams();
  const [cart, setCart] = useState([]);
  const [messageShow, setMssageShow] = useState(false);
  const { signin, setBuyNowData } = useContext(Context);
  const handleQuantityChange = (index, quantity) => {
    const newQuantities = [...cart];
    newQuantities[index].quantity += quantity;
    setCart(newQuantities);
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    cart.forEach((item, index) => {
      totalPrice += item.quantity * item.selling_price;
    });
    return totalPrice;
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [images, setImages] = useState([]);

  const [singleVariant, setSingleVariant] = useState(false);
  const [MultipleVariantTwo, setMultipleVariantTwo] = useState(false);
  const [MultipleVariantThree, setMultipleVariantThree] = useState(false);

  const [data, setData] = useState({});
  // const getData = async () => {
  //   try {
  //     const res = await getMainProductDetails(product_id);

  //     setValue("seller_id", res.data?.s_supplyer_id);
  //     setValue("product_id", res.data?.id);
  //     setData(res.data);
  //     setValue("product_title", res.data?.product_title);
  //     setValue(
  //       "customization",
  //       res.data?.s_product_customization?.s_customization?.name
  //     );
  //     setValue("createdAt", res.data?.createdAt);
  //     if (res.data.s_product_variant.variant_type === "Single") {
  //       // setData(res.data);
  //       // setValue("product_title", res.data?.product_title);
  //       // setValue(
  //       //   "customization",
  //       //   res.data?.s_product_customization?.s_customization?.name
  //       // );

  //       setValue(
  //         "s_p_v_a_d_id",
  //         res.data?.s_product_variant?.s_product_variant_attribute
  //           ?.s_product_variant_attribute_details?.[0]?.id
  //       );

  //       setValue(
  //         "variant",
  //         res.data?.s_product_variant?.s_product_variant_attribute?.s_attribute
  //           ?.name
  //       );
  //       setValue(
  //         "variant_details",
  //         res.data?.s_product_variant?.s_product_variant_attribute
  //           ?.s_product_variant_attribute_details
  //       );

  //       setValue(
  //         "sku_id",
  //         res.data?.s_product_variant?.s_product_variant_attribute
  //           ?.s_product_variant_attribute_details?.[0]?.sku_id
  //       );
  //       setValue(
  //         "mrp",
  //         res.data?.s_product_variant?.s_product_variant_attribute
  //           ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
  //           ?.mrp
  //       );

  //       setValue(
  //         "selling_price",
  //         res.data?.s_product_variant?.s_product_variant_attribute
  //           ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
  //           ?.selling_price
  //       );

  //       setValue(
  //         "minimum_order_quantity",
  //         res.data?.s_product_variant?.s_product_variant_attribute
  //           ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
  //           ?.minimum_order_quantity
  //       );
  //       setSingleVariant(true);
  //       setImages(
  //         res.data?.s_product_variant?.s_product_variant_attribute
  //           ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_images
  //       );
  //     } else if (res.data.s_product_variant.variant_type === "Multiple") {
  //       if (
  //         res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //           ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //           ?.s_p_v_a_one_d_a_d_as.length == 0
  //       ) {
  //         // setData(res.data);
  //         // setValue("product_title", res.data?.product_title);
  //         // setValue(
  //         //   "customization",
  //         //   res.data?.s_product_customization?.s_customization?.name
  //         // );
  //         setValue(
  //           "variant",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
  //         );
  //         setValue(
  //           "variant_details",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
  //         );

  //         setValue(
  //           "variant_one",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
  //         );

  //         setValue(
  //           "variant_one_details",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
  //         );

  //         setValue(
  //           "sku_id",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.sku_id
  //         );
  //         setValue(
  //           "s_p_v_a_one_d_a_d_id",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.id
  //         );

  //         setValue(
  //           "mrp",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
  //         );

  //         setValue(
  //           "selling_price",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
  //         );
  //         setValue(
  //           "minimum_order_quantity",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.minimum_order_quantity
  //         );
  //         setMultipleVariantTwo(true);
  //         setImages(
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_one_d_a_d_a_d_images
  //         );
  //       } else {
  //         // setData(res.data);
  //         // setValue("product_title", res.data?.product_title);
  //         // setValue(
  //         //   "customization",
  //         //   res.data?.s_product_customization?.s_customization?.name
  //         // );
  //         setValue(
  //           "variant",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
  //         );
  //         setValue(
  //           "variant_details",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
  //         );

  //         setValue(
  //           "variant_one",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
  //         );

  //         setValue(
  //           "variant_one_details",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
  //         );

  //         setValue(
  //           "variant_two",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
  //         );

  //         setValue(
  //           "variant_two_details",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds
  //         );

  //         setValue(
  //           "sku_id",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
  //         );

  //         setValue(
  //           "s_p_v_a_o_d_a_d_a_d_id",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
  //         );

  //         setValue(
  //           "min_quantity",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
  //             ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.minimum_order_quantity
  //         );

  //         setValue(
  //           "mrp",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
  //             ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
  //         );

  //         setValue(
  //           "selling_price",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
  //             ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
  //         );
  //         setValue(
  //           "minimum_order_quantity",
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
  //             ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.minimum_order_quantity
  //         );

  //         setImages(
  //           res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
  //             ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
  //             ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
  //             ?.s_p_v_a_one_d_a_d_a_d_images
  //         );
  //         setMultipleVariantThree(true);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getData = async () => {
    try {
      reset();
      setSingleVariant(false);
      setMultipleVariantTwo(false);
      setMultipleVariantThree(false);

      const res = await getMainProductDetails(product_id);

      setValue("seller_id", res.data?.s_supplyer_id);
      setValue("createdAt", res.data?.createdAt);
      setValue("product_id", res.data?.id);
      setData(res.data);
      setValue("product_title", res.data?.product_title);
      setValue(
        "customization",
        res.data?.s_product_customization?.s_product_type?.name
      );
      setValue(
        "product_type_id",
        res.data?.s_product_customization?.s_product_type?.id
      );
      setValue(
        "s_brand",
        res.data.supply_type == "Custom" ||
          res.data.supply_type == "Self Manufactured"
          ? res.data?.s_products_supply_type?.s_custom?.name
          : res.data?.s_brand?.name
      );
      if (res.data.s_product_variant.variant_type === "Single") {
        // setData(res.data);
        // setValue("product_title", res.data?.product_title);
        // setValue(
        //   "customization",
        //   res.data?.s_product_customization?.s_customization?.name
        // );
        setValue(
          "variant",
          res.data?.s_product_variant?.s_product_variant_attribute?.s_attribute
            ?.name
        );
        setValue(
          "variant_details",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details
        );

        setValue(
          "s_p_t_descriptions",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details[0]?.s_p_t_descriptions
        );

        setValue(
          "sku_id",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.sku_id
        );
        setValue(
          "mrp",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
            ?.mrp
        );

        setValue(
          "selling_price",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
            ?.selling_price
        );

        setValue(
          "thumbnail",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.thumbnail
        );

        setValue(
          "s_p_v_a_d_id",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.id
        );

        setValue(
          "minimum_order_quantity",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
            ?.minimum_order_quantity
        );
        setSingleVariant(true);
        setImages(
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_images
        );
      } else if (res.data.s_product_variant.variant_type === "Multiple") {
        if (
          res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
            ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
            ?.s_p_v_a_one_d_a_d_as.length == 0
        ) {
          // setData(res.data);
          // setValue("product_title", res.data?.product_title);
          // setValue(
          //   "customization",
          //   res.data?.s_product_customization?.s_customization?.name
          // );
          setValue(
            "variant",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
          );
          setValue(
            "variant_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
          );

          setValue(
            "variant_one",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
          );

          setValue(
            "variant_one_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
          );

          setValue(
            "s_p_t_descriptions",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds[0]?.s_p_t_descriptions
          );

          setValue(
            "sku_id",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.sku_id
          );
          setValue(
            "mrp",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
          );

          setValue(
            "selling_price",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
          );

          setValue(
            "thumbnail",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_o_d?.thumbnail
          );

          setValue(
            "s_p_v_a_one_d_a_d_id",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.id
          );

          setValue(
            "minimum_order_quantity",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.minimum_order_quantity
          );
          setMultipleVariantTwo(true);
          setImages(
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_a_d_images
          );
        } else {
          // setData(res.data);
          // setValue("product_title", res.data?.product_title);
          // setValue(
          //   "customization",
          //   res.data?.s_product_customization?.s_customization?.name
          // );
          setValue(
            "variant",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
          );
          setValue(
            "variant_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
          );

          setValue(
            "variant_one",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
          );

          setValue(
            "variant_one_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
          );

          setValue(
            "variant_two",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
          );

          setValue(
            "variant_two_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds
          );

          setValue(
            "s_p_t_descriptions",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds[0]
              ?.s_p_t_descriptions
          );

          setValue(
            "sku_id",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
          );
          setValue(
            "mrp",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
          );

          setValue(
            "selling_price",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
          );

          setValue(
            "thumbnail",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_o_d?.thumbnail
          );

          setValue(
            "s_p_v_a_o_d_a_d_a_d_id",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
          );

          setValue(
            "minimum_order_quantity",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.minimum_order_quantity
          );

          setImages(
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_a_d_images
          );
          setMultipleVariantThree(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeVariant = async (data, type = "Single", level = "1") => {
    if (type === "Single" && level === "1") {
      setValue("sku_id", data?.sku_id);
      setValue("mrp", data?.s_p_v_a_d_price_a_stock?.mrp);
      setValue("selling_price", data?.s_p_v_a_d_price_a_stock?.selling_price);
      setValue("s_p_v_a_d_id", data?.id);
      setValue(
        "minimum_order_quantity",
        data?.s_p_v_a_d_price_a_stock?.minimum_order_quantity
      );
    } else if (type === "Multiple" && level === "2") {
      setValue("sku_id", data?.sku_id);
      setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
      setValue(
        "selling_price",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
      );
      setValue(
        "minimum_order_quantity",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.minimum_order_quantity
      );
      setValue("s_p_v_a_one_d_a_d_id", data?.id);
    } else if (type === "Multiple" && level === "3") {
      setValue("sku_id", data?.sku_id);
      setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
      setValue(
        "minimum_order_quantity",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.minimum_order_quantity
      );
      setValue(
        "selling_price",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
      );

      setValue("s_p_v_a_o_d_a_d_a_d_id", data?.id);
    }
  };

  useEffect(() => {
    const details = getValues("variant_details");
    setValue("activeVariant", details?.[0]?.id);
  }, [getValues("variant_details")]);

  useEffect(() => {
    if (MultipleVariantTwo) {
      const details = getValues("variant_one_details");
      setValue("activeSubVariant", details?.[0]?.id);
    } else if (MultipleVariantThree) {
      const details = getValues("variant_one_details");
      setValue("activeSubVariant", details?.[0]?.id);
    }
  }, [getValues("variant_one_details")]);

  useEffect(() => {
    if (MultipleVariantThree) {
      const details = getValues("variant_two_details");
      setValue("activeSubChildVariant", details?.[0]?.id);
    }
  }, [getValues("variant_two_details")]);

  // console.log(getValues());

  useEffect(() => {
    if (product_id) {
      getData();
    }
  }, [product_id]);

  const navigate = useNavigate();
  const SubmitCart = async () => {
    const data = {
      product_id: getValues("product_id"),
      seller_id: getValues("seller_id"),
      s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
      s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
      s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
      min_quantity: getValues("minimum_order_quantity"),
      selling_price: getValues("selling_price"),
      sku_id: getValues("sku_id"),
      product_title: getValues("product_title"),
      quantity: getValues("minimum_order_quantity"),
      product_generic_name: getValues("product_generic_name"),
      sold_by: getValues("s_brand"),
      product_type: getValues("customization"),
      createdAt: getValues("createdAt"),
      thumbnail: getValues("thumbnail"),
    };

    console.log(data);

    const res = await btobCartAdd(data);

    navigate("/b2bbuyer/shopping-card");
  };

  console.log(getValues());

  const OnBuyNow = async () => {
    const data = {
      product_id: getValues("product_id"),
      seller_id: getValues("seller_id"),
      s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
      s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
      s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
      min_quantity: getValues("minimum_order_quantity"),
      selling_price: getValues("selling_price"),
      sku_id: getValues("sku_id"),
      product_title: getValues("product_title"),
      quantity: getValues("minimum_order_quantity"),
    };
    setBuyNowData(data);
    navigate("/b2bbuyer/b2b-payment");
  };

  return (
    <>
      <Offcanvas
        className="moddd OffcanvasAddtocard "
        show={props.show}
        onHide={props.handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Select variations
            {/* and quantity */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="OffAddtocart">
            <div className="ProdpricebeforeShip">
              <h3>Product Price before shipping</h3>
              {/* <p>Minimum order quantity: 50 pieces</p> */}
              {/* <h2>₹ {getTotalPrice()}</h2> */}
              <h2>₹ {getValues("selling_price")}</h2>
            </div>

            {singleVariant && (
              <>
                <div className="canvasVariants">
                  <p class="product-id mt-2">Variations</p>
                  <div className="varainttype">
                    <p class="quantity">1. {getValues("variant")}</p>
                    <div class="standard-section">
                      {getValues("variant_details")?.map((item, index) => (
                        <Button
                          variant="standard"
                          className={
                            getValues("activeVariant") === item?.id
                              ? "selected-btn"
                              : "unselected-btn"
                          }
                          key={item?.id ?? index}
                          onClick={async () => {
                            await setValue("activeVariant", item?.id);
                            await onChangeVariant(item, "Single", "1");
                            await setImages(item?.s_p_v_a_d_images);
                          }}
                        >
                          {item?.s_sub_attribute?.name ?? "Unknown"}
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}

            {MultipleVariantTwo && (
              <>
                <p className="product-id">Variations</p>
                <p className="quantity">1. {getValues("variant")}</p>

                <div className="standard-section">
                  {getValues("variant_details")?.map((item, index) => (
                    <Button
                      variant="standard"
                      className={
                        getValues("activeVariant") === item?.id
                          ? "selected-btn"
                          : "unselected-btn"
                      }
                      key={index}
                      onClick={async () => {
                        await setValue("activeVariant", item?.id);
                        await setValue(
                          "activeSubVariant",
                          item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.id
                        );
                        await setValue(
                          "variant_one",
                          item?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name ?? ""
                        );

                        await setValue(
                          "variant_one_details",
                          item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds ?? []
                        );

                        onChangeVariant(
                          getValues("variant_one_details")?.[0],
                          "Multiple",
                          "2"
                        );

                        await setImages(
                          item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                        );
                      }}
                    >
                      {item?.s_sub_attribute?.name ?? "Unknown"}
                    </Button>
                  ))}
                </div>

                <p className="quantity">2. {getValues("variant_one")}</p>

                <div className="standard-section">
                  {getValues("variant_one_details")?.map((item, index) => (
                    <Button
                      variant="standard"
                      className={
                        getValues("activeSubVariant") === item?.id
                          ? "selected-btn"
                          : "unselected-btn"
                      }
                      key={item?.id ?? index}
                      onClick={async () => {
                        await setValue("activeSubVariant", item?.id);
                        onChangeVariant(item, "Multiple", "2");
                        setImages(item?.s_p_v_a_one_d_a_d_a_d_images ?? []);
                      }}
                    >
                      {item?.s_sub_attribute?.name ?? "Unknown"}
                    </Button>
                  ))}
                </div>
              </>
            )}

            {MultipleVariantThree && (
              <>
                <p className="product-id">Variations</p>
                <p className="quantity">1. {getValues("variant")}</p>

                <div className="standard-section">
                  {getValues("variant_details")?.map((item, index) => (
                    <Button
                      variant="standard"
                      className={
                        getValues("activeVariant") === item?.id
                          ? "selected-btn"
                          : "unselected-btn"
                      }
                      key={item?.id ?? index}
                      onClick={async () => {
                        await setValue("activeVariant", item?.id);

                        await setValue(
                          "activeSubVariant",
                          item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.id
                        );

                        await setValue(
                          "activeSubChildVariant",
                          item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_as?.[0]
                            ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
                        );

                        await setValue(
                          "variant_one",
                          item?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name ?? ""
                        );

                        await setValue(
                          "variant_one_details",
                          item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds ?? []
                        );

                        setValue(
                          "variant_two",
                          item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
                        );

                        setValue(
                          "variant_two_details",
                          item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds
                        );

                        setValue(
                          "sku_id",
                          item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_as?.[0]
                            ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                        );
                        setValue(
                          "mrp",
                          item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_as?.[0]
                            ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                            ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                        );

                        setImages(
                          item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_as?.[0]
                            ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                        );
                      }}
                    >
                      {item?.s_sub_attribute?.name ?? "Unknown"}
                    </Button>
                  ))}
                </div>

                <p className="quantity">2. {getValues("variant_one")}</p>

                <div className="standard-section">
                  {getValues("variant_one_details")?.map((item, index) => (
                    <Button
                      variant="standard"
                      className={
                        getValues("activeSubVariant") === item?.id
                          ? "selected-btn"
                          : "unselected-btn"
                      }
                      key={item?.id ?? index}
                      onClick={async () => {
                        await setValue("activeSubVariant", item?.id);
                        await setValue(
                          "activeSubChildVariant",
                          item?.s_p_v_a_one_d_a_d_as?.[0]
                            ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
                        );
                        setValue(
                          "variant_two",
                          item?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
                        );

                        setValue(
                          "variant_two_details",
                          item?.s_p_v_a_one_d_a_d_as?.[0]
                            ?.s_p_v_a_one_d_a_d_a_ds
                        );

                        setValue(
                          "sku_id",
                          item?.s_p_v_a_one_d_a_d_as?.[0]
                            ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                        );
                        setValue(
                          "mrp",
                          item?.s_p_v_a_one_d_a_d_as?.[0]
                            ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                            ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                        );

                        setImages(
                          item?.s_p_v_a_one_d_a_d_as?.[0]
                            ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                        );
                      }}
                    >
                      {item?.s_sub_attribute?.name ?? "Unknown"}
                    </Button>
                  ))}
                </div>

                <p className="quantity">3. {getValues("variant_two")}</p>

                <div className="standard-section">
                  {getValues("variant_two_details")?.map((item, index) => (
                    <Button
                      variant="standard"
                      className={
                        getValues("activeSubChildVariant") === item?.id
                          ? "selected-btn"
                          : "unselected-btn"
                      }
                      key={item?.id ?? index}
                      onClick={async () => {
                        await setValue("activeSubChildVariant", item?.id);
                        onChangeVariant(item, "Multiple", "3");
                        setImages(item?.s_p_v_a_one_d_a_d_a_d_images ?? []);
                      }}
                    >
                      {item?.s_sub_attribute?.name ?? "Unknown"}
                    </Button>
                  ))}
                </div>
              </>
            )}
          </div>

          {/* <div className="canvasVariants OffAddtocart">
            <p class="product-id mt-2">Variations</p>
            <div className="varainttype">
              <p class="quantity">1. Color</p>
              <div class="standard-section">
                <button type="button" class="btn btn-standard">
                  Black
                </button>
                <button type="button" class="btn btn-standard">
                  {" "}
                  Red{" "}
                </button>
                <button type="button" class="btn btn-standard">
                  {" "}
                  Gray
                </button>
              </div>
            </div>

            <div className="varainttype">
              <p class="quantity">1. Color</p>
              <div class="standard-section">
                <button type="button" class="btn btn-standard">
                  Black
                </button>
                <button type="button" class="btn btn-standard">
                  {" "}
                  Red{" "}
                </button>
                <button type="button" class="btn btn-standard">
                  {" "}
                  Gray
                </button>
              </div>
            </div>

            <div className="varainttype">
              <p class="quantity">1. Color</p>
              <div class="standard-section">
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                    <div className="colourTextsec">
                      <div className="colourImg">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/images/B2Bbuyer/BestSeller/productImg2.png"
                          }
                          alt={"color"}
                        />
                      </div>
                      <div className="my-auto">
                        <p>red</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3  col-md-3 col-sm-3 col-3 my-auto">
                    <p className="mb-0">₹ 1000</p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                    <div className="increDecresec">
                      <div className="qty-input">
                        <button
                          className="qty-count qty-count--minus"
                          data-action="minus"
                          type="button"
                          // onClick={() => handleQuantityChange(index, -1)}
                        >
                          -
                        </button>
                        <span className="product-qty">5</span>
                        <button
                          className="qty-count qty-count--add"
                          data-action="add"
                          type="button"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="row subtotalMain">
            {/* <div className="Subtotal">
              <p>Item subtotal (2 variations 8 items)</p>
              <p>₹ 80000</p>
            </div>
            <div className="Subtotal">
              <p>Tax</p>
              <p>4%</p>
            </div>
            <div className="Subtotal">
              <p>Shipping total</p>
              <p>₹ 80000</p>
            </div>

            <div className="Subtotal">
              <p style={{ fontWeight: "600" }}>Subtotal</p>
              <p style={{ fontWeight: "600" }}>₹ 80000</p>
            </div> */}

            <div className="AddtoCardbtn">
              {/* <Link to={"/b2bbuyer/b2b-payment"}> */}
              <Button
                className="btn btn-buy me-2"
                // onClick={OnBuyNow}
                onClick={() => setMssageShow(true)}
              >
                Buy Now
              </Button>

              {/* </Link> */}
              {/* <Link to={""}> */}
              <Button className="btn btn-add" onClick={SubmitCart}>
                Add to Cart
              </Button>
              {/* </Link> */}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <ComingSoonModal
        onHide={() => setMssageShow(false)}
        show={messageShow}
        heading="Comming soon..."
        text="Website is under construction"
      />
    </>
  );
};

export default AddTocart;
