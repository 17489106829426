import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
function Noservice() {
    return (
        <>
            <section className='noservice-tab'>
                <div className='m-4 text-center'>
                <FontAwesomeIcon icon="fa-solid fa-xmark" />
                    <p className='grey-text'>No Service for this product</p>
                </div>
            </section>
        </>
    )
}

export default Noservice