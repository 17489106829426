import React from 'react'
import BannerCommon from '../CommonComponents/BannerCommon/BannerCommon'
import BlogContent from './BlogContent/BlogContent'
import Comment from './Comment/Comment'
const BlogsPage = () => {
  return (
    <>
      <BannerCommon heading={"Blogs"} content={"Blogs"} />
      <BlogContent />

    </>
  )
}

export default BlogsPage
