import React, { useContext, useEffect, useState } from "react";
import "./Procurement_Customized_Slider.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// Import Swiper styles
import "swiper/css";
import { Context } from "../../../../../utils/context";
import { IndustryType } from "../../../../../utils/apis/master/Master";
import parse from "html-react-parser";
function Procurement_Customized_Slider() {
  // Data for each slide
  const slidesData = [
    {
      imgSrc:
        "/assest/images/B2B_panel/Home/Procurement_Customized/Industry.png",
      title: "Source from Key Industry Centers",
      listItems: [
        "Original Products",
        "Showcasing Manufacturing",
        "Industry Competitiveness",
      ],
    },
    {
      imgSrc: "/assest/images/B2B_panel/Home/Procurement_Customized/MEI.png",
      title: "Product that has won the MEI Award",
      listItems: [
        "Excellence in Manufacturing",
        "Innovative Design",
        "Awarded by industry expert",
      ],
    },
    {
      imgSrc:
        "/assest/images/B2B_panel/Home/Procurement_Customized/Personalize.png",
      title: "Personalize Your Product",
      listItems: [
        "Customizable logo / Size / Banner",
        "Make your own design",
        "Sample available",
      ],
    },
    {
      imgSrc: "/assest/images/B2B_panel/Home/Procurement_Customized/Expo.png",
      title: "Intelligent Expo",
      listItems: ["Reliable Suppliers", "Recommended as wordl's famous"],
    },
    // Add more data for additional slides as needed
  ];

  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState({
    data: [],
  });
  const getData = async () => {
    setData(await IndustryType());
  };

  console.log(data);
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="Procurement_Customized_Slider">
        <div className="container">
          <Swiper
            spaceBetween={10}
            slidesPerView={4}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2000, // Time between slides in milliseconds (3 seconds here)
              disableOnInteraction: false, // Auto-slide will not stop on user interactions
            }}
            modules={[Pagination,Autoplay]}
            className="Services_Swiper"
            breakpoints={{
              // when window width is >= 280px
              280: {
                slidesPerView: 1,
              },
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
              },
              // when window width is >= 485px
              485: {
                slidesPerView: 2,
              },

              // when window width is >= 576px
              576: {
                slidesPerView: 2,
              },

              // when window width is >= 768px
              768: {
                slidesPerView: 2,
              },
              // when window width is >= 992px
              992: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
          >
            {/* Use map function to render SwiperSlide dynamically */}
            {data?.data?.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="Services_card">
                  <div className="img_overlay_holder">
                    <div className="Services_card_img_holder">
                      <img
                        className="Services_card_img"
                        src={IMG_URL + slide?.image}
                        alt="Services_card_img"
                      />
                    </div>
                    <div className="overlay"></div>
                  </div>
                  <div className="text-holder">
                    <h4>{slide?.name}</h4>
                    <div className="services_list">
                      <ul>
                        <div className="services_list">
                          {parse(slide?.description)}
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Procurement_Customized_Slider;
