import React, { useState } from "react";
import "./Comment.css";
import Button from "react-bootstrap/Button";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { RegxExpression } from "../../../../utils/common";
import { CommentsPost } from "../../../../utils/apis/myWebsite/mywebsite";
import { Context } from "../../../../utils/context";
import SuccessModal from "../../../CommonForAll/SuccessModal/SuccessModal"


function Comment({ blog_id }) {


    const [successModalShow, setShowModal] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        setError,
        getValues,
        reset,
        setValue,
        formState: { errors },
    } = useForm();


    const onSubmit = async (data) => {
        // data.blog_id = blog_id
        console.log(data, "data");
        const res = await CommentsPost(data, blog_id);
        if (res?.success) {
            setShowModal(true)
            setTimeout(() => {
                reset();
                setShowModal(false);
            }, 3000);
        }
    }


return (
    <>
        <section className="leavecomment_section">
            <h4 className="leavecomment_heading">Leave a comment</h4>
            <Container>
                <div className="leavecomment_main">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="leaveForm">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className="leaveform_label">Full Name*</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter your full name"
                                            {...register("name", {
                                                required: "Full Name is Required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.name,
                                                "is-valid": getValues("name"),
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        {errors.name && (
                                            <span className="text-danger">
                                                {errors.name.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className="leaveform_label">Email ID*</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter your Email ID"
                                            {...register("email", {
                                                required: "Email Id required",
                                                pattern: {
                                                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                                    message: "Email Id Invalid",
                                                },
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.email,
                                                "is-valid": getValues("email"),
                                            })}
                                        />
                                        {errors.email && (
                                            <span className="text-danger">
                                                {errors.email.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label className="leaveform_label">Messages</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={6}
                                            placeholder="Enter your Message"
                                            {...register("description", {
                                                required: "description is Required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.description,
                                                "is-valid": getValues("description"),
                                            })}
                                        // onKeyDown={(event) => {
                                        //     if (!RegxExpression.name.test(event.key)) {
                                        //         event.preventDefault();
                                        //     }
                                        // }}
                                        />
                                        {errors.description && (
                                            <span className="text-danger">
                                                {errors.description.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className="leavecommentbttondiv">
                            <Button variant="Browsing" type="submit" className="leavecommentbutton">
                                Send Messages
                            </Button>
                            {/* <button className=""></button> */}
                        </div>

                    </Form>
                </div>
            </Container>
            <SuccessModal show={successModalShow} />
        </section>
    </>
);
}

export default Comment;
