import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'; // Import OverlayTrigger and Tooltip
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JoditEditor from "jodit-react";
import classNames from "classnames";

import '../CommonDetails.css'

function Expiry({ reset, errors, register,getValues }) {
  const [content, setContent] = useState("");
  return (
    <>
      <section className='expiry-tab'>
        <div className='py-2'>
          <div className='row'>
            <div className='col-xxl-4 col-lg-4 col-md-4'>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>
                  Expiry (in Days) <span className='star'>*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-title">Expiry (in Days)</Tooltip>}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-circle-info" className='ms-3' />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control type="number" placeholder="Expiry (in Days)"
                  className={classNames("", {
                    "is-invalid": errors?.expiry_date,
                  })}

                  name="expiry_date"
                  {...register("expiry_date", {
                    required: getValues("s_service_term_id") == 4
                    ? "Expiry (in Days) is required":false,
                  })}
                />
              </Form.Group>
            </div>
          </div>
        </div>

      </section>


    </>
  )
}

export default Expiry