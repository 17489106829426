import React, { useEffect, useState, useContext } from 'react'
import MyFaqBanner from './MyFaqBanner/MyFaqBanner'
import HelpCenterTab from '../UserDetails/HelpCenter/HelpCenterTab/HelpCenterTab'

// import { BlogsGet,BlogsSingleGet } from "../../../../utils/apis/myWebsite/mywebsite";
import { FAQSGet } from "../../../utils/apis/myWebsite/mywebsite"
import { Context } from '../../../utils/context';


const MyFaqPage = () => {



    const { IMG_URL } = useContext(Context);
    

    const [fAQSData, setFAQSData] = useState();
    const getFAQSData = async () => {
        const res = await FAQSGet();
        if (res?.success) {
            setFAQSData(res?.data);
        }
    }

    useEffect(() => {
        getFAQSData();
    }, [])


    return (
        <>
            <MyFaqBanner />
            <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/faq/faq.png"} className="banner-img" alt="..." />
            <div className='Faq-Content'>

                <HelpCenterTab fAQSData={fAQSData} />
            </div>
        </>
    )
}

export default MyFaqPage