import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TableImageZoom = (props) => {

    const mystyle = {
        padding: "0px",
      };

      const modimage = {
        width:"100%",
      };
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='TableImageZoom'
            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">                       
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body  style={mystyle}>
                    <img style={modimage} src={props.file} />
                </Modal.Body>

                    {/* <Modal.Footer>
                        <Button onClick={props.onHide}>Close</Button>
                    </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default TableImageZoom