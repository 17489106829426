import { useState, useEffect, useContext } from "react";
import "./B2bbuyerstepForm.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-datepicker/dist/react-datepicker.css";
import Stepone from "./Stepone/Stepone";
import Steptwo from "./Steptwo/Steptwo";
import Stepthree from "./Stepthree/Stepthree";
import Stepfour from "./Stepfour/Stepfour";
import Stepfive from "./Stepfive/Stepfive";
import Stepsix from "./Stepsix/Stepsix";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { btbPercentage } from "../../../utils/apis/btb/B2b";

import OverviewPage from "./OverviewPage/OverviewPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";

function B2bbuyerstepForm({ onNext, onPrev }) {
  const [startDate, setStartDate] = useState();
  const [userDetails, setUserDetails] = useState();
  const [workType, setWorkType] = useState();
  const [workTypeName, setWorkTypeName] = useState();

  const [innerPage, setInnerPage] = useState(0);
  const { getData, postData, IMG_URL } = useContext(Context);

  const getBuyerDetails = async () => {
    const res = await getData(
      `/btb/btb-create/btb-personal-details/${Cookies.get("btobbuyer_id")}`
    );
    if (res?.success) {
      console.log(res, "users");
      setUserDetails(res.data);
    }
  };

  useEffect(() => {
    getBuyerDetails();
    window.scrollTo(0, 0);
  }, []);

  const [page, setPage] = useState(0);

  const nextStep = () => {
    getBuyerDetails();
    window.scrollTo(0, 0);
    setPage((currPage) => currPage + 1);
    if (page === 4 && onNext) {
      onNext();
    }
  };

  const prevStep = () => {
    getBuyerDetails();
    window.scrollTo(0, 0);
    setPage((currPage) => currPage - 1);
    if (page === 0 && onPrev) {
      onPrev();
    }
  };
  const prevStepInner = () => {
    window.scrollTo(0, 0);
  };

  // const calculatePercentage = () => {
  //   const totalSteps = stepNames.length;
  //   const currentPage = page + 1;
  //   return btbPercentage[currentPage];
  // };

  const calculatePercentage = () => {
    const totalSteps = stepNames.length;
    const currentPage = page + 1;
    return (currentPage / totalSteps) * 140;
  };



  const calculateProgressPercentage = () => {
    const btbPercentage = [0, 15, 35, 50, 100];
    return btbPercentage[page];
  };

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return (
          <Stepone
            nextStep={nextStep}
            heading={stepNames[0]}
            userDetails={userDetails}
          />
        );
      case 1:
        return (
          <Steptwo
            nextStep={nextStep}
            prevStep={() => prevStep(0)}
            setWorkType={setWorkType}
            workType={workType}
            setWorkTypeName={setWorkTypeName}
            userDetails={userDetails}
          />
        );
      case 2:
        return (
          <Stepthree
            nextStep={nextStep}
            prevStep={() => prevStep(1)}
            workType={workType}
            userDetails={userDetails}
          />
        );
      case 3:
        return <Stepfour prevStep={() => prevStep(2)} nextStep={nextStep} setPage={setPage} userDetails={userDetails} innerPage={innerPage} setInnerPage={setInnerPage} />;
      case 4:
        return <Stepsix nextStep={nextStep} prevStep={() => prevStep(3)} userDetails={userDetails} setInnerPage={setInnerPage} />;
      default:
        return null;
    }
  };

  const stepNames = [
    "Personal Details",
    "Working Type",
    "Industry Details",
    "Firm Details",
    "Password",
    "Overview",
  ];

  return (
    <>
      <section className="B2bbuyerStepMain">
        <div className="container">
          <div className="mt-md-3 mt-3">
            <div className="d-md-flex d-flex-unset">
              <div className="">
                <Link to="/b2bbuyer/buyer-home" className="left-goto-homee">
                  <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> Go to home
                </Link>
              </div>
              <div className="title-stttee-align">
                <h6>B2B Buyer Registration Form</h6>
              </div>
            </div>
          </div>

          <div className="ProgressBar">
            <ProgressBar
              percent={calculatePercentage()}
              filledBackground="#1C42A9"
              height="3px"
              style={{ margin: "auto" }}
            >
              {[1, 2, 3, 4, 5].map((icon, index) => (
                <Step key={index}>
                  {({ accomplished }) => (
                    <div
                      style={{
                        backgroundColor: accomplished ? "#1C42A9" : "#ffffff",
                        color: accomplished ? "#fff" : "#000000",
                        border: accomplished
                          ? "1px solid #1C42A9"
                          : "1px solid #FFFFFF",
                        boxShadow: accomplished
                          ? "none"
                          : " 0px 0px 6px #00000029",
                      }}
                      className={`step ${accomplished ? "completed" : null}`}
                    >
                      <p className="mb-0">{icon}</p>
                      <div className="step-name">{stepNames[index]}</div>
                    </div>
                  )}
                </Step>
              ))}
            </ProgressBar>
          </div>

          <div className="userForm">
            <div className="personal-details-form step-Form-Conteiner ">
              {/* form-heading-holder */}
              <div className="form-header-holder">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-12">
                    <div className="heading-holder">
                      <h6>
                        {page === 0
                          ? "Personal Details"
                          : page === 1
                            ? "Select Working Type"
                            : page === 2
                              ? "Sector to which you are affiliated (" +
                              workTypeName +
                              ")"
                              : page === 3
                                ? "Please fill the below fields"
                                : page === 4
                                  ? "Create password for your account"
                                  : null}
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-12">
                    <div className="span-holder">
                      <span>Just a few steps to go</span>
                      <div style={{ width: "50px", height: "50px" }}>
                        <CircularProgressbar
                          value={calculateProgressPercentage()}
                          text={`${calculateProgressPercentage()}%`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">{PageDisplay()}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default B2bbuyerstepForm;
