import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Swiper, SwiperSlide } from "swiper/react";
import Table from "react-bootstrap/Table";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./SelectBrand.css";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Autosuggest from "react-autosuggest";
import Cookies from "js-cookie";

import { useForm } from "react-hook-form";

import {
  productBrandGet,
  productBrandPost,
  sellerCheckBrand,
} from "../../../../../../../utils/apis/seller/Product";
import { getCustomNames } from "../../../../../../../utils/apis/autosuggest/AutoSuggest";
import { Brand } from "../../../../../../../utils/apis/master/Master";
import Animation from "../../../../../../CommonForAll/Animation/SuccessAnimation/Animation";
import { IDS } from "../../../../../../../utils/common";
import { Context } from "../../../../../../../utils/context";

const SelectBrand = (props) => {
  const [checkBrand, setCheckBrand] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      brand_id: "",
      brand_name: "",
      brand_status: false,
      s_sub_category_id: "",
    },
  });

  const { Id } = useContext(Context);

  const [error, setError] = useState("");
  const CheckBrand = async (name) => {
    setError("");
    if (name) {
      setValue("brand_name", name);
    }
    const res = await sellerCheckBrand(getValues("brand_name"));

    if (res?.success) {
      setValue("brand_id", res.data.id);
      setBrandStatus(true);
    } else {
      setBrandStatus(false);
      setError({ brand_name: res.message });
    }
  };

  const submitData = async () => {
    var data = {
      id: Cookies.get("s_product_id"),
      s_brand_id: getValues("brand_id"),
      supply_type: getValues("supply_type"),
    };
    if (getValues("supply_type") === "Custom") {
      if (!value) {
        setError({ custom_name: "Custom name required" });
        return;
      } else {
        data.s_custom_id = value;
      }
    }

    const res = await productBrandPost(data);
    if (res?.success) {
      console.log(res);
      props.handleNext();
    }
  };

  const [brandStatus, setBrandStatus] = useState(false);
  const [customInput, setCustomInput] = useState(false);
  const [value, setAutoValue] = useState("");
  const [suggestionsList, setSuggestionsList] = useState([]);

  const getSuggestions = async (inputValue) => {
    const inputValueLowerCase = inputValue.toLowerCase();

    var res = await getCustomNames(inputValueLowerCase);

    if (res?.success) {
      return res.data?.filter((item) =>
        item.name.toLowerCase().includes(inputValueLowerCase)
      );
    }
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    const suggestions = await getSuggestions(value);
    setSuggestionsList(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const onChange = (event, { newValue }) => {
    setError("");
    setAutoValue(newValue);
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const renderSuggestion = (suggestion) => (
    <div id={suggestion?.id}>{suggestion?.name}</div>
  );

  const inputProps = {
    placeholder: "Type a name",
    value,
    onChange: onChange,
  };

  const [brands, setBrand] = useState([]);
  const getBrand = async () => {
    const res = await Brand(Id, IDS.web_site_type?.info);

    if (res?.success) {
      setBrand(res.data);
    }
  };

  const getProductBrand = async (id) => {
    const res = await productBrandGet(id);
    if (res?.success) {
      if (res.data.supply_type) {
        setValue("supply_type", res.data.supply_type);
        setBrandStatus(true);
        if (res.data.supply_type === "Custom") {
          setCustomInput(true);
          setAutoValue(res.data?.s_products_supply_type?.s_custom?.name);
        } else {
          setValue("brand_name", res.data.s_brand?.name);
          setValue("brand_id", res.data.s_brand_id);
        }
      }
    }
  };

  const [selfStatus, setSelf] = useState(false);
  const [searchBrand, setSearchBrand] = useState([]);
  const handleBrandSearch = async (name) => {
    const res = await Brand(Id, IDS.web_site_type?.inquiry, name);

    if (res?.success) {
      setSearchBrand(res?.data);
    }
  }
  useEffect(() => {
    getBrand();
    const cookie = Cookies.get("s_product_id");
    if (cookie) {
      getProductBrand(cookie);
    }
  }, []);
  useEffect(() => {
    if (value) {
      setBrandStatus(true);
    } else {
      setBrandStatus(false);
    }
  }, [value]);
  return (
    <>
      <section className="select-brand-step-2">
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-lg-12 ">
            <Form>
              <Form.Group
                className="mb-3 fotm-grp"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="brand">
                  Check the brand which want to sell
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Brand name here"
                  className={classNames("", {
                    "is-invalid": errors?.message,
                  })}
                  {...register("brand_name", {
                    required: !customInput ? "brand name is required" : false,
                  })}
                  disabled={customInput}
                  onChange={(e) => {
                    reset({
                      ...getValues(),
                      brand_name: e.target.value,
                    });
                    setBrandStatus(false);
                    handleBrandSearch(e.target.value);
                  }}
                />
                <Link to={""}>
                  {!customInput ? (
                    <button
                      className="check-brand-btn"
                      onClick={() => CheckBrand("")}
                    >
                      Check Brand
                    </button>
                  ) : null}
                </Link>
                {error && (
                  <span className="text-danger">{error.brand_name}</span>
                )}
                {!customInput && searchBrand.length > 0 && (
                  <div className="select-brand-step-2">
                    <div className="react-autosuggest__suggestions-list">
                      {searchBrand?.map((value, index) => (
                        <div className="react-autosuggest__suggestion" onClick={() => CheckBrand(value?.name)}>
                          <p>{value?.name}</p>
                        </div>
                      ))}

                    </div>
                  </div>
                )}
              </Form.Group>

              <div className="mt-5">
              <Form.Check
                  type="radio"
                  label="Selected Brand"
                  value={"Selected Brand"}
                  defaultChecked
                  name="supply_type"
                  className={classNames("my-3", {
                    "is-invalid": errors?.message,
                  })}
                  {...register("supply_type", {
                    required: "Supply Type is required",
                  })}
                  onClick={() => {setCustomInput(false); setBrandStatus(false); }}
                />
                <Form.Check
                  type="radio"
                  label="Self Manufactured"
                  value={"Self Manufactured"}
                  defaultChecked
                  name="supply_type"
                  className={classNames("my-3", {
                    "is-invalid": errors?.message,
                  })}
                  {...register("supply_type", {
                    required: "Supply Type is required",
                  })}
                  onClick={() => { setCustomInput(true); setSelf(true); setBrandStatus(true); }}
                />
                <Form.Check
                  type="radio"
                  label="Custom"
                  value={"Custom"}
                  name="supply_type"
                  className={classNames("my-3", {
                    "is-invalid": errors?.message,
                  })}
                  {...register("supply_type", {
                    required: "Supply Type is required",
                  })}
                  onClick={() => { setCustomInput(true); setSelf(false); setBrandStatus(false); }}
                />
              </div>
              {customInput && !selfStatus && (
                <Form.Group
                  className="mb-3 "
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="form-text">
                    Custom
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-title">Product</Tooltip>}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        className="ms-3"
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Autosuggest
                    suggestions={suggestionsList}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                  />
                  {error && (
                    <span className="text-danger">{error.custom_name}</span>
                  )}
                </Form.Group>
              )}
{!customInput && !selfStatus && (
              <div className="brand-swiper">
                <h5>Recently utilized brands</h5>
                <Swiper
                  pagination={{
                    dynamicBullets: true,
                  }}
                  spaceBetween={20}
                  slidesPerView={4}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {brands?.map((value, index) => (
                    <SwiperSlide>
                      <div
                        className="brand-round"
                        onClick={() => CheckBrand(value.name)}
                      >
                        <p>{value?.name}</p>
                      </div>
                    </SwiperSlide>
                  ))}

                  {/* <SwiperSlide>
                    <div className="brand-round">
                      <p>Lenovo</p>
                    </div>
                  </SwiperSlide> */}
                </Swiper>
              </div>
)}
            </Form>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <div className="right-div">
              {brandStatus && (
                <>
                  <div className="lotty-div">
                    <Animation />
                  </div>
                  <div className="continu-btn-div">
                    <p>Kindly apply for approval to sell under this brand.</p>

                    <button
                      onClick={submitData}
                      className="aprov-btn"
                      type="button"
                    >
                      Continue
                    </button>
                  </div>
                </>
              )}

              <div className="bottom-div">
                <h6>Foundational brand name principles to adhere to</h6>
                <p>
                  Utilize this brief guide to prevent brand name infringements.
                </p>
                <div className="grey-card">
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <FontAwesomeIcon
                            icon="fa-solid fa-xmark"
                            className="wrong"
                          />
                        </th>
                        <th>
                          <FontAwesomeIcon
                            icon="fa-solid fa-check"
                            className="right"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Check the correct spelling</td>
                        <td>Adidass</td>
                        <td>Adidas</td>
                      </tr>
                      <tr>
                        <td>Use full forms</td>
                        <td>CK</td>
                        <td>Calvin Klein</td>
                      </tr>
                      <tr>
                        <td>Avoid extra details</td>
                        <td>Nike Shoes</td>
                        <td>Nike</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="text-end apply_now_btn">
            <Button onClick={props.handlePrevious} className=" tabs-btn">
              back
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default SelectBrand;
