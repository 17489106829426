import React, { useContext, useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import './AddMoneywalletModal.css'
import MoneyLottie from './Money-Lottie/MoneyLottie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
const AddMoneywalletModal = (props) => {
    const [error, setError] = useState("");

    const onSubmit = async () => {

        if (props.depositAmount == 0) {
            setError("Please Enter Amount");
        }

        if (props.depositAmount != 0) {

            if (props.type === 'pay') {
                props.handleRazorpay();
                props.onHide();
            }

            if (props.type === 'withdraw') {

                if (props.depositAmount > props.walletAmount) {
                    setError("Not Enough amount in wallet");
                } else {
                    props.handleWithdraw();
                    props.onHide();
                }

            }

        }
    }
    useEffect(() => {
        setError("");
    }, [props.show]);
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='add-money-modal'
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <div className="lottie-holder">
                        <MoneyLottie />
                    </div>
                    <Form>
                        <p >Add money to your wallet</p>
                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter Amount"
                            defaultValue={props.depositAmount}
                            onChange={(e) => { props.setAmount(e.target.value); setError(""); }}
                        />
                        <span className="text-danger">{error}</span>

                    </Form>
                    {/* <Link to="/b2bbuyer/my-wallet-payment"> */}
                    <div className='text-center mt-3'>
                        <ButtonComponent
                            onClick={onSubmit}
                            className={'btn-continue'}
                        >
                            Continue
                            <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='ms-1' /></ButtonComponent>
                    </div>
                    {/* </Link> */}

                </Modal.Body>

            </Modal>
        </>
    )
}

export default AddMoneywalletModal