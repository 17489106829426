import React, { useEffect, useState } from 'react'
import './Product_total.css'

const Product_total = ({ buyNowData, quantity, amount, orderDetail, IMG_URL, getValues }) => {
    const [total, setTotal] = useState("")
    useEffect(() => {
        setTotal(buyNowData?.selling_price * buyNowData?.quantity)
    }, [buyNowData])
    return (
        <>
            <section className='Product_total_seccc'>
                <div className='d-flex'>
                    <div className='payimg-div' >
                        <img src={IMG_URL + getValues("thumbnail")} />
                    </div>
                    <div className='proddd-nnnn'>
                        <h6 className='prood-nname'>
                            {/* Armchair New Arrival Promotion Fabric Material Adjustable Leisure Single Power Electric Relax */}
                            {getValues("product_title")}
                        </h6>
                    </div>
                </div>
                <hr></hr>
                {/* <div className='row'>
                    <div className='col-6'>
                        <p className='sizeeppp'>Size : <span>W72*D81*H86CM</span></p>
                    </div>
                    <div className='col-6'>
                        <p className='sizeeppp'>Color  : <span>Olieve Green</span></p>
                    </div>
                </div> */}

                <div className='totallprrr'>
                    <div className='row mb-1'>
                        <div className='col-6'>
                            <p className='hghdg'>Item (s) Total</p>
                        </div>
                        <div className='col-6 text-end'>
                            <p className='hghdg'>{getValues("total_mrp")}</p>
                        </div>
                    </div>
                    <div className='row mb-1'>
                        <div className='col-6'>
                            <p className='hghdg'>Discount</p>
                        </div>
                        <div className='col-6 text-end'>
                            <p className='hghdg'>- ₹ {getValues("discount")}</p>
                        </div>
                    </div>
                    <div className='row mb-1'>
                        <div className='col-6'>
                            <p className='hghdg'>Tax</p>
                        </div>
                        <div className='col-6 text-end'>
                            <p className='hghdg'>+ ₹ {getValues("total_tax")}</p>
                        </div>
                    </div>
                    {/* <div className='row mb-2'>
                        <div className='col-6'>
                            <p className='hghdg'>Estimated shipping fee</p>
                        </div>
                        <div className='col-6 text-end'>
                            <p className='hghdg'>₹ 100</p>
                        </div>
                    </div> */}
                    <hr className='my-2'></hr>
                    <div className='row mb-2'>
                        <div className='col-6'>
                            <p className='hghdg'>Order total</p>
                        </div>
                        <div className='col-6 text-end'>
                            <p className='hghdg'>₹ {getValues("total")}</p>
                        </div>
                    </div>
                </div>

                <div className='protect-odd-div'>
                    <h6 className='prod-h6'>Protections for this order</h6>
                    <ul>
                        {getValues("s_p_t_description")?.map((item) => (
                            <div key={item.id}>
                                <li>{item?.name}</li>
                                <p>{item?.description}</p>
                            </div>
                        ))}
                        {/* 
        <li>On-time Dispatch Guarantee </li>
        <p>Dispatched within 25 days of payment or receive a 10% delay compensation</p>

        <li>Secure payments </li>
        <p>Every payment you make on Alibaba.com is secured with strict SSL encryption and PCI DSS data protection protocols</p> 
        */}
                    </ul>
                </div>

            </section>
        </>
    )
}

export default Product_total