import React from 'react'
import InnerBlogBanner from './InnerBlogBanner/InnerBlogBanner'
import BlogInfo from './BlogInfo/BlogInfo'
import BlogComments from './BlogComments/BlogComments'
import { Container } from 'react-bootstrap'


function InnerSellerBlog() {
  return (
    <>
    <InnerBlogBanner/>
    <BlogInfo/>
    <BlogComments/>
    </>
  )
}

export default InnerSellerBlog