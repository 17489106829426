import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ButtonComponent from "../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import "../Add_note_modal/AddNoteModal.css";
import "./CancelOrderModal.css";
import { postCancelOrder } from "../../../../../utils/apis/btb/buy_order_details";
import { getValue } from "@testing-library/user-event/dist/utils";

const CancelConfirm = (props) => {
  const CancelOrder = async () => {
    const res = await postCancelOrder(props.getValues());
    // console.log(res);
    if (res.success) {
      props.onHide();
      props.getOrders();
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-note-modal cancel-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Cancel order
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="border-line mb-2"></div>
          <p>
            You will not be able to recover your order once it has been
            canceled. Are you sure you want to continue?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonComponent
            type="button"
            onClick={CancelOrder}
            className={"btn-confirm"}
          >
            {" "}
            Confirm
          </ButtonComponent>
          <ButtonComponent onClick={props.onHide} className={"btn-cancle"}>
            Cancel
          </ButtonComponent>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CancelConfirm;
