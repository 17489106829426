import React, { useContext, useEffect, useState } from "react";
import B2bSellerDetail from "./B2bSellerDetail/B2bSellerDetail";
import { useParams } from "react-router-dom";
import { Context } from "../../../utils/context";
import { getMainProductDetails } from "../../../utils/apis/btb/Main_Product";
import B2bSellerProduct from "./B2bSellerProduct/B2bSellerProduct";
import classNames from "classnames";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import B2bFrequentlyCard from "./B2bFrequentlyCard/B2bFrequentlyCard";
import B2bsupplierCard from "./B2bSupplierCard/B2bsupplierCard";
import {
  getProducts,
  getSupplierProducts,
} from "../../../utils/apis/btb/Best_Seller";
import { ShimmerContentBlock } from "react-shimmer-effects";
import { Helmet } from "react-helmet";

const B2bSellerDetailPage = (props) => {
  const { product_id } = useParams();
  const { IMG_URL, shimmerLoader, setShimmerLoader } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [images, setImages] = useState([]);

  const [singleVariant, setSingleVariant] = useState(false);
  const [MultipleVariantTwo, setMultipleVariantTwo] = useState(false);
  const [MultipleVariantThree, setMultipleVariantThree] = useState(false);

  const [data, setData] = useState({});
  const [FrequentlyCard, setFrequentlyCard] = useState([]);
  const [supplierProducts, setsupplierProducts] = useState([]);
  const getData = async () => {
    try {
      setShimmerLoader(true);
      reset();
      setSingleVariant(false);
      setMultipleVariantTwo(false);
      setMultipleVariantThree(false);

      const res = await getMainProductDetails(product_id);

      setShimmerLoader(false);

      setValue("seller_id", res.data?.s_supplyer_id);
      setValue(
        "working_type_id",
        res.data?.s_personal_detail.s_sector.s_working_type_id
      );

      setValue("product_id", res.data?.id);
      setData(res.data);
      setValue("product_title", res.data?.product_title);
      setValue(
        "customization",
        res.data?.s_product_customization?.s_product_type?.name
      );
      setValue(
        "product_type_id",
        res.data?.s_product_customization?.s_product_type?.id
      );
      setValue(
        "s_brand",
        res.data.supply_type == "Custom" ||
          res.data.supply_type == "Self Manufactured"
          ? res.data?.s_products_supply_type?.s_custom?.name
          : res.data?.s_brand?.name
      );
      if (res.data.s_product_variant.variant_type === "Single") {
        // setData(res.data);
        // setValue("product_title", res.data?.product_title);
        // setValue(
        //   "customization",
        //   res.data?.s_product_customization?.s_customization?.name
        // );
        setValue(
          "variant",
          res.data?.s_product_variant?.s_product_variant_attribute?.s_attribute
            ?.name
        );
        setValue(
          "variant_details",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details
        );

        setValue(
          "s_p_t_descriptions",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details[0]?.s_p_t_descriptions
        );

        setValue(
          "sku_id",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.sku_id
        );
        setValue(
          "mrp",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
            ?.mrp
        );

        setValue(
          "selling_price",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
            ?.selling_price
        );

        setValue(
          "minimum_order_quantity",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
            ?.minimum_order_quantity
        );
        setSingleVariant(true);
        setImages(
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_images
        );
      } else if (res.data.s_product_variant.variant_type === "Multiple") {
        if (
          res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
            ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
            ?.s_p_v_a_one_d_a_d_as.length == 0
        ) {
          // setData(res.data);
          // setValue("product_title", res.data?.product_title);
          // setValue(
          //   "customization",
          //   res.data?.s_product_customization?.s_customization?.name
          // );
          setValue(
            "variant",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
          );
          setValue(
            "variant_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
          );

          setValue(
            "variant_one",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
          );

          setValue(
            "variant_one_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
          );

          setValue(
            "s_p_t_descriptions",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds[0]?.s_p_t_descriptions
          );

          setValue(
            "sku_id",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.sku_id
          );
          setValue(
            "mrp",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
          );

          setValue(
            "selling_price",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
          );

          setValue(
            "minimum_order_quantity",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.minimum_order_quantity
          );
          setMultipleVariantTwo(true);
          setImages(
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_a_d_images
          );
        } else {
          // setData(res.data);
          // setValue("product_title", res.data?.product_title);
          // setValue(
          //   "customization",
          //   res.data?.s_product_customization?.s_customization?.name
          // );
          setValue(
            "variant",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
          );
          setValue(
            "variant_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
          );

          setValue(
            "variant_one",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
          );

          setValue(
            "variant_one_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
          );

          setValue(
            "variant_two",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
          );

          setValue(
            "variant_two_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds
          );

          setValue(
            "s_p_t_descriptions",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds[0]
              ?.s_p_t_descriptions
          );

          setValue(
            "sku_id",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
          );
          setValue(
            "mrp",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
          );

          setValue(
            "selling_price",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
          );

          setValue(
            "minimum_order_quantity",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.minimum_order_quantity
          );

          setImages(
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_a_d_images
          );
          setMultipleVariantThree(true);
        }
      }

      const suppiler = await getSupplierProducts(res.data?.s_supplyer_id);
      setsupplierProducts(suppiler?.data);

      const subcategoryproducts = await getProducts(
        false,
        res?.data?.s_sub_category_id
      );
      setFrequentlyCard(subcategoryproducts?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeVariant = async (data, type = "Single", level = "1") => {
    console.log("onChangeVariant", data, type, level);

    if (type === "Single" && level === "1") {
      setValue("sku_id", data?.sku_id);
      setValue("mrp", data?.s_p_v_a_d_price_a_stock?.mrp);
      setValue("selling_price", data?.s_p_v_a_d_price_a_stock?.selling_price);
      setValue("s_p_t_descriptions", data?.s_p_t_descriptions);
    } else if (type === "Multiple" && level === "2") {
      setValue("sku_id", data?.sku_id);
      setValue("s_p_t_descriptions", data?.s_p_t_descriptions);
      setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
      setValue(
        "selling_price",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
      );
    } else if (type === "Multiple" && level === "3") {
      setValue("sku_id", data?.sku_id);
      setValue("s_p_t_descriptions", data?.s_p_t_descriptions);
      setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
      setValue(
        "selling_price",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
      );
    }
  };

  useEffect(() => {
    if (product_id) {
      getData();
    }
  }, [product_id]);

  useEffect(() => {
    const details = getValues("variant_details");
    setValue("activeVariant", details?.[0]?.id);
  }, [getValues("variant_details")]);

  useEffect(() => {
    if (MultipleVariantTwo) {
      const details = getValues("variant_one_details");
      setValue("activeSubVariant", details?.[0]?.id);
    } else if (MultipleVariantThree) {
      const details = getValues("variant_one_details");
      setValue("activeSubVariant", details?.[0]?.id);
    }
  }, [getValues("variant_one_details")]);

  useEffect(() => {
    if (MultipleVariantThree) {
      const details = getValues("variant_two_details");
      setValue("activeSubChildVariant", details?.[0]?.id);
    }
  }, [getValues("variant_two_details")]);

  return shimmerLoader ? (
    <ShimmerContentBlock
      title
      text
      cta
      thumbnailWidth={370}
      thumbnailHeight={370}
    />
  ) : (
    <>


     

      <B2bSellerDetail
        data={data}
        getValues={getValues}
        onChangeVariant={onChangeVariant}
        singleVariant={singleVariant}
        MultipleVariantTwo={MultipleVariantTwo}
        MultipleVariantThree={MultipleVariantThree}
        IMG_URL={IMG_URL}
        images={images}
        setImages={setImages}
        setValue={setValue}
      />
      <B2bSellerProduct
        data={data}
        // singleVariant={singleVariant}
        IMG_URL={IMG_URL}
        images={images}
        getValues={getValues}
        setImages={setImages}
      />
      <B2bFrequentlyCard
        headingName="Frequently bought together"
        FrequentlyCard={FrequentlyCard}
        IMG_URL={IMG_URL}
      />
      <B2bsupplierCard
        FrequentlyCard={supplierProducts}
        IMG_URL={IMG_URL}
        headingName="Supplier's popular products"
      />
    </>
  );
};

export default B2bSellerDetailPage;
