import React from "react";
import { Modal } from "react-bootstrap";
import ButtonComponent from "../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
const DeleteModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-details-modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <h2>Are you sure you want to delete this product from cart ?</h2>
        </Modal.Body>
        <Modal.Footer>
          <ButtonComponent onClick={props.onHide} className={"btn-cancle"}>
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className={"btn-update"}
            onClick={() => props.RemoveFromCart(props.show)}
          >
            {" "}
            Delete
          </ButtonComponent>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
