import React, { useState } from "react";
import { Card, Col, Container, Row, Offcanvas, } from "react-bootstrap";
import ViewRequestedModal from '../../../../common-components/ViewRequestedModal/ViewRequestedModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ViewRequestedQuote from '../../../../RFQ/RfqOffcanvas/ViewRequestedQuote';
import ViewQuotes from '../../../../RFQ/RfqOffcanvas/ViewQuotes';

import NewChat from "../../../../common-components/offcanvas/NewChat/NewChat";
const inquiryData = [
    {
        id: "1842770994",
        date: "2024-04-02",
        image: "/assest/images/B2Bbuyer/RFQ/Armchairp.png",
        title: "Hot Sale Living Room Single Sofa Chair Armchair High Back Lamb Cashmere Microfiber Fabric Manual Recliner Massage Chair",
        price: "₹8,000",
        minOrder: "10 Pcs",
        qty: "2 Pieces",
        requirements: "Color: Mustard Yellow",
    },
    {
        id: '1842770999',
        date: '2024-04-03',
        image: '/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png',
        title: 'Modern Single Nordic Accent Lounge Lazy Sofa Couch Chair With Ottoman Recliner Reclining Leisure Chair For Living Room',
        price: '₹15,000',
        minOrder: '20 Pcs',
        qty: '10 Pieces',
        requirements: 'Color: Cyan Blue'
    }
];
const ActionData = [
    {
        views: 'View Quotes',
    },
    {
        views: 'View this RFQ',
    },
    {
        views: 'Close RFQ',
    }
];

const B2bDeshinquiry = () => {
    // Showchat
    const [showchat, setShowchat] = useState(false);
    const handleClosechat = () => setShowchat(false);
    const handleShowchat = () => setShowchat(true);
    const [modalShow, setModalShow] = React.useState(false);

    // Offcanvas view requested 
    const [showViewRequested, setShowViewRequested] = useState(false);
    const handleCloseViewRequested = () => setShowViewRequested(false);
    const handleShowViewRequested = () => setShowViewRequested(true);

    // Offcanvas view requested quote
    const [showViewRequestedQuote, setShowViewRequestedQuote] = useState(false);
    const handleCloseViewRequestedQuote = () => setShowViewRequestedQuote(false);
    const handleShowViewRequestedQuote = () => setShowViewRequestedQuote(true);
    return (
        <>
            <section className="my-inquiry-section mt-3">
                <Row className="ms-0 me-0">
                    {inquiryData.map((item, index) => (
                        <Col xxl={12} key={index}>
                            <Card className="rfq-card">
                                <Row>
                                    <Col xxl={6} md={12}>
                                        <div className="d-flex">
                                            <div className="item-date">{item.date}</div>
                                            <div className="border-id"></div>
                                            <div className="item-id">ID: {item.id}</div>
                                        </div>
                                        <div className="chair-content">
                                            <img
                                                src={process.env.PUBLIC_URL + item.image}
                                                className="product-img"
                                                alt="..."
                                            />
                                            <div className="card-content">
                                                <h2 className="item-title">{item.title}</h2>
                                                <p className="item-price">
                                                    {item.price}{" "}
                                                    <span className="min-order">Min Order : {item.minOrder}</span>
                                                </p>
                                                <p className="qty-item">Qty : {item.qty}</p>
                                                <p className="detail-req">Detailed Requirements</p>
                                                <p className="qty-item">{item.requirements}</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} lg={4} md={5} sm={4}>
                                        <div className="content-one">
                                            <p className="status">Quotation Status</p>
                                            <p className="approved">Approved</p>
                                        </div>

                                    </Col>

                                    <Col xxl={2} lg={6} md={5} sm={6}>
                                        <div className="content-three">
                                            <p className="action">Action</p>
                                            {/* {ActionData.map((item, index) => (
                                            <Row>

                                                <div className="action-section">
                                                    <FontAwesomeIcon icon="fa-solid fa-eye" />
                                                    <p className="view">{item.views}</p>
                                                </div>
                                            </Row>

                                        ))} */}
                                            <p className="view" onClick={handleShowViewRequested}><FontAwesomeIcon icon="fa-solid fa-eye" className='eye-icon' /> View Quotes</p>
                                            <p className="view" onClick={handleShowViewRequestedQuote}><FontAwesomeIcon icon="fa-solid fa-eye" className='eye-icon' /> View this Inquiry</p>
                                            <p className="view" onClick={() => setModalShow(true)}><FontAwesomeIcon icon="fa-solid fa-eye" className='eye-icon' /> Close Inquiry</p>

                                            <ViewRequestedModal
                                                show={modalShow}
                                                onHide={() => setModalShow(false)}
                                            />
                                        </div>

                                    </Col>

                                    <Col xxl={2} lg={2} md={2} sm={2}>
                                        <div className='brown-bg'>
                                            <FontAwesomeIcon icon="fa-solid fa-comment" className="comment-img" onClick={handleShowchat} />
                                            <div className='red-dot'></div>
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row>
                                <Col md={2} sm={5} xs={5}>
                                    <div className='item-date'>{item.date}</div>
                                </Col>
                                <Col md={5} sm={5} xs={5}>
                                    <div className='item-id'>ID: {item.id}</div>
                                </Col>
                                <Col md={4} sm={2} xs={2} className='text-end'>
                                    <div className='brown-bg'>
                                        <FontAwesomeIcon icon="fa-solid fa-comment" className="comment-img" onClick={handleShowchat} />
                                        <div className='red-dot'></div>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <img src={process.env.PUBLIC_URL + item.image} className="product-img" alt="..." />
                                </Col>
                                <Col md={10}>
                                    <h2 className='item-title'>{item.title}</h2>
                                    <p className='item-price'>{item.price} <span className='min-order'>Min Order : {item.minOrder}</span></p>
                                    <p className='qty-item'>Qty : {item.qty}</p>
                                    <p className='detail-req'>Detailed Requirements</p>
                                    <p className='qty-item'>{item.requirements}</p>
                                </Col>
                            </Row> */}
                            </Card>
                        </Col>
                    ))}

                    <NewChat handleClose={handleClosechat} placement="bottom" name="bottom" show={showchat} />
                </Row>
                {/* Offcanvas View Requested */}
                <Offcanvas show={showViewRequested} onHide={handleCloseViewRequested} className="view-quotes-offcanvas" placement='end'>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ViewQuotes />
                    </Offcanvas.Body>

                </Offcanvas>



                {/* Offcanvas View Requested Quote */}
                <Offcanvas show={showViewRequestedQuote} onHide={handleCloseViewRequestedQuote} className="view-quotes-offcanvas" placement='end'>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ViewRequestedQuote />
                    </Offcanvas.Body>
                </Offcanvas>
            </section>
        </>
    )
}

export default B2bDeshinquiry