import React from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Home from "../Home/Home";
import B2Cretail_stepForm from "../B2Cretail_stepForm/B2Cretail_stepForm";


const B2Cretail_Routes_main = () => {
  const { pathname } = useLocation();
  console.log(pathname);

  return (
    <>
      {pathname.startsWith("/b2cretail") ? <Header /> : ""}
      <Routes>
        <Route path="/b2cretail-home" element={<Home />} />
        <Route
          path="/b2cretail-stepForm"
          element={<B2Cretail_stepForm />}
        />
      </Routes>

      {pathname.startsWith("/b2cretail") ? <Footer /> : ""}
    </>
  );
};

export default B2Cretail_Routes_main;
