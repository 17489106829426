import React, { useState } from "react";
import "../../../Variants/variants.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import MultiVarientInner from "../MultiVarientInner/MultiVarientInner";
const MultiVariant = ({ prevInnerPage, nextInnerPage, nextStep }) => {
  const [redirectToNext, setRedirectToNext] = useState(false);

  const handleNext = () => {
    setRedirectToNext(true);
  };

  if (redirectToNext) {
    return (
      <MultiVarientInner nextStep={nextStep} prevInnerPage={prevInnerPage} />
    );
  }
  return (
    <>
      <section className="single-varient-sec">
        <div className="sec-div ">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6">
              <div className="varient-firsttt">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Attribute 1<span className="mandatory-star"> *</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-title">Attribute 1</Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        className="ms-3"
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control placeholder="Enter" readOnly/>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Color<span className="mandatory-star"> *</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-title">Attribute 1</Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        className="ms-3"
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control type="text" placeholder="Enter" readOnly/>
                </Form.Group>
                <div className="text-end">
                  <button className="add-new-attribute-class">
                    + Add New Attributes
                  </button>
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Attribute 2<span className="mandatory-star"> *</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-title">Attribute 1</Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        className="ms-3"
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control type="text" placeholder="Enter Attribute" readOnly/>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Ltr<span className="mandatory-star"> *</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-title">Attribute 1</Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        className="ms-3"
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control type="text" placeholder="Enter" readOnly/>
                </Form.Group>
                <div className="text-end">
                  <button className="add-new-attribute-class">
                    + Add New Attributes
                  </button>
                  <button className="remove-btn-class">Remove</button>
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Attribute 3<span className="mandatory-star"> *</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-title">Attribute 1</Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        className="ms-3"
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control value="Kg" placeholder="Enter" readOnly/>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Kg<span className="mandatory-star"> *</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-title">Attribute 1</Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        className="ms-3"
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control value="10, 30, 50, 80" placeholder="Enter" readOnly/>
                </Form.Group>
                <div className="text-end">
                  <button className="add-new-attribute-class">
                    + Add New Attributes
                  </button>
                  <button className="remove-btn-class">Remove</button>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6">
              <Table bordered style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>Color</th>
                    <th>Ltr</th>
                    <th>Kg</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={8}>1.Red</td>
                    <td rowSpan={4}>1</td>
                    <td>10</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td>50</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td>80</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>2</td>
                    <td>10</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td>50</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td>80</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={6}>2.Yellow</td>
                    <td rowSpan={2}>1</td>
                    <td>10</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>2</td>
                    <td>10</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td>50</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                  <tr>
                    <td>80</td>
                    <td>
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="common-steps-btn">
          {/* <button type="button" onClick={prevInnerPage} className="back-btn">
            Back
          </button>
          <button type="button" onClick={handleNext} className="next-btn">
            Save & Next
          </button> */}
        </div>
      </section>
    </>
  );
};

export default MultiVariant;
