import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const formatDate = (date) => {
  if (!date) return null;
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};
export const getAdvertisementAllProducts = async (
  page = 1,
  newTypes,
  title = "",
  startDate = "",
  endDate = "",
  domain_id = "",
  task_id = "",
) => {
  try {
    let url = `/seller/seller-task/task?type=${newTypes}&page=${page}`;
    if (startDate !== "" && endDate !== "") {
      url += `&from=${formatDate(startDate)}&to=${formatDate(endDate)}`;
    }

    if(title !== ""){
      url += `&product_title=${title}`;
    }
    
    if(domain_id !== ""){
      url += `&domain_id=${domain_id}`;
    }
    
    if(task_id !== ""){
      url += `&task_id=${task_id}`;
    }

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const getActiveTaskApi = async (
  page = 1,
  newTypes,
  title = "",
  startDate = "",
  endDate = ""
) => {
  try {
    

    let url = `/seller/seller-task/task/active/data?type=${newTypes}&page=${page}`;
    if (startDate !== "" && endDate !== "") {
      url += `&from=${formatDate(startDate)}&to=${formatDate(endDate)}`;
    }

    if(title !== ""){
      url += `&product_title=${title}`;
    }
   

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const productStatusChange = async (id) => {
  try {
    const res = await putData(`/seller/seller-task/task/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const postStrick = async (data) => {
  try {
    const res = await postData(`/seller/seller-task/influncer-strick`,data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getInfluencerStricks = async (
  page = 1,
  domain_id = "",
) => {
  try {
    let url = `/seller/seller-task/influncer-strick?page=${page}`;
  
    
    if(domain_id !== ""){
      url += `&domain_id=${domain_id}`;
    }


    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

