import { React, useRef, useEffect, useState, useContext } from "react";
import './PrivacyPolicy.css'
import { ReturnPolicydata } from "../../../utils/apis/master/Master";
import parse from 'html-react-parser';
const CancelationAndReturn = () => {
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await ReturnPolicydata("MyWebsite");
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getDataAll();
    }, []);
    return (
        <>
            <section className='privacy-policy'>
                <div className='container'>
                    <div className='row'>
                        <div className='heading-holder'>
                            <h1>Cancellation & Returns</h1>
                            {data?.name ? parse(data.name) : ''}
                        </div>
                        {/* <div className='heading-holder'>
                            
                            <p>At Netpurti, we strive to provide a seamless and satisfactory shopping experience for our business-to-business (B2B) customers. We understand that there may be occasions where you need to cancel an order or return a product. This Cancellation & Returns Policy outlines our procedures and guidelines for such situations.</p>
                        </div>
                        <div className='heading-holder mb-3'>
                            <h2>1. Cancellation Policy:</h2>
                            <p><span>a. Order Cancellation:</span>You may request to cancel your order within [insert number] hours of placing it. To initiate a cancellation, please contact our customer support team immediately with your order details.</p>
                            <p><span>b. Accepted Cancellations:</span>We will process cancellations for orders that have not been shipped or are not in the process of being fulfilled. Once an order has been shipped, it cannot be canceled.</p>
                            <p><span>c. Cancellation Fees:</span>We reserve the right to charge a cancellation fee for orders canceled after a certain period or if the cancellation results in expenses incurred by our company. Any applicable fees will be communicated to you before processing the cancellation.</p>
                        </div>
                        <div className='heading-holder mb-3'>
                            <h2>2. Returns Policy:</h2>
                            <p><span>a. Return Eligibility:</span>We accept returns for eligible products within [insert number] days from the date of delivery. To be eligible for a return, the product must be unused, in its original packaging, and in the same condition as received.</p>
                            <p><span>b. Return Authorization:</span>Before returning any product, you must obtain authorization from our customer support team. Unauthorized returns will not be accepted.</p>
                            <p><span>c. Return Shipping:</span> You are responsible for the cost of return shipping unless the return is due to our error or a defective product. We recommend using a trackable shipping method for returns to ensure the safe delivery of the product.</p>
                            <p><span>d. Restocking Fee</span> A restocking fee may be applied to returned products, depending on the circumstances of the return. This fee will be deducted from your refund.</p>
                            <p><span>e. Refund Process:</span> Once your return is received and inspected, we will notify you of the approval or rejection of your refund. If approved, the refund will be processed to the original method of payment within [insert number] business days.</p>
                        </div>
                        <div className='heading-holder mb-3'>
                            <h2>3. Exceptions:</h2>
                            <p><span>a. Custom Orders:</span>Customized or made-to-order products are not eligible for cancellation or return unless they are defective or damaged upon receipt.</p>
                            <p><span>b. Hygiene Products:</span> For hygiene reasons, certain products such as personal care items or consumables may not be eligible for return unless they are unopened and unused.</p>
                        </div>
                        <div className='heading-holder mb-3'>
                            <h2>4. Contact Us:</h2>
                            <p>If you have any questions or concerns about our Cancellation & Returns Policy, please contact our customer support team at [Your Contact Information].</p>
                            <p>By placing an order with us, you agree to abide by the terms and conditions outlined in this policy regarding cancellations and returns. We reserve the right to update or modify this policy at any time without prior notice.</p>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default CancelationAndReturn