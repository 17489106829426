import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";

function Graduation() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // Handle form submission
  };

  return (
    <>
      <div className="stepFourMain">
        <div className="form-container">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="Education_Level">
                  <Form.Label className="">Address line 1</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.address_line_1,
                    })}
                    type="text"
                    name="address_line_1"
                    placeholder="Enter Address Line 1"
                    {...register("address_line_1", { required: "Field is required" })}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="Awarded_Year">
                  <Form.Label className="">Address line 2</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.address_line_2,
                    })}
                    type="text"
                    name="address_line_2"
                    placeholder="Enter Address Line 2"
                    {...register("address_line_2", { required: "Field is required" })}
                  />

                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 ">
                <Form.Group controlId="Authourity">
                  <Form.Label className="">City/District:</Form.Label>

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.city,
                    })}
                    type="text"
                    name="city"
                    placeholder="City..."
                    {...register("city", { required: "Field is required" })}
                  />
                </Form.Group>

              </div>

              <div className="col-md-6">
                <Form.Group controlId="Degree_Name:">
                  <Form.Label className="">State</Form.Label>

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.state,
                    })}
                    type="text"
                    name="state"
                    placeholder="State..."
                    {...register("state", { required: "Field is required" })}
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group controlId="Degree_Name:">
                  <Form.Label className="">Pincode</Form.Label>

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.pincode,
                    })}
                    type="text"
                    name="pincode"
                    placeholder="Pincode..."
                    {...register("pincode", { required: "Field is required" })}
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group controlId="Degree_Name:">
                  <Form.Label className="">Country</Form.Label>

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.country,
                    })}
                    type="text"
                    name="country"
                    placeholder="Country..."
                    {...register("country", { required: "Field is required" })}
                  />
                </Form.Group>
              </div>
            </div>


            <div className="text-end">
              <Button type="submit" className="btn StepfoursaveBtn">
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div >
    </>
  );
}

export default Graduation;
