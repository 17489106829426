import React, { useState, useEffect, useContext } from "react";
// import "./b2b-table.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { motion } from "framer-motion";

import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Pagination from "react-bootstrap/Pagination";
import { AnimatePresence } from "framer-motion";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import { ShimmerTable } from "react-shimmer-effects";
import { type } from "@testing-library/user-event/dist/type";
import VeiwEditModal from "../../../../../../CommonForAll/VeiwEditModal/VeiwEditModal";
import Mrp_modal from "../../../../../../CommonForAll/mrp-modal/Mrp_modal";
import CustomPaginate from "../../../../../../CommonForAll/CustomPaginate/CustomPaginate";
import {
  Category,
  ProductStatus,
  ProductInfoIcon,
} from "../../../../../../../utils/apis/master/Master";
import {
  getAllProducts,
  productBTCStatusChange,
  productStatusChange,
} from "../../../../../../../utils/apis/seller/Product";
import { Context } from "../../../../../../../utils/context";
import View_all_varients_offcanvas from "../../../../common-components/offcanvas/view-all-varients-offcanvas/View_all_varients_offcanvas";
import { getOuterWebSiteAllProducts } from "../../../../../../../utils/apis/seller/info-website/Products";
// import { ProductInfoIcon } from "../../../../../../utils/apis/master/Master";
import { formatDate2, formatDate } from "../../../../../../../utils/common"
const B2b_table = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { shimmerLoader, setShimmerLoader, Id } = useContext(Context);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [productId, setProductId] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [modalShowedit, setModalShowedit] = useState(false);
  const [varientsoffcanvas, setVarientsoffcanvas] = useState(false);
  const [productTitle, setProductTitle] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [category, setCategory] = useState("");
  const [varientType, setVarientType] = useState("");

  const [deleteshow, setdeleteShow] = useState(false);

  const [show1, setShow1] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const InitialTransition = () => { };
  const blackBox = {
    initial: {
      height: "100vh",
    },
  };

  const { IMG_URL, userdata, sellerDomain } = useContext(Context);
  const [products, setProducts] = useState([]);

  const getProducts = async (page) => {
    setShimmerLoader(true);
    const res = await getOuterWebSiteAllProducts(
      Id,
      page,
      productTitle,
      searchStatus,
      category,
      startDate,
      endDate,
      sellerDomain?.value,
    );
    if (res?.success) {
      console.log(res);
      const newData = res?.data?.data;
      if (newData) {
        const newIds = newData.map((d) => d?.id);
        setAllChecked(newIds);
      }
      setProducts(res.data);
      setShimmerLoader(false);
    }
  };
  const [varIndex, setIndex] = useState("");
  const [variantData, setVariantData] = useState();
  const handleVariantModal = async (data, index) => {
    await setIndex(index);
    await setVariantData(data);
    setVarientsoffcanvas(true);
  };

  const getVariantProducts = async () => {
    const res = await getOuterWebSiteAllProducts(currentPage);
    if (res?.success) {
      console.log(res);
      setProducts(res?.data);
      await setVariantData(res?.data?.data[varIndex]);
    }
  };

  const handleStatus = async (id) => {
    setShimmerLoader(true);
    const res = await productStatusChange(id);
    if (res?.success) {
      getProducts(currentPage);
      setShimmerLoader(false);
    }
  };

  const handleBTCStatus = async (id) => {
    setShimmerLoader(true);
    const res = await productBTCStatusChange(id);
    if (res?.success) {
      getProducts(currentPage);
      setShimmerLoader(false);
    }
  };

  const [productStatus, setProductStatus] = useState([]);
  const [categories, setCategories] = useState([]);
  const getMasters = async () => {
    {
      const res = await ProductStatus();
      if (res?.success) {
        setProductStatus(res?.data);
      }
    }
    {
      const res = await Category(userdata?.s_sector?.s_working_type_id);
      if (res?.success) {
        setCategories(res?.data);
      }
    }
  };

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  console.log(selectAllChecked, "ttt");

  const changeStatus = async () => {
    setShimmerLoader(true);

    if (selectAllChecked.length > 0) {
      for (const value of selectAllChecked) {
        await productStatusChange(value);
      }
    }

    setShimmerLoader(false);
    getProducts(currentPage);
  };

  useEffect(() => {
    getMasters();
    getProducts(currentPage);
  }, [currentPage, productTitle, searchStatus, category, startDate, endDate]);

  const [productInfoIcon, setProductInfoIcon] = useState([]);

  const getDataAll = async () => {
    {
      const res = await ProductInfoIcon();
      if (res?.success) {
        setProductInfoIcon(res?.data);
      }
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="B2b_table_section">
        <AnimatePresence />
        <h6 className="pdlsis">Product List</h6>
        <div className="product-main">
          <div className="search-main">
            <Form>
              <div className="row">
                <div className="col-xxl-2 co-xl-2 col-lg-4 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="search"
                      placeholder="Search by product title"
                      onChange={(e) => setProductTitle(e.target.value)}
                    />
                    <FontAwesomeIcon
                      className="serch-icoo"
                      icon="fa-solid fa-magnifying-glass"
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-5 co-xl-5 col-lg-8 col-md-6">
                  <div className="d-flex product-status-div">
                    <div className="sec1">
                      <Form.Group className="form-group">
                        <Form.Label>Product Status</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setSearchStatus(e.target.value)}
                        >
                          <option value="">-Please Select-</option>
                          {productStatus?.map((value, index) => (
                            <option
                              value={value?.id}
                              selected={searchStatus == value?.id}
                            >
                              {value?.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>From</Form.Label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          name="start_date"
                          id="start_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy" // Set date format
                          className="form-control"
                          placeholderText="DD/MM/YYYY" // Update placeholder
                        />
                      </Form.Group>
                    </div>
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>To</Form.Label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          name="end_date"
                          id="end_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy" // Set date format
                          className="form-control"
                          placeholderText="DD/MM/YYYY" // Update placeholder
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Categories</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">-Please Select-</option>
                      {categories?.map((value, index) => (
                        <option
                          value={value?.id}
                          selected={category == value?.id}
                        >
                          {value?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Reset Search</Form.Label>
                    <button
                      className="resetsearch-btn"
                      type="button"
                      onClick={() => {
                        setSearchStatus("");
                        setProductTitle("");
                        setStartDate("");
                        setEndDate("");
                        setCategory("");
                      }}
                    >
                      Reset
                    </button>
                  </Form.Group>
                </div>
                {/* <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Sample Excel</Form.Label>
                    <div>
                      <img
                        className="excelimg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/dashboard//icons/excel.png"
                        }
                      />
                    </div>
                  </Form.Group>
                </div> */}
                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Download data in Excel</Form.Label>
                    <div>
                      <img
                        className="excelimg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/dashboard//icons/excel.png"
                        }
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>

              <hr></hr>
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-4 col-md-3">
                  <button
                    className="status-change-btn"
                    type="button"
                    onClick={changeStatus}
                  >
                    Status Change
                  </button>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-9 col-6 text-end">
                  <div className="d-flex justify-content-end">
                    {/* <div className="me-3">
                      <Form.Group className="form-group">
                        <div className="d-flex justify-content-end">
                          <div className="my-auto me-2">
                            <Form.Label>Search </Form.Label>
                          </div>
                          <div>
                            <Form.Control
                              type="search"
                              placeholder="Search here"
                            />
                            <FontAwesomeIcon
                              className="serch-icoo"
                              icon="fa-solid fa-magnifying-glass"
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </div> */}
                    <div className="">
                      <Link to="/seller/seller-dashboard/info-add-product">
                        <button className="listnewpro-btn">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-plus"
                          />{" "}
                          List New Product
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>

          {/* start tabs table section  */}

          <div className="productlisttabsssec">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="All_Products"
            >
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link
                    eventKey="All_Products"
                    onClick={() => setSearchStatus("")}
                  >
                    All Products
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="Accepted_Products">
                    Accepted Products
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Rejected_Products">
                    Rejected Products
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link
                    eventKey="Draft_Products"
                    onClick={() => setSearchStatus(1)}
                  >
                    Draft Products
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="Completed_Products"
                    onClick={() => setSearchStatus(2)}
                  >
                    Completed Products
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                {/* All Product table data start  */}
                <Tab.Pane eventKey="All_Products">
                  <div className="table-overflow">
                    {shimmerLoader ? (
                      <ShimmerTable row={1} col={14} />
                    ) : (
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr className="">
                            <td className="tdd-checkkkk">
                              <input
                                type="checkbox"
                                value="selectAll"
                                checked={
                                  allChecked.length === selectAllChecked.length
                                }
                                onChange={handleChange}
                                id="selectAll"
                                className="me-1"
                              ></input>
                            </td>

                            <td>
                              Product Detail{" "}
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            {/* <td>
                    Product Image
                    <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    />
                  </td> */}

                            <td>
                              Child Category
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Category
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Variants
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Created On
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Updated On
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Total Views
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Total Orders
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            

                            {/* <td>
                    Discountable Product
                    
                  </td> */}

                            {/* <td>Add in B2C</td> */}

                            <td>Status</td>

                            <td>Edit Product</td>
                          </tr>
                        </thead>

                        <tbody>
                          {products?.data?.map((value, index) => (
                            <tr>
                              <td className="tdd-checkkkk">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value={value?.id}
                                  name="perselected"
                                  checked={selectAllChecked.includes(value?.id)}
                                  onChange={handleChange}
                                  id="flexCheckDefault"
                                ></input>
                              </td>
                              <td>
                                {" "}
                                {value?.product_title}{" "}
                                <p className="skiddd">
                                  {/* SKU ID: KS944RUR  */}
                                </p>{" "}
                              </td>
                              {/* <td>
                      <img
                        className="keybord-img"
                        src={
                          IMG_URL +
                          "/assest/images/Seller_panel/dashboard/icons/keybord.png"
                        } 
                      />
                    </td> */}
                              <td>{value?.s_child_category?.name}</td>
                              <td>{value?.s_category?.name}</td>

                              <td>
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-title">
                                      {productInfoIcon?.varient_type}
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    onClick={() =>
                                      handleVariantModal(value, index)
                                    }
                                    // onClick={handleShow}
                                    icon="fa-solid fa-eye"
                                    className=""
                                  />
                                </OverlayTrigger>
                              </td>

                              <td>
                                {value?.createdAt &&
                                  formatDate2(value.createdAt)}
                              </td>
                              <td>
                                {value?.updatedAt &&
                                  formatDate2(value.updatedAt)}
                              </td>
                              <td>
                                <FontAwesomeIcon icon="fa-solid fa-eye" />
                                300
                              </td>
                              <td>200</td>

                             
                              {/* <td>
                      <Form.Check type="switch" id="custom-switch" />
                    </td> */}
                              {/* <td>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={value?.btc_status === 1 ? true : false}
                          onClick={() => handleBTCStatus(value?.id)}
                        />
                      </td> */}
                              <td>
                                <button
                                  className={
                                    value?.status === 1
                                      ? "active-btn"
                                      : "inactive-btn"
                                  }
                                  onClick={() => handleStatus(value?.id)}
                                >
                                  {value?.status === 1 ? "Active" : "InActive"}
                                </button>
                              </td>
                              <td>
                                <button
                                  className="action-btn"
                                  onClick={() => {
                                    setVarientType(
                                      value?.s_product_variant?.variant_type
                                    );
                                    setProductId(value?.id);
                                    setModalShowedit(true);
                                  }}
                                >
                                  Action
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>

                  <div className="pagination-div">
                    <CustomPaginate
                      currentPage={currentPage}
                      totalPages={products?.totalPages}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="Accepted_Products">
                  <div className="table-overflow">
                    {shimmerLoader ? (
                      <ShimmerTable row={1} col={14} />
                    ) : (
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr className="">
                            <td className="tdd-checkkkk">
                              <input
                                type="checkbox"
                                value="selectAll"
                                checked={
                                  allChecked.length === selectAllChecked.length
                                }
                                onChange={handleChange}
                                id="selectAll"
                                className="me-1"
                              ></input>
                            </td>

                            <td>
                              Product Detail{" "}
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            {/* <td>
                    Product Image
                    <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    />
                  </td> */}

                            <td>
                              Child Category
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Category
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Variants
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Created On
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Updated On
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Total Views
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Total Orders
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                           

                            {/* <td>
                    Discountable Product
                    
                  </td> */}

                            {/* <td>Add in B2C</td> */}

                            <td>Status</td>

                            <td>Edit Product</td>
                          </tr>
                        </thead>

                        <tbody>
                          {products?.data?.map((value, index) => (
                            <tr>
                              <td className="tdd-checkkkk">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value={value?.id}
                                  name="perselected"
                                  checked={selectAllChecked.includes(value?.id)}
                                  onChange={handleChange}
                                  id="flexCheckDefault"
                                ></input>
                              </td>
                              <td>
                                {" "}
                                {value?.product_title}{" "}
                                <p className="skiddd">
                                  {/* SKU ID: KS944RUR  */}
                                </p>{" "}
                              </td>
                              {/* <td>
                      <img
                        className="keybord-img"
                        src={
                          IMG_URL +
                          "/assest/images/Seller_panel/dashboard/icons/keybord.png"
                        } 
                      />
                    </td> */}
                              <td>{value?.s_child_category?.name}</td>
                              <td>{value?.s_category?.name}</td>

                              <td>
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-title">
                                      View All Variants
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    onClick={() =>
                                      handleVariantModal(value, index)
                                    }
                                    // onClick={handleShow}
                                    icon="fa-solid fa-eye"
                                    className=""
                                  />
                                </OverlayTrigger>
                              </td>

                              <td>
                                {value?.createdAt &&
                                  new Date(value.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    }
                                  )}
                              </td>
                              <td>
                                {value?.updatedAt &&
                                  new Date(value.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    }
                                  )}
                              </td>
                              <td>
                                <FontAwesomeIcon icon="fa-solid fa-eye" />
                                300
                              </td>
                              <td>200</td>

                             
                              {/* <td>
                      <Form.Check type="switch" id="custom-switch" />
                    </td> */}
                              {/* <td>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={value?.btc_status === 1 ? true : false}
                          onClick={() => handleBTCStatus(value?.id)}
                        />
                      </td> */}
                              <td>
                                <button
                                  className={
                                    value?.status === 1
                                      ? "active-btn"
                                      : "inactive-btn"
                                  }
                                  onClick={() => handleStatus(value?.id)}
                                >
                                  {value?.status === 1 ? "Active" : "InActive"}
                                </button>
                              </td>
                              <td>
                                <button
                                  className="action-btn"
                                  onClick={() => {
                                    setVarientType(
                                      value?.s_product_variant?.variant_type
                                    );
                                    setProductId(value?.id);
                                    setModalShowedit(true);
                                  }}
                                >
                                  Action
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>

                  <div className="pagination-div">
                    <CustomPaginate
                      currentPage={currentPage}
                      totalPages={products?.totalPages}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="Rejected_Products">
                  <div className="table-overflow">
                    {shimmerLoader ? (
                      <ShimmerTable row={1} col={14} />
                    ) : (
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr className="">
                            <td className="tdd-checkkkk">
                              <input
                                type="checkbox"
                                value="selectAll"
                                checked={
                                  allChecked.length === selectAllChecked.length
                                }
                                onChange={handleChange}
                                id="selectAll"
                                className="me-1"
                              ></input>
                            </td>

                            <td>
                              Product Detail{" "}
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            {/* <td>
                    Product Image
                    <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    />
                  </td> */}

                            <td>
                              Child Category
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Category
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Variants
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Created On
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Updated On
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Total Views
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Total Orders
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                           

                            {/* <td>
                    Discountable Product
                    
                  </td> */}

                            {/* <td>Add in B2C</td> */}

                            <td>Status</td>

                            <td>Edit Product</td>
                          </tr>
                        </thead>

                        <tbody>
                          {products?.data?.map((value, index) => (
                            <tr>
                              <td className="tdd-checkkkk">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value={value?.id}
                                  name="perselected"
                                  checked={selectAllChecked.includes(value?.id)}
                                  onChange={handleChange}
                                  id="flexCheckDefault"
                                ></input>
                              </td>
                              <td>
                                {" "}
                                {value?.product_title}{" "}
                                <p className="skiddd">
                                  {/* SKU ID: KS944RUR  */}
                                </p>{" "}
                              </td>
                              {/* <td>
                      <img
                        className="keybord-img"
                        src={
                          IMG_URL +
                          "/assest/images/Seller_panel/dashboard/icons/keybord.png"
                        } 
                      />
                    </td> */}
                              <td>{value?.s_child_category?.name}</td>
                              <td>{value?.s_category?.name}</td>

                              <td>
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-title">
                                      View All Variants
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    onClick={() =>
                                      handleVariantModal(value, index)
                                    }
                                    // onClick={handleShow}
                                    icon="fa-solid fa-eye"
                                    className=""
                                  />
                                </OverlayTrigger>
                              </td>

                              <td>
                                {value?.createdAt &&
                                  new Date(value.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    }
                                  )}
                              </td>
                              <td>
                                {value?.updatedAt &&
                                  new Date(value.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    }
                                  )}
                              </td>
                              <td>
                                <FontAwesomeIcon icon="fa-solid fa-eye" />
                                300
                              </td>
                              <td>200</td>

                            
                              {/* <td>
                      <Form.Check type="switch" id="custom-switch" />
                    </td> */}
                              {/* <td>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={value?.btc_status === 1 ? true : false}
                          onClick={() => handleBTCStatus(value?.id)}
                        />
                      </td> */}
                              <td>
                                <button
                                  className={
                                    value?.status === 1
                                      ? "active-btn"
                                      : "inactive-btn"
                                  }
                                  onClick={() => handleStatus(value?.id)}
                                >
                                  {value?.status === 1 ? "Active" : "InActive"}
                                </button>
                              </td>
                              <td>
                                <button
                                  className="action-btn"
                                  onClick={() => {
                                    setVarientType(
                                      value?.s_product_variant?.variant_type
                                    );
                                    setProductId(value?.id);
                                    setModalShowedit(true);
                                  }}
                                >
                                  Action
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>

                  <div className="pagination-div">
                    <CustomPaginate
                      currentPage={currentPage}
                      totalPages={products?.totalPages}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Draft_Products">
                  <div className="table-overflow">
                    {shimmerLoader ? (
                      <ShimmerTable row={1} col={14} />
                    ) : (
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr className="">
                            <td className="tdd-checkkkk">
                              <input
                                type="checkbox"
                                value="selectAll"
                                checked={
                                  allChecked.length === selectAllChecked.length
                                }
                                onChange={handleChange}
                                id="selectAll"
                                className="me-1"
                              ></input>
                            </td>

                            <td>
                              Product Detail{" "}
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            {/* <td>
                    Product Image
                    <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    />
                  </td> */}

                            <td>
                              Child Category
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Category
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Variants
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Created On
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Updated On
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Total Views
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Total Orders
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                          

                            {/* <td>
                    Discountable Product
                    
                  </td> */}

                            {/* <td>Add in B2C</td> */}

                            <td>Status</td>

                            <td>Edit Product</td>
                          </tr>
                        </thead>

                        <tbody>
                          {products?.data?.map((value, index) => (
                            <tr>
                              <td className="tdd-checkkkk">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value={value?.id}
                                  name="perselected"
                                  checked={selectAllChecked.includes(value?.id)}
                                  onChange={handleChange}
                                  id="flexCheckDefault"
                                ></input>
                              </td>
                              <td>
                                {" "}
                                {value?.product_title}{" "}
                                <p className="skiddd">
                                  {/* SKU ID: KS944RUR  */}
                                </p>{" "}
                              </td>
                              {/* <td>
                      <img
                        className="keybord-img"
                        src={
                          IMG_URL +
                          "/assest/images/Seller_panel/dashboard/icons/keybord.png"
                        } 
                      />
                    </td> */}
                              <td>{value?.s_child_category?.name}</td>
                              <td>{value?.s_category?.name}</td>

                              <td>
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-title">
                                      View All Variants
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    onClick={() =>
                                      handleVariantModal(value, index)
                                    }
                                    // onClick={handleShow}
                                    icon="fa-solid fa-eye"
                                    className=""
                                  />
                                </OverlayTrigger>
                              </td>

                              <td>
                                {value?.createdAt &&
                                  formatDate2(value.createdAt)}
                              </td>
                              <td>
                                {value?.updatedAt &&
                                  formatDate2(value.updatedAt)}
                              </td>
                              <td>
                                <FontAwesomeIcon icon="fa-solid fa-eye" />
                                300
                              </td>
                              <td>200</td>

                             
                              {/* <td>
                      <Form.Check type="switch" id="custom-switch" />
                    </td> */}
                              {/* <td>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={value?.btc_status === 1 ? true : false}
                          onClick={() => handleBTCStatus(value?.id)}
                        />
                      </td> */}
                              <td>
                                <button
                                  className={
                                    value?.status === 1
                                      ? "active-btn"
                                      : "inactive-btn"
                                  }
                                  onClick={() => handleStatus(value?.id)}
                                >
                                  {value?.status === 1 ? "Active" : "InActive"}
                                </button>
                              </td>
                              <td>
                                <button
                                  className="action-btn"
                                  onClick={() => {
                                    setVarientType(
                                      value?.s_product_variant?.variant_type
                                    );
                                    setProductId(value?.id);
                                    setModalShowedit(true);
                                  }}
                                >
                                  Action
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>

                  <div className="pagination-div">
                    <CustomPaginate
                      currentPage={currentPage}
                      totalPages={products?.totalPages}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Completed_Products">
                  <div className="table-overflow">
                    {shimmerLoader ? (
                      <ShimmerTable row={1} col={14} />
                    ) : (
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr className="">
                            <td className="tdd-checkkkk">
                              <input
                                type="checkbox"
                                value="selectAll"
                                checked={
                                  allChecked.length === selectAllChecked.length
                                }
                                onChange={handleChange}
                                id="selectAll"
                                className="me-1"
                              ></input>
                            </td>

                            <td>
                              Product Detail{" "}
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            {/* <td>
                    Product Image
                    <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    />
                  </td> */}

                            <td>
                              Child Category
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Category
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Variants
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Created On
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Updated On
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Total Views
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Total Orders
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Manufacturing Capacity
                              <span className="pcs-td"> (pcs)</span>{" "}
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            <td>
                              Used Capacity
                              <span className="pcs-td"> (pcs)</span>
                              {/* <FontAwesomeIcon
                      className="fafdowncaret"
                      icon="fa-solid fa-caret-down"
                    /> */}
                            </td>

                            {/* <td>
                    Discountable Product
                    
                  </td> */}

                            {/* <td>Add in B2C</td> */}

                            <td>Status</td>

                            <td>Edit Product</td>
                          </tr>
                        </thead>

                        <tbody>
                          {products?.data?.map((value, index) => (
                            <tr>
                              <td className="tdd-checkkkk">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value={value?.id}
                                  name="perselected"
                                  checked={selectAllChecked.includes(value?.id)}
                                  onChange={handleChange}
                                  id="flexCheckDefault"
                                ></input>
                              </td>
                              <td>
                                {" "}
                                {value?.product_title}{" "}
                                <p className="skiddd">
                                  {/* SKU ID: KS944RUR  */}
                                </p>{" "}
                              </td>
                              {/* <td>
                      <img
                        className="keybord-img"
                        src={
                          IMG_URL +
                          "/assest/images/Seller_panel/dashboard/icons/keybord.png"
                        } 
                      />
                    </td> */}
                              <td>{value?.s_child_category?.name}</td>
                              <td>{value?.s_category?.name}</td>

                              <td>
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-title">
                                      View All Variants
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    onClick={() =>
                                      handleVariantModal(value, index)
                                    }
                                    // onClick={handleShow}
                                    icon="fa-solid fa-eye"
                                    className=""
                                  />
                                </OverlayTrigger>
                              </td>

                              <td>
                                {value?.createdAt &&
                                  formatDate2(value.createdAt)}
                              </td>
                              <td>
                                {value?.updatedAt &&
                                  formatDate2(value.updatedAt)}
                              </td>
                              <td>
                                <FontAwesomeIcon icon="fa-solid fa-eye" />
                                300
                              </td>
                              <td>200</td>

                              <td>
                                {
                                  value?.s_product_information
                                    ?.manufacturing_capacity
                                }
                              </td>
                              <td>
                                {
                                  value?.s_product_information
                                    ?.supply_capacity_a_month
                                }
                              </td>
                              {/* <td>
                      <Form.Check type="switch" id="custom-switch" />
                    </td> */}
                              {/* <td>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={value?.btc_status === 1 ? true : false}
                          onClick={() => handleBTCStatus(value?.id)}
                        />
                      </td> */}
                              <td>
                                <button
                                  className={
                                    value?.status === 1
                                      ? "active-btn"
                                      : "inactive-btn"
                                  }
                                  onClick={() => handleStatus(value?.id)}
                                >
                                  {value?.status === 1 ? "Active" : "InActive"}
                                </button>
                              </td>
                              <td>
                                <button
                                  className="action-btn"
                                  onClick={() => {
                                    setVarientType(
                                      value?.s_product_variant?.variant_type
                                    );
                                    setProductId(value?.id);
                                    setModalShowedit(true);
                                  }}
                                >
                                  Action
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>

                  <div className="pagination-div">
                    <CustomPaginate
                      currentPage={currentPage}
                      totalPages={products?.totalPages}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>

          {/* end tabs table section  */}

          <View_all_varients_offcanvas
            show={varientsoffcanvas}
            variantData={variantData}
            getVariantProducts={getVariantProducts}
            onHide={() => setVarientsoffcanvas(false)}
            placement="top"
          />

          <VeiwEditModal
            show={modalShowedit}
            getAllProducts={getOuterWebSiteAllProducts}
            varientType={varientType}
            productId={productId}
            type={type}
            onHide={() => setModalShowedit(false)}
            setModalShowedit={setModalShowedit}
          />

          <Mrp_modal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </section>
    </>
  );
};

export default B2b_table;
