import React, { useState } from "react";
import "../../../Variants/variants.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import JoditEditor from "jodit-react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import MultiVariant from "../MultiVariant/MultiVariant";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";

const MultiVarientInner = ({
  prevInnerPage,
  nextInnerPage,
  nextStep,
  prevStep,
}) => {
  const [content, setContent] = useState("");
  const [redirectToPrev, setRedirectToPrev] = useState(false); // State to control redirection

  const handlePrev = () => {
    setRedirectToPrev(true); // Set redirectToNext to true
  };

  // Check if redirectToNext is true, if true, render MultiVarientInner
  if (redirectToPrev) {
    return <MultiVariant nextStep={nextStep} prevInnerPage={prevInnerPage} />;
  }

  const uploadimages = [
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/keybord.png",
      dltimg:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/dltt.png",
    },
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/keybord.png",
      dltimg:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/dltt.png",
    },
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/keybord.png",
      dltimg:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/dltt.png",
    },
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/keybord.png",
      dltimg:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/dltt.png",
    },
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/keybord.png",
      dltimg:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/dltt.png",
    },
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/keybord.png",
      dltimg:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/dltt.png",
    },
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/keybord.png",
      dltimg:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/dltt.png",
    },
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/keybord.png",
      dltimg:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/dltt.png",
    },
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/keybord.png",
      dltimg:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/dltt.png",
    },
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/keybord.png",
      dltimg:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/dashboard/icons/dltt.png",
    },
  ];

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: true };
  return (
    <section className="single-varient-sec">
      <div className="sec-div ">
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-md-12">
            <Table
              className="multiinner-table"
              bordered
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="(Remove all)"
                    />
                  </th>
                  <th>
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="(Common for all)"
                    />
                  </th>
                </tr>
                <tr>
                  <th>Color</th>
                  <th>Ltr</th>
                  <th>Kg</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={8}>1.Red</td>
                  <td rowSpan={4}>1</td>
                  <td>10</td>
                  <td className="green-add">Add</td>
                </tr>
                <tr>
                  <td>30</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td>50</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td>80</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td rowSpan={4}>2</td>
                  <td>10</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td>30</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td>50</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td>80</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td rowSpan={6}>2.Yellow</td>
                  <td rowSpan={2}>1</td>
                  <td>10</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td>30</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td rowSpan={4}>2</td>
                  <td>10</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td>30</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td>50</td>
                  <td className="red-add">Add</td>
                </tr>
                <tr>
                  <td>80</td>
                  <td className="red-add">Add</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="col-xxl-6 col-xl-6 col-md-12">
            <div className="single-variant-tabsshd multi-scroll-box">
              {/* <Tabs
                defaultActiveKey="product"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="product" title="Product Images">
                  <div className="tab-content-data">
                    <h6 className="proppdp">
                      Product Photos <span>(Max 8)</span>
                    </h6>
                    <div className="tab-content-data">
                      <div className="uploadbox">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="slider-div">
                              <Swiper
                                slidesPerView={3.5}
                                spaceBetween={30}
                                freeMode={true}
                                pagination={{
                                  clickable: false,
                                }}
                                modules={[FreeMode, Pagination]}
                                className="mySwiper"
                              >
                                {uploadimages.map((data, index) => (
                                  <SwiperSlide>
                                    <div className="upd-card">
                                      <img
                                        className="slide-img"
                                        src={data.img}
                                      />
                                      <img
                                        className="dltt-slide-img"
                                        src={data.dltimg}
                                      />
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="produuyct-discri">
                              <h6 className="tttt">Image Resolution</h6>
                              <p className="testtt">
                                Use clear color images with a minimum resolution
                                of 512*512 px.
                              </p>
                              <h6 className="tttt">Image Guidelines</h6>
                              <p className="testtt">
                                Upload authentic product photos taken in bright
                                lighting
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="Price" title="Price & Stock Details">
                  <div className="tab-content-data">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <Form.Label>
                          Stock<span className="mandatory-star"> *</span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-title">Product</Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-info"
                              className="ms-3"
                            />
                          </OverlayTrigger>
                        </Form.Label>

                        <Form.Control placeholder="Enter stock"></Form.Control>
                      </div>

                      <div className="col-md-6  mb-3">
                        <Form.Label> MRP</Form.Label>
                        <Form.Control placeholder="Enter MRP"></Form.Control>
                      </div>
                      <div className="col-md-6  mb-3">
                        <Form.Label> Selling Price</Form.Label>
                        <Form.Control placeholder="Enter Selling Price"></Form.Control>
                      </div>
                      <div className="col-md-6  mb-3">
                        <Form.Label>
                          {" "}
                          After platform charges (selling price)
                        </Form.Label>
                        <Form.Control placeholder="500"></Form.Control>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="dimension" title="Dimension">
                  <div className="tab-content">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <Form.Label>
                          Length<span className="mandatory-star"> *</span>
                          <span>(MM)</span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-title">Product</Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-info"
                              className="ms-3"
                            />
                          </OverlayTrigger>
                        </Form.Label>

                        <Form.Control placeholder="Enter Length"></Form.Control>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>
                          Breadth<span className="mandatory-star"> *</span>
                          <span>(MM)</span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-title">Product</Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-info"
                              className="ms-3"
                            />
                          </OverlayTrigger>
                        </Form.Label>

                        <Form.Control placeholder="Enter Breadth"></Form.Control>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>
                          Height<span className="mandatory-star"> *</span>
                          <span>(MM)</span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-title">Product</Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-info"
                              className="ms-3"
                            />
                          </OverlayTrigger>
                        </Form.Label>

                        <Form.Control placeholder="Enter Height"></Form.Control>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Label>
                              Weight<span className="mandatory-star"> *</span>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-title">Product</Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-circle-info"
                                  className="ms-3"
                                />
                              </OverlayTrigger>
                            </Form.Label>

                            <Form.Control placeholder="Enter Weight"></Form.Control>
                          </div>
                          <div className="col-md-6">
                            <Form.Label>
                              Select Unit
                              <span className="mandatory-star"> *</span>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-title">Product</Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-circle-info"
                                  className="ms-3"
                                />
                              </OverlayTrigger>
                            </Form.Label>

                            <Form.Select aria-label="Default select example">
                              <option>Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="tax" title="Tax details">
                  <div className="tab-content">
                    <div className="row">
                      <h6>Tax details</h6>
                      <div className="col-md-6 mb-3">
                        <Form.Label>
                          {" "}
                          HSN<span className="mandatory-star"> *</span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-title">Product</Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-info"
                              className="ms-3"
                            />
                          </OverlayTrigger>
                        </Form.Label>
                        <Form.Control placeholder="Enter Code"></Form.Control>
                      </div>

                      <div className="col-md-6 mb-3">
                        <Form.Label>
                          {" "}
                          GST Primary<span className="mandatory-star"> *</span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-title">Product</Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-info"
                              className="ms-3"
                            />
                          </OverlayTrigger>
                        </Form.Label>
                        <Form.Select aria-label="Default select example">
                          <option>--Select One--</option>
                          <option value="1">Color</option>
                          <option value="2">Size</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="other" title="Other Details">
                  <div className="tab-content">
                    <div className="row">
                      <div className="col-md-5">
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          label="Discountable ?"
                        />
                      </div>
                      <div className="col-md-5">
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch2"
                          label="Open Delivery ?"
                        />
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="thumbnail" title="Thumbnail">
                  <div className="tab-content">
                    <p className="thumbnail-txt">Thumbnail</p>
                    <UploadButton uploader={uploader} options={options}>
                      {({ onClick }) => (
                        <button onClick={onClick} className="uploadBtn">
                          Upload <FontAwesomeIcon icon="fa-solid fa-upload" />
                        </button>
                      )}
                    </UploadButton>
                  </div>
                </Tab>

                <Tab
                  eventKey="Technical Description"
                  title="Technical Description"
                >
                  <div className="tab-content">
                    <JoditEditor
                      value={content}
                      tabIndex={1}
                      onBlur={(newContent) => setContent(newContent)}
                      onChange={(newContent) => {}}
                    />
                  </div>
                </Tab>
              </Tabs> */}

              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="1"
                className="mb-3"
              >
                <Row>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="1">Product Images</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="2">Price & Stock Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="3">Dimension</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="4">Tax details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="5">Other Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="6">Thumbnail</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="7">Technical Description</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="1">
                      <div className="tab-content-data">
                        <h6 className="proppdp">
                          Product Photos <span>(Max 8)</span>
                        </h6>
                        <div className="tab-content-data">
                          <div className="uploadbox">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="slider-div">
                                  <Swiper
                                    slidesPerView={3.5}
                                    spaceBetween={30}
                                    freeMode={true}
                                    pagination={{
                                      clickable: false,
                                    }}
                                    modules={[FreeMode, Pagination]}
                                    className="mySwiper"
                                  >
                                    {uploadimages.map((data, index) => (
                                      <SwiperSlide>
                                        <div className="upd-card">
                                          <img
                                            className="slide-img"
                                            src={data.img}
                                          />
                                          <img
                                            className="dltt-slide"
                                            src={data.dltimg}
                                          />
                                        </div>
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="produuyct-discri">
                                  <h6 className="tttt">Image Resolution</h6>
                                  <p className="testtt">
                                    Use clear color images with a minimum
                                    resolution of 512*512 px.
                                  </p>
                                  <h6 className="tttt">Image Guidelines</h6>
                                  <p className="testtt">
                                    Upload authentic product photos taken in
                                    bright lighting
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="2">
                      <div className="tab-content-data">
                        <div className="row">
                          <div className="col-md-4 mb-3">
                            <Form.Label>
                              Stock<span className="mandatory-star"> *</span>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-title">Stock</Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-circle-info"
                                  className="ms-3"
                                />
                              </OverlayTrigger>
                            </Form.Label>

                            <Form.Control placeholder="Enter stock"></Form.Control>
                          </div>

                          <div className="col-md-4  mb-3">
                            <Form.Label> MRP</Form.Label>
                            <Form.Control placeholder="Enter MRP"></Form.Control>
                          </div>
                          <div className="col-md-4  mb-3">
                            <Form.Label> Selling Price</Form.Label>
                            <Form.Control placeholder="Enter Selling Price"></Form.Control>
                          </div>
                          <div className="col-md-4  mb-3">
                            <Form.Label>
                              After platform charges (selling price)
                            </Form.Label>
                            <Form.Control placeholder="500"></Form.Control>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="3">
                      <div className="tab-content">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <Form.Label>
                              Length<span className="mandatory-star"> *</span>
                              <span>(MM)</span>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-title">Product</Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-circle-info"
                                  className="ms-3"
                                />
                              </OverlayTrigger>
                            </Form.Label>

                            <Form.Control placeholder="Enter Length"></Form.Control>
                          </div>
                          <div className="col-md-6 mb-3">
                            <Form.Label>
                              Breadth<span className="mandatory-star"> *</span>
                              <span>(MM)</span>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-title">Product</Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-circle-info"
                                  className="ms-3"
                                />
                              </OverlayTrigger>
                            </Form.Label>

                            <Form.Control placeholder="Enter Breadth"></Form.Control>
                          </div>
                          <div className="col-md-6 mb-3">
                            <Form.Label>
                              Height<span className="mandatory-star"> *</span>
                              <span>(MM)</span>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-title">Product</Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-circle-info"
                                  className="ms-3"
                                />
                              </OverlayTrigger>
                            </Form.Label>

                            <Form.Control placeholder="Enter Height"></Form.Control>
                          </div>

                          <div className="col-md-6 mb-3">
                            <div className="row">
                              <div className="col-md-7">
                                <Form.Label>
                                  Weight
                                  <span className="mandatory-star"> *</span>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-title">
                                        Product
                                      </Tooltip>
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-circle-info"
                                      className="ms-3"
                                    />
                                  </OverlayTrigger>
                                </Form.Label>

                                <Form.Control placeholder="Enter Weight"></Form.Control>
                              </div>
                              <div className="col-md-5">
                                <Form.Label>
                                  Select Unit
                                  <span className="mandatory-star"> *</span>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-title">
                                        Product
                                      </Tooltip>
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-circle-info"
                                      className="ms-3"
                                    />
                                  </OverlayTrigger>
                                </Form.Label>

                                <Form.Select aria-label="Default select example">
                                  <option>Select</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                </Form.Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="4">
                      <div className="tab-content">
                        <div className="row">
                          <h6>Tax details</h6>
                          <div className="col-md-6 mb-3">
                            <Form.Label>
                              {" "}
                              HSN<span className="mandatory-star"> *</span>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-title">Product</Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-circle-info"
                                  className="ms-3"
                                />
                              </OverlayTrigger>
                            </Form.Label>
                            <Form.Control placeholder="Enter Code"></Form.Control>
                          </div>

                          <div className="col-md-6 mb-3">
                            <Form.Label>
                              {" "}
                              GST Primary
                              <span className="mandatory-star"> *</span>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-title">Product</Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-circle-info"
                                  className="ms-3"
                                />
                              </OverlayTrigger>
                            </Form.Label>
                            <Form.Select aria-label="Default select example">
                              <option>--Select One--</option>
                              <option value="1">Color</option>
                              <option value="2">Size</option>
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="5">
                      <div className="tab-content">
                        <div className="row">
                          <div className="col-md-5">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              label="Discountable ?"
                            />
                          </div>
                          <div className="col-md-5">
                            <Form.Check
                              type="switch"
                              id="custom-switch2"
                              label="Open Delivery ?"
                            />
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="6">
                      <div className="tab-content">
                        <p className="thumbnail-txt">Thumbnail</p>
                        <UploadButton uploader={uploader} options={options}>
                          {({ onClick }) => (
                            <button onClick={onClick} className="uploadBtn">
                              Upload{" "}
                              <FontAwesomeIcon icon="fa-solid fa-upload" />
                            </button>
                          )}
                        </UploadButton>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="7">
                      <div className="tab-content">
                        <JoditEditor
                          value={content}
                          tabIndex={1}
                          onBlur={(newContent) => setContent(newContent)}
                          onChange={(newContent) => {}}
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
      <div className="common-steps-btn">
        <button type="button" onClick={handlePrev} className="back-btn">
          Back
        </button>
        <button type="button" onClick={nextStep} className="next-btn">
          Save & Next
        </button>
      </div>
    </section>
  );
};

export default MultiVarientInner;
