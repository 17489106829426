// InnerPageTwo.js

import React, { useState, Fragment, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import HomeAddress from "./Industry-Details-Tab-Forms/OfficeAddress/OfficeAddress";
import BranchAddress from "./Industry-Details-Tab-Forms/Other/Other";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../../Terms&Condition/TermsAndConditionModal";
import Other from "./Industry-Details-Tab-Forms/Other/Other";
import OfficeAddress from "./Industry-Details-Tab-Forms/OfficeAddress/OfficeAddress";
import Select from "react-select";
import { Errors, Placeholders } from "../../../../../utils/errors";
import SuccessModal from "../../../../CommonForAll/SuccessModal/SuccessModal.js";
import {
  FirmType,
  MediaTypes,
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../../utils/apis/master/Master";
import { Select2Data } from "../../../../../utils/common";
import { Context } from "../../../../../utils/context";

import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import classNames from "classnames";
import {
  firmDetailNamePost,
  getFirmDetailName,
  firmAddressDetailPost,
  getfirmAddressDetail,
  firmDetailsPost,
  getfirmDetails,
} from "../../../../../utils/apis/media/media";
const InnerPageTwo = ({
  innerNext,
  prevInnerPage,
  setPage,
  handleBack,
  activeTab,
  navItems,
  handleTabClick,
  gstNumber,
  mediaDetails,
  mediaID,
}) => {
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [addressCount, setAddressCount] = useState(1);
  const [firmType, setFirmType] = useState([]);

  const [successModalShow, setSuccessModalShow] = useState(false);

  const handleAddAddress = () => {
    setAddressCount((prevCount) => prevCount + 1);
  };

  const handleRemoveAddress = () => {
    setAddressCount((prevCount) => prevCount - 1);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const onFirmNameSubmit = async (d) => {
    // console.log(d, "form");
    const data = {
      name: d.name,
      firm_type_id: d.firm_type_id.value,
      media_personal_details_id: mediaID,
    };
    if (firmDetailNameId != "") {
      data.id = firmDetailNameId;
    }
    const res = await firmDetailNamePost(data);
    if (res?.success) {
      console.log(res);
      getFirmDetailNames();
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  };

  const getMasters = async () => {
    {
      const res = await FirmType();
      if (res?.success) {
        const data = await Select2Data(res.data, "firm_type_id", false);
        setFirmType(data);
      }
    }
  };

  const [firmDetailNameId, setFirmDetailNameId] = useState("");
  const getFirmDetailNames = async () => {
    const res = await getFirmDetailName(mediaID);
    if (res?.success) {
      setFirmDetailNameId(res.data.id);
      reset(res.data);
    }
  };

  const [error, setErrors] = useState("");
  const handleNext = () => {
    if (addId && firmDetailNameId) {
      setPage(3);
    } else {
      setErrors("Please Fill the form.");
    }
  };

  useEffect(() => {
    getMasters();
    getFirmDetailNames();
  }, []);

  const [addId, setAddId] = useState("");

  const getFirmTypes = async () => {
    const res = await FirmType();
    if (res?.success) {
      setFirmType(await Select2Data(res.data, "firm_type_id"));
    }
  };

  useEffect(() => {
    getMasters();
    getFirmDetailNames();
    getFirmTypes();
  }, []);
  return (
    <div className="form-container">
      <h6 className="ms-3 firmmmdd">
        {" "}
        <FontAwesomeIcon
          className="left-arrow  me-2"
          icon="fa-solid fa-arrow-left "
          onClick={prevInnerPage} // Call prevInnerPage function
        />
        Firm Details
      </h6>
      <div className="tab-form-container firstthreerrr">
        <Form onSubmit={handleSubmit(onFirmNameSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Enter the firm name</Form.Label>
                {/* <Form.Control type="text" placeholder="Enter firm name" /> */}
                <Form.Control
                  className={classNames("", {
                    "is-invalid": errors?.name,
                    "is-valid": getValues("name"),
                  })}
                  type="text"
                  {...register("name", {
                    required: Errors.firmName,
                  })}
                  placeholder={Placeholders.firmName}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Select your firm type</Form.Label>

                <Controller
                  name="firm_type_id"
                  {...register("firm_type_id", {
                    required: Errors.firmType,
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.firm_type_id ? "red" : baseStyles,
                        }),
                      }}
                      {...field}
                      options={firmType}
                    />
                  )}
                />
              </Form.Group>
            </div>
          </div>
          <div className="text-end">
            <Button className="btn StepfoursaveBtn" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>

      <h6 className="ms-3 firmmmdd">Firm Address Details</h6>

      <HomeAddress
        setSuccessModalShow={setSuccessModalShow}
        mediaID={mediaID}
        firmDetailNameId={firmDetailNameId}
        addId={addId}
        setAddId={setAddId}
      />

      {/* <div className="Common-details-step m-3">
        <Form.Check
          inline
          type="checkbox"
          label="Is Firm Address Details  are same ?"
          name=""
          className="my-2"
        />
      </div> */}
      <div className="col-md-12">
        <div className="text-end btnMainn apply_now_btn">
          {/* <p onClick={() => setModalShow(true)}>
            By continuing, I agree to Netpurti's
            <span className="termstxt">Terms of Use & Privacy Policy</span>
          </p> */}
          <p>
            <span className="text-danger">{error}</span>
          </p>

          <div className="registerContinueMain">
            <Button className="back-btn" onClick={prevInnerPage}>
              Back
            </Button>
          </div>

          <div className="registerContinueMain">
            <Button
              className="tabs-btn"
              onClick={handleNext}
              // type="submit"
            >
              Register and Continue
            </Button>
          </div>
        </div>
        <SuccessModal show={successModalShow} />
        <TermsAndConditionModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
    </div>
  );
};

export default InnerPageTwo;
