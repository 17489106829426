import React from "react";
import SellerstepForm from "../../SellerstepFormMain/SellerstepForm";

const Steofirm = () => {
  return (
    <>
      <section>
        <div className="container">
          <SellerstepForm />
        </div>
      </section>
      {/* <h3>dkjkfjfk</h3> */}
    </>
  );
};

export default Steofirm;
