import React, { useState, useEffect, useContext } from "react";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import {
  AttachmentPost,
  getAttachment,
} from "../../../../utils/apis/affiliate/affiliate";
import { Context } from "../../../../utils/context";

function Stepfive(props, nextStep, prevStep, affiliateDetails, affiliateID) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: true };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const { getData, postData, IMG_URL } = useContext(Context);

  const onSubmit = async (data) => {
    const formData = new FormData();

    if (attachmentId != "") {
      formData.append("id", attachmentId);
    }
    formData.append(
      "a_personal_details_id",
      props.affiliateDetails.a_personal_details_id
    );
    formData.append("logo", data.logo[0]);
    formData.append("banner", data.banner[0]);

    const res = await AttachmentPost(formData);
    if (res?.success) {
      props.nextStep();
    }
  };

  const [selectedLogoImages, setSelectedLogoImage] = useState(null);
  const [selectedBannerImages, setSelectedBannerImage] = useState(null);

  const handleLogoChange = (e) => {
    const logo = e.target.files[0];
    setSelectedLogoImage(logo);
    clearErrors("logo");
  };

  const handleBannerChange = (e) => {
    const banner = e.target.files[0];
    setSelectedBannerImage(banner);
    clearErrors("banner");
  };
  const [storeLogo, setStoreLogo] = useState(null);

  const [storeBanner, setStoreBanner] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedLogoImage(file);
    setStoreLogo(null);
    clearErrors("logo");
  };

  const handleFileBannerChange = (e) => {
    const file = e.target.files[0];
    setSelectedBannerImage(file);
    setStoreBanner(null);
    clearErrors("banner");
  };

  const handleBannerRemoveFile = () => {
    setSelectedBannerImage(null);
    const fileInput = document.getElementById("filebannerInput");
    fileInput.value = "";
  };

  const handleRemoveFile = () => {
    setSelectedLogoImage(null);
    const fileInput = document.getElementById("fileLogoInput");
    fileInput.value = "";
  };

  const [attachmentId, setAttachmentId] = useState("");

  const getMediaAttechments = async () => {
    const res = await getAttachment(
      props.affiliateDetails.a_personal_details_id
    );
    if (res?.success) {
      setAttachmentId(res.data.id);
      setStoreLogo(IMG_URL + res.data.logo);
      setStoreBanner(IMG_URL + res.data.banner);
      // setSelectedLogoImage(IMG_URL + res.data.logo);
      // setSelectedBannerImage(IMG_URL + res.data.banner);
      reset(res.data);
    }
  };

  useEffect(() => {
    getMediaAttechments();
  }, []);

  return (
    <>
      <div className="personal-details-form mb-0 ">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h6 className="ms-3 firmmmdd">Attachments</h6>
          <div className="tab-form-container firstthreerrr">
            <div className="row">
              <div className="col-lg-4">
                <div className="uploadGsthead">
                  <h3>Upload Logo</h3>
                </div>

                <div className="image-preview">
                  <div className="uploadBtnsec">
                    {storeLogo !== null ? (
                      <input
                        type="file"
                        id="fileLogoInput"
                        accept="image/*"
                        {...register("logo")}
                        onChange={handleFileChange}
                      />
                    ) : (
                      <input
                        type="file"
                        id="fileLogoInput"
                        accept="image/*"
                        {...register("logo", {
                          required: "Logo image is required",
                        })}
                        onChange={handleFileChange}
                      />
                    )}
                  </div>
                  {selectedLogoImages && (
                    <div className="previewImgmain">
                      <img
                        src={URL?.createObjectURL(selectedLogoImages)}
                        alt={`Preview of ${selectedLogoImages?.name}`}
                        className="previewImg"
                      />
                      <div>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-xmark"
                          className="circlecrossMark"
                          onClick={handleRemoveFile}
                        />
                      </div>
                    </div>
                  )}
                  {storeLogo !== null && (
                    <div className="previewImgmain">
                      <img src={storeLogo} className="previewImg" />
                    </div>
                  )}
                </div>
                {errors.logo && (
                  <span className="text-danger">{errors.logo.message}</span>
                )}
              </div>

              <div className="col-lg-4">
                <div className="uploadGsthead">
                  <h3>Upload Banner</h3>
                </div>

                <div className="image-preview">
                  <div className="uploadBtnsec">
                    {storeBanner !== null || selectedBannerImages !== null ? (
                      <input
                        type="file"
                        id="filebannerInput"
                        accept="image/*"
                        {...register("banner")}
                        onChange={handleFileBannerChange}
                      />
                    ) : (
                      <input
                        type="file"
                        id="filebannerInput"
                        accept="image/*"
                        {...register("banner", {
                          required: "Banner image is required",
                        })}
                        onChange={handleFileBannerChange}
                      />
                    )}
                  </div>
                  {selectedBannerImages && (
                    <div className="previewImgmain">
                      <img
                        src={URL.createObjectURL(selectedBannerImages)}
                        alt={`Preview of ${selectedBannerImages?.name}`}
                        className="previewImg"
                      />
                      <div>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-xmark"
                          className="circlecrossMark"
                          onClick={handleBannerRemoveFile}
                        />
                      </div>
                    </div>
                  )}
                  {storeBanner !== null && (
                    <div className="previewImgmain">
                      <img src={storeBanner} className="previewImg" />
                    </div>
                  )}
                </div>
                {errors.banner && (
                  <span className="text-danger">{errors.banner.message}</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="text-end apply_now_btn">
              <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p>

              <Button onClick={props.prevStep} className="back-btn">
                Back
              </Button>

              <Button
                type="submit"
                // onClick={nextStep}
                className="tabs-btn"
              >
                Register & Continue
              </Button>

              <TermsAndConditionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Stepfive;
