import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";


  export const transportPersonalDetailsPost = async (data) => {
    try {
        const res = await postData(`/transporter/transporter-create/personal-details`,data)      
      return res;
    } catch (error) {
      console.log(error);
    }
  };


  export const getSectorCategory = async (user_id) => {
    try {
        const res = await getData(`/btb/btb-create/btb-sector-category/${user_id}`)      
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const transportGstDetailsPost  = async (data) => {
    try {
      const res = await postData(`/transporter/transporter-create/firm-details`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  export const transportGstDetailsGet  = async (user_id) => {
      try {  
      const res = await getData(`/transporter/transporter-create/firm-details/${user_id}`);  
      return res;
    } catch (error) {
      console.log(error);
    }
  };


  export const AttachmentPost  = async (data) => {
    try {
      const res = await postData(`/transporter/transporter-create/attachment`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  export const getAttachment  = async (user_id) => {
      try {  
      const res = await getData(`/transporter/transporter-create/attachment/${user_id}`);  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const firmDetailNamePost = async (data) => {
    try {  
      const res = await postData(`/transporter/transporter-create/firm-details/name`, data);  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const getFirmDetailName = async (user_id) => {
    try {
  
      const res = await getData(`/transporter/transporter-create/firm-details/name/${user_id}`);  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const firmAddressDetailPost = async (data) => {
    try {
  
      const res = await postData(`/transporter/transporter-create/firm-address-details`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const getfirmAddressDetail = async (user_id) => {
    try {
  
      const res = await getData(`/transporter/transporter-create/firm-address-details/${user_id}`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };


  export const branchAddressDetailPost = async (data) => {
    try {
  
      const res = await postData(`/transporter/transporter-create/branch-address-details`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const getbranchAddressDetail = async (user_id) => {
    try {
  
      const res = await getData(`/transporter/transporter-create/branch-address-details/${user_id}`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };


  
  export const firmBankDetailPost = async (data) => {
    try {
  
      const res = await postData(`/transporter/transporter-create/bank-details`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const getfirmBankDetail = async (user_id) => {
    try {
  
      const res = await getData(`/transporter/transporter-create/bank-details/${user_id}`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  

  export const firmPasswordPost = async (data) => {
    try {  
        const res = await postData(`/transporter/transporter-create/personal-details/password`,data);
      
      return res;
    } catch (error) {
      console.log(error);
    }
  };


  export const postServiceAddress = async (data) => {
    try {
  
      const res = await postData(`/transporter/transporter-create/service-details`, data);  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const getServiceAddress = async (user_id) => {
    try {
  
      const res = await getData(`/transporter/transporter-create/service-details/${user_id}`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };


  export const shipmentPost = async (data) => {
    try {
  
      const res = await postData(`/transporter/transporter-create/service-shipment`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const getShipment = async (user_id) => {
    try {
  
      const res = await getData(`/transporter/transporter-create/service-shipment/${user_id}`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };