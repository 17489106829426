import React from 'react'

function StepthreeDescription() {
  return (
    <>
    <section className='step3'>
step3
    </section>
    </>
  )
}

export default StepthreeDescription