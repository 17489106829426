import React, { useContext, useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import "../../HeaderAllCategories/Categorione.css"
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import SkinCare from '../../Home/Skin_Care/SkinCare';
import SelectedTrendingProducts_NewArrivals from '../../Home/SelectedTrendingProducts_NewArrivals/SelectedTrendingProducts_NewArrivals';
import { AllCategory, SubCategory } from '../../../../utils/apis/master/Master';
import {Context} from '../../../../utils/context';
library.add(fas);

const Categorione = () => {
    const { IMG_URL, signin, usertype, setSignin, setUsertype, Id } =
    useContext(Context);

    const [category, setCategory] = useState([]);
    const [selectedCat, setselectedCat] = useState();
    const [subcategory, setSubCategory] = useState([]);

    const [categoryId, setCategoryId] = useState({});

    const getCatData = async () => {
        setCategory(await AllCategory());
    };

    const getSubCategory = async (val) => {
        setCategoryId(val);
        setSubCategory(await SubCategory(val?.id));
    };

    useEffect(() => {
        getCatData();
    }, []);

    console.log("mobile", category, subcategory)


    return (
        <>

            <section className='categories-mobile-page'>
                <div className='container'>
                    <div className='heading'>
                        <Link to="/b2bbuyer/buyer-home">
                            <FontAwesomeIcon icon="fa-solid fa-angle-left" />
                        </Link>
                        <div className='mx-auto'>
                            <h1>All categories</h1>
                        </div>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className='nav-head'>
                            <Nav variant="pills" className="d-flex">
                                {category?.data?.map((val, index) => (
                                    <div className="name-div">
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey={val?.id}
                                                onClick={async () => {
                                                    getSubCategory(val);
                                                    setselectedCat(val?.id)
                                                }}
                                            >
                                                {val?.name}
                                            </Nav.Link>
                                        </Nav.Item>
                                    </div>
                                ))}

                            </Nav>
                        </div>
                        <Tab.Content className='my-4'>
                            <Tab.Pane eventKey={selectedCat}>
                                <div className='row-div'>
                                    <div className='row'>
                                        
                                            {subcategory?.data?.map((val, index) => (
                                                <div className='col-md-2 col-sm-2 col-4 text-center'>
                                                <Link key={index}  to={`/b2bbuyer/b2b-bestSeller/${categoryId?.id}/${categoryId?.name}`}>
                                                    <div className='img-bg'>
                                                        <img  src={IMG_URL + val?.image} class="product-img" alt="..." />
                                                    </div>
                                                    <p>{val?.name}</p>
                                                </Link>
                                                </div>
                                            ))}

                                        

                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>


                    </Tab.Container>


                    <SelectedTrendingProducts_NewArrivals />



                </div>
            </section>
        </>
    )
}

export default Categorione