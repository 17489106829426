import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import { Container } from "react-bootstrap";
import "./InnerBlogBanner.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

function InnerBlogBanner() {
  const { id } = useParams();

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/blog/blog-question/${id}`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);


  return (
    <>
      <section className="bann-section">
        <Container fluid>
        <div className="tops-headig">
            <h2>Seller Blogs</h2>
        </div>
            
          <div className="main-bann">
            <div className="img-ban">
              <img
                className="bannn-img"
                // src={
                //   process.env.PUBLIC_URL +
                //   "/assest/images/Seller_panel/seller-image/sellerblogs/sellerbanner.png"
                // }
                src={
                  IMG_URL +
                 data.banner_image
                }
              />
            </div>
            <div className="ban-cont-main">
              <div className="ban-cont-div">
                <h6>{data.title}</h6>
                <p className="unwraps">
                {data.sub_title}
                </p>
              </div>
              <div className="ban-info">
                <div className="ban-info-1 info-1">
                  <FontAwesomeIcon
                    icon="fa-solid fa-user"
                    className="ban-icn"
                  />{" "}
                  <span className="info-name">{data.name}</span>
                </div>
                <div className="ban-info-1 info-2">
                  <FontAwesomeIcon
                    icon="fa-solid fa-calendar-days"
                    className="ban-icn"
                  />{" "}
                  <span className="info-name">{data.createdAt}</span>
                </div>
              </div>
            </div>
            <div className="ban-overlay"></div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default InnerBlogBanner;
