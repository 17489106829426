import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import InputGroup from 'react-bootstrap/InputGroup';
import InquiryModal from '../inquiry-Modal/InquiryModal';
const ChangeModal = (props) => {
    const [modalMailChanged, setModalMailChanged] = React.useState(false);
    const changeSetModal = () => {
        setModalMailChanged(true);
        setTimeout(() => {
        //   window.location = ""
          setModalMailChanged(false);
        }, 3000);
      };
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='edit-details-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Change Email Address
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Please make sure that your new email address works.</h2>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={6} controlId="formGridEmail">
                                <Form.Label>Login ID</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder=""
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        className='email-text'
                                    />
                                    <InputGroup.Text id="basic-addon2">Verify</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md={6} controlId="formGridEmail">
                                <Form.Label>Email </Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Enter email address"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        className='email-text'
                                    />
                                    <InputGroup.Text id="basic-addon2" className='send-otp'>Send OTP</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>

                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent onClick={props.onHide} className={'btn-cancle'}>Cancel</ButtonComponent>
                    <ButtonComponent className={'btn-update'} onClick={() => { changeSetModal(true); props.onHide(); }}> Submit</ButtonComponent>
                </Modal.Footer>
            </Modal>
            <InquiryModal
                message="Your Email Changed Successfully"
                show={modalMailChanged}
                onHide={() => setModalMailChanged(false)}
            />
        </>
    )
}

export default ChangeModal