import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const sellerProductCreateandUpdate = async (data) => {
  try {
    const res = await postData(`/seller/seller-product/s-product`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const sellerProductGet = async (id) => {
  try {
    const res = await getData(`/seller/seller-product/s-product/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const sellerCheckBrand = async (name) => {
  try {
    const res = await getData(
      `/seller/seller-product/s-product/brand-check/${name}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const productBrandPost = async (data) => {
  try {
    const res = await postData(`/seller/seller-product/s-product/brand`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const productBrandGet = async (product_id) => {
  try {
    const res = await getData(
      `/seller/seller-product/s-product/brand/${product_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const productCommonPost = async (data) => {
  try {
    const res = await postData(`/seller/seller-product/s-common-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const productCommonGet = async (id) => {
  try {
    const res = await getData(`/seller/seller-product/s-common-details/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const singleVariantTablePost = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-product/s-product-variant/s-single-variant`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const singleVariantPost = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-product/s-product-variant/s-single-variant/add`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const singleVariantGet = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-product/s-product-variant/s-single-variant/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const singleVariantDataGet = async (id) => {
  try {
    const res = await getData(
      `/seller/seller-product/s-product-variant/s-single-variant/add/${id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const RemoveSingleAll = async (id) => {
  try {
    const res = await deleteData(
      `/seller/seller-product/s-product-variant/s-single-variant/remove-all/${id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const multipleVariantPost = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-product/s-product-variant/s-multiple-variant`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const multipleVariantGet = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-product/s-product-variant/s-multiple-variant/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const AttributeDelete = async (type, id) => {
  try {
    const res = await deleteData(
      `/seller/seller-product/s-product-variant/s-multiple-variant/${id}/${type}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const VariantIdDataGet = async (type, id) => {
  try {
    const res = await getData(
      `/seller/seller-product/s-product-variant/s-multiple-variant/add/${id}/${type}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const VariantIdDataPost = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-product/s-product-variant/s-multiple-variant/add`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const RemoveAll = async (type, id) => {
  try {
    const res = await deleteData(
      `/seller/seller-product/s-product-variant/s-multiple-variant/remove-all/${id}/${type}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ProductInfoPost = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-product/s-products-information`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getProductInfo = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-product/s-products-information/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const formatDate = (date) => {
  if (!date) return null;
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};
export const getAllProducts = async (
  page = 1,
  title = "",
  status = "",
  category_id = "",
  startDate = "",
  endDate = ""
) => {
  try {
    console.log();
    if (status !== "" && category_id !== "") {
      return await getData(
        `/seller/seller-product/all-products?page=${page}&product_title=${title}&product_status=${status}&category_id=${category_id}`
      );
    }
    if (status === "" && category_id !== "") {
      return await getData(
        `/seller/seller-product/all-products?page=${page}&product_title=${title}&category_id=${category_id}`
      );
    }
    if (status !== "") {
      return await getData(
        `/seller/seller-product/all-products?page=${page}&product_title=${title}&product_status=${status}`
      );
    }
    if (startDate !== "" && endDate !== "") {
      return await getData(
        `/seller/seller-product/all-products?page=${page}&product_title=${title}&from=${formatDate(
          startDate
        )}&to=${formatDate(endDate)}`
      );
    }

    return await getData(
      `/seller/seller-product/all-products?page=${page}&product_title=${title}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getAcceptedProducts = async (
  page = 1,
  title = "",
  status = "",
  category_id = "",
  startDate = "",
  endDate = ""
) => {
  try {
    console.log();
    if (status !== "" && category_id !== "") {
      return await getData(
        `/seller/seller-product/all-products/accepted?page=${page}&product_title=${title}&product_status=${status}&category_id=${category_id}`
      );
    }
    if (status === "" && category_id !== "") {
      return await getData(
        `/seller/seller-product/all-products/accepted?page=${page}&product_title=${title}&category_id=${category_id}`
      );
    }
    if (status !== "") {
      return await getData(
        `/seller/seller-product/all-products/accepted?page=${page}&product_title=${title}&product_status=${status}`
      );
    }
    if (startDate !== "" && endDate !== "") {
      return await getData(
        `/seller/seller-product/all-products/accepted?page=${page}&product_title=${title}&from=${formatDate(
          startDate
        )}&to=${formatDate(endDate)}`
      );
    }

    return await getData(
      `/seller/seller-product/all-products/accepted?page=${page}&product_title=${title}`
    );
  } catch (error) {
    console.log(error);
  }
};
export const getRejectedProducts = async (
  page = 1,
  title = "",
  status = "",
  category_id = "",
  startDate = "",
  endDate = ""
) => {
  try {
    console.log();
    if (status !== "" && category_id !== "") {
      return await getData(
        `/seller/seller-product/all-products/rejected?page=${page}&product_title=${title}&product_status=${status}&category_id=${category_id}`
      );
    }
    if (status === "" && category_id !== "") {
      return await getData(
        `/seller/seller-product/all-products/rejected?page=${page}&product_title=${title}&category_id=${category_id}`
      );
    }
    if (status !== "") {
      return await getData(
        `/seller/seller-product/all-products/rejected?page=${page}&product_title=${title}&product_status=${status}`
      );
    }
    if (startDate !== "" && endDate !== "") {
      return await getData(
        `/seller/seller-product/all-products/rejected?page=${page}&product_title=${title}&from=${formatDate(
          startDate
        )}&to=${formatDate(endDate)}`
      );
    }

    return await getData(
      `/seller/seller-product/all-products/rejected?page=${page}&product_title=${title}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const productStatusChange = async (id) => {
  try {
    const res = await putData(`/seller/seller-product/all-products/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const productBTCStatusChange = async (id) => {
  try {
    const res = await putData(
      `/seller/seller-product/all-products/btc-status/${id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const StockChange = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-product/all-products/stock`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const MrpChange = async (data) => {
  try {
    const res = await postData(`/seller/seller-product/all-products/mrp`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const variantStatus = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-product/all-products/variant-status`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const historiesGet = async (data) => {
  try {
    const res = await postData(
      `/seller/seller-product/all-products/variant-history`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ProductDelete = async (id) => {
  try {
    const res = await deleteData(`/seller/seller-product/all-products/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};
