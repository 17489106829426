import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const btbPercentageSend = {
  stepOne: 0,
  stepTwo: 10,
  stepThree: 40,
  stepFour: 60,
  stepFive: 70,
  stepSix: 90,
  stepseven: 90,
};

export const btbPercentage = [0, 10, 30, 60, 90, 70, 80];

export const btobLogin = async (data) => {
  try {
    const res = await postData(`/btb/auth/login`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const btobPersonalDetailsPost = async (data) => {
  try {
    const res = await postData(`/btb/btb-create/btb-personal-details`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSectorCategory = async (user_id) => {
  try {
    const res = await getData(`/btb/btb-create/btb-sector-category/${user_id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const btobGstDetailsPost = async (data) => {
  try {
    const res = await postData(`/btb/btb-create/btb-firm-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const btobGstDetailsGet = async (user_id) => {
  try {
    const res = await getData(`/btb/btb-create/btb-firm-details/${user_id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmDetailNamePost = async (data) => {
  try {
    const res = await postData(`/btb/btb-create/btb-firm-details/name`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getFirmDetailName = async (user_id) => {
  try {
    const res = await getData(
      `/btb/btb-create/btb-firm-details/name/${user_id}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmAddressDetailPost = async (data) => {
  try {
    const res = await postData(
      `/btb/btb-create/btb-firm-address-details`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmAddressDetail = async (user_id) => {
  try {
    const res = await getData(
      `/btb/btb-create/btb-firm-address-details/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmDropAddressPost = async (data) => {
  try {
    const res = await postData(`/btb/btb-create/btb-firm-drop-address`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmDropAddress = async (user_id) => {
  try {
    const res = await getData(
      `/btb/btb-create/btb-firm-drop-address/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmPasswordPost = async (data) => {
  try {
    const res = await postData(
      `/btb/btb-create/btb-personal-details/password`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};
