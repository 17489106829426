import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import "./SellerTriumphs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";

const SellerTriumphs = () => {
  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const testimonialsData = [
    {
      imgSrc: "/assets/Job-portal/Images/home/testimonial/man.png",
      text: "When transitioning from brick-and-mortar to e-commerce, our goal was to achieve 300 orders per month. Today, we consistently fulfill over 700 orders daily, thanks to the growth tools provided by the Netpurti seller dashboard",
      name: "Shweta K.",
      position: "Senior Frontend Developer",
    },
    {
      imgSrc: "/assets/Job-portal/Images/home/testimonial/man.png",
      text: "I wanted to thank you so much for your help with my job search. I secured a fabulous role… It's exactly what I was looking for and I'm so excited to start! I have worked with a number of recruiters throughout my career, and your firm was easily the best.",
      name: "Sakshi  G.",
      position: "Senior Frontend Developer",
    },
    {
      imgSrc: "/assets/Job-portal/Images/home/testimonial/man.png",
      text: "I wanted to thank you so much for your help with my job search. I secured a fabulous role… It's exactly what I was looking for and I'm so excited to start! I have worked with a number of recruiters throughout my career, and your firm was easily the best.",
      name: "Aditya J.",
      position: "Senior Frontend Developer",
    },
    {
      imgSrc: "/assets/Job-portal/Images/home/testimonial/man.png",
      text: "I wanted to thank you so much for your help with my job search. I secured a fabulous role… It's exactly what I was looking for and I'm so excited to start! I have worked with a number of recruiters throughout my career, and your firm was easily the best.",
      name: "Shweta K.",
      position: "Senior Frontend Developer",
    },
  ];

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/home/s-triumphs`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <section className="Jp-home-testimonial">
      <Container fluid>
        <div className="heading">
          <h4 className="titles">Triumphs of Our Sellers</h4>
          <p>"Discover How Sellers Thrive with Us"</p>
        </div>
        <div className="row justify-content-center">
          <div className="col-xxl-10 col-xl-10 col-lg-11 col-md-12 col-12 ">
            <div className="jp-home-slider">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                initialSlide={1}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                slideShadows={false}
                className="mySwiper"
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    centeredSlides: false,
                  },
                  769: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                  },
                }}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
              >
                {data?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="main-card-div">
                      <div className="right-div">
                        <p className="pararr">{parse(item.description) || ""}</p>

                        <p className="pararr">{item.position}</p>
                      </div>
                      <div className="imgSecslider">
                        <img
                          src={
                            IMG_URL + item?.image
                          }
                          className="imgprofSide"
                        />
                        <p>{item?.name}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div
                className="main-slider"
                onClick={() => handleMainSliderClick("prev")}
              >
                <div className="main-arrow-back">
                  <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
              </div>
              <div
                className="main-slider"
                onClick={() => handleMainSliderClick("next")}
              >
                <div className="main-arrow-next">
                  <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SellerTriumphs;
