import React, { useState } from 'react';
import OrderCard from '../../../Order/OrderCard'
import "./B2bOrder.css"
import Table from 'react-bootstrap/Table';
import CancelOrderModal from '../../../../common-components/AllModal/CancelOrderModal/CancelOrderModal';
import { Link } from 'react-router-dom';
const ordersData = [

  {
    sellerName: 'Sidior Furniture Company Limited',
    productInfo: {
      imageSrc: "/assest/images/B2Bbuyer/BestSeller/productImg1.png",
      productName: "Modern Minimalist White Half Circle Designer Couches Luxury",
      minOrder: "2.0 piece",
      deliveryDate: "Estimated delivery by Apr 26-May 17"
    },
    amount: '₹60,000',
    status: 'Dispatched',
    buttons: {
      makePayment: false,
      cancelOrder: false,
      downloadInvoice: true,
      changeShippingAddress: true,
      orderAgain: false,
      viewMore: false,
      returnOrder: false
    }
  },
  {
    sellerName: 'Sidior Furniture Company Limited',
    productInfo: {
      imageSrc: "/assest/images/B2Bbuyer/BestSeller/productImg1.png",
      productName: "Modern Minimalist White Half Circle Designer Couches Luxury",
      minOrder: "2.0 piece",
      deliveryDate: "Estimated delivery by Apr 26-May 17"
    },
    amount: '₹60,000',
    status: 'waiting',
    buttons: {
      makePayment: false,
      cancelOrder: false,
      downloadInvoice: false,
      changeShippingAddress: false,
      orderAgain: false,
      viewMore: true,
      returnOrder: true
    }
  },
  {
    sellerName: 'Sidior Furniture Company Limited',
    productInfo: {
      imageSrc: "/assest/images/B2Bbuyer/BestSeller/productImg1.png",
      productName: "Modern Minimalist White Half Circle Designer Couches Luxury",
      minOrder: "2.0 piece",
      deliveryDate: "Estimated delivery by Apr 26-May 17"
    },
    amount: '₹60,000',
    status: 'Dispatched',
    buttons: {
      makePayment: true,
      cancelOrder: false,
      downloadInvoice: false,
      changeShippingAddress: true,
      orderAgain: false,
      viewMore: false,
      returnOrder: false,
      tracking: true
    }
  },
  {
    sellerName: 'Sidior Furniture Company Limited',
    productInfo: {
      imageSrc: "/assest/images/B2Bbuyer/BestSeller/productImg1.png",
      productName: "Modern Minimalist White Half Circle Designer Couches Luxury",
      minOrder: "2.0 piece",
      deliveryDate: "Estimated delivery by Apr 26-May 17"
    },
    amount: '₹60,000',
    status: 'Dispatched',
    buttons: {
      makePayment: false,
      cancelOrder: true,
      downloadInvoice: false,
      changeShippingAddress: false,
      orderAgain: false,
      viewMore: false,
      returnOrder: false
    }
  },
];
const B2bOrder = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [orders, setOrders] = useState(ordersData);

  return (
    <>
      <section className='b2b-my-order'>
        <div className='heading'>
          <h1>My Order</h1>
        </div>
        <section className='B2b-buyer-order-sec '>
          <div className='main-table-div' >
            <Table >
              <thead>
                <tr>
                  <th>Seller Name</th>
                  <th>Product information</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {orders.map((order, index) => (
                <tbody className='bd-right'>
                  <tr key={index}>
                    <td><p>{order.sellerName}</p></td>
                    <td>
                      <div className='product-info-div'>
                        <div>
                          <img src={order.productInfo.imageSrc} alt="Product" />
                        </div>
                        <div>
                          <h6>{order.productInfo.productName}</h6>
                          <p className='grey-text'>{order.productInfo.minOrder}</p>
                          <p className='grey-text'>{order.productInfo.deliveryDate}</p>
                        </div>
                      </div>
                    </td>
                    <td><p className='price-text'>{order.amount}</p></td>
                    <td><p>{order.status}</p></td>
                    <td className='text-center'>
                      <div>
                        {order.buttons.makePayment && <button className='payment-btn'>Make Payment</button>}
                      </div>

                      <div>
                        {order.buttons.cancelOrder && <button className='common-btn' onClick={() => setModalShow(true)}>Cancel Order</button>}
                      </div>

                      <div>
                        {order.buttons.downloadInvoice && <button className='common-btn'>Download Invoice</button>}
                      </div>

                      <div>
                        {order.buttons.changeShippingAddress && <button className='common-btn'>Change Shipping Address</button>}
                      </div>

                      <div>
                        {order.buttons.orderAgain && <button className='common-btn'>Order Again</button>}
                      </div>

                      <div>
                        {order.buttons.viewMore && <button className='common-btn'>View More</button>}
                      </div>

                      <div>
                        <Link to="/b2bbuyer/return-order">{order.buttons.returnOrder && <button className='common-btn'>Return Order</button>}</Link>
                      </div>
                      <div>
                        <Link to="/b2bbuyer/tracking">{order.buttons.tracking && <button className='common-btn'>Track Shipment</button>}</Link>
                      </div>

                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
          </div>
          <CancelOrderModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </section>
      </section>
    </>
  )
}

export default B2bOrder