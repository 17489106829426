import React from "react";
import "./Empower-banner.css";
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";

const Empower_banner = () => {
  return (
    <>
      <section className="Empower_banner-sec">
        <img
          className="kjbhimg"
          src={
            process.env.PUBLIC_URL +
            "/assest/images/Seller_panel/seller-image/dashboard/banner.png"
          }
        />
        <div className="blnere-overlay"></div>

        <div className="overlay-conttt">
          <div className="row">
            <div className="col-md-6">
              <p className="baner-trir">
                Empower Your Sales Journey with Our Seller Panel
              </p>
            </div>
            <div className="col-md-6 text-end">
              <div>
                <button className="view-demo-btn">
                  <Link to="">View Demo</Link>
                </button>
                <button className="list-product-btn">
                  <Link to="/seller/seller-dashboard/b2b-products">List Product</Link>
                </button>
              </div>
            </div>

            

          </div>
        </div>
      </section>


      <div className='row m-3'>
              <div className='col-6'>
                <h6 className='dash-t-ttde'>Dashboard</h6>
                <p className='dasvvv-sub-p'>Welcome to netpurti seller panel dashboard</p>
              </div>
              <div className='col-6 text-end'>
                <div className='month-wise-droppp'>
                  <Form.Select aria-label="Default select example">
                    <option>Select</option>
                    <option value="1">Monthly</option>
                    <option value="2">Yearly</option>
                    <option value="3">Weekly</option>
                  </Form.Select>
                </div>
              </div>
            </div>
    </>
  );
};

export default Empower_banner;
