import React, {useState} from "react";
import {Container} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import "./CompareAll.css";

const CompareAll = () => {
    const [isFirstActiveted, setIsFirstActiveted] = useState(true);

    const toggleParagraphs = () => {
        setIsFirstActiveted(!isFirstActiveted);
    };
    return (
        <>
            <section className="CompareAll">
                <Container>
                    <h1 className="plan">Compare All Plans</h1>
                    <div className="Desktop-View">
                        <Row className="mt-4">
                            <Col xxl={3} md={3} sm={12} xs={3}>
                                <div className="Toggle-Btn">
                                    <p className={isFirstActiveted ? "on" : "off"}>Desktop</p>

                                    <Form.Check type="switch" id="custom-switch" onClick={toggleParagraphs} />
                                    <p className={isFirstActiveted ? "off" : "on"}>Online</p>
                                </div>
                            </Col>
                            <Col xxl={9} md={9} sm={12} xs={9}>
                                <Row className="mt-3">
                                    <Col xxl={3} md={3} sm={3} xs={3}>
                                        <div className="">
                                            <div className="white-bg-subscribe">
                                                <div className="header"></div>
                                                <p className="sub-title-medium">Free Trial</p>
                                                <p className="maintenance-title">Free </p>
                                                <div className="Book">
                                                    <Button variant="primary">Buy Now</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={3} sm={3} xs={3}>
                                        <div className="white-bg-subscribe">
                                            <div className="header"></div>
                                            <p className="sub-title-medium">
                                                Semi-Annual <br></br>Plan
                                            </p>
                                            <p className="maintenance-title">US$69 </p>
                                            <div className="Book">
                                                <Button variant="primary">Buy Now</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={3} sm={3} xs={3}>
                                        <div className="white-bg-subscribe">
                                            <div className="header"></div>
                                            <p className="sub-title-medium"> Annual Plan </p>
                                            <p className="maintenance-title">US$69 </p>
                                            <div className="Book">
                                                <Button variant="primary">Buy Now</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={3} sm={3} xs={3}>
                                        <div className="white-bg-subscribe">
                                            <div className="header"></div>
                                            <p className="sub-title-medium"> Perpetual plan</p>
                                            <p className="maintenance-title">US$69 </p>
                                            <div className="Book">
                                                <Button variant="primary">Buy Now</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="Main-accordion">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className="mt-2">
                                    <Accordion.Header>Import</Accordion.Header>
                                    <Accordion.Body>
                                        <Table responsive="sm">
                                            <thead></thead>
                                            <tbody>
                                                <tr>
                                                    <td className="main-width">Import Visio</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Edit Visio Online</td>
                                                    <td align="center">First 3 visio files</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Batch Import & Convert Visio</td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Import SVG</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Import CAD</td>
                                                    <td align="center">5 free trials</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className="mt-2">
                                    <Accordion.Header>Templates</Accordion.Header>
                                    <Accordion.Body>
                                        <Table responsive="sm">
                                            <thead></thead>
                                            <tbody>
                                                <tr>
                                                    <td className="main-width">Built-in Templates</td>
                                                    <td align="center">First 2 templates</td>
                                                    <td align="center">Unlimited </td>
                                                    <td align="center">Unlimited </td>
                                                    <td align="center">Unlimited </td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Local Personal Templates</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Cloud Personal Templates </td>
                                                    <td align="center">2</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Upload to Cloud Personal Templates</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className="mt-2">
                                    <Accordion.Header>Edraw Creative Assets </Accordion.Header>
                                    <Accordion.Body>
                                        <Table responsive="sm">
                                            <thead></thead>
                                            <tbody>
                                                <tr>
                                                    <td className="main-width">EdrawMax Community Templates </td>
                                                    <td align="center">10K</td>
                                                    <td align="center">
                                                        15K <br></br>(5k Enhanced Templates)
                                                    </td>
                                                    <td align="center">
                                                        15K <br></br>(5k Enhanced Templates)
                                                    </td>
                                                    <td align="center">
                                                        15K <br></br>(5k Enhanced Templates)
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">EdrawMind Community Templates </td>
                                                    <td align="center">5K</td>
                                                    <td align="center">
                                                        5K <br></br>(1k Enhanced Templates)
                                                    </td>
                                                    <td align="center">
                                                        5K <br></br>(1k Enhanced Templates)
                                                    </td>
                                                    <td align="center">
                                                        5K <br></br>(1k Enhanced Templates)
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">EdrawMax Al Features</td>
                                                    <td align="center">500 Tokens </td>
                                                    <td align="center">Unlimited Tokens</td>
                                                    <td align="center">Unlimited Tokens </td>
                                                    <td align="center">Unlimited Tokens </td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">EdrawMind Al Features </td>
                                                    <td align="center">500 Tokens </td>
                                                    <td align="center">Unlimited Tokens</td>
                                                    <td align="center">Unlimited Tokens </td>
                                                    <td align="center">Unlimited Tokens </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" className="mt-2">
                                    <Accordion.Header>Basic Functions </Accordion.Header>
                                    <Accordion.Body>
                                        <Table responsive="sm">
                                            <thead></thead>
                                            <tbody>
                                                <tr>
                                                    <td className="main-width">Set/Unset Gridlines </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                    </td>
                                                    <td align="center">
                                                        {" "}
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Number of Shapes</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Type of shapes</td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Multiple Pages</td>
                                                    <td align="center">Max. 2 pages </td>
                                                    <td align="center">Unlimited </td>
                                                    <td align="center">Unlimited </td>
                                                    <td align="center">Unlimited </td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Background Color</td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Themes </td>
                                                    <td align="center">3 </td>
                                                    <td align="center">20+</td>
                                                    <td align="center">20+</td>
                                                    <td align="center">20+ </td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Themes Colors </td>
                                                    <td align="center">3 </td>
                                                    <td align="center">10+</td>
                                                    <td align="center">10+ </td>
                                                    <td align="center">10+</td>
                                                </tr>
                                                <tr>
                                                    <td className="main-width">Batch Replace Color</td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>

                    <div className="Mobile-View">
                        <Row className="mt-4">
                            <Col xxl={3} xl={3} md={12} sm={12} xs={12}>
                                <Form>
                                    <span className="me-2">Desktop</span>
                                    <Form.Check type="switch" id="custom-switch" />
                                    <span>Online</span>
                                </Form>
                            </Col>
                            <Col xxl={9} xl={9} md={12} sm={12} xs={12}>
                                <Row className="mt-4">
                                    <Col xxl={3} md={3} sm={3} xs={3}>
                                        <div className="">
                                            <div className="white-bg-subscribe">
                                                <div className="header"></div>
                                                <p className="sub-title-medium">Free Trial</p>
                                                <p className="maintenance-title">Free </p>
                                                <div className="Book">
                                                    <Button variant="primary">Buy Now</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={3} sm={3} xs={3}>
                                        <div className="white-bg-subscribe">
                                            <div className="header"></div>
                                            <p className="sub-title-medium">
                                                Semi-Annual <br></br>Plan
                                            </p>
                                            <p className="maintenance-title">US$69 </p>
                                            <div className="Book">
                                                <Button variant="primary">Buy Now</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={3} sm={3} xs={3}>
                                        <div className="white-bg-subscribe">
                                            <div className="header"></div>
                                            <p className="sub-title-medium"> Annual Plan </p>
                                            <p className="maintenance-title">US$69 </p>
                                            <div className="Book">
                                                <Button variant="primary">Buy Now</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={3} sm={3} xs={3}>
                                        <div className="white-bg-subscribe">
                                            <div className="header"></div>
                                            <p className="sub-title-medium"> Perpetual plan</p>
                                            <p className="maintenance-title">US$69 </p>
                                            <div className="Book">
                                                <Button variant="primary">Buy Now</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="Main-accordion">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className="mt-2">
                                    <Accordion.Header>Import</Accordion.Header>
                                    <Accordion.Body>
                                        <Table responsive="sm">
                                            <tbody>
                                                <p className="maintenance-text">Import Visio</p>
                                                <tr>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                </tr>
                                                <p className="maintenance-text">Edit Visio Online</p>
                                                <tr>
                                                    <td align="center">First 3 visio files</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                </tr>
                                                <p className="maintenance-text">Batch Import & Convert Visio</p>
                                                <tr>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />
                                                    </td>
                                                </tr>
                                                <p className="maintenance-text">Import SVG</p>
                                                <tr>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                </tr>
                                                <p className="maintenance-text">Import CAD</p>
                                                <tr>
                                                    <td align="center">5 free trials</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className="mt-2">
                                    <Accordion.Header>Templates</Accordion.Header>
                                    <Accordion.Body>
                                        <Table responsive="sm">
                                            <thead></thead>
                                            <tbody>
                                                <p className="maintenance-text">Built-in Templates</p>
                                                <tr>
                                                    <td align="center">First 2 templates</td>
                                                    <td align="center">Unlimited </td>
                                                    <td align="center">Unlimited </td>
                                                    <td align="center">Unlimited </td>
                                                </tr>
                                                <p className="maintenance-text">Local Personal Templates</p>
                                                <tr>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                </tr>
                                                <p className="maintenance-text">Cloud Personal Templates </p>
                                                <tr>
                                                    <td align="center">2</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                </tr>
                                                <p className="maintenance-text">Upload to Cloud Personal Templates</p>
                                                <tr>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                    <td align="center">-</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className="mt-2">
                                    <Accordion.Header>Edraw Creative Assets </Accordion.Header>
                                    <Accordion.Body>
                                        <Table responsive="sm">
                                            <thead></thead>
                                            <tbody>
                                                <p className="maintenance-text">EdrawMax Community Templates</p>
                                                <tr>
                                                    <td align="center">10K</td>
                                                    <td align="center">
                                                        15K <br></br>(5k Enhanced Templates)
                                                    </td>
                                                    <td align="center">
                                                        15K <br></br>(5k Enhanced Templates)
                                                    </td>
                                                    <td align="center">
                                                        15K <br></br>(5k Enhanced Templates)
                                                    </td>
                                                </tr>
                                                <p className="maintenance-text">EdrawMind Community Templates</p>
                                                <tr>
                                                    <td align="center">5K</td>
                                                    <td align="center">
                                                        5K <br></br>(1k Enhanced Templates)
                                                    </td>
                                                    <td align="center">
                                                        5K <br></br>(1k Enhanced Templates)
                                                    </td>
                                                    <td align="center">
                                                        5K <br></br>(1k Enhanced Templates)
                                                    </td>
                                                </tr>
                                                <p className="maintenance-text">EdrawMax Al Features</p>
                                                <tr>
                                                    <td align="center">500 Tokens </td>
                                                    <td align="center">Unlimited Tokens</td>
                                                    <td align="center">Unlimited Tokens </td>
                                                    <td align="center">Unlimited Tokens </td>
                                                </tr>
                                                <p className="maintenance-text">EdrawMind Al Features</p>
                                                <tr>
                                                    <td align="center">500 Tokens </td>
                                                    <td align="center">Unlimited Tokens</td>
                                                    <td align="center">Unlimited Tokens </td>
                                                    <td align="center">Unlimited Tokens </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" className="mt-2">
                                    <Accordion.Header>Basic Functions </Accordion.Header>
                                    <Accordion.Body>
                                        <Table responsive="sm">
                                            <thead></thead>
                                            <tbody>
                                                <p className="maintenance-text">Set/Unset Gridlines</p>
                                                <tr>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                </tr>
                                                <p className="maintenance-text">Number of Shapes</p>
                                                <tr>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                    <td align="center">Unlimited</td>
                                                </tr>
                                                <p className="maintenance-text">Type of shapes</p>
                                                <tr>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                </tr>
                                                <p className="maintenance-text">Multiple Pages</p>
                                                <tr>
                                                    <td align="center">Max. 2 pages </td>
                                                    <td align="center">Unlimited </td>
                                                    <td align="center">Unlimited </td>
                                                    <td align="center">Unlimited </td>
                                                </tr>
                                                <p className="maintenance-text">Background Color</p>
                                                <tr>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                </tr>
                                                <p className="maintenance-text">Themes</p>
                                                <tr>
                                                    <td align="center">3 </td>
                                                    <td align="center">20+</td>
                                                    <td align="center">20+</td>
                                                    <td align="center">20+ </td>
                                                </tr>
                                                <p className="maintenance-text">Themes Colors</p>
                                                <tr>
                                                    <td align="center">3 </td>
                                                    <td align="center">10+</td>
                                                    <td align="center">10+ </td>
                                                    <td align="center">10+</td>
                                                </tr>
                                                <p className="maintenance-text">Batch Replace Color</p>
                                                <tr>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                    <td align="center">
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />{" "}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default CompareAll;
