import React from 'react'
import Tasktable from './Tasktable/Tasktable'
import Taskstock from './Taskstock/Taskstock'

const Orders = () => {
  return (
    <>
      {/* <Taskstock /> */}
      <Tasktable />

    </>
  )
}

export default Orders