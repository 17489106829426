import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Container, Row, Form } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Table from 'react-bootstrap/Table';
import './InquireNow.css'
import HelpSlider from '../HelpCenter/HelpSlider/HelpSlider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InquiryModal from '../common-components/AllModal/inquiry-Modal/InquiryModal';
import BreadcrumComponent from '../../CommonForAll/CommonComponents/ButtonComponent/BreadcrumComponent/BreadcrumComponent';
const InquireNow = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalInquiry, setModalInquiry] = React.useState(false);
    const handleChange = (date) => {
        setSelectedDate(date);
    };
    const uplodimg = [

        "/assest/images/B2Bbuyer/HelpCenter/add.png",
        "/assest/images/B2Bbuyer/BestSeller/productImg2.png"
    ];
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/b2bbuyer/buyer-home" },
        { text: "Furniture", link: "#" },
        { text: "Armchair", link: "/b2bbuyer/b2b-bestSeller/1/Applincess" },
        { text: "Modern Microfiber Accent Lounge Chair", link: "/b2bbuyer/b2b-productMain/10" },
        { text: "Inquiry", link: "#" },
    ]);
    return (
        <>
            <section className='inquire-now mt-5'>
                <Container>
                    <Row>
                        <div>
                            <BreadcrumComponent items={breadcrumbItems} />
                        </div>
                        <Col xxl={8} xl={8}>
                            <div className='title-inquire'>
                                <h2>Send Inquiry For Customization</h2>
                            </div>
                            <div className='enquire-first-section mb-5'>
                                <div className='purple-bg'>
                                    <p className='user-name'>To Disha Marathe <span className='company-name'>( Profcyma solution pvt. ltd. )</span></p>
                                    <div className='white-bg-inquire'>
                                        <Row>
                                            <Col xxl={1}>
                                                <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/RFQ/Armchairp.png"} className="pro-img" alt="..." />
                                            </Col>
                                            <Col xxl={7}>
                                                <p className='pro-dec'>Armchair New Arrival Promotion Fabric Material Adjustable Leisure Single Power Electric Relax</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className='brown-border mt-3'></div>
                                    <div>
                                        <Form>
                                            <div className="mb-3 d-flex">
                                                <Form.Check
                                                    type='radio'
                                                    id={`default-satisfactory-quotations`}
                                                    label={`Single Delivery`}
                                                    className='radio-btn-class me-4'
                                                />
                                                <Form.Check
                                                    type='radio'
                                                    id={`default-unsuitable-quotations`}
                                                    label={`Multi Delivery`}
                                                    className='radio-btn-class'
                                                />
                                            </div>
                                        </Form>
                                    </div>
                                    <div className='white-bg-inquire'>
                                        <Row>
                                            <Col lg={2} md={5} sm={6} >
                                                <h2 className='label-date'>Date of delivery</h2>
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={handleChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    isClearable
                                                />
                                            </Col>

                                            <Col lg={2} md={5} sm={6} >
                                                <Form.Group controlId="numberInput">
                                                    <h2 className='label-date'>Quantity</h2>
                                                    {/* <Form.Label className='label-date'>Quantity</Form.Label> */}
                                                    <Form.Control type="number" />
                                                </Form.Group>
                                            </Col>
                                            {/* <Col xxl={2}>
                                            <h2 className='label-date'>Unit</h2>
                                            <DropdownButton id="dropdown-basic-button" title="Unit">
                                                <Dropdown.Item href="#/action-1">Bag/Bags</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Box/Boxes</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Carat/Carats</Dropdown.Item>
                                                <Dropdown.Item href="#/action-4">Litre/Litres</Dropdown.Item>
                                            </DropdownButton>
                                        </Col> */}
                                        </Row>
                                    </div>
                                    <div className='mt-4'>
                                        <p className='detail-txt'>Customization offer by seller for this product</p>
                                        <div className='d-flex justify-content-between'>
                                            <p className='table-heading'>Product Customization</p>
                                            <span className='doc-required'>(<span className='star-class'>*</span> - Document Required)</span>
                                        </div>
                                        <div>
                                            <Table bordered responsive>
                                                <thead>
                                                    <tr>
                                                        <th className='custome'>Customization</th>
                                                        <th className='custome' >Add yours</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Weight <br /><span className='td-subtitle'>(increase weight)</span></td>
                                                        <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Coating <br /> <span className='td-subtitle'>(Wooden coating, polish)</span></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Size <br /><span className='td-subtitle'>(As per recommended or as per discussion)</span></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Color<br /><span className='td-subtitle'>(As per discussion)</span></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Material Type<span className='star-class'>*</span> <br /><span className='td-subtitle'>((Sagwan....etc))</span></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Design<span className='star-class'>*</span><br /> <span className='td-subtitle'>(Standard design or as per requirement)</span></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Other<span className='star-class'>*</span><br /> <span className='td-subtitle'>(add other field that you want to add)</span></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div>
                                            <p className='table-heading'>Packaging</p>
                                            <Table bordered >
                                                <thead>
                                                    <tr>
                                                        <th className='custome' >Customization</th>
                                                        <th className='custome'>Add yours</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Logo <br /><span className='td-subtitle'>(Your brand logo)</span></td>
                                                        <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Packaging Material <br /> <span className='td-subtitle'>(As per convenience)</span></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Color & Style <br /><span className='td-subtitle'>(As per convenience)</span></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Color<br /><span className='td-subtitle'>(As per discussion)</span></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Licensing ad Descriptions<span className='star-class'>*</span> <br /><span className='td-subtitle'>(All licence & Description you will provide)</span></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Other<span className='star-class'>*</span><br /> <span className='td-subtitle'>(add other field that you want to add)</span></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Other<span className='star-class'>*</span><br /> <span className='td-subtitle'>(add other field that you want to add)</span></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <div>
                                                <h5 className='detail-txt'>Detailed Requirement for customization<span className='star-class'>*</span> <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" className='ms-1 circle-exclamation' /></h5>
                                                <p className='sub-details'>Enter product details such as color, size, materials etc. and other specification requirements to receive an accurate quote.</p>
                                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control as="textarea" rows={4} placeholder='Enter your message here' />
                                                </Form.Group>
                                                <div>
                                                    <div className='detail-txt mt-4'> <FontAwesomeIcon icon="fa-solid fa-paperclip" className='me-1' />Add Attachment</div>
                                                    <div className='add-attachment'>( Attachment for customization of your brand logo, label...etc)</div>
                                                </div>
                                            </div>
                                            <div>
                                                <button className='send-inquiry' onClick={() => { setModalInquiry(true) }}>Send Inquiry for Customization</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={4} xl={4}>
                            {/* <HelpSlider /> */}
                            <div>
                                <HelpSlider imagePaths={uplodimg} imageClassName="add-img" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <InquiryModal
                message="Your inquiry for customization send successfully"
                show={modalInquiry}
                onHide={() => setModalInquiry(false)}
            />
        </>
    )
}

export default InquireNow;
