import React, { useContext, useEffect, useState } from "react";
import './affi-events.css'
import { getEvents } from "../../../../../utils/apis/events/event";
import { Context } from "../../../../../utils/context";

const EventSeller = () => {

    const { IMG_URL } = useContext(Context);
    const [events, setEvents] = useState();
    const getAllEvents = async () => {
        const res = await getEvents("b2b_seller");
        if (res?.success) {
            setEvents(res?.data);
        }
    };
    useEffect(() => {
        getAllEvents();
    }, []);

    const formatEventDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        }).replace(':00', '');
        return `${formattedDate} at ${formattedTime}`;
    };
    return (
        <>
            <section className='afffievents-sec'>
                <div className='row'>
                    {events?.map((event) =>
                        <div className='col-lg-3 col-md-4 col-sm-6 mb-4'>
                            <div className='eventcard'>
                                <div className='img-div'>
                                    <img className='cardimg' src={IMG_URL + event?.image} />
                                </div>
                                <div className='cardbody'>
                                    <p className='datep'> {formatEventDate(event?.start)} to  {formatEventDate(event?.end)}</p>
                                    <h6 className='cardtitle'>{event?.title}</h6>
                                    <p className='like'> <span>Type : {event?.event_type}</span>
                                        <span className='ps-2 pe-2'>| {event?.event_type === "Online" ? event?.event_link : event?.event_address}</span><span></span></p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}

export default EventSeller