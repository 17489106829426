import React, { useEffect, useState, useContext } from "react";
import "./WebsiteDesigner.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { text } from "@fortawesome/fontawesome-svg-core";
import { DesignerGet } from "../../../../utils/apis/myWebsite/mywebsite";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const WebsiteDesigner = () => {
    const WebsiteDetails = [
        {
            heading: "Uncertain about what's necessary to begin your online journey?",
            text: "My business websites are easy to get started with and cover everything you will need to get online including website, domain names and email accounts.",
        },
        {
            heading: "Worried about the expenses involved?",
            text: "Budgeting is a breeze with my websites. I offer a straightforward monthly fee and a free design preview, with absolutely no setup or design charges.",
        },
        {
            heading: "Anxious about maintaining the freshness and relevance of your website?",
            text: "Edit easily in your own time with Site builder my drag-and-dropwebsite editor. ​No need to pay for simple updates to your own information.",
        },
    ];



    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const [designerData, setDesignerData] = useState();
    const getDesignerData = async () => {

        const res = await DesignerGet();
        if (res?.success) {
            setDesignerData(res?.data);
        }
    }

    useEffect(() => {
        getDesignerData();
    }, [])


    return (
        <>
            <Container>
                <section className="Website-Designer">
                    <div className="main-image">
                        <img
                            src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Website/MAN.png"}
                            className="person"
                        />
                    </div>
                    <h4 className="maintenance-title">Experienced Website Designer</h4>
                    <p className="sub-title-medium">Leave Setup to Me!</p>
                    <div className="main-card">

                        <div className="maintenance-slider">
                            <Swiper
                                spaceBetween={14}
                                slidesPerView={4}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                                breakpoints={{
                                    280: {
                                        slidesPerView: 2,
                                    },
                                    320: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    992: {
                                        slidesPerView: 4,
                                    },
                                }}
                            >
                                {designerData?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="website-card">
                                            <p className="sub-title">{item?.name}</p>
                                            <p className="maintenance-text">{htmlToReactParser.parse(item?.description)}</p>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>

                        {/* <Row className="justify-content-center">
                            {designerData?.map((item, index) => (
                                <Col key={index} xxl={4} xl={4} md={6}>
                                    <div className="website-card">
                                        <p className="sub-title">{item?.name}</p>
                                        <p className="maintenance-text">{htmlToReactParser.parse(item?.description)}</p> 
                                    </div>
                                </Col>
                            ))}
                        </Row> */}
                    </div>
                </section>
            </Container>
        </>
    );
};

export default WebsiteDesigner;
