import React from 'react'
import Lottie from 'react-lottie';
import "../SuccessAnimation/Animation.css"
import * as animationData from '../SuccessAnimation/Success.json';
function Animation() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <section className='main'>
                <div className='vdo-btnann' >
                    <Lottie className="success-lottie me-auto"
                        options={defaultOptions}
                        
                    />
                </div>
            </section>
        </>
    )
}

export default Animation