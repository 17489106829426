import React, { useState } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import MyInquiry from './MyInquiry/MyInquiry'
import MyRfq from './MyRfq/MyRfq'
import './MyRfq/MyRfq.css'
import WhyPursuing from './WhyPursuing/WhyPursuing'
import BreadcrumComponent from '../../CommonForAll/CommonComponents/ButtonComponent/BreadcrumComponent/BreadcrumComponent'


const Rfq = () => {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/b2bbuyer/buyer-home" },
    { text: "My Inquiries", link: "#" },
  ]);
  return (
    <section className='rfq-main'>
      <Container>
        <BreadcrumComponent items={breadcrumbItems} />
        <Row>
          <Col xxl={9} xl={9} md={8} sm={12} xs={12}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="flex-row">
                <Nav.Item>
                  <Nav.Link eventKey="first">My Inquiries</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="second">My RFQs</Nav.Link>
                </Nav.Item> */}
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <MyInquiry />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <MyRfq />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
          <Col xxl={3} xl={3} md={4} sm={12} xs={12}>
            <WhyPursuing />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Rfq