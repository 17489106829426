import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllThemeCategory = async (
  currentPage,
  perPage,
  search,
  searchStatus
) => {
  try {
    let url = `/seller/theme/theme-category?page=${currentPage}&per_page=${perPage}&term=${search}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const changeThemeCategoryStatus = async (id) => {
  try {
    return await editStatusData(`/seller/theme/theme-category/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteThemeCategory = async (recordToDeleteId) => {
  try {
    return await deleteData(`/seller/theme/theme-category/${recordToDeleteId}`);
  } catch (error) {
    console.log(error);
  }
};

export const addThemeCategory = async (finalData) => {
  try {
    return await postData(`/seller/theme/theme-category`, finalData);
  } catch (error) {
    console.log(error);
  }
};

export const editThemeCategory = async (id, finalData) => {
  try {
    return await postData(`/seller/theme/theme-category/${id}`, finalData);
  } catch (error) {
    console.log(error);
  }
};

export const getsingleThemeCategory = async (id) => {
  try {
    return await getData(`/seller/theme/theme-category/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
