import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { Link } from "react-router-dom";
library.add(fas);

function Stepfive(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isYesActive, setIsYesActive] = useState(true);
  const [startDate, setStartDate] = useState();
  const [modalShow, setModalShow] = useState(false);

  const handleSelectChange = (selectedOption) => {
    setSelectedOptions([...selectedOptions, selectedOption]);
  };

  const handleCloseButtonClick = (optionValue) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.value !== optionValue
    );
    setSelectedOptions(updatedOptions);
  };

  const options = [
    { value: " Pune", label: " Pune" },
    { value: "Mumbai (All areas)", label: "Mumbai (All areas)" },
    { value: "Bangalore", label: "Bangalore" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <div className="personal-details-form mb-0 ">
        {/* form-holder */}
        <div className="details-form-holder">
          <div className="form-container">
            {/* Current Industry , Job Title*/}
            <div className="row">
              <div className="col-md-6">
                <Form className="mb-3">
                  <Form.Group controlId="">
                    <Form.Label>Bank account number</Form.Label>
                    <div className="verified">
                      <Form.Control
                        type="email"
                        placeholder="Enter your bank account number"
                      />
                      <span className="not-verified-text">
                        <FontAwesomeIcon
                          className="me-2"
                          icon="fa-solid fa-circle-xmark"
                        />
                        not verified
                      </span>
                    </div>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-6">
                <Form className="mb-3">
                  <Form.Group controlId="">
                    <Form.Label>IFSC code</Form.Label>

                    <div className="verified">
                      <Form.Control
                        type="email"
                        placeholder="Enter your IFSC code"
                      />
                      <span className="verified-text">
                        <FontAwesomeIcon
                          className="me-2"
                          icon="fa-solid fa-circle-check"
                        />
                        verified
                      </span>
                    </div>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-6">
                <Form className="mb-3">
                  <Form.Group controlId="">
                    <Form.Label>Bank name</Form.Label>
                    <div className="verified">
                      <Form.Control
                        type="email"
                        placeholder="Enter your bank name"
                      />
                      <span className="verified-text">
                        <FontAwesomeIcon
                          className="me-2"
                          icon="fa-solid fa-circle-check"
                        />
                        verified
                      </span>
                    </div>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-6">
                <Form className="mb-3">
                  <Form.Group controlId="">
                    <Form.Label>Branch name</Form.Label>

                    <div className="verified">
                      <Form.Control
                        type="email"
                        placeholder="Enter your branch name"
                      />
                      <span className="verified-text">
                        <FontAwesomeIcon
                          className="me-2"
                          icon="fa-solid fa-circle-check"
                        />
                        verified
                      </span>
                    </div>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-6">
                <Form className="mb-3">
                  <Form.Group controlId="">
                    <Form.Label>MICR code</Form.Label>

                    <div className="verified">
                      <Form.Control
                        type="email"
                        placeholder="Enter your MICR code"
                      />
                      <span className="verified-text">
                        <FontAwesomeIcon
                          className="me-2"
                          icon="fa-solid fa-circle-check"
                        />
                        verified
                      </span>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="text-end apply_now_btn">
              <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p>
              <Link to={"/seller/seller-dashboard/"}>
                {/* <Button className="back-btn me-3">Skip</Button> */}
              </Link>
              <Button onClick={props.prevStep} className="back-btn">
                Back
              </Button>

              <Button onClick={props.nextStep} className=" tabs-btn">
                Register & Continue
              </Button>
            </div>
          </div>

          <TermsAndConditionModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
      </div>
    </>
  );
}

export default Stepfive;
