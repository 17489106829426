import React, { useContext, useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";

import { mediaAttachmentPost, getMediaAttachment } from "../../../../utils/apis/media/media";
import { Context } from "../../../../utils/context";

const Stepsix = ({
  nextInnerPage,
  nextStep,
  prevStep,
  prevInnerPage,
  handleBack,
  mediaDetails,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const handleNext = () => {
    nextInnerPage();
  };

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: true };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  console.log(errors);

  const onSubmit = async (data) => {

    const formData = new FormData();

    if (attachmentId != "") {
      formData.append("id", attachmentId);
    }
    formData.append("media_personal_details_id", mediaDetails.media_personal_details_id);
    formData.append("logo", data.logo[0]);
    formData.append("banner", data.banner[0]);

    const res = await mediaAttachmentPost(formData);
    if (res?.success) {
      console.log(res, 'success');
      // handleNext();
      nextStep();

    }
  };


  const { IMG_URL } = useContext(Context);

  const [attachmentId, setAttachmentId] = useState("");

  const getMediaAttechments = async () => {
    const res = await getMediaAttachment(mediaDetails.media_personal_details_id);
    if (res?.success) {
      console.log(res);
      setAttachmentId(res.data.id);
      setStoreLogo(IMG_URL + res.data?.logo);
      setStoreBanner(IMG_URL + res.data?.banner);
      reset(res.data);

    }
  }

  const [selectedBannerImages, setSelectedBannerImage] = useState(null);
  const [storeBanner, setStoreBanner] = useState(null);

  const handleFileBannerChange = (e) => {
    const file = e.target.files[0];
    console.log("Selected banner image:", file);
    setSelectedBannerImage(file);
    setStoreBanner(null);
    clearErrors('banner');
  };

  const handleRemoveBannerFile = () => {
    setSelectedBannerImage(null);
    const fileInput = document.getElementById('filebannerInput');
    fileInput.value = '';
  };

  const [selectedLogoImages, setSelectedLogoImage] = useState(null);
  const [storeLogo, setStoreLogo] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedLogoImage(file);
    setStoreLogo(null);
    clearErrors('logo');
  };

  const handleRemoveFile = () => {
    setSelectedLogoImage(null);
    const fileInput = document.getElementById('fileLogoInput');
    fileInput.value = '';
  };

  const [selectedAgreementImages, setSelectedAgreementLogoImage] = useState(null);
  const [storeAgreement, setStoreAgreement] = useState(null);


  const handleFileagreementChange = (e) => {
    const file = e.target.files[0];
    setSelectedAgreementLogoImage(file);
    setStoreAgreement(null);
    clearErrors('logo');
  };

  const handleagreementRemoveFile = () => {
    setSelectedAgreementLogoImage(null);
    const fileInput = document.getElementById('fileLogoInput');
    fileInput.value = '';
  };

  useEffect(() => {
    getMediaAttechments();
  }, []);

  return (
    <>
      <div className="firmDetailsFirst">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h6 className="ms-3 firmmmdd">Attachments</h6>
          <div className="tab-form-container firstthreerrr">
            <div className="row">

              <div className="col-lg-3">
                <div className="uploadGsthead">
                  <h3>Upload Logo</h3>
                </div>

                <div className="image-preview">
                  <div className="uploadBtnsec">
                    {storeLogo !== null ? (
                      <input
                        type="file"
                        id="fileLogoInput"
                        accept="image/*"
                        {...register("logo")}
                        onChange={handleFileChange}
                      />
                    ) : (
                      <input
                        type="file"
                        id="fileLogoInput"
                        accept="image/*"
                        {...register("logo", {
                          required: "Logo image is required",
                        })}
                        onChange={handleFileChange}
                      />
                    )}

                  </div>
                  {selectedLogoImages && (
                    <div className="previewImgmain">
                      <img
                        src={URL.createObjectURL(selectedLogoImages)}
                        alt={`Preview of ${selectedLogoImages.name}`}
                        className="previewImg"
                      />
                      <div>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-xmark"
                          className="circlecrossMark"
                          onClick={handleRemoveFile}
                        />
                      </div>
                    </div>
                  )}
                  {storeLogo !== null && (
                    <div className="previewImgmain">
                      <img
                        src={storeLogo}
                        className="previewImg"
                      />
                    </div>
                  )}

                </div>
                {errors.logo && (
                  <span className="text-danger">{errors.logo.message}</span>
                )}

              </div>

              <div className="col-lg-3">
                <div className="uploadGsthead">
                  <h3>Upload Banner</h3>
                </div>

                <div className="image-preview">
                  <div className="uploadBtnsec">

                    {storeBanner !== null || selectedBannerImages !== null ? (
                      <input
                        type="file"
                        id="filebannerInput"
                        accept="image/*"
                        {...register("banner")}
                        onChange={handleFileBannerChange} />
                    ) : (
                      <input type="file" id="filebannerInput" accept="image/*" {...register("banner", {
                        required: "Banner image is required",
                      })} onChange={handleFileBannerChange} />
                    )}

                  </div>
                  {selectedBannerImages && (
                    <div className="previewImgmain">
                      <img
                        src={URL.createObjectURL(selectedBannerImages)}
                        alt={`Preview of ${selectedBannerImages.name}`}
                        className="previewImg"
                      />
                      <div>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-xmark"
                          className="circlecrossMark"
                          onClick={handleRemoveBannerFile}
                        />
                      </div>
                    </div>
                  )}
                  {storeBanner !== null && (
                    <div className="previewImgmain">
                      <img
                        src={storeBanner}
                        className="previewImg"
                      />
                    </div>
                  )}

                </div>
                {errors.banner && (
                  <span className="text-danger">{errors.banner.message}</span>
                )}
              </div>

              <div className="col-lg-3">
                <div className="uploadGsthead">
                  <h3>Upload Agreement Copy</h3>
                </div>

                <div className="image-preview">
                  <div className="uploadBtnsec">

                    {storeAgreement !== null || selectedAgreementImages !== null ? (
                      <input
                        type="file"
                        id="filebannerInput"
                        accept="image/*"
                        {...register("banner")}
                        onChange={handleFileagreementChange} />
                    ) : (
                      <input type="file" id="filebannerInput" accept="image/*" {...register("banner", {
                        required: "Banner image is required",
                      })} onChange={handleFileagreementChange} />
                    )}

                  </div>
                  {selectedAgreementImages && (
                    <div className="previewImgmain">
                      <img
                        src={URL.createObjectURL(selectedAgreementImages)}
                        alt={`Preview of ${selectedAgreementImages.name}`}
                        className="previewImg"
                      />
                      <div>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-xmark"
                          className="circlecrossMark"
                          onClick={handleagreementRemoveFile}
                        />
                      </div>
                    </div>
                  )}
                  {storeAgreement !== null && (
                    <div className="previewImgmain">
                      <img
                        src={storeAgreement}
                        className="previewImg"
                      />
                    </div>
                  )}

                </div>
                {errors.banner && (
                  <span className="text-danger">{errors.banner.message}</span>
                )}
              </div>

            </div>
          </div>

          <div className="col-md-12">
            <div className="text-end btnMainn apply_now_btn">
              {/* <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p> */}
              <div className="registerContinueMain">
                <Button onClick={prevStep} className="back-btn">
                  Back
                </Button>
              </div>
              <div className="registerContinueMain">
                <Button
                  type="submit"
                  // onClick={nextStep}
                  className="tabs-btn"
                >
                  Register & Continue
                </Button>
              </div>

              <TermsAndConditionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Stepsix;
