import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { ServiceesType } from "../../../../utils/apis/master/Master";
import "./Stepfive.css";

import classNames from "classnames";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import { Context } from "../../../../utils/context";
import { RegxExpression } from "../../../../../src/utils/common";
import Select from "react-select";
import {
  mediaBranchDetailPost,
  getMediaBranchDetail,
} from "../../../../utils/apis/media/media";
import {
  mediaBranchAddDetailPost,
  getMediaBranchAddDetail,
} from "../../../../utils/apis/media/media";
import {
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
  Area,
} from "../../../../utils/apis/master/Master";
import { Errors, Placeholders } from "../../../../utils/errors";
import Cookies from "js-cookie";
import { mediaPersonalDetailsPost } from "../../../../utils/apis/media/media";

function Stepfive({
  nextStep,
  prevStep,
  mediaDetails,
  bankID,
  mediaID,
  ...props
}) {
  const [modalShow, setModalShow] = useState(false);
  const [addressCount, setAddressCount] = useState(1);
  const [areaOptions, setAreaOptions] = useState([]);

  const handleAddAddress = (props, mediaID) => {
    setAddressCount((prevCount) => prevCount + 1);
  };

  const handleRemoveAddress = () => {
    setAddressCount((prevCount) => prevCount - 1);
  };

  const handleTabClick = (eventKey) => {
    setActiveTab(eventKey);
  };

  const [activeTab, setActiveTab] = useState("office_address");

  const navItems = [
    { eventKey: "office_address", label: "Office address" },
    { eventKey: "other", label: "Other" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      data: [
        {
          b_name: "",
          b_contact: "",
          b_contact_person_name: "",
          add_line_one: "",
          add_line_two: "",
          city_id: "",
          state_id: "",
          pincode_id: "",
          country_id: "",
          area_id: "",
        },
      ],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "data",
  });

  const onSubmit = async (d) => {
    console.log(d, "form");
    // d.media_bank_details_id = bankID
    d.media_personal_details_id = mediaID;
    const res = await mediaBranchDetailPost(d);
    if (res?.success) {
      console.log(res);
      nextStep();
    }
  };

  const [service, setService] = useState([]);

  const handleServiceChange = async () => {
    const res = await ServiceesType();
    if (res?.success) {
      const data = await Select2Data(res.data, "service_area_id", false);
      setService(data);
    }
  }

  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [pincodes, setPincodes] = useState([]);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const handlePincodeChange = async (e) => {
    const res = await Pincode(e);
    if (res?.success) {
      const data = await Select2Data(res.data, "pincode_id", false);
      setPincodes(data);
    }
  };
  const handlePincodeSelect = async (id, index) => {
    const res = await pincodeWiseAll(id);
    if (res?.success) {
      console.log(res);
      const updatedCities = [...cities];
      updatedCities[index] = res.data?.city?.name;
      setCities(updatedCities);

      const updatedStates = [...states];
      updatedStates[index] = res.data?.state?.name;
      setStates(updatedStates);

      const updatedCountries = [...countries];
      updatedCountries[index] = res.data?.country?.name;
      setCountries(updatedCountries);

      setValue(`data[${index}].city_id`, res.data?.city?.id);
      setValue(`data[${index}].state_id`, res.data?.state?.id);
      setValue(`data[${index}].country_id`, res.data?.country?.id);
    }
  };

  const getfirmDropAddressIn = async () => {
    try {
      const res1 = await getMediaBranchDetail(mediaID);

      if (res1?.success) {
        const combinedData = [...res1.data];
        if (combinedData.length > 0) {
          const updatedCities = [];
          const updatedStates = [];
          const updatedCountries = [];
          const formattedData = combinedData.map((item, index) => {
            updatedCities.push(item?.city?.name);
            updatedStates.push(item?.state?.name);
            updatedCountries.push(item?.country?.name);
            // Automatically select country, state, and city based on pincode
            handlePincodeSelect(
              item?.pincode?.id,
              index
            );
            return {
              b_name: item?.b_name,
              b_contact: item?.b_contact,
              b_contact_person_name: item?.b_contact_person_name,
              add_line_one: item?.add_line_one,
              add_line_two: item?.add_line_two,
              area_id: { value: item?.s_area?.id, label: item?.s_area?.name },
              city_id: item?.city_id,
              state_id: item?.state_id,
              pincode_id: { value: item?.pincode?.id, label: item?.pincode?.name },
              country_id: item?.country_id,
            };
          });

          setCities(updatedCities);
          setStates(updatedStates);
          setCountries(updatedCountries);
          reset({ data: formattedData });
        } else {
          reset({
            data: [
              {
                b_name: "",
                b_contact: "",
                b_contact_person_name: "",
                add_line_one: "",
                add_line_two: "",
                area_id: "",
                city_id: "",
                state_id: "",
                pincode_id: "",
                country_id: "",
              },
            ],
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMasters = async () => {
    const res = await Area();
    if (res?.success) {
      const data = await Select2Data(res.data, "area_id", false);
      setAreaOptions(data);
      console.log(areaOptions);
    }
  };
  useEffect(() => {
    getfirmDropAddressIn();
    getMasters();
  }, []);

  useEffect(() => {
    getfirmDropAddressIn();
  }, []);

  return (
    <>
      <div className="form-container">
        <Form onSubmit={handleSubmit(onSubmit)} role="form">
          {fields.map((item, index) => (
            <>
              <div className="stepfourMain" key={index}>
                <div className="row me-0 ms-0">
                  <div className="col-lg-4">
                    <Form.Group className="mb-3">
                      <Form.Label>Branch Name</Form.Label>
                      <Form.Control
                        type="text"
                        className={classNames("", {
                          "is-invalid":
                            errors.data &&
                            errors.data[index] &&
                            errors.data[index].b_name,
                          "is-valid": getValues(`data[${index}].b_name`),
                        })}
                        name={`data[${index}].b_name`}
                        placeholder="Enter  Branch Name"
                        defaultValue={item.b_name}
                        {...register(`data[${index}].b_name`, {
                          required: "Branch is required",
                        })}
                        onKeyDown={(event) => {
                          if (!RegxExpression.name.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-4">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Branch Contact</Form.Label>
                      <Form.Control
                        type="text"
                        className={classNames("", {
                          "is-invalid":
                            errors.data &&
                            errors.data[index] &&
                            errors.data[index].b_contact,
                          "is-valid": getValues(`data[${index}].b_contact`),
                        })}
                        name={`data[${index}].b_contact`}
                        placeholder="Enter Branch Contact"
                        defaultValue={item.add_branch_name}
                        {...register(`data[${index}].b_contact`, {
                          required: "Branch Contact is required",
                          minLength: {
                            value: 10,
                            message: "Branch Contact must be at least 10 characters long",
                          }
                        })}
                        maxLength={10}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-4">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Branch Contact Person Name</Form.Label>
                      <Form.Control
                        type="text"
                        className={classNames("", {
                          "is-invalid":
                            errors.data &&
                            errors.data[index] &&
                            errors.data[index].b_contact_person_name,
                          "is-valid": getValues(`data[${index}].b_contact_person_name`),
                        })}
                        name={`data[${index}].b_contact_person_name`}
                        placeholder="Branch Contact Person Name"
                        defaultValue={item.add_branch_name}
                        {...register(`data[${index}].b_contact_person_name`, {
                          required: "Branch Contact Person Name is required",
                        })}
                        onKeyDown={(event) => {
                          if (!RegxExpression.name.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                <hr></hr>
                {/* form-holder */}

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group>
                      <Form.Label>Enter Address Line 1</Form.Label>
                      <Form.Control
                        type="text"
                        className={classNames("", {
                          "is-invalid":
                            errors.data &&
                            errors.data[index] &&
                            errors.data[index].add_line_one,
                          "is-valid": getValues(`data[${index}].add_line_one`),
                        })}
                        name={`data[${index}].add_line_one`}
                        placeholder="Enter Address Line 1"
                        defaultValue={item.add_line_one}
                        {...register(`data[${index}].add_line_one`, {
                          required: "Address line 1 is required",
                        })}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group>
                      <Form.Label>Address line 2</Form.Label>
                      <Form.Control
                        type="text"
                        className={classNames("", {
                          "is-invalid":
                            errors.data &&
                            errors.data[index] &&
                            errors.data[index].add_line_two,
                          "is-valid": getValues(`data[${index}].add_line_two`),
                        })}
                        name={`data[${index}].add_line_two`}
                        placeholder="Enter Address Line 2"
                        defaultValue={item.add_line_two}
                        {...register(`data[${index}].add_line_two`, {
                          required: "Address line 2 is required",
                        })}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group>
                      <Form.Label>City/District:</Form.Label>

                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors.data &&
                            errors.data[index] &&
                            errors.data[index].city,
                          "is-valid": getValues(`data[${index}].city`),
                        })}
                        type="text"
                        name="city_id"
                        readOnly
                        value={cities[index]}
                        placeholder={Placeholders.city}

                      />

                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors.data &&
                            errors.data[index] &&
                            errors.data[index].state,
                          "is-valid": getValues(`data[${index}].state`),
                        })}
                        type="text"
                        name="state_id"

                        value={states[index]}
                        placeholder={Placeholders.state}
                        readOnly

                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group>
                      <Form.Label>Pincode</Form.Label>

                      <Controller
                        name={`data[${index}].pincode_id`} // name of the field
                        {...register(`data[${index}].pincode_id`, {
                          required: "Select Pincode",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.data &&
                                  errors.data[index] &&
                                  errors.data[index].pincode_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={pincodes}
                            onInputChange={(selectedOption) => {
                              handlePincodeChange(selectedOption);
                            }}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              handlePincodeSelect(selectedOption?.value, index);
                            }}
                          />
                        )}
                      />

                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors.data &&
                            errors.data[index] &&
                            errors.data[index].country_id,
                          "is-valid": getValues(`data[${index}].country_id`),
                        })}
                        type="text"
                        name="country_id"
                        readOnly
                        value={countries[index]}
                        placeholder={Placeholders.country}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Service Area</Form.Label>
                      <Controller
                        name={`data[${index}].area_id`}
                        {...register(`data[${index}].area_id`, {
                          required: Errors.area_id,
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.data &&
                                  errors.data[index] &&
                                  errors.data[index].area_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={service}
                            onInputChange={(selectedOption) => {
                              handleServiceChange(selectedOption);
                            }}
                          />
                        )}
                      />
                    </Form.Group>
                  </div>

                  {index !== 0 && (
                    <div className="addremoveadress">
                      <div className="text-end Addnewadresstxt">
                        <p className="" onClick={() => remove(index)}>
                          Remove Address{" "}
                          <FontAwesomeIcon icon="fa-solid fa-minus" />
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ))}

          <div className="addremoveadress">
            <div className="text-end Addnewadresstxt">
              <p
                onClick={() =>
                  append({
                    b_name: "",
                    b_contact: "",
                    b_contact_person_name: "",
                    add_line_one: "",
                    add_line_two: "",
                    city_id: "",
                    state_id: "",
                    pincode_id: "",
                    country_id: "",
                    area_id: "",
                  })
                }
              >
                Add New Address <FontAwesomeIcon icon="fa-solid fa-plus" />
              </p>
            </div>
          </div>

          {/* <div className="Common-details-step m-3">
            <Form.Check
              inline
              type="checkbox"
              label="Is new address same as previous"
              name=""
              className="my-2"
            />
          </div> */}

          <div className="col-md-12">
            <div className="text-end btnMainn apply_now_btn">
              {/* <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p> */}
              <div className="registerContinueMain">
                <Button onClick={prevStep} className="back-btn">
                  Back
                </Button>
              </div>

              <div className="registerContinueMain">
                <Button
                  // type="submit"
                  // onClick={nextStep}
                  onClick={handleSubmit(onSubmit)}
                  className="tabs-btn"
                >
                  Register & Continue
                </Button>
              </div>
            </div>

            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </Form >
        {/* <div className="addremoveadress">
            <div
              className="text-end Addnewadresstxt"
              onClick={handleRemoveAddress}
            >
              <p className="">
                Remove Address <FontAwesomeIcon icon="fa-solid fa-minus" />
              </p>
            </div>
            <div className="text-end Addnewadresstxt" onClick={handleAddAddress}>
              <p className="">
                Add New Address <FontAwesomeIcon icon="fa-solid fa-plus" />
              </p>
            </div>
          </div> */}
      </div >
    </>
  );
}

export default Stepfive;
