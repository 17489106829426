import React from 'react'
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import "./CategoryDetails.css"
const CategoryDetails = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='CategoryDetails'
            >
                <Modal.Header closeButton>
                    
                </Modal.Header>

                <Modal.Body>
                    <div className='categoryMaintable table-repsonsive'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Product ID</th>
                                    <th>Product Name</th>
                                    <th>Variant ID</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Food</td>
                                    <td>Bevzilla 10 Instant Coffee Cubes Pack with Organic Date Palm Jaggery</td>
                                    <td>#345</td>
                                    <td>123456</td>
                                    <td>123456</td>
                                </tr>
                                <tr>
                                    <td>Food</td>
                                    <td>Bevzilla 10 Instant Coffee Cubes Pack with Organic Date Palm Jaggery</td>
                                    <td>#345</td>
                                    <td>123456</td>
                                    <td>123456</td>
                                </tr>
                                <tr>
                                    <td>Food</td>
                                    <td>Bevzilla 10 Instant Coffee Cubes Pack with Organic Date Palm Jaggery</td>
                                    <td>#345</td>
                                    <td>123456</td>
                                    <td>123456</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default CategoryDetails