import React, { useContext, useEffect, useState } from "react";
import "./mrp-modal.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { RegxExpression } from "../../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import { Errors, Placeholders } from "../../../utils/errors";
import DomainTutorial from "../domain-tutorial-modal/Domain-Tutorial";
import { domainExist } from "../../../utils/apis/myWebsite/mywebsite";

const Form_modal = (props) => {

  const  {selectedPlan} = props;
  const {
    control,
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const { IMG_URL } = useContext(Context);

const [optionError , setOptionError] = useState(false);
  const onSubmit = async (d) => {

    


    if(d.new_domain !== ""){
      let res = await domainExist(d.new_domain);
      if(res?.success){
        props.handleRazorpay(selectedPlan?.selling_price,selectedPlan?.id,d.new_domain,"",d.domain_name);
      }else{
          setError('new_domain',{message:res?.message});
      }

      
    }else if(d.domain && d.dns){

      let res = await domainExist(d.domain);
      if(res?.success){
        props.handleRazorpay(selectedPlan?.selling_price,selectedPlan?.id,d.domain,d.dns,d.domain_name);
      }else{
          setError('domain',{message:res?.message});
      }
      
    }else{
      setOptionError(true);
    }




  }

  console.log(errors,'http://localhost:3004/');
const [tutoModal, setTutoModal] = useState(false);
  const handleDomainTuto = async () => {
    setTutoModal(true);
  }
  useEffect(() => {

  }, []);
  return (
    <>
      <Modal className='mrp-modal' size="lg" {...props} centered >
        <Modal.Header closeButton onClick={props.onHide}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          

          <Form.Group className="mb-3" controlId="">
            <Form.Label>Domain Name</Form.Label>
            <Form.Control
              type="text"
              name="domain_name"
              placeholder="Enter Domain Name"
              {...register("domain_name", {
                required: "Domain Name required",
              })}
              className={classNames("", {
                "is-invalid": errors?.domain,
                "is-valid": getValues("domain_name"),
              })}
            />

          </Form.Group>

          
          <div className='row text-center'>
            <div className='col-12'>
                <h4>Please mention details of your domain</h4>
                {optionError && (<sub className="text-danger">Mention your domain details</sub>)}
            </div>
          </div>

          <Form.Group className="mb-3" controlId="">
            <Form.Label>Domain</Form.Label>
            <Form.Control
              type="text"
              name="domain"
              placeholder="Enter Domain"
              {...register("domain", {
                // required: "Domain required",
               
              })}
              className={classNames("", {
                "is-invalid": errors?.domain,
                "is-valid": getValues("domain"),
              })}
              onKeyDown={(event) => {
                setValue('new_domain',"");
                setOptionError(false);
              }}
            />
            <span className="text-danger">{errors?.domain?.message}</span>

          </Form.Group>
          <Form.Group className="mb-3" controlId="">
            <Form.Label>DNS</Form.Label>
            <Form.Control
              type="text"
              name="dns"
              placeholder="Enter DNS"
              {...register("dns", {
                required: getValues('new_domain') !== "" ? false : true,
              })}
              className={classNames("", {
                "is-invalid": errors?.dns,
                "is-valid": getValues("dns"),
              })}
              onKeyDown={(event) => {
                
                setOptionError(false);
              }}
            />

          </Form.Group>

          <b className="text text-info" style={{cursor:'pointer'}} onClick={handleDomainTuto}>Click for tutorial for domain </b>

          <hr class="hr-text mt-5 mb-5" data-content="OR"></hr>


          <div className='row text-center'>
            <div className='col-12'>
                <h4>Make new domain of your choice</h4>
            </div>
          </div>

          <Form.Group className="mb-3" controlId="">
            <Form.Label>Domain</Form.Label>
            <p> <sup> (E.g :- netpurti.com)</sup></p>
            <Form.Control
              type="text"
              name="new_domain"
              placeholder="Enter Domain (E.g :- netpurti.com)"
              {...register("new_domain", {
                // required: "Domain required",
              })}
              className={classNames("", {
                "is-invalid": errors?.new_domain,
                "is-valid": getValues("new_domain"),
              })}
              onKeyDown={(event) => {
               
                setValue('domain',"");
                setValue('dns',"");
                setOptionError(false);
              }}
            />

            <span className="text-danger">{errors?.new_domain?.message}</span>

          </Form.Group>

          <button className="update-btn" type="button" onClick={handleSubmit(onSubmit)}>Purchase</button>
          </Form>
          <DomainTutorial show={tutoModal} onHide={() => setTutoModal(false)} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Form_modal;
