import React, { useEffect } from 'react'
import PlanPricing from './PlanPricing/PlanPricing'
import EffortlessStartup from './EffortlessStartup/EffortlessStartup'
import BuilderAdd from './BuilderAdd/BuilderAdd'
import ECommerce from './E-Commerce/ECommerce'
import Sitebuilder from './SiteBuilder/Sitebuilder'
// import NewPlanPricing from './NewPlanPricing/NewPlanPricing'
import CompareAll from './CompareAll/CompareAll'
import NewPlanPricing from '../NewPlanPricing/NewPlanPricing'
import { useLocation } from 'react-router-dom'
import Cookies from "js-cookie"


const Pricing = () => {


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const atid = queryParams.get('atid');
  const a_id = queryParams.get('a_id');

  console.log(atid + " " + a_id,'atid');

  useEffect(() => {
    if(a_id !== null){
      Cookies.set('plan_a_id',a_id, { expires: 1 });
    }
  },[atid,a_id]);


  return (
    <>
      <NewPlanPricing />
      {/* <PlanPricing /> */}
      {/* <NewPlanPricing/> */}
      {/* <PlanPricing /> */}
      {/* <CompareAll/> */}
      <EffortlessStartup />
      <BuilderAdd />
      <ECommerce />
      {/* <Sitebuilder /> */}
    </>

  )
}

export default Pricing