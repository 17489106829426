import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
    const id = props.show;
    // const { postData, getData } = useContext(Context);
    const [showModal, setShowModal] = useState({
        code: 0,
        message: "",
    });

    ;

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();



    return (
        <>
            <Modal
                {...props}
                onHide={props.handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Country
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form role="form">
                        <Row className="justify-content-center">

                            <Col md={6}>
                                <div className="main-form-section mt-3">
                                    <Row className="justify-content-center">

                                        <Form.Group>
                                            <div className="text-center">
                                                <Form.Label>Country</Form.Label>
                                            </div>
                                            <InputGroup>

                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Country"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.name,
                                                    })}
                                                    {...register("name", {
                                                        required: "Name is required",
                                                    })}
                                                />
                                            </InputGroup>
                                            {errors.name && (
                                                <span className="text-danger">
                                                    {errors.name.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Row>
                                </div>
                            </Col>

                            <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                    {/* <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link> */}

                                    {/* <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  /> */}
                                </div>
                            </Row>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      /> */}
        </>
    );
};

export default EditOffCanvance;
