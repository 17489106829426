import React from "react";
import "./SelectedTrendingProducts_NewArrivals.css";
import SelectedTrendingProducts from "./SelectedTrendingProducts/SelectedTrendingProducts";
import NewArrivals from "./NewArrivals/NewArrivals";

function SelectedTrendingProducts_NewArrivals() {
  return (
    <>
      <div className="SelectedTrendingProducts_NewArrivals">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5">
              <div className="heading-holder">
                <h4 className="heading">Selected Trending Products</h4>
              </div>

              <div className="SelectedTrendingProducts_slider">
                <SelectedTrendingProducts />
              </div>
            </div>

            <div className="col-lg-8 col-md-7">
              <div className="arrivals-heading heading-holder">
                <h4 className="heading">New arrivals</h4>
              </div>

              <div className="NewArrivals_slider">
                <NewArrivals />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectedTrendingProducts_NewArrivals;
