import React from 'react'
import TrackingCard from './TrackingCard/TrackingCard'
import Tracker from './Tracker/Tracker'
import HelpSlider from '../../HelpCenter/HelpSlider/HelpSlider'

const ShipmentTracking = () => {
    const uplodimg = [

        "/assest/images/B2Bbuyer/Accounts/accountbanner.png",
        "/assest/images/B2Bbuyer/Accounts/accountbanner.png"
    ];
    return (
        <>
            <section className='ShipmentTracking-sec'>

                <div className='container'>

                    <div className='heading'>
                        <h1 >Shipment Tracking</h1>
                    </div>

                    <div className='row'>
                        <div className='col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-12 '>
                            <div className='bg-sec'>
                                <TrackingCard />
                                <Tracker />
                            </div>
                        </div>
                        <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-sm-5 col-12'>
                            <div>
                                <HelpSlider imagePaths={uplodimg} imageClassName="slider-img" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default ShipmentTracking