import React,{useContext,useEffect,useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './EffortlessStartup.css';
import PricingCards from '../../CommonComponents/PricingCards/PricingCards';
import { MaintenanceGet } from "../../../../utils/apis/myWebsite/mywebsite";
import { Context } from "../../../../utils/context";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination} from "swiper/modules";

const EffortlessStartup = () => {

    const pricingData = [
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Maintenance/card1.png",
            title: "I Create a Professional Website for You",
            text: "Looking for a business website with no upfront cost? I'll design a business website with up to 15 pages included for you on Site builder with room for expansion. Included is a theme redesign every 12 months!",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Maintenance/card2.png",
            title: "Powerful SEO Editing Features Built In",
            text: "Search Engine Optimisation tools for titles, descriptions, alt tags and more are at your fingertips with Site builder's included powerful toolkits!",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Maintenance/card3.png",
            title: "Domain Names & Email Included",
            text: "com.au and .com Domain Names included, extra domain names from $3 a month, plus an included 50gb Microsoft 365 Exchange Email account!",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Maintenance/card4.png",
            title: "Easy Drag and Drop Responsive Editor",
            text: "Maintaining and updating your business website has never been this easy with Site builder a Simple Yet Powerful Drag and Drop Editor!",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Maintenance/card1.png",
            title: "I Create a Professional Website for You",
            text: "Looking for a business website with no upfront cost? I'll design a business website with up to 15 pages included for you on Site builder with room for expansion. Included is a theme redesign every 12 months!",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Maintenance/card2.png",
            title: "Powerful SEO Editing Features Built In",
            text: "Search Engine Optimisation tools for titles, descriptions, alt tags and more are at your fingertips with Site builder's included powerful toolkits!",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Maintenance/card3.png",
            title: "Domain Names & Email Included",
            text: "com.au and .com Domain Names included, extra domain names from $3 a month, plus an included 50gb Microsoft 365 Exchange Email account!",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Maintenance/card4.png",
            title: "Easy Drag and Drop Responsive Editor",
            text: "Maintaining and updating your business website has never been this easy with Site builder a Simple Yet Powerful Drag and Drop Editor!",
        },
    ];

    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();


    const { IMG_URL } = useContext(Context);

    
    const [maintaenanceData, setMaintaenanceData] = useState();
    const getMaintaenanceData = async () => {

        const res = await MaintenanceGet();
        if (res?.success) {
            setMaintaenanceData(res?.data);
        }
    }

    useEffect(() => {
        getMaintaenanceData();
    }, [])


    return (
        <section className='effort-less'>
            <Container>
                <div className='text-center'>
                    <p className='effort-txt'>Streamlined website design and support for a stress-free experience</p>
                    <h3 className='effortless-title'>Effortless Startup, Affordable Rates, Hassle-Free Maintenance</h3>
                </div>
                
                <div className="effort-less-slider">
                        <Swiper
                            spaceBetween={14}
                            slidesPerView={4}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                            breakpoints={{
                                280: {
                                    slidesPerView: 2,
                                },
                                320: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                992: {
                                    slidesPerView: 4,
                                },
                            }}
                        >
                            {maintaenanceData?.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <PricingCards title={item?.name} text={htmlToReactParser.parse(item.description)} images={IMG_URL + item?.image} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                {/* <Row>
                    {maintaenanceData?.map((item, index) => (
                        <Col key={index} xxl={3} lg={3} md={4} sm={6} xs={12} className='mt-5'>
                            <PricingCards title={item?.name} text={htmlToReactParser.parse(item?.description)} images={IMG_URL + item?.image}  />
                        </Col>
                    ))}
                </Row> */}
            </Container>
        </section>
    );
};

export default EffortlessStartup;
