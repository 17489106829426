import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";


  export const supportQueryPost = async (data) => {
    try {

        const res = await postData(`/support/query/`,data)
      
      return res;
    } catch (error) {
      console.log(error);
    }
  };

 
  