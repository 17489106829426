import React from 'react'
import '../MyWebsiteLogin/LoginPage.css'
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';

const  CheckEmail = () => {
    return (
        <section className='login-page' >
            <Container>
                <Row className='mx-auto justify-content-center'>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                        <div className='login-white'>
                            <div className='form-title text-center'>
                                <h3>Check your email</h3>
                                <p>We send a password reset link to <span>abc@gmail.com</span></p>
                            </div>
                            <div className="log_in_form">
                                <div className="btn-holder text-center">
                                    <Link to='/mywebsites/reset-pass'><button className="submit_btn" type="button">
                                        Open Gmail
                                    </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CheckEmail