import React, { useState } from "react";
import "./all-supplyy.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Pagination } from "swiper/modules";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "swiper/css";
import "swiper/css/pagination";
import ContactSupplier from "../../common-components/offcanvas/ContactSupplier/ContactSupplier";
import Chatnow from "../../common-components/offcanvas/Chatnow/Chatnow";
import AddTocart from "../../common-components/offcanvas/AddTocart/AddTocart";
import ButtonComponent from "../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import { Link, useNavigate } from "react-router-dom";
import { Product_Type, RequiredIs } from "../../../../utils/common";

import ReactStars from "react-rating-stars-component";
import { render } from "react-dom";
import { Rating } from "react-simple-star-rating";
import CustomisationModal from "../../common-components/AllModal/CustomisationModal/CustomisationModal";

const AllSuppliers = (props) => {
  const { IMG_URL, data } = props;
  const navigate = useNavigate();
  const [swiperInstance, setSwiperInstance] = useState(null);

  // offcanvas code
  const [show, setShow] = useState(false);
  const [showchat, setShowchat] = useState(false);
  const [showcard, setShowcard] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClosechat = () => setShowchat(false);
  const handleShowchat = () => setShowchat(true);

  const handleCloseCard = () => setShowcard(false);
  const handleShowCard = () => setShowcard(true);

  const [showCustomisationModal, setshowCustomisationModal] = useState(false);

  const features = [
    "Feature : armchair",
    "Mail packing : Y",
    "Material : Synthetic leather",
  ];

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  return (
    <>
      <div>
        <div className="card mb-4 rightCradmainSec">
          <div className="card-body">
            {data?.data?.length === 0 && <h5>Not Matching Products</h5>}

            {data?.data?.map((item, index) => (
              <div className="row rightMaincardRow" key={index}>
                <div className="col-lg-3 col-md-3 col-sm-3 col-5">
                  <div className="rightCardsecImage allsuuppppslider">
                    <div className="rightCardsecImage">
                      <Link to={`/b2bbuyer/b2b-productMain/${item.id}`}>
                        <img src={IMG_URL + item?.thumbnail} />
                      </Link>
                    </div>
                    {/* <Swiper
                      spaceBetween={15}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        prevEl: ".swiper-button-prev",
                        nextEl: ".swiper-button-next",
                      }}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                      onSwiper={(swiper) => setSwiperInstance(swiper)}
                    >
                      {item?.images?.map((image, index) => (
                        <SwiperSlide>
                          <div className="rightCardsecImage">
                            <Link to={`/b2bbuyer/b2b-productMain/${item.id}`}>
                              <img src={IMG_URL + image} alt={image} />
                            </Link>
                          </div>
                        </SwiperSlide>
                      ))}

                      <div className="navigation-arrow-holder">
                        <div className="swiper-button-prev">
                          <FontAwesomeIcon
                            className="navigation-arrow "
                            icon={faArrowLeft}
                          />
                        </div>
                        <div className="swiper-button-next">
                          <FontAwesomeIcon
                            className="navigation-arrow "
                            icon={faArrowRight}
                          />
                        </div>
                      </div>
                    </Swiper> */}
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-sm-7  col-7 ">
                  <div>
                    <div className="rightproductHead">
                      <div className="SupplierHeadimg">
                        {/* <Link to={"/b2bbuyer/b2b-productMain"}> */}
                        {/* <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/B2Bbuyer/BestSeller/Supplierlogo.png"
                            }
                            className="supplierLogo"
                          /> */}
                        {/* </Link> */}
                        <div>
                          <h3>{item?.product_title}</h3>
                        </div>

                        <div className="main-price">
                          <div>
                            <p className="price">₹{item.selling_price}</p>
                          </div>
                          <div className="mrp-div">
                            <span className="mrp-title">MRP : </span>
                            <span className="mrp">₹{item.mrp}</span>
                          </div>
                        </div>
                        <span className="discount">
                          {item.mrp && item.selling_price
                            ? `${(
                                ((item.mrp - item.selling_price) / item.mrp) *
                                100
                              ).toFixed(2)}% off`
                            : ""}
                        </span>

                        {/* <div>
                          <p className="prod-price">₹ {item.selling_price}</p>
                        </div> */}
                        <div className="minOrderProduct">
                          <p className="minodppp">
                            MOQ : {item?.minimum_order_quantity} pc
                          </p>
                        </div>
                      </div>

                      <div className="d-flex reactstarss">
                        <p className="ordersss">{item?.order_count} Orders</p>
                        <Rating
                          initialValue={Math.round(item?.average_rating)}
                          SVGclassName="star-svg"
                          fillColor="#00000"
                          readonly
                          size={24}
                        />
                        {/* <ReactStars
                          count={5}
                          onChange={ratingChanged}
                          size={24}
                          activeColor="#ffd700"
                        /> */}
                        <p className="ratinggcountt">{item?.average_rating}</p>
                      </div>
                      <ul>
                        <h3 className="capabilityHead">Capabilities</h3>
                        {item?.highlights?.slice(0, 2).map((feature, index) => (
                          <li key={index}>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assest/images/B2Bbuyer/BestSeller/check.png"
                              }
                              className="checkSymbol"
                              alt="Check"
                            />
                            {feature?.description}
                          </li>
                        ))}
                      </ul>

                      <div className="verifiesMiansec ">
                        <div className="verifyImgtxt">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/B2Bbuyer/BestSeller/Layer2.png"
                            }
                            className="verifiedCheck"
                            alt="Verified"
                          />
                          Verified Supplier
                        </div>

                        {/* <p
                          style={{
                            background: `${item?.customization_color} !important`,
                          }}
                          onClick={() => setshowCustomisationModal(true)}
                          className="text-field"
                        >
                          {Product_Type?.map((val, index) => {
                            if (item.customization === val?.name) {
                              return val.tag;
                            }
                            return null;
                          }).filter((tag) => tag !== null)}
                        </p> */}

                        {Product_Type?.map((val, index) => {
                          if (
                            item.customization === val?.name &&
                            val.name !== "General"
                          ) {
                            return (
                              <p
                                key={index}
                                style={{
                                  background: `${item?.customization_color} !important`,
                                }}
                                onClick={() => setshowCustomisationModal(true)}
                                className="text-field"
                              >
                                {val.tag}
                              </p>
                            );
                          }
                          return null;
                        }).filter((tag) => tag !== null)}
                      </div>
                    </div>
                  </div>

                  {/* <Button
                    className="contactSupplbtn rightcardbtn me-1"
                    type="button"
                    onClick={() => {
                      navigate(`/b2bbuyer/b2b-productMain/${item.id}`);
                    }}
                  >
                    {" "}
                    Buy Now{" "}
                  </Button>

                  <Button
                    className="contactSupplbtn rightcardbtn  me-1"
                    type="button"
                    onClick={() => {
                      navigate(`/b2bbuyer/b2b-productMain/${item.id}`);
                    }}
                  >
                    {" "}
                    Add to Cart{" "}
                  </Button>

                  {RequiredIs?.inquery?.includes(item?.product_type_id) && (
                    <Button className="contactSupplbtn rightcardbtn  me-1">
                      Inquiry{" "}
                    </Button>
                  )} */}

                  {/* <div className="">
                    <ButtonComponent
                      className="contactSupplbtn rightcardbtn"
                      onClick={handleShow}
                    >
                      Contact Supplier
                    </ButtonComponent>
                    <ButtonComponent
                      className="chatnowbtn rightcardbtn"
                      onClick={handleShowchat}
                    >
                      Chat Now
                    </ButtonComponent>

                    <ButtonComponent
                      className="contactSupplbtn rightcardbtn"
                      onClick={handleShowCard}
                    >
                      Add to Cart
                    </ButtonComponent>
                  </div> */}
                </div>

                <div className="col-lg-2 col-md-2 col-sm-2  col-12  pe-0 ps-0">
                  <Button
                    className="contactSupplbtn rightcardbtn me-1"
                    type="button"
                    onClick={() => {
                      navigate(`/b2bbuyer/b2b-productMain/${item.id}`);
                    }}
                  >
                    {" "}
                    Buy Now{" "}
                  </Button>

                  <Button
                    className="contactSupplbtn rightcardbtn  me-1"
                    type="button"
                    onClick={() => {
                      navigate(`/b2bbuyer/b2b-productMain/${item.id}`);
                    }}
                  >
                    {" "}
                    Add to Cart{" "}
                  </Button>

                  {RequiredIs?.inquery?.includes(item?.product_type_id) && (
                    <Button className="contactSupplbtn rightcardbtn  me-1">
                      Inquiry{" "}
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <ContactSupplier
          handleClose={handleClose}
          placement="bottom"
          name="bottom"
          show={show}
        />

        <CustomisationModal
          show={showCustomisationModal}
          onHide={() => setshowCustomisationModal(false)}
        />

        <Chatnow
          handleClose={handleClosechat}
          placement="bottom"
          name="bottom"
          show={showchat}
        />

        <AddTocart
          handleClose={handleCloseCard}
          placement="end"
          name="end"
          show={showcard}
        />
      </div>

      {/* <div className="suggestedProd">
        <div className="subCategorieshead">
          <h3>Suggested Products</h3>
        </div>
        <div className="row swiperMainrow">
          <div className="col-lg-12 col-md-12">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className=""
            >
              <SwiperSlide>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/B2Bbuyer/BestSeller/suggestedbanner.png"
                  }
                  className="swipersuggestedImgtwo"
                />
              </SwiperSlide>

              <SwiperSlide>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/B2Bbuyer/BestSeller/suggestedbanner.png"
                  }
                  className="swipersuggestedImgtwo"
                />
              </SwiperSlide>

              <SwiperSlide>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/B2Bbuyer/BestSeller/suggestedbanner.png"
                  }
                  className="swipersuggestedImgtwo"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AllSuppliers;
