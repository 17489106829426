import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "./All_product_count_list.css";
import { allProductCounter ,allMainDashboeard} from "../../../../../../utils/apis/seller/BTBdashborad";

const All_product_count_list = () => {
  const [data, setData] = useState();
  const GetProductData = async () => {
    const response = await allMainDashboeard();
    setData(response?.data);
  };
  console.log(data, "data");
  useEffect(() => {
    GetProductData();
  }, []);
  const product_list = [
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/box.png",
      name: "All Products",
      number: data?.total_products,
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/shopping-bag.png",
      name: "New Orders",
      number:  data?.total_new_orders,
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/box2.png",
      name: "Sales Products",
      number:  data?.total_sales,
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/sales.png",
      name: "Monthly Sales",
      number:  data?.current_month_sales,
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/shopping-bag2.png",
      name: "Active Orders",
      number: data?.total_Activeproduct,
    },
  ];

  return (
    <>
      <section className="All_product_count_list">
        <div className="row">
          {product_list.map((data, index) => (
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-widthhhh">
              <div className="list-flex">
                <div className="img-div">
                  <div className="circle-ico ico1">
                    <img className="icon" src={data.icon} />
                  </div>
                </div>
                <div className="cont-div">
                  <p className="name">{data.name}</p>
                  <p className="number">{data.number}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default All_product_count_list;
