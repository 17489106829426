import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Logistic_Routes_main from '../Logistic_Routes_main/Logistic_Routes_main'

const Logistic_Routes = () => {
  return (
    <>
      <Routes>
        <Route path={`/logistic/*`} element={<Logistic_Routes_main />} />
      </Routes>
    </>
  )
}

export default Logistic_Routes