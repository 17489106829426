import React from "react";
import "./NetPurtiSeller.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
const NetPurtiSeller = () => {
  return (
    <>
      <section className="NetpurtiSeller">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="NetpurtiSeller-title">
                <h2>Master the art of using Netpurti Seller Panel</h2>
                <p>Get the know-how to use Netpurti Seller Panel like a pro</p>
              </div>
            </div>
          </div>

          <div className="videoTabmain">
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
              <Row>
                <Nav variant="pills" className="videotabRow">
                  <Nav.Item>
                    <Nav.Link eventKey="1">Create Account</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2">Informative Dashboard</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3">Seller Inventory</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4">Orders</Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    <div className="videoMainsec">
                      <video
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller_video/tabVideo.mp4"
                        }
                        className="tabVideo"
                        loop
                        muted
                        autoPlay
                      ></video>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="2"></Tab.Pane>
                  <Tab.Pane eventKey="3"></Tab.Pane>
                  <Tab.Pane eventKey="4"></Tab.Pane>
                </Tab.Content>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default NetPurtiSeller;
