import {
  faArrowTrendDown,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// import './stocklist.css'

const Orderstock = () => {
  const product_list = [
    {
      icon: "/assest/images/Seller_panel/AffilateDashboard/Icon/cursor.png",
      name: "Total Clicks",
      number: "5000",
      backgroundColor: "#FF9E4A",
      iconClassName: "line_up_down_icon",
      faIcon: faArrowTrendUp,
    },
    {
      icon: "/assest/images/Seller_panel/AffilateDashboard/Icon/user.png",
      name: "Conversion",
      number: "1000",
      backgroundColor: "#4AA5FF", // Example background color
      iconClassName: "line_up_down_icon_2",
      faIcon: faArrowTrendDown,
    },
    {
      icon: "/assest/images/Seller_panel/AffilateDashboard/Icon/black.png",
      name: "Earning Amount",
      number: "4000",
      backgroundColor: "#A9FB88", // Example background color
      iconClassName: "line_up_down_icon",
      faIcon: faArrowTrendUp,
    },
    {
      icon: "/assest/images/Seller_panel/AffilateDashboard/Icon/white-₹.png",
      name: "Incentive",
      number: "51429",
      backgroundColor: "#F44B4B", // Example background color
      iconClassName: "line_up_down_icon_2",
      faIcon: faArrowTrendDown,
    },
  ];

  return (
    <>
      <section className="Affilate_Stock_list_section Affilate_All_product_count_list">
        <div className="row">
          {product_list.map((data, index) => (
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-widthhhh">
              <div className="list-flex">
                <div className="img-div">
                  <div
                    className="circle-ico ico1"
                    style={{ backgroundColor: data.backgroundColor }}
                  >
                    <img className="icon" src={data.icon} />
                  </div>
                </div>
                <div className="name_lineup_holder">
                  <div className="cont-div">
                    <p className="name">{data.name}</p>
                    <p className="number">{data.number}</p>
                  </div>

                  <div>
                    <FontAwesomeIcon
                      className={data.iconClassName}
                      icon={data.faIcon}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Orderstock;
