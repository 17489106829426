import React, { useState, useEffect, useContext } from "react";
// import "./b2b-table.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { motion } from "framer-motion";

import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { AnimatePresence } from "framer-motion";
import { ShimmerTable } from "react-shimmer-effects";
import Form_modal from "../../../../../CommonForAll/influencer-work-modal/Form_modal";

// import {
//     getAllProducts,
//     productStatusChange,
//     historiesGet,
// } from "../../../../../../../utils/apis/seller/Product";

// import {
//     ProductStatus,
//     Category,
// } from "../../../../../../../utils/apis/master/Master";
import { type } from "@testing-library/user-event/dist/type";
import { Context } from "../../../../../../utils/context";
import CustomPaginate from "../../../../../CommonForAll/CustomPaginate/CustomPaginate";
import VeiwEditModal from "../../../../../CommonForAll/VeiwEditModal/VeiwEditModal";
import Task_modal from "../../../../../CommonForAll/task-data-modal/Task_modal";
import SuccessModalDynamic from "../../../../../CommonForAll/SuccessModalDynamic/SuccessModalDynamic";

import {
  getAffiliateTask,
  getInfluencerActiveTask,
  getInfluencerTask,
} from "../../../../../../utils/apis/affiliate/InHouseTask/Task/task";
import {
  Category,
  ProductStatus,
} from "../../../../../../utils/apis/master/Master";
import CategoryDetails from "../../../../../CommonForAll/CategoryDetails/CategoryDetails";
import View_all_varients_offcanvas from "../../../../Seller_Dashboard/common-components/offcanvas/view-all-varients-offcanvas/View_all_varients_offcanvas";
import { taskStatusChange } from "../../../../../../utils/apis/affiliate/affiliate";
import { getSuperAffiliateSetting } from "../../../../../../utils/apis/superSetting";

import InfluencerCancelModal from "../../../../../CommonForAll/InfluencerCancelModal/InfluencerCancelModal";
import { getAffiliateWalletApi } from "../../../../../../utils/apis/affiliate/wallet/wallet";
import { getInfluencerDataApi } from "../../../../../../utils/apis/influncer/influncer";
import ErrorModal from "../../../../../CommonForAll/ErrorModal/ErrorModal";

const Ordertable = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { shimmerLoader, setShimmerLoader, formatDateExpire, formatDate, userdata } =
    useContext(Context);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [productId, setProductId] = useState("");

  const [workModal, setWorkModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [cancelSuccessModal, setCancelSuccessModal] = useState(false);
  const [promptShow, setPromptShow] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [modaleye, setModaleye] = useState(false);
  const [modalShowedit, setModalShowedit] = useState(false);
  const [varientsoffcanvas, setVarientsoffcanvas] = useState(false);
  const [taskViewShow, setTaskViewShow] = useState(false);
  const [showData, setShowData] = useState();
  const [productTitle, setProductTitle] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [category, setCategory] = useState("");
  const [varientType, setVarientType] = useState("");
  const [key, setKey] = useState("home");
  const [deleteshow, setdeleteShow] = useState(false);

  const [show1, setShow1] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      setCopyModal(true);
      setTimeout(() => {
        setCopyModal(false);
      }, 2000);
      console.log('URL copied to clipboard:', url);
    }).catch(err => {
      console.error('Failed to copy URL: ', err);
    });
  };

  const InitialTransition = () => { };
  const blackBox = {
    initial: {
      height: "100vh",
    },
  };

  const { IMG_URL } = useContext(Context);
  const [products, setProducts] = useState([]);

  const getProducts = async (page) => {
    setShimmerLoader(true);
    const res = await getInfluencerActiveTask(
      page,
      productTitle,
      searchStatus,
      category,
      startDate,
      endDate
    );
    if (res?.success) {
      console.log(res);
      const newData = res?.data?.data;
      if (newData) {
        const newIds = newData.map((d) => d?.id);
        setAllChecked(newIds);
      }
      setProducts(res.data);
      setShimmerLoader(false);
    }
  };
  const [varIndex, setIndex] = useState("");
  const [variantData, setVariantData] = useState();
  const handleVariantModal = async (data, index) => {
    await setIndex(index);
    await setVariantData(data);
    setVarientsoffcanvas(true);
  };

  const [productStatus, setProductStatus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [superSetting, setSuperSetting] = useState();
  const [affiliateWallet, setAffiliateWallet] = useState();
  const [influencerData, setInfluencerData] = useState();
  const getMasters = async () => {
    {
      const res = await ProductStatus();
      if (res?.success) {
        setProductStatus(res?.data);
      }
    }
    {
      const res = await Category(userdata?.s_sector?.s_working_type_id);
      if (res?.success) {
        setCategories(res?.data);
      }
    }
    {
      const res = await getSuperAffiliateSetting();
      if (res?.success) {
        setSuperSetting(res?.data);
      }
    }
    {
      const res = await getAffiliateWalletApi();
      if (res?.success) {
        setAffiliateWallet(res?.data);
      }
    }
    {
      const res = await getInfluencerDataApi();
      if (res?.success) {
        setInfluencerData(res?.data);
      }
    }
  };

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  console.log(selectAllChecked, "ttt");

  const [promptText, setPromptText] = useState("");
  const [blockAmount, setBlockAmount] = useState("");
  const [data, setData] = useState("");
  const handleAcceptModal = async (val) => {

    await setData(val);

    let payAmount = Number(val.insentive);
    if (val?.happy_code !== null) {
      payAmount += Number(val?.visit_cost);
    }



    let blockAmount;

    if (superSetting !== null) {
      if (payAmount <= superSetting?.below_amount) {
        blockAmount = influencerData?.influencer_open?.package_amount - influencerData?.influencer_open?.package_amount * superSetting?.package_percent / 100;
      } else {
        blockAmount = influencerData?.influencer_open?.visit_cost - influencerData?.influencer_open?.visit_cost * superSetting?.visit_percent / 100;
      }
    }
    if (blockAmount > affiliateWallet?.wallet?.amount) {
      setPromptText(`₹ ${blockAmount} should be available in your wallet`);
      setErrorShow(true);
      setTimeout(() => {
        setErrorShow(false);
      }, 2000);
    } else {
      setPromptText(`₹ ${blockAmount} of amount will be blocked from your wallet`);
      setPromptShow(true);
    }

  };
  

  const handleTaskStatus = async (id, status) => {

    
      const res = await taskStatusChange(id, status);
      if (res?.success) {
        setPromptShow(false);
        getProducts(currentPage);
      }
    

    
  };



  const [affiliateTaskID, setAffiliateTaskID] = useState("");
  console.log(affiliateTaskID, 'ppppppppp');
  const handleWork = async (id) => {
    setWorkModal(true);
  }


  const [promptNote, setPromptNote] = useState("");
  const cancelTask = async (val) => {


    await setData(val);

    let payAmount = Number(val.insentive);
    if (val?.happy_code !== null) {
      payAmount += Number(val?.visit_cost);
    }



    let blockAmount;
    let penaltyAmount;

    if (superSetting !== null) {
      if (payAmount < superSetting?.below_amount) {
        blockAmount = influencerData?.influencer_open?.package_amount - influencerData?.influencer_open?.package_amount * superSetting?.package_percent / 100;
      } else {
        blockAmount = influencerData?.influencer_open?.visit_cost - influencerData?.influencer_open?.visit_cost * superSetting?.visit_percent / 100;
      }

      if (payAmount < superSetting?.below_amount_penalty) {
        penaltyAmount = Number(val.insentive) * superSetting?.amount_less_percent / 100;
      } else {
        penaltyAmount = Number(val.insentive) * superSetting?.amount_above_percent / 100;
      }

    }


    setPromptNote(`If you cancelled the task after accepting, it will cost a penalty charge of ₹ ${penaltyAmount}`)

    setBlockAmount(blockAmount);
    setPromptShow(true);



  }

  useEffect(() => {
    getMasters();
    getProducts(currentPage);
  }, [currentPage, productTitle, searchStatus, category, startDate, endDate]);

  return (
    <>
      <section className="B2b_table_section">
        <AnimatePresence />
        <h6 className="pdlsis">Influencer Active Tasks</h6>
        <div className="product-main">
          <div className="search-main">
            <Form>
              <div className="row">
                <div className="col-xxl-5 co-xl-5 col-lg-3 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      value={productTitle}
                      type="search"
                      placeholder="Search by Adv ID"
                      onChange={(e) => setProductTitle(e.target.value)}
                    />
                    <FontAwesomeIcon
                      className="serch-icoo"
                      icon="fa-solid fa-magnifying-glass"
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-5 co-xl-5 col-lg-6 col-md-6">
                  <div className="d-flex product-status-div">
                    {/* <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>From</Form.Label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          name="start_date"
                          id="start_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="DD/MM/YYYY"
                        />
                      </Form.Group>
                    </div>
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>To</Form.Label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          name="end_date"
                          id="end_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy" // Set date format
                          className="form-control"
                          placeholderText="DD/MM/YYYY" // Update placeholder
                        />
                      </Form.Group>
                    </div> */}
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>From</Form.Label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          name="start_date"
                          id="start_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="DD/MM/YYYY"
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Form.Group>
                    </div>
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>To</Form.Label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          name="end_date"
                          id="end_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="DD/MM/YYYY"
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate} // This disables dates before the selected 'From' date
                        />
                      </Form.Group>
                    </div>

                  </div>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Reset Search</Form.Label>
                    <button
                      className="resetsearch-btn"
                      type="button"
                      onClick={() => {
                        // setSearchStatus("");
                        setProductTitle("");
                        setStartDate("");
                        setEndDate("");
                        // setCategory("");
                      }}
                    >
                      Reset
                    </button>
                  </Form.Group>
                </div>
                {/* 
                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Download data in Excel</Form.Label>
                    <div>
                      <img
                        className="excelimg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/dashboard//icons/excel.png"
                        }
                      />
                    </div>
                  </Form.Group>
                </div> */}
              </div>

              <hr></hr>

              <div className="table-responsive table-overflow">
                {shimmerLoader ? (
                  <>
                    <ShimmerTable row={1} col={12} />
                  </>
                ) : (
                  <>
                    <table className="table table-bordered">
                      <thead>
                        <tr className="">
                          <td>Adv ID</td>

                          <td>Type</td>

                          <td>Insentive</td>

                          <td>Validity</td>

                          {/* <td>Product Type</td> */}
                          <td>Product </td>

                          <td>Platform</td>
                          <td>Language</td>

                          <td>Task Created</td>
                          {/* <td>Happy Code</td> */}
                          <td>Task View</td>
                          <td>View Link</td>
                          <td>Copy Link</td>
                          <td>Action</td>
                          <td>Completion</td>
                        </tr>
                      </thead>

                      <tbody>
                        {/* <tr>
                      <td> 1527</td>

                      <td>WR suresh</td>
                      <td>#1122</td>

                      <td>*******678</td>

                      <td>1286.2</td>
                      <td>100 </td>
                      <td>106 (9%)</td>
                      <td>200</td>

                      <td>5165.4</td>

                      <td>
                        <Button className="acceptbtn" type="button">
                          Paid
                        </Button>
                      </td>
                    </tr> */}

                        {products?.data?.map((val, index) => (
                          <tr>
                            {/* <td className="tdd-checkkkk">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  // value={value?.id}
                                  name="perselected"
                                  onChange={handleChange}
                                  id="flexCheckDefault"
                                ></input>
                              </td> */}

                            <td> {val?.task_id}</td>

                            <td>{val?.task_type}</td>
                            <td>₹ {val?.insentive}</td>
                            <td>
                              {formatDateExpire(val?.createdAt, val?.validity)}
                            </td>
                            {/* <td>{val?.product_type}</td> */}
                            <td>{val?.s_product?.product_title}</td>
                            <td>
                              {val?.influencer_task?.a_social_detail?.platform}
                            </td>
                            <td>{val?.influencer_task?.language?.name}</td>
                            <td>
                              {val?.createdAt &&
                                new Date(val.createdAt).toLocaleString(
                                  "en-US",
                                  {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                  }
                                )}
                            </td>

                            {/* <td>
                              {val?.affiliate_tasks.length == 0 ? (
                                <>********</>
                              ) : val?.affiliate_tasks?.[0]
                                ?.affiliate_status === "accepted" ? (
                                val?.happy_code
                              ) : (
                                <>********</>
                              )}
                            </td> */}

                            <td>
                              <FontAwesomeIcon
                                icon="fa-solid fa-eye"
                                className="actionIcon"
                                onClick={() => {
                                  setShowData(val);
                                  setTaskViewShow(true);
                                }}
                              />
                            </td>
                            <td>
                              {val?.product_id != null &&
                                (val?.affiliate_tasks.length == 0 ? (
                                  <a
                                    target="_blank"
                                    className="btn btn-info ms-3"
                                    href={val?.website_url}
                                  >
                                    View
                                  </a>
                                ) : val?.affiliate_tasks?.[0]
                                  ?.affiliate_status === "accepted" ? (
                                  <a
                                    target="_blank"
                                    className="btn btn-info ms-3"
                                    href={
                                      val?.website_url +
                                      `&a_id=${val?.affiliate_tasks?.[0]?.id}`
                                    }
                                  >
                                    View
                                  </a>
                                ) : (
                                  <a
                                    target="_blank"
                                    className="btn btn-info ms-3"
                                    href={val?.website_url}
                                  >
                                    View
                                  </a>
                                ))}
                            </td>
                            <td>
                              {val?.affiliate_tasks.length == 0 ? (

                                <FontAwesomeIcon icon="fa-solid fa-copy" className="actionIcon" onClick={() => copyToClipboard(val?.website_url)} />

                              ) : val?.affiliate_tasks?.[0]
                                ?.affiliate_status === "accepted" ? (

                                <FontAwesomeIcon icon="fa-solid fa-copy" className="actionIcon" onClick={() => copyToClipboard(val?.website_url +
                                  `&a_id=${val?.affiliate_tasks?.[0]?.id}`)} />

                              ) : (

                                <FontAwesomeIcon icon="fa-solid fa-copy" className="actionIcon" onClick={() => copyToClipboard(val?.website_url)} />

                              )}
                            </td>
                            {/* <td>
                                <div className=" imgMain">
                                  {val?.s_product?.s_brand?.image ? (
                                    <img className='bjvbhbvh-img' src={IMG_URL + val?.s_product?.s_brand?.image} />
                                  ) : ('')}
                                  
                                </div>
                              </td>
                             
                              <td>100 </td>
                              
                              <td>200</td>
  
                              <td>0</td>
                              <td>{val?.validity}</td> */}

                            {/* <td>
                                
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={val?.status}
                                    onChange={() => {
                                      handleStatus(val?.id);
                                    }}
                                    id={`flexSwitchCheckDefault${val?.id}`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`flexSwitchCheckDefault${val?.id}`}
                                  >
                                    {val?.status ? "Active" : "Inactive"}
                                  </label>
                                </div>
                              </td> */}
                            <td>
                              <div className="d-flex">
                                {/* <FontAwesomeIcon icon="fa-solid fa-pen" className="actionIcon"  /> */}
                                {/* <FontAwesomeIcon icon="fa-solid fa-trash" className="actionIcon" /> */}

                                {formatDateExpire(
                                  val?.createdAt,
                                  val?.validity
                                ) <= formatDate(new Date()) ? (
                                  <>
                                    <span className="text text-danger">
                                      <b>Expired</b>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {val?.affiliate_tasks.length == 0 ? (
                                      <>
                                        <button
                                          className="btn btn-success ms-3"
                                          type="button"
                                          onClick={() =>
                                            handleAcceptModal(val)
                                          }
                                        >
                                          Accept
                                        </button>
                                        <button
                                          className="btn btn-danger ms-3"
                                          type="button"
                                          onClick={() =>
                                            handleTaskStatus(
                                              val?.id,
                                              "rejected"
                                            )
                                          }
                                        >
                                          Reject
                                        </button>
                                      </>
                                    ) : val?.affiliate_tasks?.[0]
                                      ?.affiliate_status === "accepted" ? (
                                      <span className="text text-success">
                                        <b>Accepted</b>
                                      </span>
                                    ) : (
                                      <span className="text text-danger">
                                        <b>Rejected</b>
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </td>

                            <td>
                              {formatDateExpire(
                                val?.createdAt,
                                val?.validity
                              ) > formatDate(new Date()) && (
                                  // val?.affiliate_tasks?.[0]
                                  //   ?.influencer_work === null ? (
                                  <>
                                    <a
                                      className="btn btn-success ms-3"
                                      onClick={() => {
                                        setAffiliateTaskID(val?.affiliate_tasks?.[0]
                                          ?.id); setWorkModal(true);
                                      }}
                                    >
                                      Upload Work
                                    </a>
                                  </>

                                )}


                            </td>
                            <td>


                              {formatDateExpire(
                                val?.createdAt,
                                val?.validity
                              ) <= formatDate(new Date()) ? (
                                <>

                                </>
                              ) : (
                                <>
                                  {val?.affiliate_tasks.length == 0 ? (
                                    <>

                                    </>
                                  ) : val?.affiliate_tasks?.[0]
                                    ?.affiliate_status === "accepted" ? (
                                    <a
                                      className="btn btn-danger ms-3"
                                      onClick={() => { cancelTask(val) }}
                                    >
                                      Cancel
                                    </a>
                                  ) : (
                                    <>

                                    </>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </Form>
          </div>

          <CategoryDetails show={modaleye} onHide={() => setModaleye(false)} />

          <div className="pagination-div">
            <CustomPaginate
              currentPage={currentPage}
              totalPages={products?.totalPages}
              handlePageChange={handlePageChange}
            />
          </div>

          <Task_modal
            show={taskViewShow}
            showData={showData}
            onHide={() => setTaskViewShow(false)}
          />
        
          <Form_modal show={workModal} onHide={() => setWorkModal(false)} affiliateTaskID={affiliateTaskID} getProducts={getProducts} />

          <SuccessModalDynamic show={copyModal} onHide={() => setCopyModal(false)} text={'Link Copied'} />
          <SuccessModalDynamic show={cancelSuccessModal} onHide={() => setCancelSuccessModal(false)} text={'Task Cancelled Successfully'} />
          <InfluencerCancelModal show={promptShow} onHide={() => setPromptShow(false)} getProducts={getProducts} setCancelSuccessModal={setCancelSuccessModal} note={promptNote} data={data} blockAmount={blockAmount} handleTaskStatus={handleTaskStatus} />
          <ErrorModal show={errorShow} onHide={() => setErrorShow(false)} text={promptText} />
        </div>
      </section>
    </>
  );
};

export default Ordertable;
