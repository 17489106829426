import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./ErrorModal.css";
import ErrorLottie from "../Animation/ErrorAnimation/ErrorLottie";
const ErrorModal = (props) => {
  return (
    <>
      <section>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="ErrorModalPop"

        >
          <Modal.Body>
            <ErrorLottie />

            <div className="modalContentinfo">
              <div className="modalHead">
                <h3>{props.heading}</h3>
                <p>{props.text}</p>
              </div>

              {/* <div className="text-center">
                <Button className="btn tryagainbtn" onClick={() => props.onHide()}>Try again</Button>
              </div> */}
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default ErrorModal;
