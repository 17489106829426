import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getBuyOrderDetails = async (data) => {
  try {
    const res = await postData(`/btb/order-process/buy-order-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllOrders = async (data) => {
  try {
    const res = await getData(`/btb/order-process/order`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const postCancelOrder = async (data) => {
  try {
    const res = await postData(`/btb/order-process/order-cancel`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
