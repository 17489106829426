import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import { add } from "../../../../../../utils/apis/seller/outer-website/masters/flashSale/flashSale";
import {
  AllProduct,

} from "../../../../../../utils/apis/master/Master";
import { IDS } from "../../../../../../utils/common";
import Select from "react-select";
library.add(fas);

const AddOffCanvance = (props) => {

  const { postData, getData, Select2Data, Id, Select3Data, sellerDomain } = useContext(Context);
  const [startDateTime, setStartDateTime] = useState('');
  const [product, setProduct] = useState('');
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm();

  const imageFile = watch("image");
  const startTime = watch('start_time', startDateTime);
  const onSubmit = async (data) => {
    try {
      console.log(data);

      const sendData = new FormData();
      sendData.append("domain_id", sellerDomain?.value);
      sendData.append('name', data?.name);
      sendData.append('start_time', data?.start_time);
      sendData.append('end_time', data?.end_time);
      sendData.append('percentage', data?.percentage);
      sendData.append('image', data?.image[0]);
      const productIds = data?.product_id?.map(option => option.value);
      if (productIds && productIds.length > 0) {
        const productIdsJSON = JSON.stringify(productIds);
        sendData.append("product_id", productIdsJSON);
      }
      const response = await add(sendData);
      // console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response?.code, message: response?.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };
  const getCurrentDateTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localISOTime = new Date(now - offset).toISOString().slice(0, 16);
    return localISOTime;
  };



  const GetAllProducts = async () => {
    const response = await AllProduct(sellerDomain?.value, 'BTC');

    if (response?.success) {
      setProduct(await Select3Data(response?.data, "product_id"));
    }
    // console.log(response.data, "product product");
  };

  useEffect(() => {
    GetAllProducts();
  }, []);
  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Flash Sale
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Form.Group>

                    <Form.Label>Heading</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Heading"
                        className={classNames("", {
                          "is-invalid": errors?.name,
                        })}
                        {...register("name", {
                          required: "Heading is required",
                        })}
                        onChange={(e) => setStartDateTime(e.target.value)}
                      />
                    </InputGroup>
                    {errors.name && (
                      <span className="text-danger">{errors.name.message}</span>
                    )}
                  </Form.Group>
                </div>

                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Product</Form.Label>

                    <Controller
                      name="product_id" // name of the field
                      {...register("product_id", {
                        required: "Select Product",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          isMulti
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.product_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={product}
                        />
                      )}
                    />

                    {errors.product_id && (
                      <span className="text-danger">
                        {errors.product_id.message}
                      </span>
                    )}
                  </Row>
                </div>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="datetime-local"
                        name="start_time"
                        min={getCurrentDateTime()}
                        className={classNames("", {
                          "is-invalid": errors?.start_time,
                        })}
                        {...register("start_time", {
                          required: "Date and time is required",
                        })}
                      />
                    </InputGroup>
                    {errors.dateTime && (
                      <span className="text-danger">{errors.start_time.message}</span>
                    )}
                  </Form.Group>
                </div>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="datetime-local"
                        name="end_time"
                        min={startTime || getCurrentDateTime()}
                        className={classNames("", {
                          "is-invalid": errors?.end_time,
                        })}
                        {...register("end_time", {
                          required: "Date and time is required",
                        })}
                      />
                    </InputGroup>
                    {errors.dateTime && (
                      <span className="text-danger">{errors.end_time.message}</span>
                    )}
                  </Form.Group>
                </div>

                <div className="main-form-section mt-3">
                  <Form.Group>

                    <Form.Label>percentage</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="percentage"
                        max={100}
                        min={0}
                        placeholder="percentage"
                        className={classNames("", {
                          "is-invalid": errors?.percentage,
                        })}
                        {...register("percentage", {
                          required: "percentage is required",
                        })}
                      // onChange={(e) => setStartDateTime(e.target.value)}
                      />
                    </InputGroup>
                    {errors.percentage && (
                      <span className="text-danger">{errors.percentage.message}</span>
                    )}
                  </Form.Group>
                </div>


                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    <Form.Label className="text-left">
                      Image
                    </Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          required: "Image is required",
                          // validate: async (value) => {
                          //   if (typeof value !== "string") {
                          //     const fileTypes = ["jpg", "png", "jpeg"];
                          //     const fileType = value[0].name?.split(".")[1];

                          //     if (!fileTypes.includes(fileType)) {
                          //       return `please upload a valid file format. (${fileTypes})`;
                          //     }

                          //     const sizes = await getDimension(value[0]);
                          //     if (
                          //       sizes.width !== 420 &&
                          //       sizes.height !== 520
                          //     ) {
                          //       return "Image width and height must be 420 px and 520 px";
                          //     }

                          //     const fileSize = Math.round(
                          //       value[0].size / 1024
                          //     );
                          //     if (fileSize > 500) {
                          //       return "file size must be lower than 500kb";
                          //     }
                          //   }
                          // },
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}

                  </Row>
                </div>
              </Col>

              <Col className="main-form-section mt-3">
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    {
                      imageFile &&
                      imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            // src={URL.createObjectURL(getValues("image")[0])}
                            src={URL?.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      )
                    }

                  </Row>
                </div>
              </Col>




              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>

        </Modal.Body>
      </Modal >

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
