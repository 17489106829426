import React from 'react'
import './TrackingCard.css'
const TrackingCard = () => {
    return (
        <>
            <section className='TrackingCard-sec'>
                    <div className='card-sec'>
                        <div className='row'>
                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                <p className='invoice-text'>Invoice Number : INV00002</p>
                                <div className='left-sec'>
                                    <div>
                                        <p className='order-text'>Sidior Furniture Company Limited</p>
                                    </div>
                                    <div className='me-3'>
                                        <img src="/assest/images/B2Bbuyer/BestSeller/productImg1.png" alt="Product" className="prduct-img" />

                                    </div>
                                    <div className='details'>
                                        <h6>Modern Minimalist White Half Circle Designer Couches Luxury</h6>
                                        <p>Min. order: 2.0 piece</p>
                                        <p>Estimated delivery by Apr 26-May 17</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className='right-sec'>
                                    <div>
                                        <p className='price-text'>₹60,000</p>
                                    </div>


                                    <div className='count'>
                                        <p className='order-text'>Order Placed</p>
                                    </div>

                                    <div>
                                        <button className='order-btn'>View Order</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
             

            </section>
        </>
    )
}

export default TrackingCard