import React from 'react'
import { Button } from "react-bootstrap"
const ButtonComponent = ({ className, children, onClick }) => {
    return (
        <>
            <Button type="button" className={className} onClick={onClick}> 
                {children}
            </Button>
        </>
    )
}

export default ButtonComponent