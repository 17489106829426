import Home from "../Home/Home";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SellerRoutesMain from "../SellerRoutesMain/SellerRoutesMain";
import Call_support from "../call-support/Call_support";

const SellerRoutes = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Routes>
        <Route path={`/seller/*`} element={<SellerRoutesMain />} />
      </Routes>
    </>
  );
};

export default SellerRoutes;
