import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './Notification_offcanvas.css'

const Notification_offcanvas = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    return (
        <>
            <section className=''>
                <Offcanvas
                    show={show}
                    onHide={handleClose}
                    {...props}
                    className="Notification_offcanvas offcanvas-end"
                >
                    <Offcanvas.Header closeButton onClick={props.onHide}>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='row notification-div'>
                            <div className='col-6'>
                                <Offcanvas.Title>Notification</Offcanvas.Title>
                            </div>
                            <div className='col-6 text-end'>
                            <p className='maksk-ppp'>Mark all as read</p>
                            </div>
                        </div>

                        <div className='notification-div bg-collll'>
                            <div className='row'>
                                <div className='col-9'>
                                    <p className='noti-pppp'><img className='nott-imgg-pp' src={process.env.PUBLIC_URL + '/assest/images/Seller_panel/dashboard/icons/nott.png'} />New message from <span className='not-pp-name'>sara jhonson</span></p>
                                </div>
                                <div className='col-3'>
                                    <p className='nott-p-timggg'>5 min ago</p>
                                </div>
                            </div>
                        </div>
                        <div className='notification-div '>
                            <div className='row'>
                                <div className='col-9'>
                                    <p className='noti-pppp'>New order received <span className='not-pp-name'>from XYZ</span></p>
                                </div>
                                <div className='col-3'>
                                    <p className='nott-p-timggg'>5 min ago</p>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </section>
        </>
    )
}

export default Notification_offcanvas