import React from "react";
import { Button } from "react-bootstrap";

const ButtonComponent = ({ onClick, className, children, variant, type }) => {
  return (
    <>
      <section className="ButtonMain">
        <Button
          type={type}
          onClick={onClick}
          className={className}
          variant={variant}
        >
          {children}
        </Button>
      </section>
    </>
  );
};

export default ButtonComponent;
