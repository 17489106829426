import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../utils/context";
import './PrivacyPolicy.css'
import { PrivacyPolicydata } from "../../../utils/apis/master/Master";
import parse from 'html-react-parser';
const PrivacyPolicy = () => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);


    const getDataAll = async () => {
        try {
            const response = await PrivacyPolicydata("MyWebsite");
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getDataAll();
    }, []);
    return (
        <>
            <section className='privacy-policy'>
                <div className='container'>
                    <div className='row'>

                        <div className='heading-holder'>
                            <h1>Privacy Policy</h1>
                            {data?.name ? parse(data.name) : ''}
                        </div>
                        {/* <div className='heading-holder'>
                           
                            <p>At Netpurti's , we are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information gathered through our B2B e-commerce platform.</p>
                        </div>
                        <div className='heading-holder'>
                            <h2>1. Information Collection:</h2>
                            <p>We may collect personal information such as name, email address, phone number, job title, company name, and billing address when you register for an account, place an order, or interact with our platform. Additionally, we may collect non-personal information such as browser type, IP address, and device information for analytical purposes.</p>
                        </div>
                        <div className='heading-holder'>
                            <h2>2. Use of Information:</h2>
                            <p>We use the collected information to provide and improve our services, process transactions, communicate with you, customize your experience, and comply with legal obligations. We may also use your information to send promotional emails and updates about our products and services, but you can opt out of receiving such communications at any time.</p>
                        </div>
                        <div className='heading-holder'>
                            <h2>3. Information Sharing:</h2>
                            <p>We may share your personal information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing you. These parties are obligated to keep your information confidential and are prohibited from using it for any other purpose. We may also disclose your information when required by law or to protect our rights, property, or safety, or that of others.</p>
                        </div>
                        <div className='heading-holder'>
                            <h2>4. Data Security:</h2>
                            <p>We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                        </div>
                        <div className='heading-holder'>
                            <h2>5. Cookies:</h2>
                            <p>Our website may use cookies and similar tracking technologies to enhance your browsing experience, analyze trends, administer the website, and gather demographic information. You can control the use of cookies through your browser settings, but please note that disabling cookies may affect the functionality of the website.</p>
                        </div>
                        <div className='heading-holder'>
                            <h2>6. Third-Party Links:</h2>
                            <p>Our website may contain links to third-party websites or services that are not operated by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                        </div>
                        <div className='heading-holder'>
                            <h2>7. Children's Privacy:</h2>
                            <p>Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If you believe that we have inadvertently collected information from a child, please contact us immediately.</p>
                        </div>
                        <div className='heading-holder'>
                            <h2>8. Changes to Privacy Policy:</h2>
                            <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on this page. We encourage you to review this Privacy Policy periodically for any updates.</p>
                        </div>
                        <div className='heading-holder'>
                            <h2>9. Contact Us:</h2>
                            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at [Your Contact Information].</p>
                        </div>
                        <div className='heading-holder'>
                            <p>By using our B2B e-commerce platform, you agree to the collection and use of information as outlined in this Privacy Policy.</p>
                        </div> */}
                    </div>
                </div>
            </section>

        </>
    )
}

export default PrivacyPolicy