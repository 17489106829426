import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./FrequentlyBoughtCrad.css";
import CustomisationModal from "../AllModal/CustomisationModal/CustomisationModal";
import { Product_Type } from "../../../../utils/common";
const FrequentlyBoughtCard = ({
  content,
  selling_price,
  mrp,
  orderdetails,
  image,
  imgClassName,
  className,
  textClassName,
  alt,
  IMG_URL,
  product_title,
  customization,
  customization_color,
  id,
}) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const [showCustomisationModal, setshowCustomisationModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className={className}>
        <img
          src={image}
          className={imgClassName}
          alt={alt}
          onClick={() => {
            navigate(`/b2bbuyer/b2b-productMain/${id}`);
          }}
        />
        <div className="d-flex justify-content-between product-description-section">
          <h4>{product_title} </h4>{" "}
          {Product_Type?.map((val, index) => {
            if (customization === val?.name && val.name !== "General") {
              return (
                <button
                  style={{
                    backgroundColor: `${customization_color} !important`,
                  }}
                  onClick={() => setshowCustomisationModal(true)}
                  className="btn-customize"
                >
                  {val.tag}
                </button>
              );
            }
            return null;
          }).filter((tag) => tag !== null)}
          {/* <button
            style={{ backgroundColor: `${customization_color} !important` }}
            onClick={() => setshowCustomisationModal(true)}
            className="btn-customize"
          >
            {" "}
            {Product_Type?.map((val, index) => {
              if (customization === val?.name) {
                return val.tag;
              }
              return null;
            }).filter((tag) => tag !== null)}
          </button> */}
        </div>

        <div
          onClick={() => {
            navigate(`/b2bbuyer/b2b-productMain/${id}`);
            console.log("sdasdasdasd");
          }}
          className="main-price"
        >
          <div>
            <p className="price">₹{selling_price}</p>
          </div>
          <div className="mrp-div">
            <span className="mrp-title">MRP : </span>
            <span className="mrp">₹{mrp}</span>
          </div>
        </div>
        <span className="discount">
          {mrp && selling_price
            ? `${(((mrp - selling_price) / mrp) * 100).toFixed(2)}% off`
            : ""}
        </span>

        <div className="text-holder">
          {/* <h4>{content}</h4>
          <p>₹ {mrp}</p>
          <p>
            {mrp && rupees
              ? `${((mrp - rupees) / mrp * 100).toFixed(2)}% off`
              : ''}
          </p>
          <p>₹{rupees}</p> */}
          <span>Min Order: {orderdetails} Pieces</span>
        </div>
      </div>

      <CustomisationModal
        show={showCustomisationModal}
        onHide={() => setshowCustomisationModal(false)}
      />
    </>
  );
};

export default FrequentlyBoughtCard;
