import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import JoditEditor from "jodit-react";
import Nav from "react-bootstrap/Nav";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tab from "react-bootstrap/Tab";
import "./ProductInfo.css";
import PreviewSubmitmodal from "../../PreviewSubmitmodal/PreviewSubmitmodal";
import Logout_modal from "../../logout-modal/Logout_modal";

const ProductInfo = ({ nextStep, prevStep }) => {
  const [modalShow, setModalShow] = useState(false);
  const [content, setContent] = useState("");

  const [logoutshow, setLogoutShow] = useState(false);
  return (
    <>
      <section className="product-info-sec">
        <div className="row">
          <div className="col-md-12">
            <div className="main-card">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="heighlight"
              >
                <Nav variant="pills" className="">
                  <Nav.Item>
                    <Nav.Link eventKey="heighlight">
                      Product Highlights
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-title">
                            Product Highlights
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="description">
                      Product Description
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-title">
                            Product Description
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="heighlight">
                    <div className="heighlight-box mb-5">
                      <p>Modal Name</p>
                      <h6>S2020</h6>
                      <p>Modal Number</p>
                      <h6>S2020 Mechanical</h6>
                      <p>Type</p>
                      <h6>Gaming Keyboard</h6>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="description">
                  <div className="heighlight-box mb-5">
                      <p>Modal Name</p>
                      <h6>S2020</h6>
                      <p>Modal Number</p>
                      <h6>S2020 Mechanical</h6>
                      <p>Type</p>
                      <h6>Gaming Keyboard</h6>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
            <div className="main-card">
              <div className="row">
                <div className="col-xxl-4 col-lg-4 col-md-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Manufacturing Capacity <span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-title">
                            The number of items your employees can produce in a specific period of time, like a month.
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Manufacturing Capacity "
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-lg-4 col-md-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Use Monthly Capacity <span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-category">
                            The capacity calculated for the monthly time frame according to the methodology based on Article 10 of the FCA Regulation.
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Supply Capacity a Month"
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-lg-4 col-md-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Meta Tag <span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-brand">Meta Tag is used for SEO purpose to reach out to the customers easily.</Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control type="text" placeholder="Enter Meta Tag" />
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-lg-4 col-md-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Meta Description <span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-title">Meta Description to inform and interests users with a short, relevant summary of what a particular page is about.</Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Meta Description "
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="common-steps-btn">
              
              <button
                type="button"
                onClick={() => setLogoutShow(true)}
                className="next-btn"
              >
                Submit
              </button>

            </div>

            <Logout_modal
              show={logoutshow}
              onHide={() => setLogoutShow(false)}
              title="Are you sure you the data you enter is correct for listing?"
              btnname="Confirm"
            />

            <PreviewSubmitmodal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductInfo;
