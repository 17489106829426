
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import './PasswordResetSuccessful.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const PasswordResetSuccessful = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='password-successful'
        >
            <Modal.Body>
                <div className='success-reset'>
                    <h3>Password Reset</h3>
                    <p>Your password has been successfully reset</p>
                    <div className='text-center'>
                        <Link to='/mywebsites/login'><button className='back-login'><FontAwesomeIcon icon="fa-solid fa-arrow-left-long" /> Back to login</button></Link> 
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PasswordResetSuccessful