import React from 'react'
import SuccessFulLotti from '../../MyWebsiteAnimation/SuccessFulLotti'
import Modal from 'react-bootstrap/Modal';
import './PaymentSuccessful.css'

const PaymentSuccessful = (props) => {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='payment-successful'
        >
            <Modal.Body>
                <SuccessFulLotti/>
                <p className='payment-success text-center'>Payment Successfully!</p>
            </Modal.Body>
        </Modal>
    )
}

export default PaymentSuccessful