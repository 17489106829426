import React, { useContext, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
// import Tab from "react-bootstrap/Tab";
import "react-tabs-scrollable/dist/rts.css";
import Brand from "../../InfoMaster/Brand/Tables";
import FAQ from "../../InfoMaster/Faq/Table";
import SubFaq from "../../InfoMaster/SubFaq/Table";
// import WorkingType from "../InfoMaster/WorkingType/Table";
import Category from "../../InfoMaster/Category/Table";
import SubCategory from "../../InfoMaster/SubCategory/Table";
import ChildCategory from "../../InfoMaster/ChildCategory/Table";
import ChildSubCategory from "../../InfoMaster/ChildSubCategory/Table";
import Blogs from "../../InfoMaster/Blogs/Tables";
// import CouponCodes from "../../InfoMaster/CouponCodes/Table";
// import TaxType from "../InfoMaster/Tax_Types/Tables";
// import TaxPercentage from "../InfoMaster/Tax_Percentage/Tables";
import WebHeader from "../../InfoMaster/WebHeader/Tables";
import AppSetup from "../../InfoMaster/AppSetup/Edit";
// import ContactUs from "../InfoMaster/contact_us/Tables";
import Users from "../../InfoMaster/users/Tables";
import CrazyDeals from "../../InfoMaster/CrazyDeals/Table";
import ExclusiveCollection from "../../InfoMaster/ExclusiveCollection/Table";
import Attribute from "../../InfoMaster/Attribute/Table";
import SubAttribute from "../../InfoMaster/SubAttribute/Table";
import AttributePackaging from "../../InfoMaster/AttributePackaging/Table";
import ShopTrends from "../../InfoMaster/ShopTrends/Table";
import HomePageBanner from "../../InfoMaster/HomePageBanner/Table";
import AboutUsBanner from "../../InfoMaster/banner/Tables";
import OurStory from "../../InfoMaster/OurStory/Tables";
import HappyCustomer from "../../InfoMaster/HappyCustomer/Tables";
import WhyChoose from "../../InfoMaster/whyChoose/Tables";
import PopularProduct from "../../InfoMaster/PopularProduct/Table";
import SaleBanner from "../../InfoMaster/SaleBanner/Table";
import CategoryBanner from "../../InfoMaster/CategoryBanner/Table";
import UserMotivation from "../../InfoMaster/UserMotivation/Table";
import ProductDealType from "../../InfoMaster/ProductDealType/Table";
import ProductDeal from "../../InfoMaster/ProductDeal/Table";
import OurPerformance from "../../InfoMaster/OurPerformnce/Tables";
import SocialMedia from "../../InfoMaster/SocialMedia/Table";
import PrivacyPolicy from "../../InfoMaster/PrivacyPolicy/Edit";
import TermCondition from "../../InfoMaster/TermCondition/Edit";
import "./Setting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faArrowRightArrowLeft,
  faCircleQuestion,
  faCodeBranch,
  faGlobe,
  faList,
  faListAlt,
  faQuestion,
  faTableCellsLarge,
  faTree,
  faUser,
  faUsers,
  faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import {
  faBandcamp,
  faBloggerB,
  faIdeal,
  faOptinMonster,
  faProductHunt,
} from "@fortawesome/free-brands-svg-icons";
import { Context } from "../../../../../../utils/context";


const Setting = () => {
  const [filter, setFilter] = useState("");
  const [activeTab, setActiveTab] = useState("0");

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };
  const tabsArray = [
    {
      key: 0,
      name: (
        <>
          <FontAwesomeIcon icon={faBandcamp} />
          <span className="name-text">Brand</span>
        </>
      ),
      link: "masters/brand",
      component: <Brand />,
      tooltip: "Brand",
      section: "brands",
    },
    {
      key: 1,
      name: (
        <>
          <FontAwesomeIcon icon={faCircleQuestion} />
          <span className="name-text">FAQ</span>
        </>
      ),
      link: "masters/faq",
      component: <FAQ />,
      tooltip: "FAQ",
      section: "faq",
    },
    {
      key: 2,
      name: (
        <>
          <FontAwesomeIcon icon={faQuestion} />
          <span className="name-text">Sub FAQ</span>
        </>
      ),
      link: "masters/sub-faq",
      component: <SubFaq />,
      tooltip: "Sub FAQ",
      section: "faq",
    },

    {
      key: 3,
      name: (
        <>
          <FontAwesomeIcon icon={faBloggerB} />
          <span className="name-text">Blogs</span>
        </>
      ),
      link: "masters/blogs",
      component: <Blogs />,
      tooltip: "Blogs",
      section: "blog",
    },
    {
      key: 4,
      name: (
        <>
          <FontAwesomeIcon icon={faUser} />
          <span className="name-text">Users</span>
        </>
      ),
      link: "masters/users",
      component: <Users />,
      tooltip: "Users",
      section: "users",
    },

    {
      key: 5,
      name: (
        <>
          <FontAwesomeIcon icon={faIdeal} />
          <span className="name-text">Crazy Deals</span>
        </>
      ),
      link: "masters/crazy-deals",
      component: <CrazyDeals />,
      tooltip: "Crazy Deals",
      section: "crazydeals",
    },
    {
      key: 6,
      name: (
        <>
          <FontAwesomeIcon icon={faListAlt} />
          <span className="name-text">Exclusive Collection</span>
        </>
      ),
      link: "masters/exclusive-collections",
      component: <ExclusiveCollection />,
      tooltip: "Exclusive Collection",
      section: "exclusive collections",
    },

    {
      key: 7,
      name: (
        <>
          <FontAwesomeIcon icon={faWindowRestore} />
          <span className="name-text">Our Story</span>
        </>
      ),
      link: "masters/about-our-story",
      component: <OurStory />,
      tooltip: "Our Story",
      section: "our story",
    },
    {
      key: 8,
      name: (
        <>
          <FontAwesomeIcon icon={faUsers} />
          <span className="name-text">Happy Customer</span>
        </>
      ),
      link: "masters/happy-customer",
      component: <HappyCustomer />,
      tooltip: "Happy Customer",
    },
    {
      key: 9,
      name: (
        <>
          <FontAwesomeIcon icon={faOptinMonster} />
          <span className="name-text">Why Choose Us ?</span>
        </>
      ),
      link: "masters/why-choose",
      component: <WhyChoose />,
      tooltip: "Why Choose Us ?",
      section: "why choose us",
    },
    {
      key: 10,
      name: (
        <>
          <FontAwesomeIcon icon={faTree} />
          <span className="name-text">Shop Trends</span>
        </>
      ),
      link: "masters/shop-trends",
      component: <ShopTrends />,
      tooltip: "Shop Trends",
      section: "shop trends",
    },

    {
      key: 11,
      name: (
        <>
          <FontAwesomeIcon icon={faProductHunt} />
          <span className="name-text">Popular Product</span>
        </>
      ),
      link: "masters/popular_product",
      component: <PopularProduct />,
      tooltip: "Popular Product",
      section: "popular products",
    },
    {
      key: 12,
      name: (
        <>
          <FontAwesomeIcon icon={faUser} />
          <span className="name-text">User Motivation</span>
        </>
      ),
      link: "masters/user-motivation",
      component: <UserMotivation />,
      tooltip: "User Motivation",
      section: "user motivations",
    },

    {
      key: 13,
      name: (
        <>
          <FontAwesomeIcon icon={faUser} />
          <span className="name-text">Product Deal Type</span>
        </>
      ),
      link: "masters/product-deal-type",
      component: <ProductDealType />,
      tooltip: "Product Deal Type",
      section: "product deals",
    },

    {
      key: 14,
      name: (
        <>
          <FontAwesomeIcon icon={faUser} />
          <span className="name-text">Product Deal</span>
        </>
      ),
      link: "masters/product-deal",
      component: <ProductDeal />,
      tooltip: "Product Deal",
      section: "product deals",
    },
    {
      key: 15,
      name: (
        <>
          <FontAwesomeIcon icon={faGlobe} />
          <span className="name-text">Our Performance</span>
        </>
      ),
      link: "masters/our-performance",
      component: <OurPerformance />,
      tooltip: "Our Performance",
      section: "our performance",
    },
    {
      key: 16,
      name: (
        <>
          <FontAwesomeIcon icon={faArrowRightArrowLeft} />
          <span className="name-text">Privacy Policy</span>
        </>
      ),
      link: "masters/privacy-policy",
      component: <PrivacyPolicy />,
      tooltip: "Privacy Policy",
      section: "privacy policy",
    },
    {
      key: 17,
      name: (
        <>
          <FontAwesomeIcon icon={faArrowRightArrowLeft} />
          <span className="name-text">Term Condition</span>
        </>
      ),
      link: "masters/term-condition",
      component: <TermCondition />,
      tooltip: "Term Condition",
      section: "terms and conditions",
    },
    {
      key: 18,
      name: (
        <>
          <FontAwesomeIcon icon={faAddressCard} />
          <span className="name-text">About Us</span>
        </>
      ),
      link: "masters/about-us-banner",
      component: <AboutUsBanner />,
      tooltip: "About Us",
      section: "about us",
    },
  ];

  // const tabContent = [
  //   { key: 0, component: <Brand /> },
  //   { key: 1, component: <FAQ /> },
  //   { key: 2, component: <SubFaq /> },
  //   { key: 3, component: <Blogs /> },
  //   { key: 4, component: <Users /> },
  //   { key: 5, component: <CrazyDeals /> },
  //   { key: 6, component: <ExclusiveCollection /> },
  //   { key: 7, component: <OurStory /> },
  //   { key: 8, component: <HappyCustomer /> },
  //   { key: 9, component: <WhyChoose /> },
  //   { key: 10, component: <ShopTrends /> },
  //   { key: 11, component: <PopularProduct /> },
  //   { key: 12, component: <UserMotivation /> },
  //   { key: 13, component: <SocialMedia /> },
  //   { key: 14, component: <ReturnPolicy /> },
  //   { key: 15, component: <AboutUsBanner /> },
  // ];

  // const [activeTab, setActiveTab] = useState(0);
  // const onTabClick = (e, index) => {
  //   console.log(e);
  //   setActiveTab(index);
  // };
  // const tabsArray = [...Array(20).keys()];

  const renderTooltip = (props) => (
    <Tooltip {...props}>{props.tooltip}</Tooltip>
  );



  const closeSidebar = () => {
    setSidebar(true);

    setOuterSidebar(true);

    setInnerSidebar(false);
  };

  const {
    sidebar,
    setSidebar,
    outerSidebar,
    setOuterSidebar,
    innerSidebar,
    setInnerSidebar,
    domainData, setDomainData
  } = useContext(Context);

  return (
    <>
      {/* <Tabs activeTab={activeTab} onTabClick={onTabClick}>
        {tabsArray.map((item, index) => (
          <Tab key={item.eventKey} eventKey={item.key}>
            {item.name}
          </Tab>
        ))}
      </Tabs>

      {tabContent.map((item) => (
        <TabScreen key={item.key} activeTab={activeTab} index={item.key}>
          {item.component}
        </TabScreen>
      ))} */}
      <div className="sidebar-tab-holder">
        <Tab.Container
          className="sidebar-tab-holder"
          id="left-tabs-example"
          activeKey={activeTab}
          onSelect={(e) => setActiveTab(e)}
        >
          <Row>
            <div
              // sm={1}
              className={`scale-up-hor-left ${!innerSidebar
                ? "col-width-expand col-xxl-2 col-xl-3 col-md-3"
                : "sidebar-adjust col-width col-xxl-1 col-xl-1 col-md-1"
                }`}

            // className={`  scale-up-hor-left ${
            //       sidebar ? "col-width-expand" : "col-width"
            //     }`}
            >
              <Nav variant="pills" className="flex-column">
                {tabsArray.map((item) => (

                  domainData?.sections?.includes(item.section) && (
                    <OverlayTrigger
                      key={item.key}
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip({ tooltip: item.tooltip })}
                    >
                      <Nav.Item onClick={closeSidebar}>
                        <Nav.Link eventKey={item.key}>{item.name}</Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                  )


                ))}
              </Nav>
            </div>
            <div
              // sm={11}
              className={`${!innerSidebar
                ? "col2-width col-xxl-10 col-xl-9 col-md-9"
                : "col2-width col-xxl-11 col-xl-11 col-md-11"
                }`}
            >
              {" "}
              <Tab.Content>
                {tabsArray.map((item) => (
                  <Tab.Pane eventKey={item.key} key={item.key}>
                    {item.component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </div>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};

export default Setting;
