import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonComponent from "../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import "./SelectAdress.css";
import AddAdress from "./AddAdress";
import EditAdress from "./EditAdress";
import {
  btobAddressList,
  btobAddressDelete,
} from "../../../../../utils/apis/btb/Shipping_Address";

export const SelectAdress = (props) => {
  //   useEffect(() => {
  //     props.getData;
  //   }, []);

  console.log(props.address);
  const [modalShownew, setModalShownew] = useState(false);
  const [modalShowedit, setModalShowedit] = useState(false);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="adresss-modals"
      >
        <div className="desktop-view">
          <div className="flex-div px-4 py-3 ">
            <h1>Select shipping address</h1>
            <div className="flex-div me-2">
              <ButtonComponent
                className="add-adress-btn"
                onClick={() => {
                  setModalShownew(true);
                  props.onHide();
                }}
              >
                Add new address
              </ButtonComponent>
              <FontAwesomeIcon
                icon="fa-solid fa-xmark"
                className="close-icon"
                onClick={props.onHide}
              />
            </div>
          </div>
        </div>

        <div className="mobile-view">
          <div className="flex-div px-4 py-3 ">
            <div>
              <h1>Select shipping address</h1>
              <ButtonComponent className="add-adress-btn">
                Add new address
              </ButtonComponent>
            </div>
            <FontAwesomeIcon
              icon="fa-solid fa-xmark"
              className="close-icon"
              onClick={props.onHide}
            />
          </div>
        </div>
        <hr className="my-0 mx-3" />

        <Modal.Body>
          {props.address?.map((data, index) => (
            <div className="existing-user-div" key={index}>
              <div className="flex-div">
                <button className="res-btn">{data?.address_type}</button>
                <div>
                  <FontAwesomeIcon
                    icon="fa-solid fa-pen"
                    className="icons"
                    onClick={() => {
                      setModalShowedit(data?.id);
                      props.onHide();
                    }}
                  />
                  <FontAwesomeIcon
                    icon="fa-solid fa-trash"
                    onClick={async () => {
                      await btobAddressDelete(data?.id);
                      await props.getData();
                      await props.onHide();
                    }}
                    className="icons"
                  />
                </div>
              </div>
              <h6>{data?.name}</h6>
              <p>
                {data?.address} {data?.city?.name}, {data?.state?.name},{" "}
                {data?.pincode?.name}, {data?.country?.name}.
              </p>
              <p>{data?.phone}</p>
            </div>
          ))}
        </Modal.Body>

        <div className="text-end m-2">
          <Button onClick={props.onHide} className="btn-cancle">
            Cancel
          </Button>
          <Button onClick={props.onHide} className="conform-btn">
            Confirm
          </Button>
        </div>
      </Modal>
      <AddAdress
        show={modalShownew}
        getData={props.getData}
        onHide={() => setModalShownew(false)}
      />

      <EditAdress
        show={modalShowedit}
        getData={props.getData}
        onHide={() => setModalShowedit(false)}
      />
    </>
  );
};
