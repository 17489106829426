import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllFaq = async (currentPage, perPage, search, searchStatus, domain_id) => {
  try {
    let url = `/seller/outer-website/masters/faq?page=${currentPage}&per_page=${perPage}&term=${search}&domain_id=${domain_id}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`
    }
    return await getData(
      url
    );
  } catch (error) {
    console.log(error);
  }
};

export const changeFaqStatus = async (id) => {
  try {
    return await editStatusData(`/seller/outer-website/masters/faq/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteFaq = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/outer-website/masters/faq/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const addFaq = async (finalData) => {
  try {
    return await postData(`/seller/outer-website/masters/faq`, finalData);
  } catch (error) {
    console.log(error);
  }
};

export const editFaq = async (id, finalData) => {
  try {
    return await postData(`/seller/outer-website/masters/faq/${id}`, finalData);
  } catch (error) {
    console.log(error);
  }
};

export const getsingleFaq = async (id) => {
  try {
    return await getData(`/seller/outer-website/masters/faq/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
