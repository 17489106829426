import React from 'react';
import './BuilderAdd.css';
import { Container, Row, Col } from 'react-bootstrap';
import ButtonComponent from '../../CommonComponents/ButtonComponent/ButtonComponent';

const builderAddOns = [
    {
        title: 'Extra MS365 Mailboxes',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec.',
        price: 40,
        frequency: 'Per Month'
    },
    {
        title: 'Extra MS365 Mailboxes',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec.',
        price: 40,
        frequency: 'Per Month'
    },
];

const BuilderAdd = () => {
    return (
        <section className='builder-add mt-5'>
            <Container>
                <Row className="justify-content-center">
                    <div className='add-ons-title text-center'>
                        <h3 className='maintenance-title'>Netpurti Site builder Add-ons</h3>
                        <p className=''>Enhance your website with powerful tools through Netpurti Site builder Add-ons. Seamlessly integrate e-commerce, social media feeds, and more to maintain a stunning online presence effortlessly.</p>
                    </div>
                    {builderAddOns.map((addOn, index) => (
                        <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6} xs={12}  >
                            <div className='cream-bg'>
                                <h3>{addOn.title}</h3>
                                <p className='add-on-txt'>{addOn.description}</p>
                                <div className='plan-txt-number'>{`$${addOn.price}`}</div>
                                <div className='plan-txt-month'>{addOn.frequency}</div>
                                <div className='text-center'><ButtonComponent className='contact-me mt-4'>Contact Me</ButtonComponent></div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default BuilderAdd;
