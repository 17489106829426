import React,{useState,useContext,useEffect} from 'react'
import Subscription from './Subscription/Subscription'
import { SubscriptionGet } from '../../../../utils/apis/myWebsite/mywebsite';

const NewPlanPricing = () => {

  const [subscriptionData, setSubscriptionData] = useState();
  
  const getSubscriptionData = async () => {
    const res = await SubscriptionGet();
    if (res?.success) {
      setSubscriptionData(res?.data);
    }
  }

  useEffect(() => {
    getSubscriptionData();
  }, [])


    return (
        <>
            <Subscription subscriptionData={subscriptionData}/>
        </>
    )
}

export default NewPlanPricing