import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import CreateAccountSeccseccfullyModal from "../../../CommonForAll/CreateAccountSeccseccfullyModal/CreateAccountSeccseccfullyModal";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../utils/context";
import { RegxExpression } from "../../../../../src/utils/common";
import Select from "react-select";
import classNames from "classnames";
import { mediaPasswordPost } from "../../../../utils/apis/media/media";

import PasswordChecklist from "react-password-checklist"
import { getValue } from "@testing-library/user-event/dist/utils";
import Cookies from "js-cookie";
import ComingSoonModal from "../../../CommonForAll/CommingSoonModal/CommingSoonModal";

function Stepeight({ nextStep, prevStep, mediaDetails, mediaID }) {
  const { getData, postData, IMG_URL } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEye);
  const [iconTwo, setIconTwo] = useState(faEye);
  const [messageShow, setMssageShow] = useState(false);


  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      re_password: "",

    }
  });
  const navigate = useNavigate();
  const [matcherror, setmatchErrors] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);
  const onSubmit = async (data) => {

    console.log(data);
    data.media_personal_details_id = mediaID;
    // data.percentage = sellerPercentageSend.stepSix; 
    if (data.password === data.re_password) {
      const res = await mediaPasswordPost(data);
      if (res?.success) {
        setmatchErrors("");

        setModalShow2(true);

        //show coming soon modal
        setTimeout(() => {
          setModalShow2(false);
          setMssageShow(true)

        }, 3000);

        setTimeout(() => {
          Cookies.remove('media_id');
          navigate("/b2bbuyer/buyer-home");
        }, 6000);
      }
    } else {
      setmatchErrors("Password Must Match");
    }


  };
  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")

  return (
    <>
      <div className="personal-details-form  employee-details-step">
        <div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row stepsixform">
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Enter Password*</Form.Label>
                  <div className="StepsixPassword">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password*"
                      className={classNames("", {
                        "is-invalid": errors?.password,
                      })}
                      name="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Password must be at least 8 characters long",
                        },
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                          message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                        }
                      })}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <PasswordChecklist
                      rules={["minLength", "specialChar", "number", "capital", "match"]}
                      minLength={8}
                      value={password}
                      valueAgain={passwordAgain}
                      onChange={(isValid) => { }}
                    />

                    <FontAwesomeIcon
                      icon={iconOne}
                      className="StepsixeditIcon"
                      onClick={() => togglePasswordVisibility("password")}
                    />
                    {errors?.password && (
                      <span className="text text-danger">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="reenterPassword">
                  <Form.Label>Re-Enter Password*</Form.Label>
                  <div className="StepsixPassword">
                    <Form.Control
                      type={showPasswordOne ? "text" : "password"}
                      placeholder="Re-Enter Password*"
                      className={classNames("", {
                        "is-invalid": errors?.re_password,
                      })}
                      name="re_password"
                      {...register("re_password", {
                        required: "Re-Password is required",
                      })}
                      onChange={e => setPasswordAgain(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={iconTwo}
                      className="StepsixeditIcon"
                      onClick={() =>
                        togglePasswordVisibility("reenterPassword")
                      }
                    />
                    {errors?.re_password && (
                      <span className="text text-danger">
                        {errors.re_password.message}
                      </span>
                    )}
                  </div>
                </Form.Group>
              </div>
              {matcherror && (
                <span className="text text-danger">{matcherror}</span>
              )}
            </div>
            <div className="text-end btnMainn apply_now_btn">
              {/* <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p> */}

              <div className="registerContinueMain">
                <Button onClick={prevStep} className="back-btn">
                  Back
                </Button>
              </div>

              <div className="registerContinueMain">
                <Button className="tabs-btn" type="submit">
                  Submit
                </Button>
              </div>

              <CreateAccountSeccseccfullyModal
                show={modalShow2}
                onHide={() => setModalShow2(false)}
              />
              <TermsAndConditionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              {/* <CreateAccountSuccess
                show={modalSuccess}
                onHide={() => setModalSuccess(false)}
              /> */}
            </div>
          </Form>
          <ComingSoonModal
            onHide={() => setMssageShow(false)}
            show={messageShow}
            heading="Comming soon..."
            text="Website is under construction"
          />
        </div>
        <div className="col-md-12"></div>
      </div>
    </>
  );
}

export default Stepeight;
