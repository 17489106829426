import React, { useEffect, useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import classNames from "classnames";
import { Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";

import Select from "react-select";
import { Context } from "../../../../../../utils/context";
import { Errors, Placeholders } from "../../../../../../utils/errors";
import {
  IndustryType,
  IndustrySubCategory,
} from "../../../../../../utils/apis/master/Master";
import {
  industryDetailNamePost,
  getIndustryDetailName,
} from "../../../../../../utils/apis/brand/Brand";

function IndustryDetails({ userID, setSuccessModalShow, setIndustry }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      data: [
        {
          industry_id: "",
          industry_sub_category_id: "",
        },
      ],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "data",
  });

  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);

  const [industryCategory, setIndustryCategory] = useState([]);
  const [industrySubCategory, setIndustrySubCategory] = useState([]);

  const getMasters = async () => {
    {
      const res = await IndustryType();
      if (res?.success) {
        var data = await Select2Data(res.data, "industry_id", false);
        setIndustryCategory(data);
      }
    }
  };

  const handleCategoryChange = async (data, index) => {
    const res = await IndustrySubCategory(data);
    if (res?.success) {
      var data = await Select2Data(res.data, "industry_sub_category_id", false);
      const updatedIndustrySubCategory = [...industrySubCategory];
      updatedIndustrySubCategory[index] = data;
      setIndustrySubCategory(updatedIndustrySubCategory);
    }
  };

  // s_personal_details_id: req.body.s_personal_details_id,

  const onSubmit = async (d, index) => {
    console.log(getValues(), "fsddsfv");
    const keys = Object.keys(d.data);
    var data = [];
    keys.forEach((key) => {
      const value = d.data[key];
      var cityData = {
        industry_id: value.industry_id.value,
        industry_sub_category_id: value.industry_sub_category_id.value,
      };
      data.push(cityData);
    });

    var sendData = {
      data: data,
      b_personal_details_id: userID,
    };

    console.log(sendData);
    const res = await industryDetailNamePost(sendData);
    if (res?.success) {
      console.log(res, "addresspost");
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
        getIndustryDetail();
      }, 2000);
    }
  };

  const getIndustryDetail = async () => {
    const res = await getIndustryDetailName(userID);
    if (res?.success) {
      if (res.data.length > 0) {

        await setIndustry(res.data);
        var data = [];
        res.data.forEach((item, index) => {
          let nextData = {
            industry_id: {
              value: item.industry_category?.id,
              label: item.industry_category?.name,
            },
            industry_sub_category_id: {
              value: item.industry_sub_category?.id,
              label: item.industry_sub_category?.name,
            },
          };
          data.push(nextData);
        });

        reset({ data: data });

      } else {
        reset({
          data: [
            {
              industry_id: "",
              industry_sub_category_id: "",
            },
          ],
        });
      }
    }
  };

  useEffect(() => {
    getMasters();
    getIndustryDetail();
  }, []);
  return (
    <>
      <div className="tab-form-container firstthreerrr">
        <Form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((item, index) => (
            <div className="row">
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Category</Form.Label>

                  <Controller
                    name={`data[${index}].industry_id`}
                    {...register(`data[${index}].industry_id`, {
                      required: Errors.category,
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputStyle={{
                          borderColor: errors.industry_id ? "red" : "green",
                        }}
                        // styles={{
                        //   control: (baseStyles) => ({
                        //     ...baseStyles,
                        //     borderColor: errors.industry_id
                        //       ? "red"
                        //       : baseStyles,
                        //   }),
                        // }}
                        {...field}
                        options={industryCategory}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          handleCategoryChange(selectedOption.value, index);
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Sub Category</Form.Label>

                  <Controller
                    name={`data[${index}].industry_sub_category_id`}
                    {...register(`data[${index}].industry_sub_category_id`, {
                      required: Errors.sub_category,
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputStyle={{
                          borderColor: errors.industry_sub_category_id
                            ? "red"
                            : getValues('industry_sub_category_id')
                              ? "green"
                              : "",
                        }}

                        {...field}
                        options={
                          industrySubCategory[index] &&
                          industrySubCategory[index]
                        }
                      />
                    )}
                  />
                </Form.Group>
              </div>
              {index !== 0 && (
                <div className="addremoveadress">
                  <div className="text-end Addnewadresstxt">
                    <p className="" onClick={() => remove(index)}>
                      Remove Category{" "}
                      <FontAwesomeIcon icon="fa-solid fa-minus" />
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))}

          <div className="text-end">
            <Button className="btn StepfoursaveBtn" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>

      <div className="addremoveadress">
        <div className="text-end Addnewadresstxt">
          <p
            onClick={() =>
              append({
                industry_id: "",
                industry_sub_category_id: "",
              })
            }
          >
            Add New Industry <FontAwesomeIcon icon="fa-solid fa-plus" />
          </p>
        </div>
      </div>
    </>
  );
}

export default IndustryDetails;
