import React, { useContext, useEffect } from 'react'
import './Seller_profile.css'
import Form from 'react-bootstrap/Form';
import MobileOtpModal from '../../../../CommonForAll/Mobile_otp_modal/MobileOtpModal';
import EmailOtpModal from '../../../../CommonForAll/Email_otp_modal/EmailOtpModal';
import { Context } from '../../../../../utils/context';

const Seller_profile = () => {

    const [modalShow, setModalShow] = React.useState(false);
    const [modalShow2, setModalShow2] = React.useState(false);


    const {userdata} = useContext(Context);


    const getProfile = async () => {
        
    } 

    useEffect(() => {
            getProfile();
    },[]);  

    return (
        <>
            <section className='Seller_profile'>
                <h4>Seller Profile</h4>

                <div className='form-main-divvv mt-5'>
                    <Form>
                        <div className='row'>
                        <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your address" />
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your address" />
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Contact No.</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your number" />
                                    <button className='send-otp-btt' type='button' onClick={() => setModalShow(true)}>Send Otp</button>
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your email" />
                                    <button className='send-otp-btt' type='button' onClick={() => setModalShow2(true)}>Send Otp</button>
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your address" />
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Flat no</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your flat no" />
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Town</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your town" />
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>City</Form.Label>
                                    <Form.Select aria-label="Default select example">
                                        <option>Select your select</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>State</Form.Label>
                                    <Form.Select aria-label="Default select example">
                                        <option>Select your State</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select aria-label="Default select example">
                                        <option>Select your Country</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your pincode" />
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div className='row'>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your bank name" />
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Ifsc Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your Ifsc code" />
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Bank Account No</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your bank account no" />
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Branch Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your branch name" />
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <Form.Label>Micr Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your micr_code" />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-xl-3 col-md-4 mb-3'>
                                <div className='form-group'>
                                    <button className='submitbtn'>Save</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>

            </section>

            <MobileOtpModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            <EmailOtpModal
                show={modalShow2}
                onHide={() => setModalShow2(false)}
            />

        </>
    )
}

export default Seller_profile