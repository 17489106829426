import React from 'react'
import './Api_documentattions.css'

import Api_list from './api-list/Api_list'
const Api_documentattions = () => {
    return (

        <>
            <Api_list />

        </>
    )
}

export default Api_documentattions