// InnerPageTwo.js

import React, { useState, Fragment, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import HomeAddress from "./Industry-Details-Tab-Forms/HomeAddress/HomeAddress";
import ShopAddress from "./Industry-Details-Tab-Forms/ShopAddress/ShopAddress";
import BranchAddress from "./Industry-Details-Tab-Forms/BranchAddress/BranchAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { Errors, Placeholders } from "../../../../../utils/errors";
import SuccessModal from "../../../../CommonForAll/SuccessModal/SuccessModal.js";
 
 
import {
  FirmType,
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../../utils/apis/master/Master";
import { Select2Data } from "../../../../../utils/common";
import { Context } from "../../../../../utils/context";
import classNames from "classnames";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";

import {
  firmDetailNamePost,
  getFirmDetailName,
  firmAddressDetailPost,
  getfirmAddressDetail,
  firmDetailsPost,
  getfirmDetails,
} from "../../../../../utils/apis/checker/checker";

const InnerPageTwo = ({
  setPage,
  handleBack,
  prevInnerPage,
  handleSaveAndNext,
  activeTab,
  navItems,
  handleTabClick,
  innerNext,
  checkerDetails,
  checkerID,
}) => {
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [addressCount, setAddressCount] = useState(1);

  const [firmType, setFirmType] = useState([]);
  const [successModalShow, setSuccessModalShow] = useState(false);



  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const onFirmNameSubmit = async (d) => {
    console.log(d, "formNameSubmit");
   
    d.firm_type_id = d.firm_type_id?.value;
    d.c_personal_details_id = checkerID;
    if (firmDetailNameId != "") {
      d.id = firmDetailNameId;
    }
    const res = await firmDetailNamePost(d);
    if (res?.success) {
      console.log(res);
      getFirmDetailNames();
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  };


  const [addId, setAddId] = useState("");


  const getMasters = async () => {
    {
      const res = await FirmType();
      if (res?.success) {
        const data = await Select2Data(res.data, "firm_type_id", false);
        setFirmType(data);
      }
    }
  };

  const [firmDetailNameId, setFirmDetailNameId] = useState("");
  const getFirmDetailNames = async () => {
    const res = await getFirmDetailName(checkerID);
    if (res?.success) {
      setFirmDetailNameId(res.data.id);
      reset(res.data);
    }
  };

  useEffect(() => {
    getMasters();
    getFirmDetailNames();
  }, []);

  
  const [error, setErrors] = useState("");
  const handleNext = () => {
    if(addId && firmDetailNameId){
      setPage(2);
    }else{
      setErrors("Please Fill the form.");
    }
  }



  const getFirmTypes = async () => {
    const res = await FirmType();
    if (res?.success) {
      setFirmType(await Select2Data(res.data, "firm_type_id"));
    }
  };

  useEffect(() => {
    getMasters();
    getFirmDetailNames();
    getFirmTypes();
  }, []);
  return (
    <div className="form-container">
      <h6 className="ms-3 firmmmdd">
        {" "}
        <FontAwesomeIcon
          className="left-arrow  me-2"
          icon="fa-solid fa-arrow-left "
          onClick={handleBack} // Call prevInnerPage function
        />
        Firm Details
      </h6>
      <div className="tab-form-container firstthreerrr">
        <Form onSubmit={handleSubmit(onFirmNameSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Enter the firm name</Form.Label>
                {/* <Form.Control type="text" placeholder="Enter firm name" /> */}
                <Form.Control
                  className={classNames("", {
                    "is-invalid": errors?.name,
                    "is-valid": getValues('name'),
                  })}
                  type="text"
                  {...register("name", {
                    required: Errors.firmName,
                  })}
                  placeholder={Placeholders.firmName}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Select your firm type</Form.Label>
                <Controller
                  name="firm_type_id"
                  {...register("firm_type_id", {
                    required: Errors.firmType,
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      
                      inputStyle={{
                        borderColor: errors.firm_type_id
                          ? "red"
                          : getValues('firm_type_id')
                          ? "green"
                          : "",
                      }}
                      {...field}
                      options={firmType}
                    />
                  )}
                />
              </Form.Group>
            </div>
          </div>
          <div className="text-end">
            <Button className="btn StepfoursaveBtn" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>

      <h6 className="ms-3 firmmmdd">Firm Address Details</h6>
      <HomeAddress 
      setSuccessModalShow={setSuccessModalShow} 
      checkerID={checkerID}
       firmDetailNameId={firmDetailNameId}
       addId={addId}
       setAddId={setAddId}
      />


      

      <div className="education-step-save-and-next">
      <div className="apply-btn-holder apply_now_btn  text-end">
         <span className="text-danger">{error}</span>
        </div>
        <div className="apply-btn-holder apply_now_btn  text-end">
          <Button
            className="back-btn"
            onClick={handleBack}
          >
            Back
          </Button>

          <Button className="tabs-btn" onClick={handleNext}>
            Register and Continue
          </Button>

          <SuccessModal show={successModalShow} />
        </div>
      </div>
    </div>
  );
};

export default InnerPageTwo;
