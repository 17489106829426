import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const mediaPercentageSend = {
  stepOne: 0,
  stepTwo: 10,
  stepThree: 30,
  stepFour: 50,
  stepFive: 60,
  stepSix: 70,
  stepseven: 80,
  stepeight: 85,
  stepnine: 99,
};

export const mediaPercentage = [0, 10, 30, 50, 60, 70, 80, 85, 99];

export const mediaPersonalDetailsPost = async (data) => {
  try {
    const res = await postData(
      `/media/media-create/media-personal-details`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const mediaPasswordPost = async (data) => {
  try {
    const res = await postData(
      `/media/media-create/media-personal-details/password`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

// export const getSectorCategory = async (user_id) => {
//   try {

//     const res = await getData(`/seller/seller-create/s-sector-category/${user_id}`)

//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const firmDetailsPost = async (data) => {
  try {
    const res = await postData(`/media/media-create/media-firm-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getfirmDetails = async (mediaId) => {
  try {
    const res = await getData(
      `/media/media-create/media-firm-details/${mediaId}`
    );
    console.log("res firm :-", mediaId);
    console.log("res firm :-", res);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmDetailNamePost = async (data) => {
  try {
    const res = await postData(
      `/media/media-create/media-firm-details/name`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getFirmDetailName = async (user_id) => {
  try {
    const res = await getData(
      `/media/media-create/media-firm-details/name/${user_id}`
    );
    console.log("res firm :-", user_id);
    console.log("res firm :-", res);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmAddressDetailPost = async (data) => {
  try {
    const res = await postData(
      `/media/media-create/media-communication-address`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmAddressDetail = async (user_id) => {
  try {
    const res = await getData(
      `/media/media-create/media-communication-address/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmBankDetailPost = async (data) => {
  try {
    const res = await postData(`/media/media-create/media-bank-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmBankDetail = async (data) => {
  try {
    const res = await getData(`/media/media-create/media-bank-details/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const mediaBranchDetailPost = async (data) => {
  try {
    const res = await postData(
      `/media/media-create/media-branch-details`,
      data
    );
    console.log("Data to send in branch Details :- ", res.data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getMediaBranchDetail = async (data) => {
  try {
    const res = await getData(
      `/media/media-create/media-branch-details/${data}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const mediaBranchAddDetailPost = async (data) => {
  try {
    const res = await postData(
      `/media/media-create/media-branch-add-details`,
      data
    );
    console.log("Data to send in branch Address Details :- ", res.data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getMediaBranchAddDetail = async (data) => {
  try {
    const res = await getData(
      `/media/media-create/media-branch-add-details/${data}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const mediaAttachmentPost = async (data) => {
  try {
    const res = await postData(`/media/media-create/media-attachment`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getMediaAttachment = async (data) => {
  try {
    const res = await getData(`/media/media-create/media-attachment/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const mediaServiceAreaPost = async (data) => {
  try {
    const res = await postData(
      `/media/media-create/media-service-details`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getMediaServiceArea = async (data) => {
  try {
    const res = await getData(
      `/media/media-create/media-service-details/${data}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const mediaSectorPost = async (data) => {
  try {
    const res = await postData(`/media/media-create/media-sector`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getMediaSector = async (data) => {
  try {
    const res = await getData(`/media/media-create/media-sector/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};
