import React, { useState, useContext, useEffect } from "react";
import "./SelectedTrendingProducts.css";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import SelectedTrendingProducts from "./SelectedTrendingProducts/SelectedTrendingProducts";

import { Context } from "../../../../../utils/context";
import { getSelectedTrendingProducts } from "../../../../../utils/apis/btb/Home_Page";
import { useNavigate } from "react-router-dom";

const SelectedTrendingProducts = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const getData = async () => {
    setData(await getSelectedTrendingProducts());
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <div className="SelectedTrendingProducts">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2000, // Time between slides in milliseconds (3 seconds here)
            disableOnInteraction: false, // Auto-slide will not stop on user interactions
          }}
          modules={[Pagination, Navigation, Autoplay]}
          className="SelectedTrendingProducts_Swiper"
          onSwiper={(swiper) => setSwiperInstance(swiper)}
        >
          {/* Map over slidesData array to generate SwiperSlides */}
          {data?.data?.map((slide, index) => (
            <SwiperSlide
              key={index}
              onClick={() => {
                navigate(`/b2bbuyer/b2b-productMain/${slide?.id}`);
              }}
            >
              <div className="row">
                <div className="col-md-12 p-0">
                  <div className="SelectedTrendingProducts-main-img-holder">
                    <img
                      className="SelectedTrendingProducts-main-img"
                      src={IMG_URL + slide.thumbnail}
                      alt="SelectedTrendingProducts-main-img"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                {slide.image0 && (
                  <div className="col-md-4 col-4 p-0">
                    <div className="SelectedTrendingProducts-img-holder">
                      <img
                        className="SelectedTrendingProducts-img"
                        src={IMG_URL + slide.image0}
                        alt="SelectedTrendingProducts-img"
                      />
                    </div>
                  </div>
                )}

                {slide.image1 && (
                  <div className="col-md-4 col-4 p-0">
                    <div className="SelectedTrendingProducts-img-holder">
                      <img
                        className="SelectedTrendingProducts-img"
                        src={IMG_URL + slide.image1}
                        alt="SelectedTrendingProducts-img"
                      />
                    </div>
                  </div>
                )}
                {slide.image2 && (
                  <div className="col-md-4 col-4 p-0">
                    <div className="SelectedTrendingProducts-img-holder">
                      <img
                        className="SelectedTrendingProducts-img"
                        src={IMG_URL + slide.image2}
                        alt="SelectedTrendingProducts-img"
                      />
                    </div>
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}

          {/* <div className="navigation-arrow-holder">
            <div className="swiper-button-prev">
              <FontAwesomeIcon
                className="navigation-arrow "
                icon={faArrowLeft}
              />
            </div>
            <div className="swiper-button-next">
              <FontAwesomeIcon
                className="navigation-arrow "
                icon={faArrowRight}
              />
            </div>
          </div> */}

          {/* <div
            className="navigation-arrow-holder"
            onClick={() => handleMainSliderClick("prev")}
          >
            <div className="swiper-button-prev">
              <FontAwesomeIcon
                className="navigation-arrow "
                icon={faArrowLeft}
              />
            </div>
          </div>
          <div
            className="navigation-arrow-holder"
            onClick={() => handleMainSliderClick("next")}
          >
            <div className="swiper-button-next">
              <FontAwesomeIcon
                className="navigation-arrow "
                icon={faArrowRight}
              />
            </div>
          </div> */}
        </Swiper>
      </div>
    </>
  );
};

export default SelectedTrendingProducts;
