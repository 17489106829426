import React,{useState,useEffect,useContext} from "react";

import PortfolioContent from "./PortfolioContent/PortfolioContent";
import BannerCommon from "../CommonComponents/BannerCommon/BannerCommon";
import { PortfolioGetData } from "../../../utils/apis/myWebsite/mywebsite";

const PortfolioMain = () => {

  const [PortfolioData, setPortfolioData] = useState();
  const getPortfolioData = async () => {
      const res = await PortfolioGetData();
      if (res?.success) {
          setPortfolioData(res?.data);
      }
  }

  useEffect(() => {
      getPortfolioData();
  }, [])

  return (
    <>
      <BannerCommon heading={"Portfolio"} content={"portfolio"} text={"Explore these inspiring client websites built with Portfolio Site builder for your next project!"}/>
      <PortfolioContent PortfolioData={PortfolioData}/>
    </>
  );
};

export default PortfolioMain;
