import React from 'react'
import { Routes, Route } from "react-router-dom"
import B2bDashboardContent from '../../B2bDashboardContent/B2bDashboardContent'
import B2bOrder from '../../B2bOrder/B2bOrder'
import B2bDeshinquiry from '../../B2bDeshInquiry/B2bDeshinquiry'
import B2bDeshMyCart from '../../B2bDeshMyCart/B2bDeshMyCart'
import B2bDeshMessage from '../../B2bDeshMessage/B2bDeshMessage'
import B2bRefundRefer from '../../B2bRefundRefer/B2bRefundRefer'
const B2bDashboardRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/b2bdashboard' element={<B2bDashboardContent />} />
        <Route path='/b2border' element={<B2bOrder />} />
        <Route path='/b2binquiry' element={<B2bDeshinquiry />} />
        <Route path='/b2bcart' element={<B2bDeshMyCart />} />
        <Route path='/b2bmessage' element={<B2bDeshMessage />} />
        <Route path='/b2brefund' element={<B2bRefundRefer />} />
      </Routes>
    </>
  )
}

export default B2bDashboardRoutes