import {
    getData,
  } from "../../../api";
  
  export const getGuide = async () => {
    try {
      return await getData(`/affiliate/affiliate-guide`);
    } catch (error) {
      console.log(error);
    }
  };