import React from "react";
import "./HomeBanner.css";
const HomeBanner = () => {
  return (
    <section className="home-banner">
      <div className="container">
        <div className="row bg-color">
          <div className="col-md-6">
            <div className="heading-holder">
              <h5>Empower Your Sales Journey with Our Seller Panel</h5>
            </div>
            <div className="button-holder ">
              <button className="btn sell-btn">Sell Online</button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="imge-holder text-center">
              <img
                className="bannn-img"
                src={
                  process.env.PUBLIC_URL +
                  "/assest/images/Seller_panel/seller-image/banner/online-fashion.png"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
