import React, { useContext, useState } from "react";
import "./Seller_dashboard.css";
import Sidebar_seller_dashboard from "./Sidebar/Sidebar_seller_dashboard";
import Header_seller_dashboard from "./header/Header_seller_dashboard";
import { useLocation } from "react-router-dom";
import DashboardRoutes from "./SellerDashboard_Routes/DashboardRoutes";
import Google_meet from "./common-components/Google-meet/Google_meet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../utils/context";
import { HashLoader } from "react-spinners";

const SellerDashboard = () => {
  const location = useLocation();
  const { sidebar, setSidebar, domainLoader, setDomainLoader } =
    useContext(Context);
  return (
    <>
      <section className="seller-dash-top-main">
        {domainLoader && <HashLoader className="domain-loader" size={100} />}

        <div className="d-flex">
          <div
            className={`${
              sidebar
                ? "sidebar-flex  scale-down-hor-left"
                : "sidebarflexone scale-up-hor-left"
            }`}
          >
            <Sidebar_seller_dashboard location={location} />
          </div>

          <div
            className={`dashboard-part content-flex ${
              sidebar ? " " : "scale-down-hor-left"
            }`}
          >
            <Header_seller_dashboard
              sidebarclosed={sidebar}
              setSidebarclosed={setSidebar}
            />

            <div className="dash-main-cont-sec">
              <DashboardRoutes
                sidebarclosed={sidebar}
                setSidebarclosed={setSidebar}
              />
            </div>
          </div>
        </div>

        {/* <Google_meet /> */}

        {/* <div className="questionmarkdiv">
          <FontAwesomeIcon
            className="quetjbcicon"
            icon="fa-solid fa-circle-question"
          />
        </div> */}
      </section>
    </>
  );
};

export default SellerDashboard;
