import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import classNames from "classnames";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { ServiceesType, City, State, Country, Pincode, pincodeWiseAll } from "../../../../utils/apis/master/Master";
import { Errors, Placeholders } from "../../../../utils/errors";
import "./Stepfour.css";


// import { firmBankDetailPost, getfirmBankDetail } from "../../../../utils/apis/transport/transport";
// library.add(fas);
import { branchAddressDetailPost, getbranchAddressDetail } from "../../../../utils/apis/transport/transport";
import { RegxExpression } from "../../../../utils/common";
import { Context } from "../../../../utils/context";

function Stepfour({ nextStep, prevStep, userDetails, setPage }) {
  const [modalShow, setModalShow] = useState(false);
  const [addressCount, setAddressCount] = useState(1);

  const handleAddAddress = () => {
    setAddressCount((prevCount) => prevCount + 1);
  };

  const handleRemoveAddress = () => {
    setAddressCount((prevCount) => prevCount - 1);
  };

  const handleTabClick = (eventKey) => {
    setActiveTab(eventKey);
  };

  const [activeTab, setActiveTab] = useState("office_address");

  const navItems = [
    { eventKey: "office_address", label: "Office address" },
    { eventKey: "other", label: "Other" },
  ];

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {

      data: [
        {
          name: "",
          contact: "",
          contact_person: "",
          add_line_one: "",
          add_line_two: "",
          city_id: "",
          state_id: "",
          pincode_id: "",
          country_id: "",
          service_area_id: "",
        },
      ],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "data",
  });

  const onSubmit = async (d) => {
    console.log(d, "form");
    d.t_personal_details_id = userDetails?.t_personal_details_id;
    const res = await branchAddressDetailPost(d);
    if (res?.success) {
        nextStep();
    }
  };


  const [service, setService] = useState([]);

  const handleServiceChange = async (e) => {
    const res = await ServiceesType(e);
    if (res?.success) {
      const data = await Select2Data(res.data, "service_area_id", false);
      setService(data);
    }
  }

  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [pincodes, setPincodes] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const handlePincodeChange = async (e) => {
    const res = await Pincode(e);
    if (res?.success) {
      const data = await Select2Data(res.data, "pincode_id", false);
      setPincodes(data);
    }
  }
  const handlePincodeSelect = async (id, index) => {
    const res = await pincodeWiseAll(id);
    if (res?.success) {
      console.log(res);
      const updatedCities = [...cities];
      updatedCities[index] = res.data.city.name;
      setCities(updatedCities);

      const updatedStates = [...states];
      updatedStates[index] = res.data.state.name;
      setStates(updatedStates);

      const updatedCountries = [...countries];
      updatedCountries[index] = res.data.country.name;
      setCountries(updatedCountries);

      setValue(`data[${index}].city_id`, res.data.city.id);
      setValue(`data[${index}].state_id`, res.data.state.id);
      setValue(`data[${index}].country_id`, res.data.country.id);


    }
  }

  const [dropCount, setDropCount] = useState(0);

  const getBranchDetailIn = async () => {
    const res = await getbranchAddressDetail(userDetails?.t_personal_details_id);
    if (res?.success) {
      setDropCount(res.data.length);
      if (res.data.length > 0) {
        reset({ data: res.data });
        console.log(res);
        var updatedCities = [...cities];
        var updatedStates = [...states];
        var updatedCountries = [...countries];
        res.data.forEach((item, index) => {
          updatedCities[index] = item.city.name;
          updatedStates[index] = item.state.name;
          updatedCountries[index] = item.country.name;
          setValue(`data[${index}].service_area_id`,{value: item?.s_area?.id , label: item?.s_area?.name});
        });
        setCities(updatedCities);
        setStates(updatedStates);
        setCountries(updatedCountries);

        
        
      } else {
        reset({
          data: [
            {
              name: "",
              add_line_one: "",
              add_line_two: "",
              city_id: "",
              state_id: "",
              pincode_id: "",
              country_id: "",
              service_area_id: "",
            },
          ],
        });
      }
    }
  }

  useEffect(() => {
    getBranchDetailIn();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="form-container">
        <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
          <div>
            {fields.map((item, index) => (
              <>
                <div className="stepfourMain" key={index}>
                  <div className="row me-0 ms-0">
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>Branch Name</Form.Label>
                        <Form.Control
                          type="text"
                          className={classNames("", {
                            "is-invalid":
                              errors.data &&
                              errors.data[index] &&
                              errors.data[index].name,
                              "is-valid":getValues(`data[${index}].name`),
                          })}
                          name={`data[${index}].name`}
                          placeholder="Enter  Branch Name"
                          defaultValue={item.add_branch_name}
                          {...register(`data[${index}].name`, {
                            required: "Branch is required",
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Branch Contact</Form.Label>
                        <Form.Control
                          type="text"
                          className={classNames("", {
                            "is-invalid":
                              errors.data &&
                              errors.data[index] &&
                              errors.data[index].contact,
                              "is-valid":getValues(`data[${index}].contact`),
                          })}
                          name={`data[${index}].contact`}
                          placeholder="Enter Branch Contact"
                          defaultValue={item.add_branch_name}
                          {...register(`data[${index}].contact`, {
                            required: "Branch Contact is required",
                            minLength: {
                              value: 10,
                              message: "Branch Contact must be at least 10 characters long",
                            }
                          })}
                          maxLength={10}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-lg-4">
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Branch Contact Person Name</Form.Label>
                        <Form.Control
                          type="text"
                          className={classNames("", {
                            "is-invalid":
                              errors.data &&
                              errors.data[index] &&
                              errors.data[index].contact_person,
                              "is-valid":getValues(`data[${index}].contact_person`),
                          })}
                          name={`data[${index}].contact_person`}
                          placeholder="Branch Contact Person Name"
                          defaultValue={item.add_branch_name}
                          {...register(`data[${index}].contact_person`, {
                            required: "Branch Contact Person Name is required",
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <hr></hr>
                  {/* form-holder */}

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Enter Address Line 1</Form.Label>
                        <Form.Control
                          type="text"
                          className={classNames("", {
                            "is-invalid":
                              errors.data &&
                              errors.data[index] &&
                              errors.data[index].add_line_one,
                              "is-valid":getValues(`data[${index}].add_line_one`),
                          })}
                          name={`data[${index}].add_line_one`}
                          placeholder="Enter Address Line 1"
                          defaultValue={item.add_line_one}
                          {...register(`data[${index}].add_line_one`, {
                            required: "Address line 1 is required",
                          })}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Address line 2</Form.Label>
                        <Form.Control
                          type="text"
                          className={classNames("", {
                            "is-invalid":
                              errors.data &&
                              errors.data[index] &&
                              errors.data[index].add_line_two,
                              "is-valid":getValues(`data[${index}].add_line_two`),
                          })}
                          name={`data[${index}].add_line_two`}
                          placeholder="Enter Address Line 2"
                          defaultValue={item.add_line_two}
                          {...register(`data[${index}].add_line_two`, {
                            required: "Address line 2 is required",
                          })}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>City/District:</Form.Label>

                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors.data &&
                            errors.data[index] &&
                            errors.data[index].city,
                            "is-valid":getValues(`data[${index}].city`),
                          })}
                          type="text"
                          name="city_id"
                          readOnly
                          value={cities[index]}
                          placeholder={Placeholders.city}

                        />

                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors.data &&
                            errors.data[index] &&
                            errors.data[index].state,
                            "is-valid":getValues(`data[${index}].state`),
                          })}
                          type="text"
                          name="state_id"

                          value={states[index]}
                          placeholder={Placeholders.state}
                          readOnly

                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Pincode</Form.Label>
                        <Controller
                          name={`data[${index}].pincode_id`}
                          {...register(`data[${index}].pincode_id`, {
                            required: Errors.pincode,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.data &&
                                  errors.data[index] &&
                                  errors.data[index].pincode_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={pincodes}
                              onInputChange={(selectedOption) => {
                                handlePincodeChange(selectedOption);
                              }}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                handlePincodeSelect(selectedOption.value, index);
                              }}
                            />
                          )}
                        />

                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors.data &&
                            errors.data[index] &&
                            errors.data[index].country,
                            "is-valid":getValues(`data[${index}].country`),
                          })}
                          type="text"
                          name="country_id"
                          readOnly
                          value={countries[index]}
                          placeholder={Placeholders.country}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>Service Area</Form.Label>
                        <Controller
                          name={`data[${index}].service_area_id`}
                          {...register(`data[${index}].service_area_id`, {
                            required: Errors.service_area_id,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.data &&
                                  errors.data[index] &&
                                  errors.data[index].service_area_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={service}
                              onInputChange={(selectedOption) => {
                                handleServiceChange(selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>
                    </div>

                    {index !== 0 && (
                      <div className="addremoveadress">
                        <div className="text-end Addnewadresstxt">
                          <p className="" onClick={() => remove(index)}>
                            Remove Address{" "}
                            <FontAwesomeIcon icon="fa-solid fa-minus" />
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}

            <div className="Common-details-step m-3">
              {/* <Form.Check
              inline
              type="checkbox"
              label="Is new address same as previous"
              name=""
              className="my-2"
            /> */}
            </div>

            <div className="addremoveadress">
              {/* <div
              className="text-end Addnewadresstxt"
              onClick={handleRemoveAddress}
            >
              <p className="">
                Remove new branch <FontAwesomeIcon icon="fa-solid fa-minus" />
              </p>
            </div> */}
              <div className="text-end Addnewadresstxt">
                <p
                  onClick={() =>
                    append({
                      name: "",
                      contact: "",
                      contact_person: "",
                      add_line_one: "",
                      add_line_two: "",
                      city_id: "",
                      state_id: "",
                      pincode_id: "",
                      country_id: "",
                      service_area_id: "",
                    })
                  }
                >
                  Add new branch  <FontAwesomeIcon icon="fa-solid fa-plus" />
                </p>
              </div>
            </div>



            <div className="col-md-12">
              <div className="text-end apply_now_btn">
                {/* <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p> */}

                <Button onClick={prevStep} className="back-btn">
                  Back
                </Button>

                <Button onClick={handleSubmit(onSubmit)} className="tabs-btn">
                  Register & Continue
                </Button>
              </div>

              <TermsAndConditionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </Form>
      </div >

    </>
  );
}

export default Stepfour;
