import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { getCaution } from "../../../../../utils/apis/master/Master";
import { Select2Data } from "../../../../../utils/api";
import Select from "react-select";
const ProductCautionSafety = ({
  reset,
  errors,
  register,
  Controller,
  control,
  getValues,
  setValue,
  working_type,
  useFieldArray,
  IMG_URL,
}) => {
  const { append, fields, remove } = useFieldArray({
    control,
    name: "caution_details",
  });

  const validateWordCount = (value) => {
    const wordCount = value.length;
    return wordCount <= 100 || "Maximum 100 words are allowed";
  };

  const [caution, setCaution] = useState([]);
  const getAll = async () => {
    const res = await getCaution();
    await setCaution(
      await Select2Data(res.data, "caution_id", false, true, false, true)
    );
  };

  useEffect(() => {
    getAll();
  }, []);

  return (
    <>
      {fields?.map((value, index) => (
        <>
          <div className="col-xxl-12 col-md-12 col-12 align-items-center d-flex mt-auto mb-2">
            <Controller
              name={`caution_details[${index}].caution_id`}
              {...register(`caution_details[${index}].caution_id`, {
                required: "Caution is required",
              })}
              control={control}
              render={({ field }) => (
                <Select
                  classNamePrefix={"select-btn"}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor:
                        errors?.caution_details &&
                        errors?.caution_details[index] &&
                        errors?.caution_details[index]?.caution_id
                          ? "red"
                          : baseStyles,
                    }),
                  }}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption); // Update form state
                    setValue(
                      `caution_details[${index}].description`,
                      selectedOption.description
                    );
                    reset(getValues());
                  }}
                  value={field.value}
                  // {...field}
                  options={caution}
                />
              )}
            />{" "}
            <div className="preview-box">
              {" "}
              <img
                style={{ width: "40px", height: "40px" }}
                src={
                  IMG_URL +
                  getValues(`caution_details[${index}].caution_id.image`)
                }
                onError={(e) => {
                  e.target.src =
                    process.env.PUBLIC_URL +
                    "/assest/images/Seller_panel/dashboard/icons/addicooo.png";
                }}
              />
            </div>
            <Form.Control
              type="text"
              placeholder="Description"
              className={classNames("me-2", {
                "is-invalid":
                  errors?.caution_details &&
                  errors?.caution_details[index] &&
                  errors?.caution_details[index]?.description,
              })}
              name={`caution_details[${index}].description`}
              {...register(`caution_details[${index}].description`, {
                required: "Description is required",
                validate: validateWordCount,
              })}
            />{" "}
            {fields?.length !== 1 && (
              <button
                className="delet-row"
                onClick={() => remove(index)}
                type="button"
              >
                <FontAwesomeIcon icon="fa-solid fa-minus" />
              </button>
            )}
          </div>
        </>
      ))}
      {fields?.length < 6 && (
        <button
          className="addrowiccc"
          type="button"
          style={{
            float: "right",
          }}
          onClick={() =>
            append({
              name: "",
            })
          }
        >
          <img
            style={{ margin: "0px" }}
            src={
              process.env.PUBLIC_URL +
              "/assest/images/Seller_panel/dashboard/icons/addicooo.png"
            }
          />
        </button>
      )}
    </>
  );
};

export default ProductCautionSafety;
