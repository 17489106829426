import React, { useContext, useEffect, useState } from "react";
import "./mrp-modal.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { RegxExpression } from "../../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import { Errors, Placeholders } from "../../../utils/errors";
import DomainTutorial from "../domain-tutorial-modal/Domain-Tutorial";

import SuccessModalDynamic from "../SuccessModalDynamic/SuccessModalDynamic";
import ErrorModal from "../ErrorModal/ErrorModal";
import { getInfluencerWork, influencerWorkPost } from "../../../utils/apis/affiliate/InHouseTask/Task/task";

const Form_modal = (props) => {

  const { selectedPlan } = props;
  const {
    control,
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const { IMG_URL, getData } = useContext(Context);

  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const onSubmit = async (d) => {

    console.log(d.document?.[0]);
    if (props?.affiliateTaskID !== "") {


      const data = {
        id: props?.affiliateTaskID,
        work_link: inputs,
        deletedInputs: deletedInputs,
      }

      const res = await influencerWorkPost(data);
      if (res?.success) {
        props.onHide();
        props.getProducts();
        setSuccessModal(true);
        setTimeout(() => {
          setSuccessModal(false);
        }, 3000);

      }
    } else {
      props.onHide();
      setErrorModal(true);
      setTimeout(() => {
        setErrorModal(false);
      }, 3000);
    }

  }






  // Function to handle adding a new input field
  const handleAddInput = (arrayIndex, index, id) => {
    const newInput = { id: id, value: '' };

    const updatedArray = [
      ...inputs[arrayIndex].slice(0, index + 1),
      newInput,
      ...inputs[arrayIndex].slice(index + 1),
    ];

    const updatedInputs = inputs.map((inputArray, i) =>
      i === arrayIndex ? updatedArray : inputArray
    );

    setInputs(updatedInputs);
  };

  // Function to handle removing an input field
  const handleRemoveInput = (arrayIndex, index) => {
    if (inputs[arrayIndex].length > 1) {

      if (inputs[arrayIndex][index].work_id) {
        // Create a new array by spreading the existing deletedInputs and adding the new work_id
        const deleted = [...deletedInputs, inputs[arrayIndex][index].work_id];
      
        // Update the deletedInputs state with the new array
        setDeletedInputs(deleted);
      }

      const updatedArray = inputs[arrayIndex].filter((_, i) => i !== index);

      const updatedInputs = inputs.map((inputArray, i) =>
        i === arrayIndex ? updatedArray : inputArray
      );

      setInputs(updatedInputs);
    }
  };

  // Function to handle input change (optional, if you need to manage input values)

  const handleInputChange = (arrayIndex, inputIndex, value) => {
    setInputs(inputs.map((inputArray, i) =>
      i === arrayIndex
        ? inputArray.map((input, j) =>
          j === inputIndex
            ? { ...input, value }
            : input
        )
        : inputArray
    ));
  };

  const [inputs, setInputs] = useState();
  const [deletedInputs, setDeletedInputs] = useState([]);
  const [socialMendia, setSocialMedia] = useState([]);
  const getSocialMedia = async () => {
    {
      const res = await getData(`/affiliate/affiliate-task/influncer/social-link`);
      const work = await getInfluencerWork(props.affiliateTaskID);


      if (res?.success) {
        setSocialMedia(res?.data);
        if (work?.success) {
          if (work?.data.length > 0) {
            const inputsArray = res.data.map((value) => {
              const matchedWorkItems = work?.data?.filter(item => item?.a_social_details_id === value?.id);
            
              if (matchedWorkItems.length > 0) {
                return matchedWorkItems.map(matchedItem => ({
                  id: value?.id,
                  value: matchedItem?.link,
                  work_id: matchedItem?.id
                }));
              } else{
                return [{
                  id: value?.id,
                  value: '',
                }];
              }
            });
            


            setInputs(inputsArray);

          } else {
            const inputsArray = res.data.map((value, index) => [{ id: value?.id, value: '' }]);

            setInputs(inputsArray);
          }

        } else {
          const inputsArray = res.data.map((value, index) => [{ id: value?.id, value: '' }]);

          setInputs(inputsArray);
        }


      }
    }
  }

  console.log(inputs, 'lllll');



  useEffect(() => {
    getSocialMedia();
  }, [props.show])

  return (
    <>
      <Modal className='mrp-modal' size="lg" {...props} centered >
        <Modal.Header closeButton onClick={props.onHide}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <div className='row text-center'>
              <div className='col-12'>
                <h4>Please Submit your work here</h4>
              </div>
            </div>

            {socialMendia && socialMendia?.map((social, index) =>

              <>
                <Form.Group className="mb-3" controlId="">
                  <div className="d-flex soccc">
                    <Form.Label>Social Media : </Form.Label>
                    <img className="socialicccc" src={IMG_URL + social?.social_platform_id?.image} />
                    <p className="socicnm">{social?.social_platform_id?.label}</p>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="">
                  <div className="d-flex soccc">
                    <Form.Label>Page Name : </Form.Label>
                    <p className="socicnm"> {social?.page_name}</p>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Work Link : </Form.Label>
                  <div>
                    {inputs?.[index]?.map((input, inputindex) => (
                      <div key={input.id} className="d-flex addinputmain mb-3">
                        <Form.Control
                          type="text"
                          name={`input-${input.id}`}
                          placeholder="Enter Work Link"
                          value={input.value}
                          onChange={(e) => handleInputChange(index, inputindex, e.target.value)}
                        />
                        <button className="plusicoo" type="button" onClick={() => handleAddInput(index, inputindex, social?.id)}>+</button>
                        {inputindex !== 0 &&
                          <button className="minusicoo" type="button" onClick={() => handleRemoveInput(index, inputindex)}>-</button>
                        }

                      </div>
                    ))}
                  </div>

                  {/* <div className="d-flex addinputmain mb-3">
                    <Form.Control type=""  name="" placeholder="Enter Work File"   />  
                    <button className="plusicoo">+</button>
                    <button className="minusicoo">-</button>
                </div>  */}

                </Form.Group>

              </>

            )}

            <button className="update-btn" type="button" onClick={handleSubmit(onSubmit)}>Submit</button>

            {/* <Form.Group className="mb-3" controlId="">
              <Form.Label>Work File</Form.Label>
              <Form.Control
                type="file"
                name="document"
                placeholder="Enter Work File"
                {...register("document", {
                  required: "Work File required",
                })}
                className={classNames("", {
                  "is-invalid": errors?.document,
                })}
                onChange={handleFileChange}
              />
            </Form.Group>

            <div className='row'>
              <div className='col-12'>
                <h6>File Name:</h6>{fileName}
              </div>
            </div>

            <Form.Group className="mb-3" controlId="">
              <Form.Label>Work Link</Form.Label>
              <Form.Control
                type="text"
                name="work_link"
                placeholder="Enter Work Link"
                {...register("work_link", {
                  required: "Work Link required",
                })}
                className={classNames("", {
                  "is-invalid": errors?.work_link,
                })}
              />
            </Form.Group>

            <button className="update-btn" type="button" onClick={handleSubmit(onSubmit)}>Purchase</button> */}



          </Form>

          <SuccessModalDynamic show={successModal} onHide={() => setSuccessModal(false)} text={'Your Work has been submitted'} />
          <ErrorModal show={errorModal} onHide={() => setErrorModal(false)} text={'Something Went Wrong'} />


        </Modal.Body>
      </Modal>
    </>
  );
};

export default Form_modal;
