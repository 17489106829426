import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import "./Furniture_Slider.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Context } from "../../../../../../utils/context";
import { useNavigate } from "react-router-dom";

function Furniture_Slider(props) {
  const { products } = props;
  const { IMG_URL } = useContext(Context);

  const navigate = useNavigate();

  const [showRemainingSlides, setShowRemainingSlides] = useState(false);

  const toggleRemainingSlides = () => {
    setShowRemainingSlides(!showRemainingSlides);
  };

  console.log("productsproductsproducts", products);

  return (
    <>
      <div className="Furniture_Slider">
        <Swiper
          spaceBetween={20}
          slidesPerView={5}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 2000, // Time between slides in milliseconds (3 seconds here)
            disableOnInteraction: false, // Auto-slide will not stop on user interactions
          }}
          className="Furniture_Swiper"
          breakpoints={{
            // when window width is >= 280px
            280: {
              slidesPerView: 2,
            },
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
            },
            // when window width is >= 992px
            992: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
        >
          {products
            ?.slice(0, showRemainingSlides ? products.length : 5)
            .map((item, index) => (
              <SwiperSlide
                key={index}
                onClick={() => {
                  navigate(`/b2bbuyer/b2b-productMain/${item?.id}`);
                }}
              >
                <div className="Furniture-img-holder">
                  <h4>{item?.product_title}</h4>
                  <div className="Furniture-img-img">
                    <img
                      className="Furniture-img"
                      src={IMG_URL + item.thumbnail}
                      alt={item.thumbnail}
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          {!showRemainingSlides && products?.length > 5 && (
            <SwiperSlide>
              <div className="half-width-button-container">
                <button
                  onClick={toggleRemainingSlides}
                  className="view-more-button"
                >
                  View More
                </button>
              </div>
            </SwiperSlide>
          )}
          {/* Map over the furnitureData array to generate SwiperSlides */}
          {/* {products?.slice(0, 5).map((item, index) => (
            <SwiperSlide key={index}>
              <div className="Furniture-img-holder">
                <h4>{item.title}</h4>
                <div className="Furniture-img-img">
                  <img
                    className="Furniture-img"
                    src={IMG_URL + item.thumbnail}
                    alt={item.thumbnail}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
          {!showRemainingSlides &&
            products?.length > 0 &&
            products?.length >= 5 && (
              <SwiperSlide>
                <div className="half-width-button-container">
                  <button
                    onClick={toggleRemainingSlides}
                    className="view-more-button"
                  >
                    View More
                  </button>
                </div>
              </SwiperSlide>
            )}
          {showRemainingSlides &&
            products?.slice(5).map((item, index) => (
              <SwiperSlide key={index}>
                <div className="Furniture-img-holder">
                  <h4>{item.title}</h4>
                  <div className="Furniture-img-img">
                    <img
                      className="Furniture-img"
                      src={IMG_URL + item.thumbnail}
                      alt={item.thumbnail}
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))} */}
        </Swiper>
      </div>
    </>
  );
}

export default Furniture_Slider;
