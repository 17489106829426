import React, { useEffect, useState } from "react";
import "./Overviewpage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import CreateAccountSeccseccfullyModal from "../../../CommonForAll/CreateAccountSeccseccfullyModal/CreateAccountSeccseccfullyModal";

import Cookies from 'js-cookie';
import {
  getSellerPersonalDetails,
  getSectorCategory,
  getfirmDetails,
  getFirmDetailName,
  getfirmAddressDetail, getfirmDropAddress, getfirmPickupAddress, getfirmBankDetail, getSellerOverview, finalPost
} from "../../../../utils/apis/seller/Seller";
const OverviewPage = ({ prevStep, goToStep, userDetails, workTypeName,sellerEdit }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const navigate = useNavigate();

  const handleEditPersonalDetails = () => {
    goToStep(0);
  };

  const handleEditWorkingDetails = () => {
    goToStep(1);
  };

  const handleEditIndustryDetails = () => {
    goToStep(2);
  };

  const handleEditFirmDetails = () => {
    goToStep(3);
  };

  const handleEditBankDetails = () => {
    goToStep(4);
  };

  console.log(userDetails?.s_personal_details_id);

  const [personalData, setPersonalData] = useState("");
  const [sectorCategory, setSectorCategory] = useState("");
  const [firmData, setFirmData] = useState("");
  const [firmDataName, setFirmDataName] = useState("");
  const [firmAddressData, setFirmAddressData] = useState("");
  const [firmDropAddressData, setFirmDropAddressData] = useState([]);
  const [firmPickupAddressData, setFirmPickupAddressData] = useState([]);
  const [firmBankData, setFirmBankData] = useState("");
  const allDataGet = async () => {
    {
      const res = await getSellerPersonalDetails(userDetails?.s_personal_details_id);
      if (res?.success) {
        console.log(res);
        setPersonalData(res.data);
      }
    }
    {
      const res = await getSellerOverview(userDetails?.s_personal_details_id);
      if (res?.success) {
        console.log(res);
        setSectorCategory(res.data);
      }
    }
    {
      const res = await getfirmDetails(userDetails?.s_personal_details_id);
      if (res?.success) {
        console.log(res);
        setFirmData(res.data);
      }
    }
    {
      const res = await getFirmDetailName(userDetails?.s_personal_details_id);
      if (res?.success) {
        console.log(res);
        setFirmDataName(res.data);
      }
    }
    {
      const res = await getfirmAddressDetail(userDetails?.s_personal_details_id);
      if (res?.success) {
        console.log(res);
        setFirmAddressData(res.data);
      }
    }
    {
      const res = await getfirmDropAddress(userDetails?.s_personal_details_id);
      if (res?.success) {
        console.log(res);
        setFirmDropAddressData(res.data);
      }
    }
    {
      const res = await getfirmPickupAddress(userDetails?.s_personal_details_id);
      if (res?.success) {
        console.log(res);
        setFirmPickupAddressData(res.data);
      }
    }
    {
      const res = await getfirmBankDetail(userDetails?.s_personal_details_id);
      if (res?.success) {
        console.log(res);
        setFirmBankData(res.data);
      }
    }
  }


  const finalSubmit = async () => {
    const data = {
      s_personal_details_id: userDetails?.s_personal_details_id,
      percentage: 100,
    };
    const res = await finalPost(data);
    if (res?.success) {
      console.log(res);
      setModalShow2(true);
      setTimeout(() => {
        setModalShow2(false);
        Cookies.remove("seller_id");
        navigate("/seller/seller_login");
      }, 3000);
    }
  }
  useEffect(() => {
    allDataGet();
  }, []);

  return (
    <>
      <section className="Overview">
        <div className="container">
          <div className="row">
            <div className="overviewContent">
              <div className="row">
                <div className="col-lg-6">
                  {!sellerEdit && (
                    <p>Almost there...</p>
                  )}
                  
                </div>
                <div className="col-lg-6"></div>
              </div>

              {/* Personal Details */}

              <div className="mb-5">
                <div className="PersonalHead">
                  <h3>Personal Details</h3>
                </div>
                <div className="personalBox">
                  <div className="row">
                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>First Name</p>
                        <p>{personalData?.f_name}</p>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>Last Name</p>
                        <p>{personalData?.l_name}</p>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>Contact Number</p>
                        <p>{personalData?.contact_no}</p>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>Email</p>
                        <p>{personalData?.email}</p>
                      </div>
                    </div>

                    <div
                      className="editIcon"
                      onClick={() => handleEditPersonalDetails()}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        className="me-2"
                      />
                      <span style={{ fontWeight: "500" }}>Edit</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Working type */}

              <div className="mb-5">
                <div className="PersonalHead">
                  <h3>Working Type</h3>
                </div>
                <div className="personalBox">
                  <div className="row">
                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>Working Type</p>
                        <p>{workTypeName}</p>
                      </div>
                    </div>

                    <div
                      className="editIcon"
                      onClick={() => handleEditWorkingDetails()}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        className="me-2"
                      />
                      <span style={{ fontWeight: "500" }}>Edit</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Selected Categories */}

              <div className="mb-5">
                <div className="PersonalHead">
                  <h3>Selected Categories</h3>
                </div>
                <div className="personalBox">
                  <div className="row">
                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>Category</p>
                        <p>{sectorCategory?.s_category?.name}</p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-3">
                      <div className="personalDetailsInner">
                        <p>Sub Category</p>
                        {sectorCategory?.s_sector_subs?.map((value, index) => (
                          <b> <p>{value?.s_sub_category?.name}</p></b>
                        ))}
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>Child Category</p>
                        {sectorCategory?.s_sector_child_categories?.map((value, index) => (
                          <b> <p>{value?.s_child_category?.name}</p></b>
                        ))}
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>Child Sub Category</p>
                        {sectorCategory?.s_sector_child_sub_categories?.map((value, index) => (
                          <b> <p>{value?.s_child_sub_category?.name}</p></b>
                        ))}
                      </div>
                    </div>

                    <div
                      className="editIcon"
                      onClick={() => handleEditIndustryDetails()}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        className="me-2"
                      />
                      <span style={{ fontWeight: "500" }}>Edit</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="firmDetails">
                <div className="PersonalHead">
                  <h3>Firm Details</h3>
                </div>

                <div className="personalBox mb-5">
                  <div className="row overviewPerRow">
                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>PAN Number</p>
                        <p>{firmData?.pan_no}</p>
                      </div>
                    </div>

                    {/* <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>Enrollment Number</p>
                        <p>XXXX</p>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>CIN Number</p>
                        <p>XXXX</p>
                      </div>
                    </div> */}

                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>GST</p>
                        <p>{firmData?.gst_no}</p>
                      </div>
                    </div>

                    <div className="editIcon" onClick={handleEditFirmDetails}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        className="me-2"
                      />
                      <span style={{ fontWeight: "500" }}>Edit</span>
                    </div>
                  </div>

                  <div className="row overviewPerRow">
                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>Firm Name</p>
                        <p>{firmDataName?.name}</p>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-3">
                      <div className="personalDetailsInner">
                        <p>Firm Type</p>
                        <p>{firmDataName?.s_firm_type_id?.label}</p>
                      </div>
                    </div>

                    <div className="editIcon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        className="me-2"
                      />
                      <span style={{ fontWeight: "500" }}>Edit</span>
                    </div>
                  </div>

                  <div className="row overviewPerRow">
                    <div className="col-lg-6  col-md-6">
                      <div className="personalDetailsInner">
                        <p>{firmAddressData?.s_firm_address_type?.name}</p>
                        <p>
                          {firmAddressData?.add_line_one}, {firmAddressData?.add_line_two}, {firmAddressData?.city?.name}, {firmAddressData?.state?.name}, {firmAddressData?.country?.name} - {firmAddressData?.pincode?.name}
                        </p>
                      </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-4">
                      <div className="personalDetailsInner">
                        <p>Shop address</p>
                        <p>
                          C/o Sobhan Kumar Vellanki kanakamamidi village road,
                          Telangana - 501218
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div className="personalDetailsInner">
                        <p>Branch office address</p>
                        <p>
                          Kalpataru Gardens, 157 Kamatghar Village Road,
                          Bhiwandi - Nizampur - 421302
                        </p>
                      </div>
                    </div> */}

                    <div className="editIcon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        className="me-2"
                      />
                      <span style={{ fontWeight: "500" }}>Edit</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="card firmDetailsCard">
                        <div className="card-body">
                          <div className="firmcardInsideCon">
                            <h3>Drop Address</h3>
                            {firmDropAddressData?.map((value, index) => (

                              <p>
                                {value?.add_line_one},
                                {value?.add_line_two},
                                {value?.city?.name},
                                {value?.state?.name},
                                {value?.country?.name} - {value?.pincode?.name}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="card firmDetailsCard">
                        <div className="card-body">
                          <div className="firmcardInsideCon">
                            <h3>Pickup address</h3>
                            {firmPickupAddressData?.map((value, index) => (

                              <p>
                                {value?.add_line_one},
                                {value?.add_line_two},
                                {value?.city?.name},
                                {value?.state?.name},
                                {value?.country?.name} - {value?.pincode?.name}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bankDetails">
                  <div className="PersonalHead">
                    <h3>Bank Details</h3>
                  </div>

                  <div className="personalBox">
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="personalDetailsInner">
                          <p>Bank name</p>
                          <p>{firmBankData?.bank_name}</p>
                        </div>
                      </div>

                      <div className="col-lg-2">
                        <div className="personalDetailsInner">
                          <p>Bank account number</p>
                          <p>{firmBankData?.bank_account_no}</p>
                        </div>
                      </div>

                      <div className="col-lg-2">
                        <div className="personalDetailsInner">
                          <p>IFSC code</p>
                          <p>{firmBankData?.ifsc_code}</p>
                        </div>
                      </div>

                      {/* <div className="col-lg-2">
                        <div className="personalDetailsInner">
                          <p>Branch name</p>
                          <p>{firmBankData?.branch_name}</p>
                        </div>
                      </div>

                      <div className="col-lg-2">
                        <div className="personalDetailsInner">
                          <p>MICR code</p>
                          <p>{firmBankData?.micr_code}</p>
                        </div>
                      </div> */}

                      <div className="editIcon" onClick={handleEditBankDetails}>
                        <FontAwesomeIcon
                          icon="fa-solid fa-pen"
                          className="me-2"
                        />
                        <span style={{ fontWeight: "500" }}>Edit</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="text-end apply_now_btn">
                  {/* <p onClick={() => setModalShow(true)}>
                    By continuing, I agree to Netpurti's
                    <span className="termstxt">
                      Terms of Use & Privacy Policy
                    </span>
                  </p> */}

                  <Button className="back-btn me-2" onClick={prevStep}>
                    Back
                  </Button>
                  <Button
                    className="tabs-btn"
                    onClick={finalSubmit}

                  >
                    Create Account
                  </Button>
                  <CreateAccountSeccseccfullyModal
                    show={modalShow2}
                    onHide={() => setModalShow2(false)}
                  />

                  <TermsAndConditionModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OverviewPage;
