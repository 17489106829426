import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip } from "react-bootstrap"; // Import OverlayTrigger and Tooltip
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoditEditor from "jodit-react";

import "../CommonDetails.css";

function Warrentytab() {
  const [content, setContent] = useState("");
  return (
    <>
      <section className="warrenty-tab">
        <div className="py-2">
          <div className="row">
            <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Warranty Provided By <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-title">Warranty Provided By</Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter Company Name" readOnly/>
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-lg-4 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Warranty Period of Product <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-category">
                        Warranty Period of Product
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter warranty period" readOnly/>
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Helpline Number <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-brand">Helpline Number</Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter helpline number" readOnly/>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-xxl-4 col-md-4 col-4">
            <p className="me-2 minitext">Service Type</p>
            <div className="">
              <Form.Check
                type="radio"
                label="Go to Service Center"
                name="category"
                id="category1"
                className="my-2"
                disabled 
              />
              <Form.Check
                type="radio"
                label="Onsite Service"
                name="category"
                id="category2"
                className="my-2"
                disabled 
              />
              <Form.Check
                type="radio"
                label="Go to Manufacture Spot"
                name="category"
                id="category3"
                className="my-2"
                disabled 
              />
            </div>
          </div>
          <div className="col-xxl-8 col-md-8 col-8">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Address{" "}
                <span className="span-text">( if manufacture spot )</span>{" "}
                <span className="star">*</span>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-brand">Helpline Number</Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-circle-info"
                    className="ms-3"
                  />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Enter address"
                readOnly
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="select-to-div">
            <Form.Label>Warranty Terms</Form.Label>
            <JoditEditor
              value={content}
              tabIndex={1}
              onBlur={(newContent) => setContent(newContent)}
              onChange={(newContent) => {}}
              config={{
                readonly: true, // This makes the editor read-only
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Warrentytab;
