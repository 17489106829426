import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";


  export const threePSupportPersonalDetailsPost = async (data) => {
    try {

        const res = await postData(`/three-party-support/create/personal-details`,data)
      
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const getSectorCategory = async (user_id) => {
    try {

        const res = await getData(`/three-party-support/create/personal-details/${user_id}`)
      
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const threePSupportFirmDetailsPost = async (data) => {
    try {

        const res = await postData(`/three-party-support/create/firm-details`,data)
      
      return res;
    } catch (error) {
      console.log(error);
    }
  };



  export const firmDetailsPost = async (data) => {
    try {
  
      const res = await postData(`/three-party-support/create/firm-details`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  export const getfirmDetails = async (user_id) => {
    try {
  
      const res = await getData(`/three-party-support/create/firm-details/${user_id}`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const firmDetailNamePost = async (data) => {
    try {
  
      const res = await postData(`/three-party-support/create/firm-details/name`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const getFirmDetailName = async (user_id) => {
    try {
  
      const res = await getData(`/three-party-support/create/firm-details/name/${user_id}`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const firmAddressDetailPost = async (data) => {
    try {
  
      const res = await postData(`/three-party-support/create/firm-address`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const getfirmAddressDetail = async (user_id) => {
    try {
  
      const res = await getData(`/three-party-support/create/firm-address/${user_id}`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const postServiceAddress = async (data) => {
    try {
  
      const res = await postData(`/three-party-support/create/service-details`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const getServiceAddress = async (user_id) => {
    try {
  
      const res = await getData(`/three-party-support/create/service-details/${user_id}`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };


  export const firmBankDetailPost = async (data) => {
    try {
  
        const res = await postData(`/three-party-support/create/bank-details`,data);
      
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const getfirmBankDetail = async (user_id) => {
    try {
  
        const res = await getData(`/three-party-support/create/bank-details/${user_id}`);
      
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const firmPasswordPost = async (data) => {
    try {
  
        const res = await postData(`/three-party-support/create/personal-details/password`,data);
      
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  

  export const getAttachment = async (user_id) => {
    try {
  
      const res = await getData(`/three-party-support/create/attachment/${user_id}`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const AttachmentPost = async (data) => {
    try {
  
      const res = await postData(`/three-party-support/create/attachment`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  