import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../../../Components/assets/icons/PLUS.png";
import colunms from "../../../../../../Components/assets/icons/LINES.png";
import search1 from "../../../../../../Components/assets/icons/search.png";
import top from "../../../../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
// import Header from "../../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import { AddButton, EditButton, DeletButton } from "../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// import SettingContent from "../SettingContent/SettingContent";
import { Button } from "react-bootstrap";
import { Context } from "../../../../../../utils/context";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
import parsee from "html-react-parser";
import {
  getAllChildCategory,
  changeChildCategoryStatus,
  deleteChildCategory,
} from "../../../../../../utils/apis/seller/outer-website/masters/ChildCategory/child_category";
import { pulledCategory } from "../../../../../../utils/apis/seller/masters";
import { addChildCategory } from "../../../../../../utils/apis/seller/outer-website/masters/ChildCategory/child_category";
import {
  AllCategory,
  Category,
  ChildCategory,
  SubCategory,
  AllSubCategory
} from "../../../../../../utils/apis/master/Master";
import Select from "react-select";
import { IDS } from "../../../../../../utils/common";
import TableImageZoom from "../../common/TableImageZoom";
library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    isAllow,
    Select2Data,
    Id,
    sellerDomain
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);
  const [pulled, setPulled] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [searchCategary, setSearchCategary] = useState("");
  const [searchSubCategary, setSearchSubCategary] = useState("");
  const [searchStatus, setSearchStatus] = useState(null);
  const [searchOrder, setSearchOrder] = useState("");
  const getDataAll = async () => {
    const response = await getAllChildCategory(
      currentPage ? currentPage : 1,
      perPage ? perPage : 5,
      search,
      searchCategary,
      searchSubCategary,
      searchStatus,
      searchOrder,
      sellerDomain?.value
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
    const res = await pulledCategory();
    setPulled(res?.data);
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const ChangeStatus = async (id) => {
    const response = await changeChildCategoryStatus(id);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteChildCategory(recordToDeleteId);
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetAllCategory = async () => {
    const response = await AllCategory(Id, IDS.web_site_type.outer, sellerDomain?.value);
    if (response?.success) {
      setCategory(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await AllSubCategory(Id, IDS.web_site_type.outer, id);
    if (response?.success) {
      setSubCategory(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  useEffect(() => {
    GetAllCategory();
    // GetAllSubCategory();
  }, []);
  //   const BulkUpload = async (e) => {
  //     try {
  //       const formData = new FormData();
  //       formData.append("file", e.target.files[0]);
  //       const response = await postData(
  //         "/seller/outer-website/masters/brands",
  //         formData,
  //         {}
  //       );
  //       if (response?.success) {
  //         setShowModal({ code: response.code, message: response.message });
  //       } else {
  //         setShowModal({ code: response.code, message: response.message });
  //       }
  //       setTimeout(() => {
  //         setShowModal(0);
  //         setShowoff(response?.data);
  //       }, 1000);
  //       getDataAll();
  //     } catch (error) {
  //       console.error("Error uploading file:", error);
  //     }
  //   };

  //   const Sample = async (e) => {
  //     try {
  //       await getDownloadDataExcel(
  //         "/seller/outer-website/masters/brands",
  //         {},
  //         "country"
  //       );
  //     } catch (error) {
  //       console.error("Error uploading file:", error);
  //     }
  //   };

  //   const Export = async () => {
  //     try {
  //       await getDownloadDataExcel(
  //         "/seller/outer-website/masters/brands",
  //         { ids: selectAllChecked },
  //         "Brand"
  //       );
  //     } catch (error) {
  //       console.error("Error uploading file:", error);
  //     }
  //   };
  const statusOption = [
    { label: "active", value: 1 },
    { label: "Inactive", value: 0 },
  ];
  const sortOrderOption = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
  ];

  const [modalShowImgsize, setModalShowImgsize] = React.useState(false);
  const [file, setFile] = React.useState("");

  return (
    <>
      <div className="main-advancedashboard">
        {/* <Header title={"Country"} link={"/seller/masters/s-brand"} /> */}
        <section className="AdvanceDashboard">
          <div className="row">
            <div className="col-lg-12">
              <section className="Tabels tab-radio tab-radio">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="d-flex">
                      <div className="add me-3">
                        {/* {isAllow.includes(270) ? ( */}
                        <Link
                          // to="/seller/masters/s-brand/add"
                          type="button"
                          className="btn btn-add pe-3"
                        >
                          <div onClick={() => handleShow()}>
                            <img src={plus} className="plus me-2 ms-0" alt="" />
                            Add Child Category
                          </div>
                        </Link>
                        {/* ) : (
                          <></>
                        )} */}
                      </div>

                      <div className="add me-3">
                        <div className="dropdown">
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={colunms} className="columns " alt="" />
                            Column Selection
                            <img src={top} className="top ms-1" alt="" />
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                                href="#"
                              >
                                Sr. No.
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                                href="#"
                              >
                                Category
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col3")}
                                href="#"
                              >
                                Sub Category
                                {visible?.col3 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>{" "}
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col7")}
                                href="#"
                              >
                                Child Category Original Name
                                {visible?.col7 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col8")}
                                href="#"
                              >
                                Child Category Rename
                                {visible?.col8 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col4")}
                                href="#"
                              >
                                Image
                                {visible?.col4 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col5")}
                                href="#"
                              >
                                Status
                                {visible?.col5 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col6")}
                                href="#"
                              >
                                Action
                                {visible?.col6 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <div className="add me-3">
                        {/* {isAllow.includes(273) ? ( 
                        <button
                          className="btn btn-add pe-3"
                          onClick={() =>
                            document.getElementById("CountryFile").click()
                          }
                        >
                          Bulk Upload
                        </button>
                        {/* ) : (
                          <></>
                        )} 

                        <input
                          type="file"
                          id="CountryFile"
                          onChange={(e) => {
                            BulkUpload(e);
                          }}
                          accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          style={{ display: "none" }}
                        />
                      </div> */}

                      {/* <div className="add me-3">
                        {/* {isAllow.includes(274) ? ( 
                        <Link type="button" className="btn btn-add pe-3">
                          <div onClick={Sample}>Sample</div>
                        </Link>
                        {/* ) : (
                          <></>
                        )} 
                      </div> */}

                      {/* {isAllow.includes(115) ? ( */}
                      {/* <button
                        className="btn btn-columns ms-2"
                        type="button"
                        onClick={() => {
                          if (selectAllChecked.length === 0) {
                            alert("Please Select At Least One Record");
                          } else {
                            Export();
                          }
                        }}
                      >
                        Export
                      </button> */}
                      {/* ) : (
                        <></>
                      )} */}
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4 me-0 ms-0">
                      <div className="col-xxl-12 col-xl-12  col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <div className="show me-2">
                            <p className="show mt-1">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show mt-1">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className=" col-xxl-12 col-xl-12 col-lg-12  col-md-12 col-12">
                        <div className="d-flex justify-content-end">
                          <div className="sowing me-2">
                            <p className="show mt-2">{`Showing ${Math.min(
                              (currentPage - 1) * perPage + 1
                            )} to ${Math.min(
                              currentPage * perPage,
                              data?.data?.totalEntries
                            )} of ${data?.data?.totalEntries} entries`}</p>
                          </div>
                          <div className="num me-2">
                            <Select
                              value={searchStatus}
                              onChange={(selectedOption) => {
                                setSearchStatus(selectedOption);
                              }}
                              options={statusOption}
                              placeholder="status"
                              isSearchable
                            />
                          </div>

                          <div className="num me-2">
                            <Select
                              value={searchOrder}
                              onChange={(selectedOption) => {
                                setSearchOrder(selectedOption);
                              }}
                              options={sortOrderOption}
                              placeholder="order"
                              isSearchable
                            />
                          </div>
                          <div className="num me-2">
                            <Select
                              value={searchCategary}
                              onChange={(selectedOption) => {
                                setSearchCategary(selectedOption);
                                GetAllSubCategory(selectedOption.value);
                              }}
                              options={category}
                              placeholder="category"
                              isSearchable
                            />
                          </div>
                          <div className="num me-2">
                            <Select
                              value={searchSubCategary}
                              onChange={(selectedOption) => {
                                setSearchSubCategary(selectedOption);
                                // GetAllChildCategory(selectedOption.value);
                              }}
                              options={subCategory}
                              placeholder="subcategory"
                              isSearchable
                            />
                          </div>
                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Child Category"
                              id=""
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          <div className="Search me-2">
                            <button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </button>
                          </div>

                          <div className="Search-1">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setSearchStatus("");
                                setSearchSubCategary("");
                                setSearchCategary("");
                                setSearchOrder("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3 me-0 ms-0">
                    <div className="data table-responsive">
                      <Table bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {" "}
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th className="tax-name">Original Category </th>
                            )}{" "}
                            {visible.col3 && (
                              <th className="tax-name">Webiste Category </th>
                            )}{" "}
                            {visible.col4 && (
                              <th className="tax-name">
                                Original Sub Category{" "}
                              </th>
                            )}
                            {visible.col5 && (
                              <th className="tax-name">
                                Website Sub Category{" "}
                              </th>
                            )}
                            {visible.col6 && (
                              <th className="tax-name">
                                Child Category Original Name{" "}
                              </th>
                            )}
                            {visible.col7 && (
                              <th className="tax-name">
                                Child Category Rename{" "}
                              </th>
                            )}
                            {visible.col8 && (
                              <th className="tax-name">Image </th>
                            )}
                            {visible.col11 && (
                              <th className="tax-name">Sort Order</th>
                            )}
                            {visible.col9 && (
                              <th className="tax-name">Status</th>
                            )}
                            {visible.col10 && (
                              <th className="active">Action</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.data?.map((d, index) => {
                            return (
                              <tr className="" key={index}>
                                {" "}
                                {visible.col1 && <td>{++index}.</td>}
                                {visible.col2 && (
                                  <td>
                                    {d?.s_child_category?.s_category?.name}
                                  </td>
                                )}
                                {visible.col3 && (
                                  <td>
                                    {d?.s_category?.category_of_seller?.name}
                                  </td>
                                )}
                                {visible.col4 && (
                                  <td>
                                    {d?.s_child_category?.s_sub_category?.name}
                                  </td>
                                )}{" "}
                                {visible.col5 && (
                                  <td>
                                    {
                                      d?.s_sub_category?.subcategory_of_seller
                                        ?.name
                                    }
                                  </td>
                                )}{" "}
                                {visible.col6 && (
                                  <td>{d?.s_child_category?.name}</td>
                                )}
                                {visible.col7 && <td>{d?.name}</td>}
                                {visible.col8 && (
                                  <td>
                                    {d?.image && (
                                      <img
                                        onClick={() => { setFile(`${IMG_URL}${d?.image}`); setModalShowImgsize(true); }}
                                        src={`${IMG_URL}${d?.image}`} className="tbsmimgg"
                                        alt="Image"
                                        width="120"
                                        height="100"
                                      />
                                    )}
                                  </td>
                                )}
                                {visible.col11 && <td>{d?.sort_order}</td>}
                                {visible.col9 && (
                                  <td>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={d?.status}
                                        onChange={() => {
                                          ChangeStatus(d?.id);
                                        }}
                                        id={`flexSwitchCheckDefault${d?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                      >
                                        {d?.status ? "Active" : "Inactive"}
                                      </label>
                                    </div>
                                  </td>
                                )}
                                {visible.col10 && (
                                  <td>
                                    {/* {pulled.includes(d.category_id) ? (
                                      <></>
                                    ) : ( */}
                                    <div className="d-flex">
                                      {/* {isAllow.includes(271) ? ( */}
                                      <EditButton
                                        handleShow1={handleShow1}
                                        id={d?.id}
                                      />
                                      {/* ) : ( <></>
                                      )} */}
                                      {/* {isAllow.includes(272) ? ( */}
                                      <DeletButton
                                        showDeleteRecord={showDeleteRecord}
                                        id={d?.id}
                                        name={d?.name}
                                      />
                                      {/*  ) : (
                                         <></>
                                       )} */}
                                    </div>
                                    {/* )} */}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />

      <TableImageZoom
        show={modalShowImgsize}
        onHide={() => setModalShowImgsize(false)}
        file={file}
      />
    </>
  );
};

export default Tables;
