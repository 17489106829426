import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const bankVerify = async (bank_account_no, ifsc_code) => {
  try {
    const res = await postData(`/without-login/third-party/bank-verification`, {
      bank_account_no,
      ifsc_code,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const gstVerify = async (data) => {
  try {
    const res = await postData(`/seller/third-party/corporate-gst-in`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const panVerify = async (pan_no) => {
  try {
    const res = await postData(`/seller/third-party/pan-validation`, {
      pan_no,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};
