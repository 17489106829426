import { useState, useEffect, useContext } from "react";
import "./SellerstepForm.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-datepicker/dist/react-datepicker.css";
import Stepone from "./Stepone/Stepone";
import Steptwo from "./Steptwo/Steptwo";
import Stepthree from "./Stepthree/Stepthree";
import Stepfour from "./Stepfour/Stepfour";
import Stepfive from "./Stepfive/Stepfive";
import Stepsix from "./Stepsix/Stepsix";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import OverviewPage from "./OverviewPage/OverviewPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { sellerPercentage } from "../../../utils/apis/seller/Seller";
import { Context } from "../../../utils/context";
import { getSellerPersonalDetails } from "../../../utils/apis/seller/Seller";
function SellerstepForm({ onNext, onPrev }) {

  const { pathname } = useLocation();

  const [searchParam] = useSearchParams();
console.log(searchParam.get('profile'),'pathname');

  const [startDate, setStartDate] = useState();
  const [userDetails, setUserDetails] = useState();
  const [workType, setWorkType] = useState();
  const [workTypeName, setWorkTypeName] = useState();
  const [sellerEdit, setSellerEdit] = useState(false);

  const { getData, postData, IMG_URL } = useContext(Context);
  const getSellerDetails = async () => {
    console.log(Cookies.get("seller_id"), "llllllll");
    const res = await getSellerPersonalDetails(Cookies.get("seller_id"));
    if (res?.success) {
      console.log(res, "users");
      await setUserDetails(res.data);
      if(searchParam.get('profile')){
        if(searchParam.get('profile') === 'p'){
          setPage(6);
          setSellerEdit(true);
        }else{
          setSellerEdit(false);
        }
      }else{
        setSellerEdit(false);
      }
    }
  };

  useEffect(() => {
    getSellerDetails();
    window.scrollTo(0, 0);
   
  }, []);

  const [page, setPage] = useState(0);

  const nextStep = () => {
    getSellerDetails();
    window.scrollTo(0, 0);
    setPage((currPage) => currPage + 1);
    if (page === 6 && onNext) {
      onNext();
    }
  };

  const prevStep = () => {
    getSellerDetails();
    window.scrollTo(0, 0);
    setPage((currPage) => currPage - 1);
    if (page === 0 && onPrev) {
      onPrev();
    }
  };
  const prevStepInner = () => {
    window.scrollTo(0, 0);
  };

  const calculatePercentage = () => {
    const totalSteps = stepNames.length;
    const currentPage = page + 1;
    return sellerPercentage[currentPage];
  };

  const calculateProgressPercentage = () => {
    return sellerPercentage[page];
  };
  const renderHeading = () => {
    switch (page) {
      case 0:
        return "Personal Details";
      case 1:
        return "Select Working Type";
      case 2:
        return "Sector to which you are affiliated (" + workTypeName + ")";
      case 3:
        return "Please fill the below fields";
      case 4:
        return "Please fill the below fields";
      case 5:
        return "Create password for your account";
      default:
        return null;
    }
  };

  const goToStep = (stepIndex) => {
    setPage(stepIndex);
  };

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return (
          <Stepone
            nextStep={nextStep}
            heading={stepNames[0]}
            userDetails={userDetails}
          />
        );
      case 1:
        return (
          <Steptwo
            nextStep={nextStep}
            prevStep={() => prevStep(0)}
            setWorkType={setWorkType}
            workType={workType}
            setWorkTypeName={setWorkTypeName}
            userDetails={userDetails}
          />
        );
      case 2:
        return (
          <Stepthree
            nextStep={nextStep}
            prevStep={() => prevStep(1)}
            workType={workType}
            userDetails={userDetails}
          />
        );
      case 3:
        return (
          <Stepfour
            prevStep={() => prevStep(2)}
            setPage={setPage}
            userDetails={userDetails}
          />
        );
      case 4:
        return (
          <Stepfive
            nextStep={nextStep}
            prevStep={() => prevStep(3)}
            userDetails={userDetails}
          />
        );
      case 5:
        return (
          <Stepsix
            nextStep={nextStep}
            prevStep={() => prevStep(4)}
            userDetails={userDetails}
          />
        );
      case 6:
        return (
          <OverviewPage
            prevStep={() => prevStep(5)}
            goToStep={goToStep}
            userDetails={userDetails}
            workTypeName={workTypeName}
            sellerEdit={sellerEdit}
          />
        );
      default:
        return null;
    }
  };



  const stepNames = [
    "Personal Details",
    "Working Type",
    "Industry Details",
    "Firm Details",
    "Bank Details",
    "Password",
    "Overview",
    "fill the details",
  ];

  return (
    <>
      <section className="SellerStepMain">
        <div className=" mt-md-3 mt-3">
          <div className="d-md-flex d-flex-unset">
            <div className="">
            {!sellerEdit && (
              <Link to="/seller/seller_home" className="left-goto-homee">
                <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> Go to home
              </Link>
            )}
            </div>
            <div className="title-stttee-align">
            {!sellerEdit ? (
              <h6>Seller Registration Form</h6>
            ):(
              <h6>Seller Update Form</h6>
            )}
            </div>
          </div>
        </div>
        {page !== 6 ? (
          <div className="ProgressBar">
            <ProgressBar
              percent={calculatePercentage()}
              filledBackground="#1C42A9"
              height="3px"
              style={{ margin: "auto" }}
            >
              {[1, 2, 3, 4, 5, 6].map((icon, index) => (
                <Step key={index}>
                  {({ accomplished }) => (
                    <div
                      style={{
                        backgroundColor: accomplished ? "#1C42A9" : "#ffffff",
                        color: accomplished ? "#fff" : "#000000",
                        border: accomplished
                          ? "1px solid #1C42A9"
                          : "1px solid #FFFFFF",
                        boxShadow: accomplished
                          ? "none"
                          : " 0px 0px 6px #00000029",
                      }}
                      className={`step ${accomplished ? "completed" : null}`}
                    >
                      <p className="mb-0">{icon}</p>
                      <div className="step-name">{stepNames[index]}</div>
                    </div>
                  )}
                </Step>
              ))}
            </ProgressBar>
          </div>
        ) : (
          <></>
        )}

        <div className={page !== 6 ? "userForm" : "overviewMain"}>
          <div className="personal-details-form step-Form-Conteiner ">
            {/* form-heading-holder */}
            <div className="form-header-holder">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="heading-holder">
                    <h6>
                      {page === 0
                        ? "Personal Details"
                        : page === 1
                          ? "Select Working Type"
                          : page === 2
                            ? "Sector to which you are affiliated (" +
                            workTypeName +
                            ")"
                            : page === 3
                              ? "Please fill the below fields"
                              : page === 4
                                ? "Please fill the below fields"
                                : page === 5
                                  ? "Create password for your account"
                                  : page === 6
                                    ? "Overview"
                                    : null}
                    </h6>
                  </div>
                </div>
                {!sellerEdit && (
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="span-holder">
                    <span>Just a few steps to go</span>
                    <div style={{ width: "40px", height: "40px" }}>
                      <CircularProgressbar
                        value={calculateProgressPercentage()}
                        text={`${calculateProgressPercentage()}%`}
                      />
                    </div>
                  </div>
                </div>
                )}
              </div>
            </div>
            <div className="">{PageDisplay()}</div>
            {!sellerEdit && (
            <p>Already have account? <Link to={'/seller/sellerlogin'}>Click here</Link> </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default SellerstepForm;
