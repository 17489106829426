// InnerPageOne.js

import React, { useEffect, useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import TermsAndConditionModal from "../../Terms&Condition/TermsAndConditionModal";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { gstVerify } from "../../../../../utils/apis/third_party/third_party";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../utils/context";
import { ShimmerTitle } from "react-shimmer-effects";
import { RegxExpression } from "../../../../../utils/common";
import {
  firmDetailsPost,
  getfirmDetails,
  getFirmDetailName,
} from "../../../../../utils/apis/checker/checker";

const InnerPageOne = ({
  nextInnerPage,
  prevInnerPage,
  handleBack,
  checkerID,
  checkerDetails,
  firmName,
  setFirmname,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const handleNext = () => {
    nextInnerPage();
  };

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: true };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  console.log(errors);

  const { getData, postData, IMG_URL, shimmerLoader, setShimmerLoader } =
    useContext(Context);

  const onSubmit = async (d) => {
    if (verifyStatus) {
      const data = new FormData();

      if (checkId != "") {
        data.append("id", checkId);
      }
      data.append("gst_image", d.gst_image[0]);
      data.append("gst_no", d.gst_no);
      data.append("c_personal_details_id", checkerID);

      const res = await firmDetailsPost(data);
      if (res?.success) {
        console.log(res, "success");
        handleNext();
      }
    } else {
      if (!verifyStatus) {
        setError("gst_no", {
          type: "manual",
          message: "Verify your GST No.",
        });
      }
    }
  };
  const [selectedGSTImages, setSelectedGSTImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedGSTImage(file);
    setStoreGst(null);
    clearErrors("gst_image");
  };

  const handleRemoveFile = () => {
    setSelectedGSTImage(null);
    const fileInput = document.getElementById("fileGSTInput");
    fileInput.value = "";
  };

  const [checkId, setfirmId] = useState("");
  const [storeGst, setStoreGst] = useState(null);

  const getCheckerFirmDetails = async () => {
    const res = await getfirmDetails(checkerID);
    if (res?.success) {
      console.log("CheckerFirmDetail", res);
      setStoreGst(IMG_URL + res.data.gst_image);
      setfirmId(res.data.id);
      setVerifyStatus(true);
      reset(res.data);
    }
  };

  const [verifyStatus, setVerifyStatus] = useState(false);
  const verifyGst = async () => {
    setShimmerLoader(true);
    var data = {
      c_personal_details_id: checkerID,
      gst_no: getValues("gst_no"),
    };
    const res = await gstVerify(data);
    if (res?.success) {
      setFirmname(res?.data?.data?.business_name);
      setValue("name", res.data.data.business_name);
      clearErrors();
      setVerifyStatus(true);
      setShimmerLoader(false);
    } else {
      setVerifyStatus(false);
      setError("gst_no", {
        type: "manual",
        message: res.message.message,
      });
      setShimmerLoader(false);
    }
  };

  useEffect(() => {
    getCheckerFirmDetails();
    console.log("checkerID:-", checkerID);
  }, []);

  return (
    <div className="firmDetailsFirst">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-6">
            <Form.Group className="mb-4 bankk-verrr" controlId="formBasicEmail">
              <Form.Label>GST Number</Form.Label>
              {shimmerLoader ? (
                <ShimmerTitle line={1} gap={10} />
              ) : (
                <>
                  <Form.Control
                    type="text"
                    name="gst_no"
                    placeholder="Enter your GST number"
                   c
                    className={classNames("", {
                      "is-invalid": errors?.gst_no,
                      "is-valid": verifyStatus,
                    })}
                    onChange={() => {
                      setError("gst_no", "");
                      setVerifyStatus(false);
                    }}
                  />{" "}
                  {!verifyStatus && (
                    <button
                      type="button"
                      className="verrrr-bttt btn-info"
                      onClick={verifyGst}
                    >
                      Verify
                    </button>
                  )}
                </>
              )}
            </Form.Group>

            <div className="uploadGsthead">
              <h3>Upload GST Document</h3>
            </div>
            <div className="d-flex">
              <div className="uploadBtnsec">
                {storeGst === null ? (
                  <input
                    type="file"
                    id="fileGSTInput"
                    accept="image/*"
                    {...register("gst_image", {
                      required: "GST card image is required",
                    })}
                    onChange={handleFileChange}
                  />
                ) : (
                  <input
                    type="file"
                    id="fileGSTInput"
                    accept="image/*"
                    {...register("gst_image")}
                    onChange={handleFileChange}
                  />
                )}
              </div>
              {selectedGSTImages && (
                <div className="previewImgmain">
                  <img
                    src={URL.createObjectURL(selectedGSTImages)}
                    alt={`Preview of ${selectedGSTImages.name}`}
                    className="previewImg"
                  />
                  <div>
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-xmark"
                      className="circlecrossMark"
                      onClick={handleRemoveFile}
                    />
                  </div>
                </div>
              )}
              {storeGst !== null && (
                <div className="previewImgmain">
                  <img src={storeGst} className="previewImg" />
                </div>
              )}
            </div>
            {errors.gst_image && (
              <span className="text-danger">{errors.gst_image.message}</span>
            )}
          </div>
        </div>

        <div className="col-md-12">
          <div className="text-end apply_now_btn">
            {/* <p onClick={() => setModalShow(true)}>
              By continuing, I agree to Netpurti's
              <span className="termstxt">Terms of Use & Privacy Policy</span>
            </p> */}

            <Button onClick={handleBack} className="back-btn">
              Back
            </Button>

            <Button
              // onClick={handleNext}
              type="submit"
              className="tabs-btn"
            >
              Register and Continue
            </Button>

            {/* <Button
              onClick={handleNext}
              className="tabs-btn">
              Next
            </Button> */}

            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default InnerPageOne;
