import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoditEditor from "jodit-react";
import classNames from "classnames";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useEffect } from "react";
import { ProductInfoIcon } from "../../../../../utils/apis/master/Master";
import { ProductNarrationChange } from "../../../../../utils/common";

function Guarenteetab({
  reset,
  errors,
  register,
  Controller,
  control,
  getValues,
  setValue,
  working_type,
  useFieldArray,
}) {
  const [content, setContent] = useState("");

  const [productInfoIcon, setProductInfoIcon] = useState([]);

  const [provided, setProvided] = useState([
    {
      value: "Brand",
      label: "Brand",
    },
    {
      value: "other",
      label: "Other",
    },
  ]);

  const [providedInput, setProvidedInput] = useState(false);
  const handleChange = async (d) => {
    await setValue("g_provided_by", d);
    if (d.value === "other") {
      setProvidedInput(true);
    } else {
      setProvidedInput(false);
    }
  };

  const getDataAll = async () => {
    {
      const res = await ProductInfoIcon();
      if (res?.success) {
        setProductInfoIcon(res?.data);
      }
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const { append, fields, remove } = useFieldArray({
    control,
    name: "warrenty_details",
  });

  const validateWordCount = (value) => {
    const wordCount = value.length;
    return wordCount <= 100 || "Maximum 60 words are allowed";
  };
  return (
    <>
      <section className="guarentee-tab">
        <div className="py-2">
          <div className="row">
            <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Guarantee Provided By <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-title">
                        {productInfoIcon?.warrenty_provided_by}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Controller
                  name="g_provided_by"
                  {...register("g_provided_by", {
                    required: "Guarantee Provided By is required",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors?.g_provided_by
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      options={provided}
                      onChange={(e) => handleChange(e)}
                    />
                  )}
                />
                {getValues("g_provided_by")?.value === "other" && (
                  <Form.Control
                    type="text"
                    placeholder="Guarantee Provided By"
                    className={classNames("mt-3", {
                      "is-invalid": errors?.g_other_provided_by,
                    })}
                    name="g_other_provided_by"
                    {...register("g_other_provided_by", {
                      required: "Guarantee is required",
                    })}
                  />
                )}
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Guarantee Period of Product{" "}
                  {working_type == 1
                    ? "in Day"
                    : working_type == 2 && "As Per Brand"}{" "}
                  <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-category">
                        {productInfoIcon?.warrenty_period_of_product}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Guarantee Period"
                  className={classNames("", {
                    "is-invalid": errors?.g_period,
                  })}
                  name="g_period"
                  {...register("g_period", {
                    required: "Guarantee Period is required",
                  })}
                />
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Helpline Number <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-brand">
                        {productInfoIcon?.helpline_no}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Helpline"
                  className={classNames("", {
                    "is-invalid": errors?.g_helpline,
                  })}
                  name="g_helpline"
                  {...register("g_helpline", {
                    required: "Helpline is required",
                  })}
                />
              </Form.Group>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="select-to-div">
            <Form.Label>
              Guarantee Terms :- Maximum 100 words are allowed
            </Form.Label>
            {fields?.map((value, index) => (
              <>
                <div className="col-xxl-12 col-md-12 col-12 align-items-center d-flex mt-auto mb-2">
                  <Form.Control
                    type="text"
                    placeholder="Points"
                    className={classNames("me-2", {
                      "is-invalid":
                        errors?.warrenty_details &&
                        errors?.warrenty_details[index] &&
                        errors?.warrenty_details[index]?.name,
                    })}
                    name={`warrenty_details[${index}].name`}
                    {...register(`warrenty_details[${index}].name`, {
                      required: "Points is required",
                      validate: validateWordCount,
                    })}
                    onChange={async (e) => {
                      setValue(
                        `warrenty_details[${index}].name`,
                        await ProductNarrationChange(e)
                      );
                    }}
                  />{" "}
                  {fields?.length !== 1 && (
                    <button
                      className="delet-row"
                      onClick={() => remove(index)}
                      type="button"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-minus" />
                    </button>
                  )}
                </div>
              </>
            ))}
            {fields?.length < 10 && (
              <button
                className="addrowiccc"
                type="button"
                style={{
                  float: "right",
                }}
                onClick={() =>
                  append({
                    name: "",
                  })
                }
              >
                <img
                  style={{ margin: "0px" }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/Seller_panel/dashboard/icons/addicooo.png"
                  }
                />
              </button>
            )}
            {/* <Controller
              name="g_term"
              {...register("g_term", {
                required: "g_term is required",
              })}
              control={control}
              render={({ field }) => (
                <JoditEditor
                  value={content}
                  tabIndex={1}
                  onBlur={(newContent) => setContent(newContent)}
                  {...field}
                />
              )}
            /> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Guarenteetab;
