import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Container } from "react-bootstrap";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "./WomenSeller.css";

const WomenSeller = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const WomenSellerDetails = [
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/sellersuccessstory/w1.png",
      headingname: "Introducing Disha Marathe:",
      womencontent:
        " From hard work to self-confidence, Ekta's journey led to 40 successful brands on Flipkart. An inspiring tale of entrepreneurial triumph!",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/sellersuccessstory/w2.png",
      headingname: "Introducing jini:",
      womencontent:
        " From hard work to self-confidence, Ekta's journey led to 40 successful brands on Flipkart. An inspiring tale of entrepreneurial triumph!",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/sellersuccessstory/w3.png",
      headingname: "Introducing Sonal Agrwal:",
      womencontent:
        " From hard work to self-confidence, Ekta's journey led to 40 successful brands on Flipkart. An inspiring tale of entrepreneurial triumph!",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/sellersuccessstory/w4.png",
      headingname: "Introducing Ekta Shah:",
      womencontent:
        " From hard work to self-confidence, Ekta's journey led to 40 successful brands on Flipkart. An inspiring tale of entrepreneurial triumph!",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/sellersuccessstory/w1.png",
      headingname: "Introducing Sonal Agrwal:",
      womencontent:
        " From hard work to self-confidence, Ekta's journey led to 40 successful brands on Flipkart. An inspiring tale of entrepreneurial triumph!",
    },
  ];

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/success-stories/s-women-seller`);
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <section className="women-seller">
      <Container>
        <div className="section-title">
          <h4>Netpurti's Women Sellers</h4>
        </div>
        <Swiper
          modules={[Pagination]}
          spaceBetween={20}
          onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            360: {
              slidesPerView: 1,
            },
            467: {
              slidesPerView: 2,
            },
            567: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 2,
            },

            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1440: {
              slidesPerView: 4,
            },
            2000: {
              slidesPerView: 4,
            },
            2560: {
              slidesPerView: 4,
            },
            3000: {
              slidesPerView: 4,
            },
          }}
        >
          {data?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="main-img-class mb-5">
                {item?.image && (
                  <img
                    src={IMG_URL + item?.image}
                    className="women-imgg"
                    alt="..."
                  />
                )}
                <div className="person-name-class">
                  <p className="title-womenn">{item?.name}</p>
                  <p className="womencontent">
                    {parse(item?.description) || ""}
                  </p>
                </div>
                <div className="black-overlay"></div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div
          className="main-slider"
          onClick={() => handleMainSliderClick("prev")}
        >
          <div className="arrow-back" />
        </div>
        <div
          className="main-slider"
          onClick={() => handleMainSliderClick("next")}
        >
          <div className="arrow-next" />
        </div>
      </Container>
    </section>
  );
};

export default WomenSeller;
