import React from 'react'
import './netp_call_support.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset } from '@fortawesome/free-solid-svg-icons'
import CallSupportModal from '../../CommonForAll/callSupportModal/CallSupportModal'

const Call_support = () => {

    const [modalShow, setModalShow] = React.useState(false);
    
    return (
        <>
            <div className='Call_support-sec'>
                <div className='icon-div' type="button" onClick={() => setModalShow(true)}>
                    <FontAwesomeIcon className='supporticon' icon={faHeadset} />
                </div>
            </div>

            <CallSupportModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Call_support