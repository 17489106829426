import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import "./SellSmarter.css";
const SellSmarter = () => {
  const cardContents = [
    "Showcase your products to a vast online audience, boosting sales.",
    "Effortlessly organize inventory, track orders, and manage promotions hassle-free.",
    "Enjoy secure transactions and receive instant payments for your products.",
    "Encourage positive reviews, building trust and credibility among potential buyers",
  ];

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/home/s-sell-smart`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="sell-smarter">
        <div className="container">
          <div className="row">
            <div className="heading-holder mb-lg-3 mb-md-5 mb-sm-3 mb-3">
              <h4>Sell Smarter, Not Harder</h4>
              <p>Unlock Seller Success With Netpurti</p>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-5 col-md-5">
              <div className="girls-image  mt-3">
                <img
                  className="girl-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/Seller_panel/seller-image/banner/girl-img.png"
                  }
                />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-7">
              <div className="row">
                {data?.map((item, index) => (
                  <div className="col-md-6 col-sm-6" key={index}>
                    <div className="white-card mb-4">
                      <p>{item?.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SellSmarter;
