import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import "../../HeaderAllCategories/Categorione.css"
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import SelectedTrendingProducts_NewArrivals from '../../Home/SelectedTrendingProducts_NewArrivals/SelectedTrendingProducts_NewArrivals';
library.add(fas);

const Categoritwo = () => {
    return (
        <>
            <section className='categories-mobile-page'>
                <div className='container'>
                    <div className='heading'>
                        <Link to="/b2bbuyer/first-categori">
                            <FontAwesomeIcon icon="fa-solid fa-angle-left" />
                        </Link>
                        <div className='mx-auto'>
                            <h1>All categories</h1>
                        </div>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className='nav-head'>
                            <Nav variant="pills" className="d-flex">
                                <Nav.Item>
                                    <Nav.Link eventKey="first"><p>Agriculture Machinery</p></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second"><p>Machine Tools</p></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third"><p>Other Machinery & Parts</p></Nav.Link>
                                </Nav.Item>

                            </Nav>
                        </div>
                        <Tab.Content className='my-4'>
                            <Tab.Pane eventKey="first">

                                <Swiper
                                    breakpoints={{
                                        0: { slidesPerView: 3 },
                                        360: { slidesPerView: 3 },
                                        576: { slidesPerView: 4, },
                                        640: { slidesPerView: 6 },
                                        768: { slidesPerView: 6 },
                                        1200: { slidesPerView: 6 },
                                        1800: { slidesPerView: 4 },
                                        2500: { slidesPerView: 4 },
                                    }}
                                    pagination={{
                                        dynamicBullets: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper row-div"
                                >
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>

                                </Swiper>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                            <Swiper
                                    breakpoints={{
                                        0: { slidesPerView: 3 },
                                        360: { slidesPerView: 3 },
                                        576: { slidesPerView: 4, },
                                        640: { slidesPerView: 6 },
                                        768: { slidesPerView: 6 },
                                        1200: { slidesPerView: 6 },
                                        1800: { slidesPerView: 4 },
                                        2500: { slidesPerView: 4 },
                                    }}
                                    pagination={{
                                        dynamicBullets: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper row-div"
                                >
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>

                                </Swiper>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                            <Swiper
                                    breakpoints={{
                                        0: { slidesPerView: 3 },
                                        360: { slidesPerView: 3 },
                                        576: { slidesPerView: 4, },
                                        640: { slidesPerView: 6 },
                                        768: { slidesPerView: 6 },
                                        1200: { slidesPerView: 6 },
                                        1800: { slidesPerView: 4 },
                                        2500: { slidesPerView: 4 },
                                    }}
                                    pagination={{
                                        dynamicBullets: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper row-div"
                                >
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                        <div>
                                            <div className='img-bg'>
                                                <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                            </div>
                                            <p>Agriculture <br></br> Machinery</p>
                                        </div>
                                    </SwiperSlide>

                                </Swiper>
                            </Tab.Pane>

                        </Tab.Content>


                    </Tab.Container>


                    <SelectedTrendingProducts_NewArrivals/>
                </div>
            </section>

        </>
    )
}

export default Categoritwo