import React, { useEffect, useState } from "react";
import "./View_all_varients_offcanvas.css";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Mrp_modal from "../../../../../CommonForAll/mrp-modal/Mrp_modal";
import Stock_modal from "../../../../../CommonForAll/mrp-modal/Stock_modal";
import { Context } from "../../../../../../utils/context";
import { useContext } from "react";
import { StockChange, MrpChange, variantStatus } from "../../../../../../utils/apis/seller/Product";
import { ShimmerTable } from "react-shimmer-effects";

const View_all_varients_offcanvas = (props) => {
  const [show, setShow] = useState(false);
  const [modalShowedit, setModalShowedit] = useState(false);

  const { IMG_URL, shimmerLoader, setShimmerLoader } = useContext(Context);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modalShow, setModalShow] = useState(false);
  const [stockModalShow, setStockModalShow] = useState(false);

  const [mrp, setMrp] = useState(0);
  const [stock, setStock] = useState(0);
  const [type, setType] = useState("");
  const [varid, setData] = useState("");
  const [changeValue, setChangeValue] = useState(1);
  const [productId, setProductId] = useState(props?.variantData?.s_product_variant?.s_product_id);

  const handleMrp = async () => {
    setShimmerLoader(true);
    var data = {
      id: varid,
      mrp: mrp,
      type: type,
      s_product_id: productId,
    }
    const res = await MrpChange(data);
    if (res?.success) {
      console.log();
      props.getVariantProducts();
      setShimmerLoader(false);
    }
  }



  const handleStock = async () => {
    setShimmerLoader(true);
    var data = {
      id: varid,
      value: changeValue,
      stock: stock,
      type: type,
      s_product_id: productId,
    }
    console.log(data);
    const res = await StockChange(data);
    if (res?.success) {
      console.log();
      props.getVariantProducts();
      setShimmerLoader(false);
    }
  }

  const handleStatus = async (id, type, level,pro_id) => {
    setShimmerLoader(true);
    var data = {
      id: id,
      level: level,
      type: type,
      s_product_id: pro_id,
    }
    const res = await variantStatus(data);
    if (res?.success) {
      console.log();
      props.getVariantProducts();
      setShimmerLoader(false);
    }
  }
  console.log(mrp);
  return (
    <>
      <section className="View_all_varients_offcanvas">
        <Offcanvas
          className="variants-offcanvas offcanvas-top"
          show={show}
          onHide={handleClose}
          {...props}
        >
          <div className="container ">
            <Offcanvas.Header closeButton onClick={props.onHide}>
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="variats-table-sec">
              {shimmerLoader ? (
                <ShimmerTable row={5} col={7} />
              ) : (
                <table className="table table-responsive table-bordered">
                  <thead>
                    <tr className="">
                      <td>
                        Product Detail{" "}
                        <FontAwesomeIcon
                          className="fafdowncaret"
                          icon="fa-solid fa-caret-down"
                        />
                      </td>

                      <td>
                        Product Image
                        <FontAwesomeIcon
                          className="fafdowncaret"
                          icon="fa-solid fa-caret-down"
                        />
                      </td>

                      <td>
                        Variants
                        <FontAwesomeIcon
                          className="fafdowncaret"
                          icon="fa-solid fa-caret-down"
                        />
                      </td>

                      {/* <td>
                        Thumbnail
                        <FontAwesomeIcon
                          className="fafdowncaret"
                          icon="fa-solid fa-caret-down"
                        />
                      </td> */}

                      <td>
                        Stock
                        <FontAwesomeIcon
                          className="fafdowncaret"
                          icon="fa-solid fa-caret-down"
                        />
                      </td>

                      <td>
                        MRP
                        <FontAwesomeIcon
                          className="fafdowncaret"
                          icon="fa-solid fa-caret-down"
                        />
                      </td>
                      <td>
                        Status
                        <FontAwesomeIcon
                          className="fafdowncaret"
                          icon="fa-solid fa-caret-down"
                        />
                      </td>

                      {/* <td>
                      Edit Product
                      <FontAwesomeIcon
                        className="fafdowncaret"
                        icon="fa-solid fa-caret-down"
                      />
                    </td> */}
                    </tr>
                  </thead>
                  <tbody>


                    {props?.variantData?.s_product_variant?.variant_type === "Multiple" ? (

                      props?.variantData?.s_product_variant?.s_p_v_a_ones?.map((s_p_v_a_ones_value, s_p_v_a_ones_index) => (
                        s_p_v_a_ones_value?.s_p_v_a_one_ds?.map((s_p_v_a_one_ds_value, s_p_v_a_one_ds_index) => (
                          s_p_v_a_one_ds_value?.s_p_v_a_one_d_as?.map((s_p_v_a_one_d_as_value, s_p_v_a_one_d_as_index) => (
                            s_p_v_a_one_d_as_value?.s_p_v_a_one_d_a_ds?.map((s_p_v_a_one_d_a_ds_value, s_p_v_a_one_d_a_ds_index) => (
                              s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as.length ? (
                                s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as?.map((s_p_v_a_one_d_a_d_as_value, s_p_v_a_one_d_a_d_as_index) => (
                                  s_p_v_a_one_d_a_d_as_value?.s_p_v_a_one_d_a_d_a_ds?.map((s_p_v_a_one_d_a_d_a_ds_value, s_p_v_a_one_d_a_d_a_ds_index) => (
                                    // LEVEL 3
                                    <tr>
                                      <td>
                                        {" "}
                                        {props?.variantData?.product_title}{" "}
                                        {/* <p className="skiddd">SKU ID: KS944RUR</p>{" "} */}
                                      </td>
                                      <td>
                                        <img
                                          className="keybord-img"
                                          src={
                                            IMG_URL +
                                            s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_o_d?.thumbnail
                                          }
                                        />
                                      </td>
                                      <td>
                                        <span><b>{s_p_v_a_ones_value?.s_attribute?.name}:</b> {s_p_v_a_one_ds_value?.s_sub_attribute?.name}</span>
                                        <br></br>
                                        <span><b>{s_p_v_a_one_d_as_value?.s_attribute?.name}:</b> {s_p_v_a_one_d_a_ds_value?.s_sub_attribute?.name}</span>
                                        <br></br>
                                        <span><b>{s_p_v_a_one_d_a_d_as_value?.s_attribute?.name}:</b> {s_p_v_a_one_d_a_d_a_ds_value?.s_sub_attribute?.name}</span>
                                      </td>

                                      {/* <td>
                                            <img
                                              className="keybord-img"
                                              src={
                                                IMG_URL +
                                                s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_o_d?.thumbnail
                                              }
                                            />
                                          </td> */}

                                      <td>
                                        <div className="d-flex justify-content-center">
                                            <div
                                              className="plus-icon"
                                              // onClick={() => handleStock(s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.id, 0, value?.variant_type)}
                                              onClick={() => {
                                                setType(props?.variantData?.s_product_variant?.variant_type);
                                                setData(s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.id);
                                                setChangeValue(0);
                                                setProductId(props?.variantData?.s_product_variant?.s_product_id);
                                                setStockModalShow(true);
                                              }}
                                            >
                                              <FontAwesomeIcon icon="fa-solid fa-minus" />
                                            </div>

                                          {s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.stock}{" "}

                                            <div
                                              className="plus-icon"
                                              // onClick={() => handleStock(s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.id, 1, value?.variant_type)}
                                              onClick={() => {
                                                setType(props?.variantData?.s_product_variant?.variant_type);
                                                setData(s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.id);
                                                setChangeValue(1);
                                                setProductId(props?.variantData?.s_product_variant?.s_product_id);
                                                setStockModalShow(true);
                                              }}
                                            >
                                              <FontAwesomeIcon icon="fa-solid fa-plus" />
                                            </div>

                                        </div>
                                      </td>
                                      <td>
                                        <div className="d-flex  justify-content-center">
                                          {s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp}
                                            <div
                                              className="plus-icon"
                                              onClick={() => {
                                                setType(props?.variantData?.s_product_variant?.variant_type);
                                                setMrp(s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
                                                setData(s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.id);
                                                setProductId(props?.variantData?.s_product_variant?.s_product_id);
                                                setModalShow(true);
                                              }}
                                            >
                                              <FontAwesomeIcon icon="fa-solid fa-pen" />
                                            </div>
                                        </div>
                                      </td>

                                      <td>
                                        <button
                                          className={s_p_v_a_one_d_a_d_a_ds_value?.status === 1 ? "active-btn" : "inactive-btn"}
                                          onClick={() => 
                                            handleStatus(s_p_v_a_one_d_a_d_a_ds_value?.id, props?.variantData?.s_product_variant?.variant_type, 3,props?.variantData?.s_product_variant?.s_product_id)}
                                        >
                                          {s_p_v_a_one_d_a_d_a_ds_value?.status === 1 ? "Active" : "InActive"}
                                        </button>
                                      </td>
                                      {/* <td>
                                          <button
                                            className="action-btn"
                                            onClick={() => setModalShowedit(true)}
                                          >
                                            Action
                                          </button>
                                        </td> */}
                                    </tr>

                                  ))
                                ))
                              ) : (

                                // LEVEL 2
                                <tr>
                                  <td>
                                    {" "}
                                    {props?.variantData?.product_title}{" "}
                                    {/* <p className="skiddd">SKU ID: KS944RUR</p>{" "} */}
                                  </td>
                                  <td>
                                    <img
                                      className="keybord-img"
                                      src={
                                        IMG_URL +
                                        s_p_v_a_one_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_o_d?.thumbnail
                                      }
                                    />
                                  </td>
                                  <td>
                                    <span><b>{s_p_v_a_ones_value?.s_attribute?.name}:</b> {s_p_v_a_one_ds_value?.s_sub_attribute?.name}</span>
                                    <br></br>
                                    <span><b>{s_p_v_a_one_d_as_value?.s_attribute?.name}:</b> {s_p_v_a_one_d_a_ds_value?.s_sub_attribute?.name}</span>
                                  </td>

                                  {/* <td>
                                        <img
                                          className="keybord-img"
                                          src={
                                            IMG_URL +
                                            s_p_v_a_one_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_o_d?.thumbnail
                                          }
                                        />
                                      </td> */}

                                  <td>
                                    <div className="d-flex justify-content-center">
                                        <div
                                          className="plus-icon"
                                          onClick={() => {
                                            setType(props?.variantData?.s_product_variant?.variant_type);
                                            setData(s_p_v_a_one_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.id);
                                            setChangeValue(0);
                                            setProductId(props?.variantData?.s_product_variant?.s_product_id);
                                            setStockModalShow(true);
                                          }}
                                        >
                                          <FontAwesomeIcon icon="fa-solid fa-minus" />
                                        </div>
                                      {s_p_v_a_one_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.stock}{" "}
                                        <div
                                          className="plus-icon"
                                          onClick={() => {
                                            setType(props?.variantData?.s_product_variant?.variant_type);
                                            setData(s_p_v_a_one_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.id);
                                            setChangeValue(1);
                                            setProductId(props?.variantData?.s_product_variant?.s_product_id);
                                            setStockModalShow(true);
                                          }}
                                        >
                                          <FontAwesomeIcon icon="fa-solid fa-plus" />
                                        </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex  justify-content-center">
                                      {s_p_v_a_one_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp}
                                        <div
                                          className="plus-icon"
                                          onClick={() => {
                                            setType(props?.variantData?.s_product_variant?.variant_type);
                                            setMrp(s_p_v_a_one_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
                                            setData(s_p_v_a_one_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.id);
                                            setProductId(props?.variantData?.s_product_variant?.s_product_id);
                                            setModalShow(true);
                                          }}
                                        >
                                          <FontAwesomeIcon icon="fa-solid fa-pen" />
                                        </div>

                                    </div>
                                  </td>

                                  <td>
                                    <button
                                      className={s_p_v_a_one_d_a_ds_value?.status === 1 ? "active-btn" : "inactive-btn"}
                                      onClick={() => 
                                        handleStatus(s_p_v_a_one_d_a_ds_value?.id, props?.variantData?.s_product_variant?.variant_type, 2,props?.variantData?.s_product_variant?.s_product_id)}
                                    >
                                      {s_p_v_a_one_d_a_ds_value?.status === 1 ? "Active" : "InActive"}
                                    </button>
                                  </td>
                                  {/* <td>
                                          <button
                                            className="action-btn"
                                            onClick={() => setModalShowedit(true)}
                                          >
                                            Action
                                          </button>
                                        </td> */}
                                </tr>

                              )

                            ))
                          ))

                        ))
                      ))

                    ) : (

                      props?.variantData?.s_product_variant?.s_product_variant_attribute?.s_product_variant_attribute_details?.map((value, index) => (
                        // LEVEL 3
                        <tr>
                          <td>
                            {" "}
                            {props?.variantData?.product_title}{" "}
                            {/* <p className="skiddd">SKU ID: KS944RUR</p>{" "} */}
                          </td>
                          <td>
                            <img
                              className="keybord-img"
                              src={
                                IMG_URL +
                                value?.thumbnail
                              }
                            />
                          </td>
                          <td>
                            <span><b>{props?.variantData?.s_product_variant?.s_product_variant_attribute?.s_attribute?.name}:</b> {value?.s_sub_attribute?.name}</span>
                          </td>

                          {/* <td>
                                <img
                                  className="keybord-img"
                                  src={
                                    IMG_URL +
                                    s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_o_d?.thumbnail
                                  }
                                />
                              </td> */}

                          <td>
                            <div className="d-flex justify-content-center">
                              <div
                                className="plus-icon"
                                // onClick={() => handleStock(s_p_v_a_one_d_a_d_a_ds_value?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.id, 0, value?.variant_type)}
                                onClick={() => {
                                  setType(props?.variantData?.s_product_variant?.variant_type);
                                  setData(value?.s_p_v_a_d_price_a_stock?.id);
                                  setChangeValue(0);
                                  setProductId(props?.variantData?.s_product_variant?.s_product_id);
                                  setStockModalShow(true);
                                }}
                              >
                                <FontAwesomeIcon icon="fa-solid fa-minus" />
                              </div>

                              {value?.s_p_v_a_d_price_a_stock?.stock}{" "}

                              <div
                                className="plus-icon"
                                // onClick={() => handleStock(value?.s_p_v_a_d_price_a_stock?.id, 1, value?.variant_type)}
                                onClick={() => {
                                  setType(props?.variantData?.s_product_variant?.variant_type);
                                  setData(value?.s_p_v_a_d_price_a_stock?.id);
                                  setChangeValue(1);
                                  setProductId(props?.variantData?.s_product_variant?.s_product_id);
                                  setStockModalShow(true);
                                }}
                              >
                                <FontAwesomeIcon icon="fa-solid fa-plus" />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex  justify-content-center">
                              {value?.s_p_v_a_d_price_a_stock?.mrp}
                                <div
                                  className="plus-icon"
                                  onClick={() => {
                                    setType(props?.variantData?.s_product_variant?.variant_type);
                                    setMrp(value?.s_p_v_a_d_price_a_stock?.mrp);
                                    setData(value?.s_p_v_a_d_price_a_stock?.id);
                                    setProductId(props?.variantData?.s_product_variant?.s_product_id);
                                    setModalShow(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-pen" />
                                </div>

                            </div>
                          </td>

                          <td>
                            <button
                              className={value?.status === 1 ? "active-btn" : "inactive-btn"}
                              onClick={() => handleStatus(value?.id, props?.variantData?.s_product_variant?.variant_type, 1,props?.variantData?.s_product_variant?.s_product_id)}
                            >
                              {value?.status === 1 ? "Active" : "InActive"}
                            </button>
                          </td>
                          {/* <td>
                              <button
                                className="action-btn"
                                onClick={() => setModalShowedit(true)}
                              >
                                Action
                              </button>
                            </td> */}
                        </tr>

                      ))
                    )}


                  </tbody>
                </table>
              )}
            </Offcanvas.Body>
          </div>
        </Offcanvas>
      </section>

      <Mrp_modal
        show={modalShow} onHide={() =>
          setModalShow(false)}

        data={varid}

        mrp={mrp}

        setMrp={setMrp}

        handleMrp={handleMrp}
      />
      <Stock_modal
        show={stockModalShow} onHide={() =>
          setStockModalShow(false)}

        data={varid}

        stock={stock}

        setStock={setStock}
        changeValue={changeValue}
        handleStock={handleStock}
      />
    </>
  );
};

export default View_all_varients_offcanvas;
