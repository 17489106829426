import React from 'react'
import { Form } from "react-bootstrap";
import ButtonComponent from "../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import InquiryModal from '../../../common-components/AllModal/inquiry-Modal/InquiryModal';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(fas);

const StepTwoAttachment = ({ handleNext, handlePrevious, onHide }) => {
  const [modalInquiry, setModalInquiry] = React.useState(false);
  const orderReturnModal = () => {
    setModalInquiry(true);
    setTimeout(() => {
      window.location = "/b2bbuyer/order"
    setModalInquiry(false);
    }, 3000);
  };
  // setTimeout(() => {
  //   window.location = "/b2bbuyer/order"
  //   setModalInquiry(false);
  // }, 3000);
  return (
    <section className='steptwo-attachment'>

      <div className='content mb-4'>
        <h6>Add Attachment</h6>
        <div className='row'>
          <div className='col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-12'>
            <div className='row'>
              <div className='col-xxl-6 col-md-6 col-12'>
                <div className='left-div'>
                  <p>Reason of product return</p>
                  <h6>Wrong product received</h6>
                  <p>attach photo or video of product</p>
                  <button className='uplod'>Upload <FontAwesomeIcon icon="fa-solid fa-upload" className='ms-2' /></button>
                </div>
              </div>
              <div className='col-xxl-6 col-md-6 col-12'>
                <div className='img-div'>
                  <img src="/assest/images/B2Bbuyer/BestSeller/productImg1.png" alt="Product" className='product' />
                  <FontAwesomeIcon icon="fa-solid fa-circle-xmark" className='wrong-icon' />
                </div>
              </div>
            </div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Review</Form.Label>
              <Form.Control type="text" placeholder="please enter the review here" />
            </Form.Group>
          </div>
        </div>

      </div>

      <div className='main-btn-div'>
        <ButtonComponent className="continue-btn" onClick={() => { orderReturnModal(true); }}>
          Proceed
        </ButtonComponent>
        <ButtonComponent className="cancel-btn" onClick={handlePrevious}>
          Cancel
        </ButtonComponent>
      </div>
      <InquiryModal
        message="Order Return successfull"
        show={modalInquiry}
        onHide={() => setModalInquiry(false)}
      />
    </section>
  )
}

export default StepTwoAttachment