import {
  getData,
} from "../../api";

export const getEvents = async (data) => {
  try {
    return await getData(`/events?${data}=1`);
  } catch (error) {
    console.log(error);
  }
};