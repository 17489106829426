
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa2, fa1, } from "@fortawesome/free-solid-svg-icons";
import StepOneSelect from "./StepOneSelect/StepOneSelect";
import StepTwoAttachment from "./StepTwoAttachment/StepTwoAttachment";
import './ReturnOrderStepForm.css'

import BreadcrumComponent from '../../../CommonForAll/CommonComponents/ButtonComponent/BreadcrumComponent/BreadcrumComponent'

const ReturnOrderStepForm = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/b2bbuyer/buyer-home" },
        { text: "Orders", link: "/b2bbuyer/order" },
        { text: "Return Order", link: "#" },
      ]);
    const [current, setCurrent] = useState(1);
    const stepLabels = ["Select a Reason", "Attachment & Detailed Review",];
    const stepIcons = [fa1, fa2,];

    const handleNext = () => {
        if (current < 2) {
            setCurrent(current + 1);
        }
    };

    const handlePrevious = () => {
        if (current > 1) {
            setCurrent(current - 1);
        }
    };

    const isStepCompleted = (step) => {
        return step < current;
    };
    return (
        <>
            <section className="return-order-stepform">
                <div className="container">

                <BreadcrumComponent items={breadcrumbItems} />
                    <Form>
                        <div className="signup-form ">
                            <h4 className="stepform-heading">Order Now</h4>



                            <div className="progress-bar-container">
                                <div className="step-row">
                                    {stepIcons.map((icon, index) => (
                                        <div
                                            key={index + 1}
                                            className={`step-container ${index + 1 === current ? "active" : ""} ${index + 1 <= current ? "completed" : ""}`}

                                        >
                                            <div className="circle-center">
                                                <div
                                                    className={`circle ${isStepCompleted(index + 1)
                                                        ? "completed"
                                                        : ""
                                                        }`}
                                                >
                                                    <FontAwesomeIcon icon={icon} />
                                                </div>
                                            </div>
                                            <div className="step my-auto">
                                                <span className="step-text">
                                                    {stepLabels[index]}
                                                </span>
                                            </div>
                                            {index < stepIcons.length - 1 && (
                                                <div
                                                    className={`line-right ${isStepCompleted(index + 1)
                                                        ? "completed"
                                                        : ""
                                                        }`}
                                                ></div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>


                        <div className="main-steps mt-3">
                            {current === 1 && (
                                <StepOneSelect handleNext={handleNext} />
                            )}
                            {current === 2 && (
                                <StepTwoAttachment
                                    handleNext={handleNext}
                                    handlePrevious={handlePrevious}
                                />
                            )}

                        </div>
                    </Form>
                </div>

            </section >
        </>

    )
}

export default ReturnOrderStepForm