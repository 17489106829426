import React from "react";
import "./New_Customers.css";
import ReactApexChart from 'react-apexcharts';

function New_Customers() {
  const series = [70];
  const options = {
    chart: {
      height: 200,
      width:200,
      type: 'radialBar',
      
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        },
        stroke: {
          color: '#ccc' // Change this to the desired stroke color
        }
      },
    },
    labels: ['Cricket'],
  };
  return (
    <>
      <div className="New_Customers">
        <div className="dashboard-box-heading-holder">
          <h4>New Customers</h4>
        </div>

        <div>
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="radialBar"
              height={200}
              width={200}
            />
          </div>
          <div id="html-dist"></div>
        </div>
      </div>
    </>
  );
}

export default New_Customers;
