import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import HelpCenterTab from './HelpCenterTab/HelpCenterTab'
import HelpSlider from './HelpSlider/HelpSlider'

const HelpCenter = () => {
    const uplodimg = [

        "/assest/images/B2Bbuyer/HelpCenter/add.png",
        "/assest/images/B2Bbuyer/HelpCenter/add.png"
    ];
    return (
        <section className='main-help-centered pt-5 pb-5'>
            <Container>
                <Row>
                    <Col xxl={9} xl={9} lg={9} md={12} sm={12} xs={12}>
                        <HelpCenterTab />
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}>
                        <HelpSlider imagePaths={uplodimg} imageClassName="add-img" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HelpCenter