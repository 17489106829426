import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { getWorkType } from "../../../../utils/apis/master/Master";
import { getSectorCategory } from "../../../../utils/apis/btb/B2b";

library.add(fas);

function Steptwo(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const handleSelectChange = (selectedOption) => {
    setSelectedOptions([...selectedOptions, selectedOption]);
  };

  const handleCloseButtonClick = (optionValue) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.value !== optionValue
    );
    setSelectedOptions(updatedOptions);
  };

  console.log(Cookies.get("btobbuyer_id"));

  const handleSuggestedSkillClick = (label) => {
    const newOption = { value: label, label: label };
    setSelectedOptions([...selectedOptions, newOption]);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const options = [
    { value: " XD", label: " XD" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    var selectedLabel = document.getElementById(
      "s_working_type_id" + data.s_working_type_id
    );
    props.setWorkTypeName(selectedLabel?.getAttribute("for"));
    props.setWorkType(data.s_working_type_id);
    props.nextStep();
  };

  const [workTypes, setWorkTypes] = useState([]);
  const [workTypesSelected, setWorkTypesSelected] = useState("");
  const getWorkTypeMaster = async () => {
    const res = await getWorkType();
    if (res?.success) {
      setWorkTypes(res.data);
    }
  };

  const getUserCategory = async () => {
    const res = await getSectorCategory(
      props.userDetails?.btb_personal_details_id
    );
    if (res?.success) {
      setWorkTypesSelected(res.data.s_working_type_id);
      reset(res.data);
    }
  };
  useEffect(() => {
    getUserCategory();
  }, [props.userDetails]);

  useEffect(() => {
    getWorkTypeMaster();
    setValue("s_working_type_id", props.workType);
  }, []);

  return (
    <>
      <div className="personal-details-form mb-0 ">
        {/* form-holder */}
        <div className="details-form-holder">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {workTypes?.map((value, index) => (
                <div className="mb-3">
                  <Form.Check
                    type="radio"
                    label={value?.name}
                    id={"s_working_type_id" + value?.id}
                    name="s_working_type_id"
                    for={value?.name}
                    value={value?.id}
                    defaultChecked={index === 0}
                    {...register("s_working_type_id", {
                      required: "Please select a category",
                    })}
                  />
                </div>
              ))}

              {errors.s_working_type_id && (
                <span className="text-danger">
                  {errors.s_working_type_id.message}
                </span>
              )}
            </div>

            <div className="col-md-12">
              <div className="text-end btnMainn apply_now_btn">
                {/* <Link to={"/seller/seller-dashboard/"}>
                <Button className="back-btn me-3">Skip</Button>
              </Link> */}

                <div className="registerContinueMain">
                  <Button onClick={props.prevStep} className="back-btn">
                    Back
                  </Button>
                </div>

                <div className="registerContinueMain">
                  <Button type="submit" className="tabs-btn">
                    Register & Continue
                  </Button>
                </div>
              </div>

              <TermsAndConditionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Steptwo;
