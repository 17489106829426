import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
const BreadcrumComponent = ({ items }) => {
  return (
    <>
      <div>
        <Breadcrumb>
          {items?.map((item, index) => (
            <Breadcrumb.Item
              key={index}
              linkAs={Link}
              linkProps={{ to: item.link }}
            >
              {item?.text}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    </>
  );
};

export default BreadcrumComponent;
