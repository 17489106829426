import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import "./BlogComments.css";
import { Col, Container,  Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
function BlogComments() {
  const comments = [
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/sellerblogs/nonbeared.png",
      lorem:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      date: "02 Apr 2021",
    },
    {
      img:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/sellerblogs/bearedman.png",
      lorem:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      date: "02 Apr 2021",
    },
  ];


  const { id } = useParams();
  const { postData } = useContext(Context);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  console.log(errors);

  const onSubmit = async (data) => {
    console.log(data, "form");
    try {
      const response = await postData(
        `/seller/blog/blog-comment/${id}`,
        data
      );
     
      console.log("response :- ", response);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/blog/blog-comment/${id}`);
      setData(response?.data);
      const totalCount = response?.data.length;
      setTotalCount(totalCount); 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-GB', options);
  };

  return (
    <>
      <section className="comment-section">
        <Container>
          <div className="commented">
            <div className="top-com">
              <p>{totalCount} Comments</p>
            </div>
            {data?.map((item, index) => (
              <div className="main-com">
                <Row>
                  <Col xxl={1} xl={1} lg={1} md={2} sm={12} xs={12}>
                    <div className="cmnt-img">
                      {" "}
                      <img className="comment-img" src={item.img} />
                    </div>
                  </Col>
                  <Col xxl={10} xl={10} lg={10} md={10} sm={12} xs={12}>
                    <div className="coment-main">
                      <div className="lorem-div">
                        <p>{item.message}</p>
                      </div>
                      <div className="replydate">
                        <p className="datess">{formatDate(item.createdAt)}</p>
                        <button className="reply-button">Reply</button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}

            <div className="form-sec">
              <Row className="form-sec-main">
                <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12}>
                  <div className="backgrd">
                    <div className="leave-comment">
                      <p>Leave a comment</p>
                    </div>

                    <Form onSubmit={handleSubmit(onSubmit)} >
                      <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <div className="form-mains">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className="labelname">
                                First Name
                              </Form.Label>
                              <Form.Control
                                className={classNames("", {
                                  "is-invalid": errors?.name,
                                })}
                                type="text"
                                name="name"
                                {...register("name", {
                                  required: "Name is required",
                                })}
                                placeholder="Title"
                              />
                              {errors.name && (
                                <span className="text-danger">{errors.name.message}</span>
                              )}
                            </Form.Group>
                            
                          </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <div className="form-mains">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className="labelname">
                                Last Name
                              </Form.Label>
                              <Form.Control
                                className={classNames("", {
                                  "is-invalid": errors?.last_name,
                                })}
                                type="text"
                                name="last_name"
                                {...register("last_name", {
                                  required: "Last Name is required",
                                })}
                                placeholder="Last Name"
                              />
                              {errors.last_name && (
                                <span className="text-danger">{errors.last_name.message}</span>
                              )}
                            </Form.Group>
                          </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <div className="form-mains">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className="labelname">
                                Email
                              </Form.Label>
                              <Form.Control
                                className={classNames("", {
                                  "is-invalid": errors?.email,
                                })}
                                type="text"
                                name="email"
                                {...register("email", {
                                  required: "Email is required",
                                  pattern: {
                                    value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                                    message: "Invalid E-Mail Address",
                                  },
                                })}
                                placeholder="Email"
                              />
                              {errors.email && (
                                <span className="text-danger">{errors.email.message}</span>
                              )}
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <Form.Group>
                          <Form.Label className="labelname">
                            Massages
                          </Form.Label>
                          <Form.Control
                                className={classNames("", {
                                  "is-invalid": errors?.message,
                                })}
                                type="text"
                                name="message"
                                {...register("message", {
                                  required: "Massages is required",
                                })}
                                placeholder="Massages"
                              />
                              {errors.message && (
                                <span className="text-danger">{errors.message.message}</span>
                              )}
                            </Form.Group>
                      </div>
                      <div className="send-div">
                        {/* <button className="send-buton" type="submit"
                      
                      >Send Messages</button> */}
                      <Button
                        className="tabs-btn"
                        // onClick={props.nextStep}
                        type="submit"
                      >
                        Register & Continue
                      </Button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default BlogComments;
