import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import parse from "html-react-parser";
import "./NetPurtiSeller.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
const NetPurtiSeller = () => {

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/home/s-master-art-sub-types`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="NetpurtiSeller">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="NetpurtiSeller-title">
                <h2>Master the art of using Netpurti Seller Panel</h2>
                <p>Get the know-how to use Netpurti Seller Panel like a pro</p>
              </div>
            </div>
          </div>

          <div className="videoTabmain">
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">

              <Row>
                <Nav variant="pills" className="videotabRow">
                  {data?.map((item, index) => (
                    <Nav.Item>
                      <Nav.Link eventKey={index}>{item?.name}</Nav.Link>
                    </Nav.Item>
                  ))}

                  {/* <Nav.Item>
                    <Nav.Link eventKey="2">Informative Dashboard</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3">Seller Inventory</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4">Orders</Nav.Link>
                  </Nav.Item> */}
                </Nav>

                <Tab.Content>
                  {data?.map((item, index) => (
                    <Tab.Pane key={index} eventKey={index}>
                      <div className="videoMainsec">
                        <video
                          src={
                            // process.env.PUBLIC_URL +
                            // "/assest/images/Seller_panel/seller_video/tabVideo.mp4"
                            IMG_URL + item?.video
                          }
                          className="tabVideo"
                          loop
                          muted
                          autoPlay
                        ></video>
                      </div>
                    </Tab.Pane>
                  ))}
                  {/* <Tab.Pane eventKey="2"></Tab.Pane>
                    <Tab.Pane eventKey="3"></Tab.Pane>
                    <Tab.Pane eventKey="4"></Tab.Pane> */}
                </Tab.Content>
              </Row>

            </Tab.Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default NetPurtiSeller;
