import React, { useState, useEffect, useContext } from "react";
import "./Sidebar_affilate_dashboard.css";
import { Row, Col, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import { Link, useLocation } from "react-router-dom";
import { faStar, faStarAndCrescent } from "@fortawesome/free-solid-svg-icons";
import ReactStars from "react-rating-stars-component";
import { render } from "react-dom";
import { Context } from "../../../../utils/context";

const ratingChanged = (newRating) => {
  console.log(newRating);
};

const Sidebar_affilate_dashboard = () => {


  const { userdata } = useContext(Context);
  const [activeKey, setActiveKey] = useState("0");
  const location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Extract the pathname from the location object
    const { pathname } = location;

    // Define a function to determine the active key based on the pathname
    const determineActiveKey = () => {
      switch (pathname) {
        case "/affilate/affilate-dashboard":
          return "0";
        case "/affilate/affilate-dashboard/b2b-products":
          return "1";
        case "/affiliate/affilate-dashboard/tracking":
          return "0";
        case "/affilate/affilate-dashboard/b2b-api-documentation":
          return "3";
        case "/affilate/affilate-dashboard/wallet":
          return "5";
        case "/affilate/affilate-dashboard/roletable":
        case "/affilate/affilate-dashboard/employeetable":
        case "/affilate/affilate-dashboard/affilateproduct":
        case "/affilate/affilate-dashboard/affilatesetting":
          return "6";
        case "/affilate/affilate-dashboard/advertisement":
          return "12";
        default:
          return "0"; // Default active key
      }
    };

    // Set the active key based on the current pathname
    setActiveKey(determineActiveKey());
  }, [location]);

  return (
    <>
      <section className="Sidebar_affilate_dashboard">
        <div className="logo-image-main">
          <Link to="/affilate/affilate-dashboard">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assest/images/Seller_panel/AffilateDashboard/Images/netpurti_logo.png"
              }
              className="logo-img"
              alt="..."
            />
          </Link>
        </div>

        <div className="admin_profile">
          <div className="img-holder">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assest/images/Seller_panel/AffilateDashboard/Images/profile.png"
              }
              className="logo-img"
              alt="..."
            />
          </div>
          <div className="text-holder">
            <h4>
              {userdata?.f_name} {userdata?.l_name}{" "}
              <FontAwesomeIcon
                className="blue-tick"
                icon="fa-solid fa-circle-check"
              />
            </h4>
            <p>ID : {userdata?.af_id}</p>
            <div className="rating">
              <ReactStars
                count={1}
                onChange={ratingChanged}
                size={15}
                marginRight={5}
                activeColor="#ffd700"
              />
              <span>4.7</span>
            </div>
          </div>
        </div>

        <div className="brown-border"></div>

        <div className="tabs-main-secttt">
          <Accordion
            activeKey={activeKey}
            onSelect={(key) => setActiveKey(key)}
          >
            <Accordion.Item eventKey="0">
              <Link to={"/affiliate/affilate-dashboard"}>
                <Accordion.Header>
                  <span className="ico-span">
                    <FontAwesomeIcon icon="fa-solid fa-house" />
                  </span>
                  Affiliate
                </Accordion.Header>

                <Accordion.Body className="inner-dropp">
                  <div
                    className="inner-menu-div"
                    onClick={() => setShow(!show)}
                  >
                    <Link
                      className="inner-menu"
                      to={"/affiliate/affilate-dashboard"}
                    >
                      <div>
                        <span className="inner-menu-icc">
                          <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                        </span>
                        Dashboard
                        <FontAwesomeIcon
                          icon="fa-solid fa-angle-right"
                          className="next-fafaf"
                        />
                      </div>
                    </Link>
                  </div>

                  <div className="inner-menu-div">
                    <Link
                      className="inner-menu"
                      to={"/affiliate/affilate-dashboard/tracking"}
                    >
                      <div>
                        <span className="inner-menu-icc">
                          <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                        </span>
                        Tracking
                      </div>
                    </Link>
                  </div>

                  <div className="inner-menu-div">
                    <Link className="inner-menu">
                      <div>
                        <span className="inner-menu-icc">
                          <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                        </span>
                        Product req status
                      </div>
                    </Link>
                  </div>

                  <div className="inner-menu-div">
                    <Link className="inner-menu">
                      <div>
                        <span className="inner-menu-icc">
                          <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                        </span>
                        Notification
                      </div>
                    </Link>
                  </div>
                </Accordion.Body>
              </Link>
            </Accordion.Item>


            <Accordion.Item eventKey="123">
              <Accordion.Header>
                <div>
                  <span className="ico-span">
                    <FontAwesomeIcon icon="fa-solid fa-house" />
                  </span>
                  In house task
                </div>
              </Accordion.Header>

              <Accordion.Body className="inner-dropp">
                <div className="inner-menu-div" onClick={() => setShow(!show)}>
                  <Link
                    className="inner-menu"
                  // to={"/affilate/affilate-dashboard/orders"}
                  >
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Dashboard
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu"
                    to={"/affiliate/affilate-dashboard/task"}
                  >
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Task
                    </div>
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="1">
              <Link className="inner-menu"
                to={"/affiliate/affilate-dashboard/affiliate-wallet"}
              >
                <Accordion.Header>
                  <div>
                    <span className="ico-span">
                      <FontAwesomeIcon icon="fa-solid fa-wallet" />
                    </span>
                    Wallet
                  </div>
                </Accordion.Header>
              </Link>
            </Accordion.Item>


            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <div>
                  <span className="ico-span">
                    <FontAwesomeIcon icon="fa-solid fa-bag-shopping" />
                  </span>
                  Task | Payout
                </div>
              </Accordion.Header>

              <Accordion.Body className="inner-dropp">
                <div className="inner-menu-div" onClick={() => setShow(!show)}>
                  <Link className="inner-menu">
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Dashboard
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu">
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Task
                    </div>
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <span className="ico-span">
                  <FontAwesomeIcon icon="fa-solid fa-calendar-days" />

                </span>
                Calender
              </Accordion.Header>
            </Accordion.Item>


            <Accordion.Item eventKey="4">
              <Link to="/affiliate/affilate-dashboard/event">
                <Accordion.Header>
                  <span className="ico-span">
                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" />
                  </span>
                  Event
                </Accordion.Header>
              </Link>
            </Accordion.Item>


            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <span className="ico-span">
                  <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
                </span>
                Account
              </Accordion.Header>
            </Accordion.Item>


            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <span className="ico-span">
                  <FontAwesomeIcon icon="fa-solid fa-user" />
                </span>
                Profile
              </Accordion.Header>
            </Accordion.Item>


            <Accordion.Item eventKey="7">
              <Link to="/affiliate/affilate-dashboard/settings">
                <Accordion.Header>
                  <span className="ico-span">
                    <FontAwesomeIcon icon="fa-solid fa-gear" />
                  </span>
                  Setting
                </Accordion.Header>
              </Link>

              {/* <Accordion.Body className="inner-dropp">
                <div className="inner-menu-div" onClick={() => setShow(!show)}>
                  <Link
                    className="inner-menu" */}
              {/* // to={"/affilate/affilate-dashboard/orders"} */}
              {/* > */}
              {/* <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Preference ad category
                    </div>
                  </Link>
                </div> */}

              {/* <div className="inner-menu-div">
                  <Link className="inner-menu">
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      + Social media profile link
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu">
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Identification Task
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu">
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Contact Details
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu">
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Open For Influencer
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu">
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Task & Payment account
                    </div>
                  </Link>
                </div>
              </Accordion.Body> */}

            </Accordion.Item>

            {/* My webaites start */}

            <Accordion.Item eventKey="8">
              <Accordion.Header>
                <span className="ico-span">
                  <FontAwesomeIcon icon="fa-solid fa-handshake-angle" />
                </span>
                Support
              </Accordion.Header>
              <Accordion.Body className="inner-dropp">
                <div className="inner-menu-div" onClick={() => setShow(!show)}>
                  <Link
                    className="inner-menu"
                  // to={"/affilate/affilate-dashboard/orders"}
                  >
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Help
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu">
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Query
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu">
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Complaint
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu">
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Contact
                    </div>
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9">
              <Accordion.Header>
                <span className="ico-span">
                  <FontAwesomeIcon icon="fa-solid fa-book" />
                </span>
                Guidelines
              </Accordion.Header>
              <Accordion.Body className="inner-dropp">
                <div className="inner-menu-div" onClick={() => setShow(!show)}>
                  <Link
                    className="inner-menu"
                  to={"/affiliate/affilate-dashboard/guidelines"}
                  >
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Rule & Guideline
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu"
                   to={"/affiliate/affilate-dashboard/guide-video"}
                   >
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Video Demo
                    </div>
                  </Link>
                </div>

                {/* <div className="inner-menu-div">
                  <Link className="inner-menu">
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Ask question
                    </div>
                  </Link>
                </div> */}

                <div className="inner-menu-div">
                  <Link className="inner-menu"
                   to={"/affiliate/affilate-dashboard/do-dont"}
                  >
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Do / Don't s
                    </div>
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="10">
              <Accordion.Header>
                <span className="ico-span">
                  <FontAwesomeIcon icon="fa-solid fa-users" />
                </span>
                Influencer
              </Accordion.Header>
              <Accordion.Body className="inner-dropp">
                <div className="inner-menu-div" onClick={() => setShow(!show)}>
                  <Link
                    className="inner-menu"
                  // to={"/affilate/affilate-dashboard/orders"}
                  >
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Dashboard
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu"
                    to={"/affiliate/affilate-dashboard/influencertask"}
                  >
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Available Task
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu" to={"/affiliate/affilate-dashboard/influenceractivetask"}>
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Active Task
                    </div>
                  </Link>
                </div>

                <div className="inner-menu-div">
                  <Link className="inner-menu" to={"/affiliate/affilate-dashboard/influencer-event"}>
                    <div>
                      <span className="inner-menu-icc">
                        <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                      </span>
                      Influencers Events
                    </div>
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* My webaites end */}
          </Accordion>
        </div>
      </section>
    </>
  );
};

export default Sidebar_affilate_dashboard;
