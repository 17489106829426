import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import "./PortfolioContent.css";
import { Rating } from "react-simple-star-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Nav from "react-bootstrap/Nav";
import Accordion from "react-bootstrap/Accordion";
import Tab from "react-bootstrap/Tab";
import PortfolioCards from "../../CommonComponents/PortfolioCards/PortfolioCards";
import Button from "react-bootstrap/Button";
import { useState } from "react";

const PortfolioContent = ({ PortfolioData }) => {

    const [showAll, setShowAll] = useState(false);
    const [numToShow, setNumToShow] = useState(4);

    const PortfolioContent = [
        {
            images:
                process.env.PUBLIC_URL +
                "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/my-website-mokup.png",
            cardtitle: "Stag- Personal Portfolio",
            css: "height:200px; width:200px;",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/2.png",
            cardtitle: "Stag- Personal Portfolio",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/1.png",
            cardtitle: "Stag- Personal Portfolio",
        },
        {
            images:
                process.env.PUBLIC_URL +
                "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/my-website-mokup.png",
            cardtitle: "Stag- Personal Portfolio",
            css: "height:200px; width:200px;",
        },
        {
            images:
                process.env.PUBLIC_URL +
                "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/my-website-mokup.png",
            cardtitle: "Stag- Personal Portfolio",
            css: "height:200px; width:200px;",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/2.png",
            cardtitle: "Stag- Personal Portfolio",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/1.png",
            cardtitle: "Stag- Personal Portfolio",
        },
        {
            images:
                process.env.PUBLIC_URL +
                "/assest/images/MyWebsite/PortfolioComponent/PortfolioContent/my-website-mokup.png",
            cardtitle: "Stag- Personal Portfolio",
            css: "height:200px; width:200px;",
        },
    ];
    const hadelPortfolioData = () => {

    }
    const portfolioDataid = PortfolioData?PortfolioData[0]?.id:1;
    const toggleShowAll = () => {
        setShowAll(!showAll);
        if (showAll) {

            setNumToShow(4);
        } else {

            setNumToShow(PortfolioContent.length);
        }
    };
    return (
        <>
            <section className="Portfolio-Content">
                <Container>
                    <Row>
                        <Col xxl={2} xl={2} lg={3} md={3}>
                            <div className="Filter-Content">
                                <p className="sub-title-medium">Filter and Refine</p>
                                <div className="price-range">
                                    <p className="maintenance-text">Price</p>
                                    <div className="Range">
                                        <Form>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="number" />
                                            </Form.Group>
                                        </Form>
                                        <p className="maintenance-text">To</p>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="number" />
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                                <div className="Rating-Star">
                                    <div className="star">
                                        <p className="maintenance-text">Rating</p>
                                        <Rating initialValue={0} SVGclassName="star-svg" fillColor="#FDDA0D" />
                                    </div>
                                    <div className="Rating">
                                        <Form>
                                            {["checkbox"].map((type) => (
                                                <div key={`default-${type}`} className="mb-3">
                                                    <Form.Check
                                                        // prettier-ignore
                                                        type={type}
                                                        id={`default-${type}`}
                                                        label={"5-4 Star"}
                                                    />

                                                    <Form.Check type={type} label={"5-4 Star"} id={`default-${type}`} />
                                                    <Form.Check type={type} label={"5-4 Star"} id={`default-${type}`} />
                                                    <Form.Check type={type} label={"5-4 Star"} id={`default-${type}`} />
                                                </div>
                                            ))}
                                        </Form>
                                    </div>
                                </div>
                                <div className="Rating-Star">
                                    <div className="text">
                                        <p className="maintenance-text">Compatible With</p>
                                    </div>

                                    <div className="Rating">
                                        <Form>
                                            {["checkbox"].map((type) => (
                                                <div key={`default-${type}`} className="mb-3">
                                                    <Form.Check
                                                        // prettier-ignore
                                                        type={type}
                                                        id={`default-${type}`}
                                                        label={"WPML"}
                                                    />

                                                    <Form.Check
                                                        type={type}
                                                        label={"WooCommerce"}
                                                        id={`default-${type}`}
                                                    />
                                                    <Form.Check
                                                        type={type}
                                                        label={"Elementor"}
                                                        id={`default-${type}`}
                                                    />
                                                    <Form.Check
                                                        type={type}
                                                        label={"Bootstrap"}
                                                        id={`default-${type}`}
                                                    />
                                                    <Form.Check
                                                        type={type}
                                                        label={"Contact form"}
                                                        id={`default-${type}`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={10} xl={10} lg={9} md={9}>
                            <div className="">
                                <Tab.Container id="left-tabs-example" defaultActiveKey={portfolioDataid}>
                                    <Row>
                                        <div className="main-product">
                                            <Nav variant="pills" className="nav-tabs-scroll">
                                                {PortfolioData?.map((data) => (
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={data?.id} className="maintenance-text" onClick={hadelPortfolioData()}>
                                                            {data?.name}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </Nav>

                                            <div className="Search-Box">
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="email" />
                                                        <div className="search-icon">
                                                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                                                        </div>
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </div>
                                        <Tab.Content>
                                            {PortfolioData?.map((data) => (
                                                <Tab.Pane eventKey={data?.id}>
                                                    <Row className="mt-3">
                                                        {data?.m_w_portfolios?.slice(0, numToShow)?.map((item, index) => (
                                                            <Col xxl={3} xl={4} lg={6} md={6} sm={6} xs={12} key={index}>
                                                                <PortfolioCards
                                                                    images={item.image}
                                                                    cardtitle={item.name}
                                                                    csss={item.css}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </Tab.Pane>
                                            ))}

                                        </Tab.Content>
                                    </Row>
                                </Tab.Container>
                            </div>
                            <div className="Show-More">
                                {(numToShow < PortfolioContent.length || showAll) && (
                                    <Button variant="primary" className="show" onClick={toggleShowAll}>{showAll ? "Show Less" : "Show More"}</Button>

                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default PortfolioContent;
