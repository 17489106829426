import React, { useState, useEffect, useContext } from "react";
// import "./b2b-table.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { motion } from "framer-motion";

import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Pagination from "react-bootstrap/Pagination";
import { AnimatePresence } from "framer-motion";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import { ShimmerTable } from "react-shimmer-effects";
import { type } from "@testing-library/user-event/dist/type";
import VeiwEditModal from "../../../../../../CommonForAll/VeiwEditModal/VeiwEditModal";
import Mrp_modal from "../../../../../../CommonForAll/mrp-modal/Mrp_modal";
import CustomPaginate from "../../../../../../CommonForAll/CustomPaginate/CustomPaginate";
import {
  Category,
  ProductStatus,
  OrderStatus,
} from "../../../../../../../utils/apis/master/Master";
import {
  getAllProducts,
  productBTCStatusChange,
  productStatusChange,
} from "../../../../../../../utils/apis/seller/Product";
import { Context } from "../../../../../../../utils/context";
import View_all_varients_offcanvas from "../../../../common-components/offcanvas/view-all-varients-offcanvas/View_all_varients_offcanvas";

import Select from "react-select";
import {
  getBTBAllOrderStatus,
  getBTBAllOrders,
  postBTBAllOrderStatus,
  changeStatus,
  postBTBAcceptStatus,
  postBTBRejectStatus,
} from "../../../../../../../utils/apis/orders/btb_orders/Ordes";
import { postData, putData } from "../../../../../../../utils/api";

const B2b_table = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { shimmerLoader, setShimmerLoader, Id, Select2Data } =
    useContext(Context);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [productId, setProductId] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [modalShowedit, setModalShowedit] = useState(false);
  const [varientsoffcanvas, setVarientsoffcanvas] = useState(false);
  const [productTitle, setProductTitle] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [category, setCategory] = useState("");
  const [varientType, setVarientType] = useState("");

  const [deleteshow, setdeleteShow] = useState(false);

  const [show1, setShow1] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const InitialTransition = () => {};
  const blackBox = {
    initial: {
      height: "100vh",
    },
  };

  const { IMG_URL, userdata } = useContext(Context);
  const [products, setProducts] = useState([]);
  const [order_status_id, setOrder_status_id] = useState([]);

  const getProducts = async (page) => {
    setShimmerLoader(true);
    const res = await getBTBAllOrders(
      Id,
      page,
      productTitle,
      startDate,
      endDate
      // order_status_id
    );

    if (res?.success) {
      const newData = res?.data?.data;
      if (newData) {
        const newIds = newData.map((d) => d?.id);
        setAllChecked(newIds);
      }
      setProducts(res.data);
      setShimmerLoader(false);
    }
  };

  const [orderStatus, setOrderStatus] = useState([]);

  const [orderChange, setOrderChange] = useState();
  const getMasters = async () => {
    {
      const res = await getBTBAllOrderStatus();
      if (res?.success) {
        setOrderStatus(await Select2Data(res?.data, "order_status_id", false));
        // setOrderStatus(res?.data)
      }
    }
  };

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  const getChangeOrderStatus = async (value) => {
    {
      if (selectAllChecked.length > 0) {
        const res = await postBTBAllOrderStatus(selectAllChecked, value);
        if (res?.success) {
          setOrderChange("");
          setSelectAllChecked("");
          getProducts();
        }
      }
    }
  };

  const [acceptStatus, setAcceptStatus] = useState();

  const ChangeStatus = async (id, status) => {
    const response = await postData(`/seller/orders/btb/accept-reject/${id}`, {
      accept_or_reject: status ? 1 : 0,
    });
    setAcceptStatus(response);
  };

  const [acceptChangeStatus, setAcceptChangeStatus] = useState();

  const ChangeAcceptStatus = async (id) => {
    const response = await postBTBAcceptStatus(id);
    setAcceptChangeStatus(response);
  };

  const [rejectChangeStatus, setRejectChangeStatus] = useState();

  const ChangeRejectStatus = async (id) => {
    const response = await postBTBRejectStatus(id);
    setRejectChangeStatus(response);
  };

  const changeStatus = async () => {
    setShimmerLoader(true);

    if (selectAllChecked.length > 0) {
      for (const value of selectAllChecked) {
        await productStatusChange(value);
      }
    }

    setShimmerLoader(false);
    getProducts(currentPage);
  };

  // useEffect(() => {}, [orderChange]);

  useEffect(() => {
    getMasters();
    getProducts(currentPage);
  }, [
    currentPage,
    productTitle,
    searchStatus,
    category,
    startDate,
    endDate,
    order_status_id,
    acceptStatus,
  ]);
  const [value, SetValue] = useState([]);
  const statusStyle = {
    color:
      value?.btb_order_status?.name === "Processing"
        ? "green"
        : value?.btb_order_status?.name === "canceled"
        ? "red"
        : "black",
  };

  return (
    <>
      <section className="B2b_table_section">
        <AnimatePresence />
        <h6 className="pdlsis">Product List</h6>
        <div className="product-main">
          <div className="search-main">
            <Form>
              <div className="row justify-content-between">
                <div className="col-xxl-2 co-xl-2 col-lg-4 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="search"
                      placeholder="Search by User Name"
                      onChange={(e) => setProductTitle(e.target.value)}
                    />
                    <FontAwesomeIcon
                      className="serch-icoo"
                      icon="fa-solid fa-magnifying-glass"
                    />
                  </Form.Group>
                </div>

                <div className="col-xxl-5 co-xl-5 col-lg-4 col-md-6">
                  <div className="d-flex product-status-div">
                    {/* <div className="sec1">
                      <Form.Group className="form-group">
                        <Form.Label>Product Status</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setSearchStatus(e.target.value)}
                        >
                          <option value="">-Please Select-</option>
                          {productStatus?.map((value, index) => (
                            <option
                              value={value?.id}
                              selected={searchStatus == value?.id}
                            >
                              {value?.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div> */}
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>From</Form.Label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          name="start_date"
                          id="start_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="DD/MM/YYYY"
                        />
                      </Form.Group>
                    </div>
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>To</Form.Label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          name="end_date"
                          id="end_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="DD/MM/YYYY"
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Categories</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setOrderChange(e.target.value)}
                    >
                      <option value="">-Please Select-</option>
                      {orderStatus?.map((value, index) => (
                        <option
                          value={value?.id}
                          selected={category == value?.id}
                        >
                          {value?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div> 

                {/* <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Change Order Status</Form.Label>
                    {products?.data?.map((value, index) => {
                      if (value.accept_or_reject == 1) {
                        return (
                          <Select
                            key={index}
                            value={orderChange}
                            onChange={async (selectedOption) => {
                              if (selectAllChecked.length === 0) {
                                alert(
                                  "Please Select At Least One Record To Order Change"
                                );
                              } else {
                                await setOrderChange(selectedOption);
                                await getChangeOrderStatus(selectedOption);
                              }
                            }}
                            options={orderStatus}
                            placeholder="-- select order --"
                            isSearchable
                          />
                        );
                      }
                      return null;
                    })}
                  </Form.Group>
                </div> */}

                {/* <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Change Order Status</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setOrder_status_id(e.target.value)}
                    >
                      <option value="">-Please Select-</option>
                      {orderStatus?.map((value, index) => (
                        <option
                          value={value?.label}
                          id={value?.label}
                          selected={order_status_id == value?.label}
                        >
                          {value?.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div> */}

                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Reset Search</Form.Label>
                    <button
                      className="resetsearch-btn"
                      type="button"
                      onClick={() => {
                        setSearchStatus("");
                        setProductTitle("");
                        setStartDate("");
                        setEndDate("");
                        setOrder_status_id("");
                      }}
                    >
                      Reset
                    </button>
                  </Form.Group>
                </div>
                {/* <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Sample Excel</Form.Label>
                    <div>
                      <img
                        className="excelimg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/dashboard//icons/excel.png"
                        }
                      />
                    </div>
                  </Form.Group>
                </div> */}
                {/* <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Download data in Excel</Form.Label>
                    <div>
                      <img
                        className="excelimg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/dashboard//icons/excel.png"
                        }
                      />
                    </div>
                  </Form.Group>
                </div> */}
              </div>

              <hr></hr>
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-4 col-md-3">
                  {/* <select
                    className="form-control"
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">-Please Select-</option>
                      {categories?.map((value, index) => (
                        <option
                          value={value?.id}
                          selected={category == value?.id}
                        >
                          {value?.name}
                        </option>
                      ))}
                    </select>

                  <button
                    className="status-change-btn"
                    type="button"
                    // onClick={changeStatus}
                  >
                    Status Change
                  </button> */}
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-9 col-6 text-end">
                  <div className="d-flex justify-content-end">
                    {/* <div className="me-3">
                      <Form.Group className="form-group">
                        <div className="d-flex justify-content-end">
                          <div className="my-auto me-2">
                            <Form.Label>Search </Form.Label>
                          </div>
                          <div>
                            <Form.Control
                              type="search"
                              placeholder="Search here"
                            />
                            <FontAwesomeIcon
                              className="serch-icoo"
                              icon="fa-solid fa-magnifying-glass"
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </div> */}
                    {/* <div className="">
                      <Link to="/seller/seller-dashboard/sellerlistnewproduct">
                        <button className="listnewpro-btn">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-plus"
                          />{" "}
                          List New Product
                        </button>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </Form>
          </div>

          {/* start tabs table section  */}

          <div className="productlisttabsssec">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="All_Products"
            >
              <Tab.Content>
                <Tab.Pane eventKey="All_Products">
                  <div className="table-overflow">
                    {shimmerLoader ? (
                      <ShimmerTable row={1} col={14} />
                    ) : (
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr className="">
                            <td className="tdd-checkkkk">
                              {/* <input
                                type="checkbox"
                                value="selectAll"
                                checked={
                                  allChecked.length === selectAllChecked.length
                                }
                                onChange={handleChange}
                                id="selectAll"
                                className="me-1"
                              ></input>*/}
                            </td>
                            <td>Order </td>
                            <td>Invoice Number </td>
                            <td>Payment Mode </td>
                            <td>Amount</td>
                            <td>Customer Id</td>
                            <td>Selling Price</td>
                            <td>MRP</td>
                            <td>Quantity</td>
                            <td>Order Status</td>
                            <td>Created On</td>
                            <td>Updated On</td>
                            <td>Accept Or Reject</td>
                          </tr>
                        </thead>

                        <tbody>
                          {products?.data?.map((value, index) => (
                            <tr key={index}>
                              {value?.accept_or_reject == 1 ? (
                                <td className="tdd-checkkkk">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value={value?.id}
                                    name="perselected"
                                    checked={selectAllChecked.includes(
                                      value?.id
                                    )}
                                    onChange={handleChange}
                                    id="flexCheckDefault"
                                  ></input>
                                </td>
                              ) : (
                                <td></td>
                              )}
                              <td> {value?.id}</td>
                              <td>{value?.invoice_number}</td>
                              <td>{value?.btb_order?.payment_mode}</td>{" "}
                              <td>{value?.total}</td>{" "}
                              <td>{value?.btb_order?.btb_user_id}</td>{" "}
                              <td>{value?.selling_price}</td>{" "}
                              <td>{value?.mrp}</td>
                              <td>{value?.quantity}</td>{" "}
                              {/* <td style={statusStyle}>{value?.btb_order_status?.name}</td> */}
                              {value?.btb_order_status?.name ===
                              "Processing" ? (
                                <td
                                  style={{
                                    color: "#c0c000",
                                    fontWeight: "600",
                                  }}
                                >
                                  {value?.btb_order_status?.name}
                                </td>
                              ) : value?.btb_order_status?.name ===
                                "Cancelled" ? (
                                <td style={{ color: "red", fontWeight: "600" }}>
                                  {value?.btb_order_status?.name}
                                </td>
                              ) : value?.btb_order_status?.name ===
                                "Ordered" ? (
                                <td
                                  style={{ color: "green", fontWeight: "600" }}
                                >
                                  {value?.btb_order_status?.name}
                                </td>
                              ) : (
                                <td style={{ color: "black" }}>
                                  {value?.btb_order_status?.name}
                                </td>
                              )}
                              <td>
                                <td>
                                  {value?.createdAt &&
                                    (() => {
                                      const date = new Date(value.createdAt);
                                      const day = date
                                        .getDate()
                                        .toString()
                                        .padStart(2, "0");
                                      const month = (date.getMonth() + 1)
                                        .toString()
                                        .padStart(2, "0");
                                      const year = date.getFullYear();
                                      return `${day}-${month}-${year}`;
                                    })()}
                                </td>
                              </td>
                              <td>
                                {value?.updatedAt &&
                                  (() => {
                                    const date = new Date(value.updatedAt);
                                    const day = date
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0");
                                    const month = (date.getMonth() + 1)
                                      .toString()
                                      .padStart(2, "0");
                                    const year = date.getFullYear();
                                    return `${day}-${month}-${year}`;
                                  })()}
                              </td>
                              <td>
                                <div className="d-flex">
                                  {value.accept_or_reject == 1 ? (
                                    <button
                                      className="btn btn-success  me-1"
                                      disabled
                                    >
                                      Accepted
                                    </button>
                                  ) : value.accept_or_reject == 0 ? (
                                    <button
                                      className="btn btn-danger  me-1"
                                      disabled
                                    >
                                      Rejected
                                    </button>
                                  ) : (
                                    <div className="d-flex">
                                      <button
                                        className="btn btn-success  me-1"
                                        onClick={() => {
                                          ChangeStatus(value.id, true);
                                          ChangeAcceptStatus(value.id);
                                        }}
                                      >
                                        Accept
                                      </button>
                                      <button
                                        className="btn btn-danger me-1"
                                        onClick={() => {
                                          ChangeStatus(value.id, false);
                                          ChangeRejectStatus(value.id);
                                        }}
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>

                  <div className="pagination-div">
                    <CustomPaginate
                      currentPage={currentPage}
                      totalPages={products?.totalPages}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>

          {/* <VeiwEditModal
            show={modalShowedit}
            getAllProducts={getOuterWebSiteAllProducts}
            varientType={varientType}
            productId={productId}
            type={type}
            onHide={() => setModalShowedit(false)}
          /> */}

          <Mrp_modal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </section>
    </>
  );
};

export default B2b_table;
