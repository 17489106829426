import React, { useEffect } from "react";
import "./App.css";
import "../src/Components/B2B_Buyer/B2B_Common_Style/B2B_Style.css";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import SellerRoutes from "./Components/SellerPanel/SellerRoutes/SellerRoutes";
import Welcome from "./Components/Welcome/Welcome";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import AppContext from "./utils/context";
import B2b_Routes from "./Components/B2B_Buyer/B2b_Routes/B2b_Routes";
import Logistic_Routes from "./Components/Logistic/Logistic_Routes/Logistic_Routes";
import B2Cretail_Routes from "./Components/B2CRetail/B2Cretail_Routes/B2Cretail_Routes";
import MywebsitesRoute from "./Components/MyWebsites/MywebsitesRoute/MywebsitesRoute";
import MywebsitesRoutemain from "./Components/MyWebsites/MywebsitesRoutemain/MywebsitesRoutemain";
import Affiliate_Dashboard from "./Components/SellerPanel/Affiliate-Dashboard/Affiliate_Dashboard";
import AffiliateRoutes from "./Components/SellerPanel/Affiliate-Dashboard/AffiliateRoutes/AffiliateRoutes";

import Home from "./Components/B2B_Buyer/Home/Home";
import Affiliate_Login from "../src/Components/SellerPanel/Affiliate-Dashboard/AffiliateLogin/AffiliateLogin";
import Home_link from "./Components/home-link/Home_link";
// Inside netpurti_seller_admin2/src/App.js
// import InquiryComponent from '../inquiry-website-1/src/Component/HomePage/HomePage';

library.add(fas);

function App() {

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const isSeller = pathname.includes("/seller");
  const b2bbuyer = pathname.includes("/b2bbuyer");
  const logistic = pathname.includes("/logistic");
  const b2cretail = pathname.includes("/b2cretail");
  const mywebsites = pathname.includes("/mywebsites");
  const isAffilate = pathname.includes("/affiliate");


  useEffect(() => {
    const path = window.location.pathname; // Get the current path
    if (path === '/') {
      navigate('/b2bbuyer/buyer-home'); // Redirect to the desired URL
    }
  }, [navigate]);

  return (
    <>
      <AppContext>
        <ScrollToTop />
        {/* {isSeller && <Header />} */}
        <Routes>
          {/* <Route path="/inquiry" element={<InquiryComponent />} /> */}
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/" element={<Home />} />
          {/* <Route path="/affiliate/affilate-dashboard" element={<Affiliate_Dashboard />} /> */}
        </Routes>

        {isSeller && (
          <>
            <SellerRoutes />
          </>
        )}

        {isAffilate && (
          <>
            <AffiliateRoutes />
          </>
        )}

        {b2bbuyer && <B2b_Routes />}

        {logistic && <Logistic_Routes />}

        {b2cretail && <B2Cretail_Routes />}

        {mywebsites && <MywebsitesRoute />}

        {/* {isSeller && <Footer />} */}


        {pathname.startsWith("/seller/seller-dashboard") || pathname.startsWith("/affiliate/affilate-dashboard")? "" : <Home_link />}
        
      </AppContext>
    </>
  );
}

export default App;
