import React, { useContext } from "react";
import { useState, useEffect } from "react";
// import './ThemeDetails.css'
import { Col, Container, Row } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { Context } from "../../../../utils/context";
import { useLocation } from 'react-router-dom';
const PreviewDetails = () => {
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const { id } = useParams();
    const { getData, IMG_URL } = useContext(Context);
    const [productDetails, setProductDetails] = useState();
    const [tabId, setTabId] = useState();
    const getProduct = async () => {
        const res = await getData(`/outer-website/packages/theme-details/${id}`);
        if (res?.success) {
            setProductDetails(res?.data)
            // setTabId(res?.data[0]?.m_w_plan_details[0]?.id)
        } else {

        }
    }
    console.log(tabId, "tabId tabId");
    useEffect(() => {
        getProduct();
    }, []);

    const updatedAtString = productDetails?.theme_sub_category?.theme?.updatedAt;
    const updatedAtDate = updatedAtString ? new Date(updatedAtString) : null;
    const formattedDate = updatedAtDate ? updatedAtDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    }) : 'Date Not Available';

    const updatedAtString1 = productDetails?.theme_sub_category?.theme?.createdAt;
    const updatedAtDate1 = updatedAtString1 ? new Date(updatedAtString) : null;
    const formattedDate1 = updatedAtDate1 ? updatedAtDate1.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    }) : 'Date Not Available';

    const navigate = useNavigate();
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search); // Extract query parameters
  const planId = queryParams.get('plan_id');
   console.log(planId,"planId planId planId");
   
    return (
        <section className='blog-card'>
            <Container>
                <Breadcrumb>
                     <Breadcrumb.Item><Link to={`/mywebsites/pricing`}>Package</Link></Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => navigate(`/mywebsites/livepreview/${planId}`)}>Theme</Breadcrumb.Item>
                    <Breadcrumb.Item active>Theme Details</Breadcrumb.Item>
                </Breadcrumb>
                <div className="text-end mb-3">
                     <a href={productDetails?.demo_url} target="_blank"><button className="btn btn-info">View Website</button></a>
                </div>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className='blog-main-img'>
                            {/* <img src={IMG_URL + productDetails?.theme_sub_category?.theme?.image} className='theme-card-img' alt='...' /> */}
                            <iframe
                                className='theme-card-img'
                                src={productDetails?.demo_url}
                                title="Live Preview"
                            />
                            {/* <div className="overlay">
                <div className="dot-lotti">
                  <Link to={productDetails?.demo_url} >
                    <button type='button' className='continue-btn'>Live Preview <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></button>
                    </Link>
                </div>
              </div> */}
                        </div>
                        <div>
                            <p className='theme-description'> {htmlToReactParser.parse(productDetails?.theme_sub_category?.theme?.description)}</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                        {/* <div className='second-section'>
              <div>
                <Row>
                  <Col xxl={9}>
                    <h4 className='themmm'>Avada 7.11 is live!</h4>
                  </Col>
                  <Col xxl={3}>
                    <p className='price'>Rs. 4536</p>
                  </Col>
                </Row>
              </div>
              <div>
                <p className='check-texttt'><FontAwesomeIcon icon="fa-solid fa-check" /> Included:Quality checked by Envato</p>
                <p className='check-texttt'><FontAwesomeIcon icon="fa-solid fa-check" /> Included:Future updates</p>
                <p className='check-texttt'><FontAwesomeIcon icon="fa-solid fa-check" /> 6 months support from ThemeFusion </p>
              </div>
              <button type='button' className='add-to-cart'><FontAwesomeIcon icon="fa-solid fa-cart-shopping"  /> Add to Cart</button>
            </div> */}
                        <div className='third-section'>
                            <Row>
                                <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={12}>
                                    <p className='feature-head'>Last Update</p>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={7} sm={6} xs={12}>
                                    <p className='feature-head-sub'>{formattedDate}</p>
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={12}>
                                    <p className='feature-head'>Published</p>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={7} sm={6} xs={12}>
                                    <p className='feature-head-sub'>{formattedDate1}</p>
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={12}>
                                    <p className='feature-head'>Gutenberg Optimized</p>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={7} sm={6} xs={12}>
                                    <p className='feature-head-sub'>Yes</p>
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={12}>
                                    <p className='feature-head'>Widget Ready	</p>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={7} sm={6} xs={12}>
                                    <p className='feature-head-sub'>Yes</p>
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={12}>
                                    <p className='feature-head'>Compatible Browsers</p>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={7} sm={6} xs={12}>
                                    <p className='feature-head-sub'>Chrome, Safari</p>
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={12}>
                                    <p className='feature-head'>Software Version	</p>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={7} sm={6} xs={12}>
                                    <p className='feature-head-sub'>Wordpress 6.4</p>
                                    <p className='feature-head-sub'>Wordpress 6.4</p>
                                    <p className='feature-head-sub'>Wordpress 6.4</p>
                                    <p className='feature-head-sub'>Wordpress 6.4</p>
                                    <p className='feature-head-sub'>Wordpress 6.4</p>
                                    <p className='feature-head-sub'>Wordpress 6.4</p>
                                    <p className='feature-head-sub'>Wordpress 6.4</p>
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={12}>
                                    <p className='feature-head'>Tags	</p>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={7} sm={6} xs={12}>
                                    <p className='feature-head-sub'>Blog, Buisness, Clean, Corporate, Porfolio</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PreviewDetails