import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../../utils/context";
import {
  getsingleCategory,
  editCategory,
  getsingleCategoryOfSeller,
} from "../../../../../../utils/apis/seller/Inquiry-website/masters/Category/category";

library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, sellerDomain } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getsingleCategory(id);
    reset(response?.data);
    setValue("original_name", response?.data?.s_category?.name);
  };

  const GetEditDataCategory = async () => {
    const response = await getsingleCategoryOfSeller(id);

    reset(response?.data);
  };
  useEffect(() => {
    GetEditData();
    GetEditDataCategory();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("domain_id", sellerDomain?.value);
      if (data?.sort_order) finalData.append("sort_order", data?.sort_order);
      finalData.append("image", data?.image[0]);
      const response = await editCategory(id, finalData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Original Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          disabled
                          type="text"
                          name="original_name"
                          value={getValues("original_name")}
                          {...register("original_name")}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Website Category Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Category"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Sort Order</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="sort_order"
                          className={classNames("", {
                            "is-invalid": errors?.sort_order,
                          })}
                          {...register("sort_order", {
                            // required: "Name is required",
                          })}
                          onKeyDown={(e) => {
                            if (
                              !/[\d]/.test(e.key) &&
                              e.key !== "Backspace" &&
                              e.key !== "Delete" &&
                              e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </InputGroup>
                      {/* {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )} */}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            // required: "Image is required",
                          })}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={4} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={8}>
                      {typeof getValues("image") == "string" ? (
                        <div className="image-preview-container">
                          <img
                            src={IMG_URL + getValues("image")}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "150px", height: "130px" }}
                          />
                        </div>
                      ) : (
                        imageFile &&
                        imageFile?.length > 0 && (
                          <div className="image-preview-container">
                            <img
                              // src={URL.createObjectURL(getValues("image")[0])}
                              src={URL?.createObjectURL(imageFile[0])}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "100px", height: "100px" }}
                            />
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
