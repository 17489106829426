import React from 'react'
import Modal from 'react-bootstrap/Modal';
import './AddNoteModal.css'
import Form from 'react-bootstrap/Form';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
const AddNoteModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='add-note-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add a Note to the supplier
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent onClick={props.onHide} className={'btn-cancle'}>Cancel</ButtonComponent>
                    <ButtonComponent onClick={props.onHide} className={'btn-confirm'} > Confirm</ButtonComponent>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddNoteModal