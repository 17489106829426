import React, { useState, useEffect } from "react";
import "./Product_history_modal.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import { historiesGet } from "../../../utils/apis/seller/Product";

const Product_history_modal = (props) => {
  // props.varientType

  const [historys, setHistory] = useState();
  const getHistorys = async () => {
    const res = await historiesGet({
      s_product_id: props.productId,
      type: props.varientType,
    });
    if (res?.success) {
      setHistory(res.data);
    }
  };

  useEffect(() => {
    getHistorys();
  }, [props.productId]);
  return (
    <>
      <Modal className="Product_history_modal" {...props} centered size="lg">
        <Modal.Header closeButton onClick={props.onHide}>
          <Modal.Title className="mod-tt">History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Stock">
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey="Status">Status</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Stock">Stock</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="MRP">MRP</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content className="mt-3">
              <Tab.Pane eventKey="Status">
                <div className="stock-table-div">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Remark</th>
                        <th>Date</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historys?.s_product_status_histories?.map(
                        (value, index) => (
                          <tr>
                            <td>
                              {value?.s_personal_detail?.f_name}
                              {value?.s_personal_detail?.l_name}
                            </td>
                            <td>
                              Changed the status to{" "}
                              {value?.status === 0 ? "InActive" : "Active"} of{" "}
                              <span className="pd-name">
                                {historys?.product_title}
                              </span>
                            </td>
                            <td>
                              {new Date(value?.createdAt).toLocaleDateString()}
                            </td>
                            <td>
                              {new Date(value?.createdAt).toLocaleTimeString()}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="Stock">
                <div className="stock-table-div">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Remark</th>
                        <th>After Stock</th>
                        <th>Date</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historys?.s_m_v_stock_histories &&
                        historys?.s_m_v_stock_histories?.map((value, index) => (
                          <tr>
                            <td>
                              {value?.s_personal_detail?.f_name}{" "}
                              {value?.s_personal_detail?.l_name}
                            </td>
                            <td>
                              Stock
                              {" " + value?.type + " "}
                              to{" "}
                              <span className="pd-name">
                                {historys?.product_title}
                              </span>
                            </td>
                            <td>{value?.stock}</td>
                            <td>
                              {new Date(value?.createdAt).toLocaleDateString()}
                            </td>
                            <td>
                              {new Date(value?.createdAt).toLocaleTimeString()}
                            </td>
                          </tr>
                        ))}

                      {historys?.s_s_v_stock_histories &&
                        historys?.s_s_v_stock_histories?.map((value, index) => (
                          <tr>
                            <td>
                              {value?.s_personal_detail?.f_name}{" "}
                              {value?.s_personal_detail?.l_name}
                            </td>
                            <td>
                              Stock
                              {" " + value?.type + " "}
                              to{" "}
                              <span className="pd-name">
                                {historys?.product_title}
                              </span>
                            </td>
                            <td>{value?.stock}</td>
                            <td>
                              {new Date(value?.createdAt).toLocaleDateString()}
                            </td>
                            <td>
                              {new Date(value?.createdAt).toLocaleTimeString()}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="MRP">
                <div className="stock-table-div">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Remark</th>
                        <th>MRP Changed to</th>
                        <th>Date</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historys?.s_m_v_mrp_histories &&
                        historys?.s_m_v_mrp_histories?.map((value, index) => (
                          <tr>
                            <td>
                              {value?.s_personal_detail?.f_name}{" "}
                              {value?.s_personal_detail?.l_name}
                            </td>
                            <td>
                              MRP changed of{" "}
                              <span className="pd-name">
                                {historys?.product_title}
                              </span>
                            </td>
                            <td>{value?.mrp}</td>
                            <td>
                              {new Date(value?.createdAt).toLocaleDateString()}
                            </td>
                            <td>
                              {new Date(value?.createdAt).toLocaleTimeString()}
                            </td>
                          </tr>
                        ))}

                      {historys?.s_s_v_mrp_histories &&
                        historys?.s_s_v_mrp_histories?.map((value, index) => (
                          <tr>
                            <td>
                              {value?.s_personal_detail?.f_name}{" "}
                              {value?.s_personal_detail?.l_name}
                            </td>
                            <td>
                              MRP changed of{" "}
                              <span className="pd-name">
                                {historys?.product_title}
                              </span>
                            </td>
                            <td>{value?.mrp}</td>
                            <td>
                              {new Date(value?.createdAt).toLocaleDateString()}
                            </td>
                            <td>
                              {new Date(value?.createdAt).toLocaleTimeString()}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Product_history_modal;
