import React from "react";
import "./SuccessModal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Successfull_Lottie from "../Successfull_Lottie/Successfull_Lottie";
const SuccessModal = (props) => {
  const { message } = props; // Extracting 'message' prop
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inquiry-modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="lottie-holder">
            <Successfull_Lottie />
          </div>
          <p>{message}</p> {/* Displaying the 'message' prop */}
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default SuccessModal;
