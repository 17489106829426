import MywebsitesRoutemain from "../MywebsitesRoutemain/MywebsitesRoutemain";
import { Routes, Route } from "react-router-dom";
const MywebsitesRoute = () => {
  return (
    <>
      <Routes>
        <Route path={`/mywebsites/*`} element={<MywebsitesRoutemain />} />
      </Routes>
    </>
  );
};

export default MywebsitesRoute;
