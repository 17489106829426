
import React, { useEffect, useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import classNames from "classnames";
import { Tab, Nav } from "react-bootstrap";
import {
    useForm,
    Controller,
    SubmitHandler,
    useFieldArray,
} from "react-hook-form";

import Select from "react-select";
import { shipmentType } from "../../../../utils/apis/master/Master";
import { Errors, Placeholders } from "../../../../utils/errors";
import { Context } from "../../../../utils/context";
import { getShipment, shipmentPost } from "../../../../utils/apis/transport/transport";

function Service({ userID, userDetails, setShipmentId, setSuccessModalShow }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {
        control,
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors },
    } = useForm();

    const { getData, postData, IMG_URL, Select2Data } = useContext(Context);


    const onSubmit = async (data) => {
        console.log(data, 'form');
        data.t_personal_details_id = userDetails?.t_personal_details_id;
        const res = await shipmentPost(data);
        if (res?.success) {
            console.log(res, 'addresspost');
            setShipmentId(res?.data?.id);
            setSuccessModalShow(true);
            setTimeout(() => {
                setSuccessModalShow(false);
            }, 2000);
        }
    };


    const getshipmentDetailIn = async () => {
        const res = await getShipment(userDetails?.t_personal_details_id);
        if (res?.success) {
            reset(res.data);
            setShipmentId(res?.data?.id);
            setValue(`shipment_id`,{value: res?.data?.shiping_service_type?.id , label: res?.data?.shiping_service_type?.name});
        }
    }

    const [service, setService] = useState([]);

    const handleServiceChange = async (e) => {
        const res = await shipmentType(e);
        if (res?.success) {
            const data = await Select2Data(res.data, "shipment_id", false);
            setService(data);
        }
    }


    useEffect(() => {
        getshipmentDetailIn();
    }, []);
    return (
        <>

            <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="Home_address"
                activeKey="Home_address"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="col-lg-4" controlId="">
                        <Form.Label>Shipment Service Type</Form.Label>
                        <Controller
                            name={`shipment_id`}
                            {...register(`shipment_id`, {
                                required: Errors.shipment_id,
                            })}
                            control={control}
                            render={({ field }) => (
                                <Select
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: errors.shipment_id
                                                ? "red"
                                                : baseStyles,
                                        }),
                                    }}
                                    {...field}
                                    options={service}
                                    onInputChange={(selectedOption) => {
                                        handleServiceChange(selectedOption);
                                    }}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group className="col-lg-6" controlId="">
                        <div className="text-end">
                            <Button
                                className="btn StepfoursaveBtn"
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                        </div>
                    </Form.Group>

                </Form>
            </Tab.Container >

        </>
    );
}

export default Service;
