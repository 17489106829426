import { React, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Container, Row, Col } from "react-bootstrap";
import "./Reviews.css";
import Table from "react-bootstrap/Table";
import { Rating } from "react-simple-star-rating";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import ProgressBar from "react-bootstrap/ProgressBar";
const Reviews = (props) => {
    const { data, singleVariant } = props;
    const [activeTab, setActiveTab] = useState("first");

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const now = 60;
    const [showSeeking, setShowSeeking] = useState(false);
    // Eight tab section
    const ReviewsDetails = [
        {
            name: "Shubham Kumar",
            date: "May 16, 2023",
            text: "very nice experience....super quality materials.",

            image:
                process.env.PUBLIC_URL +
                "/assest/images/B2Bbuyer/B2bSellerProduct/review1.png",
        },
        {
            name: "Rohit Verma",
            date: "June 10, 2023",
            text: "very helpful and good stuffs thank you so much",

            image:
                process.env.PUBLIC_URL +
                "/assest/images/B2Bbuyer/B2bSellerProduct/review2.png",
        },
        {
            name: "Rohit Verma",
            date: "June 10, 2023",
            text: "very helpful and good stuffs thank you so much",

            image:
                process.env.PUBLIC_URL +
                "/assest/images/B2Bbuyer/B2bSellerProduct/review3.png",
        },
    ];

    const QutionsDetails = [
        {
            customer:
                process.env.PUBLIC_URL +
                "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
            name: "Oshin Kawale",
            question:
                "What factors should I consider before purchasing a product online?",
            customertwo:
                process.env.PUBLIC_URL +
                "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
            customername: "Pratiksha Pethe",
            ans: "Before making a purchase online, consider factors such as product reviews, seller reputation, return policies, shipping costs, and product specifications to ensure you make an informed decision.",
        },
        {
            customer:
                process.env.PUBLIC_URL +
                "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
            name: "Oshin Kawale",
            question:
                "What factors should I consider before purchasing a product online?",
            customertwo:
                process.env.PUBLIC_URL +
                "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
            customername: "Pratiksha Pethe",
            ans: "Before making a purchase online, consider factors such as product reviews, seller reputation, return policies, shipping costs, and product specifications to ensure you make an informed decision.",
        },
    ];

    return (
        <>
            {/* B2bSellerProduct section started */}
            <section className="DashboardReviews">
                <Container>
                    <Row>
                        <Col xxl={12}>
                            <div className="Product-section">
                                <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey="first"
                                    activeKey={activeTab}
                                    onSelect={handleTabChange}
                                >
                                    <div className="main-nav">
                                        <Nav variant="pills" className="reviewsNavbar">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">About Product</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">
                                                    Product Description
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">
                                                    Technical Specification
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fourth">Customization</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fifth">
                                                    Warranty & Product Caution
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="six">Ratings & Reviews</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">

                                            <div className="Content-First-Tab">
                                                <div
                                                    className="Content-First-Tab"
                                                    dangerouslySetInnerHTML={{ __html: data?.about }}
                                                ></div>
                                                <Row>
                                                    <Col xxl={5}>
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/assest/images/B2Bbuyer/B2BSellerDetail/product.png"
                                                            }
                                                            alt=""
                                                            className="product-image"
                                                        />
                                                    </Col>
                                                    <Col xxl={7}>
                                                        <p className="text-lorem">
                                                            Lorem Ipsum is simply dummy text of the printing and
                                                            typesetting industry. Lorem Ipsum has been the industry's
                                                            standard dummy text ever since the 1500s, when an unknown
                                                            printer took a galley of type and scrambled it to make a
                                                            type specimen book. It has survived not only five centuries,
                                                            but also the leap into electronic typesetting, remaining
                                                            essentially unchanged. It was popularised in the 1960s with
                                                            the release of Letraset sheets containing Lorem Ipsum
                                                            passages, and more recently with desktop publishing software
                                                            like Aldus PageMaker including versions of Lorem Ipsum.
                                                        </p>
                                                        <p className="text-lorem"> Sed
                                                            ut perspiciatis unde omnis iste natus error sit voluptatem
                                                            accusantium doloremque laudantium, totam rem aperiam, eaque
                                                            ipsa quae ab illo inventore veritatis et quasi architecto
                                                            beatae vitae dicta sunt explicabo. Nemo enim ipsam
                                                            voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                                                            sed quia consequuntur magni dolores eos qui ratione
                                                            voluptatem sequi nesciunt. Neque porro quisquam est, qui
                                                            dolorem ipsum quia dolor sit amet, consectetur, adipisci
                                                            velit, sed quia non numquam</p>
                                                    </Col>
                                                </Row>
                                            </div>


                                        </Tab.Pane>

                                        <Tab.Pane eventKey="second">

                                            <div className="Content-Second-Tab">
                                                <div className="content-one">
                                                    <p className="title-tab">
                                                        Transform Your Living Space with Our Luxurious Sofa
                                                    </p>
                                                    <p className="name">
                                                        Elevate the ambiance of your living room with our
                                                        exquisitely crafted sofa, designed to merge
                                                        unparalleled comfort with timeless elegance.
                                                        Impeccably tailored and meticulously engineered, our
                                                        sofa is more than just a piece of furniture – it's a
                                                        statement of refined taste and superior
                                                        craftsmanship.
                                                    </p>
                                                </div>
                                                <ul>
                                                    <li className="title-tab">
                                                        <FontAwesomeIcon icon={faCircleCheck} /> Superior
                                                        Comfort
                                                    </li>
                                                    <li className="title-tab">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        Timeless Design
                                                    </li>
                                                    <li className="title-tab">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        Premium Materials
                                                    </li>
                                                    <li className="title-tab">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        Customization Options
                                                    </li>
                                                </ul>
                                                <div className="product-table">
                                                    <Row>
                                                        <Col xl={6}>
                                                            <h4 className="title-tab"> Key attributes</h4>
                                                            <p className="name">
                                                                Industry-specific attributes
                                                            </p>
                                                            <Table className="tableDdemo">
                                                                <tbody>
                                                                    <tr className="name">
                                                                        <td>Feature</td>
                                                                        <td>sofa chair</td>
                                                                    </tr>
                                                                    <tr className="name">
                                                                        <td>Mail packing</td>
                                                                        <td>Y</td>
                                                                    </tr>
                                                                    <tr className="name">
                                                                        <td>Application</td>
                                                                        <td>
                                                                            Living Room, Bedroom, Hotel, Apartment
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="name">
                                                                        <td>Design Style</td>
                                                                        <td>Modern</td>
                                                                    </tr>
                                                                    <tr className="name">
                                                                        <td>Material</td>
                                                                        <td>Fabric</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>

                                                        <Col xxl={6}>
                                                            <h4 className="title-tab">Other attributes</h4>
                                                            <Table className="tableDdemo mt-3">
                                                                <tbody>
                                                                    <tr className="name">
                                                                        <td>Place of Origin</td>
                                                                        <td>India</td>
                                                                    </tr>
                                                                    <tr className="name">
                                                                        <td>Specific Use</td>
                                                                        <td>Living Room Chair</td>
                                                                    </tr>
                                                                    <tr className="name">
                                                                        <td>General Use</td>
                                                                        <td>Home Furniture</td>
                                                                    </tr>
                                                                    <tr className="name">
                                                                        <td>Type</td>
                                                                        <td>Living Room Furniture</td>
                                                                    </tr>
                                                                    <tr className="name">
                                                                        <td>Appearance</td>
                                                                        <td>Modern</td>
                                                                    </tr>
                                                                    <tr className="name">
                                                                        <td>Style</td>
                                                                        <td>Leisure Chair</td>
                                                                    </tr>
                                                                    <tr className="name">
                                                                        <td>Folded</td>
                                                                        <td>NO</td>
                                                                    </tr>
                                                                    <tr className="name">
                                                                        <td>Brand Name</td>
                                                                        <td>OEM</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>


                                        </Tab.Pane>

                                        <Tab.Pane eventKey="third">

                                            <div className="Content-third-Tab">
                                                <Row>

                                                    <h4 className="title-tab">Technical Specification</h4>
                                                    <p className="name">Industry-specific attributes</p>
                                                    <Table className="tableDdemo">
                                                        <tbody>
                                                            <tr className="name">
                                                                <td>Feature</td>
                                                                <td>sofa chair</td>
                                                            </tr>
                                                            <tr className="name">
                                                                <td>Mail packing</td>
                                                                <td>Y</td>
                                                            </tr>
                                                            <tr className="name">
                                                                <td>Application</td>
                                                                <td>Living Room, Bedroom, Hotel, Apartment</td>
                                                            </tr>
                                                            <tr className="name">
                                                                <td>Design Style</td>
                                                                <td>Modern</td>
                                                            </tr>
                                                            <tr className="name">
                                                                <td>Material</td>
                                                                <td>Fabric</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>

                                                </Row>

                                            </div>

                                            <div
                                                className="Content-third-Tab"
                                                dangerouslySetInnerHTML={{ __html: data?.about }}
                                            ></div>


                                        </Tab.Pane>

                                        <Tab.Pane eventKey="fourth">
                                            <div className="Content-fourth-Tab">
                                                <Row>
                                                    <h4 className="title-tab">Customization offer by seller</h4>
                                                    <p className="name">Product Customization</p>
                                                    <Table>
                                                        <thead>
                                                            <tr>

                                                                <th className="weight">Weight</th>
                                                                <th className="weight">Details</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Weight</td>
                                                                <td>increase weight</td>

                                                            </tr>
                                                            <tr>
                                                                <td>Coating</td>
                                                                <td>Wooden coating, polish</td>

                                                            </tr>
                                                            <tr>
                                                                <td>Size</td>
                                                                <td>As per recommended or as per discussion</td>

                                                            </tr>
                                                            <tr>
                                                                <td>Color</td>
                                                                <td>As per discussion</td>

                                                            </tr>
                                                            <tr>
                                                                <td>Material Type</td>
                                                                <td>Sagwan....etc</td>

                                                            </tr>
                                                            <tr>
                                                                <td>Design</td>
                                                                <td>Standard design or as per requirement</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>

                                                </Row>


                                            </div>


                                        </Tab.Pane>

                                        <Tab.Pane eventKey="fifth">

                                            <div className="Content-fifth-Tab">

                                                <ul>
                                                    <Row>

                                                        <Table className="tableDdemo">
                                                            <tbody>
                                                                <tr className="name">
                                                                    <td>Warranty Provided By</td>
                                                                    <td>John Doe</td>
                                                                </tr>
                                                                <tr className="name">
                                                                    <td>Warranty Period of Product</td>
                                                                    <td>1 Year</td>
                                                                </tr>
                                                                <tr className="name">
                                                                    <td>Helpline Number</td>
                                                                    <td>+020 784523</td>
                                                                </tr>
                                                                <tr className="name">
                                                                    <td>Service Type</td>
                                                                    <td>Go to Service Center</td>
                                                                </tr>
                                                                <tr className="name">
                                                                    <td>Address</td>
                                                                    <td>
                                                                        123, ABC Street, XYZ Area, Pune - 411001
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>

                                                    </Row>

                                                    <h4 className="title-tab">Warranty Terms</h4>
                                                    <li className="title-tab">Limited Warranty for Sofa Product</li>
                                                    <p className="name">
                                                        Thank you for choosing [Company Name] for your furniture needs.
                                                        We are dedicated to providing you with high-quality products and
                                                        exceptional customer service. Your satisfaction is our priority.
                                                    </p>

                                                    <li className="title-tab">Coverage</li>
                                                    <p className="name">
                                                        [Company Name] warrants to the original purchaser that our sofa product shall be free from defects in materials and workmanship under normal use and service for a period of [Insert Duration] from the date of purchase.
                                                    </p>

                                                    <li className="title-tab">What is Covered</li>
                                                    <p className="name">
                                                        This warranty covers structural defects in frames, springs, and joints, as well as defects in materials such as fabric, leather, and cushioning.
                                                    </p>

                                                    <li className="title-tab">What is Not Covered</li>
                                                    <p className="name">
                                                        This warranty does not cover damages resulting from improper
                                                        use, improper care or maintenance, accidents, or normal wear and
                                                        tear. It also does not cover damages caused by unauthorized
                                                        repairs or modifications.
                                                    </p>

                                                    <li className="title-tab">Claims Process</li>
                                                    <p className="name">
                                                        If you believe your sofa product is defective and covered under this warranty, please contact our customer service department at [Insert Contact Information]. You may be required to provide proof of purchase and photographs of the issue. Our customer service team will assess your claim and, if deemed necessary, arrange for repair or replacement of the defective part or product.
                                                    </p>

                                                    <li className="title-tab">Exclusions and Limitations</li>
                                                    <p className="name">
                                                        [Company Name] shall not be liable for any incidental or consequential damages arising out of the use or inability to use the sofa product, including but not limited to loss of time, inconvenience, or any other commercial loss.
                                                    </p>

                                                    <li className="title-tab">Legal Rights</li>
                                                    <p className="name">
                                                        This warranty gives you specific legal rights, and you may also have other rights which vary from state to state or country to country. This warranty is valid only in the jurisdiction where the sofa product was purchased.
                                                    </p>


                                                </ul>

                                                <ul>
                                                    <p className="product">Product Caution and Safety Guidelines for Sofa</p>
                                                    <p>At [Company Name], your safety and satisfaction are paramount to us. Please read and adhere to the following caution and safety guidelines to ensure the safe use of your sofa product:</p>
                                                    <Row>

                                                        <div className="d-flex">
                                                            <div className="circle">
                                                                <img
                                                                    src={
                                                                        process.env.PUBLIC_URL +
                                                                        "/assest/images/B2Bbuyer/B2BSellerDetail/teamwork.png"
                                                                    }
                                                                    alt=""
                                                                    className="prodcut-icon"
                                                                />
                                                            </div>
                                                            <p className="title-tab">Assembly and Installation</p>
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                            <div className="circle">
                                                                <img
                                                                    src={
                                                                        process.env.PUBLIC_URL +
                                                                        "/assest/images/B2Bbuyer/B2BSellerDetail/user-guide.png"
                                                                    }
                                                                    alt=""
                                                                    className="prodcut-icon"
                                                                />
                                                            </div>
                                                            <p className="title-tab">Usage Guidelines</p>
                                                        </div>



                                                        <div className="d-flex">
                                                            <div className="circle">
                                                                <img
                                                                    src={
                                                                        process.env.PUBLIC_URL +
                                                                        "/assest/images/B2Bbuyer/B2BSellerDetail/Layer.png"
                                                                    }
                                                                    alt=""
                                                                    className="prodcut-icon"
                                                                />
                                                            </div>
                                                            <p className="title-tab">Weight Limitations</p>
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                            <div className="circle">
                                                                <img
                                                                    src={
                                                                        process.env.PUBLIC_URL +
                                                                        "/assest/images/B2Bbuyer/B2BSellerDetail/solidarity.png"
                                                                    }
                                                                    alt=""
                                                                    className="prodcut-icon"
                                                                />
                                                            </div>
                                                            <p className="title-tab">Maintenance and Care</p>
                                                        </div>


                                                    </Row>
                                                </ul>
                                            </div>


                                        </Tab.Pane>

                                        <Tab.Pane eventKey="six">


                                            <div className="Content-Six-Tab">
                                                <div className="d-flex">
                                                    <p className="key">Ratings & Review</p>
                                                    <div className="Review-btn">
                                                        <Button variant="review">Write a review</Button>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="star">
                                                        <Rating
                                                            initialValue={0}
                                                            SVGclassName="star-svg"
                                                            fillColor="#00000"
                                                        />
                                                    </div>
                                                    <div className="star">
                                                        <p className="rating-number">4.3</p>
                                                    </div>
                                                </div>
                                                <div className="rating-progressbar">
                                                    <p className="rating-text">5 Star</p>
                                                    <ProgressBar now={60} variant="secondary" />
                                                    <p className="rating-text">117</p>
                                                </div>
                                                <div className="rating-progressbar">
                                                    <p className="rating-text">4 Star</p>

                                                    <ProgressBar now={60} variant="secondary" />
                                                    <p className="rating-text">226</p>
                                                </div>
                                                <div className="rating-progressbar">
                                                    <p className="rating-text">3 Star</p>
                                                    <ProgressBar now={60} variant="secondary" />
                                                    <p className="rating-text">75</p>
                                                </div>
                                                <div className="rating-progressbar">
                                                    <p className="rating-text">2 Star</p>
                                                    <ProgressBar now={60} variant="secondary" />
                                                    <p className="rating-text">30</p>
                                                </div>
                                                <div className="rating-progressbar">
                                                    <p className="rating-text">1 Star</p>
                                                    <ProgressBar now={60} variant="secondary" />
                                                    <p className="rating-text">5</p>
                                                </div>

                                                {ReviewsDetails.map((item, index) => (
                                                    <Row>
                                                        <Col xxl={9} xl={9} lg={9} md={9} sm={12}>
                                                            <div className="Reviews-Section">
                                                                <div className="review-image">
                                                                    <img src={item.image} className="review" />
                                                                </div>
                                                                <div className="content-review">
                                                                    <h4 className="title-tab">{item.name}</h4>
                                                                    <div className="stars">
                                                                        <p className="name">{item.date}</p>
                                                                        <div className="App">
                                                                            <Rating
                                                                                initialValue={0}
                                                                                SVGclassName="star-svg"
                                                                                fillColor="#00000"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="name">{item.text}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>


                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>


        </>
    );
};

export default Reviews;
