import React, { useEffect, useState, useContext } from "react"
import "../MyProfile.css";
import { Scrollchor } from 'react-scrollchor';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import EditPersonalDetailsModal from "../../../common-components/AllModal/Editpersonal-Detailmodal/EditPersonalDetailsModal";
import EditFirmDetailsModal from "../../../common-components/AllModal/Editpersonal-Detailmodal/EditFirmlDetailsModal";
import { faUser, faBriefcase, faIndustry, faFilm, faLock, faChevronRight, faPen, faEye } from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../../../../utils/api";
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
library.add(fas);

const FourthModal = () => {
    const [activeTab, setActiveTab] = useState("");
    const [modalWorkingType, setModalWorkingType] = useState(false);
    const { IMG_URL, Id } =
        useContext(Context);
    console.log("ID:---", Id);


    useEffect(() => {
        if (activeTab) {
            const element = document.getElementById(activeTab.slice(1)); // Remove '#' from activeTab
            if (element) {
                const yOffset = -80;
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: "smooth" });
            }
        }
    }, [activeTab]);

    const handleTabClick = (id) => {
        setActiveTab(id);
    };

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm();



    const getAllData = async () => {
        try {
            const res = await getData(`/btb/btb-create/btb-firm-details/${Id}`);
            reset(res?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(" getValues()", getValues());

    useEffect(() => {
        getAllData();
    }, [Id]);

    return (
        <>
            <div id="sample-code4" >
                <div className="card b2bprofileCard">
                    <div className="card-body">
                        <div className="personalDetail">
                            <h6>Firm Details</h6>
                            <div className="card-body">
                                <div className="personalDetail">
                                    <div className="Innernamee">
                                        <p>GST</p>
                                        <h3>{getValues("gst_no")}</h3>
                                    </div>
                                </div>
                                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-12 ">
                                    <div className="Innernamee">
                                        <p>Attachment</p>
                                        <div className="img-div">
                                            <img className="pdf-img" src={IMG_URL + getValues("gst_image")} alt="Logo" />
                                            {/* <button className="view-icon"><FontAwesomeIcon icon={faEye} /></button> */}
                                        </div>
                                    </div>


                                </div>
                            </div>
                            {/* <button className="edit-btn" onClick={() => { setModalWorkingType(true) }}><FontAwesomeIcon icon={faPen} /></button> */}
                        </div>
                    </div>
                </div>
            </div>

            <EditFirmDetailsModal
                data={getValues()}
                show={modalWorkingType}
                onHide={() => { setModalWorkingType(false); getAllData() }}
            />
        </>
    )
}

export default FourthModal








