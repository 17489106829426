import React from "react";
import FAQBanner from "./FAQBanner/FAQBanner";
import FAQuestion from "./FAQestion/FAQestion";
import FAQForm from "./FAQForm/FAQForm";

function FAQ() {
  return (
    <>
      <FAQBanner />
      <FAQuestion />
      <FAQForm />
    </>
  );
}

export default FAQ;
