import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import "./api-list.css";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Api_response from "../Api-response/Api_response";
const Api_list = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [pageId, setPageId] = useState("");
  const [activePageId, setActivePageId] = useState("");

  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/api-documentation/website/types`);
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  const handlePageClick = (pageId) => {
    setActivePageId(pageId); // Update active page ID
  };

  console.log(activePageId, "activePageId");
  return (
    <>
      <section className="Api_list-secttt">
        <div className="row">
          <div className="col-12">
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
              <div className="row">
                <div className=" col-xxl-3 col-xl-4 col-lg-4 col-md-4">
                  <div className="accordiontabs">
                    {data &&
                      data.map((type) => (
                        <Accordion key={type.id}>
                          <Accordion.Item eventKey={type.id}>
                            <Accordion.Header>{type.name}</Accordion.Header>
                            <Accordion.Body>
                              <div className="innerlinks">
                                <Nav variant="pills" className="flex-column">
                                  {type.website_pages &&
                                    type.website_pages.map((page) => (
                                      <Nav.Item key={page.id}>
                                        <Nav.Link
                                          eventKey={page.id}
                                          onClick={() =>
                                            handlePageClick(page.id)
                                          }
                                        >
                                          {page.name}
                                        </Nav.Link>
                                      </Nav.Item>
                                    ))}
                                </Nav>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ))}
                  </div>
                </div>

                <div className="col-lg-9">
                  <Tab.Content>
                    {/* {data && data.map((type ) => ( */}
                    {/* <Tab.Pane key={type.id} eventKey={type.id}> */}
                    <Tab.Pane>
                      {/* {type.website_pages &&
                            type.website_pages.map((page) => ( */}

                      <Api_response activePageId={activePageId} />

                      {/* ))} */}
                    </Tab.Pane>
                    {/* ))} */}
                  </Tab.Content>

                  {/* <Tab.Content>
                    {data && data.map((type) => (
                      <Tab.Pane key={type.id} eventKey={type.id}>
                        {type.website_pages && type.website_pages.map((page) => (
                          <Tab.Pane key={page.id} eventKey={page.id}>
                            {activePageId === page.id && <Api_response key={page.id} id={page.id} />}
                          </Tab.Pane>
                        ))}
                      </Tab.Pane>
                    ))}
                  </Tab.Content> */}
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default Api_list;
