// InnerPageTwo.js

import React, { useState, Fragment, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../../Terms&Condition/TermsAndConditionModal";
import Select from "react-select";
import SuccessModal from "../../../../CommonForAll/SuccessModal/SuccessModal.js";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import classNames from "classnames";

import { Errors, Placeholders } from "../../../../../utils/errors";
import { FirmType } from "../../../../../utils/apis/master/Master";
import { IndustryType } from "../../../../../utils/apis/master/Master.js";
//import{IndustryType} from "../../../../../utils/apis/brand/Brand.js";
import { Context } from "../../../../../utils/context";
import {
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../../utils/apis/master/Master.js";
import {
  firmDetailNamePost,
  getFirmDetailName,
  brandPercentageSend
} from "../../../../../utils/apis/brand/Brand.js";
import { industryDetailNamePost } from "../../../../../utils/apis/brand/Brand.js";
import FirmAddressDetails from "./Industry-Details-Tab-Forms/firmAddressDetails.js";
import IndustryDetails from "./Industry-Details-Tab-Forms/IndustryDetails.js";

const InnerPageTwo = ({
  innerNext,
  setPage,
  handleBack,
  activeTab,
  navItems,
  handleTabClick,
  handleSaveAndNext,
  userID,
  firmName,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [addressCount, setAddressCount] = useState(1);
  const [pickupaddressCount, setPickupAddressCount] = useState(1);
  const [dropCount, setDropCount] = useState(0);
  const [pickupCount, setPickupCount] = useState(0);
  const [homeAddressId, setHomeAddressId] = useState("");
  const [successModalShow, setSuccessModalShow] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onFirmNameSubmit = async (d) => {
    console.log(d, "form");
    const data = {
      name: d.name,
      s_firm_type_id: d.s_firm_type_id.value,
      b_personal_details_id: userID,
      percentage: brandPercentageSend.stepFour,

    };
    if (firmDetailNameId != "") {
      data.id = firmDetailNameId;
    }
    const res = await firmDetailNamePost(data);
    if (res?.success) {
      console.log(res);
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  };

  const onIndustryNameSubmit = async (d) => {
    console.log(d, "form");
    const data = {
      name: d.name,
      s_firm_type_id: d.s_firm_type_id.value,
      s_personal_details_id: userID,
    };
    if (firmDetailNameId != "") {
      data.id = firmDetailNameId;
    }
    const res = await industryDetailNamePost(data);
    if (res?.success) {
      console.log(res);
    }
  };

  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);

  const [firmTypes, setFirmType] = useState([]);
  const getMasters = async () => {
    {
      const res = await FirmType();
      if (res?.success) {
        const data = await Select2Data(res.data, "s_firm_type_id", false);
        setFirmType(data);
      }
    }
  };



  const [firmDetailNameId, setFirmDetailNameId] = useState("");
  const [industry, setIndustry] = useState([]);
  const [addressId, setAddressId] = useState("");

  const getFirmDetailNames = async () => {
    const res = await getFirmDetailName(userID);
    if (res?.success) {
      setFirmDetailNameId(res.data.id);
      reset(res.data);
    } else {
      await setValue('name', firmName);
    }
  };

  const [error, setErrors] = useState("");
  const handleNext = async () => {
    if (
      dropCount > 0 &&
      pickupCount > 0 &&
      firmDetailNameId !== "" &&
      homeAddressId !== ""
    ) {
      setPage(4);
    } else {
      if (dropCount === 0) {
        setErrors("Drop Address Required");
      }
      if (pickupCount === 0) {
        setErrors("Pickup Address Required");
      }
      if (firmDetailNameId === "") {
        setErrors("Firm name Required");
      }
      if (homeAddressId === "") {
        setErrors("Firm Address Required");
      }
    }
  };




  useEffect(() => {
    getMasters();
    getFirmDetailNames();
  }, []);



  return (
    <div className="form-container ">
      <h6 className="ms-3 firmmmdd">
        <FontAwesomeIcon
          className="left-arrow  me-2"
          icon="fa-solid fa-arrow-left "
          onClick={handleBack}
        />
        Firm Details
      </h6>
      <div className="tab-form-container firstthreerrr">
        <Form onSubmit={handleSubmit(onFirmNameSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Enter the firm name</Form.Label>
                {/* <Form.Control type="text" placeholder="Enter firm name" /> */}
                <Form.Control
                  className={classNames("", {
                    "is-invalid": errors?.name,
                    "is-valid": getValues('name'),
                  })}
                  type="text"
                  {...register("name", {
                    required: Errors.firmName,
                  })}
                  placeholder={Placeholders.firmName}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Select your firm type</Form.Label>
                <Controller
                  name="s_firm_type_id"
                  {...register("s_firm_type_id", {
                    required: Errors.firmType,
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select

                      inputStyle={{
                        borderColor: errors.s_firm_type_id
                          ? "red"
                          : getValues('s_firm_type_id')
                            ? "green"
                            : "",
                      }}
                      {...field}
                      options={firmTypes}
                    />
                  )}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-end">
                <Button className="btn StepfoursaveBtn" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>

      <h6 className="ms-3 firmmmdd"> Industry Details </h6>
      <IndustryDetails userID={userID}  setSuccessModalShow={setSuccessModalShow} setIndustry={setIndustry}/>

      <h6 className="ms-3 firmmmdd">Firm Address Details</h6>
      <div className="tab-form-container">
        <div className="stepthreeMain">
          <FirmAddressDetails userID={userID} setSuccessModalShow={setSuccessModalShow} setAddressId={setAddressId}/>
        </div>
      </div>

      <div className="col-md-12">
        <div className="text-end btnMainn apply_now_btn">
          <div className="registerContinueMain">
            <Button className="back-btn" onClick={handleBack}>
              Back
            </Button>
          </div>

          <div className="registerContinueMain">
            <Button className="tabs-btn" onClick={() => setPage(2)}>
              Register and Continue
            </Button>

          </div>
        </div>
        <SuccessModal show={successModalShow} />
        <TermsAndConditionModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
    </div>
  );
};

export default InnerPageTwo;
