import React, { useEffect, useState, useContext } from "react"
import "../MyProfile.css";
import { Scrollchor } from 'react-scrollchor';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import EditFirmAddress from "../../../common-components/AllModal/Editpersonal-Detailmodal/EditFirmAddress";
import WorkingTypeModal from "../../../common-components/AllModal/Editpersonal-Detailmodal/WorkingTypeModal";
import { faUser, faBriefcase, faIndustry, faFilm, faLock, faChevronRight, faPen, faEye } from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../../../../utils/api";
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
library.add(fas);

const SixthModal = () => {
    const [activeTab, setActiveTab] = useState("");
    const [modalEditDetails, setModalEditDetails] = useState(false);
    const [count,setcount] = useState(0)
    const { IMG_URL, Id } =
        useContext(Context);
    console.log("ID:---", Id);


    useEffect(() => {
        if (activeTab) {
            const element = document.getElementById(activeTab.slice(1)); // Remove '#' from activeTab
            if (element) {
                const yOffset = -80;
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: "smooth" });
            }
        }
    }, [activeTab]);

    const handleTabClick = (id) => {
        setActiveTab(id);
    };

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm();


    const getAllData = async () => {
        try {
            const res = await getData(`/btb/btb-create/btb-firm-address-details/${Id}`);
            reset(res?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(" getValues()", getValues());

    useEffect(() => {
        getAllData();
    }, [Id]);

    return (
        <>
            <div id="sample-code6">
                <div className="card b2bprofileCard">
                    <div className="border-div">
                        <div className="card-body">
                        <div className="personalDetail">
                            <h4>Communication Address Details</h4>
                        </div>
                            <div className="row">
                                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12 ">
                                    <div className="Innernamee">
                                        <p>Address Type</p>
                                        <h3>{getValues("s_firm_address_type_id")?.label}</h3>
                                    </div>
                                </div>
                                <div className="col-xxl-9 col-xl-8 col-lg-6 col-md-6 col-12 ">
                                    <div className="Innernamee">
                                        <p>Address</p>
                                        <h3>{getValues("add_line_one")} , {getValues("add_line_two")} , {getValues("city_id")?.label} ,{getValues("state_id")?.label}
                                            {getValues("country_id")?.label} , {getValues("pincode_id")?.label}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="edit-btn" onClick={() => { 
                        setModalEditDetails(true) 
                        setcount((count) => count+1)
                        }}><FontAwesomeIcon icon={faPen} /></button>
                </div>
            </div>

            <EditFirmAddress
                data={getValues()}
                key={count}
                show={modalEditDetails}
                onHide={() => { setModalEditDetails(false); getAllData() }}
            />
        </>
    )
}

export default SixthModal








