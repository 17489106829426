import React, { useState } from "react";
import "./Order.css";
import Table from "react-bootstrap/Table";
import CancelOrderModal from "../../common-components/AllModal/CancelOrderModal/CancelOrderModal";
import { Link } from "react-router-dom";
import OrderCard from "./OrderCard";

const OrderComponent = () => {
  return (
    <div>
      <section className="B2b-buyer-order-sec my-4">
        <div className="container">
          <div className="row">
            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-12">
              <div className="heading">
                <h1>My Order</h1>
              </div>
              <OrderCard />
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-5">
              <div className="banner-div">
                <img
                  src="/assest/images/B2Bbuyer/HelpCenter/add.png"
                  alt="Product"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderComponent;
