import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {
  getCaution,
  getCustomizeProductAttributes,
  getPackagingAttributes,
} from "../../../../../../../../../utils/apis/master/Master";
import { Select2Data } from "../../../../../../../../../utils/api";
import Select from "react-select";
import { ProductNarrationChange } from "../../../../../../../../../utils/common";
const Packaging = ({
  reset,
  errors,
  register,
  Controller,
  control,
  getValues,
  setValue,
  working_type,
  useFieldArray,
  IMG_URL,
}) => {
  const { append, fields, remove } = useFieldArray({
    control,
    name: "packaging_details",
  });

  const validateWordCount = (value) => {
    const wordCount = value.length;
    return wordCount <= 100 || "Maximum 100 words are allowed";
  };

  const [packaging, setPackaging] = useState([]);
  const [customizeProduct, setCustomizeProduct] = useState([]);
  const getAll = async () => {
    {
      const res = await getPackagingAttributes();
      await setPackaging(
        await Select2Data(res.data, "packaging_id", false, false, true)
      );
    }

    {
      const res = await getCustomizeProductAttributes();
      await setCustomizeProduct(
        await Select2Data(res.data, "customize_product_id", false, false, true)
      );
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  return (
    <>
      {fields?.map((value, index) => (
        <>
          <div className="col-xxl-12 col-md-12 col-12 align-items-center d-flex mt-auto mb-2">
            <Controller
              name={`packaging_details[${index}].packaging_id`}
              {...register(`packaging_details[${index}].packaging_id`, {
                required: "Packaging is required",
              })}
              control={control}
              render={({ field }) => (
                <Select
                  classNamePrefix={"select-btn "}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor:
                        errors?.packaging_details &&
                        errors?.packaging_details[index] &&
                        errors?.packaging_details[index]?.packaging_id
                          ? "red"
                          : baseStyles,
                    }),
                  }}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                    setValue(
                      `packaging_details[${index}].disable`,
                      selectedOption?.document
                    );
                    setValue(
                      `packaging_details[${index}].document`,
                      selectedOption?.document
                    );
                    reset(getValues());
                  }}
                  value={field.value}
                  options={packaging}
                />
              )}
            />{" "}
            <Form.Control
              type="text"
              placeholder="Description"
              className={classNames("ms-2 me-2", {
                "is-invalid":
                  errors?.packaging_details &&
                  errors?.packaging_details[index] &&
                  errors?.packaging_details[index]?.description,
              })}
              name={`packaging_details[${index}].description`}
              {...register(`packaging_details[${index}].description`, {
                required: "Description is required",
                validate: validateWordCount,
              })}
              onChange={async (e) => {
                setValue(
                  `packaging_details[${index}].description`,
                  await ProductNarrationChange(e)
                );
              }}
            />{" "}
            <div className="">
              <p className="mb-1 minitext">Document</p>

              <Controller
                control={control}
                name={`packaging_details[${index}].document`}
                render={({ field }) => (
                  <Form.Check
                    type="switch"
                    id={`custom-switch-${index}`}
                    className={classNames("", {
                      "is-invalid":
                        errors.data &&
                        errors.data[index] &&
                        errors.data[index].document,
                    })}
                    name={`packaging_details[${index}].document`}
                    disabled={getValues(`packaging_details[${index}].disable`)}
                    checked={field.value} // Set the checked attribute based on field value
                    onChange={(e) => field.onChange(e.target.checked)} // Call field.onChange to update value
                    {...field} // Spread the field object to ensure onChange, onBlur, and value are passed
                  />
                )}
              />
            </div>
            {fields?.length !== 1 && (
              <button
                className="delet-row"
                onClick={() => remove(index)}
                type="button"
              >
                <FontAwesomeIcon icon="fa-solid fa-minus" />
              </button>
            )}
          </div>
        </>
      ))}
      {fields?.length < 6 && (
        <button
          className="addrowiccc"
          type="button"
          style={{
            float: "right",
          }}
          onClick={() =>
            append({
              name: "",
            })
          }
        >
          <img
            style={{ margin: "0px" }}
            src={
              process.env.PUBLIC_URL +
              "/assest/images/Seller_panel/dashboard/icons/addicooo.png"
            }
          />
        </button>
      )}
    </>
  );
};

export default Packaging;
