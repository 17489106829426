import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Errors, Placeholders } from "../../../../../utils/errors";
import {
  sendMobileOTP,
  sendEmailOTP,
  verifyMobileOTP,
  verifyEmailOTP,
} from "../../../../../utils/apis/common/Common";

import { Context } from "../../../../../utils/context";
import classNames from "classnames";
import {
  AttachmentPost,
  getAttachment,
} from "../../../../../utils/apis/three_p_support/three_p_support";

library.add(fas);

function Steptwo(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: true };

  const { getData, postData, IMG_URL } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  console.log(errors);

  const onSubmit = async (d) => {
    const data = new FormData();

    if (firmId != "") {
      data.append("id", firmId);
    }
    data.append("logo", d.logo[0]);
    data.append("banner", d.banner[0]);
    data.append("agreement", d.agreement[0]);

    data.append(
      "three_p_personal_details_id",
      props?.userDetails?.three_p_personal_details_id
    );

    const res = await AttachmentPost(data);
    if (res?.success) {
      console.log(res, "success");
      props.nextStep();
    }
  };

  const [firmId, setfirmId] = useState("");

  const getUserFirmDetails = async () => {
    const res = await getAttachment(
      props?.userDetails?.three_p_personal_details_id
    );
    if (res?.success) {
      setStoreLogo(IMG_URL + res.data.logo);
      setStoreBanner(IMG_URL + res.data.banner);
      setStoreAgreement(IMG_URL + res.data.agreement);
      setfirmId(res.data.id);
      reset(res.data);
    }
  };

  useEffect(() => {
    getUserFirmDetails();
  }, []);

  const [selectedLogoImages, setSelectedLogoImage] = useState(null);
  const [storeLogo, setStoreLogo] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedLogoImage(file);
    setStoreLogo(null);
    clearErrors("logo");
  };

  const handleRemoveFile = () => {
    setSelectedLogoImage(null);
    const fileInput = document.getElementById("fileLogoInput");
    fileInput.value = "";
  };

  const [selectedAgreementImages, setSelectedAgreementImage] = useState(null);
  const [storeAgreement, setStoreAgreement] = useState(null);
  const handleFileAgreementChange = (e) => {
    const file = e.target.files[0];
    setSelectedAgreementImage(file);
    setStoreAgreement(null);
    clearErrors("agreement");
  };

  const handleAgreementRemoveFile = () => {
    setSelectedAgreementImage(null);
    const fileInput = document.getElementById("fileAgreementInput");
    fileInput.value = "";
  };

  const [selectedBannerImages, setSelectedBannerImage] = useState(null);
  const [storeBanner, setStoreBanner] = useState(null);

  const handleFileBannerChange = (e) => {
    const file = e.target.files[0];
    console.log("Selected banner image:", file);
    setSelectedBannerImage(file);
    setStoreBanner(null);
    clearErrors("banner");
  };

  const handleRemoveBannerFile = () => {
    setSelectedBannerImage(null);
    const fileInput = document.getElementById("filebannerInput");
    fileInput.value = "";
  };

  useEffect(() => {
    getUserFirmDetails();
  }, []);

  return (
    <>
      {/* form-holder */}
      <div className="details-form-holder">
        <h6 className="ms-3 firmmmdd">Attachments</h6>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="tab-form-container firstthreerrr">
              <div className="row">
                <div className="col-lg-4">
                  <div className="uploadGsthead">
                    <h3>Upload Logo</h3>
                  </div>

                  <div className="image-preview">
                    <div className="uploadBtnsec">
                      {storeLogo !== null ? (
                        <input
                          type="file"
                          id="fileLogoInput"
                          accept="image/*"
                          {...register("logo")}
                          onChange={handleFileChange}
                        />
                      ) : (
                        <input
                          type="file"
                          id="fileLogoInput"
                          accept="image/*"
                          {...register("logo", {
                            required: "Logo image is required",
                          })}
                          onChange={handleFileChange}
                        />
                      )}
                    </div>
                    {selectedLogoImages && (
                      <div className="previewImgmain">
                        <img
                          src={URL.createObjectURL(selectedLogoImages)}
                          alt={`Preview of ${selectedLogoImages.name}`}
                          className="previewImg"
                        />
                        <div>
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-xmark"
                            className="circlecrossMark"
                            onClick={handleRemoveFile}
                          />
                        </div>
                      </div>
                    )}
                    {storeLogo !== null && (
                      <div className="previewImgmain">
                        <img src={storeLogo} className="previewImg" />
                      </div>
                    )}
                  </div>
                  {errors.logo && (
                    <span className="text-danger">{errors.logo.message}</span>
                  )}
                </div>

                <div className="col-lg-4">
                  <div className="uploadGsthead">
                    <h3>Upload Banner</h3>
                  </div>

                  <div className="image-preview">
                    <div className="uploadBtnsec">
                      {storeBanner !== null || selectedBannerImages !== null ? (
                        <input
                          type="file"
                          id="filebannerInput"
                          accept="image/*"
                          {...register("banner")}
                          onChange={handleFileBannerChange}
                        />
                      ) : (
                        <input
                          type="file"
                          id="filebannerInput"
                          accept="image/*"
                          {...register("banner", {
                            required: "Banner image is required",
                          })}
                          onChange={handleFileBannerChange}
                        />
                      )}
                    </div>
                    {selectedBannerImages && (
                      <div className="previewImgmain">
                        <img
                          src={URL.createObjectURL(selectedBannerImages)}
                          alt={`Preview of ${selectedBannerImages.name}`}
                          className="previewImg"
                        />
                        <div>
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-xmark"
                            className="circlecrossMark"
                            onClick={handleFileBannerChange}
                          />
                        </div>
                      </div>
                    )}
                    {storeBanner !== null && (
                      <div className="previewImgmain">
                        <img src={storeBanner} className="previewImg" />
                      </div>
                    )}
                  </div>
                  {errors.banner && (
                    <span className="text-danger">{errors.banner.message}</span>
                  )}
                </div>

                <div className="col-lg-4">
                  <div className="uploadGsthead">
                    <h3>Upload Agreement Copy</h3>
                  </div>

                  <div className="image-preview">
                    <div className="uploadBtnsec">
                      {storeAgreement !== null ? (
                        <input
                          type="file"
                          id="fileAgreementInput"
                          accept="image/*"
                          {...register("agreement")}
                          onChange={handleFileAgreementChange}
                        />
                      ) : (
                        <input
                          type="file"
                          id="fileAgreementInput"
                          accept="image/*"
                          {...register("agreement", {
                            required: "agreement image is required",
                          })}
                          onChange={handleFileAgreementChange}
                        />
                      )}
                    </div>
                    {selectedAgreementImages && (
                      <div className="previewImgmain">
                        <img
                          src={URL.createObjectURL(selectedAgreementImages)}
                          alt={`Preview of ${selectedAgreementImages.name}`}
                          className="previewImg"
                        />
                        <div>
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-xmark"
                            className="circlecrossMark"
                            onClick={handleFileAgreementChange}
                          />
                        </div>
                      </div>
                    )}
                    {storeAgreement !== null && (
                      <div className="previewImgmain">
                        <img src={storeAgreement} className="previewImg" />
                      </div>
                    )}
                  </div>
                  {errors.agreement && (
                    <span className="text-danger">
                      {errors.agreement.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="text-end btnMainn apply_now_btn">
                {/* <p onClick={() => setModalShow(true)}>
                  By continuing, I agree to Netpurti's
                  <span className="termstxt">Terms of Use & Privacy Policy</span>
                </p> */}
                <div className="registerContinueMain">
                  <Button onClick={props.prevStep} className="back-btn">
                    Back
                  </Button>

                </div>
                <div className="registerContinueMain">
                  <Button
                    // onClick={props.nextStep}
                    className="tabs-btn"
                    type="submit"
                  >
                    Register & Continue
                  </Button>

                </div>
              </div>

              <TermsAndConditionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Steptwo;
