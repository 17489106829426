import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";


export const CheckerPersonalDetailsPost = async (data) => {
  try {

    const res = await postData(`/checker/checker-create/checker-personal-details`, data)

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const checkerPasswordPost = async (data) => {
  try {

    const res = await postData(`/checker/checker-create/checker-personal-details/password`, data)

    return res;
  } catch (error) {
    console.log(error);
  }
};

// export const getSectorCategory = async (user_id) => {
//   try {

//     const res = await getData(`/seller/seller-create/s-sector-category/${user_id}`)

//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const firmDetailsPost = async (data) => {
  try {

    const res = await postData(`/checker/checker-create/checker-firm-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getfirmDetails = async (CheckerId) => {
  try {

    const res = await getData(`/checker/checker-create/checker-firm-details/${CheckerId}`);
    console.log("res firm :-", CheckerId);
    console.log("res firm :-", res);
    return res;

  } catch (error) {
    console.log(error);
  }
};

export const firmDetailNamePost = async (data) => {
  try {

    const res = await postData(`/checker/checker-create/checker-firm-details/name`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getFirmDetailName = async (user_id) => {
  try {

    const res = await getData(`/checker/checker-create/checker-firm-details/name/${user_id}`);
    console.log("res firm :-", user_id);
    console.log("res firm :-", res);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmAddressDetailPost = async (data) => {
  try {

    const res = await postData(`/checker/checker-create/checker-communication-address`, data);


    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmAddressDetail = async (user_id) => {
  try {

    const res = await getData(`/checker/checker-create/checker-communication-address/${user_id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmBankDetailPost = async (data) => {
  try {

    const res = await postData(`/checker/checker-create/checker-bank-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmBankDetail = async (data) => {
  try {

    const res = await getData(`/checker/checker-create/checker-bank-details/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};


export const CheckerBranchDetailPost = async (data) => {
  try {

    const res = await postData(`/checker/checker-create/checker-branch-details`, data);
    console.log("Data to send in branch Details :- ", res.data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCheckerBranchDetail = async (data) => {
  try {

    const res = await getData(`/checker/checker-create/checker-branch-details/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const CheckerBranchAddDetailPost = async (data) => {
  try {

    const res = await postData(`/checker/checker-create/checker-branch-add-details`, data);
    console.log("Data to send in branch Address Details :- ", res.data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCheckerBranchAddDetail = async (data) => {
  try {

    const res = await getData(`/checker/checker-create/checker-branch-add-details/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const CheckerAttachmentPost = async (data) => {
  try {

    const res = await postData(`/checker/checker-create/checker-attachment`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCheckerAttachment = async (data) => {
  try {

    const res = await getData(`/checker/checker-create/checker-attachment/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const CheckerServiceAreaPost = async (data) => {
  try {

    const res = await postData(`/checker/checker-create/checker-service-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCheckerServiceArea = async (data) => {
  try {

    const res = await getData(`/checker/checker-create/checker-service-details/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const CheckerSectorPost = async (data) => {
  try {

    const res = await postData(`/checker/checker-create/checker-sector`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCheckerSector = async (data) => {
  try {

    const res = await getData(`/checker/checker-create/checker-sector/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

