import React from 'react'
import { Route, Routes } from 'react-router-dom'
import B2Cretail_Routes_main from '../B2Cretail_Routes_main/B2Cretail_Routes_main';

const B2Cretail_Routes = () => {
  return (
    <>
      <Routes>
        <Route path={`/b2cretail/*`} element={<B2Cretail_Routes_main />} />
      </Routes>
    </>
  )
}

export default B2Cretail_Routes