import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import './NewChat.css'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
const NewChat = ({ show, handleClose, ...props }) => {
    const handleCloseInternal = () => {
        handleClose();
    };

    return (
        <>
            <Offcanvas
                className="OffcanvasChatnow-new"
                show={show}
                onHide={handleClose}
                {...props}
            >

                <Offcanvas.Body>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col lg={8} md={8} className="order-le-1 order-md-1 oeder-sm-2 order-2">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="title ">
                                            <div className="d-flex-div ">
                                                <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/BestSeller/pngtree.png"} className="userImg" />
                                                <h1>Jade Sidior</h1>
                                            </div>
                                            <div>
                                                <button className="cls-btn" >
                                                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                </button>
                                            </div>
                                        </div>

                                        <div className="left-side-content">
                                            <div className="left-flex">
                                                <p>2024-04-02 | 12:04 pm</p>
                                                <div className="bg-div">
                                                    D
                                                </div>
                                                <p>you</p>
                                            </div>
                                            <div className="info-div">
                                                <div className="row">
                                                    <div className=" col-md-6 col-12">
                                                        <div className="d-flex-div">
                                                            <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/BestSeller/productImg3.png"} className="userImg-big" />
                                                            <p>Armchair New Arrival Promotion Fabric Material Adjustable Leisure Single Power Electric Relax</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12 left-border1">
                                                        <div className="price-div">
                                                            <h6>
                                                                10,000
                                                            </h6>
                                                            <p>( Min Order : 50pcs )</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="left-flex justify-content-start">
                                                <div className="bg-div-two bg-div">
                                                    D
                                                </div>
                                                <p>you</p>
                                                <p>2024-04-02 | 12:04 pm</p>
                                            </div>
                                            <div className="info-div">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-10 col-12 bottom-border">
                                                        <div className="d-flex-div">
                                                            <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/BestSeller/productImg3.png"} className="userImg-two" />
                                                            <p>Armchair New Arrival Promotion Fabric Material Adjustable Leisure Single Power Electric Relax</p>
                                                            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-12 col-xl-12 col-md-12 col-12 ">
                                                        <div className="quantity-div mt-2">
                                                            <p>
                                                                Quantity : ≥10.0 Set/Sets
                                                            </p>
                                                            <p>Unit price : ₹10,000</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <Form.Group className=" form-div mt-5 mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control as="textarea" rows={4} placeholder="Enter your message here" />
                                                    <div className="textAreaMain message-div">
                                                        <div className="galleryIconsec">
                                                            <FontAwesomeIcon icon={faSmile} className="Icon mx-2" />

                                                            <FontAwesomeIcon icon={faImage} className="Icon" />
                                                        </div>
                                                        <div className="sendbtnSec">
                                                            <button className="sendbtn">Send</button>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="title ">
                                            <div className="d-flex-div ">
                                                <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/BestSeller/search-grid.png"} className="userImg" />
                                                <h1>Maud Zheng</h1>

                                            </div>
                                            {/* <div>
                                                <button className="cls-btn" onClick={handleCloseInternal}>
                                                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                </button>
                                            </div> */}
                                        </div>

                                        <div className="left-side-content">
                                            <div className="left-flex">
                                                <p>2024-04-02 | 12:04 pm</p>
                                                <div className="bg-div">
                                                    D
                                                </div>
                                                <p>you</p>
                                            </div>
                                            <div className="info-div">
                                                <div className="row">
                                                    <div className=" col-md-6 col-12">
                                                        <div className="d-flex-div">
                                                            <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/BestSeller/productImg3.png"} className="userImg-big" />
                                                            <p>Armchair New Arrival Promotion Fabric Material Adjustable Leisure Single Power Electric Relax</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12 left-border">
                                                        <div className="price-div">
                                                            <h6>
                                                                10,000
                                                            </h6>
                                                            <p>( Min Order : 50pcs )</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="left-flex justify-content-start">
                                                <div className="bg-div-two bg-div">
                                                    D
                                                </div>
                                                <p>you</p>
                                                <p>2024-04-02 | 12:04 pm</p>
                                            </div>
                                            <div className="info-div">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-10 col-12 bottom-border">
                                                        <div className="d-flex-div">
                                                            <img
                                                                src={
                                                                    process.env.PUBLIC_URL +
                                                                    "/assest/images/B2Bbuyer/BestSeller/productImg3.png"
                                                                }
                                                                className="userImg-two"
                                                            />
                                                            <p>Armchair New Arrival Promotion Fabric Material Adjustable Leisure Single Power Electric Relax</p>
                                                            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-12 col-xl-12 col-md-12 col-12 ">
                                                        <div className="quantity-div mt-2">
                                                            <p>
                                                                Quantity : ≥10.0 Set/Sets
                                                            </p>
                                                            <p>Unit price : ₹10,000</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <Form.Group className=" form-div mt-5 mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control as="textarea" rows={4} placeholder="Enter your message here" />
                                                    <div className="textAreaMain message-div">
                                                        <div className="galleryIconsec">
                                                            <FontAwesomeIcon icon={faSmile} className="Icon mx-2" />

                                                            <FontAwesomeIcon icon={faImage} className="Icon" />
                                                        </div>
                                                        <div className="sendbtnSec">
                                                            <button className="sendbtn">Send</button>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                            <Col lg={4} md={4} className='left-border order-le-2 order-md-2 oeder-sm-1 order-1'>
                                <div className="message-div bottom-border ">
                                    <h1>Messages</h1>
                                    <button className="cls-btn" onClick={handleCloseInternal}>
                                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                    </button>
                                </div>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            <div className="message-container d-flex-div my-2">
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/BestSeller/pngtree.png"} className="userImg" />
                                                </div>
                                                <div>
                                                    <h6>Jade Sidior</h6>
                                                    <p>Sidior Furniture Company Limit...</p>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            <div className="message-container d-flex-div my-2">
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/BestSeller/search-grid.png"} className="userImg" />
                                                </div>
                                                <div>
                                                    <h6>Maud Zheng</h6>
                                                    <p>Ganzhou Hoyoo Electronic Tec</p>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                    </Tab.Container>
                    {/* <div className="col-xxl-8 col-xl-8 col-md-8 col-14">
                        <div className="title ">
                            <div className="d-flex-div ">

                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/B2Bbuyer/BestSeller/userimg.png"
                                    }
                                    className="userImg"
                                />
                                <h1>Rose Merry</h1>

                            </div>
                            <div>
                                <button className="cls-btn" onClick={handleCloseInternal}>
                                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                </button>
                            </div>
                        </div>

                        <div className="left-side-content">
                            <div className="left-flex">
                                <p>2024-04-02 | 12:04 pm</p>
                                <div className="bg-div">
                                    D
                                </div>
                                <p>you</p>
                            </div>
                            <div className="info-div">
                                <div className="row">
                                    <div className="col-xxl-8 col-xl-8 col-md-8 col-12">
                                        <div className="d-flex-div">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assest/images/B2Bbuyer/BestSeller/userimg.png"
                                                }
                                                className="userImg-big"
                                            />
                                            <p>Armchair New Arrival Promotion Fabric Material Adjustable Leisure Single Power Electric Relax</p>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-md-4 col-12 left-border">
                                        <div className="price-div">
                                            <h6>
                                                10,000
                                            </h6>
                                            <p>( Min Order : 50pcs )</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="left-flex justify-content-start">

                                <div className="bg-div-two bg-div">
                                    D
                                </div>
                                <p>you</p>
                                <p>2024-04-02 | 12:04 pm</p>
                            </div>
                            <div className="info-div">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-md-12 col-12 bottom-border">
                                        <div className="d-flex-div">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assest/images/B2Bbuyer/BestSeller/userimg.png"
                                                }
                                                className="userImg-two"
                                            />
                                            <p>Armchair New Arrival Promotion Fabric Material Adjustable Leisure Single Power Electric Relax</p>
                                            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                                        </div>
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-md-12 col-12 ">
                                        <div className="quantity-div mt-2">
                                            <p>
                                                Quantity : ≥10.0 Set/Sets
                                            </p>
                                            <p>Unit price : ₹10,000</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Form.Group className=" form-div mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={4} placeholder="Enter your message here" />
                                    <div className="textAreaMain message-div">
                                        <div className="galleryIconsec">
                                            <FontAwesomeIcon icon={faSmile} className="Icon mx-2" />

                                            <FontAwesomeIcon icon={faImage} className="Icon" />
                                        </div>
                                        <div className="sendbtnSec">
                                            <button className="sendbtn">Send</button>
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-md-4  col-14 left-border">
                        <div className="message-div bottom-border ">
                            <h1>Messages</h1>
                            <button className="cls-btn" onClick={handleCloseInternal}>
                                <FontAwesomeIcon icon="fa-solid fa-xmark" />
                            </button>
                        </div>
                        <div className="message-container d-flex-div my-2">
                            <div>
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/B2Bbuyer/BestSeller/userimg.png"
                                    }
                                    className="userImg"
                                />
                            </div>
                            <div>
                                <h6>Jade Sidior</h6>
                                <p>Sidior Furniture Company Limit...</p>
                            </div>
                        </div>
                    </div> */}

                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default NewChat;
