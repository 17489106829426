import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../../../Components/assets/icons/a1.png";
import colunms from "../../../../../../Components/assets/icons/LINES.png";
import pen from "../../../../../../Components/assets/icons/pen.png";
import basket from "../../../../../../Components/assets/icons/basket.png";
import search1 from "../../../../../../Components/assets/icons/search.png";
import top from "../../../../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../../../../assets/icons/circle.png";
import rigth from "../../../../../assets/icons/rigth.png";
import save from "../../../../../assets/icons/save.png";
import cancel from "../../../../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
// import Header from "../../../../../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
// import AddOffCanvance from "./Add";
// import EditOffCanvance from "./Edit";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../../../../utils/context";

library.add(fas);

// ********************************************************************************************************************************************************

const Category = () => {
  const {
    postData,
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    Select2Data,
    IMG_URL,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const [customerName, setCustomerName] = useState("");
  const [serachProduct, setSerachProduct] = useState("");
  const [serachOrderStatus, setSearchOrderSatatus] = useState("");
  const [serachPaymentStatus, setSearchPaymentSatatus] = useState("");

  const getDataAll = async () => {
    const response = await getData(
      `/seller/super-admin/sub-category?page=${currentPage}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };
  useEffect(() => {
    getDataAll();
  }, [
    perPage,
    reset,
    show,
    show1,
    customerName,
    serachProduct,
    serachOrderStatus,
    serachPaymentStatus,
  ]);

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  return (
    <>
      <div className="main-advancedashboard">
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio mt-5">
                <div className="">
                  <div className="row">
                    <div className="d-flex">
                      <div className="add me-3"></div>
                      <div className="add">
                        <div className="dropdown">
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={colunms} className="columns " alt="" />
                            Column Selection{" "}
                            <img src={top} className="top ms-1" alt="" />{" "}
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                                href="#"
                              >
                                Sr. No.
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                                href="#"
                              >
                                Category
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col3")}
                                href="#"
                              >
                                Image
                                {visible?.col3 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-line mt-3"></div>
                    <div className="row  me-0 ms-0 mt-4">
                      <div className="col-xxl-12 col-xl-12  col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <div className="show me-2">
                            <p className="show mt-1">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show mt-1">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="  col-xxl-12 col-xl-12 col-lg-12  col-md-12 col-12">
                        <div className="d-flex justify-content-end">
                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>

                          <div className="Search me-2">
                            <button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </button>
                          </div>

                          <div className="Search-1">
                            <button
                              type="button"
                              onClick={() => {
                                setSearchPaymentSatatus("");
                                setSearchOrderSatatus("");
                                setSerachProduct("");
                                setCustomerName("");
                                setSearch("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3 me-0 ms-0">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {/* {visible.col2 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Invoice No.
                              </th>
                            )} */}
                            {visible.col2 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Category
                              </th>
                            )}
                            {visible.col2 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Sub Category
                              </th>
                            )}
                            {visible.col3 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Image
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {isAllow.includes(5) ? ( */}
                          {data?.data?.data?.map((d, index) => {
                            return (
                              <tr className="" key={index}>
                                {/* <td>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value={d?.id}
                                    name="perselected"
                                    checked={selectAllChecked.includes(d?.id)}
                                    onChange={handleChange}
                                    id="flexCheckDefault"
                                  ></input>
                                </td> */}
                                {visible.col1 && <td>{++index}.</td>}
                                {visible.col2 && (
                                  <td>{d.s_category?.name}</td>
                                )}{" "}
                                {visible.col2 && <td>{d.name}</td>}
                                {visible.col3 && (
                                  <td>
                                    {d?.image && (
                                      <img
                                        src={`${IMG_URL}${d.image}`}
                                        alt="Image"
                                        width="120"
                                        height="100"
                                      />
                                    )}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      <Toaster position="top-right" />
    </>
  );
};

export default Category;
