import React from "react";
import "./Affilate_All_product_count_list.css";
import { faArrowTrendDown, faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Affilate_All_product_count_list = () => {
  const product_list = [
    {
      icon: "/assest/images/Seller_panel/AffilateDashboard/Icon/cursor.png",
      name: "Total Clicks",
      number: "300",
      backgroundColor: "#FF9E4A", // Example background color
      iconClassName:"line_up_down_icon",
      faIcon: faArrowTrendUp 
    },
    {
      icon: "/assest/images/Seller_panel/AffilateDashboard/Icon/staffing.png",
      name: "Total onbording",
      number: "150",
      backgroundColor: "#FF4A4A", // Example background color
      iconClassName:"line_up_down_icon",
      faIcon: faArrowTrendUp 
    },
    {
      icon: "/assest/images/Seller_panel/AffilateDashboard/Icon/user.png",
      name: "Renewable Customer",
      number: "500",
      backgroundColor: "#4AFFDA", // Example background color
      iconClassName:"line_up_down_icon",
      faIcon: faArrowTrendUp 
    },
    {
      icon: "/assest/images/Seller_panel/AffilateDashboard/Icon/task.png",
      name: "Active Links",
      number: "89K",
      backgroundColor: "#FF4A8E", // Example background color
      iconClassName:"line_up_down_icon",
      faIcon: faArrowTrendUp 
    },
    {
      icon: "/assest/images/Seller_panel/AffilateDashboard/Icon/black.png",
      name: "Earning",
      number: "30",
      backgroundColor: "#AAFF87", // Example background color
      iconClassName:"line_up_down_icon_2",
      faIcon: faArrowTrendDown
    },
    {
      icon: "/assest/images/Seller_panel/AffilateDashboard/Icon/user.png",
      name: "Conversion",
      number: "30",
      backgroundColor: "#4AA5FF", // Example background color
      iconClassName:"line_up_down_icon_2",
      faIcon: faArrowTrendDown
      
    },
  ];

  return (
    <>
      <section className="Affilate_All_product_count_list">
        <div className="row">
          {product_list.map((data, index) => (
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-4 ">
              <div className="list-flex">
                <div className="img-div">
                  <div
                    className="circle-ico ico1 "
                    style={{ backgroundColor: data.backgroundColor }}
                  >
                    <img className="icon" src={data.icon} />
                  </div>
                </div>
                <div className="name_lineup_holder">
                  <div className="cont-div">
                    <p className="name">{data.name}</p>
                    <p className="number">{data.number}</p>
                  </div>

                  <div>
                    <FontAwesomeIcon
                      // className="line_up_down_icon"
                      className={data.iconClassName}
                      icon={data.faIcon}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Affilate_All_product_count_list;


