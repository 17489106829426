import React from "react";
import "./FAQBanner.css";
function FAQBanner() {
  return (
    <section className="FAQ-banner">
      <div className="heading">
        <h1>Frequently Asked Questions</h1>
      </div>

      <div>
        <img
          src={
            process.env.PUBLIC_URL +
            "/assest/images/Seller_panel/FAQ/FaqBanner.png"
          }
          className="Faqbannerimg"
        />
      </div>
    </section>
  );
}

export default FAQBanner;
