import React, { useContext } from "react";
import { useState, useEffect } from "react";
import './ThemePage.css';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ReactStars from 'react-stars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useParams } from 'react-router-dom';
import { Context } from "../../../../../../utils/context";
import { activateTheme, getMypackageThemes } from "../../../../../../utils/apis/seller/Mypackages/MyPackages";
import ConfirmModalDynamic from "../../../../../CommonForAll/ConfirmModalDynamic/PromptModalDynamic";
import SuccessModalDynamic from "../../../../../CommonForAll/SuccessModalDynamic/SuccessModalDynamic";
import { depositAmountPost, getMypackages } from "../../../../../../utils/apis/seller/Mypackages/MyPackages";
const ThemePage = () => {
    const { id } = useParams();
    const ratingChanged = (newRating) => {
        console.log(newRating);
    };

    const navigate = useNavigate();

    const { getData, IMG_URL,isTokenValid } = useContext(Context);
    const [successShow, setSuccessShow] = useState(false);
    const [activateShow, setActivateShow] = useState(false);
    const [themeId, setThemeId] = useState();
    const [productDetails, setProductDetails] = useState();
    const [tabId, setTabId] = useState();
    const getProduct = async () => {
        const res = await getMypackageThemes(id);
        if (res?.success) {
            setProductDetails(res?.data)
            // setTabId(res?.data[0]?.m_w_plan_details[0]?.id)
        } else {

        }
    }

    const handleConfirm = async (theme, domain_id) => {
        setActivateShow(false);
        const data = {
            theme_id : theme,
            domain_id:domain_id,
        };
        const res = await activateTheme(data);

        if(res?.success){
            setSuccessShow(true);
            setTimeout(() => {
                getProduct();
                
                   isTokenValid();
                    setSuccessShow(false);
            }, 3000);
        }
    }
    useEffect(() => {
        getProduct();
    }, []);


    const { postData,Id } = useContext(Context);


    const [sellerWallet, setSellerWallet] = useState();
    const getWalletHistory = async () => {
      const res = await getMypackages();
      if (res?.success) {
        setSellerWallet(res?.data);
      }
    };
  console.log(sellerWallet,"sellerWallet");
    const [depositAmount, setAmount] = useState(0);
    const [amountModal, setAmountModal] = useState(false);
    const addWallet = () => {
      setAmountModal(true);
    }
  
    useEffect(() => {
      getWalletHistory();
    }, []);
  
    const getStatus = (expDate) => {
      if (!expDate) return 'Inactive';   
      const [day, month, year] = expDate.split('/');
      const expirationDate = new Date(year, month - 1, day);
      const currentDate = new Date();
      return expirationDate >= currentDate ? 'Active' : 'Inactive';
    };

    return (
        <section className='blog-card'>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate("/seller/seller-dashboard/my-package")}>Package</Breadcrumb.Item>
                    <Breadcrumb.Item active>Theme</Breadcrumb.Item>
                </Breadcrumb>

                <div className="theme-infodesdiv">
                <div className="row">
                   
                        <div className="col-xxl-3 col-lg-4  col-sm-6 col-12 mb-3">
                            <p className="data">Plan Name:<span> {productDetails?.domainData?.seller_plan?.m_w_plan_detail?.name}</span></p>
                        </div>
                        <div className="col-xxl-3 col-lg-4  col-sm-6 col-12 mb-3">
                            <p className="data">Exp date:<span> {productDetails?.domainData?.seller_plan?.exp_date}</span></p>
                        </div>
                        <div className="col-xxl-3 col-lg-4  col-sm-6 col-12 mb-3">
                            <p className="data">Domain Name:<span> {productDetails?.domainData?.domain_name}</span></p>
                        </div>
                        <div className="col-xxl-3 col-lg-4  col-sm-6 col-12 mb-3">
                            <p className="data">Domain:<span>  <a target="_blank" href={productDetails?.domainData?.domain}>{productDetails?.domainData?.domain}</a></span></p>
                        </div>
                        <div className="col-xxl-3 col-lg-4  col-sm-6 col-12 mb-3">
                            <p className="data">Dns: {productDetails?.domainData?.dns}</p>
                        </div>
                        <div className="col-xxl-3 col-lg-4  col-sm-6 col-12 mb-3">
                            <p className="data">Amount:<span>₹ {productDetails?.domainData?.seller_plan?.amount}</span>	</p>
                        </div>
                        <div className="col-xxl-3 col-lg-4  col-sm-6 col-12 mb-3">
                            <p className="data">Status:<span style={getStatus(productDetails?.domainData?.seller_plan?.exp_date) === 'Active' ? { color: 'green' } : { color: 'red' }}> {getStatus(productDetails?.domainData?.seller_plan?.exp_date)}</span>	</p>
                        </div>
                       
                    </div>
                </div>

                <Row>
                    {productDetails?.data?.map((val) => (
                        <Col key={val.id} xxl={3} xl={3} lg={4} md={6} sm={12} xs={12}>
                            {/* <Link to={`/seller/seller-dashboard/theme-details/${val?.theme?.id}`}> */}

                            <Card className={productDetails?.domainData?.theme_id === val?.theme?.id ? "activate" : ""}>
                                <div className='blog-main-img'>
                                    <img src={IMG_URL + val?.theme?.theme_images?.[0]?.image} className='blog-card-img' alt="{image}" />
                                    {/* <div className="overlay">
                                            <div className="dot-lotti">
                                                <button type='button' className='continue-btn'>Live Preview <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></button>

                                            </div>
                                        </div> */}
                                </div>
                                <Card.Body>
                                    <Card.Title className='card-tit'>{val?.theme?.name}</Card.Title>
                                    <Row>
                                        {/* <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                                <div className='blog-price'>{val.price}</div>
                                                <ReactStars
                                                    count={5}
                                                    onChange={ratingChanged}
                                                    size={15}
                                                    color2={'#ffd700'} />
                                                <div className='blog-txtt'>{val.sales}</div>
                                            </Col> */}

                                        {productDetails?.domainData?.theme_id === val?.theme?.id ? (
                                            <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                                                <div className='mt-4'>
                                                    <Link >
                                                        <button type='button' className='active-now'>Active Theme</button>
                                                    </Link>
                                                </div>
                                            </Col>
                                        ) : (
                                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                                <div className='mt-4'>
                                                    <Link >
                                                        <button type='button' className='buy-now' onClick={() => {setThemeId(val?.theme?.id); setActivateShow(true);}} >Activate</button>
                                                    </Link>
                                                </div>
                                            </Col>
                                        )}


                                    </Row>
                                </Card.Body>
                            </Card>
                            {/* </Link> */}
                        </Col>
                    ))}
                </Row>
            </Container>
            <ConfirmModalDynamic show={activateShow} onHide={() => setActivateShow(false)} handleConfirm={() => handleConfirm(themeId,id)} buttonText={"Activate"}  text={"After Activation your request will be sent it will take around 2-3 hours to reflect. Are you sure you want to proceed?"}/>
                <SuccessModalDynamic show={successShow} onHide={() => setSuccessShow(false)} text={"Theme Succesfully Activated"}/>
        </section>

        
    );
};

export default ThemePage;
