// import React from 'react'
import './WhyPursuing.css'
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
const WhyPursuing = () => {
  return (
    <section className='why-pursuing'>
    <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
        <SwiperSlide><img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/RFQ/banner-profcyma.png"} className="company-img" alt="..." /></SwiperSlide>
        <SwiperSlide><img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/RFQ/banner-profcyma.png"} className="company-img" alt="..." /></SwiperSlide>
        <SwiperSlide><img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/RFQ/banner-profcyma.png"} className="company-img" alt="..." /></SwiperSlide>
        
      </Swiper>
      {/* <div className='main-img-class'>
        <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/RFQ/Profcyma-LOGO_Approved-(1).png"} className="company-img" alt="..." />
        <h2 className='why-pursuing-txt mt-4'>Why Pursuing </h2>
        <div className='title-online'>Online MBA</div>
        <p className='smart-choice'>In session 2024 is a smart choice</p>
        <button className='apply-now-btn'>Apply Now</button>
        <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/RFQ/girl.png"} className="girl-img" alt="..." />
      </div> */}
    </section>
  )
}

export default WhyPursuing