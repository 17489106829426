import React, { useContext, useEffect, useState } from "react";
import "./Order.css";
import Table from "react-bootstrap/Table";
import CancelOrderModal from "../../common-components/AllModal/CancelOrderModal/CancelOrderModal";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { getAllOrders } from "../../../../utils/apis/btb/buy_order_details";
import { useForm } from "react-hook-form";
import { IDS } from "../../../../utils/common";
import Select from "react-select";
import { BtbOrderStatus, BtbProductTypes } from "../../../../utils/apis/master/Master";
import search1 from "../../../assets/icons/search.png";


const OrderCard = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchAmount, setSearchAmount] = useState("");
  const [searchInvoice, setSearchInvoice] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [searchStatus, setSearchStatus] = useState({});
  const [searchType, setSearchType] = useState({});
  const [resetButten, setResetButten] = useState();
  const { IMG_URL, Select2Data, getData } = useContext(Context);

  const { reset } = useForm();

  const getOrders = async () => {
    const res = await getData(`/btb/order-process/order?searchName=${searchName}&searchAmount=${searchAmount}` +
      `&searchInvoice=${searchInvoice}&searchStatus=${searchStatus?.value || ""}&searchDate=${searchDate || ""}&searchType=${searchType?.value || ""}`);
    reset(res?.data);
    setOrders(res?.data);
  };

  useEffect(() => {
    getOrders()
  }, [searchName, searchAmount, searchInvoice, searchStatus, searchDate, resetButten, searchType])

  const getOrderStatus = async () => {
    const res = await BtbOrderStatus();
    setOrderStatus(await Select2Data(res?.data, "order_status_id"));
  };
  const getProductType = async () => {
    const res = await BtbProductTypes();
    setProductTypes(await Select2Data(res?.data, "s_product_type_id"));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    getProductType();
    getOrderStatus();
    getOrders();
  }, []);

  return (
    <section className="B2b-buyer-order-sec ">
      <div className="main-div  col-lg-12  col-md-12 col-12 mt-2" >
        <div className="first-div">
          <div className="num me-2">
            <input
              type="text"
              className="form-control"
              id=""
              placeholder="Product Name"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
            />
          </div>
          <div className="num me-2">
            <input
              type="text"
              className="form-control"
              id=""
              placeholder="Invoive"
              value={searchInvoice}
              onChange={(e) => {
                setSearchInvoice(e.target.value);
              }}
            />
          </div>
          <div className="num me-2">
            <input
              type="number"
              className="form-control"
              id=""
              placeholder="Amount"
              value={searchAmount}
              onChange={(e) => {
                setSearchAmount(e.target.value);
              }}
            />
          </div>
          <div className="num me-2">
            <input
              max={new Date().toISOString().split("T")[0]}
              type="date"
              className="form-control"
              id=""
              placeholder="Select Date"
              value={searchDate}
              onChange={(e) => {
                setSearchDate(e.target.value);
              }}
            />
          </div>
          </div> 

          <div className="second-div">   
          <div className="select-div">
            <Select
              value={searchStatus}
              onChange={(selectedOption) => {
                setSearchStatus(selectedOption);
              }}
              options={orderStatus}
              placeholder="Order Status"
              isSearchable
            />
          </div>
          <div className="select-div">
            <Select
              value={searchType}
              onChange={(selectedOption) => {
                setSearchType(selectedOption);
              }}
              options={productTypes}
              placeholder="Tag"
              isSearchable
            />
          </div>


          {/* <div className="Search me-2">
              <button
                type="button"
                onClick={getOrders}
                className="btn btn-search"
              >
                <img src={search1} className="search" alt="" />
              </button>
            </div> */}
          <div className="Search-1">
            <button
              type="button"
              onClick={() => {
                setSearchName("");
                setSearchStatus("");
                setSearchInvoice("");
                setSearchAmount("");
                setSearchDate("");
                setSearchType("");
                setResetButten(!resetButten);
              }}
              className="btn btn-reset"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="main-table-div  table-responsive">

        <table className="table table-bordered mt-2">
          <thead>
            <tr>
              <th>Image</th>
              <th>Invoice No.</th>
              <th>Order Id</th>
              {/* <th>Seller Id</th> */}
              <th>Product information</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          {orders?.map((order, index) => (
            <tbody className="bd-right" key={index}>
              <tr>
                <td>
                  <img
                    className="proddimggg"
                    src={
                      IMG_URL +
                      (order?.s_product_variant_attribute_detail?.thumbnail ||
                        order?.s_p_v_a_one_d_a_d_a_d?.s_p_v_a_o_d_a_d_a_d_o_d
                          ?.thumbnail ||
                        order?.s_p_v_a_one_d_a_d?.s_p_v_a_o_d_a_d_a_d_o_d
                          ?.thumbnail ||
                        "")
                    }
                  />
                </td>
                <td>
                  <p>{order?.invoice_number}</p>

                </td>
                <td>
                  <p>{order?.id}</p>
                  <p>
                    Order Date:{" "}
                    {order?.createdAt
                      ? formatDate(order?.createdAt)
                      : "Date not available"}
                  </p>
                </td>
                {/* <td>
                  <p>{order?.seller_id}</p>
                </td> */}

                <td>
                  <div className="product-info-div">
                    <div>
                      <h6>{order?.s_product?.product_title}</h6>
                      <p className="grey-text">
                        Quantity: {order?.quantity} pieces
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="price-text">₹ {order?.total}</p>
                </td>
                <td>
                  <p>{order?.btb_order_status?.name}</p>
                </td>
                <td className="">
                  {IDS?.btb_order_status?.cancelled !==
                    order?.order_status_id &&
                    IDS?.btb_order_status?.time_out !== order?.order_status_id ? (
                    <div>
                      <button
                        className="common-btn"
                        onClick={() => setModalShow(order?.id)}
                      >
                        Cancel Order
                      </button>
                    </div>
                  ) : IDS?.btb_order_status?.time_out ==
                    order?.order_status_id ? (
                    <>
                      <p>
                        We apologize for the time is out. The refund bill has
                        been processed and sent.
                        {/* Date:{" "}
                        {order?.updatedAt
                          ? formatDate(order?.updatedAt)
                          : "Date not available"} */}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Cancel Date:{" "}
                        {order?.updatedAt
                          ? formatDate(order?.updatedAt)
                          : "Date not available"}
                      </p>
                    </>
                  )}

                  <Link to={`/b2bbuyer/b2b-productMain/${order?.product_id}`}>
                    <p>
                      Buy Again
                    </p>
                  </Link>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      <CancelOrderModal
        getOrders={getOrders}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </section >
  );
};

export default OrderCard;
