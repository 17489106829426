import React, { useState, useContext, useEffect, isValidElement } from "react";
import "../SellerLogin.css";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { RegxExpression } from "../../../../utils/common";
import {
  userForgotPassPost,
  resetPassWordPost,
  userCheck,
} from "../../../../utils/apis/myWebsite/mywebsite";
import EmailOtpModal from "../../../CommonForAll/Email_otp_modal/EmailOtpModal";
import PasswordChecklist from "react-password-checklist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, fas } from "@fortawesome/free-solid-svg-icons";
import {
  sendMobileOTP,
  resendMobileOTP,
  sendEmailOTP,
  verifyMobileOTP,
  verifyEmailOTP,
} from "../../../../utils/apis/common/Common";

import SuccessModal from "../../../CommonForAll/SuccessFullModal/SuccessModal";

const SellerForgetPass = () => {
  const {
    control,
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm();

  const navigate = useNavigate();
  const [emailerror, setemailErrors] = useState("");

  const [seconds, setSeconds] = useState(0);
  const [otpErr, setOtpErr] = useState("");
  const [modalShow2, setModalShow2] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [iconOne, setIconOne] = useState(faEye);
  const [iconTwo, setIconTwo] = useState(faEye);
  const [matcherror, setmatchErrors] = useState("");

  const [successModal, setSuccessModal] = useState({
    message: "",
    show: false,
  });

  const openSuccessModal = (text) => {
    setSuccessModal({ message: text, show: true });
    setTimeout(() => {
      setSuccessModal({ message: "", show: false });
      navigate("/seller/sellerlogin");
    }, 3000);
  };

  const openOtpSuccessModal = (text) => {
    setSuccessModal({ message: text, show: true });
    setTimeout(() => {
      setSuccessModal({ message: "", show: false });
    }, 1000);
  };

  const sendOtp = async () => {
    const email = getValues("email");

    // Define the email validation pattern
    const emailPattern =
      /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    // Check if the email is provided
    if (email && email !== "") {
      // Validate the email format
      if (!emailPattern.test(email)) {
        setError("email", {
          type: "manual",
          message: "Email Id Invalid",
        });
        return;
      } else {
        clearErrors("email");
      }

      const data = {
        email: email,
      };

      const exist = await userCheck(data);
      if (exist?.data) {
        clearErrors();
        const res = await userForgotPassPost(data);
        if (res?.success) {
          setModalShow2(true);
          setSeconds(90);
        } else {
          setemailErrors(res?.message);
        }
      } else {
        setError("email", {
          type: "manual",
          message: "No user found with the given Email",
        });
        return;
      }
    } else {
      setError("email", {
        type: "manual",
        message: "Email Required",
      });
      return;
    }
  };

  const verifyOTP = async (type, otp) => {
    const res = await verifyEmailOTP(getValues("email"), otp);
    if (res?.success) {
      setEmailVerify(true);
      setModalShow2(false);
      openOtpSuccessModal("Email Verified");
      // openSuccessModal("Email verified!");
      // clearErrors();
      setSeconds(0);
    } else {
      setOtpErr(res?.message);
    }
  };

  const onSubmit = async (data) => {
    const res = await resetPassWordPost(data);
    if (res?.success) {
      reset();
      openSuccessModal("Password Changed Successfully");
    } else {
      setOtpErr(res?.message);
    }
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };

  return (
    <>
      <section className="SellerLogin">
        <div className="container">
          <div className="loginHead">
            <Link to={"/seller/seller_home"}>
              <p>
                <FontAwesomeIcon
                  icon="fa-solid fa-less-than"
                  className="lessThanSymbol"
                />
                Go to Home
              </p>
            </Link>
            <h3>Seller Login</h3>
          </div>

          <div className="col-lg-6 mx-auto">
            <div className="personal-details-form mb-0 ">
              {/* form-holder */}

              <div className="details-form-holder">
                <div className="form-container">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Email ID</Form.Label>
                      <Form.Control
                        disabled={emailVerify}
                        type="text"
                        name="Email"
                        placeholder="Enter Your Email Id"
                        {...register("email", {
                          required: "Email Id required",
                          pattern: {
                            value:
                              /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                            message: "Email Id Invalid",
                          },
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.email,
                          "is-valid": emailVerify,
                        })}
                      />
                      {emailVerify && (
                        <div
                          style={{
                            position: "absolute",
                            top: "70%",
                            right: "20px",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <span style={{ color: "green", fontSize: "1.2em" }}>
                            ✔
                          </span>
                        </div>
                      )}
                      {errors.email && (
                        <span className="text-danger">
                          {errors.email.message}
                        </span>
                      )}
                      {emailerror && (
                        <span className="text text-danger">{emailerror}</span>
                      )}
                    </Form.Group>

                    {emailVerify ? (
                      <>
                        <Form.Group className="mb-3" controlId="password">
                          <Form.Label>Enter New Password*</Form.Label>
                          <div className="StepsixPassword">
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter Password*"
                              className={classNames("", {
                                "is-invalid": errors?.password,
                              })}
                              name="password"
                              {...register("password", {
                                required: "Password is required",
                                minLength: {
                                  value: 8,
                                  message:
                                    "Password must be at least 8 characters long",
                                },
                                pattern: {
                                  value:
                                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                  message:
                                    "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                },
                              })}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <PasswordChecklist
                              rules={[
                                "minLength",
                                "specialChar",
                                "number",
                                "capital",
                                "match",
                              ]}
                              minLength={8}
                              value={password}
                              valueAgain={passwordAgain}
                              onChange={(isValid) => {}}
                            />
                            <FontAwesomeIcon
                              icon={iconOne}
                              className="StepsixeditIcon"
                              onClick={() =>
                                togglePasswordVisibility("password")
                              }
                            />
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="reenterPassword"
                        >
                          <Form.Label>Re-Enter New Password*</Form.Label>
                          <div className="StepsixPassword">
                            <Form.Control
                              type={showPasswordOne ? "text" : "password"}
                              placeholder="Re-Enter Password*"
                              className={classNames("", {
                                "is-invalid": errors?.new_password,
                              })}
                              name="new_password"
                              {...register("new_password", {
                                required: "Re-Password is required",
                              })}
                              onChange={(e) => setPasswordAgain(e.target.value)}
                            />
                            <FontAwesomeIcon
                              icon={iconTwo}
                              className="StepsixeditIcon"
                              onClick={() =>
                                togglePasswordVisibility("reenterPassword")
                              }
                            />
                          </div>
                        </Form.Group>
                        {matcherror && (
                          <span className="text text-danger">{matcherror}</span>
                        )}

                        <div className="btn-holder text-center">
                          {/* <Link to='/mywebsites/check-email'><button className="submit_btn" type="submit"> */}
                          <button className="submit_btn" type="submit">
                            Reset Password
                          </button>
                          {/* </Link> */}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="btn-holder text-center">
                          {/* <Link to='/mywebsites/check-email'><button className="submit_btn" type="submit"> */}
                          <button
                            className="submit_btn"
                            type="button"
                            onClick={() => sendOtp()}
                          >
                            Send OTP
                          </button>
                          {/* </Link> */}
                        </div>
                        <EmailOtpModal
                          show={modalShow2}
                          seconds={seconds}
                          otpErr={otpErr}
                          setSeconds={setSeconds}
                          verifyOTP={verifyOTP}
                          // handleSendOtp={() => handleSendOtp("email", "resend")}
                          onHide={() => {
                            setModalShow2(false);
                            setOtpErr("");
                          }}
                        />

                        {/* <div className='text-center mt-3'>
                                            <p className='login-txtt'>Didn't receive the email?  <Link to='#'><span className='forget-txtt'>Click to resend</span></Link></p>
                                        </div> */}
                      </>
                    )}
                  </Form>
                </div>
                <SuccessModal
                  message={successModal?.message}
                  show={successModal?.show}
                  onHide={() => setSuccessModal({ message: "", show: false })}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SellerForgetPass;
