import React from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Home from "../Home/Home";
import Logistic_Transport_stepForm from "../Logistic_Transport_stepForm/Logistic_Transport_stepForm";

const Logistic_Routes_main = () => {
  const { pathname } = useLocation();
  console.log(pathname);

  return (
    <>
      {pathname.startsWith("/logistic") ? <Header /> : ""}
      <Routes>
        <Route path="/logistic-home" element={<Home />} />
        <Route
          path="/logistic-Transport-stepForm"
          element={<Logistic_Transport_stepForm />}
        />
      </Routes>

      {pathname.startsWith("/logistic") ? <Footer /> : ""}
    </>
  );
};

export default Logistic_Routes_main;
