import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import './ViewRequestedModal.css';
import InquiryModal from '../AllModal/inquiry-Modal/InquiryModal';

const ViewRequestedModal = (props) => {
    const [modalInquiry, setModalInquiry] = React.useState(false);
    
    return (
        <div>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='view-request'
            >
                <Modal.Body>
                    <p className='first-title-view-request'> No new quotations will be accepted for this RFQ once it has been canceled</p>
                    <p className='second-title'>Please select a reason for close</p>
                    <Form>
                        <div className="mb-3">
                            <Form.Check
                                type='radio'
                                id={`default-satisfactory-quotations`}
                                label={`Have received the satisfactory quotations, and don't need more`}
                                className='radio-btn-class'
                                name='name'
                            />
                            <Form.Check
                                type='radio'
                                id={`default-unsuitable-quotations`}
                                label={`None of the quotations were suitable, and don't need more`}
                                className='radio-btn-class'
                                name='name'
                            />
                            <Form.Check
                                type='radio'
                                id={`default-sourcing-plans-changed`}
                                label={`My sourcing plans have changed, don't need more quotations`}
                                className='radio-btn-class'
                                name='name'
                            />
                            <Form.Check
                                type='radio'
                                id={`default-other-reason`}
                                label={`Other`}
                                className='radio-btn-class'
                                name='name'
                            />
                        </div>
                    </Form>
                    <div>
                        <button className='submit-btn' onClick={() => { setModalInquiry(true); props.onHide(); }}>Submit</button>
                        <button className='cancel-btn ms-3' onClick={props.onHide}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>

            <InquiryModal
                message="Your Inquiry Close Successfully"
                show={modalInquiry}
                onHide={() => setModalInquiry(false)}
            />
        </div>
    );
}

export default ViewRequestedModal;