import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import Cookies from "js-cookie";
import { Context } from "../../../../utils/context";
import { getWorkType } from "../../../../utils/apis/master/Master";
import { getSectorCategory } from "../../../../utils/apis/seller/Seller";

library.add(fas);

function Steptwo(props) {
  const { getData, postData, IMG_URL } = useContext(Context);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const handleSelectChange = (selectedOption) => {
    setSelectedOptions([...selectedOptions, selectedOption]);
  };

  const handleCloseButtonClick = (optionValue) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.value !== optionValue
    );
    setSelectedOptions(updatedOptions);
  };

  const handleSuggestedSkillClick = (label) => {
    const newOption = { value: label, label: label };
    setSelectedOptions([...selectedOptions, newOption]);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const options = [
    { value: " XD", label: " XD" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    var selectedLabel = document.getElementById(
      "s_working_type_id" + data.s_working_type_id
    );

    props.setWorkTypeName(selectedLabel.getAttribute("for"));
    props.setWorkType(data.s_working_type_id);
    props.nextStep();
  };

  const [workTypes, setWorkTypes] = useState([]);
  const [workTypesSelected, setWorkTypesSelected] = useState("");
  const getWorkTypeMaster = async () => {
    const res = await getWorkType();
    if (res?.success) {
      setWorkTypes(res.data);
    }
  };

  const getUserCategory = async () => {
    const res = await getSectorCategory(
      props.userDetails?.s_personal_details_id
    );

    if (res?.success) {
      setWorkTypesSelected(res.data.s_working_type_id);
      reset(res.data);
      props.setWorkType(res?.data?.s_working_type_id);
    }
  };
  useEffect(() => {
    getUserCategory();
  }, [props.userDetails]);

  useEffect(() => {
    getWorkTypeMaster();
  }, []);
  return (
    <>
      <div className="personal-details-form userForm">
        {/* form-holder */}
        <div className="details-form-holder">
          <div className="row">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="radio-text-content">
                {workTypes?.map((value, index) => (
                  <div className="mb-3">
                   
                    <Form.Check
                      type="radio"
                      label={value?.name}
                      id={"s_working_type_id" + value?.id}
                      name="s_working_type_id"
                      for={value?.name}
                      value={value?.id}
                      {...register("s_working_type_id", {
                        required: "Please select a category",
                      })}
                      defaultChecked={props.workType == value?.id? true:false}
                    />
                  </div>
                ))}
              </div>
              {errors.s_working_type_id && (
                <span className="text-danger">
                  {errors.s_working_type_id.message}
                </span>
              )}
              <div className=" text-end apply_now_btn">
                <Link to={"/seller/seller-login/"}>
                  <Button className="back-btn me-3">Skip</Button>
                </Link>

                <Button onClick={props.prevStep} className="back-btn me-3">
                  Back
                </Button>

                {/* <div className="registerContinueMain"> */}
                  <Button type="submit" className="tabs-btn">
                    Register & Continue
                  </Button>

                {/* </div> */}
              </div>
            </Form>
          </div>

          <div className="col-md-12">
            {/* <div className="text-end apply_now_btn">
              <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p>

              <Link to={"/seller/seller-dashboard/"}>
                <Button className="back-btn me-3">Skip</Button>
              </Link>

              <Button onClick={props.prevStep} className="back-btn">
                Back
              </Button>

              <Button
                // onClick={props.nextStep} 
                handleSubmit={handleSubmit(onSubmit)}
                type="submit"
                className="tabs-btn">
                Register & Continue
              </Button>
            </div> */}

            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Steptwo;
