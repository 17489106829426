import React, { useRef, useState,useContext,useEffect } from "react";
import "./UpdateInformation.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import required modules
import { Grid, Pagination } from "swiper/modules";
import ButtonComponent from "../../CommonComponents/ButtonComponent/ButtonComponent";
import { UpdateInfoGet } from "../../../../utils/apis/myWebsite/mywebsite";

const UpdateInformation = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [show, setShow] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const imageSources = [
    {
      imagePath:
        process.env.PUBLIC_URL +
        "/assest/images/MyWebsite/HomeImages/UpdateInformation/laptop.png",
      date: "11 Apr, 2023",
      title:
        "Have you checked your Google Business Profile is Up-To-Date recently?",
    },
    {
      imagePath:
        process.env.PUBLIC_URL +
        "/assest/images/MyWebsite/HomeImages/UpdateInformation/laptop.png",
      date: "11 Apr, 2023",
      title:
        "Have you checked your Google Business Profile is Up-To-Date recently?",
    },
    {
      imagePath:
        process.env.PUBLIC_URL +
        "/assest/images/MyWebsite/HomeImages/UpdateInformation/group.png",
      date: "11 Apr, 2023",
      title: "Why WordPress Might Not Suit Your Small Business Website",
    },

    {
      imagePath:
        process.env.PUBLIC_URL +
        "/assest/images/MyWebsite/HomeImages/UpdateInformation/group.png",
      date: "11 Apr, 2023",
      title: "Why WordPress Might Not Suit Your Small Business Website",
    },
    {
      imagePath:
        process.env.PUBLIC_URL +
        "/assest/images/MyWebsite/HomeImages/UpdateInformation/laptop.png",
      date: "11 Apr, 2023",
      title: "Why WordPress Might Not Suit Your Small Business Website",
    },

    {
      imagePath:
        process.env.PUBLIC_URL +
        "/assest/images/MyWebsite/HomeImages/UpdateInformation/laptop.png",
      date: "11 Apr, 2023",
      title: "Why WordPress Might Not Suit Your Small Business Website",
    },
  ];

  const [UpdateInfoData, setUpdateInfoData] = useState();
  const getUpdateInfoData = async () => {

    const res = await UpdateInfoGet();
    if (res?.success) {
      setUpdateInfoData(res?.data);
    }
  }
  useEffect(() => {
    // getUpdateInfoData();
  }, [])
  
  return (
    <>
      <Container>
        <section className="UpdateInformation">
          <h4 className="maintenance-title">
            Stay in the Loop with Netpurti Design<br></br>Updates and
            Information
          </h4>
          <Row className="mt-5">
            <Col xxl={6}>
              <div className="">
                <div className="Book">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assest/images/MyWebsite/HomeImages/UpdateInformation/books.png"
                    }
                    className="books"
                  />
                  <div className="overlay"></div>
                  <div className="content">
                    <div className="d-flex">
                      <FontAwesomeIcon icon="fa-solid fa-calendar-days" />
                      <p className="maintenance-text">11 Apr, 2023</p>
                    </div>
                    <div className="content-card">
                      <h4 className="maintenance-title">
                        Collaborate like a Pro
                      </h4>
                      <p className="sub-title">
                        Top 10 Things Jon Web Design Needs to Create <br></br>
                        Your Business Website
                      </p>
                      <div className="Read-More">
                        <ButtonComponent variant="" className='read'>
                          Read More
                          <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                        </ButtonComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xxl={6}>
              <div className="Information-Slider">
                <Swiper
                  breakpoints={{
                    575: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },

                    992: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1199: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1400: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                  }}
                  // slidesPerView={2}
                  grid={{
                    rows: 2,
                  }}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Grid, Pagination]}
                  className="mySwiper"
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                >
                  {imageSources.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="Slider-update">
                        <div className="slider-card-image">
                          <img src={item.imagePath} className="card-image" />
                        </div>

                        <div className="overlay"></div>
                        <div className="content">
                          <div className="d-flex">
                            <FontAwesomeIcon icon={["fas", "calendar-days"]} />
                            <p className="maintenance-text">{item.date}</p>
                          </div>
                          <div className="content-card">
                            <h4 className="sub-title-medium">{item.title}</h4>
                            <div className="Read-More">
                              <ButtonComponent className="read">

                                Read More
                                <FontAwesomeIcon
                                  icon={["fas", "arrow-right"]}
                                />
                              </ButtonComponent>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </Col>
          </Row>
          <div className="silder-btn">
            <div
              className="back-btn"
              onClick={() => handleMainSliderClick("next")}
            ></div>
            <div
              className="next-btn"
              onClick={() => handleMainSliderClick("prev")}
            ></div>
          </div>
        </section>
      </Container>
    </>
  );
};

export default UpdateInformation;
