import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import Form from "react-bootstrap/Form";
import "./FAQForm.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Button } from "react-bootstrap";
function FAQForm() {
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const { postData, getData, Select2Data } = useContext(Context);
  const [SelectTopic, setSelectTopic] = useState([]);
  const {

    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  console.log(errors);

  const onSubmit = async (data) => {
    console.log(data, "form");

    const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("last_name", data?.last_name);
      finalData.append("message", data?.message);
      finalData.append("phone", data?.phone);
      finalData.append("email", data?.email);
      finalData.append("select_topic_id", data?.select_topic_id?.value);
      console.log("finalData", finalData);
    try {
      const response = await postData(
        `/seller/faq/s-faq-feedback`,
        finalData
      );

      console.log("response :- ", response);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllFAQ = async ( ) => {
    const response = await getData(`/without-login/seller/masters/allselecttopic`);

    if (response?.success) {
      setSelectTopic(await Select2Data(response?.data, "select_topic_id"));
    }
  };
console.log(SelectTopic);
  useEffect(() => {
    GetAllFAQ();
  }, []);
  
  return (
    <>
      <section className="FaqForm">
        <div className="container-fluid">
          <div className="faqassist">
            <div className="text-center">
              <h2 className="delight-text">We are delighted to assist you</h2>
              <p>If you still have lingering questions or unresolved queries, please feel free to share your thoughts below. Your feedback is valuable,<br /> and we are committed to enhancing the website by providing additional information to address your concerns</p>
            </div>
            <div className="col-md-8 justify-content-center mx-auto">
              <Form onSubmit={handleSubmit(onSubmit)} >
                <div className="row faqFormMain ">
                  <div className="col-lg-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control

                        placeholder="first name"
                        className={classNames("", {
                          "is-invalid": errors?.name,
                        })}
                        type="text"
                        name="name"
                        {...register("name", {
                          required: "Name is required",
                        })}

                      />
                      
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control type="text" placeholder="Last Name"
                        className={classNames("", {
                          "is-invalid": errors?.last_name,
                        })}

                        name="last_name"
                        {...register("last_name", {
                          required: "last name is required",
                        })}
                      />
                      
                    </Form.Group>
                  </div>

                  <div className="col-lg-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter email"
                        className={classNames("", {
                          "is-invalid": errors?.email,
                        })}

                        name="email"
                        {...register("email", {
                          required: "email is required",
                          pattern: {
                            value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                            message: "Invalid E-Mail Address",
                          },
                        })}
                      />
                     
                    </Form.Group>
                  </div>

                  <div className="col-lg-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control type="phone" placeholder="Enter Phone"
                        inputMode="numeric"
                        className={classNames("", {
                          "is-invalid": errors?.phone,
                        })}

                        name="phone"
                        {...register("phone", {
                          required: "phone is required",
                          pattern: {
                            value: /^[6-9]\d{9}$/,
                            message: "Invalid Contact Number",
                          },
                        })}
                      />
                      
                    </Form.Group>
                  </div>

                  <div className="col-lg-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Select a Topic</Form.Label>
                    
                  
                    <Controller
                      name="select_topic_id" // name of the field
                      {...register("select_topic_id", {
                        required: "Select Topic",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.select_topic_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={SelectTopic}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            // GetAllFAQ(selectedOption.value);
                            setValue("select_topic_id", selectedOption);
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </div>

              
                  <div className="col-lg-12">
                    <Form.Label>Messages</Form.Label>
                    <FloatingLabel controlId="floatingPassword" label="Enter Message">
                      <Form.Control type="text" placeholder="Enter Message"
                        className={classNames("", {
                          "is-invalid": errors?.message,
                        })}

                        name="message"
                        {...register("message", {
                          required: "message is required",
                        })}
                      />
                      
                    </FloatingLabel>
                  </div>
                  {/* <button className="send-query-btn mt-5" type="submit" >Send Query</button> */}
                  <Button
                        className="send-query-btn mt-5"
                        // onClick={props.nextStep}
                        type="submit"
                      >
                        Send Query
                      </Button>
                </div>
              </Form>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default FAQForm;
