import React, { useState } from 'react'
import { Col, Offcanvas, Row } from 'react-bootstrap'
import './MyRfq.css';
import ViewRequestedModal from '../../common-components/ViewRequestedModal/ViewRequestedModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ViewRequestedQuote from '../RfqOffcanvas/ViewRequestedQuote';
import ViewQuotes from '../RfqOffcanvas/ViewQuotes';

const rfqData = [
  {
    date: "2024-04-02",
    id: "1842770994",
    title: "Hot Sale Living Room Single Sofa Chair Armchair High Back Lamb Cashmere Microfiber Fabric Manual Recliner Massage Chair",
    quotesReceived: 1,
    quotationStatus: "Approved",
    quantity: "2 Pieces"
  },
  {
    date: "2024-04-02",
    id: "1842770994",
    title: "Poliformfurniture Modern Luxury Design Velvet Boucle Bedroom Restaurant Armchair Full Lounge Accent Chair Living Room",
    quotesReceived: 2,
    quotationStatus: "Pending",
    quantity: "2 Pieces"
  }
];

const MyRfq = () => {
  const [modalShow, setModalShow] = React.useState(false);

  // Offcanvas view requested 
  const [showViewRequested, setShowViewRequested] = useState(false);
  const handleCloseViewRequested = () => setShowViewRequested(false);
  const handleShowViewRequested = () => setShowViewRequested(true);

  // Offcanvas view requested quote
  const [showViewRequestedQuote, setShowViewRequestedQuote] = useState(false);
  const handleCloseViewRequestedQuote = () => setShowViewRequestedQuote(false);
  const handleShowViewRequestedQuote = () => setShowViewRequestedQuote(true);

  return (
    <section className='my-rfq'>
      {rfqData.map((rfq, index) => (
        <div className='card-myrfq mt-3' key={index}>
          <Row>
            <Col xxl={6}>
              <h2 className='card-myrfq-title'>RFQ</h2>
              <Row>
                <Col md={2}>
                  <div className='item-date'>{rfq.date}</div>
                </Col>
                <Col md={5}>
                  <div className='item-id'>ID: {rfq.id}</div>
                </Col>
              </Row>
              <p className='rfq-title mt-2'>{rfq.title}</p>
            </Col>
            <Col xxl={2}>
              <h2 className='card-myrfq-title'>Quotation from</h2>
              <p className='card-text'>{rfq.quotesReceived} Quotes Received</p>
            </Col>
            <Col xxl={2}>
              <h2 className='card-myrfq-title'>Quotation Status</h2>
              <p className={rfq.quotationStatus === "Pending" ? "status-pending" : "status-approved"}>{rfq.quotationStatus}</p>
            </Col>
            <Col xxl={2}>
              <h2 className='card-myrfq-title'>Action</h2>
              <p className='card-text' onClick={handleShowViewRequested}><FontAwesomeIcon icon="fa-solid fa-eye" className='eye-icon' /> View Quotes</p>
              <p className='card-text' onClick={handleShowViewRequestedQuote}><FontAwesomeIcon icon="fa-solid fa-eye" className='eye-icon' /> View this RFQ</p>
              <p className='card-text' onClick={() => setModalShow(true)}><FontAwesomeIcon icon="fa-solid fa-eye" className='eye-icon' /> Close RFQ</p>

              <ViewRequestedModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </Col>
            <Col>
              <p className='pieces'>{rfq.quantity}</p>
            </Col>
          </Row>
        </div>
      ))}

      {/* Offcanvas View Requested */}
      <Offcanvas show={showViewRequested} onHide={handleCloseViewRequested} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ViewQuotes />
        </Offcanvas.Body>
      </Offcanvas>

      {/* Offcanvas View Requested Quote */}
      <Offcanvas show={showViewRequestedQuote} onHide={handleCloseViewRequestedQuote} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ViewRequestedQuote />
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  )
}

export default MyRfq;
