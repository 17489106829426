import React, { useEffect, useState, useContext } from "react"
import './EditPersonalDetailsModal.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangeModal from './ChangeModal';
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import InquiryModal from "../../../common-components/AllModal/inquiry-Modal/InquiryModal";
import { gstVerify } from "../../../../../utils/apis/third_party/third_party";
import {
    btobGstDetailsPost,
    btobGstDetailsGet,
    btbPercentageSend,
} from "../../../../../utils/apis/btb/B2b.js";
import classNames from "classnames";
const EditFirmDetailsModal = (props) => {
    const [modalChange, setModalChange] = useState(false);
    const [modalInquiry, setModalInquiry] = useState(false);
    const [mobileSeconds, setMobileSeconds] = useState(0);
    const [emaiSseconds, setEmailSeconds] = useState(0);

    const [mobileVerify, setMobileVerify] = useState(true);
    const [emailVerify, setEmailVerify] = useState(true);
    const [termStatus, setTermStatus] = useState(false);
    const [otpErr, setOtpErr] = useState("");
    const [modalShow2, setModalShow2] = useState(false);
    const [modalShow3, setModalShow3] = useState(false);

    const [currentMobile, setCurrentMobile] = useState("");
    const [currentEmail, setCurrentEmail] = useState("");

    const { getData, postData, IMG_URL, shimmerLoader, setShimmerLoader, Id } =
        useContext(Context);

    const submitModal = () => {
        setModalInquiry(true);
        setTimeout(() => {
            props.onHide();
            reset();
            setModalInquiry(false);
        }, 3000);
    };


    const options = { multi: true };
    const {
        control,
        register,
        handleSubmit,
        getValues,
        clearErrors,
        setError,
        reset,
        formState: { errors },
    } = useForm();
    // console.log(errors);

    // console.log(userID,"userID?.btb_personal_details_iduserDetails?.btb_personal_details_id");
    const onSubmit = async (d) => {
        if (verifyStatus) {
            const data = new FormData();
            if (firmId != "") {
                data.append("id", d?.id);
            }
            data.append("gst_image", d.gst_image[0]);
            data.append("gst_no", d.gst_no);
            data.append("btb_personal_details_id", Id);
            // data.append("name", firmName);
            // data.append("percentage", btbPercentageSend.stepFour);

            const res = await btobGstDetailsPost(data);
            if (res?.success) {
                console.log(res, "success");
                getUserFirmDetails();
                submitModal()
                // handleNext();
            }
        } else {
            if (!verifyStatus) {
                setError("gst_no", {
                    type: "manual",
                    message: "Verify your GST No.",
                });
            }
        }
    };

    const [selectedGSTImages, setSelectedGSTImage] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedGSTImage(file);
        clearErrors("gst_image");
        const previewImg = document.querySelector(".previewImg");
        if (previewImg) {
            previewImg.src = "";
        }
    };

    const handleRemoveFile = () => {
        setSelectedGSTImage(null);
        const fileInput = document.getElementById("fileGSTInput");
        fileInput.value = "";
    };

    const [firmId, setfirmId] = useState("");
    const [storeGst, setStoreGst] = useState(null);

    const getUserFirmDetails = async () => {
        const res = await btobGstDetailsGet(Id);
        if (res?.success) {
            setVerifyStatus(true);
            setStoreGst(IMG_URL + res.data.gst_image);
            setfirmId(res.data.id);
            reset(res.data);
        }
    };

    const [verifyStatus, setVerifyStatus] = useState(false);

    const verifyGst = async () => {
        setShimmerLoader(true);
        var data = {
            btb_personal_details_id: Id,
            gst_no: getValues("gst_no"),
        };
        const res = await gstVerify(data);
        if (res?.success) {
            console.log(res);
            // setFirmname(res?.data?.data?.business_name);
            clearErrors();
            setVerifyStatus(true);
            setShimmerLoader(false);
        } else {
            setVerifyStatus(false);
            setError("gst_no", {
                type: "manual",
                message: res.message.message,
            });
            setShimmerLoader(false);
        }
    };
    useEffect(() => {
        getUserFirmDetails();
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='edit-details-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Personal Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Form.Group as={Col} md={4} controlId="formGridName">
                                <Form.Label>GST No</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="gst_no"
                                    placeholder="Enter your GST number"
                                    {...register("gst_no", {
                                        // required: "GST Number is required",
                                        pattern: {
                                            value:
                                                /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
                                            message: "Invalid GST Number",
                                        },
                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.gst_no,
                                        "is-valid": verifyStatus,
                                    })}
                                    onChange={() => {
                                        setError("gst_no", "");
                                        setVerifyStatus(false);
                                    }}
                                />
                                {errors.gst_no && (
                                    <span className="text-danger">{errors.gst_no.message}</span>
                                )}
                                {!verifyStatus && (
                                    <button
                                        type="button"
                                        className="verrrr-bttt btn-info"
                                        onClick={verifyGst}
                                    >
                                        Verify
                                    </button>
                                )}
                            </Form.Group>



                            <Form.Group as={Col} md={4} controlId="formGridDesignation">
                                <Form.Label>Attachment</Form.Label>
                                <input
                                    type="file"
                                    id="fileGSTInput"
                                    accept="image/*"
                                    {...register("gst_image")}
                                    onChange={handleFileChange}
                                />
                            </Form.Group>
                            {selectedGSTImages && (
                                <div className="previewImgmain">
                                    <img style={{ width: "100px", height: "100px" }}
                                        src={URL.createObjectURL(selectedGSTImages)}
                                        alt={`Preview of ${selectedGSTImages.name}`}
                                        className="previewImg"
                                    />
                                    <div>
                                        <FontAwesomeIcon
                                            icon="fa-solid fa-circle-xmark"
                                            className="circlecrossMark"
                                            onClick={handleRemoveFile}
                                        />
                                    </div>
                                </div>
                            )}
                            {!selectedGSTImages && storeGst !== null && (
                                <div className="previewImgmain">
                                    <img style={{ width: "100px", height: "100px" }} src={storeGst} className="previewImg" />
                                </div>
                            )}
                        </Row>
                        <Modal.Footer>
                            <ButtonComponent onClick={props.onHide} className={'btn-cancle'}>Cancel</ButtonComponent>
                            <ButtonComponent onClick={() => handleSubmit(onSubmit(getValues()))} className={'btn-update'} > Update</ButtonComponent>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal>
            <ChangeModal
                show={modalChange}
                onHide={() => setModalChange(false)}
            />
            <InquiryModal
                message="Submitted successfully"
                show={modalInquiry}
                onHide={() => setModalInquiry(false)}
            />
        </>
    )
}

export default EditFirmDetailsModal