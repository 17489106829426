import React from 'react'
import './HelpCenterBanner.css'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const HelpCenterBanner = () => {
    return (
        <section className='help-center-tab-banner'>
            <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Help-Center/help-bg.png"} className="banner-img" alt="..." />
            <div className='banner-txttt'>
                <h2 className='hi-txt'>Hi,</h2>
                <h3 className='how-can-txtt'> How can we help?</h3>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /></InputGroup.Text>
                    <Form.Control
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                    />
                </InputGroup>
            </div>
        </section>
    )
}

export default HelpCenterBanner