import { React, useState, useRef, useEffect } from "react";
import "./B2bSellerDetail.css";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import Form from "react-bootstrap/Form";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import ButtonComponent from "../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import AddTocart from "../../common-components/offcanvas/AddTocart/AddTocart";
import Accordion from "react-bootstrap/Accordion";
import ContactSupplier from "../../common-components/offcanvas/ContactSupplier/ContactSupplier";
import BreadcrumComponent from "../../../CommonForAll/CommonComponents/ButtonComponent/BreadcrumComponent/BreadcrumComponent";
import Table from "react-bootstrap/Table";
import ProgressBar from "react-bootstrap/ProgressBar";
// import required modules

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Radio } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import NewChat from "../../common-components/offcanvas/NewChat/NewChat";
import { IDS, Product_Type, RequiredIs } from "../../../../utils/common";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Successfull_Lottie from "../../../CommonForAll/Successfull_Lottie/Successfull_Lottie";
import SuccessModal from "../../../CommonForAll/SuccessFullModal/SuccessModal";
import CustomisationModal from "../../common-components/AllModal/CustomisationModal/CustomisationModal";
import { Helmet } from "react-helmet";

const B2bSellerDetail = (props) => {
  const {
    data,
    images,
    setImages,
    IMG_URL,
    singleVariant,
    getValues,
    MultipleVariantTwo,
    MultipleVariantThree,
    onChangeVariant,
    setValue,
  } = props;

  const navigate = useNavigate("");

  //   ---------------------------------------------------------------------------------------------

  const [selectedImage, setSelectedImage] = useState(null);
  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [swiperInstancenew, setSwiperInstancenew] = useState(null);
  const [showcard, setShowcard] = useState(false);
  const handleCloseCard = () => setShowcard(false);
  const handleShowCard = () => setShowcard(true);

  const [show, setShow] = useState(false);
  const [showchat, setShowchat] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClosechat = () => setShowchat(false);
  const handleShowchat = () => setShowchat(true);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleSliderClick = (direction) => {
    if (swiperInstancenew) {
      if (direction === "prev") {
        swiperInstancenew.slidePrev();
      } else if (direction === "next") {
        swiperInstancenew.slideNext();
      }
    }
  };

  const handleClickSlide = (index) => {
    if (swiperRef.current !== null) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const [number, setNumber] = useState(1);
  const handleMinusClick = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  const handlePlusClick = () => {
    setNumber(number + 1);
  };
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/b2bbuyer/buyer-home" },
    { text: "Furniture", link: "#" },
    { text: "Armchair", link: "#" },
    { text: "Modern Microfiber Accent Lounge Chair", link: "#" },
  ]);

  //
  const [status, setStatus] = useState(false);
  const handleCopyUrl = () => {
    const url = window.location.href;

    navigator.clipboard
      .writeText(url)
      .then(() => {
        // setStatus("URL copied to clipboard!");
        setStatus(true);
        setTimeout(() => {
          setStatus(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
        // setStatus("Failed to copy URL");
        setStatus(false);
      });
  };

  const [showCustomisationModal, setshowCustomisationModal] = useState(false);
  return (
    <>


      <Helmet>
        <title>Product Description</title>
        <meta name="description" content="Product Description" />
        <meta name="fb:admins" content="" />
        <meta name="fb:app_id" content="" />
        <meta name="og:price:sale_amount" content={getValues("selling_price")} />
        <meta name="og:price:standard_amount" content={getValues("mrp")} />
        <meta name="product:availability" content={getValues("stock") > 0 ? "In Stock" : "Out of Stock"} />
        <meta name="product:brand" content={getValues("brand")} />
        <meta name="product:price:amount" content={getValues("selling_price")} />
        <meta name="product:price:currency" content={"INR"} />
        <meta name="product:item_group_id" content={getValues("product_id")} />

      </Helmet>


      {/* B2bSeller Section Started */}

      {/* <Successfull_Lottie /> */}
      <SuccessModal show={status} message="URL copied to clipboard!" />
      <section className="B2b-Seller-Detail">
        <div className="Background-image">
          <Container>
            <div>{/* <BreadcrumComponent items={breadcrumbItems} /> */}</div>
            <Row className="product-content">
              <Col xxl={5} xl={5} lg={6}>
                {/* Swiper for products started */}

                <div className="main">
                  <div className="sidebar-swiper">
                    <Swiper
                      ref={swiperRef}
                      direction={"vertical"}
                      // slidesPerView={"auto"} Autoplay
                      modules={[Navigation]}
                      breakpoints={{
                        0: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        380: {
                          slidesPerView: 3,
                          spaceBetween: 25,
                        },
                        575: {
                          slidesPerView: 4,
                          spaceBetween: 30,
                        },
                        768: {
                          slidesPerView: 4,
                          spaceBetween: 40,
                        },
                        992: {
                          slidesPerView: 4,
                          spaceBetween: 50,
                        },
                        1200: {
                          slidesPerView: 3,
                          spaceBetween: 60,
                        },
                        1440: {
                          slidesPerView: 3,
                          spaceBetween: 70,
                        },
                        2000: {
                          slidesPerView: 3,
                          spaceBetween: 80,
                        },
                      }}
                      navigation
                      pagination={{ clickable: true }}
                      className="mySwiper"
                      onSwiper={(swiper) => setSwiperInstance(swiper)}
                    >
                      {images?.map((item, index) => (
                        <SwiperSlide key={index}>
                          <div
                            className="gallery-imgs"
                            onClick={() => handleClickSlide(index)}
                          >
                            <img
                              src={IMG_URL + item.image}
                              className="inner-img"
                              alt="Sofa"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>

                    {/* <div className="silder-btn">
                      <div
                        className="back-btn"
                        onClick={() => handleMainSliderClick("prev")}
                      ></div>
                      <div
                        className="next-btn"
                        onClick={() => handleMainSliderClick("next")}
                      ></div>
                    </div> */}
                  </div>

                  {/* this slider for mobile view started*/}
                  <div className="sidebar-swiper-mobile-view">
                    <Swiper
                      // slidesPerView={"auto"} Autoplay
                      modules={[Navigation]}
                      breakpoints={{
                        0: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        320: {
                          slidesPerView: 2,
                          spaceBetween: 15,
                        },
                        576: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 40,
                        },
                        1200: {
                          slidesPerView: 4,
                          spaceBetween: 50,
                        },
                        1440: {
                          slidesPerView: 4,
                          spaceBetween: 60,
                        },
                        1920: {
                          slidesPerView: 4,
                          spaceBetween: 70,
                        },
                      }}
                      navigation
                      pagination={{ clickable: true }}
                      className="mySwiper"
                      onSwiper={(swiper) => setSwiperInstance(swiper)}
                    >
                      {images?.map((item, index) => (
                        <SwiperSlide key={index}>
                          <div className="gallery-imgs">
                            <img
                              src={IMG_URL + item.image}
                              className="inner-img"
                              alt="Gallery Image"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>

                    <div className="silder-btn">
                      <div
                        className="back-btn"
                        onClick={() => handleMainSliderClick("prev")}
                      ></div>
                      <div
                        className="next-btn"
                        onClick={() => handleMainSliderClick("next")}
                      ></div>
                    </div>
                  </div>
                  {/* this slider for mobile view End*/}
                  <div className="background-product-detail">
                    <div className="product-detail">
                      <Swiper
                        navigation={true}
                        // autoplay={{
                        //   delay: 2000,
                        //   disableOnInteraction: false,
                        // }} Autoplay
                        modules={[Navigation]}
                        className="mySwiper"
                        onSwiper={(swiper) => setSwiperInstancenew(swiper)}
                        ref={swiperRef}
                        slidesPerView={1}
                        spaceBetween={10}
                        breakpoints={{
                          0: {
                            slidesPerView: 1,
                          },
                          320: {
                            slidesPerView: 1,
                          },
                          485: {
                            slidesPerView: 1,
                          },
                          575: {
                            slidesPerView: 1,
                          },

                          768: {
                            slidesPerView: 1,
                          },
                          992: {
                            slidesPerView: 1,
                          },
                          1024: {
                            slidesPerView: 1,
                          },
                          1200: {
                            slidesPerView: 1,
                          },
                          1440: {
                            slidesPerView: 1,
                          },
                          2000: {
                            slidesPerView: 1,
                          },
                        }}
                      >
                        {images?.map((item, index) => (
                          <SwiperSlide key={index}>
                            <img
                              className="product-img"
                              src={IMG_URL + item.image}
                              alt="Selected"
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      {/* <div className="silder-btn">
                        <div
                          className="back-btn"
                          onClick={() => handleSliderClick("prev")}
                        >
                          <FontAwesomeIcon icon={faCaretLeft} />
                        </div>
                        <div
                          className="next-btn"
                          onClick={() => handleSliderClick("next")}
                        >
                          <FontAwesomeIcon icon={faCaretRight} />
                        </div>
                      </div> */}

                      {/* <div className="silder-btn">
                        <div
                          className="back-btn"
                          onClick={() => handleSliderClick("prev")}
                        ></div>
                        <div
                          className="next-btn"
                          onClick={() => handleSliderClick("next")}
                        ></div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Col>

              <Col xxl={4} xl={4} lg={6}>
                <div className="product-information">
                  <h1 className="heading">
                    {getValues("product_title")}
                    {/* Armchair New Arrival Promotion Fabric Material Adjustable
                    Leisure Single */}
                    {/* <br></br>
                    Power Electric Relax */}
                  </h1>
                  <p className="product-id">SKU ID : {getValues("sku_id")}</p>
                  <div className="d-flex">
                    <p className="quantity">
                      Minimum order quantity:{" "}
                      {getValues("minimum_order_quantity")} pieces
                    </p>
                    <div className="border-between"></div>
                    <div className="customize">
                      {Product_Type?.map((val, index) => {
                        {
                          /* && val.name !== "General" */
                        }
                        if (getValues("customization") === val?.name) {
                          return (
                            <Button
                              variant="customize-btn"
                              style={{
                                backgroundColor: `${getValues(
                                  "customization_color"
                                )} !important`,
                              }}
                              className="me-3"
                              onClick={() => setshowCustomisationModal(true)}
                            >
                              {val.rename}
                            </Button>
                          );
                        }
                        return null;
                      })}

                      {/* <Button
                        variant="customize-btn"
                        style={{
                          backgroundColor: `${getValues(
                            "customization_color"
                          )} !important`,
                        }}
                        className="me-3"
                        onClick={() => setshowCustomisationModal(true)}
                      >
                        {getValues("customization")}
                      </Button> */}

                      <FontAwesomeIcon
                        icon="fa-solid fa-share-nodes"
                        className="me-2"
                        onClick={handleCopyUrl}
                      />
                      <FontAwesomeIcon
                        icon="fa-solid fa-print"
                        onClick={handlePrint}
                      />
                    </div>
                  </div>
                  <span>{status}</span>
                  {/* {getValues("working_type_id")} */}
                  {/* {getValues("customization")} */}
                  {getValues("working_type_id") ===
                    IDS.working_type.manufacturer ? (
                    <div className="manufacture-bar">
                      <p>Monthly MFG Capacity </p>
                      <div className="row">
                        <div className="col-lg-8 col-md-5 col-8 mt-2">
                          <div className="progress-container">
                            <ProgressBar>
                              <ProgressBar
                                striped
                                variant="danger"
                                now={
                                  (data?.s_product_information
                                    ?.supply_capacity_a_month /
                                    data?.s_product_information
                                      ?.manufacturing_capacity) *
                                  100
                                }
                                key={1}
                              />
                              <ProgressBar
                                striped
                                variant="warning"
                                now={100}
                                key={2}
                              />
                              <ProgressBar
                                variant="success"
                                now={
                                  ((data?.s_product_information
                                    ?.manufacturing_capacity -
                                    data?.s_product_information
                                      ?.supply_capacity_a_month) /
                                    data?.s_product_information
                                      ?.manufacturing_capacity) *
                                  100
                                }
                                key={3}
                              />
                            </ProgressBar>

                            <div className="progress-numbers d-flex justify-content-start mt-2">
                              <span>
                                {(
                                  (data?.s_product_information
                                    ?.supply_capacity_a_month /
                                    data?.s_product_information
                                      ?.manufacturing_capacity) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>

                              <span>
                                {(
                                  ((data?.s_product_information
                                    ?.manufacturing_capacity -
                                    data?.s_product_information
                                      ?.supply_capacity_a_month) /
                                    data?.s_product_information
                                      ?.manufacturing_capacity) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                              <span>100%</span>
                            </div>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="show-percentage">
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-exclamation"
                              className="exclamation-icon"
                            />
                            <div className="used-capacity">
                              <div className="d-flex">
                                <span className="red-circle  me-1"></span>
                                <p>
                                  Capacity Used:{" "}
                                  {(
                                    (data?.s_product_information
                                      ?.supply_capacity_a_month /
                                      data?.s_product_information
                                        ?.manufacturing_capacity) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </p>
                              </div>

                              <div className="d-flex">
                                <span className="green-circle me-1"></span>{" "}
                                <p>Total Capacity: {100}%</p>
                              </div>

                              <div className="d-flex">
                                <span className="yellow-circle me-1"></span>
                                <p>
                                  Remaining Capacity :{" "}
                                  {(
                                    ((data?.s_product_information
                                      ?.manufacturing_capacity -
                                      data?.s_product_information
                                        ?.supply_capacity_a_month) /
                                      data?.s_product_information
                                        ?.manufacturing_capacity) *
                                    100
                                  ).toFixed(2)}{" "}
                                  %
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* <div className="manufacture-bar">
                    <p>Monthly MFG Capacity </p>
                    <div className="row">
                      <div className="col-lg-8 col-md-5 col-8 mt-2">
                        

                        <div className="progress-container">
                          <ProgressBar>
                            <ProgressBar
                              striped
                              variant="danger"
                              now={
                                (data?.s_product_information
                                  ?.supply_capacity_a_month /
                                  data?.s_product_information
                                    ?.manufacturing_capacity) *
                                100
                              }
                              key={1}
                            />
                            <ProgressBar
                              striped
                              variant="warning"
                              now={100}
                              key={2}
                            />
                            <ProgressBar
                              variant="success"
                              now={
                                ((data?.s_product_information
                                  ?.manufacturing_capacity -
                                  data?.s_product_information
                                    ?.supply_capacity_a_month) /
                                  data?.s_product_information
                                    ?.manufacturing_capacity) *
                                100
                              }
                              key={3}
                            />
                          </ProgressBar>

                       
                          <div className="progress-numbers d-flex justify-content-start mt-2">
                            <span>
                              {(
                                (data?.s_product_information
                                  ?.supply_capacity_a_month /
                                  data?.s_product_information
                                    ?.manufacturing_capacity) *
                                100
                              ).toFixed(2)}
                              %
                            </span>

                            <span>
                              {(
                                ((data?.s_product_information
                                  ?.manufacturing_capacity -
                                  data?.s_product_information
                                    ?.supply_capacity_a_month) /
                                  data?.s_product_information
                                    ?.manufacturing_capacity) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                            <span>100%</span>
                          </div>
                        </div>

                        
                      </div>

                      <div className="col-4">
                        <div className="show-percentage">
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-exclamation"
                            className="exclamation-icon"
                          />
                          <div className="used-capacity">
                            <div className="d-flex">
                              <span className="red-circle  me-1"></span>
                              <p>
                                Capacity Used:{" "}
                                {(
                                  (data?.s_product_information
                                    ?.supply_capacity_a_month /
                                    data?.s_product_information
                                      ?.manufacturing_capacity) *
                                  100
                                ).toFixed(2)}
                                %
                              </p>
                            </div>

                            <div className="d-flex">
                              <span className="green-circle me-1"></span>{" "}
                              <p>Total Capacity: {100}%</p>
                            </div>

                            <div className="d-flex">
                              <span className="yellow-circle me-1"></span>
                              <p>
                                Remaining Capacity :{" "}
                                {(
                                  ((data?.s_product_information
                                    ?.manufacturing_capacity -
                                    data?.s_product_information
                                      ?.supply_capacity_a_month) /
                                    data?.s_product_information
                                      ?.manufacturing_capacity) *
                                  100
                                ).toFixed(2)}{" "}
                                %
                              </p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="main-price">
                    <p className="price">₹{getValues("selling_price")}</p>
                    {/* <ButtonComponent variant="primary" onClick={handleShowCard}>
                                            Select Variations
                                        </ButtonComponent> */}
                    <div className="mrp-div">
                      <span className="mrp-title">MRP : </span>
                      <span className="mrp">₹{getValues("mrp")}</span>
                    </div>
                    <div className="discount-div">
                      <span className="discount">
                        (
                        {(
                          ((getValues("mrp") - getValues("selling_price")) /
                            getValues("mrp")) *
                          100
                        ).toFixed(2)}{" "}
                        % off)
                      </span>
                    </div>
                  </div>
                  <AddTocart
                    handleClose={handleCloseCard}
                    placement="end"
                    name="end"
                    show={showcard}
                    getValues={getValues}
                    singleVariant={singleVariant}
                    MultipleVariantTwo={MultipleVariantTwo}
                    MultipleVariantThree={MultipleVariantThree}
                    IMG_URL={IMG_URL}
                    data={data}
                  />
                  {singleVariant && (
                    <>
                      <p className="product-id mt-2">Variations</p>
                      <p className="quantity">1. {getValues("variant")}</p>

                      <div className="standard-section">
                        {getValues("variant_details")?.map((item, index) => (
                          <Button
                            variant="standard"
                            className={
                              getValues("activeVariant") === item?.id
                                ? "selected-btn"
                                : "unselected-btn"
                            }
                            key={item?.id ?? index}
                            onClick={async () => {
                              await setValue("activeVariant", item?.id);
                              await onChangeVariant(item, "Single", "1");
                              await setImages(item?.s_p_v_a_d_images);
                            }}
                          >
                            {item?.s_sub_attribute?.name ?? "Unknown"}
                          </Button>
                        ))}
                      </div>
                    </>
                  )}
                  {MultipleVariantTwo && (
                    <>
                      <p className="product-id">Variations</p>
                      <p className="quantity">1. {getValues("variant")}</p>

                      <div className="standard-section">
                        {getValues("variant_details")?.map((item, index) => (
                          <Button
                            variant="standard"
                            className={
                              getValues("activeVariant") === item?.id
                                ? "selected-btn"
                                : "unselected-btn"
                            }
                            key={index}
                            onClick={async () => {
                              await setValue("activeVariant", item?.id);
                              await setValue(
                                "activeSubVariant",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]?.id
                              );
                              await setValue(
                                "variant_one",
                                item?.s_p_v_a_one_d_as?.[0]?.s_attribute
                                  ?.name ?? ""
                              );

                              await setValue(
                                "variant_one_details",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds ?? []
                              );

                              onChangeVariant(
                                getValues("variant_one_details")?.[0],
                                "Multiple",
                                "2"
                              );

                              await setImages(
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                              );
                            }}
                          >
                            {item?.s_sub_attribute?.name ?? "Unknown"}
                          </Button>
                        ))}
                      </div>

                      <p className="quantity">2. {getValues("variant_one")}</p>

                      <div className="standard-section">
                        {getValues("variant_one_details")?.map(
                          (item, index) => (
                            <Button
                              variant="standard"
                              className={
                                getValues("activeSubVariant") === item?.id
                                  ? "selected-btn"
                                  : "unselected-btn"
                              }
                              key={item?.id ?? index}
                              onClick={async () => {
                                await setValue("activeSubVariant", item?.id);
                                onChangeVariant(item, "Multiple", "2");
                                setImages(
                                  item?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                );
                              }}
                            >
                              {item?.s_sub_attribute?.name ?? "Unknown"}
                            </Button>
                          )
                        )}
                      </div>
                    </>
                  )}
                  {MultipleVariantThree && (
                    <>
                      <p className="product-id">Variations</p>
                      <p className="quantity">1. {getValues("variant")}</p>

                      <div className="standard-section">
                        {getValues("variant_details")?.map((item, index) => (
                          <Button
                            variant="standard"
                            className={
                              getValues("activeVariant") === item?.id
                                ? "selected-btn"
                                : "unselected-btn"
                            }
                            key={item?.id ?? index}
                            onClick={async () => {
                              await setValue("activeVariant", item?.id);

                              await setValue(
                                "activeSubVariant",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]?.id
                              );

                              await setValue(
                                "activeSubChildVariant",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
                              );

                              await setValue(
                                "variant_one",
                                item?.s_p_v_a_one_d_as?.[0]?.s_attribute
                                  ?.name ?? ""
                              );

                              await setValue(
                                "variant_one_details",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds ?? []
                              );

                              setValue(
                                "variant_two",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
                              );

                              setValue(
                                "variant_two_details",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_ds
                              );

                              setValue(
                                "sku_id",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                              );
                              setValue(
                                "mrp",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                  ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                              );

                              setValue(
                                "selling_price",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                  ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
                              );

                              setImages(
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                              );
                            }}
                          >
                            {item?.s_sub_attribute?.name ?? "Unknown"}
                          </Button>
                        ))}
                      </div>

                      <p className="quantity">2. {getValues("variant_one")}</p>

                      <div className="standard-section">
                        {getValues("variant_one_details")?.map(
                          (item, index) => (
                            <Button
                              variant="standard"
                              className={
                                getValues("activeSubVariant") === item?.id
                                  ? "selected-btn"
                                  : "unselected-btn"
                              }
                              key={item?.id ?? index}
                              onClick={async () => {
                                await setValue("activeSubVariant", item?.id);
                                await setValue(
                                  "activeSubChildVariant",
                                  item?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
                                );
                                setValue(
                                  "variant_two",
                                  item?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute
                                    ?.name
                                );

                                setValue(
                                  "variant_two_details",
                                  item?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds
                                );

                                setValue(
                                  "sku_id",
                                  item?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                                );
                                setValue(
                                  "mrp",
                                  item?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                    ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                                );

                                setValue(
                                  "selling_price",
                                  item?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                    ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
                                );

                                setImages(
                                  item?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                );
                              }}
                            >
                              {item?.s_sub_attribute?.name ?? "Unknown"}
                            </Button>
                          )
                        )}
                      </div>

                      <p className="quantity">3. {getValues("variant_two")}</p>

                      <div className="standard-section">
                        {getValues("variant_two_details")?.map(
                          (item, index) => (
                            <Button
                              variant="standard"
                              className={
                                getValues("activeSubChildVariant") === item?.id
                                  ? "selected-btn"
                                  : "unselected-btn"
                              }
                              key={item?.id ?? index}
                              onClick={async () => {
                                await setValue(
                                  "activeSubChildVariant",
                                  item?.id
                                );
                                onChangeVariant(item, "Multiple", "3");
                                setImages(
                                  item?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                );
                              }}
                            >
                              {item?.s_sub_attribute?.name ?? "Unknown"}
                            </Button>
                          )
                        )}
                      </div>
                    </>
                  )}
                  {/* <p className="quantity">2. Size</p> */}
                  <div className="standard-section">
                    {/* <Button variant="standard">Standard</Button> */}
                    <div className="buttons-section">
                      <ButtonComponent variant="chat" onClick={handleShowCard}>
                        Buy Now
                      </ButtonComponent>

                      <ButtonComponent
                        variant="primary"
                        onClick={handleShowCard}
                      >
                        Add to Cart
                      </ButtonComponent>

                      {RequiredIs?.inquery?.includes(
                        getValues("product_type_id")
                      ) && (
                          <>
                            {/* <Link to="/b2bbuyer/inquire-now">
                            <ButtonComponent variant="chat" className="chat">
                              Inquire Now
                            </ButtonComponent>
                          </Link> */}
                          </>
                        )}

                      {/* <div className="circle" onClick={handleShowchat}>
                        <FontAwesomeIcon icon="fa-solid fa-message" />
                      </div> */}
                      <NewChat
                        handleClose={handleClosechat}
                        placement="bottom"
                        name="bottom"
                        show={showchat}
                      />
                    </div>
                  </div>
                  <Row className="mt-4">
                    {data?.s_product_policy?.is_replaceable_day ? (
                      <Col md={3} sm={3}>
                        <div className="Warranty">
                          <img
                            // src={
                            //   process.env.PUBLIC_URL +
                            //   "/assest/images/B2Bbuyer/B2BSellerDetail/quality.png"
                            // }
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/B2Bbuyer/B2BSellerDetail/group.png"
                            }
                            alt=""
                            className="icon"
                          />
                        </div>
                        <p className="year">
                          {data?.s_product_policy?.is_replaceable_day} Days
                          Replacement
                        </p>
                      </Col>
                    ) : (
                      <></>
                    )}

                    {data?.s_product_policy?.is_returnable_day ? (
                      <Col md={3} sm={3}>
                        <div className="Warranty">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/B2Bbuyer/B2BSellerDetail/group.png"
                            }
                            alt=""
                            className="icon"
                          />
                        </div>
                        <p className="year">
                          {" "}
                          {data?.s_product_policy?.is_returnable_day} Days
                          Returnable
                        </p>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <div className="brand-holder">
                    <p>
                      Brand :<span>{getValues("s_brand")}</span>
                    </p>
                  </div>
                  <div
                    className="brand-holder"
                    onClick={() => {
                      navigate(
                        `/b2bbuyer/near-me/?seller_id=${getValues("seller_id")}`
                      );
                    }}
                  >
                    <p>
                      Seller Id :<span>{getValues("seller_id")}</span>
                    </p>
                  </div>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Product Highlights</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {data?.s_product_information?.s_product_highlights?.map(
                            (name, index) => (
                              <li key={index} className="product-text">
                                {name?.description}
                              </li>
                            )
                          )}
                        </ul>
                        {/* <h4 className="product-text">
                          Product Highlight: Luxurious Comfort and Timeless
                          Elegance with Our Sofa
                        </h4>
                        <p className="product-text">
                          Indulge in the epitome of comfort and style with our
                          exquisite sofa, meticulously crafted to elevate your
                          living space to new heights of sophistication. Here
                          are the key highlights of our sofa:
                        </p> */}
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                      <Accordion.Header>Manufacturer Details</Accordion.Header>
                      <Accordion.Body>
                        <h4 className="product-text">
                          Product Highlight: Luxurious Comfort and Timeless
                          Elegance with Our Sofa
                        </h4>
                        <p className="product-text">
                          Indulge in the epitome of comfort and style with our
                          exquisite sofa, meticulously crafted to elevate your
                          living space to new heights of sophistication. Here
                          are the key highlights of our sofa:
                        </p>
                      </Accordion.Body>
                    </Accordion.Item> */}
                  </Accordion>
                </div>
              </Col>

              {/* <Col xxl={3} xl={3} lg={6} md={8}>
                <div className="Amount-Card">
                  <div className="amount-header">
                    <p className="amount-text mb-0 pt-3">Amount</p>
                    <div className="d-flex">
                      <p className="price">10,000</p>
                      <p className="amount-text">( GST Incl. Price )</p>
                      <p className="amount-text">
                        M.R.P. :<span className="text-rupees"> 10000</span>{" "}
                      </p>
                    </div>
                  </div>

                  <div>
                    <div id="second-div" class="shopping-img-align mt-2">
                      <div class="d-flex add-cart-box">
                        <button
                          class="minus btn"
                          onClick={() => handleMinusClick()}
                        >
                          -
                        </button>
                        <div class="quantity">{number}</div>
                        <button
                          class="add btn"
                          onClick={() => handlePlusClick()}
                        >
                          +
                        </button>
                      </div>
                      <p className="amount-text">
                        ( Min. order quantity 1 pcs )
                      </p>
                      <Row>
                        <Table>
                          <thead>
                            <tr>
                              <th className="amount-text">Select</th>
                              <th className="amount-text">Variants</th>
                              <th className="amount-text">Discounted Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <Form.Check type="radio" name="name" />
                              </td>
                              <td>
                                <div className="chair">
                                  <img
                                    className="product-image"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assest/images/B2Bbuyer/B2BSellerDetail/Sofa_1.png"
                                    }
                                    alt="product-image"
                                  />
                                </div>
                              </td>
                              <td>
                                <p className="price">Rs. 9,500</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Form.Check type="radio" name="name" />
                              </td>
                              <td>
                                {" "}
                                <div className="chair">
                                  <img
                                    className="product-image"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assest/images/B2Bbuyer/B2BSellerDetail/Sofa_1.png"
                                    }
                                    alt="product-image"
                                  />
                                </div>
                              </td>
                              <td>
                                <p className="price">Rs. 9,500</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Form.Check type="radio" name="name" />
                              </td>
                              <td>
                                {" "}
                                <div className="chair">
                                  <img
                                    className="product-image"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assest/images/B2Bbuyer/B2BSellerDetail/Sofa_1.png"
                                    }
                                    alt="product-image"
                                  />
                                </div>
                              </td>
                              <td>
                                <p className="price">Rs. 9,500</p>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <ButtonComponent className={"Buy-now"}>
                          Buy Now
                        </ButtonComponent>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Container>

          <ContactSupplier
            handleClose={handleClose}
            placement="bottom"
            name="bottom"
            show={show}
          />
        </div>
      </section>

      {/* <section className="B2b-Seller-Detail">
        <div className="Background-image">
          <Container>
            <Row className="product-content">
              <Col xxl={5} xl={5} lg={5}>
                <div className="main">
                  <div className="sidebar-swiper">
                    <Swiper
                      direction={"vertical"}
                      slidesPerView={"auto"}
                      modules={[Navigation, Autoplay]}
                      breakpoints={{
                        0: {
                          slidesPerView: 4,
                          spaceBetween: 60,
                        },
                        380: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        485: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        575: {
                          slidesPerView: 5,
                          spaceBetween: 30,
                        },

                        768: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        992: {
                          slidesPerView: 6,
                          spaceBetween: 80,
                        },
                        1024: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        1200: {
                          slidesPerView: 5,
                          spaceBetween: 80,
                        },
                        1440: {
                          slidesPerView: 5,
                          spaceBetween: 50,
                        },
                        2000: {
                          slidesPerView: 4,
                          spaceBetween: 20,
                        },
                      }}
                      navigation
                      pagination={{ clickable: true }}
                      className="mySwiper"
                      onSwiper={(swiper) => setSwiperInstance(swiper)}
                    >
                      {images?.map((item, index) => (
                        <SwiperSlide>
                          <div className="gallery-imgs">
                            <img
                              src={IMG_URL + item.image}
                              className="inner-img"
                              alt="Sofa"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="silder-btn">
                      <div
                        className="back-btn"
                        onClick={() => handleMainSliderClick("prev")}
                      ></div>
                      <div
                        className="next-btn"
                        onClick={() => handleMainSliderClick("next")}
                      ></div>
                    </div>
                  </div>
                  <div className="sidebar-swiper-mobile-view">
                    <Swiper
                      slidesPerView={"auto"}
                      modules={[Navigation, Autoplay]}
                      breakpoints={{
                        0: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        320: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        485: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        575: {
                          slidesPerView: 5,
                          spaceBetween: 20,
                        },

                        768: {
                          slidesPerView: 8,
                          spaceBetween: 10,
                        },
                        992: {
                          slidesPerView: 6,
                          spaceBetween: 80,
                        },
                        1024: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        1200: {
                          slidesPerView: 5,
                          spaceBetween: 80,
                        },
                        1440: {
                          slidesPerView: 5,
                          spaceBetween: 50,
                        },
                        2000: {
                          slidesPerView: 4,
                          spaceBetween: 20,
                        },
                      }}
                      navigation
                      pagination={{ clickable: true }}
                      className="mySwiper"
                      onSwiper={(swiper) => setSwiperInstance(swiper)}
                    >
                      {verticalswiperDetails.map((item, index) => (
                        <SwiperSlide>
                          <div className="gallery-imgs">
                            <img
                              src={item.image}
                              className="inner-img"
                              alt="Sofa"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="silder-btn">
                      <div
                        className="back-btn"
                        onClick={() => handleMainSliderClick("prev")}
                      ></div>
                      <div
                        className="next-btn"
                        onClick={() => handleMainSliderClick("next")}
                      ></div>
                    </div>
                  </div>
                
                  <div className="background-product-detail">
                    <div className="product-detail">
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                        onSwiper={(swiper) => setSwiperInstancenew(swiper)}
                      >
                        {images?.map((item, index) => (
                          <SwiperSlide key={index}>
                            <img
                              className="product-img"
                              src={IMG_URL + item.image}
                              alt="Selected"
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <div className="silder-btn">
                        <div
                          className="back-btn"
                          onClick={() => handleSliderClick("prev")}
                        ></div>
                        <div
                          className="next-btn"
                          onClick={() => handleSliderClick("next")}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xxl={7} xl={7} lg={7}>
                <div className="product-information">
                  <h1 className="heading">
                    {singleVariant?.product_title}
                  </h1>
                  <p className="product-id">SKU ID :ABC-12345-S-BL</p>

                  <div className="d-flex">
                    <p className="quantity">
                      Minimum order quantity: 10 pieces
                    </p>
                    <div className="border-between"></div>
                    <p className="product">
                      {
                        singleVariant?.s_product_customization?.s_customization
                          ?.name
                      }
                    
                    </p>
                  </div>
                  <div className="main-price">
                    <p className="price">10,000</p>
                    <ButtonComponent variant="primary" onClick={handleShowCard}>
                      Select Variations
                    </ButtonComponent>
                  </div>

                  <AddTocart
                    handleClose={handleCloseCard}
                    placement="end"
                    name="end"
                    show={showcard}
                  />

                  <p className="product-id">Variations</p>
                  <p className="quantity">
                    1.{" "}
                    {
                      singleVariant?.s_product_variant
                        ?.s_product_variant_attribute?.s_attribute?.name
                    }
                  </p>

                 
                  <div className="main-section-tabs">
                    {singleVariant?.s_product_variant?.s_product_variant_attribute?.s_product_variant_attribute_details?.map(
                      (item, index) => (
                        <div className="background-color-sofa">
                          <img
                            src={IMG_URL + item.thumbnail}
                            className="product"
                            alt="Check"
                            onClick={async () => {
                              await setImages(item?.s_p_v_a_d_images);
                            }}
                          />
                        </div>
                      )
                    )}
                   
                  </div>
                

                 


                  <div className="standard-section">
                    <div className="buttons-section">
                      <ButtonComponent
                        variant="primary"
                        onClick={handleShowCard}
                      >
                        Buy Now
                      </ButtonComponent>
                      <ButtonComponent variant="primary" onClick={handleShow}>
                        Contact Supplier
                      </ButtonComponent>
                      <ButtonComponent
                        variant="chat"
                        className="chat"
                        onClick={handleShowchat}
                      >
                        Chat Now
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <ContactSupplier
            handleClose={handleClose}
            placement="bottom"
            name="bottom"
            show={show}
          />
        </div>
      </section> */}
      <CustomisationModal
        show={showCustomisationModal}
        onHide={() => setshowCustomisationModal(false)}
      />
    </>
  );
};

export default B2bSellerDetail;
