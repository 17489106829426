import React, { useEffect, useState, useContext } from "react"
import './EditPersonalDetailsModal.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangeModal from './ChangeModal';
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler, useFieldArray, } from "react-hook-form";
import InquiryModal from "../../../common-components/AllModal/inquiry-Modal/InquiryModal";
import Select from "react-select";
import { Tab, Nav } from "react-bootstrap";
import {
    firmAddressType,
    City,
    State,
    Country,
    Pincode,
    pincodeWiseAll,
} from "../../../../../utils/apis/master/Master";
import {
    firmDropAddressPost,
    getfirmDropAddress,
} from "../../../../../utils/apis/btb/B2b";
import classNames from "classnames";
const EditDropAddress = (props) => {
    const [modalChange, setModalChange] = useState(false);
    const [modalInquiry, setModalInquiry] = useState(false);



    const submitModal = () => {
        setModalInquiry(true);
        setTimeout(() => {
            props.onHide();
            reset();
            setModalInquiry(false);
        }, 3000);
    };


    const { getData, postData, IMG_URL, Select2Data, Id } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },


    } = useForm();

    const { append, fields, remove } = useFieldArray({
        control,
        name: "data",
    });

    console.log(errors);
    const [firmAddressTypes, setFirmAddressType] = useState([]);
    const [cities, setCities] = useState("");
    const [states, setStates] = useState("");
    const [countries, setCountries] = useState("");
    const [pincodes, setPincodes] = useState([]);
    const getMasters = async () => {
        {
            const res = await firmAddressType();
            if (res?.success) {
                setFirmAddressType(res.data);
            }
        }
    };

    const handlePincodeChange = async (e) => {
        const res = await Pincode(e);
        if (res?.success) {
            const data = await Select2Data(res.data, "pincode_id", false);
            setPincodes(data);
        }
    };


    const handlePincodeSelect = async (id, index) => {
        const res = await pincodeWiseAll(id);
        if (res?.success) {
            console.log(":-----", res);
            const updatedCities = [...cities];
            updatedCities[index] = res.data.city.label;
            setCities(updatedCities);

            const updatedStates = [...states];
            updatedStates[index] = res.data.state.label;
            setStates(updatedStates);

            const updatedCountries = [...countries];
            updatedCountries[index] = res.data.country.label;
            setCountries(updatedCountries);

            setValue(`data[${index}].city_id`, res.data.city);
            setValue(`data[${index}].state_id`, res.data.state);
            setValue(`data[${index}].country_id`, res.data.country);
        }
    };

    const onSubmit = async (d) => {
        console.log(d, "form");
        d.btb_personal_details_id = Id;
        const res = await firmDropAddressPost(d);
        if (res?.success) {
            console.log(res, "addresspost");
            submitModal();
        }
    };


    const getfirmDropAddressIn = async () => {
        const res = await getfirmDropAddress(Id);
        if (res?.success) {
            if (res.data.length > 0) {
                console.log(res);
                var updatedCities = [...cities];
                var updatedStates = [...states];
                var updatedCountries = [...countries];
                var updatedPincodes = [...pincodes];
                res.data.forEach((item, index) => {
                    updatedCities[index] = item.city_id?.label;
                    updatedStates[index] = item.state_id?.label;
                    updatedCountries[index] = item.country_id?.label;
                    updatedPincodes[index] = item.country_id?.label;
                });
                setCities(updatedCities);
                setStates(updatedStates);
                setCountries(updatedCountries);
                setPincodes(updatedPincodes);

                reset({ data: res.data });
            } else {
                reset({
                    data: [
                        {
                            name: "",
                            add_line_one: "",
                            add_line_two: "",
                            city_id: "",
                            state_id: "",
                            pincode_id: "",
                            country_id: "",
                            s_firm_address_type: "",
                        },
                    ],
                });
            }
        }
    };

    useEffect(() => {
        // getMasters();
        // handlePincodeChange()
        getfirmDropAddressIn();
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='edit-details-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Drop Address Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Row className="mb-3">
                            {fields.map((item, index) => (
                                <div key={item.id} className="row">
                                    <div className="col-md-6">
                                        <Form.Group>
                                            <Form.Label>Enter name of address</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className={classNames("", {
                                                    "is-invalid":
                                                        errors.data &&
                                                        errors.data[index] &&
                                                        errors.data[index].name,
                                                })}
                                                name={`data[${index}].name`}
                                                placeholder="Enter Name of address"
                                                defaultValue={item.name}
                                                {...register(`data[${index}].name`, {
                                                    required: "Name of address is required",
                                                })}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-6"></div>

                                    <div className="col-md-6">
                                        <Form.Group>
                                            <Form.Label>Enter Address Line 1</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className={classNames("", {
                                                    "is-invalid":
                                                        errors.data &&
                                                        errors.data[index] &&
                                                        errors.data[index].add_line_one,
                                                })}
                                                name={`data[${index}].add_line_one`}
                                                placeholder="Enter Address Line 1"
                                                defaultValue={item.add_line_one}
                                                {...register(`data[${index}].add_line_one`, {
                                                    required: "Address line 1 is required",
                                                })}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group>
                                            <Form.Label>Address line 2</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className={classNames("", {
                                                    "is-invalid":
                                                        errors.data &&
                                                        errors.data[index] &&
                                                        errors.data[index].add_line_two,
                                                })}
                                                name={`data[${index}].add_line_two`}
                                                placeholder="Enter Address Line 2"
                                                defaultValue={item.add_line_two}
                                                {...register(`data[${index}].add_line_two`, {
                                                    required: "Address line 2 is required",
                                                })}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-6">
                                        <Form.Group>
                                            <Form.Label>City/District:</Form.Label>

                                            <Form.Control
                                                className={classNames("", {
                                                    "is-invalid": errors?.city,
                                                })}
                                                type="text"
                                                name={`data[${index}].city_id`}
                                                readOnly
                                                value={cities[index]}
                                            // placeholder={Placeholders.city}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group>
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                className={classNames("", {
                                                    "is-invalid": errors?.state,
                                                })}
                                                type="text"
                                                name={`data[${index}].state_id`}

                                                value={states[index]}
                                                // placeholder={Placeholders.state}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group>
                                            <Form.Label>Pincode</Form.Label>

                                            <Controller
                                                name={`data[${index}].pincode_id`}
                                                control={control}
                                                rules={{ required: "Pincode Required" }}
                                                render={({ field, fieldState: { error } }) => (
                                                    <Select
                                                        {...field}
                                                        options={pincodes}
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                borderColor: error ? "red" : baseStyles.borderColor,
                                                            }),
                                                        }}
                                                        onInputChange={(inputValue) => {
                                                            handlePincodeChange(inputValue);  // Handle user input changes, if necessary
                                                        }}
                                                        onChange={(selectedOption) => {
                                                            // console.log("selectedOption", selectedOption);

                                                            field.onChange(selectedOption);  // Update the field value
                                                            handlePincodeSelect(selectedOption.value, index);  // Custom handler for additional logic
                                                        }}

                                                    />
                                                )}
                                            />

                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group>
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                className={classNames("", {
                                                    "is-invalid": errors?.country,
                                                })}
                                                type="text"

                                                name={`data[${index}].country_id`}
                                                readOnly
                                                value={countries[index]}
                                            // placeholder={Placeholders.country}
                                            />
                                        </Form.Group>
                                    </div>




                                    {index !== 0 && (
                                        <div className="addremoveadress">
                                            <div className="text-end Addnewadresstxt">
                                                <p className="" onClick={() => remove(index)}>
                                                    Remove Address{" "}
                                                    <FontAwesomeIcon icon="fa-solid fa-minus" />
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <hr className="mt-3"></hr>
                                </div>
                            ))}



                            <div className="addremoveadress">
                                <div className="text-end Addnewadresstxt">
                                    <p
                                        onClick={() =>
                                            append({
                                                name: "",
                                                add_line_one: "",
                                                add_line_two: "",
                                                city_id: "",
                                                state_id: "",
                                                pincode_id: "",
                                                country_id: "",
                                            })
                                        }
                                    >
                                        Add New Address <FontAwesomeIcon icon="fa-solid fa-plus" />
                                    </p>
                                </div>
                            </div>

                        </Row>
                        <Modal.Footer>
                            <ButtonComponent onClick={props.onHide} className={'btn-cancle'}>Cancel</ButtonComponent>
                            <Button
                                // onClick={() => handleSubmit(onSubmit)}
                                type="submit"
                                className={'btn-update'}
                            > Update</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal >

            <InquiryModal
                message="Submitted successfully"
                show={modalInquiry}
                onHide={() => setModalInquiry(false)}
            />
        </>
    )
}

export default EditDropAddress 