import { useState, useEffect, useContext } from "react";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../../Terms&Condition/TermsAndConditionModal";
import { Link } from "react-router-dom";
import { Context } from "../../../../../utils/context";

function StepthreeInner({ setSelectedSubCategory, handleRegisterBack, subCategory, selectedSubCategory, nextStep, childCategory, setChildCategory }) {
  
  const { getData, postData, IMG_URL } = useContext(Context);
  const [showStepThreeInner, setShowStepThreeInner] = useState(false);
  const handleShowStepThreeInner = () => {
    setShowStepThreeInner(true);
  };
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [s_sub_category_id, sets_sub_category_id] = useState("");
  const [s_sector_sub_id, sets_sector_sub_id] = useState("");
  const [childCategoryIn, setChildCategoryIn] = useState([]);


  const handleClick = (id) => {

    if (childCategory.some(item => item.s_child_category_id === id)) {

      setChildCategory(prevState => prevState.filter(item => item.s_child_category_id !== id));
      
    } else {
      setChildCategory(prevState => [
        ...prevState,
        {
          s_sub_category_id: s_sub_category_id,
          btb_sector_sub_id: s_sector_sub_id,
          s_child_category_id: id
        }
      ]);
    }
    console.log(childCategory,"childCategorychildCategorychildCategorychildCategory");
  };

  
  const [errors, setErrors] = useState('');

  const subCategoryClick = async (index) => {


    var value = subCategory[index];
    sets_sector_sub_id(value?.id);
    sets_sub_category_id(value?.s_sub_category?.id);    
    setSelectedSubCategory(value?.s_sub_category?.id);
    const res = await getData(`/seller/masters/allchildcategory/${value?.s_sub_category?.id}`);
    if (res?.success) {
      setChildCategoryIn(res.data);
    }
  };


  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredChildCategory = childCategoryIn?.filter((childCategoryIn) =>
  childCategoryIn.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleNext = async () => {
    if(childCategory.length === 0){
      setErrors('Select any Sub Category to continue.');
    }else{
      nextStep();
    }
  }


  return (
    <>
      <div className="personal-details-form">
        <div className="">
          <div className="form-container">
            <div className="row stepthreeformRowmain">
              <div className="col-md-3">
                <div className="CategoriesHead">
                  <h3>
                    <FontAwesomeIcon
                      className="left-arrow me-2"
                      icon="fa-solid fa-arrow-left"
                      onClick={handleRegisterBack}
                    />
                    Subcategory
                  </h3>
                </div>
                <div className="categoriesList">
                  {subCategory?.map((value,index) => (
                    <p
                      key={index}
                      className={
                        selectedSubCategory === value?.s_sub_category?.id ? "selected" : ""
                      }
                      onClick={() => subCategoryClick(index)}
                    >
                      {value?.s_sub_category?.name}
                    </p>
                  ))}
                </div>
              </div>
              <div className="col-md-9">
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="stepthreesearch">
                        <Form>
                          <Form.Group controlId="">
                            <Form.Control
                              type="text"
                              name=""
                              placeholder="Search for the sector you belong to"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                          </Form.Group>
                          <FontAwesomeIcon
                            icon="fa-solid fa-magnifying-glass"
                            className="searchStepIon"
                          />
                        </Form>
                      </div>

                      <div className="grid-container">
                        {filteredChildCategory?.map((option, index) => (
                          <div
                            key={index}
                            className={`box ${childCategory?.some(item => item.s_child_category_id === option?.id) ? "active" : ""}`}
                            onClick={() => handleClick(option?.id)}
                          >
                            {option?.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="text-end apply_now_btn">
                {errors && (
                  <p>
                    <span className="termstxt text text-danger">
                      {errors}
                    </span>
                  </p>
                )}
                <Link to={"/seller/seller-dashboard/"}>
                  {/* <Button className="back-btn me-3">Skip</Button> */}
                </Link>


                <Button onClick={handleNext} className=" tabs-btn">
                  Save & Next
                </Button>

                <TermsAndConditionModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StepthreeInner;
