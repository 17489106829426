import React from "react";
import "./advertisement_add_task_modal.css";
import { useState, useContext, useEffect } from "react";
import "../selldash-commonmodal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../../../utils/context";
import SuccessModal from "../../../../../CommonForAll/SuccessModal/SuccessModal";

const Affiliate_add_task_modal = (props) => {
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [successModalShow, setSuccessModalShow] = useState(false);

  const dateObject = new Date(startDate);
  const formattedDate = dateObject.toLocaleDateString("en-IN", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const { getData, postData, IMG_URL, Select2Data, Id, usertype } =
    useContext(Context);
  const [product, setProducts] = useState("");
  const [productData, setProductsData] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (d) => {
    console.log(d);
    const data = {
      amount: d?.amount,
      s_product_type_id: d?.s_product_type_id?.value,
      product_available: d?.product_available?.value,
      validity: d?.validity,
      task_type: "affiliate",
    };
    const res = await postData(
      `/seller/seller-task/task/${props.editId}`,
      data
    );
    // console.log(res?.success,"res?.success");
    if (res?.success) {
      console.log("hhhhhhhhhh");
      props.onHide();
      // reset();
      setProductsData("");
      setSuccessModalShow(true);
      props.getAllProducts();
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 1000);
    }
  };
  const Select3Data = async (data, name, other = false) => {
    const result = data.map((data) => ({
      value: data?.id,
      label: data?.product_title,
      name: name,
    }));

    if (other) {
      result.push({ value: "0", label: "Other", name: name });
    }
    return result;
  };
  const getMasters = async () => {
    // const res = await getData(`/seller/masters/all-products?seller_id=${Id}&type=`);
    const res = await getData(
      `/seller/seller-task/task/all-products/data?type=outer`
    );

    if (res?.success) {
      const data = await Select3Data(res.data, "s_product_type_id", false);
      setProducts(data);
    }
  };
  const handlePincodeChange = async (product_id) => {
    const res = await getData(
      `/seller/seller-task/task/products/${product_id.value}`
    );
    if (res?.success) {
      setProductsData(res?.data);
    }
  };
  const options = [
    { value: "1", label: "Yes" },
    { value: "0", label: "NO" },
  ];
  useEffect(() => {
    getMasters();
  }, []);

  const GetEditData = async () => {
    const response = await getData(`/seller/seller-task/task/${props.editId}`);
    reset(response?.data);
    const res = await getData(
      `/seller/seller-task/task/products/${response?.data?.s_product_type_id?.value}`
    );
    if (res?.success) {
      setProductsData(res?.data);
    }
  };
  useEffect(() => {
    GetEditData();
  }, [props.editId]);
  return (
    <>
      <section className="">
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="selldash-commonmodal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Task
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-xxl-9 col-xl-9 col-lg-12">
                <div className="form-sec">
                  <Form>
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label>Choose Product Type</Form.Label>
                          <Controller
                            name="s_product_type_id"
                            {...register("s_product_type_id", {
                              required: "Product Is Required",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.s_product_type_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={product}
                                onChange={(options) => {
                                  handlePincodeChange(options);
                                  setValue("s_product_type_id", options);
                                }}
                                placeholder="--- please select ---"
                              />
                            )}
                          />
                          {errors?.s_product_type_id && (
                            <span className="text text-danger">
                              {errors.s_product_type_id.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Amount per product selling insentive
                          </Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.amount,
                            })}
                            type="text"
                            {...register("amount", {
                              required: "Insentive Amount Is Required",
                            })}
                            placeholder="amount Amount"
                          />
                          {errors?.amount && (
                            <span className="text text-danger">
                              {errors.amount.message}
                            </span>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Product available for review</Form.Label>
                          <Controller
                            name="product_available"
                            {...register("product_available", {
                              required: "Available for review is required",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.product_available
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={options}
                                placeholder="--- Please select ---"
                              />
                            )}
                          />
                          {errors?.product_available && (
                            <span className="text text-danger">
                              {errors.product_available.message}
                            </span>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Validity</Form.Label>
                          <div>
                            <Form.Control
                              type="date"
                              className={classNames("", {
                                "is-invalid": errors?.validity,
                              })}
                              {...register("validity", {
                                required: "Date is required",
                              })}
                              placeholder="Enter website"
                            />
                            {/* <DatePicker
                                                            {...register("validity",{
                                                                required:"validity"
                                                            })}
                                                            selected={startDate}
                                                            onChange={(date) => setStartDate(date)}
                                                            name="start_date"
                                                            id="start_date"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            minDate={new Date()}
                                                            placeholderText="MM/DD/YYYY"
                                                        /> */}
                          </div>
                          {errors?.validity && (
                            <span className="text text-danger">
                              {errors.validity.message}
                            </span>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-xxl-8 col-xl-8 col-lg-6">
                        <div className="row">
                          <div className="col-xxl-4 col-xl-4 col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Brand Name{" "}
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>
                                  {productData.s_brand?.name}
                                </Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Type{" "}
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>{productData.added_by}</Form.Label>
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-lg-4 col-sm-6">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Industries
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>
                                  {productData.s_category?.name}
                                </Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Product Review
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>4.3</Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Cost
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>500</Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Discount
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>5%</Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row">
                          <div className=" col-xxl-8 col-xl-8  col-lg-12  col-sm-6">
                            <Form.Group className="mb-1">
                              <div>
                                <Form.Label>
                                  Highlit Point{" "}
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                            </Form.Group>
                            <div className="ullistt">
                              <ul>
                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-12  col-sm-6">
                            <Form.Group className="mb-1">
                              <div>
                                <Form.Label>
                                  Image{" "}
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div className="">
                                <img
                                  className="bjvbhbvh-img"
                                  src={IMG_URL + productData.s_brand?.image}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-end">
                      {/* <button className='btn closebtn'>Close</button> */}
                      <button
                        className="btn sbmitbtn"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-12">
                <Form.Group className="mb-1">
                  <div>
                    <Form.Label>Instructions</Form.Label>
                  </div>
                </Form.Group>
                <div className="ullistt bg">
                  <ul>
                    <li>Lorem ipsum dolor sit amet, consectetur</li>
                    <li>Lorem ipsum dolor sit amet, consectetur</li>
                    <li>Lorem ipsum dolor sit amet, consectetur</li>
                    <li>Lorem ipsum dolor sit amet, consectetur</li>
                    <li>Lorem ipsum dolor sit amet, consectetur</li>
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
                        <Button onClick={props.onHide}>Close</Button>
                    </Modal.Footer> */}
        </Modal>
        <SuccessModal show={successModalShow} />
      </section>
    </>
  );
};

export default Affiliate_add_task_modal;
