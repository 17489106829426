import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "../../ShoppingCartMain/ShoppingCartMain.css";
import ShoppingCartCard from "../ShoppingCartCard/ShoppingCartCard";
import SummaryCard from "../SummaryCard/SummaryCard";
import {
  btobCartList,
  btobseletedCartList,
} from "../../../../utils/apis/btb/Cart";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";
import Cookies from "js-cookie";
import { Link, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import {
  BtbOrderStatus,
  BtbProductTypes,
} from "../../../../utils/apis/master/Master";
const ShoppingCart = () => {
  const { signin, Select2Data, getData } = useContext(Context);
  const [productTypes, setProductTypes] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchAmount, setSearchAmount] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [searchType, setSearchType] = useState({});
  const [resetButten, setResetButten] = useState();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [cart, setcart] = useState([]);
  const location = useLocation();
  console.log(location);
  const getDataAll = async () => {
    console.log("signin ? ", signin);

    if (signin) {
      let res;
      console.log("Kiran");
      console.log(location.pathname == "/b2bbuyer/start-order");
      if (location.pathname == "/b2bbuyer/start-order") {
        res = await btobseletedCartList();
      } else {
        res = await getData(
          `/btb/cart/show?searchName=${searchName}&searchAmount=${searchAmount}` +
            `&searchDate=${searchDate || ""}&searchType=${
              searchType?.value || ""
            }`
        );
      }
      console.log("CartResponse", res);
      setValue("total_items", res?.data?.length);

      let total_tax = 0;
      let total_mrp = 0;
      let total_selling = 0;
      for (const data of res?.data) {
        total_tax += data?.total_tax;
        total_mrp += data?.total_mrp;
        total_selling += data?.total_selling;
      }
      setValue("status", res?.data?.status);
      setValue("total_tax", total_tax);
      setValue("total_selling", total_selling);
      setValue("total_mrp", total_mrp);
      setValue("total", total_selling + total_tax);
      setValue("discount", total_mrp - total_selling);
      setValue("sold_by", res?.data?.sold_by);
      setcart(res?.data);
    } else {
      if (Cookies.get("btb_cart")) {
        if (JSON.parse(Cookies.get("btb_cart")).length === 0) {
          setValue("total_tax", 0);
          setValue("total_selling", 0);
          setValue("total_mrp", 0);
          setValue("total", 0);
          setValue("discount", 0);
          setcart([]);
        }

        await setcart(JSON.parse(Cookies.get("btb_cart")));
      }
    }
  };

  useEffect(() => {
    getDataAll();
  }, [searchName, searchAmount, searchDate, resetButten, searchType]);

  const getProductType = async () => {
    const res = await BtbProductTypes();
    setProductTypes(await Select2Data(res?.data, "s_product_type_id"));
  };

  useEffect(() => {
    getProductType();
    getDataAll();
  }, []);

  console.log(cart);

  return (
    <>
      <section className="ShoppingCart-sec">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
              <div className="heading">
                <h1>Shopping Cart</h1>
              </div>
              <div className="  col-lg-8  col-md-12 col-12 mt-2">
                <div className="d-flex justify-content-end entry-showing">
                  <div className="num me-2">
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Product Name"
                      value={searchName}
                      onChange={(e) => {
                        setSearchName(e.target.value);
                      }}
                    />
                  </div>

                  {/* <div className="num me-2">
                    <input
                      type="number"
                      className="form-control"
                      id=""
                      placeholder="Amount"
                      value={searchAmount}
                      onChange={(e) => {
                        setSearchAmount(e.target.value);
                      }}
                    />
                  </div> */}
                  <div className="num me-2">
                    <input
                      max={new Date().toISOString().split("T")[0]}
                      type="date"
                      className="form-control"
                      id=""
                      placeholder="Select Date"
                      value={searchDate}
                      onChange={(e) => {
                        setSearchDate(e.target.value);
                      }}
                    />
                  </div>

                  <div className="select-div">
                    <Select
                      value={searchType}
                      onChange={(selectedOption) => {
                        setSearchType(selectedOption);
                      }}
                      options={productTypes}
                      placeholder="Tag"
                      isSearchable
                    />
                  </div>

                  {/* <div className="Search me-2">
              <button
                type="button"
                onClick={getOrders}
                className="btn btn-search"
              >
                <img src={search1} className="search" alt="" />
              </button>
            </div> */}
                  <div className="Search-1">
                    <button
                      type="button"
                      onClick={() => {
                        setSearchName("");
                        setSearchAmount("");
                        setSearchDate("");
                        setSearchType("");
                        setResetButten(!resetButten);
                      }}
                      className="btn btn-reset"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              {/* <Form.Group className="my-2">
                <Form.Check
                  label="Select all items"
                  feedback="You must agree before submitting."
                />
              </Form.Group> */}
              <ShoppingCartCard cardDataArray={cart} getData={getDataAll} />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              {signin && (
                <SummaryCard
                  getValues={getValues}
                  link={"/b2bbuyer/start-order"}
                  text={"Proceed to Checkout"}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShoppingCart;
