import React, { useEffect, useState, useContext } from "react"
import './EditPersonalDetailsModal.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangeModal from './ChangeModal';
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import InquiryModal from "../../../common-components/AllModal/inquiry-Modal/InquiryModal";
import Select from "react-select";
import { Tab, Nav } from "react-bootstrap";
import {
    firmAddressType,
    City,
    State,
    Country,
    Pincode,
    pincodeWiseAll,
} from "../../../../../utils/apis/master/Master";
import {
    firmAddressDetailPost,
    getfirmAddressDetail,
} from "../../../../../utils/apis/btb/B2b";
import classNames from "classnames";
const EditFirmAddress = (props) => {
    const [modalChange, setModalChange] = useState(false);
    const [modalInquiry, setModalInquiry] = useState(false);

    const submitModal = () => {
        setModalInquiry(true);
        setTimeout(() => {
            props.onHide();
            reset();
            setModalInquiry(false);
        }, 3000);
    };

    const { getData, postData, IMG_URL, Select2Data, Id } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },


    } = useForm();

    console.log(errors);
    const [firmAddressTypes, setFirmAddressType] = useState([]);
    const [cities, setCities] = useState("");
    const [states, setStates] = useState("");
    const [countries, setCountries] = useState("");
    const [pincodes, setPincodes] = useState([]);
    const getMasters = async () => {
        {
            const res = await firmAddressType();
            if (res?.success) {
                setFirmAddressType(res.data);
            }
        }
    };

    const handlePincodeChange = async (e) => {
        const res = await Pincode(e);
        if (res?.success) {
            const data = await Select2Data(res.data, "pincode_id", false);
            setPincodes(data);
        }
    };
    const handlePincodeSelect = async (id) => {
        const res = await pincodeWiseAll(id);
        if (res?.success) {
            console.log(res);
            setCities(res.data.city?.label);
            setStates(res.data.state?.label);
            setCountries(res.data.country?.label);
            reset({
                city_id: res.data.city,
                state_id: res.data.state,
                country_id: res.data.country,
                pincode_id: {
                    value: res.data.id,
                    label: res.data.name,
                },
            });
        }
    };
    // s_personal_details_id: req.body.s_personal_details_id,

    const onSubmit = async (data) => {
        // alert("hello")
        console.log(data, "form");
        const formData = new FormData();
        formData.append("add_line_one", data?.add_line_one);
        formData.append("add_line_two", data?.add_line_two);
        formData.append("city_id", data?.city_id?.value);
        formData.append("state_id", data?.state_id?.value);
        formData.append("country_id", data?.country_id?.value);
        formData.append("s_firm_address_type_id", data?.s_firm_address_type_id);
        formData.append("pincode_id", data?.pincode_id?.value);
        formData.append("btb_personal_details_id", Id);
        console.log("formData", formData);

        const res = await firmAddressDetailPost(formData);
        if (res?.success) {
            console.log(res, "addresspost");
            submitModal();
        }
    };

    const getfirmAddressDetailIn = async () => {
        const res = await getfirmAddressDetail(Id);
        if (res?.success) {
            console.log("res?.dat", res?.data);

            // setHomeAddressId(res.data.id);
            console.log(res, "getfirmAddressDetailgetfirmAddressDetail");

            setCities(res.data.city_id?.label);
            setStates(res.data.state_id?.label);
            setCountries(res.data.country_id?.label);
            reset(res.data);
        }
    };

    useEffect(() => {
        getMasters();
        getfirmAddressDetailIn();
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='edit-details-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Communication Address Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Row className="mb-3">
                            <div className="nav-tab-holder">
                                <h6>Address Type</h6>
                                <Nav variant="radio">
                                    {firmAddressTypes?.map((item, index) => (
                                        <Form.Check
                                            type="radio"
                                            key={index}
                                            id={index}
                                            name="addressType"
                                            label={item?.name}
                                            value={item?.id}
                                            defaultChecked={
                                                getValues("s_firm_address_type_id") === item?.id
                                            }
                                            {...register("s_firm_address_type_id", {
                                                required: "Address Type Required",
                                            })}
                                        />
                                    ))}

                                </Nav>
                                {errors?.s_firm_address_type_id && (<span className="text text-danger">
                                        {errors.s_firm_address_type_id.message}
                                    </span>

                                )}
                            </div>


                            {/* <Tab.Content className="details-form-holder">
                                <Tab.Pane eventKey="Home_address" key="Home_address"> */}
                            <div className="stepFourMain">
                                <div className="form-container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group controlId="Education_Level">
                                                <Form.Label className="">Address line 1</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="add_line_one"
                                                    placeholder="Address Line One"
                                                    {...register("add_line_one", {
                                                        required: "Required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.add_line_one?.message,
                                                        "is-valid": getValues("add_line_one"),
                                                    })}
                                                />
                                                {errors?.add_line_one && (
                                                    <span className="text text-danger">
                                                        {errors.add_line_one.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group controlId="Awarded_Year">
                                                <Form.Label className="">Address line 2</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="add_line_two"
                                                    placeholder="Address Line Two"
                                                    {...register("add_line_two", {
                                                        required: "Required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.add_line_two,
                                                        "is-valid": getValues("add_line_two"),
                                                    })}
                                                />

                                                {errors?.add_line_two && (
                                                    <span className="text text-danger">
                                                        {errors.add_line_two.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group controlId="Authourity">
                                                <Form.Label className="">City/District:</Form.Label>

                                                <Form.Control
                                                    // {...register("city_id", {
                                                    //     required: "Select City ",
                                                    // })}
                                                    // className={classNames("", {
                                                    //     "is-invalid": errors?.city,
                                                    // })}
                                                    type="text"
                                                    name="city_id"
                                                    readOnly
                                                    value={cities}
                                                    placeholder="----City----"
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6">
                                            <Form.Group controlId="Degree_Name:">
                                                <Form.Label className="">State</Form.Label>

                                                <Form.Control
                                                    // {...register("state_id", {
                                                    //     required: "Select Satet ",
                                                    // })}
                                                    // className={classNames("", {
                                                    //     "is-invalid": errors?.state,
                                                    // })}
                                                    type="text"
                                                    name="state_id"
                                                    value={states}
                                                    placeholder="----State----"
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6">
                                            <Form.Group controlId="Degree_Name:">
                                                <Form.Label className="">Pincode</Form.Label>

                                                <Controller
                                                    name="pincode_id"
                                                    {...register("pincode_id", {
                                                        required: "Select Pincode ",
                                                    })}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors.pincode_id
                                                                        ? "red"
                                                                        : baseStyles,
                                                                }),
                                                            }}
                                                            {...field}
                                                            options={pincodes}
                                                            onInputChange={(selectedOption) => {
                                                                handlePincodeChange(selectedOption);
                                                            }}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption.value);
                                                                handlePincodeSelect(selectedOption.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6">
                                            <Form.Group controlId="Degree_Name:">
                                                <Form.Label className="">Country</Form.Label>

                                                <Form.Control
                                                    // {...register("country_id", {
                                                    //     required: "Select Country ",
                                                    // })}
                                                    // className={classNames("", {
                                                    //     "is-invalid": errors?.country,
                                                    // })}
                                                    type="text"
                                                    name="country_id"
                                                    readOnly
                                                    value={countries}
                                                    placeholder="----Country----"
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* </Tab.Pane>
                            </Tab.Content> */}

                        </Row>
                        <Modal.Footer>
                            <ButtonComponent onClick={props.onHide} type="button" className={'btn-cancle'}>Cancel</ButtonComponent>
                            <Button
                                // onClick={() => handleSubmit(onSubmit)}
                                type="submit"
                                className={'btn-update'}
                            > Update</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal >
            <ChangeModal
                show={modalChange}
                onHide={() => setModalChange(false)}
            />
            <InquiryModal
                message="Submitted successfully"
                show={modalInquiry}
                onHide={() => setModalInquiry(false)}
            />
        </>
    )
}

export default EditFirmAddress