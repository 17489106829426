import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "./stocklist.css";
import { allProductCounter ,allProductOrder} from "../../../../../../../utils/apis/seller/BTBdashborad";

const Stock_list = () => {
  const [data, setData] = useState();
  const GetProductData = async () => {
    const response = await allProductCounter();
    setData(response?.data);
  };
  console.log(data, "data");
  useEffect(() => {
    GetProductData();
  }, []);

  const product_list = [
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/box.png",
      name: "Current Stock",
      number: data?.totalstock
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/shopping-bag.png",
      name: "Active Stock",
      number: data?.totalstockActive,
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/box2.png",
      name: "Inactive Stock",
      number: data?.totalstockInActive,
    },
    // {
    //   icon: "/assest/images/Seller_panel/dashboard/icons/box.png",
    //   name: "Cart Product",
    //   number: "50 / ₹67854",
    // },
    // {
    //   icon: "/assest/images/Seller_panel/dashboard/icons/sales2.png",
    //   name: "Total Sales",
    //   number: "56K",
    // },
  ];

  return (
    <>
      <section className="Stock_list_section">
        <div className="row">
          {product_list.map((data, index) => (
            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-widthhhh">
              <div className="list-flex">
                <div className="img-div">
                  <div className="circle-ico ico1">
                    <img className="icon" src={data.icon} />
                  </div>
                </div>
                <div className="cont-div">
                  <p className="name">{data.name}</p>
                  <p className="number">{data.number}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Stock_list;
