import React, { useEffect, useState, useContext } from "react";
import "../MyProfile.css";
import { Scrollchor } from "react-scrollchor";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import EditBankDetailsModal from "../../../common-components/AllModal/Editpersonal-Detailmodal/EditBankDetailsModal";
import {
  faUser,
  faBriefcase,
  faIndustry,
  faFilm,
  faLock,
  faChevronRight,
  faPen,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../../../../utils/api";
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
library.add(fas);

const EighthModal = () => {
  const [activeTab, setActiveTab] = useState("");
  const [modalEditDetails, setModalEditDetails] = useState(false);
  const { IMG_URL, Id } = useContext(Context);
  console.log("ID:---", Id);

  useEffect(() => {
    if (activeTab) {
      const element = document.getElementById(activeTab.slice(1)); // Remove '#' from activeTab
      if (element) {
        const yOffset = -80;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [activeTab]);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const getAllData = async () => {
    try {
      const res = await getData(`/btb/btb-create/btb-bank-details/${Id}`);
      reset(res?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(" getValues()", getValues());

  useEffect(() => {
    getAllData();
  }, [Id]);

  return (
    <>
      <div id="sample-code8">
        <div className="card b2bprofileCard">
          <div className="card-body">
            <div className="personalDetail">
              <h4>Bank Details</h4>
            </div>
            <div className="row">
              <div className=" col-xxl-2 col-xl-3 col-lg-3  col-md-3">
                <div className="Innernamee">
                  <p>Bank Name</p>
                  <h3>{getValues("bank_name")}</h3>
                </div>
              </div>
              <div className="col-xxl-2 col-xl-3 col-lg-3  col-md-3">
                <div className="Innernamee">
                  <p>Branch Name</p>
                  <h3>{getValues("branch_name")}</h3>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-3">
                <div className="Innernamee">
                  <p>Account Number</p>
                  <h3>{getValues("account_no")}</h3>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                <div className="Innernamee">
                  <p>IFSC Code</p>
                  <h3>{getValues("ifsc")}</h3>
                </div>
              </div>
            </div>
          </div>
          <button
            className="edit-btn"
            onClick={() => {
              setModalEditDetails(true);
            }}
          >
            <FontAwesomeIcon icon={faPen} />
          </button>
        </div>
      </div>

      <EditBankDetailsModal
        data={getValues()}
        show={modalEditDetails}
        onHide={() => {
          setModalEditDetails(false);
          getAllData();
        }}
      />
    </>
  );
};

export default EighthModal;
