import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const brandPercentageSend = {
  stepOne: 0,
  stepTwo: 10,
  stepThree: 60,
  stepFour: 90,
};

export const brandPercentage = [0, 10, 60, 90];

export const brandPersonalDetailsPost = async (data) => {
  try {
    //http://127.0.0.1:3000/api/brand/brand-create/brand-personal-details/1
    const res = await postData(
      `/brand/brand-create/brand-personal-details   `,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

//   export const brandGstDetailsPost = async (data) => {
//     try {
// //http://127.0.0.1:3000/api/brand/brand-create/brand-personal-details/1
//         const res = await postData(`brand/brand-create/brand-firm-details   `,data)

//       return res;
//     } catch (error) {
//       console.log(error);
//     }
//   };
export const brandGstDetailsPost = async (data) => {
  try {
    const res = await postData(`/brand/brand-create/brand-firm-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const brandGstDetailsGet = async (user_id) => {
  try {
    const res = await getData(
      `/brand/brand-create/brand-firm-details/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmDetailNamePost = async (data) => {
  try {
    const res = await postData(
      `/brand/brand-create/brand-firm-details/name`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getFirmDetailName = async (user_id) => {
  try {
    const res = await getData(
      `/brand/brand-create/brand-firm-details/name/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmDropAddressPost = async (data) => {
  try {
    const res = await postData(
      `/brand/brand-create/brand-firm-address-details`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmDropAddress = async (user_id) => {
  try {
    const res = await getData(
      `/brand/brand-create/brand-firm-address-details/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmAddressDetailPost = async (data) => {
  try {
    const res = await postData(
      `/brand/brand-create/brand-firm-address-details`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmAddressDetail = async (user_id) => {
  try {
    const res = await getData(
      `/brand/brand-create/brand-firm-address-details/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSectorCategory = async (user_id) => {
  try {
    const res = await getData(
      `/seller/seller-create/s-sector-category/${user_id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const mediaAttachmentPost = async (data) => {
  try {
    const res = await postData(
      `/brand/brand-create/brand-firm-attachment`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getMediaAttachment = async (data) => {
  try {
    const res = await getData(
      `/brand/brand-create/brand-firm-attachment/${data}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const firmPasswordPost = async (data) => {
  try {
    const res = await postData(
      `/brand/brand-create/brand-personal-details/password`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const industryDetailNamePost = async (data) => {
  try {
    return await postData(`/brand/brand-create/brand-firm-industry`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getIndustryDetailName = async (user_id) => {
  try {
    return await getData(`/brand/brand-create/brand-firm-industry/${user_id}`);
  } catch (error) {
    console.log(error);
  }
};
