import React, { useContext } from 'react'

import "./CompareAll.css";
import { Container } from "react-bootstrap";
import Animation from '../../Animation/SellerConvertPrompt/Animation';
import { Context } from '../../../../utils/context';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';

const SellerConvertPrompt = () => {

  const {userdata, setSignin} = useContext(Context);

  const navigate = useNavigate();
  const handleNavigate = async () => {
    
    Cookies.set("seller_id", userdata?.id, {
      expires: 1,
    });
    navigate('/seller/seller_stepform');
  }
  return (
    <section className="CompareAll text-center">
      <Container>
      <Animation />
        <h1 className="plan text-success mt-5">Register as a seller for this feature.</h1>
        <a onClick={() => handleNavigate()} className='btn btn-success mt-3'>Register Here</a>
      </Container>
    </section>
  )
}

export default SellerConvertPrompt