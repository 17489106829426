import { useState, useEffect, useContext } from "react";
import "./Logistic_Transport_stepForm.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-datepicker/dist/react-datepicker.css";
import Stepone from "./Stepone/Stepone";
import Steptwo from "./Steptwo/Steptwo";
import Stepthree from "./Stepthree/Stepthree";
import Stepfour from "./Stepfour/Stepfour";
import Stepfive from "./Stepfive/Stepfive";
import Stepsix from "./Stepsix/Stepsix";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Stepseven from "./Stepseven/Stepseven";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";

function Logistic_Transport_stepForm({ onNext, onPrev }) {
  const [startDate, setStartDate] = useState();
  const [userDetails, setUserDetails] = useState();
  const [showInnerComponent, setShowInnerComponent] = useState(true);
  const innerNext = () => {
    setShowInnerComponent(!showInnerComponent);
  };

  const { getData, postData, IMG_URL } = useContext(Context);

  const getlogisticDetails = async () => {
    const res = await getData(
      `/transporter/transporter-create/personal-details/${Cookies.get(
        "transport_id"
      )}`
    );
    if (res?.success) {
      setUserDetails(res.data);
    }
  };

  useEffect(() => {
    getlogisticDetails();
    window.scrollTo(0, 0);
  }, []);

  const [page, setPage] = useState(0);

  const nextStep = () => {
    getlogisticDetails();
    window.scrollTo(0, 0);
    setPage((currPage) => currPage + 1);
    if (page === 6 && onNext) {
      onNext();
    }
  };

  const prevStep = () => {
    getlogisticDetails();
    window.scrollTo(0, 0);
    setPage((currPage) => currPage - 1);
    if (page === 0 && onPrev) {
      onPrev();
    }
  };
  const prevStepInner = () => {
    window.scrollTo(0, 0);
  };

  const calculatePercentage = () => {
    const totalSteps = stepNames.length;
    const currentPage = page + 1;
    return (currentPage / totalSteps) * 100;
  };

  const calculateProgressPercentage = () => {
    const stepProgressPercentages = [0, 15, 35, 50, 65, 85, 99];

    return stepProgressPercentages[page];
  };
  const renderHeading = () => {
    switch (page) {
      case 0:
        return "Personal Details";
      case 1:
        return "Select Media Type";
      case 2:
        return "Please fill the below fields";
      case 3:
        return "Please fill the below fields";
      case 4:
        return "Please fill the below fields";
      case 5:
        return "Please fill the below fields";
      default:
        return null;
    }
  };

  const goToStep = (stepIndex) => {
    setPage(stepIndex);
  };

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return (
          <Stepone
            nextStep={nextStep}
            heading={stepNames[0]}
            userDetails={userDetails}
          />
        );
      case 1:
        return (
          <Steptwo
            prevStep={() => prevStep(0)}
            setPage={setPage}
            userDetails={userDetails}
          />
        );
      case 2:
        return (
          <Stepthree
            nextStep={nextStep}
            prevStep={() => prevStep(1)}
            setPage={setPage}
            userDetails={userDetails}
          />
        );
      case 3:
        return (
          <Stepsix
            nextStep={nextStep}
            prevStep={() => prevStep(3)}
            userDetails={userDetails}
          />
        );
      case 4:
        return (
          <Stepfour
            nextStep={nextStep}
            prevStep={() => prevStep(4)}
            userDetails={userDetails}
          />
        );
      case 5:
        return (
          <Stepfive
            nextStep={nextStep}
            prevStep={() => prevStep(4)}
            userDetails={userDetails}
          />
        );
      case 6:
        return (
          <Stepseven
            nextStep={nextStep}
            prevStep={() => prevStep(4)}
            userDetails={userDetails}
          />
        );
      default:
        return null;
    }
  };

  const stepheading = [
    "Personal Details",
    "Firm Details",
    "Bank Details",
    "Service Details",
    "Branch Details",
    "Attachment",
    "Password",
  ];

  const stepNames = [
    "Personal Details",
    "Firm Details",
    "Bank Details",
    "Service Details",
    "Branch Details",
    "Attachment",
    "Password",
  ];

  return (
    <>
      <section className="Logistic-Transport-StepMain">
        <div className="container">
          <div className=" mt-3">
            <div className="d-md-flex d-flex-unset">
              <div className="">
                <Link to="/logistic/logistic-home" className="left-goto-homee">
                  <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> Go to home
                </Link>
              </div>
              <div className="title-stttee-align">
                <h6>Transport Registration Form</h6>
              </div>
            </div>
          </div>

          {page !== 7 ? (
            <div className="ProgressBar">
              <ProgressBar
                percent={calculatePercentage()}
                filledBackground="#1C42A9"
                height="3px"
                style={{ margin: "auto" }}
              >
                {[1, 2, 3, 4, 5, 6, 7].map((icon, index) => (
                  <Step key={index}>
                    {({ accomplished }) => (
                      <div
                        style={{
                          backgroundColor: accomplished ? "#1C42A9" : "#ffffff",
                          color: accomplished ? "#fff" : "#000000",
                          border: accomplished
                            ? "1px solid #1C42A9"
                            : "1px solid #FFFFFF",
                          boxShadow: accomplished
                            ? "none"
                            : " 0px 0px 6px #00000029",
                        }}
                        className={`step ${accomplished ? "completed" : null}`}
                      >
                        <p className="mb-0">{icon}</p>
                        <div className="step-name">{stepNames[index]}</div>
                      </div>
                    )}
                  </Step>
                ))}
              </ProgressBar>
            </div>
          ) : (
            <></>
          )}

          <div className={page !== 7 ? "userForm" : "overviewMain"}>
            <div className="personal-details-form step-Form-Conteiner ">
              {/* form-heading-holder */}
              <div className="form-header-holder">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-12">
                    <div className="heading-holder">
                      <h6>
                        {page === 0
                          ? "Personal Details"
                          : page === 1
                            ? "Please fill the below fields"
                            : page === 2
                              ? "Please fill the below fields"
                              : page === 3
                                ? "Please fill the below fields"
                                : page === 4
                                  ? "Please fill the below fields"
                                  : page === 5
                                    ? "Please fill the below fields"
                                    : page === 6
                                      ? "Create password for your account"
                                      : null}
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-12">
                    <div className="span-holder">
                      <span>Just a few steps to go</span>
                      <div style={{ width: "40px", height: "40px" }}>
                        <CircularProgressbar
                          value={calculateProgressPercentage()}
                          text={`${calculateProgressPercentage()}%`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">{PageDisplay()}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Logistic_Transport_stepForm;
