import React,{useState,useContext,useEffect} from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Resources.css";
import BannerCommon from "../CommonComponents/BannerCommon/BannerCommon";
import { ResourcesGet } from "../../../utils/apis/myWebsite/mywebsite";
import { Context } from "../../../utils/context";

const Resources = () => {
    const ResourcesCards = [
        {
            images:
                process.env.PUBLIC_URL +
                "/assest/images/MyWebsite/Resources/Mask Group 112.png ",
            text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum socii natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel,",
        },
        {
            images:
                process.env.PUBLIC_URL +
                "/assest/images/MyWebsite/Resources/favicon.png",
            text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum socii natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel,",
        },
        {
            images:
                process.env.PUBLIC_URL +
                "/assest/images/MyWebsite/Resources/fonts.png",
            text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum socii natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel,",
        },
    ];

    
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();
    
    const { IMG_URL } = useContext(Context);

    const [resourcesData, setResourcesData] = useState();
    const getResourcesData = async () => {

        const res = await ResourcesGet();    
        if (res?.success) {
            setResourcesData(res?.data);
        }
    }

    useEffect(() => {
        getResourcesData();
    }, [])

    return (
        <>
        <BannerCommon heading={"Resources"} content={"Resources"} />
            <section className="Resources">
                <div className="container">
                    <Row>
                        {resourcesData?.map((item, index) => (
                            <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
                                <Card>
                                    <div className="card-main">
                                        <div className="main-image">
                                            <Card.Img variant="top" src={IMG_URL + item?.image} />

                                            <div className="overlay"></div>
                                            <div className="content-img">
                                                <p className="maintenance-text">
                                                  {htmlToReactParser.parse(item?.description)}
                                                </p>
                                            </div>
                                        </div>
                                        <Card.Body>
                                            <Card.Text className="maintenance-text">{item?.name}</Card.Text>
                                        </Card.Body>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </section>
        </>
    );
};

export default Resources;
