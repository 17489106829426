import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import "react-step-progress-bar/styles.css";
import Cookies from "js-cookie";
import { Context } from "../../../utils/context";
import { sellerLogin } from "../../../utils/apis/seller/Seller";
import "./SellerLogin.css";

import { Login } from "../../../utils/apis/seller/outer-website/login/Login";
import Call_support from "../call-support/Call_support";
const SellerLogin = () => {
  const { getData, postData, IMG_URL, setSignin, setUsertype } =
    useContext(Context);

  const [showPassword, setShowPassword] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const navigate = useNavigate();
  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    }
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (d) => {
    console.log(d);

    const res = await sellerLogin(d);
    if (res?.success) {
      console.log(res);
      Cookies.set("net_purti_security", res.data.token, { expires: 1 });
      setUsertype(res.data?.type);
      setSignin(true);
      navigate("/seller/seller-dashboard/");
    } else {
      setError("password", {
        type: "server",
        message: res?.message,
      });
    }
  };

  console.log(errors);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleForgetPassword = () => {
    navigate("/seller/sellerforget");
  };

  return (
    <>
      <section className="SellerLogin">
        <div className="container">
          <div className="loginHead">
            <Link to={"/seller/seller_home"}>
              <p>
                <FontAwesomeIcon
                  icon="fa-solid fa-less-than"
                  className="lessThanSymbol"
                />
                Go to Home
              </p>
            </Link>
            <h3>Seller Login</h3>
            <p></p>
          </div>

          <div className="col-lg-6 mx-auto">
            <div className="personal-details-form mb-0 ">
              {/* form-holder */}

              <div className="details-form-holder">
                <div className="form-container">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="field-bottom">
                            <Form.Group controlId="Last_Name">
                              <Form.Label className="required form-field">
                                Email
                              </Form.Label>
                              <div className="get-otp-text">
                                <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder="Enter Last Name"
                                  {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                      value:
                                        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                      message: "Invalid email address",
                                    },
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.email,
                                  })}
                                />
                              </div>
                              {errors?.email && (
                                <span className="text text-danger">
                                  {errors.email.message}
                                </span>
                              )}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="field-bottom">
                            <Form.Group controlId="Last_Name">
                              <Form.Label className="required form-field">
                                Password
                              </Form.Label>

                              <div className="get-otp-text">
                                <Form.Control
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Enter Password*"
                                  className={classNames("Passwordfield", {
                                    "is-invalid": errors?.password,
                                  })}
                                  name="password"
                                  {...register("password", {
                                    required: "Password is required",
                                  })}
                                />

                                <FontAwesomeIcon
                                  icon={iconOne}
                                  className="StepsixeditIcon"
                                  onClick={() =>
                                    togglePasswordVisibility("password")
                                  }
                                />
                                <p onClick={toggleForgetPassword}>
                                  Forget password ?
                                </p>
                                {errors?.password && (
                                  <span className="text text-danger">
                                    {errors.password?.message}
                                  </span>
                                )}
                              </div>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="text-center ">
                            <Button
                              className="tabs-btn login_now_btn"
                              type="submit"
                            >
                              Login
                            </Button>
                          </div>
                          <p>
                            Start selling by{" "}
                            <Link to={"/seller/seller_stepform"}>
                              {" "}
                              Sign-in{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Call_support />
    </>
  );
};

export default SellerLogin;
