import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "./Stepthree.css";
import InnerPageOne from "./InnerPageOne/InnerPageOne";
import InnerPageTwo from "./InnerPageTwo/InnerPageTwo";

function Stepthree({ setPage, mediaDetails, ...props }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [activeTab, setActiveTab] = useState("officeAddress");
  const [gstNumber, setGstNumber] = useState("");

  const [firmName, setFirmname] = useState("");

  const navItems = [
    { eventKey: "officeAddress", label: "Office address" },
    { eventKey: "other", label: "Other" },
  ];

  const handleSaveAndNext = () => {
    const currentIndex = navItems.findIndex(
      (item) => item.eventKey === activeTab
    );

    if (currentIndex < navItems.length - 1) {
      const nextTab = navItems[currentIndex + 1].eventKey;
      setActiveTab(nextTab);
    } else {
      setPage(4);
    }
  };

  const handleBack = () => {
    const currentIndex = navItems.findIndex(
      (item) => item.eventKey === activeTab
    );

    if (currentIndex > 0) {
      const prevTab = navItems[currentIndex - 1].eventKey;
      setActiveTab(prevTab);
    } else {
      setPage(1);
    }
  };

  const handleTabClick = (eventKey) => {
    setActiveTab(eventKey);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [innerPage, setInnerPage] = useState(0);

  const nextInnerPage = () => {
    setInnerPage((currPage) => currPage + 1);
  };

  const prevInnerPage = () => {
    setInnerPage((currPage) => currPage - 1);
  };
  const [showInnerComponent, setShowInnerComponent] = useState(true);
  const innerNext = () => {
    setShowInnerComponent(!showInnerComponent);
  };

  return (
    <>
      <div className="personal-details-form mb-0 ">
        {innerPage === 0 ? (
          <InnerPageOne
            mediaID={mediaDetails?.media_personal_details_id}
            nextInnerPage={nextInnerPage}
            prevInnerPage={prevInnerPage}
            handleBack={handleBack}
            setGstNumber={setGstNumber}
            firmName={firmName}
            setFirmname={setFirmname}
          />
        ) : (
          <InnerPageTwo
            setPage={setPage}
            handleBack={handleBack}
            handleSaveAndNext={handleSaveAndNext}
            activeTab={activeTab}
            navItems={navItems}
            handleTabClick={handleTabClick}
            innerNext={innerNext}
            prevInnerPage={prevInnerPage} // Pass prevInnerPage function
            gstNumber={gstNumber}
            mediaDetails={props.mediaDetails}
            mediaID={mediaDetails?.media_personal_details_id}
            firmName={firmName}
          />
        )}
      </div>
    </>
  );
}

export default Stepthree;
