import React from 'react'

function Steptwoprice() {
  return (
    <>
    <section className='step2'>
step2
    </section>
    </>
  )
}

export default Steptwoprice