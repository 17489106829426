import React, { useContext } from "react";
import { useState, useEffect } from "react";
import Empower_banner from "./banner/Empower_banner";
import All_product_count_list from "./all-product-count/All_product_count_list";
import "./main-dashboard.css";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { allProductCounter, allBarChart, allBarCategory } from "../../../../../utils/apis/seller/BTBdashborad";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,

  Cell,
} from "recharts";
const Maindashboard = () => {

  const [bardata, setBarData] = useState([]);
  const GetAllBarChart = async () => {
    const response = await allBarChart();
    await setBarData(response?.data);
  };

  const [categorydata, setCategoryData] = useState([]);
  const GetAllCategoryChart = async () => {
    const response = await allBarCategory();
    await setCategoryData(response?.data);
  };

  useEffect(() => {
    GetAllCategoryChart();
    GetAllBarChart();
  }, []);

  const colors = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8042",
    "#00C49F",
    "#FFBB28",
  ];

  console.log(categorydata, "categorydata?.data");

  return (
    <>
      <Empower_banner />
      <All_product_count_list />

      <section className="chartt-main-div">
        <div className="row">
          <div className=" col-xxl-6 col-xl-12 col-lg-12 mt-xl-0 my-5">
            <div className="innerbox">
              <h6 className="box-name">Earning overview</h6>
              <div>
                {/* <LineChart
                  xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                  series={[
                    {
                      data: [2, 5.5, 2, 8.5, 1.5, 5],
                    },
                  ]}
                  width={500}
                  height={300}
                /> */}

                {/* <div className="revenue-chart">
                  <h2>Total Revenue</h2> */}
                <BarChart
                  width={480}
                  height={300}
                  data={bardata}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value">
                    {bardata?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colors[index % colors.length]}
                      />
                    ))}
                  </Bar>
                </BarChart>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-12  col-lg-12 mt-xl-0 mt-lg-0 mt-md-5 my-5">
            <div className="innerbox">
              <h6 className="box-name">Sales By Category</h6>
              <div>
              {categorydata?.length > 0 && (


                <PieChart
                  series={[
                    {
                      data: categorydata.length > 0
                        ? categorydata
                        : [{ label: 'No sales Data', value: 100 }] // Fallback data when categorydata is empty
                    },
                  ]}
                  width={400}
                  height={200}
                />
              )}
              </div>
            </div>
          </div>
          {/* <div className="col-xxl-6 col-xl-12 col-lg-12 mt-xl-0 mt-md-5 my-5">
            <div className="innerbox">
              <h6 className="box-name">My Messages</h6>
              <hr></hr>

              <div className="message-overflow">
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Maindashboard;
