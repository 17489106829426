// import React, { useState } from "react";
// import InputGroup from "react-bootstrap/InputGroup";
// import Form from "react-bootstrap/Form";
// // import Header from "../../Header/Header";
// import Col from "react-bootstrap/Col";
// import Nav from "react-bootstrap/Nav";
// import Row from "react-bootstrap/Row";
// // import Tab from "react-bootstrap/Tab";
// import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
// import "react-tabs-scrollable/dist/rts.css";
// import Brand from "../../InquiryMaster/Brand/Tables";
// import FAQ from "../../InquiryMaster/Faq/Table";
// import SubFaq from "../../InquiryMaster/SubFaq/Table";
// // import WorkingType from "../InquiryMaster/WorkingType/Table";
// import Category from "../../InquiryMaster/Category/Table";
// import SubCategory from "../../InquiryMaster/SubCategory/Table";
// import ChildCategory from "../../InquiryMaster/ChildCategory/Table";
// import ChildSubCategory from "../../InquiryMaster/ChildSubCategory/Table";
// import Blogs from "../../InquiryMaster/Blogs/Tables";
// // import CouponCodes from "../../InquiryMaster/CouponCodes/Table";
// // import TaxType from "../InquiryMaster/Tax_Types/Tables";
// // import TaxPercentage from "../InquiryMaster/Tax_Percentage/Tables";
// import WebHeader from "../../InquiryMaster/WebHeader/Tables";
// import AppSetup from "../../InquiryMaster/AppSetup/Tables";
// // import ContactUs from "../InquiryMaster/contact_us/Tables";
// import Users from "../../InquiryMaster/users/Tables";
// import CrazyDeals from "../../InquiryMaster/CrazyDeals/Table";
// import ExclusiveCollection from "../../InquiryMaster/ExclusiveCollection/Table";
// import Attribute from "../../InquiryMaster/Attribute/Table";
// import SubAttribute from "../../InquiryMaster/SubAttribute/Table";
// import AttributePackaging from "../../InquiryMaster/AttributePackaging/Table";
// import ShopTrends from "../../InquiryMaster/ShopTrends/Table";
// import HomePageBanner from "../../InquiryMaster/HomePageBanner/Table";
// import AboutUsBanner from "../../InquiryMaster/banner/Tables";
// import OurStory from "../../InquiryMaster/OurStory/Tables";
// import HappyCustomer from "../../InquiryMaster/HappyCustomer/Tables";
// import WhyChoose from "../../InquiryMaster/whyChoose/Tables";
// import PopularProduct from "../../InquiryMaster/PopularProduct/Table";
// import SaleBanner from "../../InquiryMaster/SaleBanner/Table";
// import CategoryBanner from "../../InquiryMaster/CategoryBanner/Table";
// import UserMotivation from "../../InquiryMaster/UserMotivation/Table";

// import SocialMedia from "../../InquiryMaster/SocialMedia/Table";

// import "./Setting.css";

// const Setting = () => {
//   const [filter, setFilter] = useState("");
//   const [active, setActive] = useState(1);

//   const handleFilterChange = (event) => {
//     setFilter(event.target.value.toLowerCase());
//   };

//   const tabsArray = [

//     { key: 0, name: "Category", link: "masters/category" },
//     { key: 1, name: "Sub Category", link: "masters/sub-category" },
//     { key: 2, name: "Child Category", link: "masters/child-category" },

//   ];

//   const tabContent = [
//     { key: 0, component: <Category /> },
//     { key: 1, component: <SubCategory /> },
//     { key: 2, component: <ChildCategory /> },
//   ];

//   const [activeTab, setActiveTab] = useState(0);
//   const onTabClick = (e, index) => {
//     console.log(e);
//     setActiveTab(index);
//   };
//   // const tabsArray = [...Array(20).keys()];

//   return (
//     <>
//       <Tabs activeTab={activeTab} onTabClick={onTabClick}>
//         {tabsArray.map((item, index) => (
//           <Tab key={item.eventKey} eventKey={item.key}>
//             {item.name}
//           </Tab>
//         ))}
//       </Tabs>

//       {tabContent.map((item) => (
//         <TabScreen key={item.key} activeTab={activeTab} index={item.key}>
//           {item.component}
//         </TabScreen> ))}
//     </>
//   );
// };

// export default Setting;

import React, { useContext, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
// import Tab from "react-bootstrap/Tab";
import "react-tabs-scrollable/dist/rts.css";
import Brand from "../../InquiryMaster/Brand/Tables";
import FAQ from "../../InquiryMaster/Faq/Table";
import SubFaq from "../../InquiryMaster/SubFaq/Table";
// import WorkingType from "../InquiryMaster/WorkingType/Table";
import Category from "../../InquiryMaster/Category/Table";
import SubCategory from "../../InquiryMaster/SubCategory/Table";
import ChildCategory from "../../InquiryMaster/ChildCategory/Table";
import ChildSubCategory from "../../InquiryMaster/ChildSubCategory/Table";
import Blogs from "../../InquiryMaster/Blogs/Tables";
// import CouponCodes from "../../InquiryMaster/CouponCodes/Table";
// import TaxType from "../InquiryMaster/Tax_Types/Tables";
// import TaxPercentage from "../InquiryMaster/Tax_Percentage/Tables";
import WebHeader from "../../InquiryMaster/WebHeader/Tables";
import AppSetup from "../../InquiryMaster/AppSetup/Tables";
// import ContactUs from "../InquiryMaster/contact_us/Tables";
import Users from "../../InquiryMaster/users/Tables";
import CrazyDeals from "../../InquiryMaster/CrazyDeals/Table";
import ExclusiveCollection from "../../InquiryMaster/ExclusiveCollection/Table";
import Attribute from "../../InquiryMaster/Attribute/Table";
import SubAttribute from "../../InquiryMaster/SubAttribute/Table";
import AttributePackaging from "../../InquiryMaster/AttributePackaging/Table";
import ShopTrends from "../../InquiryMaster/ShopTrends/Table";
import HomePageBanner from "../../InquiryMaster/HomePageBanner/Table";
import AboutUsBanner from "../../InquiryMaster/banner/Tables";
import OurStory from "../../InquiryMaster/OurStory/Tables";
import HappyCustomer from "../../InquiryMaster/HappyCustomer/Tables";
import WhyChoose from "../../InquiryMaster/whyChoose/Tables";
import PopularProduct from "../../InquiryMaster/PopularProduct/Table";
import SaleBanner from "../../InquiryMaster/SaleBanner/Table";
import CategoryBanner from "../../InquiryMaster/CategoryBanner/Table";
import UserMotivation from "../../InquiryMaster/UserMotivation/Table";

import SocialMedia from "../../InquiryMaster/SocialMedia/Table";

import "./Setting.css";
import {
  faCodeBranch,
  faTableCellsLarge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFigma } from "@fortawesome/free-brands-svg-icons";
import { OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import { Context } from "../../../../../../utils/context";

const Setting = () => {
  const [filter, setFilter] = useState("");
  const [activeTab, setActiveTab] = useState("0");

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const tabsArray = [
    {
      key: "0",
      name: (
        <>
          <FontAwesomeIcon icon={faTableCellsLarge} />
          <span className="name-text">Category</span>
        </>
      ),
      link: "masters/category",
      component: <Category />,
      tooltip: "Category",
    },
    {
      key: "1",
      name: (
        <>
          <FontAwesomeIcon icon={faCodeBranch} />
          <span className="name-text">Sub Category</span>
        </>
      ),
      link: "masters/sub-category",
      component: <SubCategory />,
      tooltip: "Sub Category",
    },
    {
      key: "2",
      name: (
        <>
          <FontAwesomeIcon icon={faFigma} />
          <span className="name-text">Child Category</span>
        </>
      ),
      link: "masters/child-category",
      component: <ChildCategory />,
      tooltip: "Child Category",
    },
  ];

  const renderTooltip = (props) => (
    <Tooltip {...props}>{props.tooltip}</Tooltip>
  );


  const closeSidebar = () => {
    setSidebar(true);

    setOuterSidebar(true);

    setInnerSidebar(false);
  };

  const {
    sidebar,
    setSidebar,
    outerSidebar,
    setOuterSidebar,
    innerSidebar,
    setInnerSidebar,
  } = useContext(Context);


  return (
    <>
      <div className="sidebar-tab-holder">
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeTab}
          onSelect={(e) => setActiveTab(e)}
        >
          <Row>
            <div
              // sm={1}
              className={`scale-up-hor-left ${!innerSidebar
                  ? "col-width-expand col-xxl-2 col-xl-3 col-md-3"
                  : " sidebar-adjust col-width col-xxl-1 col-xl-1 col-md-1"
                }`}

            // className={`  scale-up-hor-left ${
            //       sidebar ? "col-width-expand" : "col-width"
            //     }`}
            >
              <Nav variant="pills" className="flex-column">
                {tabsArray.map((item) => (
                  <OverlayTrigger
                    key={item.key}
                    placement="right"
                    delay={{ show: 200, hide: 0 }}
                    overlay={renderTooltip({ tooltip: item.tooltip })}
                  >
                    <Nav.Item onClick={closeSidebar}>
                      <Nav.Link eventKey={item.key}>{item.name}</Nav.Link>
                    </Nav.Item>
                  </OverlayTrigger>
                ))}
              </Nav>
            </div>
            <div
              // sm={11}
              className={`${!innerSidebar
                  ? "col2-width col-xxl-10 col-xl-9 col-md-9"
                  : "col2-width col-xxl-11 col-xl-11 col-md-11"
                }`}
            >
              {" "}
              <Tab.Content>
                {tabsArray.map((item) => (
                  <Tab.Pane eventKey={item.key} key={item.key}>
                    {item.component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </div>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};

export default Setting;
