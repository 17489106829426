import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form } from "react-bootstrap";
import classNames from "classnames";
import { ProductNarrationChange } from "../../../../../utils/common";
const ProductDescription = ({ register, useFieldArray, control, errors,setValue }) => {
  const { append, fields, remove } = useFieldArray({
    control,
    name: "description",
  });

  const validateWordCount = (value) => {
    const wordCount = value.length;
    return wordCount <= 40 || "Maximum 40 words are allowed";
  };

  const validateWordCountDescription = (value) => {
    const wordCount = value.length;
    return wordCount <= 500 || "Maximum 500 words are allowed";
  };

  return (
    <>
      <div className="row">
        <div className="select-to-div">
          <Form.Label>
            Product Description :- Name Maximum 40 words And Description Maximum
            500 words are allowed
          </Form.Label>

          {fields?.map((value, index) => (
            <>
              <div className="row" key={index}>
                <div
                  className="col-xxl-4 col-md-4 col-12 align-items-center d-flex mt-auto mb-2"
                  key={index}
                >
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    // value={value?.name}
                    className={classNames("", {
                      "is-invalid":
                        errors?.description &&
                        errors?.description[index] &&
                        errors?.description[index]?.name,
                    })}
                    name={`description[${index}].name`}
                    {...register(`description[${index}].name`, {
                      required: "Name is required",
                      validate: validateWordCount,
                    })}
                    onChange={async (e) => {
                      setValue(
                        `description[${index}].name`,
                        await ProductNarrationChange(e)
                      );
                    }}
                  />
                </div>
                <div className="col-xxl-8 col-md-8 col-12 align-items-center d-flex mt-auto mb-2">
                  <Form.Control
                    type="text"
                    // value={value?.description}
                    placeholder="Description"
                    className={classNames("me-2", {
                      "is-invalid":
                        errors?.description &&
                        errors?.description[index] &&
                        errors?.description[index]?.description,
                    })}
                    name={`description[${index}].description`}
                    {...register(`description[${index}].description`, {
                      required: "Description is required",
                      validate: validateWordCountDescription,
                    })}
                    onChange={async (e) => {
                      setValue(
                        `description[${index}].description`,
                        await ProductNarrationChange(e)
                      );
                    }}
                  />
                  {fields?.length !== 1 && (
                    <button
                      className="delet-row"
                      onClick={() => remove(index)}
                      type="button"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-minus" />
                    </button>
                  )}
                </div>
              </div>
            </>
          ))}
          {fields?.length < 20 && (
            <button
              className="addrowiccc"
              type="button"
              style={{
                float: "right",
              }}
              onClick={() =>
                append({
                  name: "",
                })
              }
            >
              <img
                style={{ margin: "0px" }}
                src={
                  process.env.PUBLIC_URL +
                  "/assest/images/Seller_panel/dashboard/icons/addicooo.png"
                }
              />
            </button>
          )}

          {/* <Controller
              name="w_term"
              {...register("w_term", {
                required: "Warranty Terms is required",
              })}
              control={control}
              render={({ field }) => (
                <JoditEditor
                  value={content}
                  tabIndex={1}
                  onBlur={(newContent) => setContent(newContent)}
                  {...field}
                />
              )}
            /> */}
        </div>
      </div>
    </>
  );
};

export default ProductDescription;
