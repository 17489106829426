import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { library, parse } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";

import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./ViewOffCanvance.css";
import toast, { Toaster } from "react-hot-toast";
import { type } from "@testing-library/user-event/dist/type";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { data } from "jquery";
import { useForm } from "react-hook-form";
import B2bSellerDetail from "./B2bSellerDetail/B2bSellerDetail";
import B2bsupplierCard from "./B2bSupplierCard/B2bsupplierCard";
import B2bFrequentlyCard from "./B2bFrequentlyCard/B2bFrequentlyCard";
import B2bSellerProduct from "./B2bSellerProduct/B2bSellerProduct";
import { Context } from "../../../../../../../../utils/context";

library.add(fas);

const ViewOffCanvance = (props) => {
  // console?.log(props?.id);

  const product_id = props?.id;
  const { getData, IMG_URL } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [images, setImages] = useState([]);

  const [singleVariant, setSingleVariant] = useState(false);
  const [MultipleVariantTwo, setMultipleVariantTwo] = useState(false);
  const [MultipleVariantThree, setMultipleVariantThree] = useState(false);

  const [data, setData] = useState({});
  const [FrequentlyCard, setFrequentlyCard] = useState([]);
  const [supplierProducts, setsupplierProducts] = useState([]);
  const getDataDetails = async () => {
    try {
      reset();
      setSingleVariant(false);
      setMultipleVariantTwo(false);
      setMultipleVariantThree(false);
      setImages([]);

      const res = await getData(
        `/seller/seller-btb-product/all-products/live-preview/${props?.id}`
      );
      // const res = await getMainProductDetails(product_id);
      // const subcategoryproducts = await getProducts(
      //   false,
      //   res?.data?.s_sub_category_id
      // );
      // setFrequentlyCard(subcategoryproducts?.data);

      // const suppiler = await getSupplierProducts(res?.data?.s_supplyer_id);
      // setsupplierProducts(suppiler?.data);
      setValue("seller_id", res?.data?.s_supplyer_id);
      setValue("product_id", res?.data?.id);
      setData(res?.data);
      setValue("product_title", res?.data?.product_title);
      setValue(
        "customization",
        res?.data?.s_product_customization?.s_product_type?.name
      );
      setValue(
        "s_brand",
        res?.data?.supply_type == "Custom" ||
          res?.data?.supply_type == "Self Manufactured"
          ? res?.data?.s_products_supply_type?.s_custom?.name
          : res?.data?.s_brand?.name
      );
      if (res?.data?.s_product_variant?.variant_type === "Single") {
        // setData(res?.data);
        // setValue("product_title", res?.data?.product_title);
        // setValue(
        //   "customization",
        //   res?.data?.s_product_customization?.s_customization?.name
        // );
        setValue(
          "variant",
          res?.data?.s_product_variant?.s_product_variant_attribute?.s_attribute
            ?.name
        );
        setValue(
          "variant_details",
          res?.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details
        );

        setValue(
          "s_p_t_descriptions",
          res?.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details[0]?.s_p_t_descriptions
        );

        setValue(
          "sku_id",
          res?.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.sku_id
        );
        setValue(
          "mrp",
          res?.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
            ?.mrp
        );

        setValue(
          "selling_price",
          res?.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
            ?.selling_price
        );

        setValue(
          "minimum_order_quantity",
          res?.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
            ?.minimum_order_quantity
        );
        setSingleVariant(true);
        setImages(
          res?.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_images
        );
      } else if (res?.data?.s_product_variant?.variant_type === "Multiple") {
        if (
          res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
            ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
            ?.s_p_v_a_one_d_a_d_as?.length == 0
        ) {
          // setData(res?.data);
          // setValue("product_title", res?.data?.product_title);
          // setValue(
          //   "customization",
          //   res?.data?.s_product_customization?.s_customization?.name
          // );
          setValue(
            "variant",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
          );
          setValue(
            "variant_details",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
          );

          setValue(
            "variant_one",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
          );

          setValue(
            "variant_one_details",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
          );

          setValue(
            "s_p_t_descriptions",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds[0]?.s_p_t_descriptions
          );

          setValue(
            "sku_id",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.sku_id
          );
          setValue(
            "mrp",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
          );

          setValue(
            "selling_price",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
          );

          setValue(
            "minimum_order_quantity",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.minimum_order_quantity
          );
          setMultipleVariantTwo(true);
          setImages(
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_a_d_images
          );
        } else {
          // setData(res?.data);
          // setValue("product_title", res?.data?.product_title);
          // setValue(
          //   "customization",
          //   res?.data?.s_product_customization?.s_customization?.name
          // );
          setValue(
            "variant",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
          );
          setValue(
            "variant_details",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
          );

          setValue(
            "variant_one",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
          );

          setValue(
            "variant_one_details",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
          );

          setValue(
            "variant_two",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
          );

          setValue(
            "variant_two_details",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds
          );

          setValue(
            "s_p_t_descriptions",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds[0]
              ?.s_p_t_descriptions
          );

          setValue(
            "sku_id",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
          );
          setValue(
            "mrp",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
          );

          setValue(
            "selling_price",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
          );

          setValue(
            "minimum_order_quantity",
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.minimum_order_quantity
          );

          setImages(
            res?.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_a_d_images
          );
          setMultipleVariantThree(true);
        }
      }
    } catch (error) {
      console?.log(error);
    }
  };

  const onChangeVariant = async (data, type = "Single", level = "1") => {
    if (type === "Single" && level === "1") {
      setValue("sku_id", data?.sku_id);
      setValue("mrp", data?.s_p_v_a_d_price_a_stock?.mrp);
      setValue("selling_price", data?.s_p_v_a_d_price_a_stock?.selling_price);
      setValue("s_p_t_descriptions", data?.s_p_t_descriptions);
    } else if (type === "Multiple" && level === "2") {
      setValue("sku_id", data?.sku_id);
      setValue("s_p_t_descriptions", data?.s_p_t_descriptions);
      setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
      setValue(
        "selling_price",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
      );
    } else if (type === "Multiple" && level === "3") {
      setValue("sku_id", data?.sku_id);
      setValue("s_p_t_descriptions", data?.s_p_t_descriptions);
      setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
      setValue(
        "selling_price",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
      );
    }
  };

  useEffect(() => {
    if (product_id) {
      getDataDetails();
    }
  }, [props?.id]);

  return (
    <>
      <Offcanvas
        show={props?.show}
        style={{ width: "80%", height: "auto" }}
        placement={"end"}
        onHide={props?.handleClose}
        className="offcan"
      >
        <Container className="tabss-main">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>
                  <h3>Product Details </h3>
                </Card.Title>

                <B2bSellerDetail
                  data={data}
                  getValues={getValues}
                  onChangeVariant={onChangeVariant}
                  singleVariant={singleVariant}
                  MultipleVariantTwo={MultipleVariantTwo}
                  MultipleVariantThree={MultipleVariantThree}
                  IMG_URL={IMG_URL}
                  images={images}
                  setImages={setImages}
                  setValue={setValue}
                />
                <B2bSellerProduct
                  data={data}
                  // singleVariant={singleVariant}
                  IMG_URL={IMG_URL}
                  images={images}
                  getValues={getValues}
                  setImages={setImages}
                />
                {/* <B2bFrequentlyCard
                  headingName="Frequently bought together"
                  FrequentlyCard={FrequentlyCard}
                  IMG_URL={IMG_URL}
                /> */}
                {/* <B2bsupplierCard
                  FrequentlyCard={supplierProducts}
                  IMG_URL={IMG_URL}
                  headingName="Supplier's popular products"
                /> */}
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Container>
      </Offcanvas>
    </>
  );
};

export default ViewOffCanvance;
