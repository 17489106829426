import React, { useState, useContext, useEffect } from "react";
import "./Header_affilate_dashboard.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Notification_offcanvas from "../common-components/offcanvas/notification-offcanvas/Notification_offcanvas";
import Logout_modal from "../../../CommonForAll/logout-modal/Logout_modal";

import { Context } from "../../../../utils/context";
import { useNavigate } from "react-router-dom";

const Header_affilate_dashboard = () => {


  const navigate = useNavigate();
  const { userdata,usertype,signin } = useContext(Context);

  const [notShow, setNotShow] = useState(false);

  const [smShow, setSmShow] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [nottificationshow, setNotificationShow] = useState(false);
  const [logoutshow, setLogoutShow] = useState(false);
  
  const [copySuccess, setCopySuccess] = useState(false);


  useEffect(() => {
    console.log(usertype);

    if (signin) {
      if (usertype !== "Affiliate") {
        if (usertype === 'Seller') {
          navigate('/seller/seller-dashboard');
        } else {
          navigate('/affiliate/affiliatelogin');
        }
      }
    }


  }, [userdata]);


  const handleCopy = () => {
    navigator.clipboard.writeText(userdata?.af_id);
    setCopySuccess(true);  
    setTimeout(() => {
      setCopySuccess(false); 
    }, 2000);
  };

  return (
    <>
      <section className="Header_affilate_dashboard container-fluid p-0">
        <div className=" ">
          <div className="row me-0">
          

            <div className="col-md-6">
              <div className="first-col">
                <div className="">
                  <div className="search-notification">
                    <div className="search-bar">
                      <Form inline>
                        <InputGroup>
                          <InputGroup.Text id="basic-addon1">
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Type here to search"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </InputGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="sec_col">
                <div className="">
                  <div className="">
                    <div className="d-flex head-notti-flex justify-content-end">
                      <div className="space-bd">
                        <div className="noti-div">
                          <p>
                            Affiliate ID :<span>{userdata?.af_id}</span>
                          </p>
                        </div>
                      </div>
                      {/* <div className="space-bd">
                        <div className="noti-div">
                          <p>
                            Wallet Amount :<span> ₹ {userdata?.amount}</span>
                          </p>
                        </div>
                      </div> */}
                      <div className="space-bd">
                        <div className="noti-div">
                          <FontAwesomeIcon
                            className="nottibel-icco"
                            icon="fa-solid fa-copy"
                            style={{ cursor: "pointer" }} 
                            onClick={handleCopy}
                          /><br/>
                           {copySuccess && <span style={{color:"green",fontWeight:"600"}}>Copied to clipboard!</span>}
                        </div>
                      </div>
                      <div className="space-bd">
                        <div className="noti-div">
                          <FontAwesomeIcon
                            className="nottibel-icco"
                            icon="fa-solid fa-share-nodes"
                          />
                        </div>
                      </div>
                      <div className="space-bd">
                        <div
                          className="noti-div"
                          onClick={() => setNotificationShow(true)}
                        >
                          <FontAwesomeIcon
                            className="nottibel-icco"
                            icon="fa-solid fa-bell"
                          />
                          <span className="not-contt">7</span>
                        </div>

                        {/* <Notification_offcanvas
                          show={nottificationshow}
                          onHide={() => setNotificationShow(false)}
                        /> */}
                      </div>
                      <div className="space-bd2">
                        <div
                          className="noti-div "
                          onClick={() => setLogoutShow(true)}
                        >
                          <FontAwesomeIcon
                            className="nottibel-icco"
                            icon="fa-solid fa-right-from-bracket"
                          />
                        </div>

                        <Logout_modal
                          show={logoutshow}
                          onHide={() => setLogoutShow(false)}
                          title="Are you sure you want to logout?"
                          btnname="Logout"
                          userType={'affiliate'}
                        />

                        {/* <Logout_modal
                show={deleteshow}
                productId={productId}
                getAllProducts={props.getAllProducts}
                onHide={() => setdeleteShow(false)}
                title="Are you sure you want to delete this product from listing?"
                btnname="Delete"
              /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header_affilate_dashboard;
