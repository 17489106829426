import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./VeiwEditModal.css";
import ViewProductmodal from "../ViewProductmodal/ViewProductmodal";
import ViewBTBProductmodal from "../ViewBTBProductmodal/ViewProductmodal";
import EditProductModal from "../EditProductModal/EditProductModal";
import EditProductBTBModal from "../EditProductBTBModal/EditProductModal";
import Product_history_modal from "../product-history-modal/Product_history_modal";
import Logout_modal from "../logout-modal/Logout_modal";
import { useLocation } from "react-router-dom";
import { Paths } from "../../../utils/common";
const VeiwEditModal = (props) => {
  const location = useLocation();

  const [modalShownew, setModalShownew] = useState(false);
  const [modalShowprod, setModalShowprod] = useState(false);
  const [modalShownew1, setModalShownew1] = useState(false);

  const [deleteshow, setdeleteShow] = useState(false);
  const [productId, setProductId] = useState("");
  const [viewType, setType] = useState("view");

  console.log("modalShowprod", modalShowprod);

  console.log(props);
  useEffect(() => {
    setProductId(props.productId);
  }, [props.productId]);

  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ViewEditModal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Product Actions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="text-center">
                <button
                  type="button"
                  className="mb-2 viewProdbtn"
                  onClick={() => {
                    setType("view");
                    setModalShownew(true);
                  }}
                >
                  View Product
                </button>
              </div>
              {Paths.btb_seller_product == location.pathname ? (
                <ViewBTBProductmodal
                  show={modalShownew}
                  productId={productId}
                  onHide={() => setModalShownew(false)}
                />
              ) : (
                <ViewProductmodal
                  show={modalShownew}
                  productId={productId}
                  onHide={() => setModalShownew(false)}
                />
              )}
            </div>
            <div className="col-lg-6">
              <div className="text-center">
                {props.accept_or_reject != 1 && (
                  <button
                    type="button"
                    className="mb-3 viewProdbtn"
                    onClick={() => {
                      setType("edit");
                      setModalShowprod(true);
                    }}
                  >
                    Edit Product
                  </button>
                )}
              </div>

              {Paths.btb_seller_product == location.pathname ? (
                <EditProductBTBModal
                  show={modalShowprod}
                  productId={productId}
                  getAllProducts={props.getAllProducts}
                  viewType={viewType}
                  onHide={() => {
                    props.getAllProducts();
                    setModalShowprod(false);
                  }}
                />
              ) : (
                <EditProductModal
                  setModalShowedit={props?.setModalShowedit}
                  setModalShowprod={setModalShowprod}
                  show={modalShowprod}
                  productId={productId}
                  getAllProducts={props.getAllProducts}
                  viewType={viewType}
                  onHide={() => {
                    props.getAllProducts();
                    setModalShowprod(false);
                  }}
                />
              )}
            </div>
            <div className="col-lg-6">
              <div className="text-center">
                <button
                  type="button"
                  className="mb-3 viewProdbtn"
                  onClick={() => setModalShownew1(true)}
                >
                  Product History
                </button>
              </div>

              <Product_history_modal
                show={modalShownew1}
                varientType={props.varientType}
                productId={productId}
                onHide={() => setModalShownew1(false)}
              />
            </div>
            <div className="col-lg-6">
              <div className="text-center">
                <button
                  type="button"
                  className="mb-3 viewProdbtn"
                  onClick={() => setdeleteShow(true)}
                >
                  Delete
                </button>
              </div>
              <Logout_modal
                setModalShowedit={props?.setModalShowedit}
                show={deleteshow}
                productId={productId}
                getAllProducts={props.getAllProducts}
                onHide={() => setdeleteShow(false)}
                title="Are you sure you want to delete this product from listing?"
                btnname="Delete"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VeiwEditModal;
