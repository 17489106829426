import React from "react";
import "./PortfolioCart.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";

const PortfolioCart = () => {
  return (
    <>
      <section className="PortfolioCart">
        <Container>
          <h4 className="maintenance-title">Your Shopping Cart</h4>
          <Row className="justify-content-center">
            <Col xxl={8}>
              <div className="Cart">
                <div className="content-cart">
                  <h4 className="maintenance-title">
                    Your shopping cart is Empty
                  </h4>
                  <NavLink to="./portfolio-content">
                    <div className=" KeepBrowsing">
                      <Button variant="Browsing" className="maintenance-text">
                        Keep Browsing
                      </Button>
                    </div>
                  </NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PortfolioCart;
