import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./TermsAndConditionModal.css";
const TermsAndConditionModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="TermsModal"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="modalTitle"
            id="contained-modal-title-vcenter"
          >
            Terms & Condition & Privacy policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalTitle">
            <h4>Terms & Condition</h4>
          </div>
          <div className="termsList">
            <ol start={1}>
              <li>
                Acceptance of Terms: By accessing or using the Netpurti Seller
                Panel, you agree to abide by these Terms & Conditions.
              </li>
              <li>
                Account Registration: You must register for an account to access
                the Netpurti Seller Panel. You are responsible for maintaining
                the confidentiality of your account information and for all
                activities that occur under your account.
              </li>
              <li>
                Use of the Seller Panel: The Seller Panel is intended for use by
                authorized sellers only. You agree to use the Seller Panel only
                for lawful purposes and in compliance with all applicable laws
                and regulations.
              </li>
              <li>
                Prohibited Activities: You agree not to engage in any activities
                that may disrupt or interfere with the operation of the Seller
                Panel or that may harm Netpurti or its users.
              </li>
              <li>
                Intellectual Property: All content and materials available on
                the Seller Panel are the property of Netpurti or its licensors
                and are protected by intellectual property laws. You may not use
                any content from the Seller Panel without the express permission
                of Netpurti.
              </li>
              <li>
                Limitation of Liability: Netpurti is not liable for any damages
                or losses arising out of your use of the Seller Panel, including
                but not limited to direct, indirect, incidental, consequential,
                or punitive damages.
              </li>
            </ol>
          </div>

          <div className="modalTitle">
            <h4>Privacy Policy</h4>
          </div>

          <div className="termsList">
            <ol start={1}>
              <li>
                Acceptance of Terms: By accessing or using the Netpurti Seller
                Panel, you agree to abide by these Terms & Conditions.
              </li>
              <li>
                Account Registration: You must register for an account to access
                the Netpurti Seller Panel. You are responsible for maintaining
                the confidentiality of your account information and for all
                activities that occur under your account.
              </li>
              <li>
                Use of the Seller Panel: The Seller Panel is intended for use by
                authorized sellers only. You agree to use the Seller Panel only
                for lawful purposes and in compliance with all applicable laws
                and regulations.
              </li>
              <li>
                Prohibited Activities: You agree not to engage in any activities
                that may disrupt or interfere with the operation of the Seller
                Panel or that may harm Netpurti or its users.
              </li>
              <li>
                Intellectual Property: All content and materials available on
                the Seller Panel are the property of Netpurti or its licensors
                and are protected by intellectual property laws. You may not use
                any content from the Seller Panel without the express permission
                of Netpurti.
              </li>
              <li>
                Limitation of Liability: Netpurti is not liable for any damages
                or losses arising out of your use of the Seller Panel, including
                but not limited to direct, indirect, incidental, consequential,
                or punitive damages.
              </li>
            </ol>
          </div>
        </Modal.Body>

        <div className="text-center mb-5">
          <Button
            onClick={() => {
              props.setTermStatus(true);
              props.onHide();
            }}
          >
            I Agree
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TermsAndConditionModal;
