import React from 'react'
import './HomeReasonImage.css'

const HomeReasonImage = () => {
  return (
    <section className='reson-page'>
      <div className='reson-main-img'>
        <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/HomePage/Reason/reason.png"} className="reason-img" alt="..." />
        <div className='black-overlay'></div>
        <div className='txt-card-overlay'>
          <h2>Reasons behind my preference for Netpurti over WordPress for my clients</h2>
          <p>Netpurti streamlines website ownership by bundling hosting, editing, and security updates into one easy package. Unlike WordPress, which can be complex to maintain, Netpurti simplifies the process for all users, regardless of technical expertise.</p>
        </div>
      </div>
    </section>
  )
}

export default HomeReasonImage