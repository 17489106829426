import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";



export const getSocial = async (data) => {
  try {

    const res = await getData(`/affiliate/affiliate-task/influncer/social-link`);

    return res;
  } catch (error) {
    console.log(error); 
  }
};

export const getInfluencerDataApi = async (data) => {
  try {

    const res = await getData(`/affiliate/affiliate-task/influncer/data`);

    return res;
  } catch (error) {
    console.log(error); 
  }
};

export const getInfluencerAddress = async (data) => {
  try {

    const res = await getData(`/affiliate/affiliate-task/influncer/address`);

    return res;
  } catch (error) {
    console.log(error); 
  }
};

export const SocialUrlPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-task/influncer/social-link`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const visitePlacePost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-task/influncer/visite-place`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const OpenInfluencerPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-task/influncer/open-influencer`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const InfluencerLanguagePost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-task/influncer/languages`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const InfluencerCountryPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-task/influncer/country`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const InfluencerCurrencyPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-task/influncer/currency`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const InfluencerCategoryPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-task/influncer/category`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const InfluencerSubCategoryPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-task/influncer/subcategory`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const InfluencerChildCategoryPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-task/influncer/childcategory`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSocialUrl = async (data) => {
  try {

    const res = await getData(`/affiliate/affiliate-create/affiliate-social-url/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};


