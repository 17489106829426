import React, { useEffect, useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import classNames from "classnames";
import { Tab, Nav } from "react-bootstrap";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";

import Select from "react-select";

import {
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../../../utils/apis/master/Master";
import { Errors, Placeholders } from "../../../../../../utils/errors";
import {
  firmAddressDetailPost,
  getfirmAddressDetail,
} from "../../../../../../utils/apis/brand/Brand";
import { Context } from "../../../../../../utils/context";

function FirmAddressDetails({ userID, setSuccessModalShow, setAddressId }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [firmAddressTypes, setFirmAddressType] = useState([]);
  const [cities, setCities] = useState("");
  const [states, setStates] = useState("");
  const [countries, setCountries] = useState("");
  const [pincodes, setPincodes] = useState([]);
  const getMasters = async () => {
    {
      const res = await firmAddressType();
      if (res?.success) {
        setFirmAddressType(res.data);
      }
    }
  };

  const handlePincodeChange = async (e) => {
    const res = await Pincode(e);
    if (res?.success) {
      const data = await Select2Data(res.data, "pincode_id", false);
      setPincodes(data);
    }
  };
  const handlePincodeSelect = async (id) => {
    const res = await pincodeWiseAll(id);
    if (res?.success) {
      console.log(res);
      setCities(res.data.city.name);
      setStates(res.data.state.name);
      setCountries(res.data.country.name);
      reset({
        city_id: res.data.city_id,
        state_id: res.data.state_id,
        country_id: res.data.country_id,
        pincode_id: {
          value: res.data.id,
          label: res.data.name,
        },
      });
    }
  };
  // s_personal_details_id: req.body.s_personal_details_id,

  const onSubmit = async (data) => {
    console.log(data, "form");
    data.pincode_id = data?.pincode_id.value;
    data.b_personal_details_id = userID;
    const res = await firmAddressDetailPost(data);
    if (res?.success) {
      console.log(res, "addresspost");
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
        getfirmAddressDetailIn();
      }, 2000);
    }
  };

  const getfirmAddressDetailIn = async () => {
    const res = await getfirmAddressDetail(userID);
    if (res?.success) {
      //   setFirmAddressId(res.data.id);
      console.log(res);
      setAddressId(res.data?.id);
      setCities(res.data.city?.name);
      setStates(res.data.state?.name);
      setCountries(res.data.country?.name);
      reset(res.data);
    }
  };

  useEffect(() => {
    getMasters();
    getfirmAddressDetailIn();
  }, []);
  return (
    <>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="Home_address"
        activeKey="Home_address"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="row me-0 ms-0">
            <div className="col-md-12">
              <div className="nav-tab-holder">
                <h6>Address Type</h6>
                <Nav variant="radio">
                  {firmAddressTypes?.map((item, index) => (
                    <Form.Check
                      type="radio"
                      key={index}
                      id={index}
                      name="addressType"
                      label={item?.name}
                      value={item?.id}
                      defaultChecked={getValues('s_firm_address_type_id') === item?.id}
                      {...register("s_firm_address_type_id", { required: Errors.addressType })}
                    />
                  ))}
                </Nav>
              </div>
            </div>
          </div> */}

          <Tab.Content className="details-form-holder">
            <Tab.Pane eventKey="Home_address" key="Home_address">
              <div className="stepFourMain">
                <div className="form-container">
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group controlId="Education_Level">
                        <Form.Label className="">
                          Enter name of address
                        </Form.Label>

                        <Form.Control
                          type="text"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          name="name"
                          placeholder={Placeholders.name}
                          {...register("name", { required: Errors.name })}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group controlId="Education_Level">
                        <Form.Label className="">Address line 1</Form.Label>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.add_line_one,
                          })}
                          type="text"
                          name="add_line_one"
                          placeholder={Placeholders.address_line_one}
                          {...register("add_line_one", {
                            required: Errors.address_line_one,
                          })}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="Awarded_Year">
                        <Form.Label className="">Address line 2</Form.Label>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.add_line_two,
                          })}
                          type="text"
                          name="add_line_two"
                          placeholder={Placeholders.address_line_two}
                          {...register("add_line_two", {
                            required: Errors.address_line_two,
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group controlId="Authourity">
                        <Form.Label className="">City/District:</Form.Label>

                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.city,
                          })}
                          type="text"
                          name="city_id"
                          readOnly
                          value={cities}
                          placeholder={Placeholders.city}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="Degree_Name:">
                        <Form.Label className="">State</Form.Label>

                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.state,
                          })}
                          type="text"
                          name="state_id"
                          value={states}
                          placeholder={Placeholders.state}
                          readOnly
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="Degree_Name:">
                        <Form.Label className="">Pincode</Form.Label>
                        <Controller
                          name="pincode_id"
                          {...register("pincode_id", {
                            required: Errors.pincode,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.pincode_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={pincodes}
                              onInputChange={(selectedOption) => {
                                handlePincodeChange(selectedOption);
                              }}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption.value);
                                handlePincodeSelect(selectedOption.value);
                              }}
                            />
                          )}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="Degree_Name:">
                        <Form.Label className="">Country</Form.Label>

                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.country,
                          })}
                          type="text"
                          name="country_id"
                          readOnly
                          value={countries}
                          placeholder={Placeholders.country}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="text-end">
                    <Button type="submit" className="btn StepfoursaveBtn">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Form>
      </Tab.Container>
    </>
  );
}

export default FirmAddressDetails;
