import React from "react";
import "./advertisement_add_task_modal.css";
import { useState, useContext, useEffect } from "react";
import "../selldash-commonmodal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../../../utils/context";
import JoditEditor from "jodit-react";
import SuccessModal from "../../../../../CommonForAll/SuccessModal/SuccessModal";
import { affiliateProductGet } from "../../../../../../utils/apis/affiliate/InHouseTask/Task/task";

const Affiliate_add_task_modal = (props) => {
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [successModalShow, setSuccessModalShow] = useState(false);

  const dateObject = new Date(startDate);
  const formattedDate = dateObject.toLocaleDateString("en-IN", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const { getData, postData, IMG_URL, Select2Data, Id, usertype, Select3Data, BTB_URL,
    BTC_URL,sellerDomain   } =
    useContext(Context);
  const [product, setProducts] = useState("");
  const [productData, setProductsData] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (d) => {
    console.log(d);
    const data = {
      domain_id: sellerDomain?.value,
      insentive: d.insentive,
      product_id: d.productId?.value,
      product_type: d.product_type?.value,
      product_available: d.review_status?.value,
      validity: d.validity,
      highlight_point: d.highlight_point,
      instruction: d.instruction,
      task_type: "affiliate",
      website_url:
        d.product_type?.value === "BTB"
          ? BTB_URL + d.productId?.value
          : BTC_URL + d.productId?.value,
    };
    const res = await postData("/seller/seller-task/task", data);
    if (res?.success) {
      props.onHide();
      reset();
      setProductsData("");
      setSuccessModalShow(true);
      props.getAllProducts();
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  };

  const getMasters = async (type) => {
    setValue("productId", "");
    const res = await affiliateProductGet(type);

    if (res?.success) {
      const data = await Select3Data(res.data, "productId", false);
      await setProducts(data);
    }
  };
  const handlePincodeChange = async (product_id) => {
    const res = await getData(
      `/seller/seller-task/task/products/${product_id.value}`
    );
    if (res?.success) {
      setProductsData(res?.data);
    }
  };
  const options = [
    { value: "1", label: "Yes" },
    { value: "0", label: "NO" },
  ];

  const typeoptions = [
    { value: "BTB", label: "B2B" },
    { value: "BTC", label: "B2C" },
    // {value:'Website' , label:'Website'},
  ];

  console.log(errors, "llll");
  useEffect(() => {
    // getMasters();
  }, []);
  return (
    <>
      <section className="">
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="selldash-commonmodal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Task
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-xxl-9 col-xl-9 col-lg-12">
                <div className="form-sec">
                  <Form>
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label>Choose Product Type</Form.Label>
                          <Controller
                            name="product_type"
                            {...register("product_type", {
                              // required: "Product Type Is Required",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.product_type
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={typeoptions}
                                onChange={async (option) => {
                                  await getMasters(option?.value);
                                  setValue("product_type", option);
                                }}
                                placeholder="--- please select ---"
                              />
                            )}
                          />
                          {errors?.product_type && (
                            <span className="text text-danger">
                              {errors.product_type.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Choose Product</Form.Label>
                          <Controller
                            name="productId"
                            {...register("productId", {
                              required: "Product Is Required",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.productId
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={product}
                                onChange={(selectoption) => {
                                  handlePincodeChange(selectoption);
                                  setValue("productId", selectoption);
                                }}
                                placeholder="--- please select ---"
                              />
                            )}
                          />
                          {errors?.productId && (
                            <span className="text text-danger">
                              {errors.productId.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Amount per product selling insentive
                          </Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.insentive,
                            })}
                            type="number"
                            {...register("insentive", {
                              required: "Insentive Amount Is Required",
                            })}
                            placeholder="Enter Amount"
                          />
                          {errors?.insentive && (
                            <span className="text text-danger">
                              {errors.insentive.message}
                            </span>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Product available for review</Form.Label>
                          <Controller
                            name="review_status"
                            {...register("review_status", {
                              required: "Available for review is required",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.review_status
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={options}
                                placeholder="--- Please select ---"
                              />
                            )}
                          />
                          {errors?.review_status && (
                            <span className="text text-danger">
                              {errors.review_status.message}
                            </span>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Validity Days</Form.Label>
                          <div>
                            <Form.Control
                              type="number"
                              className={classNames("", {
                                "is-invalid": errors?.validity,
                              })}
                              {...register("validity", {
                                required: "Validity Days is required",
                              })}
                              placeholder="Enter Validity Days"
                            />
                            {/* <DatePicker
                                                            {...register("validity",{
                                                                required:"validity"
                                                            })}
                                                            selected={startDate}
                                                            onChange={(date) => setStartDate(date)}
                                                            name="start_date"
                                                            id="start_date"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            minDate={new Date()}
                                                            placeholderText="MM/DD/YYYY"
                                                        /> */}
                          </div>
                          {errors?.validity && (
                            <span className="text text-danger">
                              {errors.validity.message}
                            </span>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-xxl-8 col-xl-8 col-lg-6">
                        <div className="row">
                          <div className="col-xxl-4 col-xl-4 col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Brand Name{" "}
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>
                                  {productData.s_brand?.name}
                                </Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Type{" "}
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>{productData.added_by}</Form.Label>
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-lg-4 col-sm-6">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Industries
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>
                                  {productData.s_category?.name}
                                </Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Product Review
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>4.3</Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Cost
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>500</Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Discount
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>5%</Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row">
                          <div className=" col-xxl-8 col-xl-8  col-lg-12  col-sm-6">
                            <Form.Group className="mb-1">
                              <div>
                                <Form.Label>
                                  Highlit Point
                                  {/* <span className='labelsbtext'>(Non editable)</span> */}
                                </Form.Label>
                              </div>
                            </Form.Group>
                            <div className="ullistt">
                              {/* <ul>
                                                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                                            </ul> */}
                              <Controller
                                name="highlight" // Provide the field name
                                control={control} // Pass the control object from useForm()
                                rules={{ required: "Highlight is required" }} // Validation rules
                                render={({ field }) => (
                                  <JoditEditor
                                    value={field.value}
                                    onChange={(newContent) =>
                                      field.onChange(newContent)
                                    }
                                    onBlur={field.onBlur}
                                    className={classNames("", {
                                      "is-invalid": !errors.highlight,
                                    })}
                                    placeholder="highlight"
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-12  col-sm-6">
                            <Form.Group className="mb-1">
                              <div>
                                <Form.Label>
                                  Image{" "}
                                  <span className="labelsbtext">
                                    (Non editable)
                                  </span>
                                </Form.Label>
                              </div>
                              <div className="">
                                <img
                                  className="bjvbhbvh-img"
                                  src={IMG_URL + productData.s_brand?.image}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-end">
                      {/* <button className='btn closebtn'>Close</button> */}
                      <button
                        className="btn sbmitbtn"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-12">
                <Form.Group className="mb-1">
                  <div>
                    <Form.Label>Instructions</Form.Label>
                  </div>
                </Form.Group>
                <div className="ullistt bg">
                  {/* <ul>
                                        <li>Lorem ipsum dolor sit amet, consectetur</li>
                                        <li>Lorem ipsum dolor sit amet, consectetur</li>
                                        <li>Lorem ipsum dolor sit amet, consectetur</li>
                                        <li>Lorem ipsum dolor sit amet, consectetur</li>
                                        <li>Lorem ipsum dolor sit amet, consectetur</li>
                                    </ul> */}
                  <Controller
                    name="instruction" // Provide the field name
                    control={control} // Pass the control object from useForm()
                    rules={{ required: "Instruction is required" }} // Validation rules
                    render={({ field }) => (
                      <JoditEditor
                        value={field.value}
                        onChange={(newContent) => field.onChange(newContent)}
                        onBlur={field.onBlur}
                        className={classNames("", {
                          "is-invalid": !errors.instruction,
                        })}
                        placeholder="instruction"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
                        <Button onClick={props.onHide}>Close</Button>
                    </Modal.Footer> */}
        </Modal>
        <SuccessModal show={successModalShow} />
      </section>
    </>
  );
};

export default Affiliate_add_task_modal;
