import React, {useContext, useEffect, useState} from "react";
import "./mrp-modal.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { RegxExpression } from "../../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";

const Task_modal = (props) => {

 


const {IMG_URL,formatDateExpire} = useContext(Context);

const [error, setError] = useState("");

  
  return (
    <>
      <Modal className='mrp-modal'
       {...props} 
       size="lg"
       centered >
        <Modal.Header closeButton onClick={props.onHide}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <h4>Task Details</h4>
            <hr/>
            <div className='col-sm-4'>
                <h6>Task Id</h6>
                <p>{props.showData?.task_id}</p>
            </div>
            <div className='col-sm-4'>
                <h6>Language</h6>
                <p>{props.showData?.language?.name}</p>
            </div>
            <div className='col-sm-4'>
                <h6>Platform</h6>
                <p>{props.showData?.a_social_detail?.platform}</p>
            </div>
            <div className='col-sm-4'>
                <h6>Validity</h6>
                <p>{formatDateExpire(props.showData?.createdAt,props.showData?.validity)}</p>
            </div>
            <div className='col-sm-4'>
                <h6>Product</h6>
                <p>{props.showData?.s_product?.product_title}</p>
            </div>
            <div className='col-sm-4'>
                <h6>Task Amount</h6>
                <p>{props.showData?.insentive}</p>
            </div>

            <h4>Visit Details</h4>
            <hr/>
            <div className='col-sm-4'>
                <h6>Task Id</h6>
                <p>{props.showData?.task_id}</p>
            </div>
            
          </div>

            
              
     
          <span className="text-danger">{error}</span>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Task_modal;
