import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import "./HomeBanner.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
const HomeBanner = () => {

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/home/s-banner`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <section className="home-banner">
      <div className="container">
        <div className="row bg-color">
          <div className="col-md-6">
            <div className="heading-holder">
              <h5>Empower Your Sales Journey with Our Seller Panel</h5>
            </div>
            <div className="button-holder ">
              <button className="btn sell-btn">Sell Online</button>
            </div>
          </div>
          <div className="col-md-6">
            {/* <div className="imge-holder text-center">

              <img
                className="bannn-img"
                src={
                  IMG_URL + data?.image
                  // process.env.PUBLIC_URL +
                  // "/assest/images/Seller_panel/seller-image/banner/online-fashion.png"
                }
              />
            </div> */}
            <div className="imge-holder text-center">
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((image, index) => (
                  <SwiperSlide>
                    <div className="imge-holder text-center">
                      <img key={image?.id} src={IMG_URL + image?.image} className='bannn-img' />
                    </div>

                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
