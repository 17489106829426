import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getWorkType = async () => {
  try {
    return await getData(`/without-login/masters/working-type`);
  } catch (error) {
    console.log(error);
  }
};

export const getCaution = async () => {
  try {
    return await getData(`/without-login/masters/all-caution`);
  } catch (error) {
    console.log(error);
  }
};

export const getPackagingAttributes = async () => {
  try {
    return await getData(`/without-login/masters/all-packaging-attributes`);
  } catch (error) {
    console.log(error);
  }
};

export const getCustomizeProductAttributes = async () => {
  try {
    return await getData(
      `/without-login/masters/all-customize-product-attributes`
    );
  } catch (error) {
    console.log(error);
  }
};

export const Currency = async () => {
  try {
    return await getData(`/without-login/masters/allcurrency`);
  } catch (error) {
    console.log(error);
  }
};

export const SocialPlatform = async () => {
  try {
    return await getData(`/without-login/masters/allsocialplatform`);
  } catch (error) {
    console.log(error);
  }
};

export const SocialLink = async (type) => {
  try {
    return await getData(`/without-login/masters/all-social-link?type=${type}`);
  } catch (error) {
    console.log(error);
  }
};

export const PrivacyPolicydata = async (type) => {
  try {
    return await getData(`/without-login/masters/all-privacy-policy?web_site_type=${type}`);
  } catch (error) {
    console.log(error);
  }
};


export const ShippingPolicydata = async (type) => {
  try {
    return await getData(`/without-login/masters/all-shipping-policy?web_site_type=${type}`);
  } catch (error) {
    console.log(error);
  }
};

export const otherPolicydata = async (type) => {
  try {
    return await getData(`/without-login/masters/all-other-policy?web_site_type=${type}`);
  } catch (error) {
    console.log(error);
  }
};



export const get_faqs = async (data) => {
  try {
    const res = await getData(`/btb/policy/faqs`);
    return res;
  } catch (error) {
    console.log(error);
  }
};


export const get_help_center_types = async (data) => {
  try {
    const res = await getData(`/btb/policy/help-center/${data}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const get_particular_policy = async (data) => {
  try {
    const res = await getData(`/without-login/masters/get-btb-policy/${data}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const get_info_page_api = async (data) => {
  try {
    const res = await getData(`/without-login/masters/get-info-page/${data}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};





export const ReturnPolicydata = async (type) => {
  try {
    return await getData(`/without-login/masters/all-return-policy?web_site_type=${type}`);
  } catch (error) {
    console.log(error);
  }
};
export const TermConditiondata = async (type) => {
  try {
    return await getData(`/without-login/masters/all-term-condition?web_site_type=${type}`);
  } catch (error) {
    console.log(error);
  }
};

export const Country = async () => {
  try {
    return await getData(`/without-login/masters/allcountry`);
  } catch (error) {
    console.log(error);
  }
};

export const singleCountry = async (country = "") => {
  try {
    let url = `/without-login/masters/singleCountry`;

    if (country !== "") {
      url += `?name=${country}`;
    }

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const State = async (id) => {
  console.log(id);
  try {
    return await getData(`/without-login/masters/allstates/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const AllState = async (id) => {
  console.log(id);
  try {
    return await getData(`/without-login/masters/allstates`);
  } catch (error) {
    console.log(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/without-login/masters/allcity/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Pincode = async (name) => {
  try {
    return await getData(`/without-login/masters/allpincode/${name}`);
  } catch (error) {
    console.log(error);
  }
};

export const allPincode = async () => {
  try {
    return await getData(`/without-login/masters/allpincode`);
  } catch (error) {
    console.log(error);
  }
};

export const pincodeWiseAll = async (id) => {
  try {
    return await getData(`/without-login/masters/all-pincode-details/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Category = async (id) => {
  try {
    return await getData(`/without-login/masters/allcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const AllCategory = async (seller_id, type, domain_id) => {
  console.log(domain_id, "domain_id");

  try {
    let url = `/without-login/masters/allcategory`;

    // if (seller_id) {
    //   url = url + "?seller_id=" + seller_id;
    // }

    if (domain_id) {
      url = url + "?domain_id=" + domain_id;
    }

    if (type) {
      url = url + "&type=" + type;
    }

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const AllSubCategory = async (seller_id, type, id, domain_id) => {
  try {
    let url = `/without-login/masters/allsubcategory/${id}`;

    if (seller_id) {
      url = url + "?seller_id=" + seller_id;
    }

    if (domain_id) {
      url = url + "?domain_id=" + domain_id;
    }

    if (type) {
      url = url + "&type=" + type;
    }

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const AllChildCategory = async (seller_id, type, id, domain_id) => {
  try {
    let url = `/without-login/masters/allchildcategory/${id}`;

    if (seller_id) {
      url = url + "?seller_id=" + seller_id;
    }

    if (domain_id) {
      url = url + "?domain_id=" + domain_id;
    }

    if (type) {
      url = url + "&type=" + type;
    }

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const AllFaq = async (domain_id, type) => {
  try {
    let url = `/seller/masters/allfaq`;

    if (domain_id) {
      url = url + "?domain_id=" + domain_id;
    }

    if (type) {
      url = url + "&type=" + type;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};
export const AllgetSubCategory = async (domain_id, type) => {
  try {
    let url = `/without-login/masters/allsubcategory`;

    if (domain_id) {
      url = url + "?domain_id=" + domain_id;
    }

    if (type) {
      url = url + "&type=" + type;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const AllProductDealType = async (seller_id, type) => {
  try {
    let url = `/seller/masters/product-deal-type`;

    if (seller_id) {
      url = url + "?seller_id=" + seller_id;
    }

    if (type) {
      url = url + "&type=" + type;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};
// export const AllProductDealType = async (seller_id) => {
//   try {
//     let url = `/seller/masters/product-deal-type`;

//     if (seller_id) {
//       url = url + "?seller_id=" + seller_id;
//     }
// <<<<<<< HEAD
// =======

//     if (type) {
//       url = url + "&type=" + type;
//     }

// >>>>>>> 30ed16d8697caecfb189519623d508f5b09e9ade
//     return await getData(url);
//   } catch (error) {
//     console.log(error);
//   }
// };

export const SubCategory = async (id) => {
  try {
    return await getData(`/without-login/masters/allsubcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const ChildCategory = async (id) => {
  try {
    return await getData(`/without-login/masters/allchildcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const ChildSubCategory = async (id) => {
  try {
    return await getData(`/without-login/masters/allchildsubcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const ProductAttributes = async (id, seller_id = null, type) => {
  try {
    let url = `/without-login/masters/all-product-attributes/${id}`;
    if (seller_id) {
      url = url + `?seller_id=${seller_id}`;
    }
    if (type) {
      url = url + "&type=" + type;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const AttributesPackaging = async (seller_id = null) => {
  try {
    let url = `/without-login/masters/all-attributes-packaging`;

    if (seller_id) {
      url = url + "?seller_id=" + seller_id;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const Attributes = async (seller_id = null, type) => {
  try {
    let url = `/without-login/masters/allattributes`;

    if (seller_id) {
      url = url + "?seller_id=" + seller_id;
    }
    if (type) {
      url = url + "&type=" + type;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const PlatformCharges = async () => {
  try {
    return await getData(`/without-login/masters/all-platform-charges`);
  } catch (error) {
    console.log(error);
  }
};

export const SubAttributes = async (id) => {
  try {
    return await getData(`/without-login/masters/all-s-sub-attributes/${id}`);
  } catch (error) {
    console.log(error);
  }
};
export const SubNewAttributes = async (id) => {
  try {
    return await getData(`/seller/home/s-sub-attributes/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const AffiliatedSector = async () => {
  try {
    return await getData(`/without-login/masters/all-s-affiliated-sector`);
  } catch (error) {
    console.log(error);
  }
};

export const CountryCode = async () => {
  try {
    return await getData(`/without-login/masters/allcountrycode`);
  } catch (error) {
    console.log(error);
  }
};

export const Area = async () => {
  try {
    return await getData(`/without-login/masters/all-s-area`);
  } catch (error) {
    console.log(error);
  }
};

export const Brand = async (seller_id = null, type, name = null) => {
  try {
    let url = `/without-login/masters/all-s-brand`;

    if (seller_id) {
      url = url + "?seller_id=" + seller_id;
    }

    if (type) {
      url = url + "&type=" + type;
    }
    if (name) {
      url = url + "&name=" + name;
    }

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const Custom = async () => {
  try {
    return await getData(`/without-login/masters/all-s-custom`);
  } catch (error) {
    console.log(error);
  }
};

export const Customization = async () => {
  try {
    return await getData(`/without-login/masters/all-s-customization`);
  } catch (error) {
    console.log(error);
  }
};

export const Days = async () => {
  try {
    return await getData(`/without-login/masters/all-s-days`);
  } catch (error) {
    console.log(error);
  }
};

export const FirmType = async () => {
  try {
    return await getData(`/without-login/masters/all-s-firm-type`);
  } catch (error) {
    console.log(error);
  }
};
export const IndustryType = async () => {
  try {
    return await getData(`/seller/masters/all-industry-category`);
  } catch (error) {
    console.log(error);
  }
};

export const IndustrySubCategory = async (id) => {
  try {
    return await getData(`/seller/masters/all-industry-sub-category/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const GST = async () => {
  try {
    return await getData(`/without-login/masters/all-s-gst`);
  } catch (error) {
    console.log(error);
  }
};

export const OrderStatus = async () => {
  try {
    return await getData(`/without-login/masters/all-s-order-status`);
  } catch (error) {
    console.log(error);
  }
};

export const BtbOrderStatus = async () => {
  try {
    return await getData(`/without-login/masters/all-btb-order-status`);
  } catch (error) {
    console.log(error);
  }
};

export const BtbProductTypes = async () => {
  try {
    return await getData(`/without-login/masters/all-btb-product-type`);
  } catch (error) {
    console.log(error);
  }
};

export const ProductInfo = async () => {
  try {
    return await getData(`/without-login/masters/all-s-product-info`);
  } catch (error) {
    console.log(error);
  }
};

export const ServiceTerm = async () => {
  try {
    return await getData(`/without-login/masters/all-s-service-term`);
  } catch (error) {
    console.log(error);
  }
};

export const ProductVideoSettings = async () => {
  try {
    return await getData(`/without-login/masters/all-product-video`);
  } catch (error) {
    console.log(error);
  }
};

export const ServiceType = async () => {
  try {
    return await getData(`/without-login/masters/all-s-service-type`);
  } catch (error) {
    console.log(error);
  }
};

export const ServiceesType = async () => {
  try {
    return await getData(`/seller/masters/all-s-service-type`);
  } catch (error) {
    console.log(error);
  }
};

export const shipmentType = async () => {
  try {
    return await getData(`/seller/masters/all-shipment`);
  } catch (error) {
    console.log(error);
  }
};

export const Unit = async () => {
  try {
    return await getData(`/without-login/masters/all-s-unit`);
  } catch (error) {
    console.log(error);
  }
};

export const VarientType = async () => {
  try {
    return await getData(`/without-login/masters/all-s-varient-type`);
  } catch (error) {
    console.log(error);
  }
};

export const firmAddressType = async () => {
  try {
    return await getData(`/without-login/masters/all-s-firm-address-type`);
  } catch (error) {
    console.log(error);
  }
};

export const getLanguageApi = async () => {
  try {
    return await getData(`/without-login/masters/all-languages`);
  } catch (error) {
    console.log(error);
  }
};

export const MediaTypes = async () => {
  try {
    return await getData(`/without-login/masters/all-media-types`);
  } catch (error) {
    console.log(error);
  }
};

export const ProductTypes = async () => {
  try {
    return await getData(`/without-login/masters/all-s-product-type`);
  } catch (error) {
    console.log(error);
  }
};

export const ProductStatus = async () => {
  try {
    return await getData(`/seller/masters/all-product-status`);
  } catch (error) {
    console.log(error);
  }
};

export const AllOnlySubCategory = async (seller_id) => {
  try {
    let url = `/without-login/masters/all-subcategory`;

    if (seller_id) {
      url = url + "?seller_id=" + seller_id;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const AllProduct = async (domain_id, type) => {
  try {
    let url = `/seller/masters/all-products`;
    if (domain_id) {
      url = url + "?domain_id=" + domain_id;
    }
    if (type) {
      url = url + "&type=" + type;
    }

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const ProductInfoIcon = async () => {
  try {
    return await getData(`/seller/masters/product-info-icon`);
  } catch (error) {
    console.log(error);
  }
};

export const ThemeCategory = async () => {
  try {
    return await getData(`/seller/masters/theme-category`);
  } catch (error) {
    console.log(error);
  }
};

export const ThemeSubCategory = async (id) => {
  try {
    return await getData(`/seller/masters/theme-sub-category/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const CFeatures = async () => {
  try {
    return await getData(`/my-website/masters/c-features`);
  } catch (error) {
    console.log(error);
  }
};

export const getgetAllBTBCancelReason = async () => {
  try {
    return await getData(`/without-login/masters/all-btb-cancel-master`);
  } catch (error) {
    console.log(error);
  }
};
