import React from 'react';
import './HelpSlider.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';


const HelpSlider = ({ imagePaths, imageClassName }) => {


    return (
        <section className='help-slider'>
            <Swiper
                modules={[Pagination, A11y, Autoplay]}
                slidesPerView={1}           
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                    loop: true,
                }}
            >
                {imagePaths?.map((imagePath, index) => (
                    <SwiperSlide key={index}>
                        <div className='product-slide'>
                            <img src={process.env.PUBLIC_URL + imagePath} className={imageClassName} alt="..." />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
}

export default HelpSlider;
