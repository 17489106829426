import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../api";

export const getMypackages = async (currentPage) => {
  try {
    const res = await getData(`/outer-website/packages/my-packages?page=${currentPage}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getMypackageThemes = async (domain_id) => {
  try {
    const res = await getData(`/outer-website/packages/my-packages-themes?domain_id=${domain_id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const activateTheme = async (data) => {
  try {
    const res = await postData(`/outer-website/packages/activateTheme`,data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getThemeSections = async (domain_id) => {
  try {
    const res = await getData(`/outer-website/packages/theme-sections?domain_id=${domain_id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};


export const depositAmountPost = async (amount, razor) => {
  try {
    const res = await postData(`/seller/seller-wallet/wallet`,{amount:amount,razor_id:razor});

    return res;
  } catch (error) {
    console.log(error);
  }
};
