import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, Modal, Container, InputGroup, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getsingledata,
  edit,
} from "../../../../../../utils/apis/seller/outer-website/masters/coupon_codes/coupon_codes";
library.add(fas);
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data, IMG_URL, getDimension, deleteData, sellerDomain } = useContext(Context);

  const id = props.show;
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();
  const imageFile = watch("image");
  const imageFile2 = watch("mobile_image");

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("domain_id", sellerDomain?.value);
      DataToSend.append("name", data?.name);
      DataToSend.append("code", data?.code);
      DataToSend.append("type", data?.type);
      DataToSend.append("discount", data?.discount);
      DataToSend.append("total_amount", data?.total_amount);
      DataToSend.append("coupon_message", data?.coupon_message);
      DataToSend.append("coupon_info", data?.coupon_info);
      DataToSend.append("minimum_order", data?.minimum_order);
      DataToSend.append("use_per_cupon", data?.use_per_cupon);
      DataToSend.append("use_per_customer", data?.use_per_customer);
      DataToSend.append("s_date", data?.s_date?.value);
      DataToSend.append("e_date", data?.e_date?.value);

      DataToSend.append(`image`, data?.image[0]);
      DataToSend.append(`mobile_image`, data?.mobile_image[0]);

      const response = await edit(id, DataToSend);
      console.log("response", response);
      console.log("data to send", DataToSend);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };




  console.log(getValues('images[0]'), 'llll');

  const GetEditData = async () => {
    const response = await getsingledata(id);
    // setData(response);
    // setData(response?.data);
    reset(response?.data)
  };

  useEffect(() => {
    GetEditData();
  }, []);


  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit Employee</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Edit Product</Card.Title>
                <hr />
                <Container>
                  <Form
                    // onSubmit={() => handleSubmit(onSubmit)}
                    role="form"
                  // className="stateclass"
                  >
                    <Row>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Type</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  as="select"
                                  name="type"
                                  className={classNames("", {
                                    "is-invalid": errors?.type,
                                  })}
                                  {...register("type", {
                                    required: "Type is required",
                                  })}
                                >
                                  <option value="">Select Type</option>
                                  <option value="percentage">Percentage</option>
                                  <option value="fixedamount">Fixed Amount</option>
                                  <option value="combo">Combo</option>
                                </Form.Control>
                              </InputGroup>
                              {errors.type && (
                                <span className="text-danger">
                                  {errors.type.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Name</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Name .."
                                  className={classNames("", {
                                    "is-invalid": errors?.name,
                                  })}
                                  {...register("name", {
                                    required: "Name is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.name && (
                                <span className="text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Coupon Code </Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="code"
                                  placeholder="Coupon Code .."
                                  className={classNames("", {
                                    "is-invalid": errors?.code,
                                  })}
                                  {...register("code", {
                                    required: "Coupon Code is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.code && (
                                <span className="text-danger">
                                  {errors.code.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Discount</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="discount"
                                  placeholder="Discount"
                                  className={classNames("", {
                                    "is-invalid": errors?.discount,
                                  })}
                                  {...register("discount", {
                                    required: "Discount is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.discount && (
                                <span className="text-danger">
                                  {errors.discount.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Total Ammount</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="total_amount"
                                  placeholder="Total Ammount"
                                  className={classNames("", {
                                    "is-invalid": errors?.total_amount,
                                  })}
                                  {...register("total_amount", {
                                    required: "Total Ammount is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.total_amount && (
                                <span className="text-danger">
                                  {errors.total_amount.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>


                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Coupen Message</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="coupon_message"
                                  placeholder="Coupen Message"
                                  className={classNames("", {
                                    "is-invalid": errors?.coupon_message,
                                  })}
                                  {...register("coupon_message", {
                                    required: "Coupen Message is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.coupon_message && (
                                <span className="text-danger">
                                  {errors.coupon_message.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Cuppon Info</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="coupon_info"
                                  placeholder="Coupen Info"
                                  className={classNames("", {
                                    "is-invalid": errors?.coupon_info,
                                  })}
                                  {...register("coupon_info", {
                                    required: "Coupen Info is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.coupon_info && (
                                <span className="text-danger">
                                  {errors.coupon_info.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Minimum Order</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="minimum_order"
                                  placeholder="Minimum Order"
                                  className={classNames("", {
                                    "is-invalid": errors?.minimum_order,
                                  })}
                                  {...register("minimum_order", {
                                    required: "Minimum Order is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.minimum_order && (
                                <span className="text-danger">
                                  {errors.minimum_order.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Use Per Coupon</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="use_per_cupon"
                                  placeholder="Use Per Coupon"
                                  className={classNames("", {
                                    "is-invalid": errors?.use_per_cupon,
                                  })}
                                  {...register("use_per_cupon", {
                                    required: "Use Per Coupon is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.use_per_cupon && (
                                <span className="text-danger">
                                  {errors.use_per_cupon.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Use Per Customer</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="use_per_customer"
                                  placeholder="Use Per Customer"
                                  className={classNames("", {
                                    "is-invalid": errors?.use_per_customer,
                                  })}
                                  {...register("use_per_customer", {
                                    required: "Use Per Customer is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.use_per_customer && (
                                <span className="text-danger">
                                  {errors.use_per_customer.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Use Per Customer</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="use_per_customer"
                                  placeholder="Use Per Customer"
                                  className={classNames("", {
                                    "is-invalid": errors?.use_per_customer,
                                  })}
                                  {...register("use_per_customer", {
                                    required: "Use Per Customer is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.use_per_customer && (
                                <span className="text-danger">
                                  {errors.use_per_customer.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            <Form.Label className="text-left">
                              Image
                            </Form.Label>

                            <Form.Group>
                              <Form.Control
                                className={classNames("", {
                                  "is-invalid": errors?.image,
                                })}
                                type="file"
                                {...register("image", {
                                  // required: "Image is required",

                                })}
                                accept=".jpg, .jpeg, .png"
                              />
                            </Form.Group>
                            {errors.image && (
                              <span className="text-danger">
                                {errors.image.message}
                              </span>
                            )}

                          </Row>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label column sm={3} className="text-center">
                              Image Preview
                            </Form.Label>
                            <Col sm={9}>
                              {typeof getValues("image") == "string" ? (
                                <div className="image-preview-container">
                                  <img
                                    src={IMG_URL + getValues("image")}
                                    alt="Preview"
                                    className="image-preview"
                                    style={{ width: "150px", height: "130px" }}
                                  />
                                </div>
                              ) : (
                                imageFile &&
                                imageFile?.length > 0 && (
                                  <div className="image-preview-container">
                                    <img
                                      // src={URL.createObjectURL(getValues("image")[0])}
                                      src={URL?.createObjectURL(imageFile[0])}
                                      alt="Preview"
                                      className="image-preview"
                                      style={{ width: "100px", height: "100px" }}
                                    />
                                  </div>
                                )
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            <Form.Label className="text-left">
                              Mobile Image
                            </Form.Label>

                            <Form.Group>
                              <Form.Control
                                className={classNames("", {
                                  "is-invalid": errors?.mobile_image,
                                })}
                                type="file"
                                {...register("mobile_image", {
                                  // required: "Mobile Image is required",

                                })}
                                accept=".jpg, .jpeg, .png"
                              />
                            </Form.Group>
                            {errors.mobile_image && (
                              <span className="text-danger">
                                {errors.mobile_image.message}
                              </span>
                            )}

                          </Row>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label column sm={3} className="text-center">
                              Mobile Image Preview
                            </Form.Label>
                            <Col sm={9}>
                              {typeof getValues("mobile_image") == "string" ? (
                                <div className="image-preview-container">
                                  <img
                                    src={IMG_URL + getValues("mobile_image")}
                                    alt="Preview"
                                    className="image-preview"
                                    style={{ width: "150px", height: "130px" }}
                                  />
                                </div>
                              ) : (
                                imageFile2 &&
                                imageFile2?.length > 0 && (
                                  <div className="image-preview-container">
                                    <img
                                      // src={URL.createObjectURL(getValues("image")[0])}
                                      src={URL?.createObjectURL(imageFile2[0])}
                                      alt="Preview"
                                      className="image-preview"
                                      style={{ width: "100px", height: "100px" }}
                                    />
                                  </div>
                                )
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Row className="mt-5 pb-3">
                        <div className="d-flex justify-content-center">
                          <Link>
                            <CancelButton
                              name={"cancel"}
                              handleClose={props.handleClose}
                            />
                          </Link>

                          <Button
                            name={"save"}
                            onClick={handleSubmit(onSubmit)}
                            type="button"
                          >
                            save
                          </Button>
                        </div>
                      </Row>
                    </Row>


                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </Card>
        </Offcanvas.Body>
      </Offcanvas >

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
