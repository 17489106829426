import React, { useContext, useEffect, useState } from "react";
import './affi-events.css'
import { getEvents } from "../../../../../utils/apis/events/event";
import { Context } from "../../../../../utils/context";
import { getGuide } from "../../../../../utils/apis/affiliate/guide";

const Guidevideo = () => {

    const { IMG_URL } = useContext(Context);
    const [events, setEvents] = useState();
    const getAllEvents = async () => {
        const res = await getGuide();
        if (res?.success) {
            setEvents(res?.data);
        }
    };
    useEffect(() => {
        getAllEvents();
    }, []);

    const formatEventDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        }).replace(':00', '');
        return `${formattedDate} at ${formattedTime}`;
    };
    return (
        <>
            <section className='afffievents-sec'>
                <div className='row'>
                    
                        <div className='col-lg-12 col-md-12 col-sm-12 mb-4'>
                            <div className='eventcard'>
                            <div className='img-div'>
                                    {/* <img className='cardimg' src={IMG_URL + event?.image} /> */}
                                    <video
                                        src={IMG_URL + events?.video}
                                        controls
                                        autoPlay
                                        className="d-block"
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                </div>
                            </div>
                        </div>
                    
                </div>
            </section>
        </>
    )
}

export default Guidevideo