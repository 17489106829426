import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import parse from "html-react-parser";
import './SelfRelient.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Pagination } from "swiper/modules";
import "swiper/css/pagination"
import "swiper/swiper-bundle.css"
import 'swiper/css';

const SelfRelient = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }

    };
    const successStoriesData = [
        {
            id: 1,
            imageUrl: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/s1.png",
            description: "Experience Vinay Garg's inspiring journey as he achieves the milestone of selling 700+ home appliances per day with Netpurti's"
        },
        {
            id: 2,
            imageUrl: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/s2.png",
            description: "Experience Vinay Garg's inspiring journey as he achieves the milestone of selling 700+ home appliances per day with Netpurti's"
        },
        {
            id: 3,
            imageUrl: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/s3.png",
            description: "Experience Vinay Garg's inspiring journey as he achieves the milestone of selling 700+ home appliances per day with Netpurti's"
        },
        {
            id: 4,
            imageUrl: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/s4.png",
            description: "Experience Vinay Garg's inspiring journey as he achieves the milestone of selling 700+ home appliances per day with Netpurti's"
        }
    ];

    const OnlineSalesDetails = [
        {
            imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/ss1.png",
            salespersoncontent: "Experience Vinay Garg's inspiring journey as he achieves the milestone of selling 700+ home appliances per day with Netpurti's"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/s2.png",
            salespersoncontent: "Meet Sanjay Jaiswal earning ₹15000/month, along with his family built a business with a turnover of over ₹45 lakhs every year and growing with Netpurti's"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/s3.png",
            salespersoncontent: "Meet Sanjay Jaiswal earning ₹15000/month, along with his family built a business with a turnover of over ₹45 lakhs every year and growing with Netpurti's"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/s4.png",
            salespersoncontent: "Meet Sanjay Jaiswal earning ₹15000/month, along with his family built a business with a turnover of over ₹45 lakhs every year and growing with Netpurti's"
        },
    ]

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/seller/success-stories/s-self-relient`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);

    return (
        <section className='self-relient'>
            <div className='section-class text-center'>
                <h4>Self-relient</h4>
            </div>
            <Container>
                <Row>
                    <Col lg={6} xl={4}>
                        <div className='self-relient-scroll'>
                            {data?.map(story => (
                                <div className='self-relient-first-section mt-3' key={story.id}>
                                    <Row>
                                        <Col md={2} sm={2} >
                                            <img src={IMG_URL + story?.image} className="self-img" alt="..." />
                                        </Col>
                                        <Col md={10} sm={10}>
                                            <div>
                                                <p className='story-desc'>{parse(story?.description) || ""}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col lg={6} xl={8} >
                        <div className='row justify-content-center'>
                            <div className='col-xxl-10 col-md-10 col-10'>
                                <Swiper
                                    modules={[Pagination]}
                                    spaceBetween={25}
                                    onSlideChange={() => console.log('slide change')}
                                    // onSwiper={(swiper) => console.log(swiper)}
                                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                        },
                                        360: {
                                            slidesPerView: 1,
                                        },
                                        467: {
                                            slidesPerView: 2,
                                        },
                                        567: {
                                            slidesPerView: 2,
                                        },
                                        640: {
                                            slidesPerView: 2,
                                        },

                                        768: {
                                            slidesPerView: 1,
                                        },
                                        992: {
                                            slidesPerView: 1,
                                        },
                                        1024: {
                                            slidesPerView: 1,
                                        },
                                        1440: {
                                            slidesPerView: 2,
                                        },
                                        2000: {
                                            slidesPerView: 2,
                                        },
                                        2560: {
                                            slidesPerView: 2,
                                        },
                                        3000: {
                                            slidesPerView: 2,
                                        },

                                    }}
                                >
                                    {data?.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <div className='card main-white-card-class-selff mb-5'>
                                                <div className='card-img-top main-img-class'>
                                                    {item.image && (
                                                        <img
                                                            src={IMG_URL + item?.image}
                                                            className="person-imgg"
                                                            alt="..."
                                                        />
                                                    )}
                                                </div>
                                                <div className='card-body'>
                                                    <p className='sales-content'>{parse(item?.description) || ""}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div
                                    className="main-slider"
                                    onClick={() => handleMainSliderClick("prev")}
                                >
                                    <div className="arrow-back" />
                                </div>
                                <div
                                    className="main-slider"
                                    onClick={() => handleMainSliderClick("next")}
                                >
                                    <div className="arrow-next" />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SelfRelient;
