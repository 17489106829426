import React from 'react'
import './CustomerInfo.css'
import { Row, Col } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import ButtonComponent from '../../../CommonComponents/ButtonComponent/ButtonComponent';
import PaymentSuccessful from '../../../MyWebsiteModal/PaymentSuccessful/PaymentSuccessful';

const CustomerInfo = () => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <section className='customer-info-section'>
            <p className='title-customer'>Customer Info</p>
            <Row>
                <Col xxl={6}>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="name" />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xxl={6}>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="name" />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col xxl={12}>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <div className='check-brown-border'></div>
            <p className='title-customer'>Payment</p>
            <Row>
                <Col xxl={6}>
                    <div className='form-chek-in'>
                        <Form.Check
                            type='radio'
                            id={`default-unsuitable-quotations`}
                            label={`Paypal`}
                            className='radio-btn-class mb-3'
                            name='name'
                        />
                        <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Checkoutform/paypal.png"} className="paypal-img" alt="..." />
                    </div>
                </Col>
                <Col xxl={6}>
                    <div className='form-chek-in'>
                        <Form.Check
                            type='radio'
                            id={`default-unsuitable-quotations`}
                            label={`Credit Card`}
                            className='radio-btn-class mb-3'
                            name='name'
                        />
                        <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Checkoutform/visa.png"} className="pay-img" alt="..." />
                    </div>
                </Col>
            </Row>
            <div className='card-holder-class'>
                <Row>
                    <Col xxl={6}>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Card Holder Name</Form.Label>
                                <Form.Control type="name" />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xxl={6}>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Card Number</Form.Label>
                                <Form.Control type="number" />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={6}>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Card Pin</Form.Label>
                                <Form.Control type="number" />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xxl={6}>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Card EXP Date</Form.Label>
                                <Form.Control type="date" />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>

            </div>
            <div className='check-brown-border'></div>
            <p className='title-customer'>Billing Info</p>
            <Row>
                <Col xxl={6}>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="name" />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xxl={6}>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="number" />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col xxl={6}>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>State</Form.Label>
                            <Form.Control type="name" />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xxl={6}>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="name" />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col xl={10} lg={10} md={10} sm={10} xs={8}>
                    <div className='title-customer'>Discount</div>
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={4}>
                    <div className='title-customer'>$12</div>
                </Col>
                <Col xl={10} lg={10} md={10} sm={10} xs={8}>
                    <div className='title-customer'>Tax/Vat</div>
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={4}>
                    <div className='title-customer'>$5</div>
                </Col>
            </Row>
            <div className='check-brown-border'></div>
            <Row>
                <Col xl={10} lg={10} md={10} sm={10} xs={8}>
                    <p className='title-customer-orange' >Total</p>
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={4}>
                    <p className='title-customer-orange'>$5657</p>
                </Col>
            </Row>
            <div className='text-center'>
                <ButtonComponent className='proceed-btn' onClick={() => setModalShow(true)}>Proceed Payment</ButtonComponent>

                <PaymentSuccessful
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
        </section>
    )
}

export default CustomerInfo