import React from "react";
import Orderstock from "./Orderstock/Orderstock";
import Ordertable from "./Ordertable/Ordertable";

const Orders = () => {
  return (
    <>
      <Orderstock />
      <Ordertable />
    </>
  );
};

export default Orders;
