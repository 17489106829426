import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllTheme = async (
  currentPage,
  perPage,
  search,
  searchCategory,
  searchSubCategory,
  searchStatus
) => {
  try {
    let url = `/seller/theme/theme?page=${currentPage}&per_page=${perPage}&term=${search}&category=${
      searchCategory?.label || ""
    }&sub_category=${searchSubCategory?.label || ""}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const changeThemeStatus = async (id) => {
  try {
    return await editStatusData(`/seller/theme/theme/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteTheme = async (recordToDeleteId) => {
  try {
    return await deleteData(`/seller/theme/theme/${recordToDeleteId}`);
  } catch (error) {
    console.log(error);
  }
};

export const addTheme = async (finalData) => {
  try {
    return await postData(`/seller/theme/theme`, finalData);
  } catch (error) {
    console.log(error);
  }
};

export const editTheme = async (id, finalData) => {
  try {
    return await postData(`/seller/theme/theme/${id}`, finalData);
  } catch (error) {
    console.log(error);
  }
};

export const getsingleTheme = async (id) => {
  try {
    return await getData(`/seller/theme/theme/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
