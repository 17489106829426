// import React from "react";
// import "./Theme.css";
// import { Link } from "react-router-dom";
// import Col from "react-bootstrap/Col";
// import Nav from "react-bootstrap/Nav";
// import Row from "react-bootstrap/Row";
// import Tab from "react-bootstrap/Tab";
// import Accordion from "react-bootstrap/Accordion";
// import ThemeCategory from "../ThemeComponent/ThemeCategory/Table";
// import ThemeSubCategory from "../ThemeComponent/ThemeSubCategory/Table";
// import Theme from "../ThemeComponent/Theme/Table";

// const Mywebsite = () => {
//   return (
//     <>
//       <section className="Mywebsite">
//         <div className="mywebsiteMain">
//           <Tab.Container id="left-tabs-example" defaultActiveKey="1">
//             <Row>
//               <Col sm={2}>
//                 <div className="websitestab">
//                   <Nav variant="pills" className="flex-column">
//                     <Nav.Item onClick={closeSidebar}>
//                       <Nav.Link eventKey="1">Theme Category</Nav.Link>
//                     </Nav.Item>{" "}
//                     <Nav.Item onClick={closeSidebar}>
//                       <Nav.Link eventKey="2">Theme Sub Category</Nav.Link>
//                     </Nav.Item>{" "}
//                     <Nav.Item onClick={closeSidebar}>
//                       <Nav.Link eventKey="3">Theme</Nav.Link>
//                     </Nav.Item>
//                   </Nav>
//                 </div>
//               </Col>
//               <Col sm={10}>
//                 <Tab.Content>
//                   <Tab.Pane eventKey="1">
//                     <ThemeCategory />
//                   </Tab.Pane>
//                   <Tab.Pane eventKey="2">
//                     <ThemeSubCategory />
//                   </Tab.Pane>{" "}
//                   <Tab.Pane eventKey="3">
//                     <Theme />
//                   </Tab.Pane>
//                 </Tab.Content>
//               </Col>
//             </Row>
//           </Tab.Container>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Mywebsite;

import React, { useState } from "react";
import "./Theme.css";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Accordion from "react-bootstrap/Accordion";
import ThemeCategory from "../ThemeComponent/ThemeCategory/Table";
import ThemeSubCategory from "../ThemeComponent/ThemeSubCategory/Table";
import Theme from "../ThemeComponent/Theme/Table";
import {
  faCodeBranch,
  faTableCellsLarge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFigma } from "@fortawesome/free-brands-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Mywebsite = ({ setSidebarclosed, sidebarclosed }) => {
  const [filter, setFilter] = useState("");
  const [activeTab, setActiveTab] = useState("0");

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const tabsArray = [
    {
      key: "0",
      name: (
        <>
          <FontAwesomeIcon icon={faTableCellsLarge} />
        </>
      ),
      component: <ThemeCategory />,
      tooltip: "Theme Category",
    },
    {
      key: "1",
      name: (
        <>
          <FontAwesomeIcon icon={faCodeBranch} />
        </>
      ),
      component: <ThemeSubCategory />,
      tooltip: "Theme Sub Category",
    },
    {
      key: "2",
      name: (
        <>
          <FontAwesomeIcon icon={faFigma} />
        </>
      ),
      component: <Theme />,
      tooltip: "Theme",
    },
  ];

  const renderTooltip = (props) => (
    <Tooltip {...props}>{props.tooltip}</Tooltip>
  );

  const closeSidebar = () => {
    if (!sidebarclosed) {
      setSidebarclosed(true);
    }
  };

  return (
    <>
      {" "}
      <section className="Mywebsite">
        <div className="mywebsiteMain">
          <div className=" sidebar-tab-holder">
            <Tab.Container
              id="left-tabs-example"
              activeKey={activeTab}
              onSelect={(e) => setActiveTab(e)}
            >
              <Row>
                <Col sm={1} className="col-width scale-up-hor-left">
                  <Nav variant="pills" className="flex-column">
                    {tabsArray.map((item) => (
                      <OverlayTrigger
                        key={item.key}
                        placement="right"
                        delay={{ show: 200, hide: 0 }}
                        overlay={renderTooltip({ tooltip: item.tooltip })}
                      >
                        <Nav.Item onClick={closeSidebar}>
                          <Nav.Link eventKey={item.key}>{item.name}</Nav.Link>
                        </Nav.Item>
                      </OverlayTrigger>
                    ))}
                  </Nav>
                </Col>
                <Col sm={11} className="col2-width">
                  <Tab.Content>
                    {tabsArray.map((item) => (
                      <Tab.Pane eventKey={item.key} key={item.key}>
                        {item.component}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mywebsite;
