import React, { useState, useContext, useEffect } from "react";

import "./BlogContent.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import Comment from "../Comment/Comment";
import { faCalendarDays, faMessage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BlogsGet, BlogsSingleGet } from "../../../../utils/apis/myWebsite/mywebsite";
import { Context } from "../../../../utils/context";


const BlogContent = () => {
  const [date, setDate] = useState(new Date());
  const [expanded, setExpanded] = useState(false);
  const [comment, setComment] = useState("");

  const toggleExpand = () => {
    setExpanded(!expanded);
  };


  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();


  const { IMG_URL } = useContext(Context);

  const [blogsData, setBlogsData] = useState();
  const getBlogsData = async () => {
    const res = await BlogsGet();
    if (res?.success) {
      setBlogsData(res?.data);
      // setMainBlogsData(res?.data[0]);
    }
  }


  const [showTextArea, setShowTextArea] = useState(false);
  const [replyText, setReplyText] = useState("");

  const toggleTextArea = () => {
    setShowTextArea(!showTextArea);
  };

  const handleReplyChange = (event) => {
    setReplyText(event.target.value);
  };

  const submitReply = () => {
    console.log("Reply submitted: " + replyText);

    setReplyText("");
    setShowTextArea(false);
  };

  const [mainblogsData, setMainBlogsData] = useState();
  const [mainblogsCommentsData, setMainBlogsCommentData] = useState();
  const getBlogOnly = async (blog_id) => {
    const res = await BlogsSingleGet(blog_id);
    if (res?.success) {
      setMainBlogsData(res?.data);
      setMainBlogsCommentData(res?.data?.comments);
    }
  }


  const [blog_id, setblogId] = useState(1);
  const onBlogClick = (blog_id) => {
    setblogId(blog_id);
  }

  useEffect(() => {
    getBlogsData();
  }, [])

  useEffect(() => {
    getBlogOnly(blog_id);
  }, [blog_id])

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  }


  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handlePublish = () => {
    console.log("Publishing comment:", comment);

    setComment("");

    setExpanded(false);
  };

  return (
    <>
      <section className="BlogsPage">
        <Container>
          <Row>
            <Col xl={8} lg={7}>
              <div className="Blogs-Card">
                <div className="book">
                  <img
                    src={IMG_URL + mainblogsData?.image}
                    className="books-img"
                    alt="..."
                  />
                  <div className="overlay"></div>
                  <div className="content">
                    <h1 className="maintenance-title">{mainblogsData?.title}</h1>
                    <div className="calender justify-content-between">
                      <p className="maintenance-text">
                        {mainblogsData?.sub_title}
                      </p>
                      <p className="maintenance-text">
                        <FontAwesomeIcon icon={faCalendarDays} /> {formatDate(mainblogsData?.createdAt)} |{" "}
                        <FontAwesomeIcon icon={faMessage} />{" " + mainblogsData?.comment_counts}
                      </p>
                    </div>
                  </div>
                </div>
                <p className="maintenance-text mt-2">
                  {htmlToReactParser.parse(mainblogsData?.description)}
                </p>
              </div>

              <div className={`comment-box ${expanded ? "expanded" : ""}`}>
                <div className="comment" onClick={toggleExpand}>
                  {/* Comment content */}
                  {expanded ? (
                    <>
                      <textarea
                        rows="3"
                        placeholder="Write your comment..."
                        value={comment}
                        onChange={handleCommentChange}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <div className="publish-btn">
                        <Button
                          variant="primary"
                          className="publish"
                          onClick={handlePublish}
                        >
                          Publish
                        </Button>
                      </div>
                    </>
                  ) : (
                    <p className="text">Click to add a comment</p>
                  )}
                  {/* End of comment content */}
                </div>
              </div>
              <div className="comment_section">
                <div className="comment_head_div">
                  <h4 className="comment_heading">{mainblogsData?.comment_counts} {"  "}Comments</h4>
                </div>
                {mainblogsCommentsData?.map((content) => (
                  <div className="comment_section_main">
                    <div className="comment_div">
                      <div className="comment_image_div">
                        <div className="comment_img">
                          {/* <img src={content.image} className="mens_img" alt="..." /> */}
                        </div>
                        <p className="maintenance-text">{formatDate(content?.createdAt)}</p>
                      </div>
                      <div className="comment_text_div">
                        <p className="maintenance-text">{htmlToReactParser.parse(content.description)}</p>
                      </div>
                    </div>
                    <div className="reply_button_div">
                      <button className="reply_button" onClick={toggleTextArea}>
                        Reply
                      </button>
                      {showTextArea && (
                        <div className="mt-3">
                          <textarea
                            value={replyText}
                            onChange={handleReplyChange}
                            rows="4"
                            cols="50"
                          ></textarea>
                          <button onClick={submitReply}>Submit</button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col xl={4} lg={5}>
              <div className="latest_blog_section">
                <div className="latest_blog_heading_div">
                  <p className="latest_blog_heading">Latest Blog</p>
                </div>
                {blogsData?.map((content2) => (
                  <div className="all_blog_cards_div" key={content2?.id} onClick={() => onBlogClick(content2?.id)}>
                    <div className="blog_card_div">
                      <div className="blog_img_div">
                        <img src={IMG_URL + content2?.image} className="blog_img" alt="..." />
                      </div>
                      <div className="blog_text_div">
                        <p className="blog_text">{content2?.name}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section >
      <Comment blog_id={blog_id} />
    </>
  );
};

export default BlogContent;
