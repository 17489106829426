import React, { useContext } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension, IMG_URL, sellerDomain } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const finalData = new FormData();
      finalData.append("domain_id", sellerDomain?.value);
      finalData.append("name", data?.name);
      finalData.append("contact_no", data?.contact_no);
      finalData.append("address", data?.address);
      finalData.append("email", data?.email);
      finalData.append("header_color", data?.header_color);
      finalData.append("footer_color", data?.footer_color);

      finalData.append("meta_tag", data?.meta_tag);
      finalData.append("meta_description", data?.meta_description);
      finalData.append("currency", data?.currency);
      finalData.append("whatapp_no", data?.whatapp_no);
      if (data?.image && data.image.length > 0) {
        finalData.append("image", data.image[0]);
      }
      console.log("finalData", finalData);
      const response = await postData(
        `/seller/inquiry-website/masters/app-setup`,
        finalData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add App Setup
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> */}
      <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
        <Row>
          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Name</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Banner"
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      {...register("name", {
                        required: "Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Contact No.</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="contact_no"
                      placeholder="Contact No"
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                      })}
                      {...register("contact_no", {
                        required: "Contact No is required",
                      })}
                    />
                  </InputGroup>
                  {errors.contact_no && (
                    <span className="text-danger">
                      {errors.contact_no.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Address</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder="Address"
                      className={classNames("", {
                        "is-invalid": errors?.address,
                      })}
                      {...register("address", {
                        required: "Address is required",
                      })}
                    />
                  </InputGroup>
                  {errors.address && (
                    <span className="text-danger">
                      {errors.address.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Email</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      className={classNames("", {
                        "is-invalid": errors?.email,
                      })}
                      {...register("email", {
                        required: "Email is required",
                      })}
                    />
                  </InputGroup>
                  {errors.email && (
                    <span className="text-danger">
                      {errors.email.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Meta Tag</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="meta_tag"
                      placeholder="meta tag"
                      className={classNames("", {
                        "is-invalid": errors?.meta_tag,
                      })}
                      {...register("meta_tag", {
                        required: "meta tag is required",
                      })}
                    />
                  </InputGroup>
                  {errors.meta_tag && (
                    <span className="text-danger">
                      {errors.meta_tag.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Meta Description</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="meta_description"
                      placeholder="meta description"
                      className={classNames("", {
                        "is-invalid": errors?.meta_description,
                      })}
                      {...register("meta_description", {
                        required: "meta description is required",
                      })}
                    />
                  </InputGroup>
                  {errors.meta_description && (
                    <span className="text-danger">
                      {errors.meta_description.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Whatapp No</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="whatapp_no"
                      placeholder="whatapp_no"
                      className={classNames("", {
                        "is-invalid": errors?.whatapp_no,
                      })}
                      {...register("whatapp_no", {
                        required: "Whatapp No is required",
                      })}
                    />
                  </InputGroup>
                  {errors.whatapp_no && (
                    <span className="text-danger">
                      {errors.whatapp_no.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>
          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Currency</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="currency"
                      placeholder="currency"
                      className={classNames("", {
                        "is-invalid": errors?.currency,
                      })}
                      {...register("currency", {
                        required: "Currency is required",
                      })}
                    />
                  </InputGroup>
                  {errors.currency && (
                    <span className="text-danger">
                      {errors.currency.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Header Color</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="header_color"
                      placeholder="Header Color"
                      className={classNames("", {
                        "is-invalid": errors?.header_color,
                      })}
                      {...register("header_color", {
                        required: "Header Color is required",
                      })}
                    />
                  </InputGroup>
                  {errors.header_color && (
                    <span className="text-danger">
                      {errors.header_color.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Header Font Color</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="header_font_color"
                      placeholder="Header Color"
                      className={classNames("", {
                        "is-invalid": errors?.header_font_color,
                      })}
                      {...register("header_font_color", {
                        required: "Header Color is required",
                      })}
                    />
                  </InputGroup>
                  {errors.header_font_color && (
                    <span className="text-danger">
                      {errors.header_font_color.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Footer Color</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="footer_color"
                      placeholder="Footer Color"
                      className={classNames("", {
                        "is-invalid": errors?.footer_color,
                      })}
                      {...register("footer_color", {
                        required: "Footer Color is required",
                      })}
                    />
                  </InputGroup>
                  {errors.footer_color && (
                    <span className="text-danger">
                      {errors.footer_color.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Header Font Color</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="header_font_color"
                      placeholder="Header Color"
                      className={classNames("", {
                        "is-invalid": errors?.header_font_color,
                      })}
                      {...register("header_font_color", {
                        required: "Header Color is required",
                      })}
                    />
                  </InputGroup>
                  {errors.header_font_color && (
                    <span className="text-danger">
                      {errors.header_font_color.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col lg={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-start">
                <Form.Label className="text-left">Image</Form.Label>

                <Form.Group>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.image,
                    })}
                    type="file"
                    {...register("image", {
                      required: "Image is required",
                      // validate: async (value) => {
                      //   if (typeof value !== "string") {
                      //     const fileTypes = ["jpg", "png", "jpeg"];
                      //     const fileType = value[0].name?.split(".")[1];

                      //     if (!fileTypes.includes(fileType)) {
                      //       return `please upload a valid file format. (${fileTypes})`;
                      //     }

                      //     const sizes = await getDimension(value[0]);
                      //     if (
                      //       sizes.width !== 1296 &&
                      //       sizes.height !== 465
                      //     ) {
                      //       return "Image width and height must be 1296 px and 465 px";
                      //     }

                      //   }
                      // },
                    })}
                    accept=".jpg, .jpeg, .png"
                  />
                </Form.Group>
                {errors.image && (
                  <span className="text-danger">
                    {errors.image.message}
                  </span>
                )}
              </Row>
            </div>
          </Col>

          <Col className="main-form-section mt-3">
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Image Preview</Form.Label>

                {imageFile && imageFile?.length > 0 && (
                  <div
                    className="image-preview-container mt-3"
                    style={{ marginLeft: "110px" }}
                  >
                    <img
                      // src={URL.createObjectURL(getValues("image")[0])}
                      src={URL?.createObjectURL(imageFile[0])}
                      alt="Preview"
                      className="image-preview"
                      style={{ width: "648px", height: "198px" }}
                    />
                  </div>
                )}
              </Row>
            </div>
          </Col>

          <Row className="mt-5 pb-3">
            <div className="d-flex justify-content-center">
              <Link>
                <CancelButton
                  name={"cancel"}
                  handleClose={props.handleClose}
                />
              </Link>

              <SaveButton
                name={"save"}
                handleSubmit={handleSubmit(onSubmit)}
              />
            </div>
          </Row>
        </Row>
      </Form>
      {/* </Modal.Body>
      </Modal> */}

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
