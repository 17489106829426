import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllData = async (
  currentPage,
  perPage,
  search,
  searchStatus
) => {
  try {
    let url = `/seller/info-website/masters/web-header?page=${currentPage}&per_page=${perPage}&term=${search}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const changeDataStatus = async (id) => {
  try {
    return await editStatusData(
      `/seller/info-website/masters/web-header/${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingledata = async (id) => {
  try {
    return await getData(`/seller/info-website/masters/web-header/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const ChangeOrder = async (id, data) => {
  try {
    return await postData(
      `/seller/info-website/masters/web-header/${id}`,
      data
    );
  } catch (error) {
    console.log(error);
  }
};
