import { Route, Routes } from "react-router-dom";

import { useLocation } from "react-router-dom";
import Affiliate_Dashboard from "../Affiliate_Dashboard";
import Affiliate_Login from "../../Affiliate-Dashboard/AffiliateLogin/AffiliateLogin"

const AffiliateMainRoutes = () => {
  const { pathname } = useLocation();

  return (
    <>

      
      <Routes>
        {/* {signin && ( */}

        <Route path="/affilate-dashboard/*" element={<Affiliate_Dashboard />} />



        {/* )} */}
      </Routes>
      
    </>
  );
};

export default AffiliateMainRoutes;
