import React, { useContext, useEffect, useState } from "react";
import "./SummaryCard.css";
import ButtonComponent from "../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import { Form, Button } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import {
  Link,
  BrowserRouter as Router,
  useLocation,
  useParams,
} from "react-router-dom";
import { ShimmerTitle } from "react-shimmer-effects";
// import Button from 'react-bootstrap/Button';
// import Animation from "../../../CommonForAll/Animation/SuccessAnimation/Animation";
// import Modal from 'react-bootstrap/Modal';
import InquiryModal from "../../common-components/AllModal/inquiry-Modal/InquiryModal";
import Modal from "react-bootstrap/Modal";
import ErrorModal from "../../../CommonForAll/ErrorModalDynamic/ErrorModal";
import {
  btobGstDetailsGet,
  btobGstDetailsPost,
} from "../../../../utils/apis/btb/B2b";
import { Context } from "../../../../utils/context";
import { gstVerify } from "../../../../utils/apis/third_party/third_party";
import ComingSoonModal from "../../../CommonForAll/CommingSoonModal/CommingSoonModal";

const SummaryCard = ({ text, link, getValues, handleRazorpay, gst }) => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const openSetModal = () => {
    if ("/b2bbuyer/start-order" == location.pathname) {
      handleRazorpay();
    }

    // setTimeout(() => {
    //   window.location = "/b2bbuyer/buyer-home"
    //   setShow(false);
    // }, 3000);
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [showgst, setShowgst] = useState(false);
  const [messageShow, setMssageShow] = useState(false);

  const handleClosegst = () => setShowgst(false);
  const handleShowgst = () => setShowgst(true);

  const getUserFirmDetails = async () => {
    const res = await btobGstDetailsGet(gst?.btb_personal_details_id);
    if (res?.success) {
      setVerifyStatus(true);
      setfirmId(res.data.id);
      reset(res.data);
    }
  };

  const { getData, postData, IMG_URL, shimmerLoader, setShimmerLoader } =
    useContext(Context);

  const [firmId, setfirmId] = useState("");
  const onSubmit = async (d) => {
    console.log(d);
    console.log(gst);
    if (verifyStatus) {
      const data = new FormData();

      data.append("id", d.id);
      data.append("btb_personal_details_id", gst?.btb_personal_details_id);
      data.append("gst_no", d.gst_no);

      const res = await btobGstDetailsPost(data);
      if (res?.success) {
        console.log(res, "success");
        getUserFirmDetails();
        setShowgst(false);
      }
    } else {
      if (!verifyStatus) {
        setError("gst_no", {
          type: "manual",
          message: "Verify your GST No.",
        });
      }
    }
  };

  const [verifyStatus, setVerifyStatus] = useState(false);
  const gst_no = watch("gst_no");
  const verifyGst = async (gst_no) => {
    setShimmerLoader(true);
    var data = {
      btb_personal_details_id: gst?.btb_personal_details_id,
      gst_no: gst_no,
    };

    const res = await gstVerify(data);
    if (res?.success) {
      console.log(res);
      clearErrors();
      setVerifyStatus(true);
      setShimmerLoader(false);
    } else {
      setVerifyStatus(false);
      setError("gst_no", {
        type: "manual",
        message: res.message.message,
      });
      setShimmerLoader(false);
    }
  };

  useEffect(() => {
    getUserFirmDetails();
  }, []);

  return (
    <>
      <div className="order-summary-card">
        <div className="flex-div">
          <h6>Order Summary</h6>
          <p>Total items: {getValues("total_items")}</p>
        </div>
        <div className="flex-div">
          <p>item(s) Total</p>
          <p>₹ {getValues("total_mrp")}</p>
        </div>
        <div className="flex-div">
          <p>Discount</p>
          <p>- ₹ {getValues("discount")}</p>
        </div>

        <div className="flex-div">
          <p>Tax</p>
          <p>+ ₹ {parseFloat(getValues("total_tax")).toFixed(2)}</p>
        </div>
        <div className="border-div" />
        <div className="flex-div mt-2">
          <p>Order total</p>
          <p>₹ {Math.round(parseFloat(getValues("total")))}</p>
        </div>

        <div className="text-center">
          {/* <Link to={link} getValues={getValues} > */}
          <ButtonComponent
            type="button"
            className="proceed-btn"
            // onClick={gst?.gst_no === null ? handleShowgst : openSetModal}
            onClick={() => setMssageShow(true)}
          >
            {text}
          </ButtonComponent>
          {/* </Link> */}

          {/* <ErrorModal
            onHide={() => setMssageShow(false)}
            show={messageShow}
            heading="Comming soon..."
            text="Website is under construction"
          /> */}
           <ComingSoonModal
            onHide={() => setMssageShow(false)}
            show={messageShow}
            heading="Comming soon..."
            text="Website is under construction"
          />

          <Modal centered show={showgst} onHide={handleClosegst}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Group
                      className="mb-4  bankk-verrr"
                      controlId="gst_no"
                    >
                      <Form.Label>GST Number</Form.Label>
                      {shimmerLoader ? (
                        <ShimmerTitle line={1} gap={10} variant="primary" />
                      ) : (
                        <>
                          <Form.Control
                            type="text"
                            name="gst_no"
                            placeholder="Enter your GST number"
                            {...register("gst_no", {
                              required: "GST Number is required",
                              pattern: {
                                value:
                                  /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
                                message: "Invalid GST Number",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.gst_no,
                              "is-valid": verifyStatus,
                            })}
                            onChange={() => {
                              setError("gst_no", "");
                              setVerifyStatus(false);
                            }}
                          />
                          {errors.gst_no && (
                            <span className="text-danger">
                              {errors.gst_no.message}
                            </span>
                          )}
                          {!verifyStatus && (
                            <button
                              type="button"
                              className="verrrr-bttt btn-info"
                              onClick={() => verifyGst(gst_no)}
                            >
                              Verify
                            </button>
                          )}
                        </>
                      )}
                    </Form.Group>

                    <br></br>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="text-end btnMainn apply_now_btn">
                    {/* <p onClick={() => setModalShow(true)}>
            By continuing, I agree to Netpurti's
            <span className="termstxt">Terms of Use & Privacy Policy</span>
          </p> */}
                    <div className="registerContinueMain"></div>
                    <div className="registerContinueMain">
                      <Button
                        // onClick={handleNext}
                        type="submit"
                        className="tabs-btn"
                      >
                        Save
                      </Button>
                    </div>

                    {/* <TermsAndConditionModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    /> */}
                  </div>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          {/* <Modal show={show} onHide={handleClose} size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='success-modal-payment'>
            <Modal.Header closeButton />
            <Modal.Body>
              <Animation />
              Payment done successfully
            </Modal.Body>
          </Modal> */}

          {/* <InquiryModal
            message="Payment done successfully"
            show={show}
            onHide={() => setShow(false)}
          /> */}
        </div>
      </div>
    </>
  );
};

export default SummaryCard;
