import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import MultiVariant from "./MultiVariant/MultiVariant";
import MultiVarientInner from "./MultiVarientInner/MultiVarientInner";

function MultiVarientMain(nextStep) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [innerPage, setInnerPage] = useState(0);

  const nextInnerPage = () => {
    setInnerPage((currPage) => currPage + 1);
  };

  const prevInnerPage = () => {
    setInnerPage((currPage) => currPage - 1);
  };

  return (
    <>
      <div className="personal-details-form p-0">
        {innerPage === 0 ? (
          <MultiVariant
            nextInnerPage={nextInnerPage}
            prevInnerPage={prevInnerPage}
          />
        ) : (
          <MultiVarientInner
            nextStep={nextStep}
            prevInnerPage={prevInnerPage}
          />
        )}
      </div>
    </>
  );
}

export default MultiVarientMain;
