import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import "./api_response.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";

const Api_response = ({ activePageId }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  console.log(activePageId);
  const getDataAll = async () => {
    try {
      const response = await getData(
        `/seller/api-documentation/website/apis/${activePageId}`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
    console.log("getting page data");
  }, [activePageId]);

  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = (e) => {
    const parentElement = e.target.parentElement;
    if (parentElement && parentElement.nextElementSibling) {
      const codeElement =
        parentElement.nextElementSibling.querySelector("code");
      if (codeElement) {
        const copyText = codeElement.textContent;
        navigator.clipboard.writeText(copyText);
        setCopySuccess("Copied!");
      } else {
        console.error(
          "No <code> element found as a child of the next sibling."
        );
      }
    } else {
      console.error("No next sibling found for the parent element.");
    }
  };
  return (
    <>
      <section className="Api_response-section">
        {data &&
          data?.map((api, index) => (
            <div className="row" >
              <div className="border-line mt-3"></div>


              <div className="col-md-6">
                <div className="responsedata">
                  <h3 className="title">Response</h3>
                  <p className="cont">Responses will be in the JSON format.</p>

                  <div className="table-data">
                    {parse(api?.response_table) || ""}
                  </div>

                  <h5 className="subtitle">Other Formats</h5>
                  <p className="cont">{parse(api?.other_formate) || ""}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="code-response-section">
                  <div className="responsive-code">
                    <div className="d-flex alignntitle">
                      <p className="">Request Example</p>
                      <FontAwesomeIcon
                        className="copyico"
                        onClick={copyToClipboard}
                        icon="fa-solid fa-copy"
                      />
                    </div>
                    <div className="resp-data">
                      <code>{parse(api?.request_example) || ""}</code>
                    </div>
                  </div>

                  <div className="text-center my-3">
                    <p className="resuytvwcv">Response Structure</p>
                  </div>

                  <div className="responsive-code">
                    <div className="d-flex alignntitle">
                      <p className="">Response Structure</p>
                      <FontAwesomeIcon
                        className="copyico"
                        onClick={copyToClipboard}
                        icon="fa-solid fa-copy"
                      />
                    </div>
                    <div className="resp-data">
                      <code>{parse(api?.request_structure) || ""}</code>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          ))}
      </section>

    </>

  );
};

export default Api_response;
