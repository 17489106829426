import { React, useState, useEffect, useContext } from "react";
import "./B2bSellerProduct.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Container, Row, Col } from "react-bootstrap";
import "./B2bSellerProduct.css";
import Table from "react-bootstrap/Table";
import { Rating } from "react-simple-star-rating";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import ProgressBar from "react-bootstrap/ProgressBar";
import RatingreviewsMoal from "../../common-components/AllModal/Editpersonal-Detailmodal/RatingreviewsMoal";
import { Context } from "../../../../utils/context";
import { IDS } from "../../../../utils/common";
const B2bSellerProduct = (props) => {
  const { data, singleVariant, IMG_URL, getValues } = props;
  const { getData, signin } = useContext(Context);
  const [activeTab, setActiveTab] = useState("first");
  const [modalRating, setModalRating] = useState(false);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const [reviews, setReviews] = useState();
  const getAllData = async () => {
    const res = await getData(`/btb/all-product-reviews/${data?.id}`);
    setReviews(res?.data);
  };

  useEffect(() => {
    getAllData();
  }, [data]);

  return (
    <>
      {/* B2bSellerProduct section started */}
      <section className="B2bSeller-Product">
        <Container>
          <Row>
            <Col xxl={9}>
              <div className="Product-section">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="first"
                  activeKey={activeTab}
                  onSelect={handleTabChange}
                >
                  <div className="main-nav">
                    <Nav variant="pills" className="">
                      <Nav.Item>
                        <Nav.Link eventKey="first">About Product</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          Product Description
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          Technical Specification
                        </Nav.Link>
                      </Nav.Item>

                      {(data?.s_product_packaging_details?.length > 0 ||
                        (data?.s_p_customize_p_details?.length > 0 &&
                          getValues("working_type_id") ===
                            IDS.working_type.manufacturer)) && (
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">Customization</Nav.Link>
                        </Nav.Item>
                      )}
                      {(data?.s_product_service_terms?.length > 0 ||
                        data?.s_product_caution_details?.length > 0) && (
                        <Nav.Item>
                          <Nav.Link eventKey="fifth">
                            Warranty & Product Caution
                          </Nav.Link>
                        </Nav.Item>
                      )}

                      <Nav.Item>
                        <Nav.Link eventKey="six">Ratings & Reviews</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>

                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {/* Content of first tab */}
                      <div className="Content-First-Tab">
                        <div
                          className="Content-First-Tab"
                          dangerouslySetInnerHTML={{ __html: data?.about }}
                        ></div>
                      </div>

                      {/* Content of second tab */}
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      {/* Content of second tab started*/}
                      <div className="Content-Second-Tab">
                        <div className="product-table">
                          <Row>
                            <Col xxl={12}>
                              <h4 className="title-tab">Other attributes</h4>
                              <Table className="tableDdemo mt-3" bordered>
                                <tbody>
                                  {data?.s_product_information?.s_product_descriptions?.map(
                                    (name, index) => (
                                      <tr className="name" key={index}>
                                        <td style={{ width: "30%" }}>
                                          {name?.name}
                                        </td>
                                        <td>{name?.description}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      {/* Content of second tab end*/}
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      <div className="product-table">
                        <Row>
                          <Col xxl={12}>
                            <h4 className="title-tab">Other attributes</h4>
                            <Table className="tableDdemo" bordered>
                              <tbody>
                                {getValues("s_p_t_descriptions")?.map(
                                  (name, index) => (
                                    <tr className="name" key={index}>
                                      <td style={{ width: "30%" }}>
                                        {name?.name}
                                      </td>
                                      <td>{name?.description}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </div>

                      {/* Content of third tab end */}
                    </Tab.Pane>

                    {(data?.s_product_packaging_details?.length > 0 ||
                      data?.s_p_customize_p_details?.length > 0) && (
                      <Tab.Pane eventKey="fourth">
                        <div className="product-table">
                          <Row>
                            {data?.s_product_packaging_details?.length && (
                              <Col xl={12}>
                                <h4 className="title-tab">Packaging</h4>
                                <Table bordered>
                                  <tbody>
                                    {data?.s_product_packaging_details?.map(
                                      (packaging, index) => (
                                        <tr className="name" key={index}>
                                          <td style={{ width: "30%" }}>
                                            {
                                              packaging?.packaging_attribute
                                                ?.name
                                            }
                                          </td>
                                          <td>{packaging?.description}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </Col>
                            )}

                            {data?.s_p_customize_p_details?.length && (
                              <Col xxl={12}>
                                <h4 className="title-tab">Product Caution</h4>
                                <Table className="tableDdemo" bordered>
                                  <tbody>
                                    {data?.s_p_customize_p_details?.map(
                                      (packaging, index) => (
                                        <tr className="name" key={index}>
                                          <td style={{ width: "30%" }}>
                                            {
                                              packaging
                                                ?.customize_product_attribute
                                                ?.name
                                            }
                                          </td>
                                          <td>{packaging?.description}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </Tab.Pane>
                    )}

                    {(data?.s_product_service_terms?.length > 0 ||
                      data?.s_product_caution_details?.length > 0) && (
                      <Tab.Pane eventKey="fifth">
                        <div className="product-table">
                          <Row>
                            {data?.s_product_service_terms?.map(
                              (service_terms, index) => (
                                <Col xl={12} key={index}>
                                  <h4 className="title-tab">
                                    {" "}
                                    {service_terms?.s_service_term?.name}
                                  </h4>
                                  <Table bordered>
                                    <tbody>
                                      {service_terms?.s_product_service_term_detail?.s_product_warrenty_details?.map(
                                        (warrenty, w_index) => (
                                          <tr className="name" key={w_index}>
                                            <td>{warrenty?.name}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </Col>
                              )
                            )}

                            <Col xxl={12}>
                              <h4 className="title-tab">Product Caution</h4>
                              <Table className="tableDdemo" bordered>
                                <tbody>
                                  {data?.s_product_caution_details?.map(
                                    (name, index) => (
                                      <tr className="name" key={index}>
                                        <td style={{ width: "30%" }}>
                                          <img
                                            src={
                                              IMG_URL +
                                              name?.caution_icon?.image
                                            }
                                            className="table-imggg"
                                            alt={name?.caution_icon?.name}
                                          />
                                        </td>
                                        <td>{name?.description}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                        {/* Fifth Tab Content end */}
                      </Tab.Pane>
                    )}

                    <Tab.Pane eventKey="six">
                      {/* Sixth Tab section started */}

                      <div className="Content-Six-Tab">
                        <div className="d-flex">
                          <p className="key">Ratings & Review</p>
                          {signin && (
                            <div className="Review-btn">
                              <Button
                                onClick={() => setModalRating(true)}
                                variant="review"
                              >
                                Write a review
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className="d-flex">
                          <div className="star">
                            <Rating
                              initialValue={Math.round(reviews?.averageRating)}
                              SVGclassName="star-svg"
                              fillColor="#00000"
                              readonly
                            />
                          </div>
                          <div className="star">
                            <p className="rating-number">
                              {Math.round(reviews?.averageRating)}
                            </p>
                          </div>
                        </div>
                        {reviews?.ratingPercentagesArray?.map((item, index) => (
                          <div key={index} className="rating-progressbar">
                            <p className="rating-text">{item?.name} Star</p>
                            <ProgressBar
                              now={item?.percentage}
                              variant="secondary"
                            />
                            <p className="rating-text">{item?.count}</p>
                          </div>
                        ))}

                        {reviews && reviews?.data?.length > 0 && (
                          <div className="reviews-mainnnnn">
                            {reviews?.data?.map((item, index) => (
                              <Row>
                                <Col xxl={9} xl={9} lg={9} md={9} sm={12}>
                                  <div className="Reviews-Section">
                                    <div className="content-review">
                                      <h4 className="title-tab">
                                        {item?.btb_personal_detail?.f_name}{" "}
                                        {item?.btb_personal_detail?.l_name}
                                      </h4>
                                      <div className="stars">
                                        <p className="name">
                                          {new Date(
                                            item.createdAt
                                          ).toLocaleDateString()}
                                        </p>
                                        <div className="App">
                                          <Rating
                                            initialValue={item?.rating}
                                            SVGclassName="star-svg"
                                            fillColor="#00000"
                                            readonly
                                          />
                                        </div>
                                      </div>
                                      <p className="name">{item.review}</p>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* Sixth Tab section End */}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
            {/* <Col xxl={3}>
              <div className="Customer-Service">
                <div className="Seeking">
                  <h4 className="heading-seeking">
                    Seeking particular information?
                  </h4>
                  <div className="content">
                    <p className="customer">Customer Q&As</p>
                    <p className="text">
                      There were 0 results in Customer Questions & Answers.
                    </p>
                    <p className="customer">
                      Unable to find the answer you seek?
                    </p>

                    <Button variant="primary" className="post">
                      Post your question
                    </Button>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
        <RatingreviewsMoal
          getDataAll={getAllData}
          product_id={data?.id}
          show={modalRating}
          onHide={() => {
            setModalRating(false);
          }}
        />
      </section>

      {/* B2bSellerProduct section End */}
    </>
  );
};

export default B2bSellerProduct;
