import React, { useState, useEffect, useContext } from "react";
import "../../../Variants/variants.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import JoditEditor from "jodit-react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import MultiVariant from "../MultiVariant/MultiVariant";
import Select from "react-select";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";

import Cookies from "js-cookie";
import { Context } from "../../../../../../utils/context";

import {
  multipleVariantPost,
  multipleVariantGet,
  AttributeDelete,
  VariantIdDataGet,
  VariantIdDataPost,
  RemoveAll,
} from "../../../../../../utils/apis/seller/BTBProduct";
import { hsnCodes } from "../../../../../../utils/apis/seller/masters";
import {
  GST,
  PlatformCharges,
  ProductInfoIcon,
  Unit,
} from "../../../../../../utils/apis/master/Master";
import { SelectHsnData, validateImage } from "../../../../../../utils/common";
import SuccessModal from "../../../../SuccessModal/SuccessModal";

const MultiVarientInner = ({
  prevInnerPage,
  nextInnerPage,
  nextStep,
  prevStep,
  productId,
}) => {
  const [content, setContent] = useState("");
  const [redirectToPrev, setRedirectToPrev] = useState(false); // State to control redirection

  const [uploadDefaultImages, setUploadDefaultImages] = useState([
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
  ]);

  const [selectedImage, setSelectedImage] = useState(null);
  const thumbnailChange = async (e) => {
    const file = e.target.files[0];

    setSelectedImage(URL.createObjectURL(file));
  };

  const handleRemoveThumbnail = () => {
    setSelectedImage(null);

    const fileInput = document.getElementById(`thumbnailInput`); // Assuming you have unique IDs for each input
    if (fileInput) {
      fileInput.value = ""; // Clear the value of the input
    }
  };

  const handleProductImage = async (e, index) => {
    const file = e.target.files[0];

    await setValue(
      `uploadimages[${index}].img`,
      "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg"
    );
    await setValue(
      `uploadimages[${index}].dltimg`,
      "/assest/images/Seller_panel/dashboard/icons/dltt.png"
    );
    await setValue(
      `uploadimages[${index}].selectedImage`,
      URL.createObjectURL(file)
    );
    await setValue(`uploadimages[${index}].status`, true);

    reset(getValues());
  };

  const removeProductImage = async (index) => {
    const fileInput = document.getElementById("product_image_" + index);
    fileInput.value = "";

    await setValue(
      `uploadimages[${index}].img`,
      "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg"
    );
    await setValue(
      `uploadimages[${index}].dltimg`,
      "/assest/images/Seller_panel/dashboard/icons/dltt.png"
    );
    await setValue(`uploadimages[${index}].selectedImage`, null);
    await setValue(`uploadimages[${index}].status`, false);
    await setValue(`uploadimages[${index}].path`, null);
    await setValue(`uploadimages[${index}].image_id`, 0);

    reset(getValues());
  };

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: true };

  const [allVariantId, setAllVariantId] = useState([]);

  const allIdFunction = async (tableDatas) => {
    // Assuming setAllVariantId and setLevelType are synchronous

    var addId = [];
    var type = "";

    tableDatas?.forEach((value, index) => {
      value?.s_p_v_a_one_ds?.forEach(
        (s_p_v_a_one_ds_value, s_p_v_a_one_ds_index) => {
          s_p_v_a_one_ds_value?.s_p_v_a_one_d_as?.forEach(
            (s_p_v_a_one_d_as_value, s_p_v_a_one_d_as_index) => {
              s_p_v_a_one_d_as_value?.s_p_v_a_one_d_a_ds?.forEach(
                (s_p_v_a_one_d_a_ds_value, s_p_v_a_one_d_a_ds_index) => {
                  // Level 2
                  if (s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as.length) {
                    s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as?.forEach(
                      (
                        s_p_v_a_one_d_a_d_as_value,
                        s_p_v_a_one_d_a_d_as_index
                      ) => {
                        s_p_v_a_one_d_a_d_as_value?.s_p_v_a_one_d_a_d_a_ds?.forEach(
                          (
                            s_p_v_a_one_d_a_d_a_ds_value,
                            s_p_v_a_one_d_a_d_a_ds_index
                          ) => {
                            // Level 3
                            if (s_p_v_a_one_d_a_d_a_ds_value?.status) {
                              type = 3;
                              addId.push(s_p_v_a_one_d_a_d_a_ds_value?.id);
                            }
                          }
                        );
                      }
                    );
                  } else {
                    // Level 2
                    if (s_p_v_a_one_d_a_ds_value?.status) {
                      type = 2;
                      addId.push(s_p_v_a_one_d_a_ds_value?.id);
                    }
                  }
                }
              );
            }
          );
        }
      );
    });

    await setLevelType(type);
    await setAllVariantId(addId);

    await getVariantData(type, addId[0]);
  };

  const [dataStatus, setDataStatus] = useState(false);
  const [allSubmited, setAllSubmited] = useState([]);
  const [variantId, setVariantId] = useState("");
  const [levelType, setLevelType] = useState("");
  const getVariantData = async (type, id) => {
    setDataStatus(false);
    const res = await VariantIdDataGet(type, id);
    if (res?.success) {
      setVariantId(id);
      setLevelType(type);
      if (res.data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.id) {
        var technical_description = res.data?.s_p_t_descriptions?.map(
          (value) => ({
            name: value?.name,
            description: value?.description,
          })
        );

        if (!technical_description?.length) {
          technical_description = [
            {
              name: "",
              description: "",
            },
          ];
        }

        reset({
          technical_description: technical_description,
        });

        const images = [];

        res.data.s_p_v_a_one_d_a_d_a_d_images.forEach((image, imageIndex) => {
          images.push({
            img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
            dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
            selectedImage: IMG_URL + image.image,
            path: image.image,
            status: true,
            image_id: image.id,
          });
        });
        for (let i = res.data.s_p_v_a_one_d_a_d_a_d_images.length; i < 8; i++) {
          images.push({
            img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
            dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
            selectedImage: null,
            status: false,
          });
        }

        setValue(`uploadimages`, images);

        setValue(`thumbnail`, res.data.s_p_v_a_o_d_a_d_a_d_o_d.thumbnail);
        setValue("description", res.data.s_p_v_a_o_d_a_d_a_d_o_d?.description);
        setValue(`discountable`, res.data.s_p_v_a_o_d_a_d_a_d_o_d.discountable);
        setValue(
          `open_delivery`,
          res.data.s_p_v_a_o_d_a_d_a_d_o_d.open_delivery
        );

        setValue(`breath`, res.data.s_p_v_a_o_d_a_d_a_d_di.breath);
        setValue(`height`, res.data.s_p_v_a_o_d_a_d_a_d_di.height);
        setValue(`length`, res.data.s_p_v_a_o_d_a_d_a_d_di.length);
        setValue(`weight`, res.data.s_p_v_a_o_d_a_d_a_d_di.weight);
        // setValue(`s_unit_id`, {
        //   value: res.data.s_p_v_a_o_d_a_d_a_d_di.s_unit.id,
        //   label: res.data.s_p_v_a_o_d_a_d_a_d_di.s_unit.name,
        // });

        setValue(
          `after_platform_charges`,
          res.data.s_p_v_a_o_d_a_d_a_d_p_a_s_d.after_platform_charges
        );
        setValue(
          `minimum_order_quantity`,
          res.data.s_p_v_a_o_d_a_d_a_d_p_a_s_d.minimum_order_quantity
        );
        setValue(`mrp`, res.data.s_p_v_a_o_d_a_d_a_d_p_a_s_d.mrp);
        setValue(
          `selling_price`,
          res.data.s_p_v_a_o_d_a_d_a_d_p_a_s_d.selling_price
        );
        setValue(`stock`, res.data.s_p_v_a_o_d_a_d_a_d_p_a_s_d.stock);

        // setValue(`hsn`, res.data.s_p_v_a_o_d_a_d_a_d_t_d.hsn);
        // setValue(`s_gst_id`, {
        //   value: res.data.s_p_v_a_o_d_a_d_a_d_t_d.s_gst.id,
        //   label: res.data.s_p_v_a_o_d_a_d_a_d_t_d.s_gst.name,
        // });

        setValue("hsn_id", {
          value: res.data.s_p_v_a_o_d_a_d_a_d_t_d.hsn_id,
          label: res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.name,
          value: res.data.s_p_v_a_o_d_a_d_a_d_t_d.hsn_id,
          label:
            res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.name +
            " | " +
            res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.gst +
            "%" +
            " | " +
            res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.product_narration,
          name: res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.name,
          percent: res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.gst,
          below: res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.below,
          gst_narration:
            res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.gst_narration,
        });

        setHsnNarration(
          res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.gst_narration
        );

        setSelectedImage(IMG_URL + res.data.s_p_v_a_o_d_a_d_a_d_o_d.thumbnail);

        setDataStatus(true);
        setHsnInputStatus(false);
        setCurrentTab("1");
      } else {
        reset();
        setCurrentTab("1");
        setHsnInputStatus(false);
        setSelectedImage("");
        setValue(`s_gst_id`, null);
        // setValue(`s_unit_id`, null);
        setValue(`description`, "");
        setValue(`uploadimages`, uploadDefaultImages);
      }
    }
  };

  const [tableData, setTableData] = useState([]);
  const getMultipleVariant = async () => {
    const cookie = productId;
    // Cookies.get("s_product_id");
    if (cookie) {
      const res = await multipleVariantGet(cookie);
      if (res?.success) {
        setTableData(res.data.s_p_v_a_ones);
        allIdFunction(res.data.s_p_v_a_ones);
      }
    }
  };

  const { getData, IMG_URL, Select2Data } = useContext(Context);
  const [gsts, setGsts] = useState(null);
  const [units, setUnit] = useState([]);
  const [productInfoIcon, setProductInfoIcon] = useState([]);

  const [platform_charges, setPlatform_Charges] = useState("");
  const [hsnCode, setHsnCode] = useState([]);

  const getDataAll = async () => {
    {
      const res = await GST();
      if (res?.success) {
        setGsts(await Select2Data(res?.data, "s_gst_id", false));
      }
    }
    {
      const res = await Unit();
      if (res?.success) {
        setUnit(await Select2Data(res?.data, "unit_id", false));
      }
    }

    {
      const res = await PlatformCharges();
      if (res?.success) {
        setPlatform_Charges(res.data.no);
      }
    }
    {
      const res = await hsnCodes();
      if (res?.success) {
        setHsnCode(await SelectHsnData(res?.data, "hsn_id", true));
      }
    }

    {
      const res = await ProductInfoIcon();
      if (res?.success) {
        setProductInfoIcon(res?.data);
      }
    }
  };

  const [hsnInputStatus, setHsnInputStatus] = useState(false);
  const [existHsn, setExistHsn] = useState("");
  const [hsnNarration, setHsnNarration] = useState("");
  const handleHsnChange = async (d) => {
    setValue(`hsn_id`, d);
    setHsnNarration(d?.gst_narration);
    if (d.value === "0") {
      setHsnInputStatus(true);
      setValue(`hsn_no`, "");
      setValue(`gst_percent`, "");
      setValue("after_tax_charges", getValues("after_platform_charges"));
    } else {
      setHsnInputStatus(false);

      if (getValues("after_platform_charges")) {
        let totalPrice = getValues("after_platform_charges");
        let taxvalue = 0;
        if (d?.below) {
          if (getValues("after_platform_charges") < Number(d?.below)) {
            taxvalue = (d.percent * getValues("after_platform_charges")) / 100;
          } else {
            taxvalue = (12 * getValues("after_platform_charges")) / 100;
          }
        } else {
          taxvalue = (d.percent * getValues("after_platform_charges")) / 100;
        }

        totalPrice = getValues("after_platform_charges") + taxvalue;

        setValue("after_tax_charges", totalPrice);
      }
    }
  };

  useEffect(() => {
    getMultipleVariant();
    getDataAll();
  }, [productId]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      technical_description: [
        {
          name: "",
          description: "",
        },
      ],
    },
  });

  const commonSubmit = async (data, id) => {
    var sendData = new FormData();

    if (levelType === 2) {
      sendData.append("s_p_v_a_one_d_a_d_id", id);
    }
    if (levelType === 3) {
      sendData.append("s_p_v_a_o_d_a_d_a_d_id", id);
    }

    sendData.append("stock", data.stock);
    sendData.append("mrp", data.mrp);
    sendData.append("selling_price", data.selling_price);
    sendData.append("after_platform_charges", data.after_platform_charges);
    sendData.append("minimum_order_quantity", data.minimum_order_quantity);
    // sendData.append("s_unit_id", data.s_unit_id.value);
    // sendData.append("s_gst_id", data.s_gst_id.value);
    if (hsnInputStatus) {
      sendData.append("hsn_no", data.hsn_no);
      sendData.append("gst_percent", data.gst_percent);
    } else {
      sendData.append("hsn_id", data.hsn_id?.value);
    }
    sendData.append("length", data.length);
    sendData.append("breath", data.breath);
    sendData.append("height", data.height);
    sendData.append("weight", data.weight);
    sendData.append(
      "technical_description",
      JSON.stringify(data.technical_description)
    );
    sendData.append("open_delivery", data.open_delivery ? 1 : 0);
    sendData.append("discountable", data.discountable ? 1 : 0);
    var thumbnailFile = document.getElementById("thumbnailInput");
    if (thumbnailFile.files[0]) {
      sendData.append("thumbnail", thumbnailFile.files[0]);
    } else {
      sendData.append("thumbnail", data.thumbnail);
    }

    let count = 0;

    sendData.append("start", count);
    data.uploadimages.forEach((image, imageIndex) => {
      if (image.status) {
        var file = document.getElementById(`product_image_${imageIndex}`);
        if (file.files[0]) {
          sendData.append("image" + count, file.files[0]);
        }
        count++;
      }
    });
    sendData.append("end", count);
    sendData.append("uploadimages", JSON.stringify(data.uploadimages));

    const res = await VariantIdDataPost(sendData);

    return res;
  };
  const onSubmit = async (data) => {
    var sendData = new FormData();

    if (levelType === 2) {
      sendData.append("s_p_v_a_one_d_a_d_id", variantId);
    }
    if (levelType === 3) {
      sendData.append("s_p_v_a_o_d_a_d_a_d_id", variantId);
    }

    sendData.append("stock", data.stock);
    sendData.append("mrp", data.mrp);
    sendData.append("selling_price", data.selling_price);
    sendData.append("after_platform_charges", data.after_platform_charges);
    sendData.append("minimum_order_quantity", data.minimum_order_quantity);
    // sendData.append("s_unit_id", data.s_unit_id.value);
    // sendData.append("s_gst_id", data.s_gst_id.value);
    if (hsnInputStatus) {
      sendData.append("hsn_no", data.hsn_no);
      sendData.append("gst_percent", data.gst_percent);
    } else {
      sendData.append("hsn_id", data.hsn_id?.value);
    }
    sendData.append("length", data.length);
    sendData.append("breath", data.breath);
    sendData.append("height", data.height);
    sendData.append("weight", data.weight);
    sendData.append(
      "technical_description",
      JSON.stringify(data.technical_description)
    );

    sendData.append("open_delivery", data.open_delivery ? 1 : 0);
    sendData.append("discountable", data.discountable ? 1 : 0);
    var thumbnailFile = document.getElementById("thumbnailInput");
    if (thumbnailFile.files[0]) {
      sendData.append("thumbnail", thumbnailFile.files[0]);
    } else {
      sendData.append("thumbnail", data.thumbnail);
    }

    let count = 0;

    sendData.append("start", count);
    data.uploadimages.forEach((image, imageIndex) => {
      if (image.status) {
        var file = document.getElementById(`product_image_${imageIndex}`);
        if (file.files[0]) {
          sendData.append("image" + count, file.files[0]);
        }
        count++;
      }
    });
    sendData.append("end", count);
    sendData.append("uploadimages", JSON.stringify(data.uploadimages));

    const res = await VariantIdDataPost(sendData);
    if (res?.success) {
      setDataStatus(true);

      const addId = variantId;

      if (!allSubmited.includes(addId)) {
        setAllSubmited([...allSubmited, addId]);
      }
      setError("");
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
      }, 2000);
    }
  };

  const [successModal, setSuccessModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isRemoveChecked, setIsRemoveChecked] = useState(false);
  const handleCommonClick = async () => {
    if (window.confirm("Are you sure you want to make common data for all?")) {
      if (dataStatus) {
        var addId = [];
        for (const value of allVariantId) {
          addId.push(value);
          await commonSubmit(getValues(), value);
        }
        setAllSubmited(addId);
        setIsChecked(true);
        setSuccessModal(true);
        setTimeout(() => {
          setSuccessModal(false);
        }, 2000);
      } else {
        alert("Please Select variant which has data");
      }
    } else {
      setIsChecked(false);
    }
  };

  const handleRemoveClick = async () => {
    if (
      window.confirm("Are you sure you want to remove all data of variants?")
    ) {
      setIsRemoveChecked(true);
      for (const value of allVariantId) {
        await RemoveAll(levelType, value);
      }
      setIsRemoveChecked(false);
      setIsChecked(false);
      getMultipleVariant();
      reset();
      setSelectedImage("");
      setValue(`s_gst_id`, null);
      // setValue(`s_unit_id`, null);
      setValue(`description`, "");
      setValue(`uploadimages`, uploadDefaultImages);
      setAllSubmited([]);
    } else {
      setIsRemoveChecked(false);
    }
  };

  const [error, setError] = useState("");
  const handleNext = async () => {
    if (allSubmited.length === allVariantId.length) {
      nextStep();
    } else {
      setError("Please Store All variant data");
    }
  };

  const handlePrev = () => {
    setRedirectToPrev(true); // Set redirectToNext to true
  };

  const [currentTab, setCurrentTab] = useState("1");

  const handleNextTab = async (type) => {
    if (type === "back") {
      if (currentTab != 1) {
        setCurrentTab(Number(currentTab) - 1);
      }
    } else {
      setCurrentTab(Number(currentTab) + 1);
    }
  };

  // -------------------------------------------------------

  const { append, fields, remove } = useFieldArray({
    control,
    name: "technical_description",
  });

  const validateWordCount = (value) => {
    const wordCount = value.length;
    return wordCount <= 40 || "Maximum 40 words are allowed";
  };

  const validateWordCountDescription = (value) => {
    const wordCount = value.length;
    return wordCount <= 500 || "Maximum 500 words are allowed";
  };

  // Check if redirectToNext is true, if true, render MultiVarientInner
  if (redirectToPrev) {
    return (
      <MultiVariant
        nextStep={nextStep}
        prevInnerPage={prevInnerPage}
        productId={productId}
      />
    );
  }
  return (
    <section className="single-varient-sec">
      <div className="sec-div ">
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-md-12">
            <Table bordered style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="(Remove all)"
                      checked={isRemoveChecked}
                      onClick={handleRemoveClick}
                    />
                  </th>
                  <th>
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="(Common for all)"
                      checked={isChecked}
                      onClick={handleCommonClick}
                    />
                  </th>
                </tr>
                <tr>
                  {tableData?.map((value, index) => (
                    <>
                      <th>
                        {value?.s_attribute?.name} {value?.s_attribute?.defind}
                      </th>
                      {value?.s_p_v_a_one_ds?.map(
                        (s_p_v_a_one_ds_value, s_p_v_a_one_ds_index) =>
                          s_p_v_a_one_ds_index === 0 &&
                          s_p_v_a_one_ds_value?.s_p_v_a_one_d_as?.map(
                            (s_p_v_a_one_d_as_value, s_p_v_a_one_d_as_index) =>
                              s_p_v_a_one_d_as_index === 0 && (
                                <th
                                  key={`${index}-${s_p_v_a_one_ds_index}-${s_p_v_a_one_d_as_index}`}
                                >
                                  {s_p_v_a_one_d_as_value?.s_attribute?.name}{" "}
                                  {s_p_v_a_one_d_as_value?.s_attribute?.defind}
                                </th>
                              )
                          )
                      )}
                      {value?.s_p_v_a_one_ds?.map(
                        (s_p_v_a_one_ds_value, s_p_v_a_one_ds_index) =>
                          s_p_v_a_one_ds_index === 0 &&
                          s_p_v_a_one_ds_value?.s_p_v_a_one_d_as?.map(
                            (s_p_v_a_one_d_as_value, s_p_v_a_one_d_as_index) =>
                              s_p_v_a_one_ds_index === 0 &&
                              s_p_v_a_one_d_as_index === 0 &&
                              s_p_v_a_one_d_as_value?.s_p_v_a_one_d_a_ds?.map(
                                (
                                  s_p_v_a_one_d_a_ds_value,
                                  s_p_v_a_one_d_a_ds_index
                                ) =>
                                  s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as?.map(
                                    (
                                      s_p_v_a_one_d_a_d_as_value,
                                      s_p_v_a_one_d_a_d_as_index
                                    ) =>
                                      s_p_v_a_one_d_a_d_as_index === 0 &&
                                      s_p_v_a_one_d_a_ds_index === 0 && (
                                        <th
                                          key={`${index}-${s_p_v_a_one_ds_index}-${s_p_v_a_one_d_as_index}-${s_p_v_a_one_d_a_ds_index}-${s_p_v_a_one_d_a_d_as_index}`}
                                        >
                                          {
                                            s_p_v_a_one_d_a_d_as_value
                                              ?.s_attribute?.name
                                          }{" "}
                                          {
                                            s_p_v_a_one_d_a_d_as_value
                                              ?.s_attribute?.defind
                                          }
                                        </th>
                                      )
                                  )
                              )
                          )
                      )}
                    </>
                  ))}

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((value, index) =>
                  value?.s_p_v_a_one_ds?.map(
                    (s_p_v_a_one_ds_value, s_p_v_a_one_ds_index) =>
                      s_p_v_a_one_ds_value?.s_p_v_a_one_d_as?.map(
                        (s_p_v_a_one_d_as_value, s_p_v_a_one_d_as_index) =>
                          s_p_v_a_one_d_as_value?.s_p_v_a_one_d_a_ds?.map(
                            (
                              s_p_v_a_one_d_a_ds_value,
                              s_p_v_a_one_d_a_ds_index
                            ) =>
                              // level 2
                              s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as
                                .length ? (
                                s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as?.map(
                                  (
                                    s_p_v_a_one_d_a_d_as_value,
                                    s_p_v_a_one_d_a_d_as_index
                                  ) =>
                                    s_p_v_a_one_d_a_d_as_value?.s_p_v_a_one_d_a_d_a_ds?.map(
                                      (
                                        s_p_v_a_one_d_a_d_a_ds_value,
                                        s_p_v_a_one_d_a_d_a_ds_index
                                      ) => (
                                        //level 3

                                        <tr>
                                          <td>
                                            {
                                              s_p_v_a_one_ds_value
                                                ?.s_sub_attribute?.name
                                            }{" "}
                                            {value?.s_attribute?.defind}{" "}
                                          </td>

                                          <td>
                                            {
                                              s_p_v_a_one_d_a_ds_value
                                                ?.s_sub_attribute?.name
                                            }{" "}
                                            {
                                              s_p_v_a_one_d_as_value
                                                ?.s_attribute?.defind
                                            }
                                          </td>

                                          <td>
                                            {
                                              s_p_v_a_one_d_a_d_a_ds_value
                                                ?.s_sub_attribute?.name
                                            }{" "}
                                            {
                                              s_p_v_a_one_d_a_d_as_value
                                                ?.s_attribute?.defind
                                            }{" "}
                                          </td>
                                          {s_p_v_a_one_d_a_d_a_ds_value?.status ? (
                                            <td
                                              className={
                                                variantId ===
                                                  s_p_v_a_one_d_a_d_a_ds_value?.id &&
                                                levelType === 3
                                                  ? "green-add"
                                                  : "red-add"
                                              }
                                              onLoad={() =>
                                                allIdFunction(
                                                  3,
                                                  s_p_v_a_one_d_a_d_a_ds_value?.id
                                                )
                                              }
                                              onClick={() =>
                                                getVariantData(
                                                  3,
                                                  s_p_v_a_one_d_a_d_a_ds_value?.id
                                                )
                                              }
                                            >
                                              Add
                                            </td>
                                          ) : (
                                            <td></td>
                                          )}
                                        </tr>
                                      )
                                    )
                                )
                              ) : (
                                <tr>
                                  <td>
                                    {
                                      s_p_v_a_one_ds_value?.s_sub_attribute
                                        ?.name
                                    }{" "}
                                    {value?.s_attribute?.defind}{" "}
                                  </td>

                                  <td>
                                    {
                                      s_p_v_a_one_d_a_ds_value?.s_sub_attribute
                                        ?.name
                                    }{" "}
                                    {
                                      s_p_v_a_one_d_as_value?.s_attribute
                                        ?.defind
                                    }
                                  </td>

                                  {s_p_v_a_one_d_a_ds_value?.status ? (
                                    <td
                                      className={
                                        variantId ===
                                          s_p_v_a_one_d_a_ds_value?.id &&
                                        levelType === 2
                                          ? "green-add"
                                          : "red-add"
                                      }
                                      onLoad={() =>
                                        allIdFunction(
                                          2,
                                          s_p_v_a_one_d_a_ds_value?.id
                                        )
                                      }
                                      onClick={() =>
                                        getVariantData(
                                          2,
                                          s_p_v_a_one_d_a_ds_value?.id
                                        )
                                      }
                                    >
                                      Add
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                </tr>
                              )
                          )
                      )
                  )
                )}
              </tbody>
            </Table>
          </div>
          <div className="col-xxl-6 col-xl-6 col-md-12">
            <div className="single-variant-tabsshd multi-scroll-box">
              {variantId && (
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="1"
                  activeKey={currentTab}
                  className="mb-3"
                >
                  <Row>
                    <Nav variant="pills" className="flex-row">
                      <Nav.Item>
                        <Nav.Link eventKey="1" onClick={() => setCurrentTab(1)}>
                          Product Images
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="2" onClick={() => setCurrentTab(2)}>
                          Price & Stock Details
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="3" onClick={() => setCurrentTab(3)}>
                          Dimension
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="4"  onClick={() => setCurrentTab(1)}>Tax details</Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link eventKey="4" onClick={() => setCurrentTab(4)}>
                          Other Details
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="5" onClick={() => setCurrentTab(5)}>
                          Thumbnail
                        </Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link eventKey="5" onClick={() => setCurrentTab(5)}>
                          Technical Description
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Tab.Content>
                      <Tab.Pane eventKey="1">
                        <div className="tab-content-data">
                          <h6 className="proppdp">
                            Product Photos <span>(Max 8)</span>
                          </h6>
                          <div className="tab-content-data">
                            <div className="uploadbox">
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="slider-div">
                                    <Swiper
                                      slidesPerView={3.5}
                                      spaceBetween={30}
                                      freeMode={true}
                                      pagination={{
                                        clickable: false,
                                      }}
                                      modules={[FreeMode, Pagination]}
                                      className="mySwiper"
                                    >
                                      {getValues(`uploadimages`)?.map(
                                        (data, index) => (
                                          <SwiperSlide>
                                            <div className="upd-card">
                                              <label
                                                htmlFor={`product_image_${index}`}
                                              >
                                                <img
                                                  className="slide-img"
                                                  src={
                                                    data.selectedImage
                                                      ? data.selectedImage
                                                      : data.img
                                                  }
                                                />
                                              </label>

                                              <input
                                                type="file"
                                                className="d-none"
                                                accept="image/*"
                                                id={`product_image_${index}`}
                                                onChange={async (e) => {
                                                  const result =
                                                    await validateImage(
                                                      e.target.files[0],
                                                      500
                                                    );
                                                  result &&
                                                    (await handleProductImage(
                                                      e,
                                                      index
                                                    ));
                                                }}
                                              />
                                              {data?.selectedImage && (
                                                <img
                                                  className="dltt-slide"
                                                  onClick={() =>
                                                    removeProductImage(index)
                                                  }
                                                  src={data.dltimg}
                                                />
                                              )}
                                            </div>
                                          </SwiperSlide>
                                        )
                                      )}
                                    </Swiper>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="produuyct-discri">
                                    <h6 className="tttt">Image Resolution</h6>
                                    <p className="testtt">
                                      Use clear color images with a ratio of
                                      1:2. and max 500KB
                                    </p>
                                    <h6 className="tttt">Image Guidelines</h6>
                                    <p className="testtt">
                                      Upload authentic product photos taken in
                                      bright lighting
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="2">
                        <div className="tab-content-data">
                          <div className="row">
                            <div className="col-md-4 mb-3">
                              <Form.Label>
                                Stock<span className="mandatory-star"> *</span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-title">Stock</Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle-info"
                                    className="ms-3"
                                  />
                                </OverlayTrigger>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                name={`stock`}
                                placeholder="Stock.."
                                className={classNames("", {
                                  "is-invalid": errors.stock,
                                })}
                                {...register(`stock`, {
                                  required: "Stock required",
                                })}
                              />
                            </div>

                            <div className="col-md-4  mb-3">
                              <Form.Label> MRP</Form.Label>
                              <Form.Control
                                type="text"
                                name={`mrp`}
                                placeholder="MRP.."
                                className={classNames("", {
                                  "is-invalid": errors.mrp,
                                })}
                                {...register(`mrp`, {
                                  required: "MRP required",
                                })}
                                onChange={(e) => {
                                  if (
                                    getValues("selling_price") +
                                      Number(platform_charges) >
                                    Number(e.target.value)
                                  ) {
                                    setValue("selling_price", "");
                                  }
                                }}
                              />
                            </div>
                            <div className="col-md-4  mb-3">
                              <Form.Label> Selling Price</Form.Label>
                              <Form.Control
                                type="number"
                                name={`selling_price`}
                                placeholder="Selling Price.."
                                className={classNames("", {
                                  "is-invalid": errors.selling_price,
                                })}
                                {...register(`selling_price`, {
                                  required: "Selling Price required",
                                })}
                                onChange={(e) => {
                                  if (
                                    Number(e.target.value) +
                                      Number(platform_charges) >
                                    getValues("mrp")
                                  ) {
                                    setValue("selling_price", "");
                                  } else {
                                    setValue("selling_price", e.target.value);

                                    setValue(
                                      "after_platform_charges",
                                      Number(e.target.value) +
                                        Number(platform_charges)
                                    );

                                    let price =
                                      Number(e.target.value) +
                                      Number(platform_charges);

                                    let totalPrice = price;
                                    let taxvalue = 0;
                                    if (getValues("hsn_id")?.below) {
                                      if (
                                        price <
                                        Number(getValues("hsn_id")?.below)
                                      ) {
                                        taxvalue =
                                          (getValues("hsn_id")?.percent *
                                            price) /
                                          100;
                                      } else {
                                        taxvalue = (12 * price) / 100;
                                      }
                                    } else {
                                      taxvalue =
                                        (getValues("hsn_id")?.percent * price) /
                                        100;
                                    }

                                    totalPrice = price + taxvalue;

                                    setValue("after_tax_charges", totalPrice);
                                  }
                                }}
                              />
                              <sup className="text-info">
                                Platform Charge - ₹ {platform_charges}
                              </sup>
                            </div>
                            <div className="col-md-12  mb-3">
                              <Form.Label>HSN</Form.Label>

                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-title">
                                    {productInfoIcon?.hsn_code}
                                  </Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-circle-info"
                                  className="ms-3"
                                />
                              </OverlayTrigger>
                              <Controller
                                name={`hsn_id`} // name of the field
                                {...register(`hsn_id`, {
                                  required: "Select HSN",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors?.hsn_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={hsnCode}
                                    onChange={(e) => handleHsnChange(e)}
                                  />
                                )}
                              />
                            </div>
                            {hsnInputStatus && (
                              <>
                                <div className="col-md-4  mb-3">
                                  <Form.Label>HSN Code</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name={`hsn_no`}
                                    placeholder="HSN Code"
                                    className={classNames("", {
                                      "is-invalid": errors?.hsn_no,
                                    })}
                                    {...register(`hsn_no`, {
                                      required: "HSN Code required",
                                    })}
                                  />
                                </div>
                                <div className="col-md-4  mb-3">
                                  <Form.Label>Gst Percent</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name={`gst_percent`}
                                    placeholder="Gst Percent"
                                    className={classNames("", {
                                      "is-invalid": errors?.gst_percent,
                                    })}
                                    {...register(`gst_percent`, {
                                      required: "Gst Percent required",
                                    })}
                                    onChange={(e) => {
                                      if (getValues("after_platform_charges")) {
                                        let taxvalue =
                                          (e.target.value *
                                            getValues(
                                              "after_platform_charges"
                                            )) /
                                          100;

                                        let totalPrice =
                                          getValues("after_platform_charges") +
                                          taxvalue;

                                        setValue(
                                          "after_tax_charges",
                                          totalPrice
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </>
                            )}
                            <div className="col-md-12  mb-3">
                              <sub className="text-info">{hsnNarration}</sub>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-md-4  mb-3">
                              <Form.Label>
                                After platform charges (selling price)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                disabled
                                name={`after_platform_charges`}
                                placeholder="After platform charges.."
                                className={classNames("", {
                                  "is-invalid": errors.after_platform_charges,
                                })}
                                {...register(`after_platform_charges`, {
                                  required: "After platform charges required",
                                })}
                              />
                              <sup className="text-info">
                                After Platform Charge should be less than MRP
                              </sup>
                            </div>

                            <div className="col-xxl-6 col-xl-6 col-md-4  mb-3">
                              <Form.Label>Minimum Order Quantity</Form.Label>
                              <Form.Control
                                type="number"
                                name={`minimum_order_quantity`}
                                placeholder="Minimum Order Quantity"
                                className={classNames("", {
                                  "is-invalid": errors.minimum_order_quantity,
                                })}
                                {...register(`minimum_order_quantity`, {
                                  required:
                                    "Minimum order quantity is required",
                                  min: {
                                    value: 1,
                                    message:
                                      "Minimum order quantity must be greater than zero",
                                  },
                                })}
                              />
                              {errors.minimum_order_quantity && (
                                <div className="invalid-feedback">
                                  {errors.minimum_order_quantity.message}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-6 col-xl-6 col-md-4  mb-3">
                              <Form.Label>
                                After tax charges (selling price)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                disabled
                                name={`after_tax_charges`}
                                placeholder="After tax charges.."
                                className={classNames("", {
                                  "is-invalid": errors.after_tax_charges,
                                })}
                                {...register(`after_tax_charges`)}
                              />
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="3">
                        <div className="tab-content">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <Form.Label>
                                Length<span className="mandatory-star"> *</span>
                                <span>(CM)</span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-title">Length</Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle-info"
                                    className="ms-3"
                                  />
                                </OverlayTrigger>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                name={`length`}
                                placeholder="Length.."
                                className={classNames("", {
                                  "is-invalid": errors.length,
                                })}
                                {...register(`length`, {
                                  required: "Length required",
                                })}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <Form.Label>
                                Breadth
                                <span className="mandatory-star"> *</span>
                                <span>(CM)</span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-title">
                                      Breadth
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle-info"
                                    className="ms-3"
                                  />
                                </OverlayTrigger>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                name={`breath`}
                                placeholder="Breadth.."
                                className={classNames("", {
                                  "is-invalid": errors.breath,
                                })}
                                {...register(`breath`, {
                                  required: "Breadth required",
                                })}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <Form.Label>
                                Height<span className="mandatory-star"> *</span>
                                <span>(CM)</span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-title">Height</Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle-info"
                                    className="ms-3"
                                  />
                                </OverlayTrigger>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                name={`height`}
                                placeholder="Height.."
                                className={classNames("", {
                                  "is-invalid": errors.height,
                                })}
                                {...register(`height`, {
                                  required: "Height required",
                                })}
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <div className="row">
                                <div className="col-md-7">
                                  <Form.Label>
                                    Weight (KG)
                                    <span className="mandatory-star"> *</span>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip-title">
                                          Weight
                                        </Tooltip>
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-circle-info"
                                        className="ms-3"
                                      />
                                    </OverlayTrigger>
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    name={`weight`}
                                    placeholder="Weight.."
                                    className={classNames("", {
                                      "is-invalid": errors.weight,
                                    })}
                                    {...register(`weight`, {
                                      required: "Weight required",
                                    })}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-md-6 mb-3">
                              <div className="row">
                                <div className="col-md-8">
                                  <Form.Label>
                                    Select Unit
                                    <span className="mandatory-star"> *</span>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip-title">
                                          Product
                                        </Tooltip>
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-circle-info"
                                        className="ms-3"
                                      />
                                    </OverlayTrigger>
                                  </Form.Label>

                                  <Controller
                                    name={`s_unit_id`} // name of the field
                                    {...register(`s_unit_id`, {
                                      required: "Select Units",
                                    })}
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        styles={{
                                          control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: errors?.s_unit_id
                                              ? "red"
                                              : baseStyles,
                                          }),
                                        }}
                                        {...field}
                                        options={units}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="4">
                        <div className="tab-content">
                          <div className="row">
                            <div className="col-md-5">
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Discountable ?"
                                className={classNames("", {
                                  "is-invalid": errors.discountable,
                                })}
                                name="discountable"
                                {...register("discountable")}
                              />
                            </div>
                            <div className="col-md-5">
                              <Form.Check
                                type="switch"
                                id="custom-switch2"
                                label="Open Delivery ?"
                                className={classNames("", {
                                  "is-invalid": errors.open_delivery,
                                })}
                                name="open_delivery"
                                {...register("open_delivery")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="tab-content mt-2">
                          <p className="thumbnail-txt">Thumbnail</p>
                          <div className="row">
                            <div className="col-md-8">
                              <input
                                type="file"
                                id={"thumbnailInput"}
                                className={classNames("", {
                                  "is-invalid": errors.thumbnail,
                                })}
                                accept="image/*"
                                name={`thumbnail`}
                                {...register(`thumbnail`, {
                                  // required: "Thumbnails is required",
                                })}
                                onChange={async (e) => {
                                  const result = await validateImage(
                                    e.target.files[0],
                                    500
                                  );
                                  !result && setValue("thumbnail", null);
                                  result && thumbnailChange(e);
                                }}
                              />
                              <br />
                              <span className="jpgimg">
                                {selectedImage ? selectedImage.name : ""}
                              </span>
                            </div>
                            <div className="col-md-4">
                              <div className="uplod-imm-div">
                                {selectedImage && (
                                  <>
                                    <img
                                      className="up-imgdg"
                                      src={selectedImage}
                                    />
                                    <img
                                      className="dlttt"
                                      onClick={() => handleRemoveThumbnail()}
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/Seller_panel/dashboard/icons/dltt.png"
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {errors.thumbnail && (
                            <span className="text-danger">
                              {errors.thumbnail.message}
                            </span>
                          )}
                        </div>
                      </Tab.Pane>

                      {/* <Tab.Pane eventKey="5">
                        <div className="tab-content">
                          <p className="thumbnail-txt">Thumbnail</p>
                          <div className="row">
                            <div className="col-md-8">
                              <input
                                type="file"
                                id={"thumbnailInput"}
                                className={classNames("", {
                                  "is-invalid": errors.thumbnail,
                                })}
                                accept="image/*"
                                name={`thumbnail`}
                                {...register(`thumbnail`, {
                                  required: "Thumbnails is required",
                                })}
                                onChange={async (e) => {
                                  const result = await validateImage(
                                    e.target.files[0],
                                    500
                                  );
                                  !result && setValue("thumbnail", null);
                                  result && thumbnailChange(e);
                                }}
                              />
                              <br />
                              <span className="jpgimg">
                                {selectedImage ? selectedImage.name : ""}
                              </span>
                            </div>
                            <div className="col-md-4">
                              <div className="uplod-imm-div">
                                {selectedImage && (
                                  <>
                                    <img
                                      className="up-imgdg"
                                      src={selectedImage}
                                    />
                                    <img
                                      className="dlttt"
                                      onClick={() => handleRemoveThumbnail()}
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/Seller_panel/dashboard/icons/dltt.png"
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {errors.thumbnail && (
                            <span className="text-danger">
                              {errors.thumbnail.message}
                            </span>
                          )}
                        </div> 
                      </Tab.Pane>*/}

                      <Tab.Pane eventKey="5">
                        <div className="tab-content">
                          <div className="row">
                            <div className="select-to-div">
                              <Form.Label>
                                Technical Description :- Name Maximum 40 words
                                And Maximum 500 words are allowed
                              </Form.Label>

                              {fields?.map((value, index) => (
                                <>
                                  <div className="row">
                                    <div className="col-xxl-6 col-md-6 col-12 align-items-center d-flex mt-auto mb-2">
                                      <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.technical_description &&
                                            errors?.technical_description[
                                              index
                                            ] &&
                                            errors?.technical_description[index]
                                              ?.name,
                                        })}
                                        name={`technical_description[${index}].name`}
                                        {...register(
                                          `technical_description[${index}].name`,
                                          {
                                            required: "Name is required",
                                            validate: validateWordCount,
                                          }
                                        )}
                                      />
                                    </div>
                                    <div className="col-xxl-6 col-md-6 col-12 align-items-center d-flex mt-auto mb-2">
                                      <Form.Control
                                        type="text"
                                        placeholder="Description"
                                        className={classNames("me-2", {
                                          "is-invalid":
                                            errors?.technical_description &&
                                            errors?.technical_description[
                                              index
                                            ] &&
                                            errors?.technical_description[index]
                                              ?.description,
                                        })}
                                        name={`technical_description[${index}].description`}
                                        {...register(
                                          `technical_description[${index}].description`,
                                          {
                                            required: "Description is required",
                                            validate:
                                              validateWordCountDescription,
                                          }
                                        )}
                                      />
                                      {fields?.length !== 1 && (
                                        <button
                                          className="delet-row"
                                          onClick={() => remove(index)}
                                          type="button"
                                        >
                                          <FontAwesomeIcon icon="fa-solid fa-minus" />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ))}
                              {fields?.length < 10 && (
                                <button
                                  className="addrowiccc"
                                  type="button"
                                  style={{
                                    float: "right",
                                  }}
                                  onClick={() =>
                                    append({
                                      name: "",
                                    })
                                  }
                                >
                                  <img
                                    style={{ margin: "0px" }}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assest/images/Seller_panel/dashboard/icons/addicooo.png"
                                    }
                                  />
                                </button>
                              )}

                              {/* <Controller
              name="w_term"
              {...register("w_term", {
                required: "Warranty Terms is required",
              })}
              control={control}
              render={({ field }) => (
                <JoditEditor
                  value={content}
                  tabIndex={1}
                  onBlur={(newContent) => setContent(newContent)}
                  {...field}
                />
              )}
            /> */}
                            </div>
                          </div>
                          {/* <Controller
                            name={`technical_description`}
                            control={control}
                            // rules={{ required: "Description is required" }}
                            render={({ field }) => (
                              <div>
                                <JoditEditor
                                  value={content}
                                  tabIndex={1}
                                  onBlur={(newContent) =>
                                    setContent(newContent)
                                  }
                                  {...field}
                                />
                                {errors?.technical_description && (
                                  <span className="text-danger">
                                    {errors?.technical_description.message}
                                  </span>
                                )}
                              </div>
                            )}
                          /> */}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                  {/* <div className="common-steps-btn">
                    <button
                      type="button"
                      onClick={() => handleNextTab("back")}
                      className="back-btn"
                    >
                      Back
                    </button>
                    {currentTab == 5 ? (
                      <></>
                    ) : (
                      <button
                        type="button"
                        onClick={() => handleNextTab("next")}
                        className="next-btn"
                      >
                        Next
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                      className="next-btn"
                    >
                      Save
                    </button>
                  </div> */}
                  {error && <sub className="text-danger">{error}</sub>}
                </Tab.Container>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="common-steps-btn">
        <button type="button" onClick={handlePrev} className="back-btn">
          Back
        </button>

        <button type="button" onClick={handleNext} className="next-btn">
          Next
        </button>
      </div>

      <SuccessModal show={successModal} onHide={() => setSuccessModal(false)} />
    </section>
  );
};

export default MultiVarientInner;
