import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip } from "react-bootstrap"; // Import OverlayTrigger and Tooltip
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoditEditor from "jodit-react";
import classNames from "classnames";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

import "../CommonDetails.css";

function Warrentytab({
  reset,
  errors,
  register,
  Controller,
  control,
  serviceTypes,
  addressStatus,
  setAddressStatus,
  getValues,
  setValue,
}) {
  const [content, setContent] = useState("");

  const [provided, setProvided] = useState([
    {
      value: "Brand",
      label: "Brand",
    },
    {
      value: "other",
      label: "Other",
    },
  ]);
  const [providedInput, setProvidedInput] = useState(false);
  const handleChange = async (d) => {
    await setValue("w_provided_by", d);
    if (d.value === "other") {
      setProvidedInput(true);
    } else {
      setProvidedInput(false);
    }
  };
  return (
    <>
      <section className="warrenty-tab">
        <div className="py-2">
          <div className="row">
            <div className="col-xxl-4 col-lg-4 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Warantee Provided By <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-title">Warantee Provided By</Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Controller
                  name="w_provided_by"
                  {...register("w_provided_by", {
                    required:
                      getValues("s_service_term_id") == 1
                        ? "Warranty Provided By is required"
                        : false,
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors?.w_provided_by
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      options={provided}
                      onChange={(e) => handleChange(e)}
                    />
                  )}
                />
                {getValues("w_provided_by")?.value === "other" && (
                  <Form.Control
                    type="text"
                    placeholder="Warantee Provided By"
                    className={classNames("mt-3", {
                      "is-invalid": errors?.w_other_provided_by,
                    })}
                    name="w_other_provided_by"
                    {...register("w_other_provided_by", {
                      required:
                        getValues("s_service_term_id") == 1
                          ? "Warantee is required"
                          : false,
                    })}
                  />
                )}
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-lg-4 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Warranty Period of Product <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-category">
                        Warranty Period of Product
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Warranty Period"
                  className={classNames("", {
                    "is-invalid": errors?.w_period,
                  })}
                  name="w_period"
                  {...register("w_period", {
                    required:
                      getValues("s_service_term_id") == 1
                        ? "Warranty is required"
                        : false,
                  })}
                />
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Helpline Number <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-brand">Helpline Number</Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Helpline"
                  className={classNames("", {
                    "is-invalid": errors?.w_helpline,
                  })}
                  name="w_helpline"
                  {...register("w_helpline", {
                    required:
                      getValues("s_service_term_id") == 1
                        ? "Helpline is required"
                        : false,
                  })}
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-xxl-4 col-md-4 col-4">
            <p className="me-2 minitext">Service Type</p>
            <div className="">
              {serviceTypes?.map((value, index) => (
                <Form.Check
                  type="radio"
                  label={value?.name}
                  name="s_service_type_id"
                  value={value?.id}
                  defaultChecked={index === 0}
                  className={classNames("my-2", {
                    "is-invalid": errors?.s_service_type_id,
                  })}
                  {...register("s_service_type_id", {
                    required:
                      getValues("s_service_term_id") == 1
                        ? "Service Type is required"
                        : false,
                  })}
                  onClick={() =>
                    setAddressStatus(value?.name === "Go to Manufacture Spot")
                  }
                />
              ))}
            </div>
          </div>
          {addressStatus && (
            <div className="col-xxl-8 col-md-8 col-8">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Address{" "}
                  <span className="span-text">( if manufacture spot )</span>{" "}
                  <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-brand">Address</Tooltip>}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter address"
                  rows={4}
                  className={classNames("", {
                    "is-invalid": errors?.w_address,
                  })}
                  name="w_address"
                  {...register("w_address", {
                    required:
                      getValues("s_service_term_id") == 1
                        ? "Address is required"
                        : false,
                  })}
                />
              </Form.Group>
            </div>
          )}
        </div>
        <div className="row">
          <div className="select-to-div">
            <Form.Label>Warranty Terms</Form.Label>

            <Controller
              name="w_term"
              {...register("w_term", {
                required:
                  getValues("s_service_term_id") == 1
                    ? "Warranty Terms is required"
                    : false,
              })}
              control={control}
              render={({ field }) => (
                <JoditEditor
                  value={content}
                  tabIndex={1}
                  onBlur={(newContent) => setContent(newContent)}
                  {...field}
                />
              )}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Warrentytab;
