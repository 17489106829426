import React, { useState, useContext } from "react";
import "./Online_Store.css";
import { Rating } from "react-simple-star-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../../utils/context";

function Online_Store({ data }) {
  const [rating, setRating] = useState(0);

  console.log("Online_Store", data);

  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const { IMG_URL } = useContext(Context);

  return (
    <>
      <div className="online-store text-center">
        <div className="pt-4">
          {/* <div className="store-holder">
            <img src={IMG_URL + data?.image} className="store-img" />
          </div> */}
          <h2 className="mt-4">{data?.s_firm_detail?.name}</h2>
          {/* <div className="start-holder">
            <Rating
              onClick={handleRating}
              initialValue={rating}
              fillColor="#DF4223"
              className="react-simple-star-rating start"
              SVGclassName="star-svg"
            />
          </div> */}
          <div className="location-holder my-1">
            {/* <FontAwesomeIcon
              icon="fa-solid fa-location-dot"
              className="location-icon me-1"
            /> */}
            <span>{data?.s_firm_address_details?.city?.name}</span>
          </div>
          {/* <p>{distanceLocation}</p> */}
        </div>
      </div>
    </>
  );
}

export default Online_Store;
