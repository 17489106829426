import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../../api";

export const getAllData = async (currentPage, perPage, search, domain_id) => {
  try {
    return await getData(
      `/seller/info-website/masters/about-us/banner?` +
      // `page=${currentPage}&per_page=${perPage}&term=${search || ""}`+
      `&domain_id=${domain_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const changeDataStatus = async (id) => {
  try {
    return await editStatusData(
      `/seller/info-website/masters/about-us/banner/${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const Delete = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/info-website/masters/about-us/banner/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const add = async (finalData) => {
  try {
    return await postData(
      `/seller/info-website/masters/about-us/banner`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const edit = async (id, finalData) => {
  try {
    return await postData(
      `/seller/info-website/masters/about-us/banner/${id}`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingledata = async (id) => {
  try {
    return await getData(`/seller/info-website/masters/about-us/banner/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
