import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import classNames from "classnames";
import { Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";

import {
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../utils/apis/master/Master";
import { Errors, Placeholders } from "../../../../utils/errors";
import {
  firmAddressDetailPost,
  getfirmAddressDetail,
} from "../../../../utils/apis/btc/Btc";

import { Context } from "../../../../utils/context";

function Stepsix({ nextStep, prevStep, userDetails, setPage }) {
  const [modalShow, setModalShow] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      data: [
        {
          name: "",
          add_line_one: "",
          add_line_two: "",
          city_id: "",
          state_id: "",
          pincode_id: "",
          country_id: "",
        },
      ],
    },
  });
  const { append, fields, remove } = useFieldArray({
    control,
    name: "data",
  });

  // const options = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ];
  // const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  // const [firmAddressTypes, setFirmAddressType] = useState([]);
  // const [cities, setCities] = useState("");
  // const [states, setStates] = useState("");
  // const [countries, setCountries] = useState("");
  // const [pincodes, setPincodes] = useState([]);
  // const getMasters = async () => {
  //   {
  //     const res = await firmAddressType();
  //     if (res?.success) {
  //       console.log(res, 'submit');
  //       setFirmAddressType(res.data);
  //     }
  //   }
  // };

  // const handlePincodeChange = async (e) => {
  //   const res = await Pincode(e);
  //   if (res?.success) {
  //     const data = await Select2Data(res.data, "pincode_id", false);
  //     setPincodes(data);
  //   }
  // };
  // const handlePincodeSelect = async (id) => {
  //   const res = await pincodeWiseAll(id);
  //   if (res?.success) {
  //     console.log(res);
  //     setCities(res.data.city.name);
  //     setStates(res.data.state.name);
  //     setCountries(res.data.country.name);
  //     reset({
  //       city_id: res.data.city_id,
  //       state_id: res.data.state_id,
  //       country_id: res.data.country_id,
  //       pincode_id: {
  //         value: res.data.id,
  //         label: res.data.name,
  //       },
  //     });
  //   }
  // };
  // s_personal_details_id: req.body.s_personal_details_id,

  // const onSubmit = async (data) => {
  //   alert('ss');
  //   console.log(data, "form");
  //   data.pincode_id = data?.pincode_id.value;
  //   data.btc_personal_details_id = userDetails?.btc_personal_details_id;
  //   const res = await firmAddressDetailPost(data);
  //   if (res?.success) {
  //     console.log(res, "addresspost");
  //      nextStep();
  //    // setPage();
  //   }
  // };
  // const { append, fields, remove } = useFieldArray({
  //   control,
  //   name: "data",
  // });

  const onSubmit = async (d) => {
    d.btc_personal_details_id = userDetails?.btc_personal_details_id;
    //d.percentage = sellerPercentageSend.stepFour;
    const res = await firmAddressDetailPost(d);
    if (res?.success) {
      nextStep();
    }
  };

  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [pincodes, setPincodes] = useState([]);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  const handlePincodeChange = async (e) => {
    const res = await Pincode(e);
    if (res?.success) {
      const data = await Select2Data(res.data, "pincode_id", false);
      setPincodes(data);
    }
  };
  const handlePincodeSelect = async (id, index) => {
    const res = await pincodeWiseAll(id);
    if (res?.success) {
      const updatedCities = [...cities];
      updatedCities[index] = res.data.city.name;
      setCities(updatedCities);

      const updatedStates = [...states];
      updatedStates[index] = res.data.state.name;
      setStates(updatedStates);

      const updatedCountries = [...countries];
      updatedCountries[index] = res.data.country.name;
      setCountries(updatedCountries);

      setValue(`data[${index}].city_id`, res.data.city.id);
      setValue(`data[${index}].state_id`, res.data.state.id);
      setValue(`data[${index}].country_id`, res.data.country.id);
    }
  };

  const getfirmDropAddressIn = async () => {
    const res = await getfirmAddressDetail(
      userDetails?.btc_personal_details_id
    );
    if (res?.success) {
      //  setDropCount(res.data.length);
      if (res.data.length > 0) {
        var updatedCities = [...cities];
        var updatedStates = [...states];
        var updatedCountries = [...countries];
        res.data.forEach((item, index) => {
          updatedCities[index] = item.city.name;
          updatedStates[index] = item.state.name;
          updatedCountries[index] = item.country.name;

          res.data[index].pincode_id = {
            value: item.pincode_id,
            label: item.pincode?.name,
          };
        });
        setCities(updatedCities);
        setStates(updatedStates);
        setCountries(updatedCountries);
        reset({ data: res.data });
      } else {
        reset({
          data: [
            {
              name: "",
              add_line_one: "",
              add_line_two: "",
              city_id: "",
              state_id: "",
              pincode_id: "",
              country_id: "",
            },
          ],
        });
      }
    }
  };

  useEffect(() => {
    getfirmDropAddressIn();
  }, []);

  return (
    <div className="dropAddressDetails">
      <div className="stepFourconstContent mb-4">
        <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
          <div className="row pickupHeadRow">
            <div className="col-lg-6 p-0">
              <div className="stepfourHead">
                <h3>Address</h3>
              </div>
            </div>
            <div className="col-lg-6 p-0 ">
              <div className="text-end">
                {/* <Button
                className="btn StepfoursaveBtn"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button> */}
              </div>
            </div>
          </div>
          <div>
            {fields.map((item, index) => (
              <div key={item.id} className="row">
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Enter name of address</Form.Label>
                    <Form.Control
                      type="text"
                      className={classNames("", {
                        "is-invalid":
                          errors.data &&
                          errors.data[index] &&
                          errors.data[index].name,
                      })}
                      name={`data[${index}].name`}
                      placeholder="Enter Name of address"
                      defaultValue={item.name}
                      {...register(`data[${index}].name`, {
                        required: "Name of address is required",
                      })}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-6"></div>

                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Enter Address Line 1</Form.Label>
                    <Form.Control
                      type="text"
                      className={classNames("", {
                        "is-invalid":
                          errors.data &&
                          errors.data[index] &&
                          errors.data[index].add_line_one,
                      })}
                      name={`data[${index}].add_line_one`}
                      placeholder="Enter Address Line 1"
                      defaultValue={item.add_line_one}
                      {...register(`data[${index}].add_line_one`, {
                        required: "Address line 1 is required",
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Address line 2</Form.Label>
                    <Form.Control
                      type="text"
                      className={classNames("", {
                        "is-invalid":
                          errors.data &&
                          errors.data[index] &&
                          errors.data[index].add_line_two,
                      })}
                      name={`data[${index}].add_line_two`}
                      placeholder="Enter Address Line 2"
                      defaultValue={item.add_line_two}
                      {...register(`data[${index}].add_line_two`, {
                        required: "Address line 2 is required",
                      })}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>City/District:</Form.Label>

                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.city,
                      })}
                      type="text"
                      name="city_id"
                      readOnly
                      value={cities[index]}
                      placeholder={Placeholders.city}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.state,
                      })}
                      type="text"
                      name="state_id"
                      value={states[index]}
                      placeholder={Placeholders.state}
                      readOnly
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Pincode</Form.Label>

                    <Controller
                      name={`data[${index}].pincode_id`}
                      {...register(`data[${index}].pincode_id`, {
                        required: Errors.pincode,
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.pincode_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={pincodes}
                          onInputChange={(selectedOption) => {
                            handlePincodeChange(selectedOption);
                          }}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            handlePincodeSelect(selectedOption.value, index);
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.country,
                      })}
                      type="text"
                      name="country_id"
                      readOnly
                      value={countries[index]}
                      placeholder={Placeholders.country}
                    />
                  </Form.Group>
                </div>

                {index !== 0 && (
                  <div className="addremoveadress">
                    <div className="text-end Addnewadresstxt">
                      <p className="" onClick={() => remove(index)}>
                        Remove Address{" "}
                        <FontAwesomeIcon icon="fa-solid fa-minus" />
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}

            <div className="addremoveadress">
              <div className="text-end Addnewadresstxt">
                <p
                  onClick={() =>
                    append({
                      name: "",
                      add_line_one: "",
                      add_line_two: "",
                      city_id: "",
                      state_id: "",
                      pincode_id: "",
                      country_id: "",
                    })
                  }
                >
                  Add New Address <FontAwesomeIcon icon="fa-solid fa-plus" />
                </p>
              </div>
            </div>
          </div>
        </Form>
        <div className="col-md-12">
          <div className="text-end btnMainn apply_now_btn">
            {/* <p onClick={() => setModalShow(true)}>
              By continuing, I agree to Netpurti's
              <span className="termstxt">Terms of Use & Privacy Policy</span>
            </p> */}
            {/* <Link to={"/seller/seller-dashboard/"}>
                  <Button className="back-btn me-3">Skip</Button>
                </Link> */}

            <div className="registerContinueMain">
              <Button onClick={prevStep} className="back-btn">
                Back
              </Button>
            </div>
            <div className="registerContinueMain">
              <Button
                className="tabs-btn"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Register & Continue
              </Button>
            </div>
            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stepsix;
