import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import parse from "html-react-parser";
import './MovingPerson.css'
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Pagination } from "swiper/modules";
import "swiper/css/pagination"
import "swiper/swiper-bundle.css"
import 'swiper/css';
import { Container } from 'react-bootstrap';

const MovingPerson = () => {
    const OnlineSalesDetails = [
        {
            imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/first.png",
            headingname: "Introducing Mahesh Roy",
            salespersoncontent: "Introducing Ganesh  Bajaj, who turned his family business into a 3 crore  enterprise, flourishing with Netpurti's partnership"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/second.png",
            headingname: "Introducing Ganesh Bajaj",
            salespersoncontent: "Introducing Ganesh  Bajaj, who turned his family business into a 3 crore  enterprise, flourishing with Netpurti's partnership"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/first.png",
            headingname: "Introducing Mahesh Roy",
            salespersoncontent: "Introducing Ganesh  Bajaj, who turned his family business into a 3 crore  enterprise, flourishing with Netpurti's partnership"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/second.png",
            headingname: "Introducing Ganesh Bajaj",
            salespersoncontent: "Introducing Ganesh  Bajaj, who turned his family business into a 3 crore  enterprise, flourishing with Netpurti's partnership"
        },
    ]

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/seller/success-stories/s-moving`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);
    return (
        <section className='moving-person'>
            <Container>
                <div className='section-title'>
                    <h4>Moving from In-Person to Online Sales</h4>
                </div>
                <Swiper
                    modules={[Pagination]}
                    spaceBetween={25}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        360: {
                            slidesPerView: 1,
                        },
                        467: {
                            slidesPerView: 2,
                        },
                        567: {
                            slidesPerView: 2,
                        },
                        640: {
                            slidesPerView: 2,
                        },

                        768: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                        1440: {
                            slidesPerView: 3,
                        },
                        2000: {
                            slidesPerView: 3,
                        },
                        2560: {
                            slidesPerView: 4,
                        },
                        3000: {
                            slidesPerView: 4,
                        },

                    }}
                >
                    {data?.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className='card main-white-card-class mb-5'>
                                <div className='card-img-top main-img-class'>
                                    {item.image && (
                                        <img
                                            src={IMG_URL + item?.image}
                                            className="person-imgg"
                                            alt="..."
                                        />
                                    )}
                                    <div className='person-name-class'>
                                        <h5 className='title'>{item?.name}</h5>
                                    </div>
                                    <div className='black-overlay'></div>
                                </div>
                                <div className='card-body'>
                                    <p className='sales-content'>{item?.description}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>
        </section>
    )
}

export default MovingPerson