import { useState, useEffect, useContext } from "react";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import StepthreeInner from "./StepthreeInner/StepthreeInner";
import StepthreeInnertwo from "./StepthreeInnertwo/StepthreeInnertwo";
import StepthreeInnerthree from "./StepthreeInnerthree/StepthreeInnerthree";
import { Context } from "../../../../utils/context";
import { btbPercentageSend } from "../../../../utils/apis/btb/B2b";

function Stepthree({ prevStep, nextStep, subheading, workType, userDetails }) {
  const { getData, postData, IMG_URL } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showInnerComponent, setShowInnerComponent] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRegisterBack = () => {
    getUserCategory();
    setSelectedOption(null);
  };

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedChildCategory, setSelectedChildCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [childCategory, setChildCategory] = useState([]);
  const [childSubCategory, setChildSubCategory] = useState([]);
  const [s_sector_id, sets_sector_id] = useState("");

  const getCategory = async () => {
    const res = await getData(`/seller/masters/allcategory/${workType}`);
    if (res?.success) {
      setCategory(res.data);
      // console.log(res.data,"allcatogarydid");
    }
  };

  const getUserCategory = async () => {
    const res = await getData(
      `/btb/btb-create/btb-sector-category/${userDetails?.btb_personal_details_id}`
    );
    if (res?.success) {
      setSelectedCategory(res.data.s_category_id);
      setSubCategory(res.data.s_sub_category_id);
    }
  };

  const getUserSubCategory = async () => {
    const res = await getData(
      `/btb/btb-create/btb-sector-sub-category/${userDetails?.btb_personal_details_id}`
    );
    if (res?.success) {
      setSubCategory(res.data.data);
      sets_sector_id(res?.data?.data[0]?.btb_sector_id);
      setChildCategory(res.data.btb_sector_child_categories);
    }
  };

  const getUserSubChildCategory = async () => {
    const res = await getData(
      `/btb/btb-create/btb-sector-child-category/${userDetails?.btb_personal_details_id}`
    );
    if (res?.success) {
      setChildCategory(res.data);
      sets_sector_id(res?.data[0]?.btb_sector_id);
    }
  };

  const handleRegisterContinue = async () => {
    console.log(subCategory, "user_id");
    const res = await postData(`/btb/btb-create/btb-sector-category`, {
      btb_personal_details_id: userDetails?.btb_personal_details_id,
      s_working_type_id: workType,
      s_category_id: selectedCategory,
      s_sub_category_id: subCategory,
      percentage: btbPercentageSend.stepThree,
    });

    if (res?.success) {
      setSelectedOption(selectedCategory);
      setShowInnerComponent(true);
      getUserSubCategory();
    }
  };

  const innerNext = async () => {
    var data = {
      s_category_id: selectedCategory,
      s_working_type_id: workType,
      btb_personal_details_id: userDetails?.btb_personal_details_id,
      btb_sector_id: s_sector_id,
      btb_sector_child_category_id: childCategory,
    };

    const res = await postData(`/btb/btb-create/btb-sector-sub-category`, data);

    if (res?.success) {
      console.log(
        res.success,
        "getUserSubChildCategorygetUserSubChildCategory"
      );
      setShowInnerComponent(false);
      getUserSubChildCategory();
    }
  };

  //   const innerNext = async () => {
  //       var data = {
  //       s_category_id: selectedCategory,
  //       s_working_type_id: workType,
  //       btb_personal_details_id: userDetails?.btb_personal_details_id,
  //       s_sector_id: s_sector_id,
  //       s_sector_child_category_id: childCategory,
  //     };

  //   const res = await postData(`/btb/btb-create/btb-sector-sub-category`,data);
  //   if(res?.success){
  //     setShowInnerComponent(false);
  //     getUserSubChildCategory();
  //   }

  // };

  const childSubPost = async () => {
    var data = {
      s_category_id: selectedCategory,
      s_working_type_id: workType,
      btb_personal_details_id: userDetails?.btb_personal_details_id,
      btb_sector_id: s_sector_id,
      btb_c_sub_category_id: childSubCategory,
    };

    const res = await postData(
      `/btb/btb-create/btb-sector-child-category`,
      data
    );

    if (res?.success) {
      nextStep();
    }
  };

  const threeBack = async () => {
    setShowInnerComponent(true);
    getUserSubCategory();
  };

  useEffect(() => {
    getUserCategory();
  }, [userDetails]);

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      {selectedOption === null ? (
        <>
          <StepthreeInnerthree
            handleRegisterContinue={handleRegisterContinue}
            prevStep={prevStep}
            category={category}
            setSubCategory={setSubCategory}
            subCategory={subCategory}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </>
      ) : showInnerComponent ? (
        <StepthreeInner
          nextStep={nextStep}
          handleRegisterBack={handleRegisterBack}
          subCategory={subCategory}
          selectedSubCategory={selectedSubCategory}
          setSelectedSubCategory={setSelectedSubCategory}
          childCategory={childCategory}
          setChildCategory={setChildCategory}
        />
      ) : (
        <>
          {/* <StepthreeInnertwo
          nextStep={childSubPost}
          innerNext={threeBack}
          childCategory={childCategory}
          childSubPost={childSubPost}
          selectedChildCategory={selectedChildCategory}
          setSelectedChildCategory={setSelectedChildCategory}
          childSubCategory={childSubCategory}
          setChildSubCategory={setChildSubCategory}
        /> */}
        </>
      )}
    </>
  );
}

export default Stepthree;
