import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoditEditor from "jodit-react";
import classNames from "classnames";
import Select from "react-select";

import "../CommonDetails.css";
import { ProductInfoIcon } from "../../../../../utils/apis/master/Master";
import { RequiredIs } from "../../../../../utils/common";

function Warrentytab({
  reset,
  errors,
  register,
  Controller,
  control,
  serviceTypes,
  addressStatus,
  setAddressStatus,
  getValues,
  setValue,
  useFieldArray,
  working_type,
}) {
  const [content, setContent] = useState("");

  const provided = [
    {
      value: "Brand",
      label: "Brand",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  const [providedInput, setProvidedInput] = useState(false);
  const handleChange = async (d) => {
    await setValue("w_provided_by", d);
    if (d.value === "other") {
      setProvidedInput(true);
    } else {
      setProvidedInput(false);
    }
  };

  const [productInfoIcon, setProductInfoIcon] = useState([]);

  const getDataAll = async () => {
    {
      const res = await ProductInfoIcon();
      if (res?.success) {
        setProductInfoIcon(res?.data);
      }
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const { append, fields, remove } = useFieldArray({
    control,
    name: "warrenty_details",
  });

  const validateWordCount = (value) => {
    const wordCount = value.length;
    return wordCount <= 100 || "Maximum 100 words are allowed";
  };

  return (
    <>
      <section className="warrenty-tab">
        <div className="py-2">
          <div className="row">
            <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Warranty Provided By <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-title">
                        {productInfoIcon?.warrenty_provided_by}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>

                <Controller
                  name="w_provided_by"
                  {...register("w_provided_by", {
                    required: "Warantee Provided By is required",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors?.w_provided_by
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      options={provided}
                      onChange={(e) => handleChange(e)}
                    />
                  )}
                />
                {getValues("w_provided_by")?.value === "other" && (
                  <Form.Control
                    type="text"
                    placeholder="Warranty Provided By"
                    className={classNames("mt-3", {
                      "is-invalid": errors?.w_other_provided_by,
                    })}
                    name="w_other_provided_by"
                    {...register("w_other_provided_by", {
                      required: "Warranty is required",
                    })}
                  />
                )}
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Warranty Period of Product{" "}
                  {working_type == 1
                    ? "in Day"
                    : working_type == 2 && "As Per Brand"}{" "}
                  <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-category">
                        {productInfoIcon?.warrenty_period_of_product}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Warranty Period"
                  className={classNames("", {
                    "is-invalid": errors?.w_period,
                  })}
                  name="w_period"
                  {...register("w_period", {
                    required: "Warranty is required",
                  })}
                />
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Helpline Number <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-brand">
                        {productInfoIcon?.helpline_no}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Helpline"
                  className={classNames("", {
                    "is-invalid": errors?.w_helpline,
                  })}
                  name="w_helpline"
                  {...register("w_helpline", {
                    required: "Helpline is required",
                  })}
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-xxl-4 col-md-4 col-4">
            <p className="me-2 minitext">Service Type</p>
            <div className="">
              {serviceTypes?.map((value, index) =>
                RequiredIs.working_type[working_type] ? (
                  !RequiredIs.working_type[working_type].includes(index) && (
                    <Form.Check
                      type="radio"
                      label={value?.name}
                      name="s_service_type_id"
                      value={value?.id}
                      defaultChecked={
                        getValues("s_service_type_id") === value?.id
                      }
                      className={classNames("my-2", {
                        "is-invalid": errors?.s_service_type_id,
                      })}
                      {...register("s_service_type_id", {
                        required: "Service Type is required",
                      })}
                      onClick={() =>
                        setAddressStatus(
                          value?.name === "Go to Manufacture Spot"
                        )
                      }
                    />
                  )
                ) : (
                  <Form.Check
                    type="radio"
                    label={value?.name}
                    name="s_service_type_id"
                    value={value?.id}
                    defaultChecked={
                      getValues("s_service_type_id") === value?.id
                    }
                    className={classNames("my-2", {
                      "is-invalid": errors?.s_service_type_id,
                    })}
                    {...register("s_service_type_id", {
                      required: "Service Type is required",
                    })}
                    onClick={() =>
                      setAddressStatus(value?.name === "Go to Manufacture Spot")
                    }
                  />
                )
              )}
            </div>
          </div>
          {/* {addressStatus && (
            <div className="col-xxl-8 col-md-8 col-8">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Address{" "}
                  <span className="span-text">( if manufacture spot )</span>{" "}
                  <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-brand">
                        {productInfoIcon?.address}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter address"
                  rows={4}
                  className={classNames("", {
                    "is-invalid": errors?.w_address,
                  })}
                  name="w_address"
                  {...register("w_address", {
                    required: "Address is required",
                  })}
                />
              </Form.Group>
            </div>
          )} */}
        </div>
        <div className="row">
          <div className="select-to-div">
            <Form.Label>
              Warranty Terms :- Maximum 100 words are allowed
            </Form.Label>

            {fields?.map((value, index) => (
              <>
                <div className="col-xxl-12 col-md-12 col-12 align-items-center d-flex mt-auto mb-2">
                  <Form.Control
                    type="text"
                    placeholder="Points"
                    className={classNames("me-2", {
                      "is-invalid":
                        errors?.warrenty_details &&
                        errors?.warrenty_details[index] &&
                        errors?.warrenty_details[index]?.name,
                    })}
                    name={`warrenty_details[${index}].name`}
                    {...register(`warrenty_details[${index}].name`, {
                      required: "Points is required",
                      validate: validateWordCount,
                    })}
                  />{" "}
                  {fields?.length !== 1 && (
                    <button
                      className="delet-row"
                      onClick={() => remove(index)}
                      type="button"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-minus" />
                    </button>
                  )}
                </div>
              </>
            ))}
            {fields?.length < 10 && (
              <button
                className="addrowiccc"
                type="button"
                style={{
                  float: "right",
                }}
                onClick={() =>
                  append({
                    name: "",
                  })
                }
              >
                <img
                  style={{ margin: "0px" }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/Seller_panel/dashboard/icons/addicooo.png"
                  }
                />
              </button>
            )}

            {/* <Controller
              name="w_term"
              {...register("w_term", {
                required: "Warranty Terms is required",
              })}
              control={control}
              render={({ field }) => (
                <JoditEditor
                  value={content}
                  tabIndex={1}
                  onBlur={(newContent) => setContent(newContent)}
                  {...field}
                />
              )}
            /> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Warrentytab;
