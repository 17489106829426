import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import "./ShoppingCartCard.css";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import { library } from "@fortawesome/fontawesome-svg-core";
import DeleteModal from "../../common-components/AllModal/Delete-modal/DeleteModal";
import AddNoteModal from "../../common-components/AllModal/Add_note_modal/AddNoteModal";
import { Context } from "../../../../utils/context";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  btobCartQuentity,
  btobCartRemove,
  btobCartStatus,
} from "../../../../utils/apis/btb/Cart";
library.add(fas);

const ShoppingCartCard = (props) => {
  const { IMG_URL } = useContext(Context);
  const [showNote, setShowNote] = useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const location = useLocation();

  console.log(props);
  const [startOrder, setStartOrder] = useState(false);
  useEffect(() => {
    if (location.pathname === "/b2bbuyer/start-order") {
      setStartOrder(true);
    }
  }, [location]);

  const Quentity = async (cart_id, type) => {
    try {
      const res = await btobCartQuentity({ cart_id: cart_id, type: type });
      await props?.getData();
    } catch (error) {
      console.log(error);
    }
  };

  const RemoveFromCart = async (id) => {
    const res = await btobCartRemove(id);
    await setModalDelete(false);
    await props?.getData();
  };

  const handleCloseNote = () => setShowNote(false);
  const handleShowNote = () => setShowNote(true);

  return (
    <>
      {props?.cardDataArray
        .filter((cardData) => (startOrder ? cardData.status : true))
        .map((cardData, index) => (
          <div className="shopping-card-main-card" key={index}>
            <Form.Group className="mb-3">
              {cardData?.formcheck && (
                <Form.Check
                  label={cardData?.product_title}
                  name="delivery"
                  inline
                />
              )}
            </Form.Group>
            <div className="sec-card-head">
              <div>
                <p>{cardData?.order}</p>
              </div>
              {cardData?.buttonNote && (
                <div onClick={() => setModalShow(true)}>
                  <span>Any note for supplier ?</span>
                  <button>Add note</button>
                </div>
              )}
            </div>
            <div className="card-sec">
              <div className="row">
                <div className="col-xxl-6 col-xl-5 col-lg-12 col-md-12 col-12">
                  <div className="left-sec">
                    {location.pathname !== "/b2bbuyer/start-order" && (
                      <Form.Check
                        name="delivery"
                        checked={cardData?.status}
                        // inline
                        onChange={(e) => {
                          btobCartStatus(cardData?.id, {
                            status: e.target.checked,
                          });
                          props?.getData(); // Refresh data after status change
                        }}
                      />
                    )}
                    <div className="me-3">
                      <img
                        src={IMG_URL + cardData?.thumbnail}
                        className="prduct-img"
                      />
                    </div>
                    <div className="details">
                      <Link to={`/b2bbuyer/b2b-productMain/${cardData?.product_id}`}>
                        <h6>{cardData?.product_title}</h6><p style={{ fontWeight: "bold" }}>({cardData?.product_type})</p>
                      </Link>
                      <p >{cardData?.product_generic_name}&</p>
                      <p>{cardData?.minOrder}</p>
                      <p>{cardData?.deliveryDate}</p>
                      <p>Sold By :-{cardData?.sold_by}</p>
                      <p>Added on:- {new Date(cardData?.createdAt).toLocaleDateString()}</p>
                    </div>
                  </div>
                </div>
                {startOrder ? (
                  <div className="col-xxl-6 col-xl-7 col-lg-12 col-md-12 col-12">
                    <div className="right-sec">
                      <p className="price-text">
                        Quanitity : {cardData?.quantity}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="col-xxl-6 col-xl-7 col-lg-12 col-md-12 col-12">
                    <div className="row right-sec">
                      <div className="col-xl-4  col-lg-4 col-md-4 col-3">
                        {/* {cardData?.buttonGroup && ( */}

                        <ButtonGroup
                          aria-label="First group"
                          className="btn-grp"
                        >
                          {/* {cardData?.min_quantity < cardData?.quantity && ( */}
                          <Button
                            className="add-btns"
                            disabled={
                              cardData?.min_quantity >= cardData?.quantity
                            }
                            onClick={() => {
                              Quentity(cardData?.cart_id, "minus");
                            }}
                          >
                            -
                          </Button>
                          {/* )} */}

                          <p className="price-text">{cardData?.quantity}</p>
                          <Button
                            className="add-btns"
                            onClick={() => {
                              Quentity(cardData?.cart_id, "add");
                            }}
                          >
                            +
                          </Button>
                        </ButtonGroup>

                        {/* )} */}

                        <div >
                          <p className="count">Mini Qty. Required {cardData?.min_quantity}</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-4 main-price">
                        <p className="price">₹{cardData?.selling_price}</p>

                        {cardData?.selling_price && cardData?.mrp ? (
                          <>

                            {cardData.selling_price < cardData.mrp && (
                              <>
                                <div><span className="mrp-title"></span><span className="mrp">₹{cardData.mrp} </span>  </div>
                                <div>
                                  <span className="discount">
                                    {` (${Math.round(
                                      ((cardData.mrp - cardData.selling_price) / cardData.mrp) * 100
                                    )}% off)`}
                                  </span>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <span>{cardData?.selling_price}</span>
                        )}
                      </div>

                      <div className="col-xl-3 col-lg-3 col-md-3 col-3 main-price">
                      <label className="title-label">Total Price</label>
                        <p className="price">₹{cardData?.selling_price * cardData?.quantity}</p>
                       

                        {cardData?.selling_price && cardData?.mrp ? (
                          <>

                            {cardData.selling_price < cardData.mrp && (
                              <>
                                <div><span className="mrp"> ₹{cardData.mrp * cardData?.quantity} </span>  <span className="discount">

                                </span></div>

                                <div>

                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <span>{cardData?.selling_price * cardData?.quantity}</span>
                        )}
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-2 col-3"
                        onClick={() => {
                          setModalDelete(cardData?.cart_id);
                        }}
                      >
                        {/* {cardData?.trash && ( */}
                        <button className="trash-btn">
                          <FontAwesomeIcon icon={fas.faTrash} />
                        </button>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      <DeleteModal
        show={modalDelete}
        onHide={() => setModalDelete(false)}
        RemoveFromCart={RemoveFromCart}
        getData={props?.getData}
      />

      <AddNoteModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default ShoppingCartCard;
