import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getProducts = async (
  category_id,
  sub_category_id,
  min,
  max,
  quantity,
  working_type_id,
  customization_id,
  attributes_id,
  sub_attributes_id
) => {
  try {
    let url = "/btb/best-seller/products?";

    if (category_id) {
      url += `&category_id=${category_id}`;
    }

    if (sub_category_id) {
      url += `&sub_category_id=${sub_category_id}`;
    }

    if (min !== undefined && min !== "") {
      url += `&min=${min}`;
    }

    if (max !== undefined && max !== "") {
      url += `&max=${max}`;
    }

    if (quantity !== undefined && quantity !== "") {
      url += `&quantity=${quantity}`;
    }

    if (attributes_id !== undefined && attributes_id !== "") {
      url += `&attributes_id=${attributes_id.value}`;
    }

    if (sub_attributes_id?.length) {
      url += `&sub_attributes_id=${sub_attributes_id?.map((id) => id.value)}`;
    }

    if (working_type_id?.length) {
      url += `&working_type_id=${working_type_id}`;
    }

    if (customization_id?.length) {
      url += `&customization_id=${customization_id}`;
    }

    // const res = await getData(
    //   `/btb/best-seller/products?&sub_category_id=${sub_category_id}&min=${min}&max=${max}&quantity=${quantity}&attributes_id=${
    //     attributes_id.value
    //   }&sub_attributes_id=${sub_attributes_id?.map(
    //     (id) => id.value
    //   )}&working_type_id=${working_type_id}&customization_id=${customization_id}&`
    // );

    const res = await getData(url);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSupplierProducts = async (
  id,
  category_id,
  sub_category_id,
  min,
  max,
  quantity,
  working_type_id,
  customization_id,
  attributes_id,
  sub_attributes_id
) => {
  try {
    let url = `/btb/best-seller/supplier-products/${id}?`;

    if (category_id) {
      url += `&category_id=${category_id}`;
    }

    if (sub_category_id) {
      url += `&sub_category_id=${sub_category_id}`;
    }

    if (min !== undefined && min !== "") {
      url += `&min=${min}`;
    }

    if (max !== undefined && max !== "") {
      url += `&max=${max}`;
    }

    if (quantity !== undefined && quantity !== "") {
      url += `&quantity=${quantity}`;
    }

    if (attributes_id !== undefined && attributes_id !== "") {
      url += `&attributes_id=${attributes_id.value}`;
    }

    if (sub_attributes_id?.length) {
      url += `&sub_attributes_id=${sub_attributes_id?.map((id) => id.value)}`;
    }

    if (working_type_id?.length) {
      url += `&working_type_id=${working_type_id}`;
    }

    if (customization_id?.length) {
      url += `&customization_id=${customization_id}`;
    }

    // const res = await getData(
    //   `/btb/best-seller/products?&sub_category_id=${sub_category_id}&min=${min}&max=${max}&quantity=${quantity}&attributes_id=${
    //     attributes_id.value
    //   }&sub_attributes_id=${sub_attributes_id?.map(
    //     (id) => id.value
    //   )}&working_type_id=${working_type_id}&customization_id=${customization_id}&`
    // );

    const res = await getData(url);
    return res;
  } catch (error) {
    console.log(error);
  }
};
