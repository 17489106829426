import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllData = async (currentPage, perPage, search, domain_id) => {
  try {
    return await getData(
      `/seller/info-website/masters/app-setup?page=${currentPage}&per_page=${perPage}&term=${search}&domain_id=${domain_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const edit = async (finalData) => {
  try {
    return await postData(
      `/seller/info-website/masters/app-setup/data`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingledata = async (domain_id) => {
  try {
    return await getData(`/seller/info-website/masters/app-setup/data?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
};
