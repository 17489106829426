import React, { useContext, useEffect, useState } from "react";
import "./ScanMartStore.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Rating } from "react-simple-star-rating";
import TrandBee from "./TrandBee";
import Veggies_Section_Card from "../../common-components/Veggies_Section_Card/Veggies_Section_Card";
import Online_Store from "../../common-components/Online_Store/Online_Store";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Veiw_All_Card from "../../common-components/Veiw_All_Card/Veiw_All_Card";
// import { Category } from "../../../../utils/apis/Product/Product";
import { Context } from "../../../../utils/context";
import {
  getSellerProducts,
  getAllSellers,
} from "../../../../utils/apis/btb/seller_product";
import { Link, useNavigate } from "react-router-dom";
import ProductTab from "./ProductTab/ProductTab";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
// import SupermarketModal from "../../Common_modal/Supermarket-modal/SupermarketModal";

const ScanMartStore = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const myParam = queryParams.get("seller_id");

  console.log(myParam, "myParam");

  const { seller_id } = useParams();
  const [rating, setRating] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [likedProducts, setLikedProducts] = useState([]); // State to track liked products
  const [addCartModal, setAddCartModal] = useState(false);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleReset = () => {
    // Set the initial value
    setRating(0);
  };

  const handleLike = (index) => {
    setLikedProducts((prevLikedProducts) => {
      const updatedLikedProducts = [...prevLikedProducts];
      updatedLikedProducts[index] = !updatedLikedProducts[index];
      return updatedLikedProducts;
    });
  };

  const { IMG_URL, getData } = useContext(Context);
  const [activeCategory, setActiveCategory] = useState("first");
  const [category, setCategory] = useState([]);
  // const getCategory = async () => {
  //   const res = await Category();
  //   if (res?.success) {
  //     setCategory(res?.data);
  //   }
  // }

  const [seller, setSeller] = useState([]);
  const getSeller = async (value) => {
    console.log("value", value);
    if (value) {
      await setActiveCategory(value?.name);
    } else {
      await setActiveCategory("first");
    }

    // const res = await NearSellerGet(city, value?.id);
    // if (res?.success) {
    //   setSeller(res?.data);
    // }
  };

  const [sellerByCategory, setSellerByCategory] = useState([]);
  const getSellerByCat = async () => {
    const res = await getSellerProducts(myParam);
    if (res?.success) {
      setSellerByCategory(res?.data);
    }
  };

  const [sellers, setSellers] = useState([]);
  const getSellers = async () => {
    const res = await getAllSellers(myParam);
    if (res?.success) {
      setSellers(res?.data);
    }
  };

  console.log("sellers", sellers);

  const navigate = useNavigate();
  const handleNext = async (id) => {
    // const res = await sellerCategory(id);
    // if (res?.success) {
    // localStorage.setItem('seller_id', id);
    navigate(`/product`);
    // }
  };

  const [city, setCity] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Fetch city name using reverse geocoding
          fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
          )
            .then((response) => response.json())
            .then((data) => {
              setCity(data.address.city);
            })
            .catch((error) => {
              console.error("Error fetching city:", error);
            });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    // getCategory();
    getSeller();
  }, [city]);

  useEffect(() => {
    getSellers();
    getSellerByCat();
  }, []);

  return (
    <>
      <section className="near-mart-store">
        <div className="container">
          <div className="heading-holder">
            <h1>Seller's Store</h1>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              {/* <Col sm={12}>
                <Nav variant="pills" className="flex-row">
                  <Swiper
                    className="mySwiper1"
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 10
                      },
                      420: {
                        slidesPerView: 2,
                        spaceBetween: 10
                      },
                      486: {
                        slidesPerView: 2,
                        spaceBetween: 10
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 10
                      },
                      992: {
                        slidesPerView: 3,
                        spaceBetween: 10
                      },
                      1200: {
                        slidesPerView: 3,
                        spaceBetween: 10
                      },
                    }}
                  >
                    <SwiperSlide className="product-slide">
                      <Nav.Item>
                        <Nav.Link className={activeCategory === "first" ? 'active' : ''} onClick={() => getSeller()}>All</Nav.Link>
                      </Nav.Item>
                    </SwiperSlide>

                    {category?.map((value, index) => (
                      <SwiperSlide className="product-slide">
                        <Nav.Item>
                          <Nav.Link className={activeCategory == value?.name ? 'active' : ''}
                            onClick={() => getSeller(value)}>{value?.name}</Nav.Link>

                        </Nav.Item>
                      </SwiperSlide>
                    ))}


               
                  </Swiper>
                </Nav>
              </Col> */}
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    {sellers?.length ? (
                      sellers?.map(
                        (value, index) =>
                          value?.products &&
                          value?.products.length > 0 && (
                            <div className="slider-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 mt-5">
                                  <Online_Store data={value} />
                                </div>

                                <div className="col-lg-9 col-md-8 mt-5">
                                  <Swiper
                                    breakpoints={{
                                      0: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                      },
                                      420: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                      },
                                      486: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                      },
                                      768: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                      },
                                      992: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                      },
                                      1200: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                      },
                                      1400: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                      },
                                      1900: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                      },
                                    }}
                                    navigation={true}
                                    autoplay={{
                                      delay: 2000, // Time between slides in milliseconds (3 seconds here)
                                      disableOnInteraction: false, // Auto-slide will not stop on user interactions
                                    }}
                                    modules={[Pagination, Navigation, Autoplay]}
                                    pagination={{
                                      dynamicBullets: true,
                                    }}
                                    onSwiper={(swiper) =>
                                      setSwiperInstance(swiper)
                                    }
                                  >
                                    {value?.products?.map((item, index) => (
                                      <SwiperSlide
                                        key={`${index}-${item.detailsLink}`}
                                      >
                                        <Link
                                          to={`/b2bbuyer/b2b-productMain/${item?.id}`}
                                        >
                                          <ProductTab
                                            item={item}
                                            IMG_URL={IMG_URL}
                                          />
                                        </Link>
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>

                                  <div class="silder-btn">
                                    <div
                                      className="back-btn"
                                      onClick={() =>
                                        handleMainSliderClick("prev")
                                      }
                                    ></div>
                                    <div
                                      className="next-btn"
                                      onClick={() =>
                                        handleMainSliderClick("next")
                                      }
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                      )
                    ) : (
                      <div className="slider-sec">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 mt-5">
                            <h1 style={{ textAlign: "center" }}>
                              No Products Available
                            </h1>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className="slider-sec">
                      <TrandBee />
                    </div>

                    <div className="slider-sec">
                      <TrandBee />
                    </div> */}
                  </Tab.Pane>

                  <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                  <Tab.Pane eventKey="Third">Second tab content</Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          {/* <SupermarketModal show={addCartModal} onHide={() => setAddCartModal(false)} /> */}
        </div>
      </section>
    </>
  );
};

export default ScanMartStore;
