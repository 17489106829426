import React from "react";
import FrequentlyBoughtCard from "../../common-components/Frequently-bought-card/FrequentlyBoughtCard";
import { useNavigate } from "react-router-dom";

const B2bsupplierCard = ({ headingName, FrequentlyCard, IMG_URL }) => {
  const frequentlycardsDetails = [
    {
      id: 1,
      content: "Modern Minimalist White Half Circle Designer Couches Luxury...",
      rupees: "₹15,000",
      orderdetails: "Min Order: 2 Pieces",
      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa.png",
      detailsLink: "",
    },
    {
      id: 2,
      content:
        "White Boucle Dinner Chairs Sedie Tavoli Da Pranzo Leather Dining...",
      rupees: "₹15,000",
      orderdetails: "Min Order: 2 Pieces",
      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/white-ashcroft.png",
      detailsLink: "",
    },
    {
      content: "Factory OEM Fabric Leather Stainless Steel Metal Leg Chairs...",
      rupees: "₹15,000",
      orderdetails: "Min Order: 2 Pieces",
      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa1.png",
    },
    {
      content: "New High End Queen King Size Bed Frame Luxury Bed Frame...",
      rupees: "₹15,000",
      orderdetails: "Min Order: 2 Pieces",
      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa2.png",
    },
    {
      content: "Nordic Design Postmodern Marble Round Coffee Table...",
      rupees: "₹15,000",
      orderdetails: "Min Order: 2 Pieces",
      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa3.png",
    },
    {
      content: "Luxury Nordic Arm Chair Dinner Gold Metal Legs Furniture...",
      rupees: "₹15,000",
      orderdetails: "Min Order: 2 Pieces",
      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa4.png",
    },
    {
      content: "Luxury Nordic Arm Chair Dinner Gold Metal Legs Furniture...",
      rupees: "₹15,000",
      orderdetails: "Min Order: 2 Pieces",
      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa5.png",
    },
    {
      content: "High Quality Living Room Modern Leisure Style Lounge Chair...",
      rupees: "₹15,000",
      orderdetails: "Min Order: 2 Pieces",
      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa6.png",
    },
  ];
  const navigate = useNavigate();

  console.log(FrequentlyCard);

  return (
    <>
      <section className="frequently-bought-together">
        <div className="container">
          <div className="row">
            <div className="hediang-holder mb-3">
              <h2>{headingName}</h2>
            </div>

            {FrequentlyCard?.map((item, index) => (
              <div
                key={index}
                // onClick={() => {
                //   navigate(`/b2bbuyer/b2b-productMain/${item.id}`);
                // }}
                className="col-lg-3 col-md-4 col-sm-6"
              >
                <FrequentlyBoughtCard
                  className="main-section mb-5"
                  product_title={item?.product_title}
                  customization={item?.customization}
                  // content={item?.product_title + " | " + item?.customization}
                  id={item?.id}
                  mrp={item?.mrp}
                  image={IMG_URL + item?.thumbnail}
                  imgClassName="seller-img"
                  selling_price={item?.selling_price}
                  IMG_URL={IMG_URL}
                  orderdetails={item?.minimum_order_quantity}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default B2bsupplierCard;
