import React, { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import "./Wallet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import { depositAmountPost, getMypackages } from "../../../../../utils/apis/seller/Mypackages/MyPackages";
import { Link, useNavigate } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import DepositModal from "../../../../CommonForAll/mrp-modal/DepositModal";

import useRazorpay from "react-razorpay";
import SuccessModalDynamic from "../../../../CommonForAll/SuccessModalDynamic/SuccessModalDynamic";
import { Context } from "../../../../../utils/context";
const Wallet = () => {





  const { postData, Id } = useContext(Context);

  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [sellerWallet, setSellerWallet] = useState();
  const getWalletHistory = async () => {
    const res = await getMypackages(currentPage);
    if (res?.success) {
      setSellerWallet(res?.data?.data);
      setCurrentPage(res?.data?.currentPage);
      setTotalPages(res?.data?.totalPages);
    }
  };
  console.log(sellerWallet, "sellerWallet");
  const [depositAmount, setAmount] = useState(0);
  const [amountModal, setAmountModal] = useState(false);
  const addWallet = () => {
    setAmountModal(true);
  }

  useEffect(() => {
    getWalletHistory();
  }, [currentPage]);

  const getStatus = (expDate) => {
    if (!expDate) return 'Inactive';
    const [day, month, year] = expDate.split('/');
    const expirationDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    return expirationDate >= currentDate ? 'Active' : 'Expired';
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);

  };

  const getPaginationItems = () => {
    let items = [];

    // Always show the first page
    items.push(
      <Pagination.Item
        key={1}
        active={currentPage === 1}
        onClick={() => handlePageClick(1)}
      >
        {1}
      </Pagination.Item>
    );

    // Add ellipsis if there is a gap between the first page and the range around current page
    if (currentPage > 4) {
      items.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    // Display 2 pages before and after the current page
    for (let i = Math.max(2, currentPage - 2); i <= Math.min(currentPage + 2, totalPages - 1); i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Add ellipsis if there is a gap between the last page and the range around current page
    if (currentPage < totalPages - 3) {
      items.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }

    // Always show the last page
    if (totalPages > 1) {
      items.push(
        <Pagination.Item
          key={totalPages}
          active={currentPage === totalPages}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <>
      <section className="Walletdashboard">
        <div className="datatableMain">
          <div className="row">
            <div className="col-lg-4 ">
              {/* <input
                                className='imppppp'
                                type='text'
                                placeholder='Search by title...'
                                value={filterText}
                                onChange={handleFilterChange}
                            /> */}

              <p>
                <b>My Packages</b>
              </p>
            </div>

            <div className="col-lg-8 text-end">
              <div className="d-flex justify-content-end">
                {/* <div className="">
                  <button className="Getrepbtnn btn" onClick={() => addWallet()}>
                    Deposit
                  </button>
                </div> */}
                {/* <div className="">
                  <button className="Getrepbtnn btn">
                    <FontAwesomeIcon icon="fa-solid fa-download" /> Get Reports
                  </button>
                </div> */}

                {/* <div className=''>
                                    <button className='filterperrrbtnn btn'><FontAwesomeIcon icon="fa-solid fa-filter" /> Filter Periods</button>
                                </div> */}

                {/* <div className="">
                  <Form.Select
                    className="selectoldenew"
                    aria-label="Default select example"
                  >
                    <option>Select</option>
                    <option value="1">Newest</option>
                    <option value="2">Oldest</option>
                  </Form.Select>
                </div> */}
              </div>
            </div>
          </div>

          {/* <DataTable
                        columns={columns}
                        data={filteredData}
                        selectableRows
                        pagination
                        defaultSortFieldId={1}
                    /> */}

          <div className="wallet-table-sccc mt-4">
            <div className="table-responsive">
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    {/* <th className="wallselecttt">
                      <Form>
                        {["checkbox"].map((type) => (
                          <div key={`inline-${type}`} className="">
                            <Form.Check
                              inline
                              label=""
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                            />
                          </div>
                        ))}
                      </Form>
                    </th> */}
                    <th>Plan Name</th>

                    <th>Exp date</th>
                    <th>Domain Name</th>
                    <th>Domain</th>
                    <th>Dns</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>View</th>
                  </tr>
                </thead>

                <tbody>
                  {sellerWallet?.map(
                    (value, index) => (
                      <tr>
                        {/* <td>
                          <FontAwesomeIcon
                            className={
                              value?.transaction_type === "debit"
                                ? "misscallicon"
                                : value?.transaction_type === "block"
                                ? "blockcallicon"
                                : "upcomimgcallicon"
                            }
                            icon={
                              value?.transaction_type === "debit"
                                ? "fa-solid fa-arrow-trend-up"
                                : value?.transaction_type === "block"
                                ? "fa-solid fa-circle-stop"
                                : "fa-solid fa-arrow-trend-down"
                            }
                          />
                        </td> */}
                        <td>{value?.m_w_plan_detail?.name}</td>

                        <td>{value?.exp_date}</td>
                        <td>{value?.domain?.domain_name}</td>
                        <td>{value?.domain?.domain}</td>
                        <td>{value?.domain?.dns}</td>
                        <td>₹ {value?.amount}</td>
                        <td style={getStatus(value?.exp_date) === 'Active' ? { color: 'green' } : { color: 'red' }}>{getStatus(value?.exp_date)}</td>
                        <td><Link to={`/seller/seller-dashboard/my-package-themes/${value?.domain?.id}`}><button className="btn viewthemebtn"> View Themes </button></Link></td>

                        {/* <td>{value?.exp_date}</td>
                        <td>{value?.amount}</td>
                        <td>{value?.exp_date}</td> */}
                        {/* <td>
                          {value?.transaction_type === "block" &&
                            sellerWallet?.f_name + " " + sellerWallet?.l_name}
                          {value?.transaction_type === "debit" &&
                            sellerWallet?.f_name + " " + sellerWallet?.l_name}
                        </td> */}
                        {/* <td>
                          {value?.transaction_type === "block" &&
                            value?.a_personal_detail?.f_name +
                              " " +
                              value?.a_personal_detail?.l_name}
                          {value?.transaction_type === "debit" &&
                            (value?.task_id !== null ? (
                              value?.a_personal_detail?.f_name +
                              " " +
                              value?.a_personal_detail?.l_name
                            ) : (
                              <></>
                            ))}
                        </td>
                        <td>{value?.seller_task?.task_id}</td>
                        <td>₹ {value?.amount}</td>
                        <td>{value?.remark}</td>
                        <td className={value?.transaction_type === 'debit' || value?.transaction_type === 'block' ? "cancc-app" : "compp-app"}>{value?.transaction_type}</td> */}
                      </tr>
                    )
                  )}

                  {/* <tr>
                                        <td><FontAwesomeIcon className='upcomimgcallicon' icon="fa-solid fa-arrow-trend-down" /></td>
                                        <td>123456789</td>
                                        <td>22/05/2024</td>
                                        <td>Aditya</td>
                                        <td>Prajwal </td>
                                        <td>Rupees</td>
                                        <td>4566</td>
                                        <td>Lorem ipsum</td>
                                        <td className='compp-app'>Completed</td>
                                    </tr> */}
                </tbody>
              </table>

              <div className="walletpaginationdiv">
                <Pagination>

                  {currentPage > 1 && (
                    <Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} />
                  )}

                  {getPaginationItems()}

                  {currentPage < totalPages && (
                    <Pagination.Next onClick={() => handlePageClick(currentPage + 1)} />
                  )}
                </Pagination>
                {/* <Pagination>{items}</Pagination> */}
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Wallet;
