import React from "react";
import HomeBanner from "./HomeBanner/HomeBanner";
import SliderHome from "./slider-home/SliderHome";
import SellSmarter from "./Sellsmarter/SellSmarter";
import OurSeller from "./Ourseller/OurSeller";
import NetPurtiSeller from "./NetPurtiSeller/NetPurtiSeller";
import SellerTriumphs from "./SellerTriumphs/SellerTriumphs";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <SliderHome />
      <SellSmarter />
      <OurSeller />
      <SellerTriumphs />
      <NetPurtiSeller />
    </>
  );
};

export default Home;
