import { React, useRef, useEffect, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip } from "react-bootstrap"; // Import OverlayTrigger and Tooltip
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoditEditor from "jodit-react";
import classNames from "classnames";

import "../CommonDetails.css";
import { ProductInfoIcon } from "../../../../../../../../../utils/apis/master/Master";

function Expiry({ reset, errors, register }) {
  const [content, setContent] = useState("");
  const [productInfoIcon, setProductInfoIcon] = useState([]);

  const getDataAll = async () => {
    {
      const res = await ProductInfoIcon();
      if (res?.success) {
        setProductInfoIcon(res?.data);
      }
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="expiry-tab">
        <div className="py-2">
          <div className="row">
            <div className="col-xxl-4 col-lg-4 col-md-4">
              <Form.Label htmlFor="inputPassword5">Expiry Days</Form.Label>
              <Form.Control
                type="number"
                placeholder="Expiry Days"
                className={classNames("", {
                  "is-invalid": errors?.expiry_day,
                })}
                name="expiry_day"
                {...register("expiry_day", {
                  required: "Expiry Days is required",
                })}
              />
            </div>
            {/* <div className="col-xxl-4 col-lg-4 col-md-4">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Expiry Date <span className="star">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-title">
                        {productInfoIcon?.expiry_date}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="ms-3"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Expiry Date"
                  className={classNames("", {
                    "is-invalid": errors?.expiry_date,
                  })}
                  name="expiry_date"
                  {...register("expiry_date", {
                    required: "Expiry Date is required",
                  })}
                />
              </Form.Group>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Expiry;
