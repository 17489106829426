import React from "react";
// import './stocklist.css'

const InfluencerStock = () => {
  const product_list = [
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/box.png",
      name: "My active task",
      number: "5000",
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/shopping-bag.png",
      name: "Total view",
      number: "1000",
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/box2.png",
      name: "Spend cost month",
      number: "4000",
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/box.png",
      name: "Active affiliate link",
      number: "50 / ₹67854",
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/sales2.png",
      name: "Sale/Total Product",
      number: "56K",
    },
  ];

  return (
    <>
      <section className="Stock_list_section">
        <div className="row">
          {product_list.map((data, index) => (
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-widthhhh">
              <div className="list-flex">
                <div className="img-div">
                  <div className="circle-ico ico1">
                    <img className="icon" src={data.icon} />
                  </div>
                </div>
                <div className="cont-div">
                  <p className="name">{data.name}</p>
                  <p className="number">{data.number}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default InfluencerStock;
