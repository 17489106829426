import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "./PricingCards.css";
const PricingCards = ({images, text, title}) => {
    return (
        <>
            <div className="Maintenance-slider">
                <div className="Maintenance-Card">
                    <div className="image-main">
                        <img src={images} className="card-img" />
                    </div>
                    <div className="black-overlay"></div>
                    <div className="content">
                        <div className="title">
                            <p className="sub-title-medium">{title}</p>
                        </div>
                        <div className="text">
                            <p className="maintenance-text">{text}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PricingCards;
