import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import parse from "html-react-parser";
import './Surmount.css'
import { Container, Row, Col } from 'react-bootstrap'

const Surmount = () => {

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/seller/success-stories/s-surmount`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);
    return (
        <section className='surmount'>
            <Container>
                <div className='section-title'>
                    <h4>Brands that surmount</h4>
                </div>
                <Row>
                    {data?.map((item, index) => (
                        <Col lg={6} xl={6}>
                            <div className='surmount-white-bg mt-4'>
                                <div className='purple-bg'>
                                    <p>{parse(item?.description) || ""}</p>
                                </div>
                                <div className='img-name'>
                                    <img src={IMG_URL + item?.image} className="surmount-user-img me-4" alt="..." />
                                </div>
                                <p className='brand-name ms-5 mt-2'>{item?.name}</p>
                            </div>
                        </Col>
                    ))}
                    {/* <Col lg={6} xl={6}>
                        <div className='surmount-white-bg mt-4'>
                            <div className='purple-bg-right'>
                                <p>Deep Bajaj, founder of Sirona, a renowned female hygiene brand, discusses how Netpurti's boosted visibility, and brand love, and spurred tremendous growth.</p>
                            </div>
                            <div className='img-name'>
                                <img src={process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/2.png"} className="surmount-user-img-right me-4" alt="..." />
                            </div>
                            <p className='brand-name-right me-5 mt-2'>Riyaz</p>
                        </div>
                    </Col> */}
                </Row>
                <Row>
                    {/* <Col lg={6} xl={6}>
                        <div className='surmount-white-bg mt-4'>
                            <div className='purple-bg'>
                                <p>Deep Bajaj, founder of Sirona, a renowned female hygiene brand, discusses how Netpurti's boosted visibility, and brand love, and spurred tremendous growth.</p>
                            </div>
                            <div className='img-name'>
                                <img src={process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/3.png"} className="surmount-user-img me-4" alt="..." />
                            </div>
                            <p className='brand-name ms-5 mt-2'>Neeraj</p>
                        </div>
                    </Col>
                    <Col lg={6} xl={6}>
                        <div className='surmount-white-bg mt-4'>
                            <div className='purple-bg-right'>
                                <p>Deep Bajaj, founder of Sirona, a renowned female hygiene brand, discusses how Netpurti's boosted visibility, and brand love, and spurred tremendous growth.</p>
                            </div>
                            <div className='img-name'>
                                <img src={process.env.PUBLIC_URL + "/assest/images/Seller_panel/sellersuccessstory/4.png"} className="surmount-user-img-right me-4" alt="..." />
                            </div>
                            <p className='brand-name-right me-5 mt-2'>Mohit</p>
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </section>
    )
}

export default Surmount