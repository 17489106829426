import React, { useRef, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Chatnow.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { faImage } from "@fortawesome/free-regular-svg-icons";
const Chatnow = ({ name, ...props }) => {
  return (
    <>
      <Offcanvas
        className="OffcanvasChat"
        show={props.show}
        onHide={props.handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assest/images/B2Bbuyer/BestSeller/userimg.png"
              }
              className="userImg"
            />
            Rose Merry
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvasmain">
            <div className="card contactSupplycard">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-7 ">
                    <div className="canvasImageTextsec">
                      <div className="canvasImagesec">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/images/B2Bbuyer/BestSeller/productImg3.png"
                          }
                        />
                      </div>
                      <p>
                        Armchair New Arrival Promotion Fabric Material
                        Adjustable Leisure Single Power Electric Relax
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="row">
                      <div className="Quantityehad">
                        <h3>₹ 10000</h3>
                        <p>( Min Order : 50pcs )</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="textAreaMain">
              <textarea
                rows={5}
                placeholder="Enter your message here"
              ></textarea>
              <div className="galleryIconsec">
                <FontAwesomeIcon icon={faSmile} className="gallertIcon" />

                <FontAwesomeIcon icon={faImage} className="ImageIcon" />
              </div>
              <div className="sendbtnSec">
                <Button className="btn">Send</Button>
              </div>
            </div> */}
            <div className="textAreaMain">
              <textarea
                rows={5}
                placeholder="Enter your message here"
              ></textarea>
              <div className="galleryIconsec">
                <FontAwesomeIcon icon={faSmile} className="gallertIcon" />
                <FontAwesomeIcon icon={faImage} className="ImageIcon" />
              </div>
              <div className="sendbtnSec">
                <button className="btn">Send</button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Chatnow;
