import React, { useState, useContext, useEffect } from "react";
import "./Header_seller_dashboard.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notification_offcanvas from "../common-components/offcanvas/notification-offcanvas/Notification_offcanvas";
import Logout_modal from "../../../CommonForAll/logout-modal/Logout_modal";

import { Context } from "../../../../utils/context";
import Select from "react-select";
import { getThemeSections } from "../../../../utils/apis/seller/Mypackages/MyPackages";
import ErrorModal from "../../../CommonForAll/ErrorModalDynamic/ErrorModal";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const Header_seller_dashboard = ({ setSidebarclosed, sidebarclosed }) => {


  const {
    userdata,
    sellerDomain,
    setSellerDomain,
    domainData,
    setDomainData,
    domainErrorModal,
    setDomainErrorModal,
    domainError,
    setDomainError,
    usertype,
    signin,
  } = useContext(Context);

  const navigate = useNavigate();
  const [notShow, setNotShow] = useState(false);

  const [smShow, setSmShow] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [nottificationshow, setNotificationShow] = useState(false);
  const [logoutshow, setLogoutShow] = useState(false);

  const toggleSidebar = () => {
    setSidebarclosed(!sidebarclosed);
  };

  const getStatus = (expDate) => {
    if (!expDate) return 'Inactive';
    const [day, month, year] = expDate.split('/');
    const expirationDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    return expirationDate >= currentDate ? 'Active' : 'Inactive';
  };
  console.log(userdata?.domains, 'header');
  const [domains, setDomain] = useState([]);
  const handleDomains = async () => {

    const options = [];


    userdata?.domains?.map((value) => {
      console.log(value?.seller_plan?.exp_date, 'header');
      let status = getStatus(value?.seller_plan?.exp_date);
      if (status === 'Active') {
        options.push({ value: value?.id, name: 'domain_id', label: value?.domain_name, url: value?.domain });
      }
    });

    await setDomain(options);
    await setSellerDomain(options?.[0]);

  }


  console.log(sellerDomain, 'setDomainData');

  useEffect(() => {

    if (signin) {
      if (usertype !== "Seller") {
        if (usertype === 'Affiliate') {
          navigate('/affiliate/affilate-dashboard');
        } else {

          navigate('/seller/sellerlogin');
        }
      }
    }
    
    handleDomains();
  }, [userdata]);



  const handleNavigate = async () => {
    
    Cookies.set("seller_id", userdata?.id, {
      expires: 2,
    });
    navigate('/seller/seller_stepform?profile=p');
  }


  return (
    <>
      <section className="Header_seller_dashboard container-fluid p-0">
        <div className="bg-body-tertiary ">
          <div className="row me-0">
            <div className="col-md-3 my-auto">
              <div className="user-image d-flex">
                <div className="ms-lg-5 ms-1 me-2  mt-3">
                  {/* <FontAwesomeIcon
                    icon="fa-solid fa-bars"
                    className="me-3"
                    onClick={toggleSidebar}
                  /> */}

                 <FontAwesomeIcon className="userprooo" icon="fa-solid fa-user" onClick={handleNavigate}/>

                </div>
                <div className="me-5 mt-3">
                  <p className="header-text">
                    Hello,
                    <br />
                    <span>
                      {userdata?.f_name} {userdata?.l_name}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-9 my-auto">
              <div className="row">
                <div className="col-xxl-4 col-md-4">
                  <Select options={domains} placeholder="Select Domain" value={sellerDomain} onChange={(e) => setSellerDomain(e)} />
                </div>
                <div className="col-xxl-8 col-md-8">
                  <div className="search-notification">
                    <div className="search-bar">
                      <Form inline>
                        <InputGroup>
                          <InputGroup.Text id="basic-addon1">
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Type here to search"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </InputGroup>
                      </Form>
                    </div>
                    <div className="">
                      <div className="d-flex head-notti-flex justify-content-end">
                        <div className="space-bd">
                          <div
                            className="noti-div"
                            onClick={() => setNotificationShow(true)}
                          >
                            <FontAwesomeIcon
                              className="nottibel-icco"
                              icon="fa-solid fa-bell"
                            />
                            <span className="not-contt">7</span>
                          </div>

                          <Notification_offcanvas
                            show={nottificationshow}
                            onHide={() => setNotificationShow(false)}
                          />
                        </div>
                        <div className="space-bd2">
                          <div
                            className="noti-div "
                            onClick={() => setLogoutShow(true)}
                          >
                            <FontAwesomeIcon
                              className="nottibel-icco"
                              icon="fa-solid fa-right-from-bracket"
                            />
                          </div>

                          <Logout_modal
                            show={logoutshow}
                            onHide={() => setLogoutShow(false)}
                            title="Are you sure you want to logout?"
                            btnname="Logout"
                            userType={'seller'}
                          />

                          {/* <Logout_modal
                show={deleteshow}
                productId={productId}
                getAllProducts={props.getAllProducts}
                onHide={() => setdeleteShow(false)}
                title="Are you sure you want to delete this product from listing?"
                btnname="Delete"
              /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorModal show={domainErrorModal} onHide={() => setDomainErrorModal(false)} heading={domainError} text={""} />
      </section>
    </>
  );
};

export default Header_seller_dashboard;