import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import "./SliderHome.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "swiper/css";
const SliderHome = () => {


  const TextSlider = [
    {
      title: "30 crore+ Netpurti customers",
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/banner/slideimg1.png",
    },
    {
      title: "Enjoy Secure and Timely Payments, 7 Days a Week",
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/banner/slideimg2.png",
    },
    {
      title: "Cost-Effective Business Operations",
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/banner/slideimg3.png",
    },
    {
      title: "Instant Seller Assistance at Your Fingertips",
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/banner/slideimg4.png",
    },
    {
      title: "Unlock Exclusive Deals",
      imageSource:
        process.env.PUBLIC_URL +
        "/assest/images/Seller_panel/seller-image/banner/slideimg5.png",
    },
  ];

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/home/s-cards`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="slider-home">
        <div className="container">
          <div className="row">
            <Swiper
              modules={[Pagination]}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              pagination={{
                dynamicBullets: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                360: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                467: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                567: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },

                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1440: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
            >
              {data?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="col-md-12">
                    <div className="main-img-class mb-5">
                      {item.image && (
                        <img
                          src={IMG_URL + item?.image}
                          className="achive-img"
                          alt="..."
                        />
                      )}
                      <div className="overlay-bg"></div>
                      <div className="name-holder text-center">
                        <h4>{item?.name}</h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default SliderHome;
