import React from "react";
import "./Business_Requirements.css";
import CountUp from "react-countup";

function Business_Requirements() {
  return (
    <>
      <div className="Business_Requirements">
        <div className="container">
          <div className="img_content_holder">
            <div className="img_overlay_holder">
              <div className="BR_bg_img_holder">
                <img
                  className="BR_banner_bg_img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/B2B_panel/Home/Business_Requirements/BR_banner_bg_img.png"
                  }
                  alt="BR_banner_bg_img"
                />
              </div>

              <div className="overlay"></div>
            </div>
            <div className="Business_Requirements_content">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <div className="content_text_holder">
                    <h4>
                      Discover countless offerings customized to fit your
                      business requirements
                    </h4>

                    <div className="counter">
                      <div className="row justify-content-center">
                        <div className="col-md-3 col-4 ">
                          <div className="counter_number_holder vr-line">
                            <h3>
                              <CountUp duration={10} end={500} />
                              M+
                            </h3>
                            <p>Products</p>
                          </div>
                        </div>

                        <div className="col-md-3 col-4  ">
                          <div className="counter_number_holder vr-line">
                            <h3>
                              <CountUp duration={10} end={200} /> K+
                            </h3>
                            <p>Suppliers</p>
                          </div>
                        </div>

                        <div className="col-md-3 col-4">
                          <div className="counter_number_holder">
                            <h3>
                              <CountUp duration={10} end={500} />+
                            </h3>
                            <p>Product categories</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Business_Requirements;
