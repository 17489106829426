import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./ContactSupplier.css";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
const ContactSupplier = ({ name, ...props }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  return (
    <>
      <Offcanvas
        className="OffcanvasContactSupply"
        show={props.show}
        onHide={props.handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Send Inquiry</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvasmain">
            <div className="contactSupplyOffFirst">
              <p>To</p>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assest/images/B2Bbuyer/BestSeller/EllipseImg.png"
                }
                className="profileImg"
              />
              <div className="d-flex nameCompanyTxt">
                <h3>Saurav Khambayate </h3>
                <p>( Profcyma solution pvt. ltd. )</p>
              </div>
            </div>
            <div className="card contactSupplycard">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="canvasImageTextsec">
                      <div className="canvasImagesec">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/images/B2Bbuyer/BestSeller/productImg3.png"
                          }
                        />
                      </div>
                      <p>
                        Armchair New Arrival Promotion Fabric Material
                        Adjustable Leisure Single Power Electric Relax
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row QuantityUnitsec">
                      <div className="col-lg-6">
                        <label>Quantity</label>
                        <Form.Control type="text"></Form.Control>
                      </div>
                      <div className="col-lg-6">
                        <label>Unit</label>
                        <Select
                          value={selectedOption}
                          onChange={setSelectedOption}
                          options={options}
                          classNamePrefix="Unitselect"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="detailedSec">
              <div className="detailedhead">
                <h3>Detailed Requirement <span className="asterisk">*</span></h3>
                <p>
                  Enter product details such as color, size, materials etc. and
                  other specification requirements to receive an accurate quote.
                </p>

                <FloatingLabel controlId="floatingInput" label="Email address">
                  <Form.Control type="email" placeholder="name@example.com" />
                </FloatingLabel>
              </div>
            </div>

            <div className="attachedResume">
              <div className="detailedhead">
                <h3>
                  <FontAwesomeIcon icon="fa-solid fa-paperclip" /> Add
                  Attachment
                </h3>

                <Form>
                  <div className="mb-3 Checkboxsec">
                    <Form.Check
                      type="checkbox"
                      label="Recommend matching suppliers if this supplier doesn't contact me on Message Center within 24 hours."
                    />

                    <p>
                      Please make sure your contact information is correct (View
                      and Edit). Your message will be sent directly to the
                      recipient(s) and will not be publicly displayed. Note that
                      if the recipient is a Gold Supplier, they can view your
                      contact information, including your registered email
                      address. Alibaba will never distribute or sell your
                      personal information to third parties without your express
                      permission.
                    </p>
                  </div>

                  <div className="Inquirybtnsec text-center">
                    <Button className="btn">Send Inquiry Now</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ContactSupplier;
