import React, { useState, useEffect, useContext } from "react";
import "../../Variants/variants.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroup, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import JoditEditor from "jodit-react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";

import SuccessModal from "../../../../../../../../CommonForAll/SuccessModal/SuccessModal";

import Cookies from "js-cookie";

import {
  multipleVariantPost,
  singleVariantGet,
  AttributeDelete,
  singleVariantDataGet,
  singleVariantPost,
  RemoveSingleAll,
} from "../../../../../../../../../utils/apis/seller/Product";
import { hsnCodes } from "../../../../../../../../../utils/apis/seller/masters";
import { ProductInfoIcon } from "../../../../../../../../../utils/apis/master/Master";
import {
  GST,
  PlatformCharges,
  Unit,
} from "../../../../../../../../../utils/apis/master/Master";
import SingleVariant from "../SingleVariant/SingleVariant";
import { Context } from "../../../../../../../../../utils/context";

const SingleVariantInner = ({
  prevInnerPage,
  nextInnerPage,
  nextStep,
  prevStep,
}) => {
  const [content, setContent] = useState("");
  const [redirectToPrev, setRedirectToPrev] = useState(false); // State to control redirection

  const [uploadDefaultImages, setUploadDefaultImages] = useState([
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
    {
      img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
      dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
      selectedImage: null,
      status: false,
    },
  ]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange', // Trigger validation on every change
  });



  

  const [selectedImage, setSelectedImage] = useState(null);
  const thumbnailChange = async (e) => {
    const file = e.target.files[0];

    setSelectedImage(URL.createObjectURL(file));
  };

  const handleRemoveThumbnail = () => {
    setSelectedImage(null);

    const fileInput = document.getElementById(`thumbnailInput`); // Assuming you have unique IDs for each input
    if (fileInput) {
      fileInput.value = ""; // Clear the value of the input
    }
  };

  const handleProductImage = async (e, index) => {
    const file = e.target.files[0];

    await setValue(
      `uploadimages[${index}].img`,
      "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg"
    );
    await setValue(
      `uploadimages[${index}].dltimg`,
      "/assest/images/Seller_panel/dashboard/icons/dltt.png"
    );
    await setValue(
      `uploadimages[${index}].selectedImage`,
      URL.createObjectURL(file)
    );
    await setValue(`uploadimages[${index}].status`, true);

    reset(getValues());
  };

  const removeProductImage = async (index) => {
    const fileInput = document.getElementById("product_image_" + index);
    fileInput.value = "";

    await setValue(
      `uploadimages[${index}].img`,
      "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg"
    );
    await setValue(
      `uploadimages[${index}].dltimg`,
      "/assest/images/Seller_panel/dashboard/icons/dltt.png"
    );
    await setValue(`uploadimages[${index}].selectedImage`, null);
    await setValue(`uploadimages[${index}].status`, false);
    await setValue(`uploadimages[${index}].path`, null);
    await setValue(`uploadimages[${index}].image_id`, 0);

    reset(getValues());
  };

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: true };

  const [allVariantId, setAllVariantId] = useState([]);

  const allIdFunction = async (tableDatas) => {
    // Assuming setAllVariantId and setLevelType are synchronous

    var addId = [];
    var submittedId = [];
    var type = "";

    tableDatas?.s_product_variant_attribute_details?.forEach((value, index) => {
      if (value?.status) {
        type = 3;
        addId.push(value?.id);
      }
    });

    await setLevelType(type);
    await setAllVariantId(addId);

    for (const value of addId || []) {
      const res = await getsubmittedData(value);

      if (res) {
        submittedId.push(value);
      }
    }

    await setAllSubmited(submittedId);

    await getVariantData(type, addId[0]);
  };

  const getsubmittedData = async (id) => {
    const res = await singleVariantDataGet(id);

    if (res?.success) {
      if (res.data.s_p_v_a_d_price_a_stock) {
        return true;
      } else {
        return false;
      }
    }
  };

  const [dataStatus, setDataStatus] = useState(false);
  const [allSubmited, setAllSubmited] = useState([]);
  const [variantId, setVariantId] = useState("");
  const [levelType, setLevelType] = useState("");
  const getVariantData = async (type, id) => {
    setDataStatus(false);
    const res = await singleVariantDataGet(id);
    if (res?.success) {
      setVariantId(id);
      setLevelType(type);
      if (res.data?.s_p_v_a_d_price_a_stock?.id) {
        // console.log(res.data, "addValues");

        const images = [];

        res.data.s_p_v_a_d_images.forEach((image, imageIndex) => {
          images.push({
            img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
            dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
            selectedImage: IMG_URL + image.image,
            path: image.image,
            status: true,
            image_id: image.id,
          });
        });
        for (let i = res.data.s_p_v_a_d_images.length; i < 8; i++) {
          images.push({
            img: "/assest/images/Seller_panel/dashboard/icons/uploadImage.jpg",
            dltimg: "/assest/images/Seller_panel/dashboard/icons/dltt.png",
            selectedImage: null,
            status: false,
          });
        }

        setValue(`uploadimages`, images);

        setValue(`thumbnail`, res.data?.thumbnail);
        setValue(`description`, res.data.description);
        setValue(`discountable`, res.data.s_p_v_a_d_o_d.discountable);
        setValue(`open_delivery`, res.data.s_p_v_a_d_o_d.open_delivery);

        setValue(`breath`, res.data.s_p_v_a_d_dimension.breath);
        setValue(`height`, res.data.s_p_v_a_d_dimension.height);
        setValue(`length`, res.data.s_p_v_a_d_dimension.length);
        setValue(`weight`, res.data.s_p_v_a_d_dimension.weight);
        // setValue(`s_unit_id`, {
        //   value: res.data.s_p_v_a_d_dimension.s_unit.id,
        //   label: res.data.s_p_v_a_d_dimension.s_unit.name,
        // });

        setValue(
          `after_platform_charges`,
          res.data.s_p_v_a_d_price_a_stock.after_platform_charges
        );
        setValue(
          `minimum_order_quantity`,
          res.data.s_p_v_a_d_price_a_stock.minimum_order_quantity
        );
        setValue(`mrp`, res.data.s_p_v_a_d_price_a_stock.mrp);
        setValue(
          `selling_price`,
          res.data.s_p_v_a_d_price_a_stock.selling_price
        );
        setValue(`stock`, res.data.s_p_v_a_d_price_a_stock.stock);

        setValue("hsn_id", {
          value: res.data.s_p_v_a_d_tax_d.hsn_id,
          label: res.data.s_p_v_a_d_tax_d?.hsn_code?.name,
          value: res.data.s_p_v_a_d_tax_d.hsn_id,
          label:
            res.data.s_p_v_a_d_tax_d?.hsn_code?.name +
            " | " +
            res.data.s_p_v_a_d_tax_d?.hsn_code?.gst +
            "%" +
            " | " +
            res.data.s_p_v_a_d_tax_d?.hsn_code?.product_narration,
          name: res.data.s_p_v_a_d_tax_d?.hsn_code?.name,
          percent: res.data.s_p_v_a_d_tax_d?.hsn_code?.gst,
          below: res.data.s_p_v_a_d_tax_d?.hsn_code?.below,
          gst_narration: res.data.s_p_v_a_d_tax_d?.hsn_code?.gst_narration,
        });

        setHsnNarration(res.data.s_p_v_a_d_tax_d?.hsn_code?.gst_narration);

        setValue(`s_gst_id`, {
          value: res.data.s_p_v_a_d_tax_d.s_gst?.id,
          label: res.data.s_p_v_a_d_tax_d.s_gst?.id,
        });

        setSelectedImage(IMG_URL + res.data?.thumbnail);

        setDataStatus(true);
        setCurrentTab("1");
      } else {
        reset();

        setCurrentTab("1");
        setSelectedImage("");
        setValue(`s_gst_id`, null);
        setValue(`hsn_id`, null);
        // setValue(`s_unit_id`, null);
        setValue(`description`, "");
        setValue(`uploadimages`, uploadDefaultImages);
      }
    }
  };

  const [tableData, setTableData] = useState([]);
  const getMultipleVariant = async () => {
    const cookie = Cookies.get("s_product_id");
    if (cookie) {
      const res = await singleVariantGet(cookie);
      if (res?.success) {
        // console.log(res);
        setTableData(res.data.s_product_variant_attribute);
        allIdFunction(res.data.s_product_variant_attribute);
      }
    }
  };

  const { getData, IMG_URL, Select2Data, SelectHsnData } = useContext(Context);
  const [gsts, setGsts] = useState(null);
  const [units, setUnit] = useState([]);
  const [hsnCode, setHsnCode] = useState([]);
  const [productInfoIcon, setProductInfoIcon] = useState([]);

  const [platform_charges, setPlatform_Charges] = useState("");

  const getDataAll = async () => {
    {
      const res = await GST();
      if (res?.success) {
        setGsts(await Select2Data(res?.data, "s_gst_id", false));
      }
    }
    {
      const res = await Unit();
      if (res?.success) {
        setUnit(await Select2Data(res?.data, "unit_id", false));
      }
    }

    {
      const res = await PlatformCharges();
      if (res?.success) {
        setPlatform_Charges(res.data.no);
      }
    }
    {
      const res = await hsnCodes();
      if (res?.success) {
        setHsnCode(await SelectHsnData(res?.data, "hsn_id", true));
      }
    }
    {
      const res = await ProductInfoIcon();
      if (res?.success) {
        setProductInfoIcon(res?.data);
      }
    }
  };

  const stockValue = watch("stock");

  const [customError, setCustomError] = useState(""); // State for custom error message

  // Separate validation function
  const validateOrderQuantity = (orderQuantity) => {
    const stock = parseInt(stockValue, 10);
    const orderQty = parseInt(orderQuantity, 10);
    console.log("Validating - Order Quantity:", orderQty, "Stock:", stock);
    
    if (!stock || orderQty > stock) {
      setCustomError("Order quantity cannot exceed stock");
    } else {
      setCustomError(""); // Clear the error if the validation passes
    }
  };

  const [hsnInputStatus, setHsnInputStatus] = useState(false);
  const [existHsn, setExistHsn] = useState("");
  const [hsnNarration, setHsnNarration] = useState("");
  const handleHsnChange = async (d) => {
    console.log(d);
    setValue(`hsn_id`, d);
    setHsnNarration(d?.gst_narration);
    if (d.value === "0") {
      setHsnInputStatus(true);
      setValue(`hsn_no`, "");
      setValue(`gst_percent`, "");
      setValue("after_tax_charges", getValues("after_platform_charges"));
    } else {
      setHsnInputStatus(false);
      if (getValues("after_platform_charges")) {
        let totalPrice = getValues("after_platform_charges");
        let taxvalue = 0;
        if (d?.below) {
          if (getValues("after_platform_charges") < Number(d?.below)) {
            taxvalue = (d.percent * getValues("after_platform_charges")) / 100;
          } else {
            taxvalue = (12 * getValues("after_platform_charges")) / 100;
          }
        } else {
          taxvalue = (d.percent * getValues("after_platform_charges")) / 100;
        }

        totalPrice = getValues("after_platform_charges") + taxvalue;

        setValue("after_tax_charges", totalPrice);
      }
    }
  };

  useEffect(() => {
    getMultipleVariant();
    getDataAll();
  }, []);



  console.log(getValues(), "values");
  console.log(allVariantId, "al");
  console.log(levelType, "lvl");

  const commonSubmit = async (data, id) => {
    // console.log(data);
    var sendData = new FormData();

    if (levelType === 2) {
      sendData.append("id", id);
    }
    if (levelType === 3) {
      sendData.append("id", id);
    }

    sendData.append("stock", data.stock);
    sendData.append("mrp", data.mrp);
    sendData.append("selling_price", data.selling_price);
    sendData.append("after_platform_charges", data.after_platform_charges);
    sendData.append("minimum_order_quantity", data.minimum_order_quantity);
    // sendData.append("s_unit_id", data.s_unit_id.value);
    // sendData.append("s_gst_id", data.s_gst_id.value);
    if (hsnInputStatus) {
      sendData.append("hsn_no", data.hsn_no);
      sendData.append("gst_percent", data.gst_percent);
    } else {
      sendData.append("hsn_id", data.hsn_id?.value);
    }
    sendData.append("length", data.length);
    sendData.append("breath", data.breath);
    sendData.append("height", data.height);
    sendData.append("weight", data.weight);
    sendData.append("description", data.description);
    sendData.append("open_delivery", data.open_delivery ? 1 : 0);
    sendData.append("discountable", data.discountable ? 1 : 0);
    var thumbnailFile = document.getElementById("thumbnailInput");
    if (thumbnailFile.files[0]) {
      sendData.append("thumbnail", thumbnailFile.files[0]);
    } else {
      sendData.append("thumbnail", data.thumbnail);
    }

    let count = 0;

    sendData.append("start", count);
    data.uploadimages.forEach((image, imageIndex) => {
      if (image.status) {
        var file = document.getElementById(`product_image_${imageIndex}`);
        if (file.files[0]) {
          sendData.append("image" + count, file.files[0]);
        }
        count++;
      }
    });
    sendData.append("end", count);
    sendData.append("uploadimages", JSON.stringify(data.uploadimages));

    const res = await singleVariantPost(sendData);

    return res;
  };
  const onSubmit = async (data) => {
    // console.log(data);
    var sendData = new FormData();

    if (levelType === 2) {
      sendData.append("id", variantId);
    }
    if (levelType === 3) {
      sendData.append("id", variantId);
    }

    sendData.append("stock", data.stock);
    sendData.append("mrp", data.mrp);
    sendData.append("selling_price", data.selling_price);
    sendData.append("after_platform_charges", data.after_platform_charges);
    sendData.append("minimum_order_quantity", data.minimum_order_quantity);
    // sendData.append("s_unit_id", data.s_unit_id.value);
    // sendData.append("s_gst_id", data.s_gst_id.value);
    if (hsnInputStatus) {
      sendData.append("hsn_no", data.hsn_no);
      sendData.append("gst_percent", data.gst_percent);
    } else {
      sendData.append("hsn_id", data.hsn_id?.value);
    }

    sendData.append("length", data.length);
    sendData.append("breath", data.breath);
    sendData.append("height", data.height);
    sendData.append("weight", data.weight);
    if (data.description) sendData.append("description", data.description);

    sendData.append("open_delivery", data.open_delivery ? 1 : 0);
    sendData.append("discountable", data.discountable ? 1 : 0);
    var thumbnailFile = document.getElementById("thumbnailInput");
    if (thumbnailFile.files[0]) {
      sendData.append("thumbnail", thumbnailFile.files[0]);
    } else {
      sendData.append("thumbnail", data.thumbnail);
    }

    let count = 0;

    sendData.append("start", count);
    data.uploadimages.forEach((image, imageIndex) => {
      if (image.status) {
        var file = document.getElementById(`product_image_${imageIndex}`);
        if (file.files[0]) {
          sendData.append("image" + count, file.files[0]);
        }
        count++;
      }
    });
    sendData.append("end", count);
    sendData.append("uploadimages", JSON.stringify(data.uploadimages));

    const res = await singleVariantPost(sendData);
    if (res?.success) {
      // console.log(res);
      setDataStatus(true);

      const addId = variantId;

      if (!allSubmited.includes(addId)) {
        setAllSubmited([...allSubmited, addId]);
      }
      setError("");
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
      }, 2000);
    }
  };

  const [successModal, setSuccessModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isRemoveChecked, setIsRemoveChecked] = useState(false);
  const handleCommonClick = async () => {
    if (window.confirm("Are you sure you want to make common data for all?")) {
      if (dataStatus) {
        var addId = [];
        for (const value of allVariantId) {
          addId.push(value);
          await commonSubmit(getValues(), value);
        }
        setAllSubmited(addId);
        setIsChecked(true);
        setSuccessModal(true);
        setTimeout(() => {
          setSuccessModal(false);
        }, 2000);
      } else {
        alert("Please Select variant which has data");
      }
    } else {
      setIsChecked(false);
    }
  };

  const handleRemoveClick = async () => {
    if (
      window.confirm("Are you sure you want to remove all data of variants?")
    ) {
      setIsRemoveChecked(true);
      for (const value of allVariantId) {
        await RemoveSingleAll(value);
      }
      setIsRemoveChecked(false);
      setIsChecked(false);
      getMultipleVariant();
      reset();
      setSelectedImage("");
      // setValue(`s_gst_id`, null);
      setValue(`hsn_id`, null);
      // setValue(`s_unit_id`, null);
      setValue(`description`, "");
      setValue(`uploadimages`, uploadDefaultImages);
      setAllSubmited([]);
    } else {
      setIsRemoveChecked(false);
    }
  };

  console.log(allSubmited, "submitted");
  const [error, setError] = useState("");
  const handleNext = async () => {
    if (allSubmited.length === allVariantId.length) {
      nextStep();
    } else {
      setError("Please Store All variant data");
    }
  };

  const handlePrev = () => {
    setRedirectToPrev(true); // Set redirectToNext to true
  };

  const [currentTab, setCurrentTab] = useState("1");

  const handleNextTab = async (type) => {
    if (type === "back") {
      setCurrentTab(Number(currentTab) - 1);
    } else {
      setCurrentTab(Number(currentTab) + 1);
    }
  };
  // Check if redirectToNext is true, if true, render SingleVariantInner
  if (redirectToPrev) {
    return <SingleVariant nextStep={nextStep} prevInnerPage={prevInnerPage} />;
  }
  return (
    <section className="single-varient-sec">
      <div className="sec-div ">
        <div className="row">
          <div className="col-xxl-4 col-xl-4 col-md-4">
            <Table bordered style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="(Remove all)"
                      checked={isRemoveChecked}
                      onClick={handleRemoveClick}
                    />
                  </th>
                  <th>
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="(Common for all)"
                      checked={isChecked}
                      onClick={handleCommonClick}
                    />
                  </th>
                </tr>
                <tr>
                  <th>
                    {tableData?.s_attribute?.name}{" "}
                    {tableData?.s_attribute?.defind}
                  </th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.s_product_variant_attribute_details?.map(
                  (value, index) => (
                    <tr>
                      <td>
                        {value?.s_sub_attribute?.name}{" "}
                        {tableData?.s_attribute?.defind}
                      </td>
                      {value?.status ? (
                        <td
                          className={
                            variantId === value?.id ? "green-add" : "red-add"
                          }
                          onLoad={() => allIdFunction(2, value?.id)}
                          onClick={() => getVariantData(2, value?.id)}
                        >
                          Add
                        </td>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
          <div className="col-xxl-8 col-xl-8 col-md-8">
            <div className="single-variant-tabsshd multi-scroll-box">
              {variantId && (
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="1"
                  activeKey={currentTab}
                  className="mb-3"
                >
                  <Row>
                    <Nav variant="pills" className="flex-row">
                      <Nav.Item>
                        <Nav.Link eventKey="1" onClick={() => setCurrentTab(1)}>
                          Product Images
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="2" onClick={() => setCurrentTab(2)}>
                          Price & Stock Details
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="3" onClick={() => setCurrentTab(3)}>
                          Dimension
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="4" onClick={() => setCurrentTab(4)}>
                          Thumbnail
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="5" onClick={() => setCurrentTab(5)}>
                          Technical Description
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Tab.Content>
                      <Tab.Pane eventKey="1">
                        <div className="tab-content-data">
                          <h6 className="proppdp">
                            Product Photos <span>(Max 8)</span>
                          </h6>
                          <div className="tab-content-data">
                            <div className="uploadbox">
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="slider-div">
                                    <Swiper
                                      slidesPerView={3.5}
                                      spaceBetween={30}
                                      freeMode={true}
                                      pagination={{
                                        clickable: false,
                                      }}
                                      modules={[FreeMode, Pagination]}
                                      className="mySwiper"
                                    >
                                      {getValues(`uploadimages`)?.map(
                                        (data, index) => (
                                          <SwiperSlide>
                                            <div className="upd-card">
                                              <label
                                                htmlFor={`product_image_${index}`}
                                              >
                                                <img
                                                  className="slide-img"
                                                  src={
                                                    data.selectedImage
                                                      ? data.selectedImage
                                                      : data.img
                                                  }
                                                />
                                              </label>

                                              <input
                                                type="file"
                                                className="d-none"
                                                accept="image/*"
                                                id={`product_image_${index}`}
                                                onChange={(e) =>
                                                  handleProductImage(e, index)
                                                }
                                              />
                                              {data?.selectedImage && (
                                                <img
                                                  className="dltt-slide"
                                                  onClick={() =>
                                                    removeProductImage(index)
                                                  }
                                                  src={data.dltimg}
                                                />
                                              )}
                                            </div>
                                          </SwiperSlide>
                                        )
                                      )}
                                    </Swiper>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="produuyct-discri">
                                    <h6 className="tttt">Image Resolution</h6>
                                    <p className="testtt">
                                      Use clear color images with a minimum
                                      resolution of 512*512 px.
                                    </p>
                                    <h6 className="tttt">Image Guidelines</h6>
                                    <p className="testtt">
                                      Upload authentic product photos taken in
                                      bright lighting
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="2">
                        <div className="tab-content-data">
                          <div className="row">
                            <div className="col-md-4 mb-3">
                              <Form.Label>
                                Stock<span className="mandatory-star"> *</span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-title">
                                      {productInfoIcon?.stock}
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle-info"
                                    className="ms-3"
                                  />
                                </OverlayTrigger>
                              </Form.Label>

                              <Form.Control
                                type="number"
                                name={`stock`}
                                placeholder="Stock.."
                                className={classNames("", {
                                  "is-invalid": errors.stock,
                                })}
                                {...register(`stock`, {
                                  required: "Stock required",
                                })}
                              />
                            </div>

                            <div className="col-md-4  mb-3">
                              <Form.Label> MRP</Form.Label>
                              <Form.Control
                                type="text"
                                name={`mrp`}
                                placeholder="MRP.."
                                className={classNames("", {
                                  "is-invalid": errors.mrp,
                                })}
                                {...register(`mrp`, {
                                  required: "MRP required",
                                })}
                                onChange={(e) => {
                                  if (
                                    getValues("selling_price") +
                                    Number(platform_charges) >
                                    Number(e.target.value)
                                  ) {
                                    setValue("selling_price", "");
                                  }
                                }}
                              />
                            </div>
                            <div className="col-md-4  mb-3">
                              <Form.Label> Selling Price</Form.Label>
                              <Form.Control
                                type="number"
                                name={`selling_price`}
                                placeholder="Selling Price.."
                                className={classNames("", {
                                  "is-invalid": errors.selling_price,
                                })}
                                {...register(`selling_price`, {
                                  required: "Selling Price required",
                                })}
                                onChange={(e) => {
                                  if (
                                    Number(e.target.value) +
                                    Number(platform_charges) >
                                    getValues("mrp")
                                  ) {
                                    setValue("selling_price", "");
                                  } else {
                                    setValue("selling_price", e.target.value);

                                    setValue(
                                      "after_platform_charges",
                                      Number(e.target.value) +
                                      Number(platform_charges)
                                    );

                                    let price =
                                      Number(e.target.value) +
                                      Number(platform_charges);

                                    let totalPrice = price;
                                    let taxvalue = 0;
                                    if (getValues("hsn_id")?.below) {
                                      if (
                                        price <
                                        Number(getValues("hsn_id")?.below)
                                      ) {
                                        taxvalue =
                                          (getValues("hsn_id")?.percent *
                                            price) /
                                          100;
                                      } else {
                                        taxvalue = (12 * price) / 100;
                                      }
                                    } else {
                                      taxvalue =
                                        (getValues("hsn_id")?.percent * price) /
                                        100;
                                    }

                                    totalPrice = price + taxvalue;

                                    setValue("after_tax_charges", totalPrice);
                                  }
                                }}
                              />
                              <sup className="text-info">
                                Platform Charge - ₹ {platform_charges}
                              </sup>
                            </div>
                            <div className="col-md-12  mb-3">
                              <Form.Label>HSN</Form.Label>

                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-title">
                                    {productInfoIcon?.hsn_code}
                                  </Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-circle-info"
                                  className="ms-3"
                                />
                              </OverlayTrigger>
                              <Controller
                                name={`hsn_id`} // name of the field
                                {...register(`hsn_id`, {
                                  required: "Select HSN",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors?.hsn_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={hsnCode}
                                    onChange={(e) => handleHsnChange(e)}
                                  />
                                )}
                              />
                            </div>
                            {hsnInputStatus && (
                              <>
                                <div className="col-md-4  mb-3">
                                  <Form.Label>HSN Code</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name={`hsn_no`}
                                    placeholder="HSN Code"
                                    className={classNames("", {
                                      "is-invalid": errors?.hsn_no,
                                    })}
                                    {...register(`hsn_no`, {
                                      required: "HSN Code required",
                                    })}
                                  />
                                </div>
                                <div className="col-md-4  mb-3">
                                  <Form.Label>Gst Percent</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name={`gst_percent`}
                                    placeholder="Gst Percent"
                                    className={classNames("", {
                                      "is-invalid": errors?.gst_percent,
                                    })}
                                    {...register(`gst_percent`, {
                                      required: "Gst Percent required",
                                    })}
                                    onChange={(e) => {
                                      if (getValues("after_platform_charges")) {
                                        let taxvalue =
                                          (e.target.value *
                                            getValues(
                                              "after_platform_charges"
                                            )) /
                                          100;

                                        let totalPrice =
                                          getValues("after_platform_charges") +
                                          taxvalue;

                                        setValue(
                                          "after_tax_charges",
                                          totalPrice
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </>
                            )}
                            <div className="col-md-12  mb-3">
                              <sub className="text-info">{hsnNarration}</sub>
                            </div>
                            <div className="col-md-4  mb-3">
                              <Form.Label>After platform charges</Form.Label>
                              <Form.Control
                                type="text"
                                disabled
                                name={`after_platform_charges`}
                                placeholder="After platform charges.."
                                className={classNames("", {
                                  "is-invalid": errors.after_platform_charges,
                                })}
                                {...register(`after_platform_charges`, {
                                  required: "After platform charges required",
                                })}
                              />
                              <sup className="text-info">
                                After Platform Charge should be less than MRP
                              </sup>
                            </div>

                            <div className="col-xxl-6 col-xl-6 col-md-4  mb-3">
                              <Form.Label>Minimum Order Quantity</Form.Label>
                              <Form.Control
                                type="number"
                                name={`minimum_order_quantity`}
                                placeholder="Minimum Order Quantity"
                                className={classNames("", {
                                  "is-invalid": errors.minimum_order_quantity,
                                })}
                                {...register(`minimum_order_quantity`, {
                                  required: "Minimum order quantity is required",
                                  min: {
                                    value: 1,
                                    message: "Minimum order quantity must be greater than zero",
                                  },
                                  onChange: (e) => validateOrderQuantity(e.target.value),
                                })}
                              />
                              {errors.minimum_order_quantity && (
                                <div className="invalid-feedback">
                                  {errors.minimum_order_quantity.message}
                                </div>
                              )}
                               {customError &&  <div className="text-danger" style={{ fontSize: "14px" }}>{customError}</div>}
                            </div>

                            <div className="col-xxl-6 col-xl-6 col-md-4  mb-3">
                              <Form.Label>
                                After tax charges (selling price)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                disabled
                                name={`after_tax_charges`}
                                placeholder="After tax charges.."
                                className={classNames("", {
                                  "is-invalid": errors.after_tax_charges,
                                })}
                                {...register(`after_tax_charges`)}
                              />
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="3">
                        <div className="tab-content">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <Form.Label>
                                Length<span className="mandatory-star"> *</span>
                                <span>(MM)</span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-title">
                                      {productInfoIcon?.length}
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle-info"
                                    className="ms-3"
                                  />
                                </OverlayTrigger>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                name={`length`}
                                placeholder="Length.."
                                className={classNames("", {
                                  "is-invalid": errors.length,
                                })}
                                {...register(`length`, {
                                  required: "Length required",
                                })}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <Form.Label>
                                Breadth
                                <span className="mandatory-star"> *</span>
                                <span>(MM)</span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-title">
                                      {productInfoIcon?.breadth}
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle-info"
                                    className="ms-3"
                                  />
                                </OverlayTrigger>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                name={`breath`}
                                placeholder="Breadth.."
                                className={classNames("", {
                                  "is-invalid": errors.breath,
                                })}
                                {...register(`breath`, {
                                  required: "Breadth required",
                                })}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <Form.Label>
                                Height<span className="mandatory-star"> *</span>
                                <span>(MM)</span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-title">
                                      {productInfoIcon?.height}
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle-info"
                                    className="ms-3"
                                  />
                                </OverlayTrigger>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                name={`height`}
                                placeholder="Height.."
                                className={classNames("", {
                                  "is-invalid": errors.height,
                                })}
                                {...register(`height`, {
                                  required: "Height required",
                                })}
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <div className="row">
                                <div className="col-md-7">
                                  <Form.Label>
                                    Weight (Kg)
                                    <span className="mandatory-star"> *</span>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip-title">
                                          {productInfoIcon?.weight}
                                        </Tooltip>
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-circle-info"
                                        className="ms-3"
                                      />
                                    </OverlayTrigger>
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    name={`weight`}
                                    placeholder="Weight.."
                                    className={classNames("", {
                                      "is-invalid": errors.weight,
                                    })}
                                    {...register(`weight`, {
                                      required: "Weight required",
                                    })}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="4">
                        <div className="tab-content">
                          <p className="thumbnail-txt">Thumbnail</p>
                          <div className="row">
                            <div className="col-md-8">
                              <input
                                type="file"
                                id={"thumbnailInput"}
                                className={classNames("", {
                                  "is-invalid": errors.thumbnail,
                                })}
                                accept="image/*"
                                name={`thumbnail`}
                                {...register(`thumbnail`)}
                                onChange={(e) => thumbnailChange(e)}
                              />
                              <br />
                              <span className="jpgimg">
                                {selectedImage ? selectedImage.name : ""}
                              </span>
                            </div>
                            <div className="col-md-4">
                              <div className="uplod-imm-div">
                                {selectedImage && (
                                  <>
                                    <img
                                      className="up-imgdg"
                                      src={selectedImage}
                                    />
                                    <img
                                      className="dlttt"
                                      width={100}
                                      onClick={() => handleRemoveThumbnail()}
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/Seller_panel/dashboard/icons/dltt.png"
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {errors.thumbnail && (
                            <span className="text-danger">
                              {errors.thumbnail.message}
                            </span>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="5">
                        <div className="tab-content">
                          <Controller
                            name={`description`}
                            control={control}
                            // rules={{ required: "Description is required" }}
                            render={({ field }) => (
                              <div>
                                <JoditEditor
                                  value={content}
                                  tabIndex={1}
                                  onBlur={(newContent) =>
                                    setContent(newContent)
                                  }
                                  {...field}
                                />
                                {errors?.description && (
                                  <span className="text-danger">
                                    {errors?.description.message}
                                  </span>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                  <div className="common-steps-btn">
                    <button
                      type="button"
                      onClick={() => handleNextTab("back")}
                      className="back-btn"
                    >
                      Back
                    </button>
                    {currentTab == 5 ? (
                      <></>
                    ) : (
                      <button
                        type="button"
                        onClick={() => handleNextTab("next")}
                        className="next-btn"
                      >
                        Next
                      </button>
                    )}

                    <button
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                      className="next-btn"
                    >
                      Save
                    </button>
                  </div>
                  {error && <sub className="text-danger">{error}</sub>}
                </Tab.Container>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="common-steps-btn">
        <button type="button" onClick={handlePrev} className="back-btn">
          Back
        </button>

        <button type="button" onClick={handleNext} className="next-btn">
          Next
        </button>
      </div>
      <SuccessModal show={successModal} onHide={() => setSuccessModal(false)} />
    </section>
  );
};

export default SingleVariantInner;
