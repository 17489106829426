import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./SuccessModal.css";
import Animation from "../Animation/SuccessAnimation/Animation";
const SuccessModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="SuccessModalPop"
      >
        <Modal.Body>
          <Animation />

          <div className="modalContentinfo">
            <div className="modalHead">
              <h3>SUCCESS!!</h3>
              <p>Your Data has been Saved.</p>
            </div>

            {/* <div className="text-center">
              <Button className="btn tryagainbtn">Try again</Button>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessModal;
