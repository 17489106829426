import React, { useEffect, useState, useContext } from "react";
import "./EditPersonalDetailsModal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ButtonComponent from "../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChangeModal from "./ChangeModal";
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import InquiryModal from "../../../common-components/AllModal/inquiry-Modal/InquiryModal";
import {
  sendMobileOTP,
  resendMobileOTP,
  sendEmailOTP,
  verifyMobileOTP,
  verifyEmailOTP,
} from "../../../../../utils/apis/common/Common";
import classNames from "classnames";
import { bankVerify } from "../../../../../utils/apis/third_party/third_party";
import { getValue } from "@testing-library/user-event/dist/utils";
const EditBankDetailsModal = (props) => {
  const [modalChange, setModalChange] = useState(false);
  const [modalInquiry, setModalInquiry] = useState(false);
  const { postData, Id } = useContext(Context);

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      verify_status: false,
    },
  });

  const submitModal = () => {
    setModalInquiry(true);
    setTimeout(() => {
      props.onHide();
      reset();
      setModalInquiry(false);
    }, 3000);
  };

  const onSubmit = async (data) => {
    if (data?.verify_status) {
      clearErrors();
      data.btb_personal_details_id = Id;
      console.log("dataHere", data);
      const res = await postData("/btb/btb-create/btb-bank-details", data);
      if (res?.success) {
        submitModal(true);
      }
    } else {
      setError("verify_status", {
        type: "manual",
        message: "Verify your Account No.",
      });
    }
  };

  useEffect(() => {
    reset(props?.data);
  }, [props?.data]);

  const verifyIfsc = async (data) => {
    const res = await bankVerify(
      data.account_no,
      data.ifsc
      // getValues("bank_account_no"),
      // getValues("ifsc_code")
    );
    if (res?.success) {
      clearErrors();
      setValue("bank_name", res?.data?.data?.ifsc_details?.bank_name);
      setValue("branch_name", res?.data?.data?.ifsc_details?.branch);
      setValue("verify_status", res?.success);
    } else {
      setValue("verify_status", false);
      setError("ifsc", {
        type: "manual",
        message: res?.message?.message,
      });
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-details-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Bank Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Form.Group as={Col} md={4} controlId="formGridName">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  name="bank_name"
                  placeholder="Bank Name"
                  className={classNames("", {
                    "is-invalid": errors?.bank_name,
                    "is-valid": !errors?.bank_name && getValues("bank_name"),
                  })}
                  {...register("bank_name", {
                    required: "Bank Name is required",
                  })}
                />
              </Form.Group>

              <Form.Group as={Col} md={4} controlId="formGridName">
                <Form.Label>Branch Name</Form.Label>
                <Form.Control
                  type="text"
                  name="branch_name"
                  placeholder="Branch Name"
                  className={classNames("", {
                    "is-invalid": errors?.branch_name,
                    "is-valid":
                      !errors?.branch_name && getValues("branch_name"),
                  })}
                  {...register("branch_name", {
                    required: "Branch Name is required",
                  })}
                />
              </Form.Group>

              <Form.Group as={Col} md={4} controlId="formGridContact">
                <Form.Label>Account Number</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    name="account_no"
                    placeholder="Account No"
                    className={classNames("", {
                      "is-invalid": errors?.account_no,
                      "is-valid":
                        !errors?.account_no && getValues("account_no"),
                    })}
                    {...register("account_no", {
                      required: "Account is required",
                      validate: {
                        isTenDigits: (value) =>
                          (value && value.length > 10) || "Invalid Account No",
                        isNumeric: (value) =>
                          /^\d+$/.test(value) || "Account No must be numeric",
                      },
                    })}
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (
                        !/[0-9]/.test(key) &&
                        key !== "Backspace" &&
                        key !== "Tab"
                      ) {
                        event.preventDefault();
                        setValue("verify_status", false);
                      }
                      // if (event.target.value?.length >= 10 && key !== "Backspace" && key !== "Tab") {
                      //     event.preventDefault();
                      // }
                    }}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col} md={4} controlId="formGridDesignation">
                <Form.Label>IFSC Code</Form.Label>
                <Form.Control
                  type="text"
                  name="ifsc"
                  placeholder="IFSC Code"
                  className={classNames("", {
                    "is-invalid": errors?.ifsc,
                    "is-valid": !errors?.ifsc && getValues("ifsc"),
                  })}
                  {...register("ifsc", {
                    required: "IFSC Code is required",
                    validate: {
                      isTenDigits: (value) =>
                        (value && value.length > 10) || "Invalid IFSC Code",
                    },
                  })}
                  onKeyDown={(event) => {
                    const { key } = event;
                    setValue("verify_status", false);
                    if (
                      !/[0-9A-Za-z]/.test(key) &&
                      key !== "Backspace" &&
                      key !== "Tab"
                    ) {
                      event.preventDefault();
                    }
                    if (
                      event.target.value?.length >= 11 &&
                      key !== "Backspace" &&
                      key !== "Tab"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    event.target.value = event.target.value.toUpperCase(); // Convert to uppercase
                  }}
                />
              </Form.Group>

              {!getValues("verify_status") && (
                <Form.Group as={Col} md={4} controlId="formGridDesignation">
                  <Form.Label>Verify Bank Details</Form.Label>
                  <ButtonComponent
                    type={"button"}
                    onClick={() => {
                      clearErrors();
                      handleSubmit(verifyIfsc)();
                    }}
                    className={"btn-cancle"}
                  >
                    Verify
                  </ButtonComponent>
                  {errors.verify_status && (
                    <span className="text-danger">
                      {errors.verify_status.message}
                    </span>
                  )}
                </Form.Group>
              )}
            </Row>
            <Modal.Footer>
              <ButtonComponent
                type={"button"}
                onClick={props.onHide}
                className={"btn-cancle"}
              >
                Cancel
              </ButtonComponent>

              <ButtonComponent
                // type="submit"
                type={"button"}
                onClick={handleSubmit(onSubmit)}
                className={"btn-update"}
              >
                {" "}
                Update
              </ButtonComponent>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <ChangeModal show={modalChange} onHide={() => setModalChange(false)} />
      <InquiryModal
        message="Submitted successfully"
        show={modalInquiry}
        onHide={() => setModalInquiry(false)}
      />
    </>
  );
};

export default EditBankDetailsModal;
