import React from 'react'
import Lottie from 'react-lottie';
import './SuccessLottey.css';
import * as animationData from './succes-lottey.json';
const SuccessLottey = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    return (
        <>
            <section className='main-22'>
                <div className='Successfull_Lottie_icon'>
                    <Lottie className="mx-auto"
                        options={defaultOptions}
                    />
                </div>
            </section>
        </>
    )
}

export default SuccessLottey
