import React from "react"
import MyProfile from "./MyProfile/MyProfile"
import MyprofileBanner from "./MyprofileBanner/MyprofileBanner"
const Accounts = () => {
    return (
        <>
            <section className="myprofile-main-sec">
                <MyprofileBanner />
                <MyProfile />
            </section>
        </>
    )
}

export default Accounts