
import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import './DashboardSidebar.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation } from 'react-router-dom';
const DashboardSidebar = () => {
    const [activeKey, setActiveKey] = useState('0');
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        switch (path) {
            case '/b2bbuyer/b2bdeshboard/b2bdashboard':
                setActiveKey('0');
                break;
            case '/b2bbuyer/b2bdeshboard/b2border':
                setActiveKey('1');
                break;
            case '/b2bbuyer/b2bdeshboard/b2binquiry':
                setActiveKey('2');
                break;
            case '':
                setActiveKey('3');
                break;
            case '/b2bbuyer/b2bdeshboard//b2bmessage':
                setActiveKey('4');
                break;
            case '':
                setActiveKey('5');
                break;
            case '':
                setActiveKey('6');
                break;
            case '/b2bbuyer/b2bdeshboard/b2brefund':
                setActiveKey('7');
                break;

            case '/b2bbuyer/b2bdeshboard/b2bcart':
                setActiveKey('16');
                break;
            default:
                setActiveKey('0');
        }
    }, [location.pathname]);
    return (
        <>
            <div className="com-sidebar">
                <div className="logo-image-main">
                    <h1>Menu</h1>
                </div>
                <div className="brown-border"></div>
                <Row >
                    <Col xl={12}>
                        <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <Link to='/b2bbuyer/b2bdeshboard/b2bdashboard'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>

                                        Dashboard
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to='/b2bbuyer/b2bdeshboard/b2border'>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        Orders
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to='/b2bbuyer/b2bdeshboard/b2binquiry'>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        Inquiries
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        Products B2B
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to='/b2bbuyer/b2bdeshboard/b2bmessage'>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        Message
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                        Transport
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>
                                        RTO
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to='/b2bbuyer/b2bdeshboard/b2brefund'>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>
                                        Refund & Return
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>
                                        Compliant
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>
                                        Checker
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>
                                        Payment
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="11">
                                    <Accordion.Header>
                                        Advertisement
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="12">
                                    <Accordion.Header>
                                        Coupon
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="13">
                                    <Accordion.Header>
                                        Farmer | Add Inventory
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="14">
                                    <Accordion.Header>
                                        Farmer | Crops Planing
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="15">
                                    <Accordion.Header>
                                        My Purchase
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to='/b2bbuyer/b2bdeshboard/b2bcart'>
                                <Accordion.Item eventKey="16">
                                    <Accordion.Header>
                                        My Cart
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="17">
                                    <Accordion.Header>
                                        My Inquiry
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="18">
                                    <Accordion.Header>
                                        My Store
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="19">
                                    <Accordion.Header>
                                        My Website
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="20">
                                    <Accordion.Header>
                                        3p Team Support
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="21">
                                    <Accordion.Header>
                                        My Subscription
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="22">
                                    <Accordion.Header>
                                        Employee Management
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="23">
                                    <Accordion.Header>
                                        Support
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Link to=''>
                                <Accordion.Item eventKey="24">
                                    <Accordion.Header>
                                        Policies
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                            <Accordion.Body>
                                {/* Your body content */}
                            </Accordion.Body>
                        </Accordion>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default DashboardSidebar