import React from "react";
import "./New_Customers_Clicks.css";
import CanvasJSReact from "@canvasjs/react-charts";

function New_Customers_Clicks() {
  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", // "light1", "dark1", "dark2"
  
    axisY: {
      lineThickness: 0, // Hide Y axis
    },
    axisX: {
      interval: 1,
      lineThickness: 0, // Hide X axis
    },
    toolBar: { enabled: false }, // Hide the toolbar
    height: 220,
    data: [
      {
        type: "line",
        toolTipContent: "Week {x}: {y}%",
        dataPoints: [
          { x: 1, y: 64 },
          { x: 2, y: 61 },
          { x: 3, y: 64 },
          { x: 4, y: 62 },
          { x: 5, y: 64 },
         
        
     
        ],
      },
    ],
  };

  return (
    <>
      <div className="New_Customers_Clicks" >
        <div className="dashboard-box-heading-holder">
          <h4>Clicks</h4>
        </div>

        <div className="line_chart">
          <CanvasJSReact.CanvasJSChart options={options}  />
        </div>
      </div>
    </>
  );
}

export default New_Customers_Clicks;
