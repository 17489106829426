import React from "react";
import "./Earning.css";

import { LineChart } from "@mui/x-charts/LineChart";

function Earning() {
  return (
    <>
      <div className="Earning">
        <div className="dashboard-box-heading-holder">
          <div>
            <h4>Earning</h4>
          </div>
          <div className="">
            <div className="row ">
              <div className="col-md-6">
                <div className="text-holder">
                  <h6>This Month</h6>
                  <p>₹50k</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="text-holder">
                  <h6>This Month</h6>
                  <p>₹50k</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <LineChart
            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
            series={[
              {
                data: [2, 5.5, 2, 8.5, 1.5, 5],
              },
            ]}
            width={500}
            height={300}
          />
        </div>
      </div>
    </>
  );
}

export default Earning;
