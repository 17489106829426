import React from 'react';
import './Subscription.css';
import { Container, Form, Nav, Tab } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';
import { Pagination } from 'swiper/modules';
const Subscription = ({subscriptionData}) => {
    
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const subscriptionPlans = [
        {
            title: "Subscription Plan",
            price: "99",
            features: [
                "EdrawMax online (Limited-Time Offer)",
                "1-year access on 3 PCs & 3 mobiles & online",
                "Access to all features",
                "10GB free cloud storage"
            ]
        },
        {
            title: "Perpetual Plan",
            price: "198",
            features: [
                "1-year free upgrades and priority support",
                "1-year access on 3 PCs & 3 mobiles & online",
                "1-year access to all features",
                "1GB free cloud storage"
            ]
        },
        {
            title: "Perpetual Bundle Plan",
            price: "245",
            features: [
                "1-year free upgrades and priority support",
                "1-year access on 3 PCs & 3 mobiles & online",
                "1-year access to all features",
                "1GB free cloud storage"
            ]
        },
        {
            title: "Perpetual Bundle Plan",
            price: "237",
            features: [
                "1-year free upgrades and priority support",
                "1-year access on 3 PCs & 3 mobiles & online",
                "1-year access to all features",
                "1GB free cloud storage"
            ]
        }
    ];

    return (
        <section className='subscription-class'>
            <div className='pricing-title text-center'>
                <h3>Pricing</h3>
            </div>
            <Container>
                <Swiper
                  pagination={true} modules={[Pagination]}
                    // modules={[A11y]}
                    slidesPerView={4}
                    spaceBetween={30}
                    className='pricing-slider'
                    breakpoints={{
                        0: { slidesPerView: 1 },
                        380: { slidesPerView: 1 },
                        485: { slidesPerView: 1 },
                        575: { slidesPerView: 1 },
                        768: { slidesPerView: 1 },
                        992: { slidesPerView: 2 },
                        1024: { slidesPerView: 2 },
                        1200: { slidesPerView: 3 },
                        1440: { slidesPerView: 3 },
                        2000: { slidesPerView: 3 }
                    }}
                >
                    {subscriptionData?.map((plan, index) => (
                        <SwiperSlide key={index}>
                            <div className='white-bg-subscribe mt-5'>
                                <h5 className='most-pop'></h5>
                                <div className='sub-white'>
                                    <img src={process.env.PUBLIC_URL + `/assest/images/MyWebsite/Help-Center/access.png`} className="plan-img" alt="..." />
                                    <h3 className='subscription-title'>{plan?.m_w_league?.name}</h3>
                                    {/* {plan?.m_w_plan_details?.map((item, index) => ( */}
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                            <Nav variant="pills" className="flex-row">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">{plan?.m_w_plan_detail?.m_w_plan_type?.name}</Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item>  */}
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <div>
                                                        <p className='cont'>Rs. ₹<span className='doller-price'>{plan?.m_w_plan_detail?.selling_price}</span></p>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <p className='available-txt me-2'>Available:</p>
                                                        {Array.from({ length: 6 }, (_, i) => (
                                                            <img key={i} src={process.env.PUBLIC_URL + `/assest/images/MyWebsite/Help-Center/access.png`} className="available-img" alt="..." />
                                                        ))}
                                                    </div>
                                                    <p className='italic-txt'>Automatically renew, cancel any time.</p>

                                                    <div>
                                                        <Link to='/mywebsites/checkout-form'><button className='buy-now-btn'>Buy Now</button></Link>
                                                        <button className='paypal-btn mt-2'><img src={process.env.PUBLIC_URL + `/assest/images/MyWebsite/Checkoutform/visa.png`} className="paypal-img" alt="..." /></button>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <ul>
                                                            {/* {plan.features.map((feature, i) => (
                                                            <li key={i}>{feature}</li>
                                                        ))} */}
                                                        {htmlToReactParser.parse(plan?.m_w_plan_detail?.description)}
                                                        </ul>
                                                    </div>
                                                    <div className='brown-bg-subscribe'></div>
                                                    <div className='d-flex mt-4'>
                                                        <Form.Group className="mb-3">
                                                            <Form.Check
                                                                required
                                                                name="terms"
                                                                label="Add "
                                                                feedbackType="invalid"
                                                                id={`validationFormik${index}`}
                                                            />
                                                        </Form.Group>
                                                        <p className='ms-2 edraw-txtt'>Edraw Creative Assets</p>
                                                        <div className='trial'>7-day trial</div>
                                                    </div>
                                                    <p className='last-line-subscribe'>Try <span className='days-free-txtt'>7 days for free,</span> then auto-renew at US$15.9 /month(20% off). Cancel any time.</p>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <div>
                                                        <p className='cont'>US$<span className='doller-price'>{plan.price}</span></p>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <p className='available-txt me-2'>Available:</p>
                                                        {Array.from({ length: 6 }, (_, i) => (
                                                            <img key={i} src={process.env.PUBLIC_URL + `/assest/images/MyWebsite/Help-Center/access.png`} className="available-img" alt="..." />
                                                        ))}
                                                    </div>
                                                    <p className='italic-txt'>Automatically renew, cancel any time.</p>
                                                    <div>
                                                        <button className='buy-now-btn'>Buy Now</button>
                                                        <button className='paypal-btn mt-2'><img src={process.env.PUBLIC_URL + `/assest/images/MyWebsite/Checkoutform/visa.png`} className="paypal-img" alt="..." /></button>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <ul>
                                                            {/* {plan.features.map((feature, i) => (
                                                            <li key={i}>{feature}</li>
                                                        ))} */}
                                                        </ul>
                                                    </div>
                                                    <div className='brown-bg-subscribe'></div>
                                                    <div className='d-flex mt-4'>
                                                        <Form.Group className="mb-3">
                                                            <Form.Check
                                                                required
                                                                name="terms"
                                                                label="Add "
                                                                feedbackType="invalid"
                                                                id={`validationFormik${index}`}
                                                            />
                                                        </Form.Group>
                                                        <p className='ms-2 edraw-txtt'>Edraw Creative Assets</p>
                                                        <div className='trial'>7-day trial</div>
                                                    </div>
                                                    <p className='last-line-subscribe'>Try <span className='days-free-txtt'>7 days for free,</span> then auto-renew at US$15.9 /month(20% off). Cancel any time.</p>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    {/* ))} */}
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>
        </section>
    );
};

export default Subscription;
