import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../api";


export const allCategoryData = async (domain_id) => {
  try {
    const res = await getData(`/outer-website/dashboard/category?domain_id=${domain_id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const allCounter = async (domain_id) => {
  try {
    const res = await getData(`/outer-website/dashboard/all-count?domain_id=${domain_id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const allBarChart = async (domain_id) => {
  try {
    const res = await getData(`/outer-website/dashboard/bar?domain_id=${domain_id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};
