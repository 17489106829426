import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./Header.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ButtonComponent from "../CommonComponents/ButtonComponent/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Context} from "../../../utils/context"
import Cookies from "js-cookie"

const Header = () => {
    const [show, setShow] = useState(false);
    const [showUser, setShowUser] = useState(false);

    const [activeLink, setActiveLink] = useState("Home");
    const handleNavItemClick = (selectedLink) => {
        setActiveLink(selectedLink);
        const navbarToggle = document.querySelector(".navbar-toggler");
        if (navbarToggle && window.innerWidth <= 992) {
            navbarToggle.click();
        }
    };

const navigate = useNavigate();
    const handleLogout = () => {
        Cookies.remove('net_purti_security');
        setSignin(false);
        navigate('/mywebsites/home');
      }
    const {signin,setSignin} = useContext(Context);
     
    return (
        <section className="my-header">
            <Container>
                <Navbar expand="lg" className="bg-body-tertiary">
                    <Navbar.Brand as={Link} to="/mywebsites/home">
                        <img
                            src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Logo/company-img.png"}
                            className="logo-img"
                            alt="..."
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse className="justify-content-center" id="navbarScroll">
                        <Navbar.Text>
                            <Nav className="me-auto">
                                <Nav className="me-auto">
                                    <Nav.Link
                                        as={Link}
                                        to="/mywebsites/home"
                                        onClick={() => handleNavItemClick("Home")}
                                        active={activeLink === "Home"}
                                    >
                                        Home
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/mywebsites/pricing"
                                        onClick={() => handleNavItemClick("Pricing")}
                                        active={activeLink === "Pricing"}
                                    >
                                        Pricing
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/mywebsites/portfolio"
                                        onClick={() => handleNavItemClick("Portfolio")}
                                        active={activeLink === "Portfolio"}
                                    >
                                        Portfolio
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/mywebsites/resources"
                                        onClick={() => handleNavItemClick("Resources")}
                                        active={activeLink === "Resources"}
                                    >
                                        Resources
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/mywebsites/blogs-page"
                                        onClick={() => handleNavItemClick("Blogs")}
                                        active={activeLink === "Blogs"}
                                    >
                                        Blogs
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/mywebsites/my-faq"
                                        onClick={() => handleNavItemClick("FAQ's")}
                                        active={activeLink === "FAQ's"}
                                    >
                                        FAQ's
                                    </Nav.Link>
                                </Nav>
                                <div className="wishlist">
                                    <Link to="/mywebsites/user-details/wishlist">
                                        <div className="login-txt mt-1">
                                            <FontAwesomeIcon icon="fa-solid fa-heart" className="me-1" onClick={handleNavItemClick} />
                                        </div>
                                    </Link>
                                </div>
                                {signin ? (
                                    <>
                                    <ButtonComponent className="log-in-btn-mobile" onClick={handleLogout}>
                                    Logout
                                </ButtonComponent>
                                    </>

                                ):(
                                    <ButtonComponent className="log-in-btn-mobile" onClick={() => setShow(!show)}>
                                    Login
                                </ButtonComponent>
                                )}
                               

                                {show && (
                                    <div className="drop-bg">
                                        <div className="box arrow-top">
                                            <Link to="/mywebsites/login" onClick={handleNavItemClick}>
                                                <div className="login-txt">Login</div>
                                            </Link>
                                            <Link to="/mywebsites/signup" onClick={handleNavItemClick}>
                                                <div className="login-txt mt-1">Signup</div>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                                <div className="user-icon-mobile">
                                    <ButtonComponent
                                        className="user-icon-btn me-2"
                                        onClick={() => {
                                            setShowUser(!showUser);
                                        }}
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Logo/user (6).png"}
                                            className="user-icon-img"
                                            alt="..."
                                        />
                                    </ButtonComponent>
                                    {showUser && (
                                        <div className="drop-bg-user">
                                            <div className="box arrow-top">
                                                <Link
                                                    to="/mywebsites/help-center-mywebsite"
                                                    onClick={handleNavItemClick}
                                                >
                                                    <div className="login-txt" onClick={() => setShowUser(!showUser)}>
                                                        <FontAwesomeIcon icon="fa-solid fa-headset" className="me-2" />
                                                        Help Center
                                                    </div>
                                                </Link>
                                                <Link to="/mywebsites/my-package" onClick={handleNavItemClick}>
                                                    <div
                                                        className="login-txt mt-1"
                                                        onClick={() => setShowUser(!showUser)}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="fa-solid fa-book-open"
                                                            className="me-1"
                                                        />{" "}
                                                        My Packages
                                                    </div>
                                                </Link>
                                                {/* <Link
                                                    to="/mywebsites/user-details/wishlist"
                                                    onClick={handleNavItemClick}
                                                >
                                                    <div
                                                        className="login-txt mt-1"
                                                        onClick={() => setShowUser(!showUser)}
                                                    >
                                                        <FontAwesomeIcon icon="fa-solid fa-heart" className="me-1" />{" "}
                                                        Wishlist
                                                    </div>
                                                </Link> */}
                                            </div>
                                        </div>
                                    )}
                                </div>



                            </Nav>
                        </Navbar.Text>

                    </Navbar.Collapse>
                    <div className="wishlist-one">
                        <Link to="/mywebsites/user-details/wishlist">
                            <div className="login-txt mt-1">
                                <FontAwesomeIcon icon="fa-solid fa-heart" className="me-1" />
                            </div>
                        </Link>
                    </div>
                    <div className="user-icon-desktop">

                        <ButtonComponent
                            className="user-icon-btn me-2"
                            onClick={() => {
                                setShowUser(!showUser);
                            }}
                        >
                            <img
                                src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Logo/user (6).png"}
                                className="user-icon-img"
                                alt="..."
                            />
                        </ButtonComponent>
                        {showUser && (
                            <div className="drop-bg-user">
                                <div className="box arrow-top">
                                    <Link to="/mywebsites/help-center-mywebsite">
                                        <div className="login-txt" onClick={() => setShowUser(!showUser)}>
                                            <FontAwesomeIcon icon="fa-solid fa-headset" className="me-2" />
                                            Help Center
                                        </div>
                                    </Link>
                                    <Link to="/mywebsites/my-package">
                                        <div className="login-txt mt-1" onClick={() => setShowUser(!showUser)}>
                                            <FontAwesomeIcon icon="fa-solid fa-book-open" className="me-1" /> My
                                            Packages
                                        </div>
                                    </Link>
                                    {/* <Link to='/mywebsites/user-details/wishlist'><div className='login-txt mt-1' onClick={() => setShowUser(!showUser)}><FontAwesomeIcon icon="fa-solid fa-heart" className='me-1' /> Wishlist</div></Link> */}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="login-desk">
                    {signin ? (
                                    <>
                                    <ButtonComponent className="log-in-btn" onClick={handleLogout}>
                                    Logout
                                </ButtonComponent>
                                    </>

                                ):(
                        <ButtonComponent
                            className="log-in-btn"
                            onClick={() => {
                                setShow(!show);
                            }}
                        >
                            Login
                        </ButtonComponent>

                                )}
                        {show && (
                            <div className="drop-bg">
                                <div className="box arrow-top">
                                    <Link to="/mywebsites/login">
                                        <div className="login-txt" onClick={() => setShow(!show)}>
                                            Login
                                        </div>
                                    </Link>
                                    <Link to="/mywebsites/signup">
                                        <div className="login-txt mt-1" onClick={() => setShow(!show)}>
                                            Signup
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </Navbar>
            </Container>
        </section>
    );
};

export default Header;
