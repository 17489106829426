import React, { useContext, useState, useEffect } from 'react'
import '../MyWebsiteLogin/LoginPage.css'
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PasswordResetSuccessful from '../../MyWebsiteModal/PasswordResetSuccessful/PasswordResetSuccessful';
import { faEye, faEyeSlash, fas } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { RegxExpression } from "../../../../utils/common";
import { resetPassWordPost } from '../../../../utils/apis/myWebsite/mywebsite';

const ResetPassword = () => {


  const [resetmodalShow, setResetModalShow] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const {
    control,
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const onSubmit = async (data) => {
    if (data.password === data.confirm_password) {
      console.log(data, "data");
      const res = await resetPassWordPost(data);
      setResetModalShow(true)
    } else {
      setError("password", {
        message: "Password Must Match",
      });
    }
  }

  return (
    <section className='login-page' >
      <Container>
        <Row className='mx-auto justify-content-center'>
          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
            <div className='login-white'>
              <div className='form-title text-center'>
                <h3>Set New Password</h3>
                <p>Your new password must be different to previously used password.</p>
              </div>
              <div className="log_in_form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mb-3" controlId="">
                    <div className="password-field-holder">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="Password"
                        placeholder="Enter Password"
                        className={classNames("", {
                          "is-invalid": errors?.password,
                        })}
                        {...register("password", {
                          required: "Password is required",
                        })}
                      />
                      <div
                        className="eye-icon-holder"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          className="password-icon"
                          icon={showPassword ? faEye : faEyeSlash}
                        />
                      </div>
                      {/* <div className="eye-icon-holder ">
                                                <FontAwesomeIcon
                                                    className="eye-icon"
                                                    icon="fa-solid fa-eye"
                                                />
                                            </div> */}
                    </div>
                    {errors.password && (
                      <span className="text-danger">
                        {errors.password.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="">
                    <div className="password-field-holder">
                      <Form.Label>Confirm New Password</Form.Label>
                      <Form.Control
                        type={showConfirmPassword ? "text" : "password"}
                        name="Password"
                        placeholder="Enter Confirm Password"
                        className={classNames("", {
                          "is-invalid": errors?.confirm_password,
                        })}
                        {...register("confirm_password", {
                          required: "Password is required",
                        })}
                      />
                      <div
                        className="eye-icon-holder"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <FontAwesomeIcon
                          className="password-icon"
                          icon={showConfirmPassword ? faEye : faEyeSlash}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <div className="btn-holder text-center">
                    <button className="submit_btn" type="submit">
                      Reset Password
                    </button>
                    <PasswordResetSuccessful
                      show={resetmodalShow}
                      onHide={() => setResetModalShow(false)}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}






export default ResetPassword;
