import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

function BlogInfo() {
  const baxtext = [
    {
      title3: "Write your story early on",
    },

    {
      title3: "Button up your brand story",
    },

    {
      title3: "Give your product a persona ",
    },
    {
      title3: "Colors draw in the customer",
    },
    {
      title3: "Who is your audience",
    },
    {
      title3: "Delivery boxes",
    },
    {
      title3: "Logos & graphics",
    },
    {
      title3: "Product blurbs",
    },
    {
      title3: "Don't overdesign it",
    },
  ];

  const baxtext2 = [
    {
      title4: "1. Write your story early on:",
      title5:
        "Your brand story is the heartbeat of your business, and incorporating it into your packaging can create a memorable and meaningful connection with customers. Share the inspiration behind your brand, the journey from conception to creation, and the values that drive you. This narrative creates an emotional bond, turning each purchase into a chapter in your customer's story with your brand.",
    },
    {
      title4: "2. Button up your brand story:",
      title5:
        "Consistency is key. Ensure that your brand story aligns seamlessly with your overall branding strategy. From the website to social media and, most importantly, the packaging, an associative narrative reinforces your brand's identity, making it instantly recognizable and trustworthy",
    },
    {
      title4: "3. Give your product a persona:",
      title5:
        "Infuse personality into your packaging to make your product more than just an item; give it a persona. Is it playful, elegant, or quirky? Tailor your packaging to match the character of your product, creating a cohesive and immersive experience for the customer",
    },
    {
      title4: "4. Colors draw in the customer:",
      title5:
        "Colors possess the ability to bustling emotions and communicate messages effectively. Choose a color scheme that resonates with your brand identity and elicits the desired emotional response from your audience. Whether it's a bold and vibrant palette or a calm and muted one, let your colors speak volumes about your brand.",
    },
    {
      title4: "5. Who is your audience?:",
      title5:
        "Understanding your target audience is fundamental to effective packaging. Consider their preferences, demographics, and lifestyle when designing your packaging. A deep understanding of your audience ensures that your packaging not only attracts but resonates with the right customers.",
    },
    {
      title4: "6. Delivery boxes:",
      title5:
        "The unboxing experience is a golden opportunity to leave a lasting impression. Invest in sturdy and visually appealing delivery boxes that align with your brand image. Customized packaging creates excitement and elevates the perceived value of your products.",
    },
    {
      title4: "7. Logos & graphics:",
      title5:
        "Your logo is the face of your brand, and incorporating it into your packaging is non-negotiable. Ensure that it's prominent, recognizable, and complements the overall design. Graphics, when used thoughtfully, can enhance your brand's visual appeal and communicate its essence effectively.",
    },
    {
      title4: "8. Product blurbs:",
      title5:
        "Craft compelling product blurbs that not only inform but also engage. Use concise and persuasive language to highlight key features, benefits, and the unique selling propositions of your products. These snippets serve as a final pitch before the customer unveils their purchase",
    },
    {
      title4: "9. Don't overdesign it:",
      title5:
        "While creativity is essential, simplicity shouldn't be underestimated. Overly complex or cluttered designs can dilute your brand message. Maintain a clean and uncluttered aesthetic that ensures your packaging is visually appealing and easy to understand.",
    },
  ];

  const { id } = useParams();

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/blog/blog-question/${id}`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="blog-info-section">
        <Container>
          <div className="blogtextsmain">
            <div className="inthe">
              <p>
                {/* In the world of e-commerce, the first physical touchpoint
                between your brand and the customer is often the packaging. It's
                more than just a protective layer; it's a canvas for your brand
                identity. In this blog post, we'll delve into the art of
                building a brand through packaging, exploring key strategies
                that can set your e-commerce venture apart in a crowded digital
                marketplace. */}
                {/* {parse(data.description) || ""} */}
                {data.description}
              </p>
            </div>
            <div className="txtmain">
              <Row>
                <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12}>
                  <div className="optionss">
                    {data?.s_sub_blogs?.map((item, index) => (
                      <div className="boxtxt">
                        <p>{item.name}</p>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col xxl={9} xl={9} lg={9} md={8} sm={12} xs={12}>
                  {data?.s_sub_blogs?.map((item2, index) => (
                    <div className="infotext">
                      <p className="txt-p1">{item2.name}</p>
                      <p className="txt-p2">{parse(item2?.description) || ""}</p>
                    </div>
                  ))}
                </Col>
              </Row>
            </div>

            <div className="blog-info">
              <div className="blog-info-1 infor-1">
                <p>
                  <FontAwesomeIcon
                    icon="fa-solid fa-heart"
                    className="blog-icn"
                  />{" "}
                    <span className="infor-name">4789</span>
                </p>
              </div>
              <div className="blog-info-1 infor-2">
                <p>
                  <FontAwesomeIcon
                    icon="fa-solid fa-message"
                    className="blog-icn"
                  />{" "}
                   <span className="infor-name"> 490 Comments</span>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default BlogInfo;
