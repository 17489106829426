import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import JoditEditor from "jodit-react";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";
import { Context } from "../../../../../../utils/context";
import { addTheme } from "../../../../../../utils/apis/seller/outer-website/masters/theme/index";
import {
  ThemeCategory,
  ThemeSubCategory,
} from "../../../../../../utils/apis/master/Master";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, Select2Data } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("description", data?.description);
      finalData.append("demo_url", data?.demo_url);
      finalData.append("image", data?.image[0]);
      finalData.append("theme_category_id", data?.theme_category_id?.value);
      finalData.append(
        "theme_sub_category_id",
        data?.theme_sub_category_id?.value
      );
      // const response = await postData(
      //   `/seller/outer-website/masters/brands`,
      //   finalData
      // );
      const response = await addTheme(finalData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const [themeCategory, setThemeCategory] = useState([]);
  const [themeSubCategory, setThemeSubCategory] = useState([]);

  const GetAllThemeCategory = async () => {
    const response = await ThemeCategory();

    if (response?.success) {
      setThemeCategory(await Select2Data(response?.data, "theme_category_id"));
    }
  };

  const GetAllThemeSubCategory = async (id) => {
    const response = await ThemeSubCategory(id);

    if (response?.success) {
      setThemeSubCategory(
        await Select2Data(response?.data, "theme_sub_category_id")
      );
    }
  };

  useEffect(() => {
    GetAllThemeCategory();
    GetAllThemeSubCategory();
  }, []);

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Theme
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Theme Category</Form.Label>

                    <Controller
                      name="theme_category_id" // name of the field
                      {...register("theme_category_id", {
                        required: "Select  Category",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.theme_category_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={themeCategory}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllThemeSubCategory(selectedOption.value);
                            setValue("theme_category_id", selectedOption);
                          }}
                        />
                      )}
                    />

                    {errors.theme_category_id && (
                      <span className="text-danger">
                        {errors.theme_category_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Theme Sub Category</Form.Label>

                    <Controller
                      name="theme_sub_category_id" // name of the field
                      // {...register("theme_sub_category_id", {
                      //   required: "Select Sub Category",
                      // })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.theme_sub_category_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={themeSubCategory}
                        />
                      )}
                    />

                    {errors.theme_sub_category_id && (
                      <span className="text-danger">
                        {errors.theme_sub_category_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Theme Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Demo url</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="demo_url"
                          placeholder="Demo Url"
                          className={classNames("", {
                            "is-invalid": errors?.demo_url,
                          })}
                          {...register("demo_url", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.demo_url && (
                        <span className="text-danger">
                          {errors.demo_url.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    {" "}
                    {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Description
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="description" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Description is required" }} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field?.value}
                              onChange={(newContent) =>
                                field.onChange(newContent)
                              }
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.description,
                              })}
                              placeholder="Description"
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <div className="main-form-section mt-3">
                <Row className="justify-content-start">
                  <Form.Label className="text-left">Image</Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.image,
                      })}
                      type="file"
                      {...register("image", {
                        required: "Image is required",
                        // validate: async (value) => {
                        //   if (typeof value !== "string") {
                        //     const fileTypes = ["jpg", "png", "jpeg"];
                        //     const fileType = value[0].name?.split(".")[1];

                        //     if (!fileTypes.includes(fileType)) {
                        //       return `please upload a valid file format. (${fileTypes})`;
                        //     }

                        //     const sizes = await getDimension(value[0]);
                        //     if (
                        //       sizes.width !== 420 &&
                        //       sizes.height !== 520
                        //     ) {
                        //       return "Image width and height must be 420 px and 520 px";
                        //     }

                        //     const fileSize = Math.round(
                        //       value[0].size / 1024
                        //     );
                        //     if (fileSize > 500) {
                        //       return "file size must be lower than 500kb";
                        //     }
                        //   }
                        // },
                      })}
                      accept=".jpg, .jpeg, .png"
                    />
                  </Form.Group>
                  {errors.image && (
                    <span className="text-danger">{errors.image.message}</span>
                  )}
                </Row>
              </div>

              <Col className="main-form-section mt-3">
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={4} className="text-center">
                      Image Preview
                    </Form.Label>
                    {imageFile && imageFile?.length > 0 && (
                      <div className="image-preview-container">
                        <img
                          // src={URL.createObjectURL(getValues("image")[0])}
                          src={URL?.createObjectURL(imageFile[0])}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    )}
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
