import Home from "../Home/Home";
import { Route, Routes } from "react-router-dom";
import Steofirm from "../Home/Stepform/Steofirm";
import Seller_blogs from "../seller_blogs/Seller_blogs";
import Seller_Dashboard from "../Seller_Dashboard/Seller_Dashboard";
import Affiliate_Dashboard from "../Affiliate-Dashboard/Affiliate_Dashboard";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import InnerSellerBlog from "../seller_blogs/InnerSellerBlog/InnerSellerBlog";
import OverviewPage from "../SellerstepFormMain/OverviewPage/OverviewPage";
import SellerViewProduct from "../Seller_Dashboard/SellerViewProduct/SellerViewProduct";
import SellerSuccessStory from "../Seller-Success-Story/SellerSuccessStory";
import Seller3PstepForm from "../Seller3PStepform/3PSellerstepFormMain/Seller3PstepForm";
import FAQ from "../FAQ/FAQ";
import SellerBrandStepform from "../SellerBrandStepform/SellerBrandStepform";
import SellerAffiliateStepform from "../SellerAffiliateStepform/SellerAffiliateStepform";
import SellerLogin from "../SellerLogin/SellerLogin";
import SellerForgetPass from "../SellerLogin/SellerForgetPass/SellerForgetPass";
import Verfiypassword from "../SellerLogin/Verfiypassword/Verfiypassword";
import SetnewPassword from "../SellerLogin/SetnewPassword/SetnewPassword";
import { useContext } from "react";
import { Context } from "../../../utils/context";

const SellerRoutesMain = () => {
  const { pathname } = useLocation();
  const { IMG_URL, usertype, signin } = useContext(Context);

  return (
    <>
      {pathname.startsWith("/seller/seller-dashboard") ? "" : <Header />}
      {/* {pathname.startsWith("/seller/affilate-dashboard") ? "" : <Header />} */}

      <Routes>
        <Route path="/seller_login" element={<SellerLogin />} />
        <Route path="/seller_home" element={<Home />} />
        <Route path="/seller_stepform" element={<Steofirm />} />
        <Route path="/seller-blogs" element={<Seller_blogs />} />
        <Route
          path="/seller-blogs/inner-seller-blog/:id"
          element={<InnerSellerBlog />}
        />
        <Route path="/faq" element={<FAQ />} />

        <Route path="/overview" element={<OverviewPage />} />
        <Route path="/seller-view-product" element={<SellerViewProduct />} />
        <Route path="/seller-success-story" element={<SellerSuccessStory />} />
        <Route path="/seller3PstepForm" element={<Seller3PstepForm />} />
        <Route path="/sellerbrandstepForm" element={<SellerBrandStepform />} />
        <Route
          path="/selleraffiliatestepForm"
          element={<SellerAffiliateStepform />}
        />
        <Route path="/sellerlogin" element={<SellerLogin />} />
        <Route path="/sellerforget" element={<SellerForgetPass />} />
        <Route path="/verfiypassword" element={<Verfiypassword />} />
        <Route path="/setnewpassword" element={<SetnewPassword />} />
        {/* {signin && ( */}
        <Route path="/seller-dashboard/*" element={<Seller_Dashboard />} />
        {/* )} */}

        {/* {signin && ( */}
        {/* <Route path="/affilate-dashboard/*" element={<Affiliate_Dashboard/>} /> */}
        {/* )} */}
      </Routes>

      {/* {pathname.startsWith("/seller/seller-dashboard") ? "" : <Footer />} */}
      {pathname.startsWith("/seller/seller-dashboard") ? "" : <Footer />}
    </>
  );
};

export default SellerRoutesMain;
