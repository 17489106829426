import React from 'react'
import './B2bDashboardContent.css'
import OrderHistoryChart from './Order-History-cahrt/OrderHistoryChart'
const B2bDashboardContent = () => {
  return (
    <>
      <section className='deshboard-start'>
        <div className='row ms-0 me-0'>
          <div className='heading-holder'>
            <h2>Dashboard</h2>
            <p>Welcome to netpurti Buyer panel dashboard</p>
          </div>
        </div>
        <div className='row  ms-0 me-0'>
          <div className='col-xxl-2 col-xl-3 col-lg-4 col-md-6'>
            <div className='total-order mb-3'>
              <div className='circle-holder'>
                <img className="circle-logo" src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/Accounts/box1.png"} alt="circle-logo"
                />
              </div>
              <div className='text-holder'>
                <h3>Total Orders</h3>
                <p>300</p>
              </div>

            </div>
          </div>

          <div className='col-xxl-2 col-xl-3 col-lg-4 col-md-6'>
            <div className='total-order mb-3'>
              <div className='circle-holder1'>
                <img className="circle-logo" src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/Accounts/box2.png"} alt="circle-logo"
                />
              </div>
              <div className='text-holder'>
                <h3>Total Inquires</h3>
                <p>50</p>
              </div>
            </div>
          </div>


          <div className='col-xxl-2 col-xl-3 col-lg-4 col-md-6'>
            <div className='total-order mb-3'>
              <div className='circle-holder2'>
                <img className="circle-logo" src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/Accounts/box.png"} alt="circle-logo"
                />
              </div>
              <div className='text-holder'>
                <h3>Quotation Received</h3>
                <p>200</p>
              </div>

            </div>
          </div>


          <div className='col-xxl-2 col-xl-3 col-lg-4 col-md-6'>
            <div className='total-order mb-3'>
              <div className='circle-holder3'>
                <img className="circle-logo" src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/Accounts/sales.png"} alt="circle-logo"
                />
              </div>
              <div className='text-holder'>
                <h3>Refund Done</h3>
                <p>10</p>
              </div>

            </div>
          </div>


          <div className='col-xxl-2 col-xl-3 col-lg-4 col-md-6'>
            <div className='total-order mb-3'>
              <div className='circle-holder4'>
                <img className="circle-logo" src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/Accounts/shopping-bag.png"} alt="circle-logo"
                />
              </div>
              <div className='text-holder'>
                <h3>Total Returns Products</h3>
                <p>30</p>
              </div>

            </div>
          </div>
        </div>
        <div className='row ms-0 me-0'>
          <div className='heading-holder mt-5'>
            <h2>Order History</h2>
            <p>Welcome to netpurti Buyer panel dashboard</p>
            <OrderHistoryChart />
          </div>
        </div>
      </section>
    </>
  )
}

export default B2bDashboardContent