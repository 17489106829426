import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JoditEditor from "jodit-react";

function Guarenteetab() {
    const [content, setContent] = useState("");
    return (
        <>
            <section className='guarentee-tab'>
                <div className='py-2'>
                    <div className='row'>
                        <div className='col-xxl-4 col-lg-4 col-md-4'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>
                                    Guarantee Provided By <span className='star'>*</span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-title">Warranty Provided By</Tooltip>}
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-circle-info" className='ms-3' />
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Control type="text" placeholder="Enter Company Name" />
                            </Form.Group>
                        </div>
                        <div className='col-xxl-4 col-lg-4 col-md-4'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>
                                    Guarantee Period of Product <span className='star'>*</span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-category">Warranty Period of Product</Tooltip>}
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-circle-info" className='ms-3' />
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Control type="text" placeholder="Enter warranty period" />
                            </Form.Group>
                        </div>
                        <div className='col-xxl-4 col-lg-4 col-md-4'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>
                                    Helpline Number <span className='star'>*</span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-brand">Helpline Number</Tooltip>}
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-circle-info" className='ms-3' />
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Control type="text" placeholder="Enter helpline number" />
                            </Form.Group>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='select-to-div'>
                        <Form.Label>
                            Guarantee Terms
                        </Form.Label>
                        <JoditEditor
                            value={content}
                            tabIndex={1}
                            onBlur={(newContent) =>
                                setContent(newContent)
                            }
                            onChange={(newContent) => { }}
                        />
                    </div>
                </div>


            </section>
        </>
    )
}

export default Guarenteetab