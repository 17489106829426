import React, { useContext } from "react";
import { Context } from "./utils/context.js";
import { useNavigate } from "react-router-dom";

import SellerLogin from "./Components/SellerPanel/SellerLogin/SellerLogin.js";
import SellerConvertPrompt from "./Components/CommonForAll/sellerMiddleware/SellerConvertPrompt/SellerConvertPrompt.js";

const SellerMiddleware = ({ component: Component }) => {
  // Destructure `component` prop
  const { signin, usertype,userdata } = useContext(Context);
  const navigate = useNavigate();
  if (signin) {
    if(userdata?.type === 'seller'){
     
        return <Component />;
     
    }else{
      return <SellerConvertPrompt />;
    }
  } else {
    return <SellerLogin />;
  }
};

export default SellerMiddleware;
