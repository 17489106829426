// InnerPageTwo.js

import React, { useState, Fragment, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../../Terms&Condition/TermsAndConditionModal";
import Select from "react-select";
import SuccessModal from "../../../../CommonForAll/SuccessModal/SuccessModal";

import { Errors, Placeholders } from "../../../../../utils/errors";

import {
  FirmType,
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../../utils/apis/master/Master";

import { Select2Data } from "../../../../../utils/common";
import { Context } from "../../../../../utils/context";
import classNames from "classnames";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";

import {
  firmDetailNamePost,
  getFirmDetailName,
  firmAddressDetailPost,
  getfirmAddressDetail,
  firmDetailsPost,
  getfirmDetails,
} from "../../../../../utils/apis/affiliate/affiliate";
import CommunicationAddress from "./Industry-Details-Tab-Forms/CommunicationAddress/CommunicationAddress";

const InnerPageTwo = ({
  innerNext,
  prevInnerPage,
  setPage,
  handleBack,
  activeTab,
  navItems,
  handleTabClick,
  affiliateDetails,
  affiliateID,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [firmType, setFirmType] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const onFirmNameSubmit = async (d) => {
    d.firm_type_id = d.firm_type_id?.value;
    d.a_personal_details_id = affiliateID;
    if (firmDetailNameId != "") {
      d.id = firmDetailNameId;
    }
    const res = await firmDetailNamePost(d);
    if (res?.success) {
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  };

  const [firmDetailNameId, setFirmDetailNameId] = useState("");
  const getFirmDetailNames = async () => {
    const res = await getFirmDetailName(affiliateID);
    if (res?.success) {
      setFirmDetailNameId(res.data.id);
      reset(res.data);
    }
  };

  const getMasters = async () => {
    {
      const res = await FirmType();
      if (res?.success) {
        const data = await Select2Data(res.data, "firm_type_id", false);
        setFirmType(data);
      }
    }
   
  };

  useEffect(() => {
    getFirmDetailNames();
    getMasters();
  }, []);

  return (
    <div className="form-container">
      <h6 className="ms-3 firmmmdd">Firm Details</h6>
      <div className="tab-form-container firstthreerrr">
        <Form onSubmit={handleSubmit(onFirmNameSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Enter the firm name</Form.Label>
                {/* <Form.Control type="text" placeholder="Enter firm name" /> */}
                <Form.Control
                  className={classNames("", {
                    "is-invalid": errors?.name,
                  })}
                  type="text"
                  {...register("name", {
                    required: Errors.firmName,
                  })}
                  placeholder={Placeholders.firmName}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Select your firm type</Form.Label>
                <Controller
                  name="s_firm_type_id"
                  {...register("s_firm_type_id", {
                    required: Errors.firmType,
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.s_firm_type_id
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      options={firmType}
                    />
                  )}
                />
              </Form.Group>
            </div>
          </div>
          <div className="text-end">
            <Button
              className="btn StepfoursaveBtn"
              onClick={handleSubmit(onFirmNameSubmit)}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>

      <h6 className="ms-3 firmmmdd">Firm Address Details</h6>
      <div className="tab-form-container">
        <CommunicationAddress
          affiliateID={affiliateID}
          setSuccessModalShow={setSuccessModalShow}
          // setHomeAddressId={setHomeAddressId}
          // setSuccessModalShow={setSuccessModalShow}
        />
      </div>

      <div className="col-md-12">
        <div className="text-end apply_now_btn">
          {/* <p onClick={() => setModalShow(true)}>
            By continuing, I agree to Netpurti's
            <span className="termstxt">Terms of Use & Privacy Policy</span>
          </p> */}

          <Button className="back-btn" onClick={prevInnerPage}>
            Back
          </Button>

          <Button className="tabs-btn" onClick={() => setPage(3)}>
            Register and Continue
          </Button>
        </div>

        <TermsAndConditionModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

        <SuccessModal show={successModalShow} />
      </div>
    </div>
  );
};

export default InnerPageTwo;
