import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import parse from "html-react-parser";
import { Container } from "react-bootstrap";
import "./SellerBanner.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const SellerBanner = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/seller/success-stories/s-stories-banner`
      );
      if (response?.success) {
        setData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <section>
      <Container>
        <div className="banner-image-main">
          {/* <img
            src={IMG_URL + data?.image}
            className="seller-banner-img"
            alt="..."
          />
          <div className="banner-textt">
            <h5>{data?.name}</h5>
          </div> */}
          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {data.map((image, index) => (
              <SwiperSlide>
                <div className="banner-image-main">
                  <img key={image.id} src={IMG_URL + image?.image} className="seller-banner-img" />
                  <div className="banner-textt">
                    <h5>{image?.name}</h5>
                  </div>

                </div>


              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Container>
    </section>
  );
};

export default SellerBanner;
