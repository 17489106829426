import React from "react";
import "./advertisement_add_task_modal.css";
import { useState, useContext, useEffect } from "react";
import "../selldash-commonmodal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import { Context } from "../../../../../../utils/context";
import useRazorpay from "react-razorpay";
import SuccessModal from "../../../../../CommonForAll/SuccessModal/SuccessModal";
import {
  getAllInfluencerApi,
  getInfluencerSocialLinksApi,
} from "../../../../../../utils/apis/affiliate/affiliate";
import { affiliateProductGet } from "../../../../../../utils/apis/affiliate/InHouseTask/Task/task";
import {
  AllState,
  City,
  Country,
  Pincode,
  State,
  getLanguageApi,
} from "../../../../../../utils/apis/master/Master";
import ErrorModal from "../../../../../CommonForAll/ErrorModal/ErrorModal";
import { getSellerWalletApi } from "../../../../../../utils/apis/seller/wallet/wallet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import { getAdvertisementAllProducts, postStrick } from "../../../../../../utils/apis/seller/advertisement";

const Strick_modal = (props) => {
  const { getData, postData, IMG_URL, Select2Data, Id, Select3Data, userdata, OUTER_URL, sellerDomain } =
    useContext(Context);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincodes, setPincode] = useState([]);
  const [product, setProducts] = useState([]);
  const [influencer, setInfluencer] = useState([]);
  const [allInfluencerData, setAllInfluencerData] = useState([]);
  const [platForm, setPlatForm] = useState([]);
  const [language, setLanguage] = useState([]);
  const [strickStatus, setStrickStatus] = useState([
    { value: "reject strick", label: "Reject Strick", name: "strick_status" },
    { value: "refund payment", label: "Refund Payment", name: "strick_status" },
    { value: "pending", label: "Pending", name: "strick_status" },
  ]);
  const [allLanguage, setAllLanguage] = useState([]);
  const [startDate, setStartDate] = useState();
  const [errorModal, setErrorModal] = useState(false);
  const [errorAmount, setErrorAmount] = useState("");
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [visitRequest, setVisitRequest] = useState(false);
  const [productData, setProductsData] = useState("");
  const [taskData, setTaskData] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();


  console.log(errors, 'await ');

  const onSubmit = async (d) => {


    if (props?.task_id) {


      d.seller_task_id = props?.task_id?.id;
      d.affiliate_id = props?.task_id?.a_personal_detail?.id;
      d.seller_remark = d?.seller_remark;


      const res = await postStrick(d);
      if (res?.success) {

        setSuccessModalShow(true);
        props.getProducts();
        setTimeout(() => {
          reset();
          setTaskData("");
          setSuccessModalShow(false);
          props.onHide();
        }, 3000);
      }
    } else {
      setError('seller_task_id', { message: 'Please Select Task First' });
    }
  };
  const SelectInfluenceData = async (data, name, other = false) => {
    const result = data.map((data) => ({
      value: data?.id,
      label: `${data?.f_name} ${data?.l_name}`,
      name: name,
      data: data?.influencer_open,
    }));

    if (other) {
      result.push({ value: "0", label: "Other", name: name });
    }
    return result;
  };
  const [newTypes, setType] = useState("influncer");
  const getInfluencerData = async (task_id = "") => {

    const res = await getAdvertisementAllProducts(1, newTypes, "", "", "", sellerDomain?.value, task_id);
    if (res?.success) {
      const data = await SelectInfluenceData(res.data.data, "productId", false);
      setInfluencer(data);
      setAllInfluencerData(res?.data?.data);
    }
  }

 



  const [sellerWallet, setSellerWallet] = useState();
  const getWalletHistory = async () => {
    const res = await getSellerWalletApi();
    if (res?.success) {
      setSellerWallet(res?.data);
    }
  };

 

  useEffect(() => {
    getInfluencerData();
    // getPlatForm();
    getWalletHistory();
  }, []);

  return (
    <>
      <section className="">
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="selldash-commonmodal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Strick
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>

            {/* <div className="input-fluid-section">
              <div className="row">
                <div className="col-md-4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Task Id</Form.Label>
                    <Form.Control type="text" placeholder="Enter Task Id"
                      className={classNames("", {
                        "is-invalid": errors?.task_id,
                      })}
                      {...register("task_id", {
                        // required: "Date is required",
                      })}
                      onChange={(e) => { getInfluencerData(e.target.value); setValue('task_id', e.target.value); }}

                    />
                  </Form.Group>
                </div>

                <div className="col-md-2">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <button className="btn btn-danger" onClick={() => { setValue('task_id', ""); }} > Reset</button>
                  </Form.Group>
                </div>
              </div>
            </div>
            <hr />
            <div className="card-swiper-section">
              <Swiper pagination={true} spaceBetween={10} slidesPerView={3} modules={[Pagination]} className="card-swiper">
                {allInfluencerData?.map((value, index) => (
                  <SwiperSlide>
                    <div className="card-holder">
                      <div className="img-text-section">
                        <div className="row">

                          <div className="col-md-4">
                            <div className="img-holder">
                              <img
                                src={value?.a_personal_detail?.image !== null ? IMG_URL + value?.a_personal_detail?.image : process.env.PUBLIC_URL + "/assest/images/seller-image/dummyUser.png"}
                                className="product-img"
                                alt="..."
                              />
                            </div>
                          </div>

                          <div className="col-md-8">
                            <div className="text-holder">
                              <h3 className="name-holder">
                                {value?.a_personal_detail?.f_name} {value?.a_personal_detail?.l_name}
                              </h3>

                              <p className="name-info-holder">
                                <sub>Task Id </sub> #{value?.task_id}
                              </p>

                              <p className="name-info-holder">
                                <sub>Insentive </sub>₹ {value?.insentive}
                              </p>

                              <p className="info-holder">
                                <sub>Visit Cost </sub>₹ {value?.visit_cost}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="tabs-holder">

                        {
                          <>

                            <div className="Social-tab-holder">
                              <p><img src={IMG_URL + value?.influencer_task?.a_social_detail?.social_platform?.image} width={20} /> {formatNumber(value?.influencer_task?.a_social_detail?.subscriber)}</p>
                            </div>
                          </>
                        }

                      </div>

                      <div className="btn-holder">
                        <Button className="view_Profile_btn  ms-2" onClick={() => {
                          handleInfluencerChange(value);
                        }}>Select Task</Button>
                      </div>


                    </div>
                  </SwiperSlide>
                ))}




              </Swiper>

            </div>

            <hr /> */}






            <div className="row">
              <div className="col-xxl-9 col-xl-9 col-lg-12">
                <div>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-6">

                        <Form.Group className="mb-3">
                          <Form.Label>Seller Remark</Form.Label>
                          <Form.Control
                            type="text"
                            as={"textarea"}
                            {...register("seller_remark", {
                              required: 'Seller Remark is required',
                            })}
                            placeholder="Enter Seller Remark"
                          />
                          {errors?.seller_remark && (
                            <span className="text text-danger">
                              {errors.seller_remark.message}
                            </span>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Task Id : <b>{props.task_id?.task_id}</b>
                                  <span className="text-danger"> {errors?.seller_task_id?.message}</span>
                                </Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-end">
                      <button className="btn closebtn">Close</button>
                      <button className="btn sbmitbtn">Submit</button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Modal.Body>

          {/* <Modal.Footer>
                        <Button onClick={props.onHide}>Close</Button>
                    </Modal.Footer> */}
        </Modal>
        <SuccessModal show={successModalShow} />
        <ErrorModal
          show={errorModal}
          onHide={() => setErrorModal(false)}
          text={"Insufficient Balance in Wallet! Must Have ₹ " + errorAmount}
        />
      </section>
    </>
  );
};

export default Strick_modal;
