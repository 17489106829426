import React, { useContext, useState } from "react";
import "./PortfolioCards.css";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { Context } from "../../../../utils/context";

const PortfolioCards = ({images,cardtitle,css}) => {

  const [heartactive, setheartActive] = useState(false);
  const handleClickes = ({ data }) => {
    setheartActive(!heartactive);
  };

  const{IMG_URL} = useContext(Context)

  return (
    <section className="Portfolio-Cards">
      <div className="card-portfolio">
        <div className="overlay"></div>
        <div className="content">
          <div className="like-btn">
            <div className="circle">
              <div className="hearticn">
                <FontAwesomeIcon
                  icon={faHeart}
                  onClick={handleClickes}
                  className={heartactive ? "gobiheartss" : "gobiheart"}
                />
              </div>
            </div>
          </div>
          <div className="portfolio-image">
            <img
              src={IMG_URL + images}
              // style={css}
              className="portfolio"
              alt="Portfolio"
            />
          </div>
          <p className="sub-title-medium">{cardtitle}</p>

          <div className="Button-section">
          
            <div className="Buy-Now">
              <ButtonComponent className="live sub-title">
                Live-Preview
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioCards;
