import React from "react";
import { Route, Routes } from "react-router-dom";
import Maindashboard from "../AffiliateSidebarComponents/main-dashboard/Maindashboard"
import Orders from "../AffiliateSidebarComponents/Orders/Orders";
// import Maindashboard from "../../Seller_Dashboard/SidebarComponents/main-dashboard/Maindashboard";
import Task from "../AffiliateSidebarComponents/Task/Task";
import InfluencerTask from "../AffiliateSidebarComponents/InfluencerTask/Task";
import InfluencerActiveTask from "../AffiliateSidebarComponents/InfluencerActiveTask/Task";
import Setting_page from "../AffiliateSidebarComponents/settings/Setting_page";
import Wallet from "../AffiliateSidebarComponents/Wallet/Wallet"
import Event from "../SidebarComponents/Event/Event";
import EventInfluencers from "../AffiliateSidebarComponents/EventInfluencer/EventInfluencers";

import Guidelines from "../AffiliateSidebarComponents/Guide/Guidelines";
import Guidevideo from "../AffiliateSidebarComponents/Guide/Guidevideo";
import DoAndDont from "../AffiliateSidebarComponents/Guide/DoAndDont";

const AffiliateDashboardRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Maindashboard />} />
        <Route path="/tracking" element={<Orders />} />
        <Route path="/task" element={<Task />} />
        <Route path="/influencertask" element={<InfluencerTask />} />
        <Route path="/influenceractivetask" element={<InfluencerActiveTask />} />
        <Route path="/settings" element={<Setting_page />} />
        <Route path="/affiliate-wallet" element={<Wallet />} />
        <Route path="/event" element={<Event />} />
        <Route path="/influencer-event" element={<EventInfluencers />} />
        <Route path="/guidelines" element={<Guidelines />} />
        <Route path="/guide-video" element={<Guidevideo />} />
        <Route path="/do-dont" element={<DoAndDont />} />
      </Routes>
    </>
  );
};

export default AffiliateDashboardRoutes;
