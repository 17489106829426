import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import {  OverlayTrigger, Tooltip } from 'react-bootstrap'; // Import OverlayTrigger and Tooltip
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JoditEditor from "jodit-react";

import '../CommonDetails.css'

function Expiry() {
    const [content, setContent] = useState("");
  return (
    <>
    <section className='expiry-tab'>
    <div className='py-2'>
          <div className='row'>
            <div className='col-xxl-4 col-lg-4 col-md-4'>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>
                Expiry Date <span className='star'>*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-title">Warranty Provided By</Tooltip>}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-circle-info" className='ms-3' />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter Expiry Date" />
              </Form.Group>
            </div>
          </div>
        </div>
        
        <div className='row'>
          <div className='select-to-div'>
            <Form.Label>
            Warranty Terms
            </Form.Label>
            <JoditEditor

              value={content}
              tabIndex={1}
              onBlur={(newContent) =>
                setContent(newContent)
              }
              onChange={(newContent) => { }}
            />
          </div>
        </div>
    </section>

    
    </>
  )
}

export default Expiry