import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";
  
  export const getSuperAffiliateSetting = async (contact_no) => {
    try {
      const res = await getData(`/without-login/super-af-setting`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  
  