import React, { useState, useContext, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import classNames from "classnames";

import Select from "react-select";
import { Context } from "../../../../../../utils/context";
import {
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../../../utils/apis/master/Master";
import { Errors, Placeholders } from "../../../../../../utils/errors";
import {
  firmDropAddressPost,
  getfirmDropAddress,
} from "../../../../../../utils/apis/btb/B2b";

const DropAddressDetails = ({ userID, setDropCount, setSuccessModalShow }) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      data: [
        {
          name: "",
          add_line_one: "",
          add_line_two: "",
          city_id: "",
          state_id: "",
          pincode_id: "",
          country_id: "",
          s_firm_address_type: "",
        },
      ],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "data",
  });

  console.log("fields", fields);


  const onSubmit = async (d) => {
    console.log(d, "form");
    d.btb_personal_details_id = userID;
    const res = await firmDropAddressPost(d);
    if (res?.success) {
      console.log(res, "addresspost");
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  };

  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [pincodes, setPincodes] = useState([]);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const handlePincodeChange = async (e) => {
    const res = await Pincode(e);
    if (res?.success) {
      const data = await Select2Data(res.data, "pincode_id", false);
      setPincodes(data);
    }
  };
  const handlePincodeSelect = async (id, index) => {
    const res = await pincodeWiseAll(id);
    if (res?.success) {
      const updatedCities = [...cities];
      updatedCities[index] = res.data.city?.label;
      setCities(updatedCities);

      const updatedStates = [...states];
      updatedStates[index] = res.data.state?.label;
      setStates(updatedStates);

      const updatedCountries = [...countries];
      updatedCountries[index] = res.data.country?.label;
      setCountries(updatedCountries);

      setValue(`data[${index}].city_id`, res.data.city);
      setValue(`data[${index}].state_id`, res.data.state);
      setValue(`data[${index}].country_id`, res.data.country);
    }
  };

  const getfirmDropAddressIn = async () => {
    const res = await getfirmDropAddress(userID);
    if (res?.success) {
      setDropCount(res.data.length);
      if (res.data.length > 0) {
        console.log(res);
        var updatedCities = [...cities];
        var updatedStates = [...states];
        var updatedCountries = [...countries];
        res.data.forEach((item, index) => {
          updatedCities[index] = item.city_id?.label;
          updatedStates[index] = item.state_id?.label;
          updatedCountries[index] = item.country_id?.label;
        });
        setCities(updatedCities);
        setStates(updatedStates);
        setCountries(updatedCountries);

        reset({ data: res.data });
      } else {
        reset({
          data: [
            {
              name: "",
              add_line_one: "",
              add_line_two: "",
              city_id: "",
              state_id: "",
              pincode_id: "",
              country_id: "",
              s_firm_address_type: "",
            },
          ],
        });
      }
    }
  };

  useEffect(() => {
    getfirmDropAddressIn();
  }, []);

  return (
    <div className="dropAddressDetails">
      <div className="stepFourconstContent mb-4">
        <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
          <div className="row pickupHeadRow">
            <div className="col-lg-6 p-0">
              <div className="stepfourHead">
                <h3>Drop address</h3>
              </div>
            </div>
            <div className="col-lg-6 p-0 ">
              <div className="text-end">
                <Button
                  className="btn StepfoursaveBtn"
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div>
            {fields.map((item, index) => (
              <div key={item.id} className="row">
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Enter name of address</Form.Label>
                    <Form.Control
                      type="text"
                      className={classNames("", {
                        "is-invalid":
                          errors.data &&
                          errors.data[index] &&
                          errors.data[index].name,
                      })}
                      name={`data[${index}].name`}
                      placeholder="Enter Name of address"
                      defaultValue={item.name}
                      {...register(`data[${index}].name`, {
                        required: "Name of address is required",
                      })}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-6"></div>

                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Enter Address Line 1</Form.Label>
                    <Form.Control
                      type="text"
                      className={classNames("", {
                        "is-invalid":
                          errors.data &&
                          errors.data[index] &&
                          errors.data[index].add_line_one,
                      })}
                      name={`data[${index}].add_line_one`}
                      placeholder="Enter Address Line 1"
                      defaultValue={item.add_line_one}
                      {...register(`data[${index}].add_line_one`, {
                        required: "Address line 1 is required",
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Address line 2</Form.Label>
                    <Form.Control
                      type="text"
                      className={classNames("", {
                        "is-invalid":
                          errors.data &&
                          errors.data[index] &&
                          errors.data[index].add_line_two,
                      })}
                      name={`data[${index}].add_line_two`}
                      placeholder="Enter Address Line 2"
                      defaultValue={item.add_line_two}
                      {...register(`data[${index}].add_line_two`, {
                        required: "Address line 2 is required",
                      })}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>City/District:</Form.Label>

                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.city,
                      })}
                      type="text"
                      name="city_id"
                      readOnly
                      value={cities[index]}
                      placeholder={Placeholders.city}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.state,
                      })}
                      type="text"
                      name="state_id"
                      value={states[index]}
                      placeholder={Placeholders.state}
                      readOnly
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Pincode</Form.Label>

                    <Controller
                      name={`data[${index}].pincode_id`}
                      {...register(`data[${index}].pincode_id`, {
                        required: Errors.pincode,
                        pattern: {
                          value: /^\d{5}$/, // Accepts only 5 digits
                          message: "Pincode must be exactly 5 digits without spaces or special characters",
                        },
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.pincode_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={pincodes}
                          onInputChange={(selectedOption) => {
                            handlePincodeChange(selectedOption);
                          }}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            handlePincodeSelect(selectedOption.value, index);
                          }}
                          onKeyDown={(e) => {
                            // Allow only digits (0-9) and backspace/delete

                            if (

                              !/[0-9]/.test(e.key) &&
                              e.key !== "Backspace" &&
                              e.key !== "Delete" &&
                              e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight" &&
                              e.key !== "Tab"
                            ) {
                              e.preventDefault(); // Block invalid input
                            }
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.country,
                      })}
                      type="text"
                      name="country_id"
                      readOnly
                      value={countries[index]}
                      placeholder={Placeholders.country}
                    />
                  </Form.Group>
                </div>

                {index !== 0 && (
                  <div className="addremoveadress">
                    <div className="text-end Addnewadresstxt">
                      <p className="" onClick={() => remove(index)}>
                        Remove Address{" "}
                        <FontAwesomeIcon icon="fa-solid fa-minus" />
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}

            <div className="addremoveadress">
              <div className="text-end Addnewadresstxt">
                <p
                  onClick={() =>
                    append({
                      name: "",
                      add_line_one: "",
                      add_line_two: "",
                      city_id: "",
                      state_id: "",
                      pincode_id: "",
                      country_id: "",
                    })
                  }
                >
                  Add New Address <FontAwesomeIcon icon="fa-solid fa-plus" />
                </p>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DropAddressDetails;
