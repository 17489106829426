import React from 'react'
import './HomeinquiryModal.css'
import InquiryModal from '../inquiry-Modal/InquiryModal';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const HomeinquiryModal = (props) => {
    const [modalInquiry, setModalInquiry] = React.useState(false);
    const openModal = () => {
        setModalInquiry(true);

        setTimeout(() => {
            // window.location = ""
            setModalInquiry(false);
        }, 3000);
    };
  
    return (

        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered scrollable
                className='edit-details-modal '
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Inquiry
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group className="mb-3" as={Col} md={6} controlId="formGridEmail">
                                <div className='d-flex'>
                                    <Form.Label>Inquiry Name </Form.Label>
                                </div>
                                <Form.Control type="text" placeholder="Enter name" />
                            </Form.Group>

                            <Form.Group className="mb-3" as={Col} md={6} controlId="formGridName">
                                <Form.Label>Generic Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter generic name" />
                            </Form.Group>

                            <Form.Group className="mb-3" as={Col} md={6} controlId="formGridName">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" placeholder="Enter Description" />
                            </Form.Group>

                            <Form.Group className="mb-3" as={Col} md={6} controlId="formGridContact">
                                <Form.Label>Qty</Form.Label>
                                <Form.Control type="text" placeholder="Enter Qty" />
                            </Form.Group>

                            <Form.Group className="mb-3" as={Col} md={6} controlId="formGridDesignation">
                                <Form.Label>Expected cost</Form.Label>
                                <Form.Control type="text" placeholder="Enter Expected cost" />
                            </Form.Group>

                            <Form.Group className="mb-3" as={Col} md={6} controlId="formGridDesignation">
                                <Form.Label>Delivery Cost</Form.Label>
                                <Form.Control type="text" placeholder="Enter Delivery Cost" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Customization details</Form.Label>
                                <Form.Control as="textarea" className='text-area' rows={3} placeholder='Enter Customization details' />
                            </Form.Group>

                            <div>
                                <div className='detail-txt mt-2'> <FontAwesomeIcon icon="fa-solid fa-paperclip" className='me-1' />Add Attachment</div>
                                <div className='add-attachment'>( Attachment for customization of your brand logo, label...etc)</div>
                            </div>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent onClick={props.onHide} className={'btn-cancle'}>Cancel</ButtonComponent>
                    <ButtonComponent onClick={() => { openModal(true); props.onHide(); }} className={'btn-update'} > Submit</ButtonComponent>
                </Modal.Footer>
            </Modal>
            <InquiryModal
                message="Your Inquiry Send Successfully"
                show={modalInquiry}
                onHide={() => setModalInquiry(false)}
            />
        </>
    )
}

export default HomeinquiryModal