import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllExclusiveCollection = async (
  currentPage,
  perPage,
  search,
  searchCategary,
  searchSubCategary,
  searchStatus,
  domain_id
) => {
  try {
    console.log(searchCategary.label, "kkkk");
    let url = `/seller/outer-website/masters/exclusive-collections?page=${currentPage}&per_page=${perPage}&term=${search}` +
      `&category=${searchCategary?.label || ""}&subcategory=${searchSubCategary?.label || ""}&domain_id=${domain_id}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`
    }
    return await getData(
      url
    );
  } catch (error) {
    console.log(error);
  }
};

export const changeExclusiveCollectionStatus = async (id) => {
  try {
    return await editStatusData(
      `/seller/outer-website/masters/exclusive-collections/${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const deleteExclusiveCollection = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/outer-website/masters/exclusive-collections/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const addExclusiveCollection = async (finalData) => {
  try {
    return await postData(
      `/seller/outer-website/masters/exclusive-collections`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const editExclusiveCollection = async (id, finalData) => {
  try {
    return await postData(
      `/seller/outer-website/masters/exclusive-collections/${id}`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingleExclusiveCollection = async (id) => {
  try {
    return await getData(
      `/seller/outer-website/masters/exclusive-collections/${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
