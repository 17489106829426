import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './ViewRequestedQuote.css'
import ViewRequestedModal from '../../common-components/ViewRequestedModal/ViewRequestedModal';

const ViewRequestedQuote = () => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <section className='view-requested'>
            <div className='first-white-bg'>
                <Row>
                    <Col xxl={2} lg={2} md={2} sm={2} xs={2}>
                        <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/RFQ/Armchairp.png"} className="pro-img" alt="..." />
                    </Col>
                    <Col xxl={10} lg={10} md={10} sm={10} xs={10}>
                        <p className='txt-first'>Hot Sale Living Room Single Sofa Chair Armchair High Back Lamb Cashmere Microfiber Fabric Manual Recliner Massage Chair</p>
                    </Col>
                </Row>
            </div>
            <div className='d-flex justify-content-between mt-4'>
                <p className='status'> Status : Approved</p>
                <button className='close-quote' onClick={() => setModalShow(true)}>Close Quote</button>
            </div>
            <div className='brown-border mt-3'></div>
            <Row>
                <Col xxl={12}>
                    <p className='title-producttt mt-4'>Product Basic Information</p>
                </Col>
                <Col md={4} sm={4}>
                    <p className='cat-name'>Product Name</p>
                </Col>
                <Col md={8} sm={8}>
                    <p className='txtt-data'>Hot Sale Living Room Single Sofa Chair Armchair High Back Lamb Cashmere Microfiber Fabric Manual Recliner Massage Chair</p>
                </Col>
            </Row>
            <Row>
                <Col md={4} sm={4}>
                    <p className='cat-name'>Category</p>
                </Col>
                <Col md={8} sm={8}>
                    <p className='txtt-data'>Recliners</p>
                </Col>
            </Row>
            <Row>
                <Col md={4} sm={4}>
                    <p className='cat-name'>Quantity</p>
                </Col>
                <Col md={8} sm={8}>
                    <p className='txtt-data'>2 Piece/Pieces</p>
                </Col>
            </Row>
            <Row>
                <Col md={4} sm={4}>
                    <p className='cat-name'>Details</p>
                </Col>
                <Col  md={8} sm={8}>
                    <div className='txtt-data'>Color - Mustard Yellow</div>
                    <div className='txtt-data'>Keyword: Recliner Leisure Chair</div>
                    <div className='txtt-data'>Type: One Seat</div>
                    <div className='txtt-data'>Function: Manual Recliner</div>
                    <div className='txtt-data'>Color: Customized Colors</div>
                    <div className='txtt-data'>Usage: Livingroom Furniture</div>
                    <div className='txtt-data'>Size: 103*86*75CM</div>
                    <div className='txtt-data'>LOGO: Customized Logo</div>
                    <div className='txtt-data'>Design Style: Mordern</div>
                    <div className='txtt-data'>Design Style: Modern</div>
                    <div className='txtt-data'>Mail packing: Y</div>
                    <div className='txtt-data'>Upholstery Material: Microfiber Fabric</div>
                    <div className='txtt-data'>Type: One Seat</div>
                    <div className='txtt-data'>Frame Material: Metal</div>
                    <div className='txtt-data'>Feature: Massage</div>
                    <div className='txtt-data'>Feature: reclining</div>
                    <div className='txtt-data'>Feature: reclining</div>
                    <div className='txtt-data'>Feature: Adjustable</div>
                </Col>
            </Row>

            <ViewRequestedModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </section>
    )
}

export default ViewRequestedQuote