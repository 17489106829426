import React, { useEffect, useState, useContext } from "react";
import "./EditPersonalDetailsModal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ButtonComponent from "../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChangeModal from "./ChangeModal";
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import InquiryModal from "../../../common-components/AllModal/inquiry-Modal/InquiryModal";
import {
  sendMobileOTP,
  resendMobileOTP,
  sendEmailOTP,
  verifyMobileOTP,
  verifyEmailOTP,
} from "../../../../../utils/apis/common/Common";
import { Rating } from "react-simple-star-rating";
import classNames from "classnames";
import { text } from "@fortawesome/fontawesome-svg-core";
const RatingreviewsMoal = (props) => {
  const [modalChange, setModalChange] = useState(false);
  const [modalInquiry, setModalInquiry] = useState(false);
  const { postData, Id } = useContext(Context);

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const submitModal = () => {
    setModalInquiry(true);
    props.onHide();
    setTimeout(() => {
      reset();
      props.getDataAll();
      setModalInquiry(false);
    }, 3000);
  };

  const onSubmit = async (data) => {
    data.s_product_id = props.product_id;
    console.log("dataHere", data);
    const res = await postData("/btb/product-reviews", data);
    if (res?.success) {
      submitModal(true);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-details-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Rate Our Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <div className="d-flex align-items-center">
                <div className="star">
                  <Rating
                    initialValue={0}
                    SVGclassName="star-svg"
                    fillColor="#000000" // Corrected color to valid hex format
                    onClick={(value) => setValue("rating", value)} // Manually set the rating value in the form
                    className={classNames("", {
                      "is-invalid": errors?.rating,
                      "is-valid": !errors?.rating && getValues("rating"),
                    })}
                  />
                </div>
                <div className="star m-2">
                  <p className="rating-number">{watch("rating") || 0}</p>{" "}
                  {/* Static rating, consider making it dynamic */}
                </div>
              </div>

              <Form.Group as={Col} md={12} controlId="formGridName">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea" // Use textarea for multiline input
                  name="review"
                  placeholder="Your Reviews...."
                  className={classNames("", {
                    "is-invalid": errors?.review,
                    "is-valid": !errors?.review && getValues("review"),
                  })}
                  {...register("review", {
                    required: "Message is required", // Validation rule for required field
                  })}
                />
              </Form.Group>
            </Row>
            <Modal.Footer>
              <ButtonComponent onClick={props.onHide} className={"btn-cancle"}>
                Cancel
              </ButtonComponent>
              <ButtonComponent
                onClick={() => handleSubmit(onSubmit)}
                className={"btn-update"}
              >
                {" "}
                Submit
              </ButtonComponent>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <ChangeModal show={modalChange} onHide={() => setModalChange(false)} />
      <InquiryModal
        message="Submitted successfully"
        show={modalInquiry}
        onHide={() => setModalInquiry(false)}
      />
    </>
  );
};

export default RatingreviewsMoal;
