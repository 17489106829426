import React from "react";
import Pagination from "react-bootstrap/Pagination";
const CustomPaginate = ({ currentPage, totalPages, handlePageChange }) => {
  return (
    <Pagination>
      {currentPage !== 1 && (
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
      )}
      {[...Array(totalPages).keys()].map((page) => {
        // Show the first 3 pages
        if (currentPage <= 2 && page < 3) {
          return (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          );
        }

        // Show the last 3 pages
        if (currentPage >= totalPages - 1 && page >= totalPages - 3) {
          return (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          );
        }

        // Show ellipsis for the remaining pages
        if (
          page >= currentPage - 2 &&
          page <= currentPage + 2 &&
          page > 2 &&
          page < totalPages - 3
        ) {
          return (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          );
        }

        // Show ellipsis
        if (
          (page === 2 && currentPage > 4) ||
          (page === totalPages - 4 && currentPage < totalPages - 3)
        ) {
          return <Pagination.Ellipsis key={page + 1} />;
        }

        return null;
      })}
      {currentPage !== totalPages && (
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
      )}
    </Pagination>
  );
};

export default CustomPaginate;