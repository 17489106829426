
import React, { useState } from "react";
import "./Tracker.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
function Tracker(props) {
    var stepPercentage = 0;

    if (props.currentStep === 1) {
        stepPercentage = 0;
    } else if (props.currentStep === 2) {
        stepPercentage = 50;
    } else if (props.currentStep === 3) {
        stepPercentage = 100;
    } else {
        stepPercentage = 0;
    }

    return (
        <>
            <section className="tracker-sec">
                <div className="container">
                   
                    <div className="main-relative-div">
                    <div className="stepper-div">
                        <ProgressBar percent={stepPercentage}>
                            <Step>
                                {({ accomplished, index }) => (
                                    <div
                                        className={`indexedStep ${accomplished ? "accomplished" : null
                                            }`}
                                    >
                                        <div className="range-text-div">
                                            <h6 className="placed">Today</h6>
                                            <p className="date">20 Dec</p>
                                            <h6 className="parcel-one">Parcel Received from shop</h6>
                                            <p className="loc">Datta Mandir Chowk, Pune, Maharashtra 411014, India</p>
                                         
                                        </div>
                                    </div>
                                )}
                            </Step>
                            <Step>
                                {({ accomplished, index }) => (
                                    <div
                                        className={`indexedStep ${accomplished ? "accomplished" : null
                                            }`}
                                    >
                                        <div className="range-text-div">
                                            <h6 className="placed">11 Apr</h6>
                                            <p className="date">20 Dec</p>
                                            <h6 className="parcel">Parcel Received from shop</h6>
                                            <p className="loc">Datta Mandir Chowk, Pune, Maharashtra 411014, India</p>
                                        </div>
                                    </div>
                                )}
                            </Step>

                            <Step>
                                {({ accomplished, index }) => (
                                    <div
                                        className={`indexedStep ${accomplished ? "accomplished" : null
                                            }`}
                                    >
                                        <div className="range-text-div">
                                            <h6 className="placed">12 Apr</h6>
                                            <p className="date">Est. 30 Dec</p>
                                            <h6 className="parcel">Parcel Received from shop</h6>
                                            <p className="loc">Datta Mandir Chowk, Pune, Maharashtra 411014, India</p>
                                        </div>
                                    </div>
                                )}
                            </Step>

                            <Step>
                                {({ accomplished, index }) => (
                                    <div
                                        className={`indexedStep ${accomplished ? "accomplished" : null
                                            }`}
                                    >
                                        <div className="range-text-div">
                                            <h6 className="placed">13 Apr</h6>
                                            <p className="date">Est. 30 Dec</p>
                                            <h6 className="parcel">Parcel Received from shop</h6>
                                            <p className="loc">Datta Mandir Chowk, Pune, Maharashtra 411014, India</p>
                                        </div>
                                    </div>
                                )}
                            </Step>
                            <Step>
                                {({ accomplished, index }) => (
                                    <div
                                        className={`indexedStep ${accomplished ? "accomplished" : null
                                            }`}
                                    >
                                        <div className="range-text-div">
                                            <h6 className="placed">14 Apr</h6>
                                            <p className="date">Est. 30 Dec</p>
                                            <h6 className="parcel">Parcel Received from shop</h6>
                                            <p className="loc">Datta Mandir Chowk, Pune, Maharashtra 411014, India</p>
                                        </div>
                                    </div>
                                )}
                            </Step>
                        </ProgressBar>
                    </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Tracker;

