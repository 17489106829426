import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AffiliateMainRoutes from "../AffiliateMainRoutes/AffiliateMainRoutes";
import Affiliate_Login from "../../Affiliate-Dashboard/AffiliateLogin/AffiliateLogin"
import Call_support from "../../call-support/Call_support";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

const AffiliateRoutes = () => {
  const { pathname } = useLocation();
  console.log(pathname,'pathname');
  
  return (
    <>
      {pathname.startsWith("/affiliate/affiliatelogin") ?  <Header /> : ''}
      <Routes>

        <Route path={`/affiliate/*`} element={<AffiliateMainRoutes />} />

        <Route path={"/affiliate/affiliatelogin"} element={<Affiliate_Login />} />
      </Routes>
      {pathname.startsWith("/affiliate/affiliatelogin") ?  <Footer /> :''}
      <Call_support />

    </>
  );
};

export default AffiliateRoutes;
