import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllData = async (currentPage, perPage, search, searchStatus, domain_id) => {
  try {
    let url = `/seller/outer-website/masters/users?page=${currentPage}&per_page=${perPage}&term=${search}&domain_id=${domain_id}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`
    }
    return await getData(
      url
    );
  } catch (error) {
    console.log(error);
  }
};

export const Delete = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/outer-website/masters/users/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingledata = async (id) => {
  try {
    return await getData(`/seller/outer-website/masters/users/${id}`);
  } catch (error) {
    console.log(error);
  }
};
