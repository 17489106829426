import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import parse from "html-react-parser";
import Accordion from "react-bootstrap/Accordion";
import "./FAQestion.css";


function FAQestion() {
  const faqCardContent = [
    {
      id: 1,
      cardTitle: "General",
      imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/FAQ/FAQFeature/1.png"
    },
    {
      id: 1,
      cardTitle: "Services",
      imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/FAQ/FAQFeature/2.png"
    },
    {
      id: 1,
      cardTitle: "Fees & Charges",
      imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/FAQ/FAQFeature/3.png"
    },
    {
      id: 1,
      cardTitle: "Managing Your Account",
      imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/FAQ/FAQFeature/4.png"
    },
    {
      id: 1,
      cardTitle: "Fulfillment by Netpurti",
      imageSource: process.env.PUBLIC_URL + "/assest/images/Seller_panel/FAQ/FAQFeature/5.png"
    },
  ];

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [subdata, setsubData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/seller/faq/faq-question`);
      setData(response?.data);
      const response1 = await getData(`/seller/faq/sub-faq`);
      setsubData(response1?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(subdata);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="faq-questions">
        <div className="container">
          <div className="CardsMain mb-5">
            <div className="row justify-content-center">
              {data?.map((item, index) => (
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                  <div className="main-card-class mt-lg-5 mt-md-5">
                    <div className='image-div'>
                      {item?.image&& (
                        <img
                          src={IMG_URL+item?.image}
                          className="feature-img"
                          alt="..."
                        />
                      )}
                    </div>
                    <div className="card faqCards">
                      <div className="card-body ">
                        <p className="mt-4">{item?.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="accordionMain mt-5">
            <Accordion defaultActiveKey="">
              <div className="row">
                {subdata?.map((item,index)=>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                     {item?.name}
                    </Accordion.Header>
                    <Accordion.Body>
                    {parse(item?.description) || ""}
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                )}
                {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How does selling on Netpurti.com work?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is the minimum quantity requirement for listing
                      products on Netpurti.com?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What types of products am I allowed to list and sell on
                      Netpurti.com?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What do I need to register to sell on Netpurti.com?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Is it possible to sell on Netpurti.com even if I don't
                      have a website?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Can I offer both products and services on Netpurti.com
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad
                    </Accordion.Body>
                  </Accordion.Item>
                </div> */}
              </div>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default FAQestion;
