import React from 'react'
import Form from 'react-bootstrap/Form';
import ButtonComponent from "../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import '../../ReturnOrderStepForm/ReturnOrderStepForm.css'

const StepOneSelect = ({ handleNext }) => {
  const labels = [
    "Product price has reduced",
    "Wrong contact number entered",
    "Ordered by mistake",
    "Incorrect product size/colour/type ordered",
    "Purchased product from somewhere else",
    "Wrong address selected",
    "Product not required anymore",
    "Incorrect payment method selected",
    "Others",
  ];
  return (
    <>
      <section className='stepone-reason'>

        <div className='content mb-4'>
          <h6>Select a reason for returning</h6>
          <div>
            {labels.map((label, index) => (
              <Form.Check
                key={index}
                type='radio'
                label={label}
                name="selecte"
              />
            ))}
            <div className='row mx-3'>
              <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6  col-12'>
                <Form.Control
                  type="text"
                  placeholder='please fill'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='main-btn-div'>
          <ButtonComponent className="continue-btn" onClick={handleNext}>
            Proceed
          </ButtonComponent>
          <ButtonComponent className="cancel-btn" >
            Cancel
          </ButtonComponent>
        </div>
      </section>

    </>
  )
}

export default StepOneSelect