import React from 'react'
import './home_link.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Home_link = () => {

    const [modalShow, setModalShow] = React.useState(false);
    
    return (
        <>
            <div className='Home_support-sec'>
                <Link to={`/welcome`}>
                <div className='home-icon-div' type="button">
                    <FontAwesomeIcon className='supporticon' icon={faUsers} />
                </div>

                </Link>
            </div>

           
        </>
    )
}

export default Home_link