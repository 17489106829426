import React, { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";

function Graduation() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <div className="stepFourMain">
        {/* form-holder */}
        <div className="form-container">
          <Form>
            <div className="">
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId="Education_Level">
                    <Form.Label className="">Address line 3</Form.Label>

                    <Form.Control
                      type="text"
                      name="Education_Level"
                      placeholder="Enter Education Level"
                    />
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form className="mb-3">
                    <Form.Group controlId="Awarded_Year">
                      <Form.Label className="">Address line 2</Form.Label>

                      <Form.Control
                        type="text"
                        name="Awarded_Year"
                        placeholder="Enter Awarded Year"
                      />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 ">
                <Form.Group controlId="Authourity">
                  <Form.Label className="">City/District:</Form.Label>

                  <Form.Control
                    type="text"
                    name="Authourity"
                    placeholder="Enter Authourity"
                    className="mb-3"
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group controlId="Degree_Name:">
                  <Form.Label className="">State</Form.Label>

                  <Form.Control
                    type="text"
                    name="Degree_Name"
                    placeholder="Enter Degree Name:"
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group controlId="Degree_Name:">
                  <Form.Label className="">Pincode</Form.Label>

                  <Form.Control
                    type="text"
                    name="Degree_Name"
                    placeholder="Enter Degree Name:"
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group controlId="Degree_Name:">
                  <Form.Label className="">Country</Form.Label>

                  <Form.Control
                    type="text"
                    name="Degree_Name"
                    className="mb-3"
                    placeholder="Enter Degree Name:"
                  />
                </Form.Group>
              </div>
            </div>

            {/* <div className="Percentages-Add-Education">
              <div className="row">
                <div className="col-md-12">
                  <div className="Add-education-btn-holder">
                    <div className="apply-btn-holder">
                      <Button className="apply_now_btn">Save</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </Form>

          {/* <Button className="back-btn me-3">Skip</Button> */}

          <div className="text-end">
            <Button className="btn StepfoursaveBtn">Save</Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Graduation;
