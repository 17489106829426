import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";

export const getCustomNames = async (name) => {
    try {
      const res = await getData(
        `/without-login/auto-suggestion/all-s-custom?name=${name}`
      );
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };