import React from 'react'
import Ordertable from './Ordertable/Ordertable'
import Orderstock from './Orderstock/Orderstock'

const Orders = () => {
  return (
    <>
      <Orderstock />
      <Ordertable />

    </>
  )
}

export default Orders