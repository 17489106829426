import {
  getData,
  postData,
  getBlobData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getSellerWalletApi = async (id) => {
  try {
    const res = await getData(`/btb/wallet`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getExportStatement = async () => {
  try {
    const response = await getData(`/btb/wallet/export`);
    return response;
  } catch (error) {
    console.error('Error fetching export statement:', error);
  }
};



export const depositAmountPost = async (amount, razor) => {
  try {
    const res = await postData(`/btb/wallet/`, { amount: amount, razor_id: razor });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const withdrawAmountPost = async (amount) => {
  try {
    const res = await postData(`/btb/wallet/withdraw`, { amount: amount });

    return res;
  } catch (error) {
    console.log(error);
  }
};
