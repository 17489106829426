import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../api";

import { formatDate } from "../Product.js";

export const getOuterWebSiteAllOrders = async (
  seller_id,
  page = 1,
  search_name = "",
  domain_id,
  invoice_no,
  order_status_id
) => {
  try {
    return await getData(
      `/outer-website/order-process/all-orders?seller_id=${seller_id}&page=${page}&term=${search_name}` +
      `&domain_id=${domain_id}&order_status_id=${order_status_id || ""}&invoice_no=${invoice_no}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getOuterWebSiteAllReturnOrders = async (
  seller_id,
  page = 1,
  search_name = "",
  domain_id
) => {
  try {
    return await getData(
      `/outer-website/order-process/all-return-orders?seller_id=${seller_id}&page=${page}&term=${search_name}&domain_id=${domain_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getOuterWebSiteAllOrderStatus = async () => {
  try {
    return await getData(`/outer-website/order-process/all-order-status`);
  } catch (error) {
    console.log(error);
  }
};



export const postOuterWebSiteAllOrderStatus = async (data, order) => {

  try {
    return await postData(
      `/outer-website/order-process/all-order-status`, { ids: data, order: order?.value },);
  } catch (error) {
    console.log(error);
  }
};

export const getOuterWebSiteAllReturnStatus = async () => {
  try {
    return await getData(`/outer-website/order-process/all-return-status`);
  } catch (error) {
    console.log(error);
  }
};

export const postOuterWebSiteAllReturnStatus = async (data, order) => {

  try {
    return await postData(
      `/outer-website/order-process/all-return-status`, { ids: data, order: order?.value },);
  } catch (error) {
    console.log(error);
  }
};
