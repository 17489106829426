import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ButtonComponent from "../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import "../Add_note_modal/AddNoteModal.css";
import "./CancelOrderModal.css";
import CancelConfirm from "./CancelConfirm";
import { getgetAllBTBCancelReason } from "../../../../../utils/apis/master/Master";
import { Select2Data } from "../../../../../utils/api";
import { useForm } from "react-hook-form";
import classNames from "classnames";

function CancelOrderModal(props) {
  const [modalConfirm, setModalConfirm] = React.useState(false);
  const labels = [
    "Shipping fee increased",
    "No stock",
    "Order is not paid within 30 days",
    "Unable to ship in accordance to agreed shipping method",
    "Unable to ship in accordance to agreed shipping time",
    "No longer needed",
    "Wrong order information or a new order will be placed",
    "Product price increased",
    "Others",
  ];

  const [resonse, setResones] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const getData = async () => {
    const res = await getgetAllBTBCancelReason();
    setResones(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = () => {
    const data = {
      order_id: props.show,
      ...getValues(),
    };
    // console.log(data);
    reset(data);
    setModalConfirm(true);
    props.onHide();
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-note-modal cancel-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Cancel order
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <>
              <p>
                Please tell us what was the reason for canceling this order ?
              </p>
              <div>
                {resonse?.map((label, index) => (
                  <Form.Check
                    key={index}
                    {...register("btb_cancel_reason_id", {
                      required: "Select Reason is required",
                    })}
                    type="radio"
                    label={label?.name}
                    value={label?.id}
                    checked={label?.id == getValues("btb_cancel_reason_id")}
                    name="btb_cancel_reason_id"
                    onChange={(e) => {
                      setValue("btb_cancel_reason_id", label?.id);
                      reset({
                        ...getValues(),
                        btb_cancel_reason_id: label?.id,
                      });
                    }}
                  />
                ))}
                <div className="mx-3">
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.reason,
                      "is-valid": getValues("reason"),
                    })}
                    type="text"
                    name="reason"
                    {...register(
                      "reason",
                      getValues("btb_cancel_reason_id") == 1
                        ? { required: "Reason is required" }
                        : {}
                    )}
                    placeholder="please fill"
                  />
                </div>
              </div>
            </>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonComponent
            className={"btn-confirm"}
            onClick={handleSubmit(onSubmit)}
            //   onClick={() => {
            //     setModalConfirm(true);
            //     props.onHide();
            //   }}
          >
            Confirm
          </ButtonComponent>
          <ButtonComponent onClick={props.onHide} className={"btn-cancle"}>
            Cancel
          </ButtonComponent>
        </Modal.Footer>
      </Modal>
      <CancelConfirm
        show={modalConfirm}
        getOrders={props.getOrders}
        getValues={getValues}
        onHide={() => setModalConfirm(false)}
      />
    </>
  );
}

export default CancelOrderModal;
