import React, { useState } from 'react'
import {
    Col,
    Container,
    Nav,
    NavDropdown,
    Navbar,
    Offcanvas,
    Row,
} from "react-bootstrap";
import './B2bMainDashboard.css'
import BreadcrumComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/BreadcrumComponent/BreadcrumComponent'
import DashboardSidebar from './Dashboard-sidebar/DashboardSidebar';
import B2bDashboardRoutes from './Dashboard-sidebar/B2bDashboardRoutes/B2bDashboardRoutes';
const B2bMainDashboard = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/b2bbuyer/buyer-home" },
        { text: "Account", link: "#" },
        { text: "Dashboard", link: "#" },

    ]);
    return (
        <>
            <section className='b2b-main-dashboard'>
                <div className='container-fluid'>
                    <Row className=''>
                        <div className='be-image '>
                            <BreadcrumComponent items={breadcrumbItems} />
                        </div>
                    </Row>
                    <Row className='desh-design'>
                        <Col xxl={2} xl={2} lg={3} md={4} className='px-0'>
                            <DashboardSidebar />
                        </Col>
                        <Col xxl={10} xl={10} lg={9} md={8} className='px-0'>
                            <div className='left-sec-dashbord'>
                                <B2bDashboardRoutes />
                            </div>

                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default B2bMainDashboard