import React from 'react'
import { useState, useEffect } from "react";
import Subscription from './Subscription/Subscription'
import CompareAll from './CompareAll/CompareAll'

const NewPlanPricing = () => {
    const [Id, setId] = useState();
    const [planId, setPlanId] = useState();
    return (
        <>
            <Subscription Id={Id} setId={setId} planId={planId} setPlanId={setPlanId} />
            <CompareAll Id={Id} setId={setId} planId={planId} setPlanId={setPlanId} />
        </>

// D:\React Projects\netpurti_seller_admin\src\Components\SellerPanel\Seller_Dashboard\SidebarComponents\Mywebsite\PackageSeller\NewPlanPricing\NewPlanPricing.js
    )
}

export default NewPlanPricing