import React,{useState,useContext,useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./DemoCard.css";
import ButtonComponent from "../../CommonComponents/ButtonComponent/ButtonComponent";
// import { DemoCardGet } from "../../../../utils/apis/myWebsite/mywebsite";
const DemoCard = () => {


    // const [demoCardData, setDemoCardData] = useState();
    // const getDemoCardData = async () => {

    //     const res = await DemoCardGet();
    //     if (res?.success) {
    //         setDemoCardData(res?.data);
    //     }
    // }

    // useEffect(() => {
    //     // getDemoCardData();
    // }, [])


    return (
        <>
            <Container>
                <section className="Demo-Card">
                    <Row>
                        <Col xxl={6} xl={6} md={6}>
                            <div className="card-demo">
                                <img
                                    src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/DemoCard/Demo1.png"}
                                    className="Demo"
                                />
                                <div className="overlay"></div>
                                <div className="demo-Content">
                                    <h4 className="maintenance-title">WANT TO KNOW MORE?</h4>
                                    <p className="sub-title">Try Netpurti's for free Yourself!</p>
                                    <div className="website-btn">
                                        <ButtonComponent variant="primary" className="web-btn">
                                            Free Website Demo
                                        </ButtonComponent>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} md={6}>
                            <div className="card-demo">
                                <img
                                    src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/DemoCard/Demo2.png"}
                                    className="Demo"
                                />
                                <div className="overlay"></div>
                                <div className="demo-Content">
                                    <h4 className="maintenance-title">WANT TO KNOW MORE?</h4>
                                    <p className="sub-title">Try Netpurti's for free Yourself!</p>
                                    <div className="website-btn">
                                        <ButtonComponent variant="primary" className="web-btn">
                                            Free Website Demo
                                        </ButtonComponent>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        </>
    );
};

export default DemoCard;
