import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-step-progress-bar/styles.css";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const Verfiypassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/seller/setnewpassword");
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <>
      <section className="SellerLogin">
        <div className="container">
          <div className="loginHead">
            <Link to={"/seller/seller_home"}>
              <p>
                <FontAwesomeIcon
                  icon="fa-solid fa-less-than"
                  className="lessThanSymbol"
                />
                Go to Home
              </p>
            </Link>
            <h3>Check your email</h3>
            <p className="headbelowtxt">
              We send a password reset link to abc@gmail.com
            </p>
          </div>

          <div className="col-lg-6 mx-auto">
            <div className="personal-details-form mb-0 ">
              {/* form-holder */}

              <div className="details-form-holder">
                <div className="form-container">
                  <Form>
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="col-md-12">
                            <div className="text-center OpenGmailsec">
                              <Button
                                className="tabs-btn opengmailbtn"
                                type="submit"
                              >
                                Open Gmail
                              </Button>

                              <p>
                                <span>Didn't receive the email?</span>
                                <span>Click to resend</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Verfiypassword;
