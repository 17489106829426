import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllSubCategory = async (currentPage, perPage, search, searchCategary, searchStatus, searchOrder, domain_id) => {
  try {
    let url = `/seller/outer-website/masters/sub-category?page=${currentPage}&per_page=${perPage}&term=${search}` +
      `&categary=${searchCategary?.value || ""}&order=${searchOrder.value || ""}&domain_id=${domain_id}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`
    }
    return await getData(
      url
    );
  } catch (error) {
    console.log(error);
  }
};

export const changeSubCategoryStatus = async (id) => {
  try {
    return await editStatusData(
      `/seller/outer-website/masters/sub-category/${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const deleteSubCategory = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/outer-website/masters/sub-category/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const addSubCategory = async (finalData) => {
  try {
    return await postData(
      `/seller/outer-website/masters/sub-category`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const editSubCategory = async (id, finalData) => {
  try {
    return await postData(
      `/seller/outer-website/masters/sub-category/${id}`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingleSubCategory = async (id) => {
  try {
    return await getData(`/seller/outer-website/masters/sub-category/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
