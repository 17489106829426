import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../../utils/context";
import {
  getsingleData,
  editData,
  getsingleOfSeller,
} from "../../../../../../utils/apis/seller/outer-website/masters/return_policy/return_policy";
import JoditEditor from "jodit-react";
import parse from "html-react-parser";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, sellerDomain } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getsingleData(sellerDomain?.value);
    reset(response?.data);
    setValue("original_name", response?.data?.s_category?.name);
  };

  const GetEditDataCategory = async () => {
    const response = await getsingleOfSeller(id);

    reset(response?.data);
  };
  useEffect(() => {
    GetEditData();
    GetEditDataCategory();
  }, []);

  const {

    register,
    handleSubmit,
    watch,
    getValues,

    formState: { errors },
    reset, control,
    setValue,
    trigger,
  } = useForm();
  const descriptionValue = watch("name");
  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const finalData = new FormData();
      finalData.append("domain_id", sellerDomain?.value);
      finalData.append("name", data?.name);
      const response = await editData(finalData);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        // props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    register('name', {
      required: 'Description is required.',
      // maxLength: {
      //   value: 250,
      //   message: 'Description must be at most 250 characters long.'
      // }
    });
  }, [register]);
  return (
    <>
      {/* <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Term Condition
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> */}
      <div class="mt-4">
        <h3>Return Policy</h3>
      </div>
      <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
        <Row>
          <Col lg={12}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-start"> {/* Align to left */}
                {/* Description */}
                <Col sm={12}>
                  <Form.Label className="text-center">
                    Description
                  </Form.Label>
                  <Form.Group>
                    <Controller
                      name="name" // Provide the field name
                      control={control} // Pass the control object from useForm()
                      rules={{ required: "Description is required" }} // Validation rules
                      render={({ field }) => (
                        <JoditEditor
                          value={descriptionValue || ''}
                          onBlur={(newContent) => {
                            setValue('name', newContent);
                            trigger('name');
                          }}

                        />
                      )}
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}
                </Col>
              </Row>
            </div>
          </Col>

          <Row className="mt-5 pb-3">
            <div className="d-flex justify-content-center">
              <Link>
                <CancelButton
                  name={"cancel"}
                  handleClose={props.handleClose}
                />
              </Link>

              <SaveButton
                name={"save"}
                handleSubmit={handleSubmit(onSubmit)}
              />
            </div>
          </Row>
        </Row>
      </Form>
      {/* </Modal.Body>
      </Modal> */}

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
