import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";
  
  export const sellerPercentageSend = {
    stepOne: 0,
    stepTwo: 10,
    stepThree: 40,
    stepFour: 60,
    stepFive: 70,
    stepSix: 80,
    stepseven: 90,
  };

  export const btcPersonalDetailsPost = async (data) => {
    try {
      const res = await postData(
        `/btc/btc-create/btc-personal-details`,
        data
      );
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const getBtcPersonalDetails = async (user_id) => {
    try {
      const res = await getData(
        `/btc/btc-create/btc-personal-details/${user_id}`
      );
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const firmAddressDetailPost = async (data) => {
    try {
      const res = await postData(
        `/btc/btc-create/btc-address-details`,
        data
      );
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const getfirmAddressDetail = async (user_id) => {
    try {
      const res = await getData(
        `/btc/btc-create/btc-address-details/${user_id}`
      );
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  export const firmPasswordPost = async (data) => {
    try {
      const res = await postData(
        `/btc/btc-create/btc-personal-details/password`,
        data
      );
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };