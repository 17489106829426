import React, { useEffect, useState, useContext } from "react";
import "./MyProfile.css";
import { Scrollchor } from "react-scrollchor";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import EditPersonalDetailsModal from "../../common-components/AllModal/Editpersonal-Detailmodal/EditPersonalDetailsModal";
import WorkingTypeModal from "../../common-components/AllModal/Editpersonal-Detailmodal/WorkingTypeModal";
import {
  faUser,
  faBriefcase,
  faIndustry,
  faFilm,
  faBook,
  faLock,
  faChevronRight,
  faPen,
  faEye,
  faFax,
  faLocationDot,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../../../utils/api";
import { Context } from "../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import FirstModal from "./profile_models/FirstModal";
import SecondModal from "./profile_models/SecondModal";
import ThirdModal from "./profile_models/ThirdModal";
import FourthModal from "./profile_models/FourthModal";
import FifthModal from "./profile_models/FifthModal";
import SixthModal from "./profile_models/SixthModal";
import SeventhModal from "./profile_models/SeventhModal";
import EighthModal from "./profile_models/EighthModal";

library.add(fas);

const MyProfile = () => {
  const [activeTab, setActiveTab] = useState("");
  useEffect(() => {
    if (activeTab) {
      const element = document.getElementById(activeTab.slice(1)); // Remove '#' from activeTab
      if (element) {
        const yOffset = -80;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [activeTab]);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };
  return (
    <>
      <section className="MyprofileSec">
        <div className="container">
          <div className="row scrollable-content-div">
            <div className="col-lg-2 col-6 order-lg-1 order-1">
              <div id="list-example" className="list-group  acounttab-stick">
                <h1
                  className="account-text mb-4
                                "
                >
                  Account
                </h1>
                <Scrollchor
                  to="#sample-code1"
                  className={`nav-link ${
                    activeTab === "#sample-code1" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("#sample-code1")}
                >
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faUser} className="me-2" />
                    <h6 className="list-name"> Personal Details</h6>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </Scrollchor>

                <Scrollchor
                  to="#sample-code2"
                  className={`nav-link ${
                    activeTab === "#sample-code2" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("#sample-code2")}
                >
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faBriefcase} className="me-2" />
                    <h6 className="list-name"> Working Type</h6>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </Scrollchor>
                <Scrollchor
                  to="#sample-code3"
                  className={`nav-link ${
                    activeTab === "#sample-code3" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("#sample-code3")}
                >
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faIndustry} className="me-2" />
                    <h6 className="list-name">Industry Details</h6>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </Scrollchor>
                <Scrollchor
                  to="#sample-code4"
                  className={`nav-link ${
                    activeTab === "#sample-code4" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("#sample-code4")}
                >
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faFilm} className="me-2" />
                    <h6 className="list-name">Firm Details</h6>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </Scrollchor>

                <Scrollchor
                  to="#sample-code5"
                  className={`nav-link ${
                    activeTab === "#sample-code5" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("#sample-code5")}
                >
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faBook} className="me-2" />
                    <h6 className="list-name">Firm Type Details</h6>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </Scrollchor>

                <Scrollchor
                  to="#sample-code6"
                  className={`nav-link ${
                    activeTab === "#sample-code6" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("#sample-code6")}
                >
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faFax} className="me-2" />
                    <h6 className="list-name">Communication Address Details</h6>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </Scrollchor>

                <Scrollchor
                  to="#sample-code7"
                  className={`nav-link ${
                    activeTab === "#sample-code7" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("#sample-code7")}
                >
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faLocationDot} className="me-2" />
                    <h6 className="list-name">Drop Address Details</h6>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </Scrollchor>

                <Scrollchor
                  to="#sample-code8"
                  className={`nav-link ${
                    activeTab === "#sample-code8" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("#sample-code8")}
                >
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faMoneyBill} className="me-2" />
                    <h6 className="list-name">Bank Details</h6>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </Scrollchor>

                {/* <Scrollchor to="#sample-code5" className={`nav-link ${activeTab === "#sample-code5" ? "active" : ""}`} onClick={() => handleTabClick("#sample-code5")}>

                                    <div className="d-flex">
                                        <FontAwesomeIcon icon={faLock} className="me-2" />
                                        <h6 className="list-name">Change Password</h6>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                </Scrollchor> */}
              </div>
            </div>

            <div className="col-lg-8 col-12 order-lg-2 order-md-3 order-2">
              <div className="middle-div">
                <FirstModal />
                <SecondModal />
                <ThirdModal />
                <FourthModal />
                <FifthModal />
                <SixthModal />
                <SeventhModal />
                <EighthModal />
              </div>
            </div>

            <div className="col-lg-2 col-md-5 col-sm-5 col-6 order-lg-3 order-md-2 order-3 ">
              <div>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/images/B2Bbuyer/Accounts/accountbanner.png"
                  }
                  className="accountbanner"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyProfile;
