import React, { useEffect, useState, useContext } from "react"
import './EditPersonalDetailsModal.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangeModal from './ChangeModal';
import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import InquiryModal from "../../../common-components/AllModal/inquiry-Modal/InquiryModal";
import { sendMobileOTP, resendMobileOTP, sendEmailOTP, verifyMobileOTP, verifyEmailOTP } from "../../../../../utils/apis/common/Common";
import classNames from "classnames";
const EditPersonalDetailsModal = (props) => {
    const [modalChange, setModalChange] = useState(false);
    const [modalInquiry, setModalInquiry] = useState(false);
    const [mobileSeconds, setMobileSeconds] = useState(0);
    const [emaiSseconds, setEmailSeconds] = useState(0);

    const [mobileVerify, setMobileVerify] = useState(true);
    const [emailVerify, setEmailVerify] = useState(true);
    const [termStatus, setTermStatus] = useState(false);
    const [otpErr, setOtpErr] = useState("");
    const [modalShow2, setModalShow2] = useState(false);
    const [modalShow3, setModalShow3] = useState(false);

    const [currentMobile, setCurrentMobile] = useState("");
    const [currentEmail, setCurrentEmail] = useState("");

    const { postData, Id } =
        useContext(Context);

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    const submitModal = () => {
        setModalInquiry(true);
        setTimeout(() => {
            props.onHide();
            reset();
            setModalInquiry(false);
        }, 3000);
    };

    const onSubmit = async (data) => {
        console.log("dataHere", data);
        const res = await postData('/btb/btb-create/btb-personal-details', data);
        // setMobileVerify(true);
        // setEmailVerify(true);
        submitModal(true);
    };


    useEffect(() => {
        reset(props?.data);
        setCurrentMobile(props?.data?.contact_no);
        setCurrentEmail(props?.data?.email);
    }, [props?.data])


    const handleEmailChange = (() => {
        // console.log("getValues(email)", getValues("email"), "==", "currentEmail", currentEmail);
        if (getValues("email") !== currentEmail) {
            setEmailVerify(false);
        }
    });




    const [otpMobileLoader, setOtpMobileLoader] = useState(false);
    const [otpEmailLoader, setOtpEmailLoader] = useState(false);

    const handleSendMobileOtp = async (type) => {
        setOtpMobileLoader(true);
        if (type === "resend") {
            const res = await resendMobileOTP(getValues("contact_no"));
            if (res?.success) {
                setModalShow2(true);
                setMobileSeconds(90);
                // clearErrors();
            }
        } else {
            const res = await sendMobileOTP(getValues("contact_no"));
            if (res?.success) {
                setModalShow2(true);
                setMobileSeconds(90);
                // clearErrors();
            }
        }
        setOtpMobileLoader(false);
    }

    const handleSendEmailOtp = async () => {
        setOtpEmailLoader(true);
        const res = await sendEmailOTP(getValues("email"));
        if (res?.success) {
            setModalShow3(true);
            setEmailSeconds(90);
            // clearErrors();
        }
        setOtpEmailLoader(true);
    }


    const verifyOTPMobile = async (otp) => {
        const res = await verifyMobileOTP(getValues('contact_no'), otp);
        if (res?.success) {
            setMobileVerify(true);
            setModalShow2(false);
            // clearErrors();
            setMobileSeconds(0);
        } else {
            setOtpErr(res.message);
        }
    }

    const verifyOTPEmail = async (otp) => {
        const res = await verifyEmailOTP(getValues('email'), otp);
        if (res?.success) {
            setEmailVerify(true);
            setModalShow3(false);
            // clearErrors();
            setEmailSeconds(0);
        } else {
            setOtpErr(res.message);
        }
    }


    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='edit-details-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Personal Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={4} controlId="formGridEmail">
                                <div className='d-flex'>
                                    <Form.Label>Email </Form.Label>
                                    {/* <span className='change' onClick={() => { setModalChange(true); props.onHide(); }}>Change</span> */}
                                </div>


                                <InputGroup className="mb-3">
                                    <Form.Control
                                        disabled
                                        type="email"
                                        name="email"
                                        placeholder="email"
                                        className={classNames("", {
                                            "is-invalid": errors?.email,
                                            "is-valid": !errors?.email && getValues("email"),
                                        })}

                                        {...register("email", {
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                message: "Invalid email address",
                                            },

                                            validate: (value) => {
                                                // Extract the domain and TLD
                                                const domainPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/;
                                                const match = value.match(domainPattern);
                                                if (match) {
                                                    const domainParts = match[1].split('.');
                                                    const tld = match[2];

                                                    // Ensure the domain and TLD are not the same
                                                    if (domainParts[domainParts.length - 1] === tld) {
                                                        return "Domain and top-level domain must be different";
                                                    }
                                                }
                                                return true;
                                            }
                                        })}
                                    />

                                    <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon="fa-solid fa-circle-check" className='me-1' />Verified</InputGroup.Text>

                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md={4} controlId="formGridName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="f_name"
                                    placeholder="First Name"
                                    className={classNames("", {
                                        "is-invalid": errors?.f_name,
                                        "is-valid": !errors?.f_name && getValues("f_name"),
                                    })}
                                    {...register("f_name", {
                                        required: "First Name is required",
                                    })}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={4} controlId="formGridName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="l_name"
                                    placeholder="Last Name"
                                    className={classNames("", {
                                        "is-invalid": errors?.l_name,
                                        "is-valid": !errors?.l_name && getValues("l_name"),
                                    })}
                                    {...register("l_name", {
                                        required: "Last Name is required",
                                    })}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={4} controlId="formGridContact">
                                <Form.Label>Contact Number</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        disabled
                                        type="text"
                                        name="contact_no"
                                        placeholder="Contact No."
                                        {...register("contact_no", {
                                            required: "Contact number is required",
                                            validate: {
                                                isTenDigits: (value) =>
                                                    value && value.length === 10 || "Contact number must be 10 digits",
                                                isNumeric: (value) =>
                                                    /^\d+$/.test(value) || "Contact number must be numeric"
                                            }
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.contact_no,
                                            "is-valid": !errors?.contact_no && getValues("contact_no")?.length === 10,
                                        })}
                                        onKeyDown={(event) => {
                                            const { key } = event;
                                            if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab") {
                                                event.preventDefault();
                                            }
                                            if (event.target.value?.length >= 10 && key !== "Backspace" && key !== "Tab") {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon="fa-solid fa-circle-check" className='me-1' />Verified</InputGroup.Text>

                                </InputGroup>

                            </Form.Group>

                            <Form.Group as={Col} md={4} controlId="formGridDesignation">
                                <Form.Label>Designation</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="designation"
                                    placeholder="Designation"
                                    className={classNames("", {
                                        "is-invalid": errors?.designation,
                                        "is-valid": !errors?.designation && getValues("designation"),
                                    })}
                                    {...register("designation", {
                                        required: "Designation is required",
                                    })}
                                />
                            </Form.Group>
                        </Row>
                        <Modal.Footer>
                            <ButtonComponent onClick={props.onHide} className={'btn-cancle'}>Cancel</ButtonComponent>
                            <ButtonComponent onClick={() => handleSubmit(onSubmit(getValues()))} className={'btn-update'} > Update</ButtonComponent>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal>
            <ChangeModal
                show={modalChange}
                onHide={() => setModalChange(false)}
            />
            <InquiryModal
                message="Submitted successfully"
                show={modalInquiry}
                onHide={() => setModalInquiry(false)}
            />
        </>
    )
}

export default EditPersonalDetailsModal