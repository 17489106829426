import { useState, useEffect, useContext } from "react";
import "./B2Cretail_stepForm.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-datepicker/dist/react-datepicker.css";
import Stepone from "./Stepone/Stepone";
import Steptwo from "./Steptwo/Steptwo";
import Stepthree from "./Stepthree/Stepthree";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { sellerPercentage } from "../../../utils/apis/seller/Seller";
import { Context } from "../../../utils/context";
import { getBtcPersonalDetails } from "../../../utils/apis/btc/Btc";

function B2Cretail_stepForm({ onNext, onPrev }) {
  const [startDate, setStartDate] = useState();
  const [showInnerComponent, setShowInnerComponent] = useState(true);
  const [userDetails, setUserDetails] = useState();
  const [workType, setWorkType] = useState();
  const [workTypeName, setWorkTypeName] = useState();

  const { getData, postData, IMG_URL } = useContext(Context);
  const getSellerDetails = async () => {
    //const res = await getData(`/btc/btc-create/btc-personal-details/${Cookies.get('btc_id')}`);

    const res = await getBtcPersonalDetails(Cookies.get("btc_id"));
    if (res?.success) {
      setUserDetails(res.data);
    }
  };

  useEffect(() => {
    getSellerDetails();
    window.scrollTo(0, 0);
  }, []);

  const [page, setPage] = useState(0);

  const nextStep = () => {
    getSellerDetails();
    window.scrollTo(0, 0);
    setPage((currPage) => currPage + 1);
    if (page === 6 && onNext) {
      onNext();
    }
  };

  const prevStep = () => {
    getSellerDetails();
    window.scrollTo(0, 0);
    setPage((currPage) => currPage - 1);
    if (page === 0 && onPrev) {
      onPrev();
    }
  };
  const prevStepInner = () => {
    window.scrollTo(0, 0);
  };

  const calculatePercentage = () => {
    const totalSteps = stepNames.length;
    const currentPage = page + 1;
    return (currentPage / totalSteps) * 100;
  };

  const calculateProgressPercentage = () => {
    const stepProgressPercentages = [0, 50, 100];

    return stepProgressPercentages[page];
  };

  const goToStep = (stepIndex) => {
    setPage(stepIndex);
  };

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return (
          <Stepone
            nextStep={nextStep}
            heading={stepNames[0]}
            userDetails={userDetails}
          />
        );
      case 1:
        return (
          <Steptwo
            prevStep={() => prevStep(2)}
            setPage={() => setPage(3)}
            nextStep={nextStep}
            userDetails={userDetails}
          />
        );
      case 2:
        return (
          <Stepthree
            nextStep={nextStep}
            prevStep={() => prevStep(2)}
            userDetails={userDetails}
          />
        );
      default:
        return null;
    }
  };

  const stepNames = ["Personal Details", "Address Details", "Password"];

  return (
    <>
      <section className="B2C-Retail-StepMain">
        <div className="container">
          <div className=" mt-5 mb-5">
            <div className="d-flex">
              <div className="">
                <Link to="/logistic/logistic-home" className="left-goto-homee">
                  <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> Go to home
                </Link>
              </div>
              <div className="title-stttee-align">
                <h6>B2C Buyer Registration Form</h6>
              </div>
            </div>
          </div>

          {page !== 3 ? (
            <div className="ProgressBar">
              <ProgressBar
                percent={calculatePercentage()}
                filledBackground="#1C42A9"
                height="3px"
                style={{ margin: "auto" }}
              >
                {[1, 2, 3].map((icon, index) => (
                  <Step key={index}>
                    {({ accomplished }) => (
                      <div
                        style={{
                          backgroundColor: accomplished ? "#1C42A9" : "#ffffff",
                          color: accomplished ? "#fff" : "#000000",
                          border: accomplished
                            ? "1px solid #1C42A9"
                            : "1px solid #FFFFFF",
                          boxShadow: accomplished
                            ? "none"
                            : " 0px 0px 6px #00000029",
                        }}
                        className={`step ${accomplished ? "completed" : null}`}
                      >
                        <p className="mb-0">{icon}</p>
                        <div className="step-name">{stepNames[index]}</div>
                      </div>
                    )}
                  </Step>
                ))}
              </ProgressBar>
            </div>
          ) : (
            <></>
          )}

          <div className="userForm">
            <div className="personal-details-form step-Form-Conteiner ">
              {/* form-heading-holder */}
              <div className="form-header-holder">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="heading-holder">
                      <h6>
                        {page === 0
                          ? "Personal Details"
                          : page === 1
                            ? "Please fill the below fields"
                            : page === 2
                              ? "Create password for your account"
                              : null}
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="span-holder">
                      <span>Just a few steps to go</span>
                      <div style={{ width: "50px", height: "50px" }}>
                        <CircularProgressbar
                          value={calculateProgressPercentage()}
                          text={`${calculateProgressPercentage()}%`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">{PageDisplay()}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default B2Cretail_stepForm;
