import React, { useContext, useEffect, useState } from "react";
import "../Payment_stepform.css";
import { Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonComponent from "../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import { SelectAdress } from "../../common-components/AllModal/SelectAdress/SelectAdress";
import { btobAddressList } from "../../../../utils/apis/btb/Shipping_Address";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";

const Shipping_address = ({ handleNext, address_id, setAddressId }) => {
  const [modalShowchange, setModalShowchange] = useState(false);

  const { signin } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm();

  const [cart, setcart] = useState([]);
  const [address, setaddress] = useState([]);
  const [addressId, setSelectedAddressId] = useState(null);

  const handleRadioChange = (id) => {
    setSelectedAddressId(id);
    setAddressId(id);
  };

  const getData = async () => {
    {
      const res = await btobAddressList();
      setaddress(res.data);
      if (res.data.length) {
        setSelectedAddressId(res.data[0]?.id);
        setAddressId(res.data[0]?.id);
        clearErrors("address_id");
      } else {
        setError("address_id", {
          type: "manual",
          message: "Select Address",
        });
      }
    }
  };

  console.log(errors);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="ShoppingCart-sec stepp-inner-main">
        <div className="shipping-div my-3">
          <div className="border-div">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="icon-bg-div me-2">
                  <FontAwesomeIcon
                    icon="fa-solid fa-location-dot"
                    className="icon"
                  />
                </div>
                <p className="ship-text">Shipping address</p>
              </div>
              <button
                className=""
                type="button"
                onClick={() => setModalShowchange(true)}
              >
                Change{" "}
                <FontAwesomeIcon icon="fa-solid fa-pen" className="edit-icon" />
              </button>
            </div>

            {address?.map((data, index) => (
              <div key={index} className="main-address ms-4">
                <input
                  type="radio"
                  name="address"
                  value={data.id}
                  checked={addressId === data.id}
                  onChange={() => handleRadioChange(data.id)}
                />
                <button type="button" className="resi-btn">
                  {data?.address_type}
                </button>
                <h6>{data?.name}</h6>
                <p className="ship-text m-0">
                  {data?.address}
                  {data?.city?.name}, {data?.state?.name}, {data?.pincode?.name}
                  , {data?.country?.name}, {data?.phone}
                </p>
              </div>
            ))}

            <SelectAdress
              address={address}
              getData={getData}
              show={modalShowchange}
              onHide={() => setModalShowchange(false)}
            />
          </div>
          {/* <div className="d-flex">
            <div className="icon-bg-div me-2">
              <FontAwesomeIcon
                icon="fa-solid fa-location-dot"
                className="icon"
              />
            </div>
            <p className="ship-text">Delivery options</p>
          </div> */}
        </div>

        <SelectAdress
          address={address}
          getData={getData}
          show={modalShowchange}
          onHide={() => setModalShowchange(false)}
        />

        {/* first div for new user start*/}
        {/* <div>
          <p className="upoopp">
            Your personal information is securely encrypted and will solely be
            utilized for the purpose of delivery.
          </p>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-3">
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>--- please select ---</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6 mb-3">
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text" placeholder="Enter full name here" />
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6 mb-3">
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number here"
                />
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6 mb-3">
              <Form.Group>
                <Form.Label>Street Address</Form.Label>
                <Form.Control type="text" placeholder="Enter street address" />
              </Form.Group>
            </div>
            <div className="col-lg-8 mb-3">
              <Form.Group>
                <Form.Label>Apartment, Unit, Building, Floor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Apartment, Unit, Building, Floor"
                />
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6 mb-3">
              <Form.Group>
                <Form.Label>State / Province</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter State / Province"
                />
              </Form.Group>
            </div>
            <div className="col-lg-4  col-md-6 mb-3">
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control type="text" placeholder="Enter City" />
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6 mb-3">
              <Form.Group>
                <Form.Label>Postal code</Form.Label>
                <Form.Control type="text" placeholder="Enter Postal code" />
              </Form.Group>
            </div>
            <div className="col-lg-12 mb-3">
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
                  <p className="wevvcwec">Select the name of address</p>
                </div>
                <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-12">
                  <div className="nam-add-tab">
                    <Nav variant="pills" defaultActiveKey="link-1">
                      <Nav.Item>
                        <Nav.Link eventKey="link-1">Business</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="link-2">Factory</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="link-3">Warehouse</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="link-4">Residential</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="link-5">Other</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="existing-user-div">
          <button className="res-btn">Recidential</button>
          <h6>Disha Marathe</h6>
          <p>
            Patil Nagar, Bavdhan Budruk, B-506 ,Ganesh Recidency, Pune,
            Maharashtra, 411021, India{" "}
          </p>
          <p>(+91), 9130530883</p>
          <ButtonComponent
            className="change-btn"
            onClick={() => setModalShowchange(true)}
          >
            Change <FontAwesomeIcon icon="fa-solid fa-pen" />
          </ButtonComponent>
          <SelectAdress
            show={modalShowchange}
            onHide={() => setModalShowchange(false)}
          />
        </div> */}

        {/* second div for existing user end*/}
        <div className="save-btn-div ">
          <ButtonComponent
            className="continue-btn"
            type="button"
            onClick={() => {
              if (errors.address_id) {
                alert("Plases Selete Address");
              } else {
                handleNext();
              }
            }}
          >
            Continue
            <FontAwesomeIcon
              className="next-fafa"
              icon="fa-solid fa-arrow-right-long"
            />
          </ButtonComponent>
        </div>
      </section>
    </>
  );
};

export default Shipping_address;
