import React, { useContext, useEffect, useState } from "react";
import "./mrp-modal.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { RegxExpression } from "../../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import { Errors, Placeholders } from "../../../utils/errors";
import { domainInformationApi } from "../../../utils/apis/myWebsite/mywebsite";

const DomainTutorial = (props) => {

  
  const { IMG_URL } = useContext(Context);

const [tutorial, setTutorial] = useState();
  const getDomainInfo = async () => {
    const res = await domainInformationApi();
    if(res?.success){
      setTutorial(res?.data);
    }
  }

  
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  useEffect(() => {
    getDomainInfo();
  }, []); 
  return (
    <>
      <Modal className='mrp-modal' size="xl" {...props} centered >
        <Modal.Header closeButton onClick={props.onHide}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
        


          <div className='row text-center'>
            <div className='col-12'>
                <h4>Domain Tutorial</h4>
            </div>
          </div>
          <div className='row text-center'>
            <div className='col-12'>
               <video
                        src={IMG_URL + tutorial?.video}
                        controls
                        autoPlay
                        className="d-block"
                        style={{ maxWidth: '100%', height: 'auto' }}
                      />
            </div>
          </div>

          <hr/>

          <div className='row text-center'>
            <div className='col-12'>
                <h4>Description</h4>
            </div>
          </div>

          <div className='row text-center mt-5'>
            <div className='col-12'>
               {htmlToReactParser.parse(tutorial?.description) || ""}
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default DomainTutorial;
