import React from "react";
import Orderstock from "../Orders/Orderstock/Orderstock";
import Ordertable from "../Orders/Ordertable/Ordertable";

const Orders = () => {
  return (
    <>
      <Orderstock />
      <Ordertable />
    </>
  );
};

export default Orders;
