// InnerPageTwo.js

import React, { useState, Fragment, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../../Terms&Condition/TermsAndConditionModal";
import Select from "react-select";
import { Errors, Placeholders } from "../../../../../../utils/errors";
import {
  FirmType,
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../../../utils/apis/master/Master";
import { Context } from "../../../../../../utils/context";
import SuccessModal from "../../../../../CommonForAll/SuccessModal/SuccessModal";
// import { firmDetailNamePost, getFirmDetailName } from "../../../../../../utils/apis/seller/Seller";
import CommunicationAddress from "./Industry-Details-Tab-Forms/CommunicationAddress/CommunicationAddress";
import {
  firmDetailsPost,
  getfirmDetails,
  getFirmDetailName,
  firmDetailNamePost,
  firmAddressDetailPost,
  getfirmAddressDetail,
} from "../../../../../../utils/apis/three_p_support/three_p_support";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import classNames from "classnames";

const InnerPageTwo = ({
  innerNext,
  prevInnerPage,
  setPage,
  handleBack,
  activeTab,
  navItems,
  firmName,
  handleTabClick,
  userID,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [addressCount, setAddressCount] = useState(1);
  const [CommunicationAddressId, setCommunicationAddressId] = useState("");

  const [successModalShow, setSuccessModalShow] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,

    formState: { errors },
  } = useForm();

  const onFirmNameSubmit = async (d) => {
    const data = {
      name: d.name,
      firm_type_id: d.firm_type_id.value,
      three_p_personal_details_id: userID,
    };
    if (firmDetailNameId != "") {
      data.id = firmDetailNameId;
    }
    const res = await firmDetailNamePost(data);
    if (res?.success) {
      getFirmDetailNames();
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  };

  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);

  const [firmTypes, setFirmType] = useState([]);
  const [firmAddressTypes, setFirmAddressType] = useState([]);
  const getMasters = async () => {
    {
      const res = await FirmType();
      if (res?.success) {
        const data = await Select2Data(res.data, "s_firm_type_id", false);
        setFirmType(data);
      }
    }
    {
      const res = await firmAddressType();
      if (res?.success) {
        setFirmAddressType(res.data);
      }
    }
  };

  const [firmDetailNameId, setFirmDetailNameId] = useState("");
  const [industry, setIndustry] = useState([]);
  const [addressId, setAddressId] = useState("");

  const getFirmDetailNames = async () => {
    const res = await getFirmDetailName(userID);
    console.log(res.data);
    if (res?.success) {
      setFirmDetailNameId(res.data.id);
      reset(res.data);
    }else{
      setValue('name',firmName);
    }
  };

  useEffect(() => {
    getMasters();
    getFirmDetailNames();
  }, []);

  return (
    <div className="form-container">
      <h6 className="ms-3 firmmmdd">
        {" "}
        <FontAwesomeIcon
          className="left-arrow  me-2"
          icon="fa-solid fa-arrow-left "
          onClick={prevInnerPage} // Call prevInnerPage function
        />
        Firm Details
      </h6>
      <div className="tab-form-container firstthreerrr">
        <Form onSubmit={handleSubmit(onFirmNameSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Enter the firm name</Form.Label>
                <Form.Control
                disabled
                  type="text"
                  className={classNames("", {
                    "is-invalid": errors?.name,
                  })}
                  {...register("name", {
                    required: Errors.firmName,
                  })}
                  placeholder={Placeholders.firmName}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Select your firm type</Form.Label>
                {/* <Select
               options={FirmType}
               
                /> */}
                <Controller
                  name="firm_type_id"
                  {...register("firm_type_id", {
                    required: Errors.firmType,
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.firm_type_id
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      options={firmTypes}
                    />
                  )}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-end">
                <Button className="btn StepfoursaveBtn" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>

      <h6 className="ms-3 firmmmdd">Firm Address Details</h6>
      <CommunicationAddress
        userID={userID}
        setCommunicationAddressId={setCommunicationAddressId}
        setSuccessModalShow={setSuccessModalShow}
      />
     

      <div className="col-md-12">
        <div className="text-end apply_now_btn">
          {/* <p onClick={() => setModalShow(true)}>
            By continuing, I agree to Netpurti's
            <span className="termstxt">Terms of Use & Privacy Policy</span>
          </p> */}


          <div className="registerContinueMain">
            <Button className="back-btn" onClick={prevInnerPage}>
              Back
            </Button>
          </div>

          <div className="registerContinueMain">
            <Button className="tabs-btn" onClick={() => setPage(2)}>
              Register and Continue
            </Button>
          </div>
        </div>

        <TermsAndConditionModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <SuccessModal show={successModalShow} />
      </div>
    </div>
  );
};

export default InnerPageTwo;
