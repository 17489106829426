import React from 'react'

function SteponeInfo() {
  return (
    <>
      <section className='step1-info'>
        <div className='card-shadow'>
          <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12' >
              <span className='thumb-text'>Thumbnail</span> <span className='span-text'>(Size : 512 * 512, Max :1)</span>
              <div className='inner-card'>
                <div className='row'>
                  <div className='col-xxl-2 col-md-2'>
                    <img
                      className="product-imgs-small"
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/Seller_panel/viewproduct/img1.png"
                      }
                      alt="Logo"

                    />
                  </div>
                  <div className='col-xxl-5 col-md-5'>
                    <img
                      className="product-imgs"
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/Seller_panel/viewproduct/img--1.png"
                      }
                      alt="Logo"

                    />
                  </div>
                  <div className='col-xxl-5 col-md-5'>
                    <div className='descriptn-div'>
                      <h6 className='title'>Image Resolution</h6>
                      <p className='sub-title'>Use clear color images with a minimum resolution of 512*512 px.</p>
                      <h6 className='title'>Image Guidelines</h6>
                      <p className='sub-title'>Upload authentic product photos taken in bright lighting</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12' >
              <span className='thumb-text'>Thumbnail</span> <span className='span-text'>(Size : 512 * 512, Max :1)</span>
              <div className='inner-card'>
                <div className='row'>
                  <div className='col-xxl-6 col-md-6'>
                    <img
                      className="product-video"
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/Seller_panel/viewproduct/img--1.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className='col-xxl-6 col-md-6'>
                    <div className='descriptn-div'>
                      <h6 className='title'>video Resolution</h6>
                      <p className='sub-title'>Use clear color images with a minimum resolution of 512*512 px.</p>
                      <h6 className='title'>video Guidelines</h6>
                      <p className='sub-title'>Upload authentic product photos taken in bright lighting</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-2'>
            <span className='thumb-text'>Product Photos</span> <span className='span-text'>(Max 8)</span>
            <div className='inner-card'>
              <div className='row'>

                <div className='col-xxl-9 col-xl-9 col-lg-8 col-md-8'>
                  klk
                </div>
                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4'>
                  klk
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SteponeInfo